import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';

import { LicenceFilter } from './licence-filter';
import { LicenceDataRow } from './licence-data-row';
//TODO: import OverviewComponent from './overview-component';
import LicenceGridComponent, { GridExcelExport } from './licence-grid-component';

import BaseViewComponent, { LeafletHelper, ContextLayerModel } from '../base/base-view-component';

import { appViews } from '../../app/app-views';
import { AppSetState } from '../../app/app-set-state';
import { searchResultType } from '../../shared/helpers/leaflet/leaflet-helper';
import { SearchResultsExcelExport } from '../../map/search-results-excel-export';

class LicenceViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.leafletHelper = new LeafletHelper(this.props.geoserverUrl, 'licence-view-component-map', new SearchResultsExcelExport(this.props.appSetState, appViews.Licence).export);
    this.state = { contextLayers: [], dataRows: [], filterEnabled: false };
  }

  componentDidMount() {
    this.update(true);
    this.loadFilter();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.update();
    }
  }

  componentWillUnmount() {
    if (this.leafletHelper !== null && this.leafletHelper !== undefined) {
      this.leafletHelper.destroy();
      delete this.leafletHelper;
    }
  }

  loadFilter = () => {
    const url = 'api/licence/filters/load';
    const onSuccess = () => {
      this.setState({ filterEnabled: true });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true, false);
  };

  update = (initialLoad) => {
    const loadAllURL = 'api/licence/load-all?excel=false';

    const onSuccess = (result) => {
      var dataRows = LicenceDataRow.FromArray(result.dataRows);
      var contextLayers = ContextLayerModel.FromArray(result.contextLayers);
      var ids;

      var filter = this.props.filter.toApiParam();
      var isFilterSet = this.props.filter.isSet;
      if (isFilterSet) {
        const loadIdsURL = 'api/licence/load-ids';

        const onFilteredSuccess = (result) => {
          ids = result;
          dataRows = dataRows.filter(({ id }) => ids.includes(id));
          this.setState({
            contextLayers: contextLayers,
            dataRows: dataRows
          }, this.setMapPosition);
        };

        this.props.appSetState.api.post(loadIdsURL, filter, onFilteredSuccess, undefined, false, true);
      }
      else {
        this.setState({
          contextLayers: contextLayers,
          dataRows: dataRows
        }, initialLoad ? this.initialSetMapPosition : this.setMapPosition);
      }
    };

    this.props.appSetState.api.getCached(loadAllURL, onSuccess, null, false, true);

  };

  initialSetMapPosition = () => {
    const { filter } = this.props;

    if (filter.mapBoundsIsSet) {
      this.leafletHelper.leafletMap.fitBounds(filter.mapBounds);
    } else {
      this.setMapPosition();
    }
  };

  setMapPosition = () => {
    const { dataRows } = this.state;

    if (this.props.filter.followMap === false) {
      if (dataRows.length > 0) {
        const bounds = L.latLngBounds([]);
        dataRows.forEach(obj => bounds.extend(obj.shapes.latLngs));
        this.leafletHelper.flyToBounds(bounds);
      }
    }
  };

  showOnMap = (dataRow) => {
    this.leafletHelper.flyToBounds(L.latLngBounds(dataRow.shapes.latLngs), searchResultType.defaultZoomLevel(searchResultType.Licence));
  };

  copyLink = (dataRow) => {
    this.props.appSetState.view.details.showLicenceLink(dataRow.id, dataRow.link);
  };

  excelExport = () => {
    new GridExcelExport().export(this.props.appSetState, this.state.dataRows);
  };

  render() {
    const { dataRows } = this.state;

    const overview = undefined;
    //const overview = (
    //  <OverviewComponent
    //    appSetState={this.props.appSetState}
    //    dataRows={dataRows}
    //  />
    //);
    const grid = (
      <LicenceGridComponent
        appSetState={this.props.appSetState}
        dataRows={dataRows}
        showOnMap={this.showOnMap}
        copyLink={this.copyLink}
      />
    );
    return (

      <BaseViewComponent
        className="licence-view-component"
        leafletHelper={this.leafletHelper}
        contextLayers={this.state.contextLayers}
        shapes={dataRows.map(obj => obj.shapes)}
        appView={appViews.Licence}
        appSetState={this.props.appSetState}
        update={this.update}
        settings={this.props.settings}
        filter={this.props.filter}
        showFiltersButton
        filterButtonEnabled={this.state.filterEnabled}
        showFilters={this.props.showFilters}
        setShowFilters={this.props.setShowFilters}
        showFollowMapButton
        followMap={this.props.followMap}
        setFollowMap={this.props.setFollowMap}
        overview={overview}
        grid={grid}
        excelExport={this.excelExport}
        reset={this.props.reset}
      />
    );
  }
}

LicenceViewComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    showMap: PropTypes.bool.isRequired,
    showOverview: PropTypes.bool.isRequired
  }).isRequired,
  filter: PropTypes.instanceOf(LicenceFilter).isRequired,
  showFilters: PropTypes.bool.isRequired,
  setShowFilters: PropTypes.func.isRequired,
  followMap: PropTypes.bool.isRequired,
  setFollowMap: PropTypes.func.isRequired,
  reset: PropTypes.func
};

export default LicenceViewComponent;
