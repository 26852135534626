import React from 'react';
import PropTypes from 'prop-types';

import { DiscoveryRow } from '../licence-detail';

import { valueTypes } from '../../../shared/helpers/value-handler';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const DiscoveriesTableComponent = (props) => {
  const { discoveries, onDiscoveryClick, onOperatorClick } = props;

  const getRow = (row, rowIndex) => {
    let name = row.name;
    if (typeof onDiscoveryClick === 'function' && typeof row.id === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onDiscoveryClick(row.id);
      };
      name = <a href="/" title="Show Details" onClick={onClick}>{name}</a>;
    }

    let operator = row.operator.operatorName;
    if (typeof onOperatorClick === 'function' && typeof row.operator.operatorId === 'number' && row.operator.operatorId > 0) {
      const onClick = (e) => {
        e.preventDefault();
        onOperatorClick(row.operator.operatorId);
      };
      operator = <a href="/" title="Show Details" onClick={onClick}>{operator}</a>;
    }

    return (
      <tr key={'discovery-row-' + rowIndex.toString()}>
        <td style={{ width: '25%' }}>{name}</td>
        <td style={{ width: '15%' }}>{row.year}</td>
        <td style={{ width: '25%' }}>{operator}</td>
        <td style={{ width: '20%' }}>{row.classification}</td>
        <td style={{ width: '15%' }}>{valueTypes.ValueOnly(valueTypes.mmmboe, row.reserves)}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Discovery Name</td>
          <td>Discovery Year</td>
          <td>Current Operator</td>
          <td>Discovery Classification</td>
          <td>Resources ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
        </tr>
      </thead>
      <tbody>
        {discoveries.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
}

DiscoveriesTableComponent.propTypes = {
  discoveries: PropTypes.arrayOf(PropTypes.instanceOf(DiscoveryRow)).isRequired,
  onDiscoveryClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default DiscoveriesTableComponent;
