import { LocalStorage as BaseLocalStorage } from '../shared/helpers/local-storage';

const SETTINGS_KEY = 'ATLAS_5_0_22_SETTINGS';

class LocalStorage extends BaseLocalStorage {
  constructor() {
    super(SETTINGS_KEY);
  }

  get localStorage() {
    let result = super.localStorage;

    if (result === null) {
      result = {};
    }
    if (typeof result.showLabels !== 'boolean') {
      result.showLabels = true;
    }
    if (typeof result.showSettingsOnHover !== 'boolean') {
      result.showSettingsOnHover = false;
    }
    if (!(Array.isArray(result.navigationHistory))) {
      result.navigationHistory = [];
    }
    if (!(Array.isArray(result.mapBookmarks))) {
      result.mapBookmarks = [];
    }
    if (typeof result.mapBookmarksVisible !== 'boolean') {
      result.mapBookmarksVisible = true;
    }

    return result;
  }

  set localStorage(value) { super.localStorage = value; }

  get showLabels() { return this.localStorage.showLabels; }
  set showLabels(value) {
    const storage = this.localStorage;
    if (storage.showLabels !== value) {
      storage.showLabels = value;
      this.localStorage = storage;
    }
  }

  get showSettingsOnHover() { return this.localStorage.showSettingsOnHover; }
  set showSettingsOnHover(value) {
    const storage = this.localStorage;
    if (storage.showSettingsOnHover !== value) {
      storage.showSettingsOnHover = value;
      this.localStorage = storage;
    }
  }

  get navigationHistory() { return this.localStorage.navigationHistory; }
  set navigationHistory(value) {
    const storage = this.localStorage;
    if (storage.navigationHistory !== value) {
      storage.navigationHistory = value;
      this.localStorage = storage;
    }
  }

  get mapBookmarks() { return this.localStorage.mapBookmarks; }
  set mapBookmarks(value) {
    const storage = this.localStorage;
    if (storage.mapBookmarks !== value) {
      storage.mapBookmarks = value;
      this.localStorage = storage;
    }
  }

  get mapBookmarksVisible() { return this.localStorage.mapBookmarksVisible; }
  set mapBookmarksVisible(value) {
    const storage = this.localStorage;
    if (storage.mapBookmarksVisible !== value) {
      storage.mapBookmarksVisible = value;
      this.localStorage = storage;
    }
  }
}

export { LocalStorage };
