import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { appIcons, appIconSizes } from './app-icons';

const SupportButtonComponent = (props) => {
  const email = 'support@westwoodenergy.com';
  const onClick = () => window.location = 'mailto:' + email;

  return (
    <Button style={{ padding: '2px 0 1px 0', fontSize: '10px', marginTop: '7px', marginRight: '5px' }} title={email} onClick={onClick}>
      {props.showLabels && <span style={{ verticalAlign: 'super', paddingLeft: '5px', paddingRight: '5px' }}>Support</span>}
      {appIcons.getIcon(appIcons.Email, appIconSizes.md)}
    </Button>
  );
};

SupportButtonComponent.propTypes = {
  showLabels: PropTypes.bool.isRequired,
  right: PropTypes.bool.isRequired
};

SupportButtonComponent.defaultProps = {
  right: false
};

export default SupportButtonComponent;