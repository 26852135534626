import L from 'leaflet';

import { createContextLayer } from '../layers/context-layer';
import { ContextLayerModel } from '../models/context-layer-model';
import { IN_DEV_MODE } from '../../common';

const filterModels = (models, zoom) => {
  if (!Array.isArray(models) || models.filter(obj => !(obj instanceof ContextLayerModel)).length > 0) {
    throw new Error('Invalid \'models\' param supplied to \'filterModels\'');
  }
  if (typeof zoom !== 'number') {
    throw new Error('Invalid \'zoom\' param supplied to \'filterModels\'');
  }

  const result = { visible: [], hidden: [] };
  for (var loop = 0; loop < models.length; loop++) {
    const model = models[loop];
    const layerIsVisibleForZoom = model.visible && (zoom >= model.minZoom && zoom <= model.maxZoom);

    if (layerIsVisibleForZoom) {
      result.visible.push(model);
    } else {
      result.hidden.push(model);
    }
  };
  return result;
};

const getLayersForZoom = (geoserverUrl, tileSize, models, zoom) => {
  if (typeof geoserverUrl !== 'string') {
    throw new Error('Invalid \'geoserverUrl\' param supplied to \'getLayersForZoom\'');
  }
  if (typeof tileSize !== 'number') {
    throw new Error('Invalid \'tileSize\' param supplied to \'getLayersForZoom\'');
  }
  if (!Array.isArray(models) || models.filter(obj => !(obj instanceof ContextLayerModel)).length > 0) {
    throw new Error('Invalid \'models\' param supplied to \'getLayersForZoom\'');
  }
  if (typeof zoom !== 'number') {
    throw new Error('Invalid \'zoom\' param supplied to \'getLayersForZoom\'');
  }

  const visibleModels = filterModels(models, zoom).visible;
  const result = visibleModels.map(obj => createContextLayer(geoserverUrl, tileSize, obj));
  return result;
};

class ContextLayerController {
  constructor(leafletMap, geoserverUrl, tileSize, contextLayerModels) {
    if (!(leafletMap instanceof L.Map)) {
      throw new Error('Invalid \'leafletMap\' param supplied to \'ContextLayerController.ctor\'');
    }
    if (typeof geoserverUrl !== 'string') {
      throw new Error('Invalid \'geoserverUrl\' param supplied to \'ContextLayerController.ctor\'');
    }
    if (typeof tileSize !== 'number') {
      throw new Error('Invalid \'tileSize\' param supplied to \'ContextLayerController.ctor\'');
    }
    if (!Array.isArray(contextLayerModels) || contextLayerModels.filter(obj => !(obj instanceof ContextLayerModel)).length > 0) {
      throw new Error('Invalid \'contextLayerModels\' param supplied to \'ContextLayerController.ctor\'');
    }

    this._updating = false;
    this._leafletMap = leafletMap;
    this._geoserverUrl = geoserverUrl;
    this._tileSize = tileSize;
    this._contextLayerModels = contextLayerModels;
    this._zoomend = () => this.update();
    this._leafletMap.on('zoomend', this._zoomend);
  }

  destroy() {
    this._leafletMap.off('zoomend', this._zoomend);

    delete this._contextLayerModels;
  }

  get geoserverUrl() { return this._geoserverUrl; }
  get contextLayerModels() { return this._contextLayerModels; }

  update() {
    const bounds = this._leafletMap.getBounds();
    const zoom = this._leafletMap.getBoundsZoom(bounds, 0);
    this.load(zoom);
  }

  load(zoom) {
    if (typeof zoom !== 'number') {
      throw new Error('Invalid \'zoom\' param supplied to \'ContextLayerController.load\'');
    }

    if (this._updating === true) {
      return;
    }

    this._updating = true;

    const bounds = this._leafletMap.getBounds();
    const layers = Object.values(this._leafletMap._layers).filter(obj => obj._contextLayerModel instanceof ContextLayerModel);
    const filteredModels = filterModels(this._contextLayerModels, zoom);

    // Add/Update layers
    for (var addLoop = 0; addLoop < filteredModels.visible.length; addLoop++) {
      const model = filteredModels.visible[addLoop];
      let layer = layers.find(obj => obj._contextLayerModel === model);

      // If styles don't match (i.e. the user has switched off labels), then we need to remove and re-add
      if (layer !== undefined && layer.options.styles !== model.getStyles()) {
        this._leafletMap.removeLayer(layer);
        layer = undefined;
      }

      if (layer === undefined) {
        this._leafletMap.addLayer(createContextLayer(this._geoserverUrl, this._tileSize, model));
      }
    }

    // Remove layers
    for (var remLoop = 0; remLoop < filteredModels.hidden.length; remLoop++) {
      const model = filteredModels.hidden[remLoop];
      const layer = layers.find(obj => obj._contextLayerModel === model);
      if (layer !== undefined) {
        this._leafletMap.removeLayer(layer);
      }
    }

    // Reset layers z-order
    this._reorderLayers();

    // Reset position/zoom if changed
    if (this._leafletMap.getZoom() !== zoom) {
      this._leafletMap.flyToBounds(bounds, { animate: true, duration: 1 });
    }

    this._updating = false;

    this.consoleLogLayers();
  }

  _reorderLayers() {
    const comparer = (a, b) => {
      a = a._contextLayerModel.layerOrder;
      b = b._contextLayerModel.layerOrder;
      if (a > b) { return 1; }
      if (b > a) { return -1; }
      return 0;
    };

    let layers = Object.values(this._leafletMap._layers).filter(obj => obj._contextLayerModel instanceof ContextLayerModel);
    layers = layers.sort(comparer);
    layers.forEach(obj => obj.bringToFront());
  }

  consoleLogLayers() {
    //if (IN_DEV_MODE) {
    //  const layerNames = Object.values(this._leafletMap._layers)
    //    .filter(obj => obj._contextLayerModel instanceof ContextLayerModel)
    //    .map(obj => obj.options.layers);
    //  console.log('MAP LAYERS', layerNames);
    //}
  }
}

export { ContextLayerController, ContextLayerModel, getLayersForZoom };