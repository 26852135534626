import React from 'react';
import PropTypes from 'prop-types';

import './table-component.scss';

import { valueTypes } from '../../../../shared/helpers/value-handler';
import BaseTableComponent from '../../../../shared/components/detail-view-grid-layout/base-table-component';
import { HubParticipant } from '../../../../shared/components/models/gmatrix3-extension-hub-participant';

const HubFieldsParticipationTableComponent = (props) => {
  const { owners, currentYear, discountRate, onCorporateClick } = props;

  const getRow = (row, rowIndex) => {
    let corporateName = row.corporateName;
    if (typeof onCorporateClick === 'function' && typeof row.corporateId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onCorporateClick(row.corporateId);
      };
      corporateName = <a href="/" title="Show Details" onClick={onClick}>{corporateName}</a>;
    }

    const key = 'row-' + rowIndex.toString();
    return (
      <tr key={key}>
        <td style={{ width: '16%' }}>{corporateName}</td>
        <td style={{ width: '8%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.netRemainingLiquidMmb)}</td>
        <td style={{ width: '8%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.netRemainingGasBCF)}</td>
        <td style={{ width: '8%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.netRemainingReservesMmboe)}</td>
        <td style={{ width: '8%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.currentYearNetProductionMBOEPD)}</td>
        <td style={{ width: '8%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.netRemainingCapexUSDMM)}</td>
        <td style={{ width: '8%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.currentYearNetOpexUSDMM)}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Participant</td>
          <td>Net remaining liquid reserves ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
          <td>Net remaining gas reserves ({valueTypes.UnitString(valueTypes.bcf)})</td>
          <td>Net remaining total reserves ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
          <td>Net {currentYear} production ({valueTypes.UnitString(valueTypes.mboed)})</td>
          <td>Net remaining capex ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>Net {currentYear} opex ({valueTypes.UnitString(valueTypes.usdMM)})</td>
        </tr>
      </thead>
      <tbody>
        {owners.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
};

HubFieldsParticipationTableComponent.propTypes = {
  owners: PropTypes.arrayOf(PropTypes.instanceOf(HubParticipant)).isRequired,
  currentYear: PropTypes.number.isRequired,
  discountRate: PropTypes.number.isRequired,
  onCorporateClick: PropTypes.func
};

export default HubFieldsParticipationTableComponent;
