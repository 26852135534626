import L from 'leaflet';

import './menu-control.scss';
import { isIconDef, getObjectClassName, createButton, getIcon, addEventHandler } from '../helpers/control-helper';
import { appendClassNames } from '../../common';

L.Control.MenuItemControl = L.Control.extend({
  initialize: function (options) { this.options = options; },
  onAdd: function () { return this.options.container; }
});

const DEFAULT_MENU_CONTROL_OPTIONS = {
  position: 'topleft',
  visible: true,
  isOpen: false,
  disabled: false,
  title: '',
  className: '',
  autoClose: true
};
const VISIBLE_CLASS_NAME = 'menu-visible';
const OPEN_CLASS_NAME = 'menu-open';
const DISABLED_CLASS_NAME = 'leaflet-disabled';
const TOGGLED_CLASS_NAME = 'toggled';

class MenuItemControl {
  constructor(iconDef, title) {
    this._objectClassName = getObjectClassName(this);

    if (!isIconDef(iconDef)) {
      throw new Error('Invalid "iconDef" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (typeof title !== 'string') {
      throw new Error('Invalid "title" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (typeof this.onClick !== 'function') {
      throw new Error('Function "onClick" has not been overridden in  "' + this._objectClassName + '.ctor"');
    }

    this._iconDef = iconDef;
    this._title = title;
    this._menu = null;
    this._button = null;
    this._icon = null;
    this._label = null;
  }

  get iconDef() { return this._iconDef; }
  set iconDef(value) { this.setIconDefAndTitle(value, this._title); }

  get title() { return this._title; }
  set title(value) { this.setIconDefAndTitle(this._iconDef, value); }

  get leafletMap() { return this._menu.leafletMap; }

  get visible() {
    const result = L.DomUtil.hasClass(this._button, VISIBLE_CLASS_NAME);
    return result;
  }
  set visible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "' + this._objectClassName + '.visible.set"');
    }

    if (this.visible !== value) {
      if (value) {
        L.DomUtil.addClass(this._button, VISIBLE_CLASS_NAME);
      } else {
        L.DomUtil.removeClass(this._button, VISIBLE_CLASS_NAME);
      }
    }
  }

  get disabled() {
    const result = L.DomUtil.hasClass(this._button, DISABLED_CLASS_NAME);
    return result;
  }
  set disabled(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "' + this._objectClassName + '.disabled.set"');
    }

    if (this.disabled !== value) {
      if (value) {
        L.DomUtil.addClass(this._button, DISABLED_CLASS_NAME);
      } else {
        L.DomUtil.removeClass(this._button, DISABLED_CLASS_NAME);
      }
    }
  }

  get toggled() {
    const result = L.DomUtil.hasClass(this._button, TOGGLED_CLASS_NAME);
    return result;
  }
  set toggled(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "' + this._objectClassName + '.toggled.set"');
    }

    if (this.toggled !== value) {
      if (value) {
        L.DomUtil.addClass(this._button, TOGGLED_CLASS_NAME);
      } else {
        L.DomUtil.removeClass(this._button, TOGGLED_CLASS_NAME);
      }
    }
  }

  _initialize(menu) {
    if (!(menu instanceof MenuControl) || this._menu !== null) {
      throw new Error('Invalid "menu" param supplied to "' + this._objectClassName + '._initialize"');
    }

    this._menu = menu;
    this._container = L.DomUtil.create('div', 'leaflet-bar menu-control-item', this._menu._menuDiv);
    this._button = L.DomUtil.create('a', VISIBLE_CLASS_NAME, this._container);
    if (this._menu._buttonLeft) {
      this._icon = L.DomUtil.create('span', '', this._button);
      this._label = L.DomUtil.create('span', '', this._button);
    } else {
      this._label = L.DomUtil.create('span', '', this._button);
      this._icon = L.DomUtil.create('span', '', this._button);
    }
    addEventHandler(this._button, 'click', () => this._intOnClick());
    this.update();
  }

  update() {
    this._icon.innerHTML = getIcon(this.iconDef);
    this._label.innerHTML = this.title;
  }

  setIconDefAndTitle(iconDef, title) {
    if (!isIconDef(iconDef)) {
      throw new Error('Invalid "iconDef" param supplied to "' + this._objectClassName + '.setIconDefAndTitle"');
    }
    if (typeof title !== 'string') {
      throw new Error('Invalid "title" param supplied to "' + this._objectClassName + '.setIconDefAndTitle"');
    }

    let changed = false;
    if (this._iconDef !== iconDef) {
      this._iconDef = iconDef;
      changed = true;
    }
    if (this._title !== title) {
      this._title = title;
      changed = true;
    }
    if (changed) {
      this.update();
    }
  }

  _intOnClick() {
    if (!this.disabled) {
      if (this._menu._autoClose) {
        this._menu.close();
      }
      this.onClick();
    }
  };
}

class MenuControl {
  constructor(leafletMap, iconDef, options = {}) {
    this._objectClassName = getObjectClassName(this);

    if (!(leafletMap instanceof L.Map)) {
      throw new Error('Invalid "leafletMap" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (!isIconDef(iconDef)) {
      throw new Error('Invalid "iconDef" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (typeof options !== 'object') {
      throw new Error('Invalid "options" param supplied to "' + this._objectClassName + '.ctor"');
    }
    options = { ...DEFAULT_MENU_CONTROL_OPTIONS, ...options };
    if (typeof options.position !== 'string') {
      throw new Error('Invalid "options.position" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (typeof options.visible !== 'boolean') {
      throw new Error('Invalid "options.visible" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (typeof options.isOpen !== 'boolean') {
      throw new Error('Invalid "options.isOpen" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (typeof options.disabled !== 'boolean') {
      throw new Error('Invalid "options.disabled" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (typeof options.title !== 'string') {
      throw new Error('Invalid "options.title" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (typeof options.className !== 'string') {
      throw new Error('Invalid "options.className" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (typeof options.autoClose !== 'boolean') {
      throw new Error('Invalid "options.autoClose" param supplied to "' + this._objectClassName + '.ctor"');
    }

    this._leafletMap = leafletMap;
    this._menuItems = [];
    this._autoClose = options.autoClose;
    this._mouseOver = false;

    this._buttonLeft = ['topleft', 'bottomleft'].includes(options.position);
    this._container = L.DomUtil.create('div', appendClassNames('menu-control', options.className) + (this._buttonLeft ? ' button-left' : ' button-right'));
    if (this._buttonLeft) {
      this._btnDiv = L.DomUtil.create('div', 'leaflet-bar button-div', this._container);
      this._menuDiv = L.DomUtil.create('div', 'menu-div', this._container);
    } else {
      this._menuDiv = L.DomUtil.create('div', 'menu-div', this._container);
      this._btnDiv = L.DomUtil.create('div', 'leaflet-bar button-div', this._container);
    }
    this._button = createButton(this._btnDiv, '', iconDef, () => this._onClick(), { title: options.title });
    this._leafletMap.addControl(new L.Control.MenuItemControl({ position: options.position, container: this._container }));
    L.DomEvent.on(this._container, 'mouseenter', () => this._mouseOver = true, this);
    L.DomEvent.on(this._container, 'mouseleave', () => this._mouseOver = false, this);

    this.visible = options.visible;
    this.isOpen = options.isOpen;
    this.disabled = options.disabled;
  }

  get leafletMap() { return this._leafletMap; };

  get visible() {
    const result = L.DomUtil.hasClass(this._container, VISIBLE_CLASS_NAME);
    return result;
  }
  set visible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "' + this._objectClassName + '.visible.set"');
    }

    if (this.visible !== value) {
      if (value) {
        L.DomUtil.addClass(this._container, VISIBLE_CLASS_NAME);
      } else {
        L.DomUtil.removeClass(this._container, VISIBLE_CLASS_NAME);
      }
    }
  }

  get isOpen() {
    const result = L.DomUtil.hasClass(this._container, OPEN_CLASS_NAME);
    return result;
  }
  set isOpen(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "' + this._objectClassName + '.isOpen.set"');
    }

    if (this.isOpen !== value) {
      if (value) {
        if (!this.disabled) {
          L.DomUtil.addClass(this._container, OPEN_CLASS_NAME);
        }
      } else {
        L.DomUtil.removeClass(this._container, OPEN_CLASS_NAME);
      }
    }
  }

  get disabled() {
    const result = L.DomUtil.hasClass(this._button, DISABLED_CLASS_NAME);
    return result;
  }
  set disabled(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "' + this._objectClassName + '.disabled.set"');
    }

    if (this.disabled !== value) {
      if (value) {
        this.isOpen = false;
        L.DomUtil.addClass(this._button, DISABLED_CLASS_NAME);
      } else {
        L.DomUtil.removeClass(this._button, DISABLED_CLASS_NAME);
      }
    }
  }

  show() { this.visible = true; }
  hide() { this.visible = false; }

  open() { this.isOpen = true; }
  close() { this.isOpen = false; }
  toggleOpen() { this.isOpen = !this.isOpen; }

  addMenuItem(menuItem) {
    menuItem._initialize(this);
    this._menuItems.push(menuItem);
  }

  clear() {
    this._menuItems = [];
    this._menuDiv.innerHTML = '';
  }

  addSeparator() {
    const outerDiv = L.DomUtil.create('div', 'leaflet-bar menu-control-separator', this._menuDiv);
    L.DomUtil.create('div', 'div-inner', outerDiv);
  }

  _onClick() {
    this.toggleOpen()
  };
}

export { L, DEFAULT_MENU_CONTROL_OPTIONS, MenuControl, MenuItemControl };