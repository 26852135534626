import React from 'react';
import PropTypes from 'prop-types';

import MapComponent from '../../../components/fiscal/map/map-component';
import { TimelineChart } from '../../../shared/components/timeline-chart/timeline-chart';
import LocationComponent from '../../../components/fiscal/fact-components/location-component';
import OwnersPieChartComponent from '../../../shared/components/owners-pie-chart/owners-pie-chart-component';
import { ContextLayerModel, latLngBounds, searchResultType } from '../../../shared/helpers/leaflet/leaflet-helper';
import { FasterPortfolio, FieldDetail } from '../../../components/fiscal/models/portfolio/faster-portfolio';
import EconomicSummaryComponent from '../../../components/fiscal/fact-components/economic-summary-component';
import DevelopmentDetailsComponent from '../../../components/fiscal/fact-components/development-details-component';
import { createSection, sectionTypes, createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedKeyFactsComponent = (props) => {
  const { portfolio, resource } = props;

  const leftPanel = (
    <React.Fragment>
      {createSection100(<LocationComponent resource={resource} onHubClick={props.onHubClick} onWellClick={props.onWellClick} onLicenceClick={props.onLicenceClick} onBlockClick={props.onBlockClick} />, 'Location')}
      {createSection100(<DevelopmentDetailsComponent resource={resource} />, 'Development details')}
      {createSection100(<EconomicSummaryComponent cashflow={resource.fiscalResource.cashflow} currentYear={portfolio.currentYear} showExtendedFiscal={resource.fiscalResource.showExtendedFiscal} />, 'Economic summary')}
    </React.Fragment>
  );
  const rightPanel = <div style={{ height: '100%' }}><MapComponent geoserverUrl={props.geoserverUrl} contextLayers={props.contextLayers} bounds={latLngBounds(resource.fiscalResource.shapes.latLngs)} zoom={searchResultType.defaultZoomLevel(searchResultType.Field)} shapes={[resource.fiscalResource.shapes]} /></div>;

  return (
    <div ref={props.innerRef}>
      {createSection([
        { content: leftPanel, sectionType: sectionTypes.section50, noMarginVertical: true },
        { content: rightPanel, sectionType: sectionTypes.section50 }
      ])}

      {createSection([
        { content: <div style={{ height: '140px' }}><TimelineChart events={resource.events} /></div>, sectionType: sectionTypes.section50, title: 'Timeline' },
        { content: <div style={{ height: '200px' }}><OwnersPieChartComponent owners={resource.owners} /></div>, sectionType: sectionTypes.section50, title: 'Participation' }
      ])}
    </div>
  );
};

AdvancedKeyFactsComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  portfolio: PropTypes.instanceOf(FasterPortfolio).isRequired,
  resource: PropTypes.instanceOf(FieldDetail).isRequired,
  onHubClick: PropTypes.func,
  onWellClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  geoserverUrl: PropTypes.string.isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired
};

export default AdvancedKeyFactsComponent;