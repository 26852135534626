import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { appIcons, appIconSizes } from '../app/app-icons';

const ValuationListComponent = (props) => {
  const { userValuations, selectedValuation, onSelect, activeEdit, onNew } = props;

  var valuations = [];

  if (userValuations !== undefined && userValuations !== null && userValuations.length > 0) {
    for (var j = 0; j < userValuations.length; j++) {
      let selected = userValuations[j].variableId === selectedValuation ? ' selected' : '';
      let variableId = userValuations[j].variableId;
      let isDefault = userValuations[j].isDefault ? ' (Default)' : '';
      let isInvalid = userValuations[j].validationState !== undefined ? userValuations[j].validationState.isValid === false ? <span className="valuation error" > {appIcons.getIcon(appIcons.ErrorTriangle, appIconSizes.sm)} </span>: '' : '';
      valuations.push(<div disabled={activeEdit} key={j} className={'valuation' + selected} onClick={() => onSelect(variableId, activeEdit)}><span> {userValuations[j].variableName}</span><span style={{ fontWeight: 'bold' }}>{isDefault}</span>{isInvalid}</div>);
    }
  }

  return (
    <div className="valuations">
      <div className="valuation-holder">{valuations}</div>
      <div className="new-btn-holder"><Button disabled={activeEdit} className="new-btn" onClick={onNew} >Add New</Button></div>
    </div>    
  );
}

ValuationListComponent.propTypes = {
  userValuations: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedValuation: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  activeEdit: PropTypes.bool.isRequired,
  onNew: PropTypes.func.isRequired
};

export default ValuationListComponent;