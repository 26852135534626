import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { AppSetState } from './app-set-state';
import { appIcons, appIconSizes } from './app-icons';

class WebinarModel {
  constructor(obj) {
    if (typeof obj.title !== 'string') {
      throw new Error('Invalid "title" supplied to "WebinarModel.ctor"');
    }
    if (typeof obj.source !== 'string') {
      throw new Error('Invalid "source" supplied to "WebinarModel.ctor"');
    }

    this.title = obj.title;
    this.source = obj.source;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "WebinarModel.FromArray"');
    }

    const result = array.map(obj => new WebinarModel(obj));
    return result;
  }
}

const WebinarsDropdownComponent = (props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [webinars, setWebinars] = React.useState([]);

  const renderWebinar = (webinar, idx) => {
    return (
      <DropdownItem key={'webinar-item-' + idx} onClick={() => props.appSetState.view.video.show(webinar.source, webinar.title)}>
        {appIcons.getIcon(appIcons.Video, appIconSizes.sm)}
        <span style={{ marginLeft: '5px' }}>{webinar.title}</span>
      </DropdownItem>
    );
  };

  const toggleDropdownOpen = () => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    } else {
      const onSuccess = (result) => {
        const models = WebinarModel.FromArray(result);
        if (models.length === 0) {
          setWebinars(<DropdownItem disabled>No Webinars</DropdownItem>)
        } else {
          setWebinars(models.map((obj, idx) => renderWebinar(obj, idx)));
        }
        setDropdownOpen(true);
      };
      props.appSetState.api.get('api/app/webinars', onSuccess);
    }
  };

  return (
    <Dropdown nav inNavbar isOpen={dropdownOpen} toggle={toggleDropdownOpen}>
      <DropdownToggle style={{ padding: '2px 0 1px 0', fontSize: '10px', marginTop: '7px', marginRight: '5px' }} nav caret title="Webinars">
        {props.showLabels && <span style={{ verticalAlign: 'super', paddingLeft: '5px', paddingRight: '5px' }}>Webinars</span>}
        {appIcons.getIcon(appIcons.Podcast, appIconSizes.md)}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-right">
        {webinars}
      </DropdownMenu>
    </Dropdown>
  );
};

WebinarsDropdownComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  showLabels: PropTypes.bool.isRequired,
  right: PropTypes.bool.isRequired
};

WebinarsDropdownComponent.defaultProps = {
  right: false
};

export default WebinarsDropdownComponent;
