import React from 'react';
import PropTypes from 'prop-types';

import CompletedAndActiveWellsTableComponent from './hub-wells/completed-and-active-wells-table-component';
import PlannedWellsTableComponent from './hub-wells/planned-wells-table-component';

import { HubDetail } from '../../../models/hub-detail';
import { createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import './advanced-hub.scss';

const AdvancedHubWellComponent = (props) => {
  const { hub, proximity } = props;
  var completedWellTable, plannedWellTable;
  var completedWellTableTitle = 'Completed and active E&A wells within ' + proximity + 'km of the hub centre point';
  var plannedWellTableTitle = 'Planned E&A wells within ' + proximity + 'km of the hub centre point';

  const hasCompletedWells = hub.proximityCompletedActiveWells !== null && hub.proximityCompletedActiveWells.length > 0;
  if (hasCompletedWells) {
    completedWellTable = <CompletedAndActiveWellsTableComponent
      wells={hub.proximityCompletedActiveWells}
      onWellClick={props.onWellClick}
      onReportClick={props.onReportClick}
      onLicenceClick={props.onLicenceClick}
      onOperatorClick={props.onOperatorClick}
      onProspectClick={props.onProspectClick}
      onDiscoveryClick={props.onDiscoveryClick}
      onFieldClick={props.onFieldClick}
    />;
  } else {
    completedWellTable = (<div>No completed or active E&A wells</div>);
  }

  const hasPlannedWells = hub.proximityPlannedWells !== null && hub.proximityPlannedWells.length > 0;
  if (hasPlannedWells) {
    plannedWellTable = <PlannedWellsTableComponent
      wells={hub.proximityPlannedWells}
      onWellClick={props.onWellClick}
      onLicenceClick={props.onLicenceClick}
      onOperatorClick={props.onOperatorClick}
      onProspectClick={props.onProspectClick}
      onDiscoveryClick={props.onDiscoveryClick}
      onFieldClick={props.onFieldClick}
    />;
  } else {
    plannedWellTable = (<div>No planned E&A wells</div>);
  }

  return (
    <div ref={props.innerRef}>
      {createSection100(completedWellTable, completedWellTableTitle)}
      {createSection100(plannedWellTable, plannedWellTableTitle)}
    </div>
  );
};

AdvancedHubWellComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  hub: PropTypes.instanceOf(HubDetail).isRequired,
  proximity: PropTypes.number.isRequired,
  onWellClick: PropTypes.func,
  onReportClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onOperatorClick: PropTypes.func,
  onProspectClick: PropTypes.func,
  onDiscoveryClick: PropTypes.func,
  onFieldClick: PropTypes.func
};

export default AdvancedHubWellComponent;