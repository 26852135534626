import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { appViews } from './app-views';
import { AppSetState } from './app-set-state';
import { appIcons, appIconSizes } from './app-icons';
import { AtlasPanel } from '../shared/helpers/enums.js';

const NavigationDropdownComponent = (props) => {
  const { view, navigationHistory, messageBox } = props.appSetState;
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [dropdownItems, setDropdownItems] = React.useState([]);

  const onItemClick = (item) => {
    const panel = AtlasPanel.History;
    switch (item.detailAppView) {
      case appViews.Reports: view.details.showReport(item.params.id, item.params.mainid, panel); break;
      case appViews.Corporate: view.details.showCorporate(item.params.id, panel); break;
      case appViews.Hub: view.details.showHub(item.params.id, panel); break;
      case appViews.Field: view.details.showField(item.params.id, panel); break;
      case appViews.Discovery: view.details.showDiscovery(item.params.id, panel); break;
      case appViews.Prospect: view.details.showProspect(item.params.id, panel); break;
      case appViews.Well: view.details.showWell(item.params.id, panel); break;
      case appViews.Licence: view.details.showLicence(item.params.id, panel); break;
      case appViews.Block: view.details.showBlock(item.params.id, panel); break;
      default: throw new Error('"appViews.' + appViews.getDisplayName(item.detailAppView) + '" not supported in "NavigationDropdownComponent.onItemClick"');
    }
  };

  const onClearClick = () => messageBox.showYesNoPrompt('Are you sure you want to clear your history?', () => navigationHistory.clear());

  const renderItem = (item, index) => {
    const result = (
      <DropdownItem key={'toolbar-navigation-history-' + index} onClick={() => onItemClick(item)} title={appViews.getDisplayName(item.detailAppView, true) + ': ' + item.displayName}>
        {appViews.getIcon(item.detailAppView, appIconSizes.sm)}
        <span style={{ marginLeft: '5px' }}>{item.displayName}</span>
      </DropdownItem>
    );
    return result;
  };

  const toggleDropdownOpen = () => {
    if (!dropdownOpen) {
      const items = navigationHistory.get();
      const newDropdownItems = [];
      if (items.length === 0) {
        newDropdownItems.push(<DropdownItem key="navigation-no-history" disabled>No History</DropdownItem>);
      } else {
        items.map((obj, index) => newDropdownItems.push(renderItem(obj, index, props)));
        newDropdownItems.push(<DropdownItem key="navigation-history-divider" divider />);
        newDropdownItems.push(<DropdownItem key="navigation-history-clear" onClick={onClearClick}>{appIcons.getIcon(appIcons.Clear, appIconSizes.sm)}&nbsp;Clear history</DropdownItem>);
      }
      setDropdownItems(newDropdownItems)
    }
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown nav inNavbar isOpen={dropdownOpen} toggle={toggleDropdownOpen}>
      <DropdownToggle style={{ padding: '2px 0 1px 0', fontSize: '10px', marginTop: '7px', marginRight: '5px' }} nav caret className="hover-spin-icon" title="History">
        {props.showLabels && <span style={{ verticalAlign: 'super', paddingLeft: '5px', paddingRight: '5px' }}>History</span>}
        {appIcons.getIcon(appIcons.History, appIconSizes.md)}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-right">
        {dropdownItems}
      </DropdownMenu>
    </Dropdown>
  );
};

NavigationDropdownComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  showLabels: PropTypes.bool.isRequired,
  right: PropTypes.bool.isRequired
};

NavigationDropdownComponent.defaultProps = {
  right: false
};

export default NavigationDropdownComponent;
