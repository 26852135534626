import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'reactstrap';

import AdvancedKeyFactsComponent from './advanced-key-facts-component';
import AdvancedReservesAndProductionComponent from './advanced-reserves-and-production-component';
import AdvancedCorporateFieldsTableComponent from './advanced-corporate-fields-component';
import AdvancedCorporateCostsComponent from './advanced-corporate-costs-component';
import AdvancedCashflowComponent from './advanced-cashflow-component';
import AdvancedCorporateDiscoveryTableComponent from './advanced-corporate-discoveries-component';
import AdvancedCorporateProspectTableComponent from './advanced-corporate-prospects-component';
import AdvancedCorporateWellComponent from './advanced-corporate-well-component';
import InactiveCorporateWarningComponent from './inactive-corporate-warning-component';
import PriceSettingsComponent from '../../../sharedComponents/price-settings-component';
import AdvancedEmissionsComponent from './advanced-emissions-component';

import { objectToEnum } from '../../../shared/helpers/common';
import { browserIsMicrosoft } from '../../../shared/helpers/browser-detect';
import { ContextLayerModel } from '../../../shared/helpers/leaflet/leaflet-helper';
import { CorporateDetail } from '../../../models/corporate-detail';
import DetailViewGridLayoutComponent from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

import { createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const sections = objectToEnum({
  KeyFacts: 0,
  ReservesAndProduction: 1,
  Fields: 3,
  Costs: 4,
  Cashflow: 5,
  Discoveries: 6,
  Prospects: 7,
  Wells: 8,
  Emissions: 9
}, false);

sections.getDisplayName = (value) => {
  switch (value) {
    case sections.KeyFacts:
      return 'Key Facts';
    case sections.ReservesAndProduction:
      return 'Reserves & Production';
    case sections.Fields:
      return 'Fields';
    case sections.Costs:
      return 'Costs';
    case sections.Cashflow:
      return 'Cashflow';
    case sections.Discoveries:
      return 'Discoveries';
    case sections.Prospects:
      return 'Prospects';
    case sections.Wells:
      return 'Wells';
    case sections.Emissions:
      return 'Emissions';
    default:
      throw new Error('Invalid "value" param supplied to "sections.getDisplayName"');
  }
};

Object.freeze(sections);

class AdvancedCorporateComponent extends React.Component {
  constructor(props) {
    super(props);

    this._scrollOffset = browserIsMicrosoft() ? 10 : 5;
    this.handleContentScroll = this.handleContentScroll.bind(this);
    this.contentRef = React.createRef();
    this.toolbarRef = React.createRef();
    this.filterPanelRef = React.createRef();
    this.bannerRef = React.createRef();
    this.keyFactsRef = React.createRef();
    this.reservesAndProductionRef = React.createRef();
    this.fieldsRef = React.createRef();
    this.costsRef = React.createRef();
    this.cashflowRef = React.createRef();
    this.discoveriesRef = React.createRef();
    this.prospectsRef = React.createRef();
    this.wellsRef = React.createRef();
    this.emissionsRef = React.createRef();
    this.state = { selectedSection: null };
  }

  componentDidMount() {
    this.contentRef.current.addEventListener('scroll', this.handleContentScroll, true);
    this.handleContentScroll();
  }

  componentWillUnmount() {
    this.contentRef.current.removeEventListener('scroll', this.handleContentScroll);
  }

  getSectionInView() {
    const bottom = this.bannerRef.current.getBoundingClientRect().bottom + this._scrollOffset;

    let section = sections.Emissions;
    let sectionTop = this.getSectionTop(section);
    if (sectionTop !== null && sectionTop <= bottom) {
      return section;
    }

    //fix for when the bottom section is not big enough to fill the panel
    var detailViewPanel = document.getElementById('detailViewGrid');
    if((detailViewPanel.offsetHeight + detailViewPanel.scrollTop) >= detailViewPanel.scrollHeight) {
      return section;
    }

    section = sections.Wells;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.Prospects;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.Discoveries;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.Cashflow;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.Costs;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.Fields;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.ReservesAndProduction;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    return sections.KeyFacts;
  }

  getRef(section) {
    switch (section) {
      case sections.KeyFacts:
        return this.keyFactsRef;
      case sections.ReservesAndProduction:
        return this.reservesAndProductionRef;
      case sections.Fields:
        return this.fieldsRef;
      case sections.Costs:
        return this.costsRef;
      case sections.Cashflow:
        return this.cashflowRef;
      case sections.Discoveries:
        return this.discoveriesRef;
      case sections.Prospects:
        return this.prospectsRef;
      case sections.Wells:
        return this.wellsRef;
      case sections.Emissions:
        return this.emissionsRef;
      default:
        throw new Error('Invalid "section" param supplied to "AdvancedCorporateComponent.getRef"');
    }
  }

  getSectionTop(section) {
    if (this.getRef(section) === (undefined || null) || this.getRef(section).current === (undefined || null)) return 0;
    const sectionRect = this.getRef(section).current.getBoundingClientRect();
    const result = sectionRect.top;
    return result;
  }

  handleContentScroll() {
    const sectionInView = this.getSectionInView();
    this.setState(prevState => {
      return prevState.selectedSection === sectionInView
        ? null
        : { selectedSection: sectionInView };
    })
  }

  btnClick(section) {
    this.getRef(section).current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    //this.setState({ selectedSection: section });
  }

  getSectionButton(section) {
    const { selectedSection } = this.state;

    const result = (
      <Button key={'section-btn-' + section} active={section === selectedSection} style={{ marginRight: '3px', borderRadius: '0px', border: 'none' }} onClick={() => this.btnClick(section)}>
        {sections.getDisplayName(section)}
      </Button>
    );
    return result;
  }

  getSection(section) {
    const props = {
      key: 'section-' + section,
      innerRef: this.getRef(section),
      corporate: this.props.corporate
    };

    switch (section) {
      case sections.KeyFacts:
        return <AdvancedKeyFactsComponent {...props} geoserverUrl={this.props.geoserverUrl} contextLayers={this.props.contextLayers} filtered={this.props.filtered} toggleFiltered={this.props.toggleFiltered} />;
      case sections.ReservesAndProduction:
        return <AdvancedReservesAndProductionComponent {...props} />;
      case sections.Fields:
        return <AdvancedCorporateFieldsTableComponent {...props} onFieldClick={this.props.onFieldClick} onHubClick={this.props.onHubClick} onOperatorClick={this.props.onCorporateClick} />;
      case sections.Costs:
        return <AdvancedCorporateCostsComponent {...props} />;
      case sections.Cashflow:
        return <AdvancedCashflowComponent {...props} />;
      case sections.Discoveries:
        return <AdvancedCorporateDiscoveryTableComponent {...props}
          onDiscoveryClick={this.props.onDiscoveryClick}
          onLicenceClick={this.props.onLicenceClick}
          onBlockClick={this.props.onBlockClick}
          onWellClick={this.props.onWellClick}
          onOperatorClick={this.props.onCorporateClick} />;
      case sections.Prospects:
        return <AdvancedCorporateProspectTableComponent {...props}
          onProspectClick={this.props.onProspectClick}
          onLicenceClick={this.props.onLicenceClick}
          onBlockClick={this.props.onBlockClick}
          onWellClick={this.props.onWellClick}
          onOperatorClick={this.props.onCorporateClick} />;
      case sections.Wells:
        return <AdvancedCorporateWellComponent {...props}
          onWellClick={this.props.onWellClick}
          onReportClick={this.props.onReportClick}
          onLicenceClick={this.props.onLicenceClick}
          onOperatorClick={this.props.onCorporateClick}
          onProspectClick={this.props.onProspectClick}
          onDiscoveryClick={this.props.onDiscoveryClick}
          onFieldClick={this.props.onFieldClick}
        />;
      case sections.Emissions:
        return <AdvancedEmissionsComponent {...props} fieldEmissions={props.corporate.chartDataCollection.emissionsByField} hubEmissions={props.corporate.chartDataCollection.emissionsByHub} />;

      default:
        throw new Error('Invalid "section" param supplied to "AdvancedCorporateComponent.getSection"');
    }
  }

  _updateSettings = () => {
    this.props.closeSettings();
    this.props.update(this.props.appSetState, this.props.corporate.corporateId, this.props.filter);
  };

  render() {
    const { isActive } = this.props.corporate;
    const { corporate, filtered, toggleFiltered, filterAvailable } = this.props;

    const newSection = this.props.showSettings
      ? <PriceSettingsComponent appSetState={this.props.appSetState} isDetail isCorporate isCorporateLarge={filterAvailable} onUpdate={this._updateSettings} advancedPlus={this.props.advancedPlus} />
      : null;

    let tmpSections = [sections.Wells];
    if (isActive === true) {
      tmpSections = sections.getValues();
    }

    let toolbar = null;
    if (tmpSections.length > 1) {
      let buttonTop = filterAvailable === true ? '19px' : '4px';
      toolbar = (
        <div ref={this.toolbarRef} style={{ height: '39px', textAlign: 'center' }}>
          <ButtonGroup style={{ top: buttonTop, float: 'left', width: '90%' }}>
            {tmpSections.map(obj => this.getSectionButton(obj))}
          </ButtonGroup>
        </div>
      );
    }

    let filterPanel = null;
    if (filterAvailable === true) {
      let currentText = '';
      let alternateText = '';
      if (filtered === true) {
        currentText = 'filtered';
        alternateText = 'full';
      }
      else {
        currentText = 'full';
        alternateText = 'filtered';
      }
      filterPanel = (
        <div ref={this.filterPanelRef} style={{ textAlign: 'center', float: 'left', position: 'relative', marginLeft: '2px', top:'5px' }}>
          {createSection100(<div style={{ fontWeight: 'bold', fontSize: '16px' }}>You are viewing the {currentText} portfolio for {corporate.corporateName}. To view the {alternateText} portfolio <a href="/" title="'Show Full Portfolio'" onClick={(e) => toggleFiltered(e)}>click here</a>, or the filter button at the top of the screen.</div>)}
        </div>
      );
    }

    let heightSubtract = null;
    if (toolbar === null && filterPanel === null) { heightSubtract = null; }
    if (toolbar === null && filterPanel !== null) { heightSubtract = 59; } //39
    if (toolbar !== null && filterPanel === null) { heightSubtract = 59; } //39
    if (toolbar !== null && filterPanel !== null) { heightSubtract = 98; } //78

    return (
      <div style={{ height: '100%' }}>
        {newSection}
        <div ref={this.bannerRef} style={{ height: heightSubtract, padding: '5px' }}>
          <div style={{ fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'left', position: 'relative', float: 'left', width: '90%', marginLeft: '2px' }} className="valuation-banner">
            Valuation Settings:
            <small style={{ marginLeft: '2px', textAlign: 'left', position: 'relative', fontSize: '1.25rem' }}><a alt="" onClick={() => this.props.openSettings()} title="Show Valuations"> {this.props.currentDetailValuationName}</a></small>
          </div>
          {filterPanel}
          {toolbar}
        </div>
        <div ref={this.contentRef} style={{ height: heightSubtract === null ? undefined : 'calc(100% - ' + heightSubtract + 'px)', overflow: 'auto', width: '100%' }}>
          <DetailViewGridLayoutComponent>
            {isActive === true ? null : createSection100(<InactiveCorporateWarningComponent />)}
            {tmpSections.map(obj => this.getSection(obj))}
          </DetailViewGridLayoutComponent>
        </div>
      </div>
    );
  }
}

AdvancedCorporateComponent.propTypes = {
  corporate: PropTypes.instanceOf(CorporateDetail).isRequired,
  onFieldClick: PropTypes.func,
  onHubClick: PropTypes.func,
  onDiscoveryClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onWellClick: PropTypes.func,
  onProspectClick: PropTypes.func,
  onReportClick: PropTypes.func,
  onCorporateClick: PropTypes.func,
  geoserverUrl: PropTypes.string.isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
  filtered: PropTypes.bool.isRequired,
  filterAvailable: PropTypes.bool.isRequired,
  toggleFiltered: PropTypes.func
};

export default AdvancedCorporateComponent;
