import { AppSetState } from "../../app/app-set-state";

import { BaseExcelExportColumn, BaseExcelExport } from "../../shared/helpers/base-excel-export";
import { valueTypes } from '../../shared/helpers/value-handler';
import { UserValuations } from '../../models/user-valuations';

class CorporateGridExcelExport {
  combineCashflowAnnuals(dataRows) {
    return [].concat(dataRows.filter(x => x.corporateCashflow !== null).map(x => x.corporateCashflow.cashflowAnnuals)).flat();
  }

  export(appSetState, dataRows, mapBaseExcelExport = null, mapWorkbook = null, sheetname = null, selectedValuation = null, baseValuation = null) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "CorporateGridExcelExport.export"');
    }
    if (selectedValuation !== null && !(selectedValuation instanceof UserValuations)) {
      throw new Error('Invalid "selectedValuation" param supplied to "FiscalBulkResourceExcelExport.export"');
    }
    if (baseValuation !== null && !(baseValuation instanceof UserValuations)) {
      throw new Error('Invalid "selectedValuation" param supplied to "FiscalBulkResourceExcelExport.export"');
    }

    const baseExcelExport = mapBaseExcelExport !== null ? mapBaseExcelExport : new BaseExcelExport();
    const workbook = mapWorkbook !== null ? mapWorkbook : baseExcelExport.createWorkbook();
    const corporateSheet = 'Company details';  //sheetname !== null ? sheetname : appViews.getDisplayName(appViews.Corporate);
    const cashflowSheet = 'Company annual cashflows';

    this.exportCorporates(workbook, dataRows, corporateSheet, baseExcelExport);
    this.exportCashflowAnnuals(workbook, dataRows, cashflowSheet, baseExcelExport);

    if (mapBaseExcelExport === null) baseExcelExport.writeWorkbook(workbook, baseExcelExport.getAtlas5WorkbookPrefix() + corporateSheet + baseExcelExport.getAtlas5WorkbookSuffix(), null, selectedValuation, baseValuation);
  }

  exportCorporates(workbook, dataRows, sheetName, baseExcelExport) {

    const colDefs = [];

    colDefs.push(new BaseExcelExportColumn("Company Name", 'name'));
    colDefs.push(new BaseExcelExportColumn("Country(s)", "country"));
    colDefs.push(new BaseExcelExportColumn("Area(s)", "areaList"));
    colDefs.push(new BaseExcelExportColumn("Company Category", "corporateCategory"));
    colDefs.push(new BaseExcelExportColumn("Net Remaining Liquid Reserves (" + valueTypes.UnitString(valueTypes.mmb) + ")", "netRemainingLiquidMMB", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Remaining Gas Reserves (" + valueTypes.UnitString(valueTypes.bcf) + ")", "netRemainingGasBCF", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Remaining Reserves (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "netRemainingReservesMMBOE", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Current Year Production (" + valueTypes.UnitString(valueTypes.mboed) + ")", 'netCurrentYearProductionBOEPD', '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Current Year Liquid Production (" + valueTypes.UnitString(valueTypes.mboed) + ")", 'netCurrentYearLiquidProductionBOEPD', '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Current Year Gas Production (" + valueTypes.UnitString(valueTypes.mmscfd) + ")", 'netCurrentYearGasProductionMMSCFD', '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Current Year Equity Based CO2 Emissions (" + valueTypes.UnitString(valueTypes.ktCO2) + ")", 'netCurrentYearEquityCO2EmissionsKTCO2', '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Current Year Operational CO2 Emissions (" + valueTypes.UnitString(valueTypes.ktCO2) + ")", 'netCurrentYearOperationalCO2EmissionsKTCO2', '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Remaining Revenue (" + valueTypes.UnitString(valueTypes.usdMM) + ")", 'netRemainingRevenueUSDMM', '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Remaining Pre-Tax Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", "netRemainingPreTaxCashflowUSDMM", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Remaining Capex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", "netRemainingCapexUSDMM", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Current Year Opex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", "netCurrentYearOpexUSDMM", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Current Year Opex/boe (" + valueTypes.UnitString(valueTypes.usdBOE) + ")", "netCurrentYearOpexPerBoe", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Remaining Opex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", "netRemainingOpexUSDMM", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Remaining Opex/boe (" + valueTypes.UnitString(valueTypes.usdBOE) + ")", "netRemainingOpexPerBoe", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Remaining Abex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", "netRemainingAbexUSDMM", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Number of Producing Fields", "producingFields"));
    colDefs.push(new BaseExcelExportColumn("Net Remaining Reserves in Producing Fields (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "netRemainingReservesInProducingFieldsMMBOE", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Number of Under Development Fields", "underDevelopmentFields"));
    colDefs.push(new BaseExcelExportColumn("Net Remaining Reserves in Under Development Fields (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "netRemainingReservesInUnderDevelopmentFieldsMMBOE", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Number of Near Term Developments", "nearTermFields"));
    colDefs.push(new BaseExcelExportColumn("Net Remaining Reserves in Near Term Developments (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "netRemainingReservesInNearTermFieldsMMBOE", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Number of Other Fields", "otherFields"));
    colDefs.push(new BaseExcelExportColumn("Percent Operated Fields", "percentOperatedFields"));
    colDefs.push(new BaseExcelExportColumn("Number of Undeveloped Discoveries", "discoveries"));
    colDefs.push(new BaseExcelExportColumn("Net Resources in Undeveloped Discoveries (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "discoveryResourcesMMBOE", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Number of Undeveloped Prospects", "prospects"));
    colDefs.push(new BaseExcelExportColumn("Net Resources in Undeveloped Prospects (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "prospectsResourcesMMBOE", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Gross Area (" + valueTypes.UnitString(valueTypes.kmsq) + ")", "grossAreaSQKM", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Net Area (" + valueTypes.UnitString(valueTypes.kmsq) + ")", "netAreaSQKM", '0.00'));

    baseExcelExport.writeSheet(workbook, sheetName, dataRows, colDefs);
  }

  exportCashflowAnnuals(workbook, dataRows, sheetName, baseExcelExport) {
    const usdMM = valueTypes.UnitString(valueTypes.usdMM);

    const columns = [
      new BaseExcelExportColumn('Company', 'resourceName'),
      new BaseExcelExportColumn('Year', 'year'),
      new BaseExcelExportColumn('Liquid Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'liquidProductionMbod', '0.00'),
      new BaseExcelExportColumn('Gas Production (' + valueTypes.UnitString(valueTypes.mmscfd) + ')', 'gasProductionMmscfd', '0.00'),
      new BaseExcelExportColumn('Total Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'combinedMboed', '0.00'),
      new BaseExcelExportColumn('Revenue (' + usdMM + ')', 'totalRevenueUsdMM', '0.00'),
      new BaseExcelExportColumn('Opex (' + usdMM + ')', 'opexUsdMM', '0.00'),
      new BaseExcelExportColumn('Emissions charge (' + usdMM + ')', 'totalEmissionsChargeUsdMM', '0.00'),
      new BaseExcelExportColumn('Export tariff (' + usdMM + ')', 'totalTariffUsdMM', '0.00'),
      new BaseExcelExportColumn('Capex (' + usdMM + ')', 'costForLossesOrDepreciationUsdMM', '0.00'),
      new BaseExcelExportColumn('Abex (' + usdMM + ')', 'abexUsdMM', '0.00'),
      new BaseExcelExportColumn('Pre-tax cashflow (' + usdMM + ')', 'preTaxCashflowUsdMM', '0.00'),
    ];

    const cashflowRows = this.combineCashflowAnnuals(dataRows);

    baseExcelExport.writeSheet(workbook, sheetName, cashflowRows, columns);
  }
}

export { CorporateGridExcelExport };
