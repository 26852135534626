import React from 'react';
import PropTypes from 'prop-types';

import CorporateDetailGridComponent, { createLink, HEADER_ROW_QUAD, NUMERIC_SORT } from '../corporate-detail-grid-component';

import { valueTypes } from '../../../../shared/helpers/value-handler';
import { NetProspect } from '../../../../models/corporate-detail';

const CorporateProspectTableComponent = (props) => {
  const { onProspectClick, onLicenceClick, onBlockClick, onOperatorClick } = props;

  const prospectIdRenderer = (params) => {
    let result = params.data.prospectId;
    if (typeof onProspectClick === 'function' && typeof params.data.resourceId === 'number') {
      result = createLink(result, 'Show Details...', () => onProspectClick(params.data.resourceId));
    }
    return result;
  };
  const licenceRenderer = (params) => {
    let result = params.data.licence;
    if (typeof onLicenceClick === 'function' && typeof params.data.licenceId === 'number') {
      result = createLink(result, 'Show Details...', () => onLicenceClick(params.data.licenceId));
    }
    return result;
  };
  const blockRenderer = (params) => {
    let result = params.data.block;
    if (typeof onBlockClick === 'function' && typeof params.data.blockId === 'number') {
      result = createLink(result, 'Show Details...', () => onBlockClick(params.data.blockId));
    }
    return result;
  };
  const operatorRenderer = (params) => {
    let result = params.data.operator;
    // params.data.operatorId === params.currentCorporateId means we're currently showing that company, so no point having a link
    if (typeof onOperatorClick === 'function' && typeof params.data.operatorId === 'number' && params.data.operatorId !== params.currentCorporateId) {
      result = createLink(result, 'Show Details...', () => onOperatorClick(params.data.operatorId));
    }
    return result;
  };
  const equityRenderer = (params) => valueTypes.ValueOnly(valueTypes.equity, params.data.equity);
  const netLiquidMmbRenderer = (params) => valueTypes.ValueOnly(valueTypes.mmmboe, params.data.netLiquidMmb);
  const netGasBcfRenderer = (params) => valueTypes.ValueOnly(valueTypes.bcf, params.data.netGasBcf);
  const netCombinedMmboeRenderer = (params) => valueTypes.ValueOnly(valueTypes.mmmboe, params.data.netCombinedMmboe);

  const columnDefs = [
    {
      headerName: 'Prospect ID',
      field: 'prospectId',
      minWidth: 110,
      cellRenderer: prospectIdRenderer
    },
    {
      headerName: 'Prospect Name',
      field: 'prospectName',
      minWidth: 195
    },
    {
      headerName: 'Area',
      field: 'area',
      minWidth: 100
    },
    {
      headerName: 'Licence',
      field: 'licence',
      minWidth: 85,
      cellRenderer: licenceRenderer
    },
    {
      headerName: 'Block',
      field: 'block',
      minWidth: 100,
      cellRenderer: blockRenderer
    },
    {
      headerName: 'Operator',
      field: 'operator',
      minWidth: 120,
      cellRenderer: operatorRenderer,
      cellRendererParams: {
        currentCorporateId: props.currentCorporateId
      }
    },
    {
      headerName: 'Equity (' + valueTypes.UnitString(valueTypes.percent) + ')',
      field: 'equity',
      minWidth: 85,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: equityRenderer
    },
    {
      headerName: 'Net Unrisked\nLiquid Resources\n(' + valueTypes.UnitString(valueTypes.mmmboe) + ')',
      field: 'netLiquidMmb',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: netLiquidMmbRenderer
    },
    {
      headerName: 'Net Unrisked\nGas Resources\n(' + valueTypes.UnitString(valueTypes.bcf) + ')',
      field: 'netGasBcf',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: netGasBcfRenderer
    },
    {
      headerName: 'Net Unrisked\nResources\n(' + valueTypes.UnitString(valueTypes.mmmboe) + ')',
      field: 'netCombinedMmboe',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: netCombinedMmboeRenderer
    },
    {
      headerName: 'Reservoir Age',
      field: 'reservoirAge',
      minWidth: 150
    }
  ];

  return <CorporateDetailGridComponent columnDefs={columnDefs} dataRows={props.netProspects} headerRowHeight={HEADER_ROW_QUAD} />;
};

CorporateProspectTableComponent.propTypes = {
  currentCorporateId: PropTypes.number.isRequired,
  netProspects: PropTypes.arrayOf(PropTypes.instanceOf(NetProspect)).isRequired,
  onProspectClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default CorporateProspectTableComponent;
