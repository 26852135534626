import { BaseFilter } from "../base/base-filter";

class ProspectFilter extends BaseFilter {
  constructor() {
    super();

    this._prospectIds = undefined;
    this._prospectNames = undefined;
    this._countryIds = undefined;
    this._areaIds = undefined;
    this._licenceIds = undefined;
    this._quadrantIds = undefined;
    this._blockIds = undefined;
    this._operatorIds = undefined;
    this._participantIds = undefined;
    this._minCombinedMmboe = undefined;
    this._maxCombinedMmboe = undefined;
    this._reservoirAgeIds = undefined;
    this.clear();
  }

  get isSet() {
    const result = super.isSet === true ||
      this.prospectIdsIsSet === true ||
      this.prospectNamesIsSet === true ||
      this.countryIdsIsSet === true ||
      this.areaIdsIsSet === true ||
      this.licenceIdsIsSet === true ||
      this.quadrantIdsIsSet === true ||
      this.blockIdsIsSet === true ||
      this.operatorIdsIsSet === true ||
      this.participantIdsIsSet === true ||
      this.minCombinedMmboeIsSet === true ||
      this.maxCombinedMmboeIsSet === true ||
      this.reservoirAgeIdsIsSet === true ||
      this.licenceStatusIsSet === true;
    return result;
  }

  get prospectIds() { return this._prospectIds; }
  set prospectIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "ProspectFilter.prospectIds.set"');
    }

    if (this._prospectIds !== value) {
      this._prospectIds = value;
    }
  }
  get prospectIdsIsSet() { return this._prospectIds.length > 0; }

  get prospectNames() { return this._prospectNames; }
  set prospectNames(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'string').length > 0) {
      throw new Error('Invalid "value" param supplied to "ProspectFilter.prospectNames.set"');
    }

    if (this._prospectNames !== value) {
      this._prospectNames = value;
    }
  }
  get prospectNamesIsSet() { return this._prospectNames.length > 0; }

  get countryIds() { return this._countryIds; }
  set countryIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "ProspectFilter.countryIds.set"');
    }

    if (this._countryIds !== value) {
      this._countryIds = value;
    }
  }
  get countryIdsIsSet() { return this._countryIds.length > 0; }

  get areaIds() { return this._areaIds; }
  set areaIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "ProspectFilter.areaIds.set"');
    }

    if (this._areaIds !== value) {
      this._areaIds = value;
    }
  }
  get areaIdsIsSet() { return this._areaIds.length > 0; }

  get licenceIds() { return this._licenceIds; }
  set licenceIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "ProspectFilter.licenceIds.set"');
    }

    if (this._licenceIds !== value) {
      this._licenceIds = value;
    }
  }
  get licenceIdsIsSet() { return this._licenceIds.length > 0; }

  get quadrantIds() { return this._quadrantIds; }
  set quadrantIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'string').length > 0) {
      throw new Error('Invalid "value" param supplied to "ProspectFilter.quadrantIds.set"');
    }

    if (this._quadrantIds !== value) {
      this._quadrantIds = value;
    }
  }
  get quadrantIdsIsSet() { return this._quadrantIds.length > 0; }

  get blockIds() { return this._blockIds; }
  set blockIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "ProspectFilter.blockIds.set"');
    }

    if (this._blockIds !== value) {
      this._blockIds = value;
    }
  }
  get blockIdsIsSet() { return this._blockIds.length > 0; }

  get operatorIds() { return this._operatorIds; }
  set operatorIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "ProspectFilter.operatorIds.set"');
    }

    if (this._operatorIds !== value) {
      this._operatorIds = value;
    }
  }
  get operatorIdsIsSet() { return this._operatorIds.length > 0; }

  get participantIds() { return this._participantIds; }
  set participantIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "ProspectFilter.participantIds.set"');
    }

    if (this._participantIds !== value) {
      this._participantIds = value;
    }
  }
  get participantIdsIsSet() { return this._participantIds.length > 0; }

  get minCombinedMmboe() { return this._minCombinedMmboe; }
  set minCombinedMmboe(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "ProspectFilter.minCombinedMmboe.set"');
    }

    if (this._minCombinedMmboe !== value) {
      this._minCombinedMmboe = value;
    }
  }
  get minCombinedMmboeIsSet() { return this._minCombinedMmboe !== null; }

  get maxCombinedMmboe() { return this._maxCombinedMmboe; }
  set maxCombinedMmboe(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "ProspectFilter.maxCombinedMmboe.set"');
    }

    if (this._maxCombinedMmboe !== value) {
      this._maxCombinedMmboe = value;
    }
  }
  get maxCombinedMmboeIsSet() { return this._maxCombinedMmboe !== null; }

  get reservoirAgeIds() { return this._reservoirAgeIds; }
  set reservoirAgeIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "ProspectFilter.reservoirAgeIds.set"');
    }

    if (this._reservoirAgeIds !== value) {
      this._reservoirAgeIds = value;
    }
  }
  get reservoirAgeIdsIsSet() { return this._reservoirAgeIds.length > 0; }

  get licenceStatus() { return this._licenceStatus; }
  set licenceStatus(key) {
    if (key !== null && typeof key !== 'number') {
      throw new Error('Invalid "value" param supplied to "FieldFilter.licenceStatus.set"');
    }

    if (this._licenceStatus !== key) {
      this._licenceStatus = key;
    }
  }
  get licenceStatusIsSet() { return this._licenceStatus !== 0; }

  clear() {
    super.clear();

    this._prospectIds = [];
    this._prospectNames = [];
    this._countryIds = [];
    this._areaIds = [];
    this._licenceIds = [];
    this._quadrantIds = [];
    this._blockIds = [];
    this._operatorIds = [];
    this._participantIds = [];
    this._minCombinedMmboe = null;
    this._maxCombinedMmboe = null;
    this._reservoirAgeIds = [];
    this._licenceStatus = 0;
  }

  clone() {
    const result = new ProspectFilter();
    super.clone(result);
    result._prospectIds = [...this._prospectIds];
    result._prospectNames = [...this._prospectNames];
    result._countryIds = [...this._countryIds];
    result._areaIds = [...this._areaIds];
    result._licenceIds = [...this._licenceIds];
    result._quadrantIds = [...this._quadrantIds];
    result._blockIds = [...this._blockIds];
    result._operatorIds = [...this._operatorIds];
    result._participantIds = [...this._participantIds];
    result._minCombinedMmboe = this._minCombinedMmboe;
    result._maxCombinedMmboe = this._maxCombinedMmboe;
    result._reservoirAgeIds = this._reservoirAgeIds;
    result._licenceStatus = this._licenceStatus;
    return result;
  }

  clearInvalidValues() {
    if (this.minCombinedMmboeIsSet && this.maxCombinedMmboeIsSet && this._minCombinedMmboe > this._maxCombinedMmboe) {
      this.minCombinedMmboe = null;
      this.maxCombinedMmboe = null;
    }
  }

  toApiParam() {
    const result = super.toApiParam();
    result.prospectIds = this._prospectIds;
    result.prospectNames = this._prospectNames;
    result.countryIds = this._countryIds;
    result.areaIds = this._areaIds;
    result.licenceIds = this._licenceIds;
    result.quadrantIds = this._quadrantIds;
    result.blockIds = this._blockIds;
    result.operatorIds = this._operatorIds;
    result.participantIds = this._participantIds;
    result.minCombinedMmboe = this._minCombinedMmboe;
    result.maxCombinedMmboe = this._maxCombinedMmboe;
    result.reservoirAgeIds = this._reservoirAgeIds;
    result.licenceStatus = this._licenceStatus;
    return result;
  }
}

export { ProspectFilter };
