class KeyValuePair {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Supplied \'obj\' param to \'KeyValuePair.ctor\' is invalid');
    }
    if (typeof obj.key !== 'number' && typeof value !== 'boolean' && typeof obj.key !== 'string') {
      throw new Error('Supplied \'obj.key\' param to \'KeyValuePair.ctor\' is invalid');
    }
    if (typeof obj.value !== 'string') {
      throw new Error('Supplied \'obj.value\' param to \'KeyValuePair.ctor\' is invalid');
    }

    this.key = obj.key;
    this.value = obj.value;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "KeyValuePair.FromArray"');
    }

    const result = array.map(obj => new KeyValuePair(obj));
    return result;
  }
}

export { KeyValuePair };