import L from 'leaflet';
import { faMinusSquare, faPlusSquare, faTimesSquare, faFileExcel } from '@fortawesome/pro-light-svg-icons';

import './search-results-control.scss';
import { ResultGroup } from './result-group';
import { ContextLayerModel, layerType } from '../../models/context-layer-model';
import { SearchResultModel, searchResultType } from '../../models/search-result-model';
import { getObjectClassName, getIcon, addEventHandler, getMapEvents, disableMapEvents, restoreMapEvents } from '../../helpers/control-helper';

L.Control.SearchResultsControl = L.Control.extend({
  initialize: function (options) { this.options = options; },
  onAdd: function () { return this.options.container; }
});

const DEFAULT_MENU_CONTROL_OPTIONS = {
  position: 'topright',
  visible: false,
  collapsed: false
};
const HIDDEN_CLASS_NAME = 'hidden';
const VISIBLE_CLASS_NAME = 'visible';
const COLLAPSED_CLASS_NAME = 'collapsed';
const EXPANDED_CLASS_NAME = 'expanded';
const NO_RESULTS_CLASS_NAME = 'no-results';
const HAS_RESULTS_CLASS_NAME = 'has-results';

class SearchResultsControl {
  constructor(leafletMap, contextLayers, excelExport = null, options = {}) {

    this._objectClassName = getObjectClassName(this);

    if (!(leafletMap instanceof L.Map)) {
      throw new Error('Invalid "leafletMap" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (!Array.isArray(contextLayers) || contextLayers.filter(obj => !(obj instanceof ContextLayerModel)).length > 1) {
      throw new Error('Invalid "contextLayers" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (typeof options !== 'object') {
      throw new Error('Invalid "options" param supplied to "' + this._objectClassName + '.ctor"');
    }
    options = { ...DEFAULT_MENU_CONTROL_OPTIONS, ...options };
    if (typeof options.position !== 'string') {
      throw new Error('Invalid "options.position" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (typeof options.visible !== 'boolean') {
      throw new Error('Invalid "options.visible" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (typeof options.collapsed !== 'boolean') {
      throw new Error('Invalid "options.collapsed" param supplied to "' + this._objectClassName + '.ctor"');
    }

    this._excelExport = excelExport;

    this._mapEvents = null;
    contextLayers = contextLayers.sort(this._contextLayersSort);

    // Container
    this._container = L.DomUtil.create('div', 'leaflet-bar search-results-control ' +
      (options.visible ? VISIBLE_CLASS_NAME : HIDDEN_CLASS_NAME) + ' ' + (options.collapsed ? COLLAPSED_CLASS_NAME : EXPANDED_CLASS_NAME));
    L.DomEvent.on(this._container, 'mouseenter', this._disableMapEvents, this);
    L.DomEvent.on(this._container, 'mouseleave', this._enableMapEvents, this);
    // Header
    const header = L.DomUtil.create('div', 'header', this._container);
    L.DomUtil.create('span', '', header).innerHTML = 'Search results';
    const closeButton = L.DomUtil.create('span', 'btn-close', header);
    closeButton.innerHTML = getIcon(faTimesSquare, { title: 'Close' });
    addEventHandler(closeButton, 'click', this.close);
    this._collapseButton = L.DomUtil.create('span', 'btn-collapse', header);
    addEventHandler(this._collapseButton, 'click', () => this.collapsed = !this.collapsed);
    this._updateCollapseButton();

    //excel export button
    if (typeof this._excelExport === 'function' && this._excelExport !== undefined && this._excelExport !== null) {
      const excelExportButton = L.DomUtil.create('span', 'btn-excel-export', header);
      excelExportButton.innerHTML = getIcon(faFileExcel, { title: 'Export to Excel' });
      addEventHandler(excelExportButton, 'click', this.excelExport);
    }

    // Body
    this._body = L.DomUtil.create('div', 'body ' + NO_RESULTS_CLASS_NAME, this._container);
    const noResultsDiv = L.DomUtil.create('div', 'no-results-div', this._body);
    noResultsDiv.innerHTML = 'No results found';
    const resultsDiv = L.DomUtil.create('div', 'results-div', this._body);

    this._onClose = null;
    this._onResultLabelClick = null;
    this._onResultIconClick = null;
    this._licenceResultGroups = contextLayers.filter(obj => obj.layerType === layerType.Blocks)
      .map(obj => new ResultGroup(obj.contextLayerId, obj.groupDisplayName, 'Licences', resultsDiv, this._intOnResultLabelClick, this._intOnResultIconClick));
    this._corporateResultGroup = new ResultGroup(-1, '', 'Companies', resultsDiv, this._intOnResultLabelClick, this._intOnResultIconClick);
    this._resultGroups = contextLayers.map(obj => ResultGroup.FromContextLayerModel(obj, resultsDiv, this._intOnResultLabelClick, this._intOnResultIconClick));
    this._leafletMap = leafletMap;
    this._canScrollWheelZoom = this._leafletMap.scrollWheelZoom._enabled === true;

    this._leafletMap.addControl(new L.Control.SearchResultsControl({ position: options.position, container: this._container }))
  }

  get visible() {
    const result = L.DomUtil.hasClass(this._container, VISIBLE_CLASS_NAME);
    return result;
  }
  set visible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "' + this._objectClassName + '.visible.set"');
    }

    if (this.visible !== value) {
      if (value) {
        L.DomUtil.addClass(this._container, VISIBLE_CLASS_NAME);
        L.DomUtil.removeClass(this._container, HIDDEN_CLASS_NAME);
      } else {
        L.DomUtil.addClass(this._container, HIDDEN_CLASS_NAME);
        L.DomUtil.removeClass(this._container, VISIBLE_CLASS_NAME);
      }
    }
  }

  get collapsed() {
    const result = L.DomUtil.hasClass(this._container, COLLAPSED_CLASS_NAME);
    return result;
  }
  set collapsed(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "' + this._objectClassName + '.collapsed.set"');
    }

    if (this.collapsed !== value) {
      if (value) {
        L.DomUtil.addClass(this._container, COLLAPSED_CLASS_NAME);
        L.DomUtil.removeClass(this._container, EXPANDED_CLASS_NAME);
      } else {
        L.DomUtil.addClass(this._container, EXPANDED_CLASS_NAME);
        L.DomUtil.removeClass(this._container, COLLAPSED_CLASS_NAME);
      }
      this._updateCollapseButton();
    }
  }

  get onClose() { return this._onClose; }
  set onClose(value) {
    if (this._onClose !== value) {
      this._onClose = value;
    }
  }

  get onResultLabelClick() { return this._onResultLabelClick; }
  set onResultLabelClick(value) {
    if (this._onResultLabelClick !== value) {
      this._onResultLabelClick = value;
    }
  }

  get onResultIconClick() { return this._onResultIconClick; }
  set onResultIconClick(value) {
    if (this._onResultIconClick !== value) {
      this._onResultIconClick = value;
    }
  }

  update(searchResults) {
    if (!Array.isArray(searchResults) || searchResults.filter(obj => !(obj instanceof SearchResultModel)).length > 0) {
      throw new Error('Invalid "searchResults" supplied to "SearchResultsControl.update"');
    }

    if (searchResults.length === 0) {
      L.DomUtil.addClass(this._body, NO_RESULTS_CLASS_NAME);
      L.DomUtil.removeClass(this._body, HAS_RESULTS_CLASS_NAME);
      this._body.style.maxHeight = undefined;
    } else {
      L.DomUtil.addClass(this._body, HAS_RESULTS_CLASS_NAME);
      L.DomUtil.removeClass(this._body, NO_RESULTS_CLASS_NAME);
      this._body.style.maxHeight = (this._leafletMap._size.y * 0.75) + 'px';
    }

    this._licenceResultGroups.forEach(rg => rg.update(searchResults.filter(obj => obj.type === searchResultType.Licence && obj.contextLayerId === rg.contextLayerId)));
    this._corporateResultGroup.update(searchResults.filter(obj => obj.type === searchResultType.Corporate));
    this._resultGroups.forEach(rg => rg.update(searchResults.filter(obj => obj.type !== searchResultType.Licence && obj.type !== searchResultType.Corporate && obj.contextLayerId === rg.contextLayerId)));
    this._searchResults = searchResults;
    this.collapsed = false;
    this.visible = true;
  }

  _contextLayersSort(contextLayerA, contextLayerB) {
    const getContextLayerOrder = (contextLayer) => {
      let result = 0;      
      switch (contextLayer.layerDisplayName) {
        case 'Fields': result = 20; break;
        case 'Discoveries': result = 40; break;
        case 'Prospects': result = 50; break;
        case 'Historic Wells': result = 70; break;
        case 'Recent Wells': result = 60; break;
        case 'Planned Wells': result = 80; break;
        case 'Blocks': result = 10; break;
        case 'Hubs': result = 30; break;
        default: result = 100; break;
      }
      switch (contextLayer.groupDisplayName) {
        case 'UK Offshore': result += 1; break;
        case 'UK Onshore': result += 98; break;
        case 'Norway': result += 2; break;
        case 'Ireland': result += 99; break;
        default: result += 100; break;
      }
      return result;
    };

    contextLayerA = getContextLayerOrder(contextLayerA);
    contextLayerB = getContextLayerOrder(contextLayerB);
    return (contextLayerA - contextLayerB);
  }

  _updateCollapseButton() {
    if (this.collapsed) {
      this._collapseButton.innerHTML = getIcon(faPlusSquare, { title: 'Expand' });
    } else {
      this._collapseButton.innerHTML = getIcon(faMinusSquare, { title: 'Collapse' });
    }
  }

  _disableMapEvents = () => {
    if (this._mapEvents === null) {
      this._mapEvents = getMapEvents(this._leafletMap);
      disableMapEvents(this._leafletMap);
    }
  }

  _enableMapEvents = () => {
    if (this._mapEvents !== null) {
      restoreMapEvents(this._leafletMap, this._mapEvents);
      this._mapEvents = null;
    }
  }

  close = () => {
    if (this.visible) {
      this.visible = false;
      if (typeof this._onClose === 'function') {
        this._onClose();
      }
    }
  }

  excelExport = () => {
    this._excelExport(this._searchResults);
  }

  _intOnResultLabelClick = (result) => {
    if (typeof this._onResultLabelClick === 'function' && this._onResultLabelClick(result) === true) {
      return;
    }
    this._intOnResultIconClick(result);
  }

  _intOnResultIconClick = (result) => {
    if (typeof this._onResultIconClick === 'function' && this._onResultIconClick(result) === true) {
      return;
    }

    const bounds = L.latLngBounds(result.shapeModel.latLngs);
    const zoom = Math.min(searchResultType.defaultZoomLevel(result.type),
      this._leafletMap.getBoundsZoom(bounds, 0));
    this._leafletMap.flyTo(bounds.getCenter(), zoom, { animate: true, duration: 1 });
  }
}

export { SearchResultsControl };
