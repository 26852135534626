import React from 'react';
import PropTypes from 'prop-types';

import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';
import { FieldDetail } from '../../../components/fiscal/models/portfolio/faster-portfolio';
import { valueTypes } from '../../../shared/helpers/value-handler';

const AdvancedBaseReportedReservesFactsComponent = (props) => {
  const { resource } = props;

  const getRows = () => {
    let baseHeader = resource.showBaseReserves ? <td colSpan='2' style={{ textAlign: 'center', backgroundColor: 'darkgrey' }}>{resource.baseValuationName} Reserves</td> : null;
    let reportedHeader = resource.showLiquidReportedReserves && resource.showGasReportedReserves ? <td colSpan='3' style={{ textAlign: 'center', backgroundColor: 'lightgrey' }}>Reported Reserves</td> : null;
    let colWidth = baseHeader && reportedHeader ? '14%' : baseHeader && !reportedHeader ? '42%' : '28%';
    let recoverableWidth = baseHeader && reportedHeader ? '20%' : baseHeader && !reportedHeader ? '42%' : '28%';

    let baseColumns = baseHeader != null ?
      <>
        <td style={{ width: recoverableWidth }}>Total Recoverable</td>
        <td style={{ width: colWidth }}>Remaining</td> 
      </>
      : null

    let reportedColumns = reportedHeader != null ?
      <>
        <td>Source</td>
        <td style={{ width: colWidth }}>Effective Date</td>
        <td style={{ width: colWidth }}>Remaining</td>
      </>
      : null

    let spacerColumn = baseHeader != null && reportedHeader != null ?
      <td></td>
      : null

    // BASE
    let baseLiquid = baseHeader != null ?
      <>
        <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.baseTotalRecoverableLiquidMmb)}</td>
        <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.baseRemainingLiquidMmb)}</td>
      </>
      : null;

    let baseGas = baseHeader != null ?
      <>
        <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.baseTotalRecoverableGasBcf)}</td>
        <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.baseRemainingGasBCF)}</td>
      </>
      : null;

    let baseTotal = baseHeader != null ?
      <>
        <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.baseTotalRecoverableCombinedMmboe)}</td>
        <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.baseRemainingCombinedMmboe)}</td>
      </>
      : null;

    // REPORTED
    let reportedLiquid = reportedHeader != null ?
      <>
        <td>{resource.reportedRemainingLiquidSource}</td>
        <td>{resource.reportedRemainingLiquidSourceDate}</td>
        <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.reportedRemainingLiquidMMB)}</td>
      </>
      : null;

    let reportedGas = reportedHeader != null ?
      <>
        <td>{resource.reportedRemainingGasSource}</td>
        <td>{resource.reportedRemainingGasSourceDate}</td>
        <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.reportedRemainingGasBCF)}</td>
      </>
      : null;

    let reportedTotal = reportedHeader != null ?
      <>
        <td></td>
        <td></td>
        <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.reportedCombinedMmboe)}</td>
      </>
      : null;

    return (
      <React.Fragment>
        <tr style={{ fontWeight: 'bold', border:'0'}}>
          <td style={{ width: '16%' }} />
          {baseHeader}
          {spacerColumn}
          {reportedHeader}
        </tr>
        <tr style={{ fontWeight: 'bold' }}>
          <td style={{ width: '16%' }} />
          {baseColumns}
          {spacerColumn}
          {reportedColumns}
        </tr>
        <tr>
          <td>Liquid ({valueTypes.UnitString(valueTypes.mmb)})</td>
          {baseLiquid}
          {spacerColumn}
          {reportedLiquid}
        </tr>
        <tr>
          <td>Gas ({valueTypes.UnitString(valueTypes.bcf)})</td>
          {baseGas}
          {spacerColumn}
          {reportedGas}
        </tr>
        <tr>
          <td>Total ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
          {baseTotal}
          {spacerColumn}
          {reportedTotal}
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent>
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

AdvancedBaseReportedReservesFactsComponent.propTypes = {
  resource: PropTypes.instanceOf(FieldDetail).isRequired
};

export default AdvancedBaseReportedReservesFactsComponent;

