import { FieldParticipant } from './gmatrix3-extension-field-participant';

class HubParticipant extends FieldParticipant {
  constructor(obj) {
    super(obj);
    
    if (typeof obj.fieldCount !== 'number') { throw new Error('Invalid ownerObj.fieldCount param supplied to HubParticipant.ctor'); }
    if (typeof obj.netRemainingGasBCF !== 'number') { throw new Error('Invalid ownerObj.netRemainingGasBCF param supplied to HubParticipant.ctor'); }

    this.fieldCount = obj.fieldCount;
    this.netRemainingGasBCF = obj.netRemainingGasBCF;
  }
}

export { HubParticipant }