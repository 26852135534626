import React from 'react';
import PropTypes from 'prop-types';

import { ProspectDetail } from '../../../models/prospect-detail';
import { valueTypes } from '../../../shared/helpers/value-handler';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const ResourcesComponent = (props) => {
  const { resource } = props;

  const getRows = () => {
    return (
      <React.Fragment>
        <tr>
          <td>Liquid ({valueTypes.UnitString(valueTypes.mmb)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.initialLiquidMmb)}</td>
        </tr>
        <tr>
          <td>Gas ({valueTypes.UnitString(valueTypes.bcf)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.initialGasBcf)}</td>
        </tr>
        <tr>
          <td>Total ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.initialReservesMmboe)}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent className="one-col-key-value">
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

ResourcesComponent.propTypes = {
  resource: PropTypes.instanceOf(ProspectDetail).isRequired
};

export default ResourcesComponent;
