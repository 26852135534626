import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import './date-picker-component.scss';
import { appIcons, appIconSizes } from '../../../app/app-icons';

const DatePickerComponent = (props) => {
  const _onChange = (value) => {
    if (value !== null && value._isAMomentObject === true) {
      value = value.toDate();
    }
    props.onChange(value);
  }

  const handleClearClick = () => props.onChange(null);
  const buttons = props.clearable === true
    ? (
      <div className="buttons-div">
        <span title="Clear" onClick={handleClearClick}>
          {appIcons.getIcon(appIcons.Close, appIconSizes.xs, false)}
        </span>
      </div>
    )
    : null;

  return (
    <div className="date-picker-component">
      {buttons}
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardDatePicker
          {...props}
          margin="dense"
          inputVariant="outlined"
          format={props.format}
          onChange={_onChange}
          InputLabelProps={{ shrink: true }}
          minDate={props.minDate === null ? undefined : props.minDate}
          maxDate={props.maxDate === null ? undefined : props.maxDate}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

DatePickerComponent.propTypes = {
  label: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool.isRequired,
  autoOk: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool.isRequired
};

DatePickerComponent.defaultProps = {
  format: 'DD/MM/YYYY',
  clearable: true,
  autoOk: true,
  fullWidth: true
};

export default DatePickerComponent;