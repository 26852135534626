import { FieldFilter } from "./field-filter";

import { appViews } from "../../app/app-views";
import { AppSetState } from "../../app/app-set-state";

import { valueTypes } from '../../shared/helpers/value-handler';
import { BaseExcelExportColumn, BaseExcelExport } from "../../shared/helpers/base-excel-export";
import { FiscalBulkResourceExcelExport } from '../../components/fiscal/helpers/fiscal-bulk-resource-excel-export';
import { FieldAttributes, CashFlowAnnual } from '../../components/fiscal/models/portfolio/faster-portfolio';

class GridExcelExport {
  advanceExport(appSetState, dataRows, filter, mapBaseExcelExport = null, mapWorkbook = null, sheetname = null, callback = null) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "GridExcelExport.export"');
    }
    if (!(filter instanceof FieldFilter)) {
      throw new Error('Invalid "filter" param supplied to "GridExcelExport.export"');
    }

    const valuationId = appSetState.appValuation.valuation.variableId;
    const url = mapWorkbook === null ? 'api/field/portfolio/load-fiscal-resource?excel=false&valuationId=' + valuationId : 'api/field/portfolio/load-fiscal-resource?excel=true&valuationId=' + valuationId;

    const onSuccess = (result) => {
      const cashFlowAnnuals = result.cashFlowAnnuals !== null ? result.cashFlowAnnuals.map(obj => new CashFlowAnnual(obj)) : null;
      const resourceAttributes = result.resourceAttributes !== null ? result.resourceAttributes.map(obj => new FieldAttributes(obj)) : null;
      if (dataRows === null && mapBaseExcelExport !== null) dataRows = result.dataRows;

      new FiscalBulkResourceExcelExport(cashFlowAnnuals, dataRows, resourceAttributes).exportAll(mapBaseExcelExport, mapWorkbook, sheetname, appSetState.appValuation.valuation, appSetState.appValuation.baseValuation);
      if (callback !== null) callback();
    };

    var bWait = mapWorkbook !== null ? false : true;

    appSetState.api.post(url, filter.toApiParam(), onSuccess, undefined, true, bWait);
  }

  async export(appSetState, dataRows, mapBaseExcelExport = null, mapWorkbook = null, sheetname = null) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "GridExcelExport.export"');
    }

    const baseExcelExport = mapBaseExcelExport !== null ? mapBaseExcelExport : new BaseExcelExport();
    const workbook = mapWorkbook !== null ? mapWorkbook : baseExcelExport.createWorkbook();

    const appView = sheetname !== null ? sheetname : appViews.getDisplayName(appViews.Field);
    const colDefs = []
    colDefs.push(new BaseExcelExportColumn('Field Name', 'resourceName'));
    colDefs.push(new BaseExcelExportColumn('Country', 'countryName'));
    colDefs.push(new BaseExcelExportColumn('Area', 'areaName'));
    colDefs.push(new BaseExcelExportColumn('Hub', 'hubName'));
    colDefs.push(new BaseExcelExportColumn('Operator', 'operatorName'));
    colDefs.push(new BaseExcelExportColumn('Development Status', 'status'));
    colDefs.push(new BaseExcelExportColumn('COP Year', 'lastProductionYear'));
    colDefs.push(new BaseExcelExportColumn('Remaining Liquid\nReserves (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'remainingLiquidMMB', '0.00'));
    colDefs.push(new BaseExcelExportColumn('Remaining Gas Reserves (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'remainingGasBCF', '0.00'));
    colDefs.push(new BaseExcelExportColumn('Remaining Reserves (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'remainingReservesMMBOE', '0.00'));
    colDefs.push(new BaseExcelExportColumn('Current Year Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'currentYearProductionBOEPD', '0.00'));
    colDefs.push(new BaseExcelExportColumn('Remaining Discounted Post Tax NPV (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'npv', '0.00'));   
    colDefs.push(new BaseExcelExportColumn('Current Year CO2 Emissions (' + valueTypes.UnitString(valueTypes.ktCO2) + ')', 'currentYearCO2EmissionsKTCO2', '0.00'));

    baseExcelExport.writeSheet(workbook, appView, dataRows, colDefs);
    if (mapBaseExcelExport === null) baseExcelExport.writeWorkbook(workbook, baseExcelExport.getAtlas5WorkbookPrefix() + appView + baseExcelExport.getAtlas5WorkbookSuffix());
  }
}

export { GridExcelExport };
