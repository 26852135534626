import { FiscalResource } from '../models/portfolio/fiscal-resource';
import { objectToEnum } from '../../../shared/helpers/common';
import { valueTypes } from '../../../shared/helpers/value-handler';
import { BaseExcelExportColumn, BaseExcelExport } from '../../../shared/helpers/base-excel-export';
import { UserValuations } from '../../../models/user-valuations';

const exportType = objectToEnum({
  CashflowAnnuals: 0,
  //TODO: XXX: 1
});

class FiscalResourceExcelExport extends BaseExcelExport {
  constructor(fiscalResource, valuation, baseValuation) {
    if (!(fiscalResource instanceof FiscalResource)) {
      throw new Error('Invalid "fiscalResource" param supplied to "FiscalResourceExcelExport.ctor"');
    }
    if (!(valuation instanceof UserValuations)) {
      throw new Error('Invalid "valuation" param supplied to "FiscalResourceExcelExport.ctor"');
    }
    if (!(baseValuation instanceof UserValuations)) {
      throw new Error('Invalid "baseValuation" param supplied to "FiscalResourceExcelExport.ctor"');
    }

    super();
    this._fiscalResource = fiscalResource;
    this._valuation = valuation;
    this._baseValuation = baseValuation;
  }

  exportAll() {
    this._doExport(exportType.getValues());
  }

  exportCashflowAnnuals() {
    this.export([exportType.CashflowAnnuals]);
  }

  //TODO: exportXXX() {
  //TODO:   this.export([exportType.XXX]);
  //TODO: }

  _doExport(exportTypes) {
    if (!Array.isArray(exportTypes) || exportTypes.length === 0 || exportTypes.filter(obj => exportType.isInvalid(obj)).length > 0) {
      throw new Error('Invalid "exportTypes" param supplied to "FiscalResourceExcelExport.export"');
    }

    const workbook = this.createWorkbook();

    if (exportTypes.includes(exportType.CashflowAnnuals)) {
      this._doExportCashflowAnnuals(workbook);
    }

    //TODO: if (exportTypes.includes(exportType.XXX)) {
    //TODO:   this._doExportXXX(workbook);
    //TODO: }

    this.writeWorkbook(workbook, 'Field ' + this._fiscalResource.resourceName + this.getAtlas5WorkbookSuffix(), null, this._valuation, this._baseValuation);
  }

  _doExportCashflowAnnuals(workbook) {
    const usdMM = valueTypes.UnitString(valueTypes.usdMM);

    const columns = [
      new BaseExcelExportColumn('Year', 'year'),
      new BaseExcelExportColumn('Liquid Price (' + valueTypes.UnitString(valueTypes.usdBOE) + ')', 'liquidPriceUsdBOE', '0.00'),
      new BaseExcelExportColumn('Gas Price (' + valueTypes.UnitString(valueTypes.usdMCF) + ')', 'gasPriceUsdMCF', '0.00'),
      //new BaseExcelExportColumn('liquidPriceDiscountPremiumPercent', 'liquidPriceDiscountPremiumPercent'),
      //new BaseExcelExportColumn('gasPriceDiscountPremiumPercent', 'gasPriceDiscountPremiumPercent'),
      //new BaseExcelExportColumn('discountRate', 'discountRate'),
      new BaseExcelExportColumn('Liquid Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'liquidProductionMbod', '0.00'),
      new BaseExcelExportColumn('Gas Production (' + valueTypes.UnitString(valueTypes.mmscfd) + ')', 'gasProductionMmscfd', '0.00'),
      new BaseExcelExportColumn('Total Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'combinedMboed', '0.00'),
      //new BaseExcelExportColumn('gasProductionMboed', 'gasProductionMboed'),
      //new BaseExcelExportColumn('liquidRevenueUsdMM', 'liquidRevenueUsdMM'),
      //new BaseExcelExportColumn('gasRevenueUsdMM', 'gasRevenueUsdMM'),
      new BaseExcelExportColumn('Revenue (' + usdMM + ')', 'totalRevenueUsdMM', '0.00'),
      new BaseExcelExportColumn('Opex (' + usdMM + ')', 'opexUsdMM', '0.00'),
      new BaseExcelExportColumn('Emissions charge (' + usdMM + ')', 'totalEmissionsChargeUsdMM', '0.00'),
      //new BaseExcelExportColumn('liquidTariffUsdBOE', 'liquidTariffUsdBOE'),
      //new BaseExcelExportColumn('gasTariffUsdBOE', 'gasTariffUsdBOE'),
      //new BaseExcelExportColumn('liquidTariffUsdMM', 'liquidTariffUsdMM'),
      //new BaseExcelExportColumn('gasTariffUsdMM', 'gasTariffUsdMM'),
      new BaseExcelExportColumn('Export tariff (' + usdMM + ')', 'totalTariffUsdMM', '0.00'),
      //new BaseExcelExportColumn('ebitda', 'ebitda'),
      //new BaseExcelExportColumn('cumulativeCostForDDAUsdMM', 'cumulativeCostForDDAUsdMM'),
      //new BaseExcelExportColumn('depreciationUsdMM', 'depreciationUsdMM'),
      //new BaseExcelExportColumn('openingBalanceUsdMM', 'openingBalanceUsdMM'),
      //new BaseExcelExportColumn('lossesOrDepreciationAddedUsdMM', 'lossesOrDepreciationAddedUsdMM'),
      //new BaseExcelExportColumn('lossesUsedOrIncomeShelteredUsdMM', 'lossesUsedOrIncomeShelteredUsdMM'),
      //new BaseExcelExportColumn('rfesUpliftUsdMM', 'rfesUpliftUsdMM'),
      //new BaseExcelExportColumn('closingBalanceUsdMM', 'closingBalanceUsdMM'),
      //new BaseExcelExportColumn('ctTaxBaseUsdMM', 'ctTaxBaseUsdMM'),
      //new BaseExcelExportColumn('investmentsToUpliftUsdMM', 'investmentsToUpliftUsdMM'),
      //new BaseExcelExportColumn('upliftUsdMM', 'upliftUsdMM'),
      //new BaseExcelExportColumn('cumulativeUpliftAfterUsageUsdMM', 'cumulativeUpliftAfterUsageUsdMM'),
      //new BaseExcelExportColumn('investmentAllowanceAvailableUsdMM', 'investmentAllowanceAvailableUsdMM'),
      //new BaseExcelExportColumn('investmentAllowanceUsedUsdMM', 'investmentAllowanceUsedUsdMM'),
      //new BaseExcelExportColumn('sctIncomeShelteredUsdMM', 'sctIncomeShelteredUsdMM'),
      //new BaseExcelExportColumn('sctTaxBaseUsdMM', 'sctTaxBaseUsdMM'),
      //new BaseExcelExportColumn('explorationCapexUsdMM', 'explorationCapexUsdMM'),
      //new BaseExcelExportColumn('developmentCapexUsdMM', 'developmentCapexUsdMM'),
      new BaseExcelExportColumn('Capex (' + usdMM + ')', 'costForLossesOrDepreciationUsdMM', '0.00'),
      new BaseExcelExportColumn('Abex (' + usdMM + ')', 'abexUsdMM', '0.00'),
      new BaseExcelExportColumn('Pre-tax cashflow (' + usdMM + ')', 'preTaxCashflowUsdMM', '0.00'),
      //new BaseExcelExportColumn('cumulativePreTaxCashflowUsdMM', 'cumulativePreTaxCashflowUsdMM'),
      //new BaseExcelExportColumn('explorationRebateUsdMM', 'explorationRebateUsdMM'),
      new BaseExcelExportColumn('Corporate tax (' + usdMM + ')', 'corporationTaxUsdMM', '0.00'),
      new BaseExcelExportColumn('Special tax (' + usdMM + ')', 'specialTaxUsdMM', '0.00'),
      //new BaseExcelExportColumn('abexReliefUsdMM', 'abexReliefUsdMM'),
      //new BaseExcelExportColumn('ctAndSCT', 'ctAndSCT'),
      new BaseExcelExportColumn('Undiscounted Post-tax cashflow (' + usdMM + ')', 'postTaxCashflowUsdMM', '0.00'),
      //new BaseExcelExportColumn('cumulativePostTaxCashflowUsdMM', 'cumulativePostTaxCashflowUsdMM'),
      new BaseExcelExportColumn('Discounted Post-tax cashflow (' + usdMM + ')', 'discountedPostTaxCashflowUsdMM', '0.00'),
      //new BaseExcelExportColumn('cumulativeDiscountedPostTaxCashflowUsdMM', 'cumulativeDiscountedPostTaxCashflowUsdMM'),
      //new BaseExcelExportColumn('opexTypeId', 'opexTypeId'),
      //new BaseExcelExportColumn('resourceOpexUsdMM', 'resourceOpexUsdMM'),
      //new BaseExcelExportColumn('opexCostSharePercent', 'opexCostSharePercent'),
      //new BaseExcelExportColumn('portfolioEquityMultiplier', 'portfolioEquityMultiplier'),
    ];

    this.writeSheet(workbook, 'Cashflow Annuals', this._fiscalResource.cashflow.cashflowAnnuals, columns);
  }

  /*
   FULL EXPORT TO BE REVIEWED AFTER NW FISCAL JOB RELEASED   
   */

  //_doExportCashflowAnnuals(workbook) {
  //  const usdMM = valueTypes.UnitString(valueTypes.usdMM);

  //  const columns = [
  //    new BaseExcelExportColumn('Year', 'year'),
  //    new BaseExcelExportColumn('Liquid Price (' + valueTypes.UnitString(valueTypes.usdBOE) + ')', 'liquidPriceUsdBOE', '0.00'),
  //    new BaseExcelExportColumn('Gas Price (' + valueTypes.UnitString(valueTypes.usdMCF) + ')', 'gasPriceUsdMCF', '0.00'),      
  //    new BaseExcelExportColumn('Liquid Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'liquidProductionMbod', '0.00'),
  //    new BaseExcelExportColumn('Gas Production (' + valueTypes.UnitString(valueTypes.mmscfd) + ')', 'gasProductionMmscfd', '0.00'),      
  //    new BaseExcelExportColumn('Revenue (' + usdMM + ')', 'totalRevenueUsdMM', '0.00'),
  //    new BaseExcelExportColumn('Opex (' + usdMM + ')', 'opexUsdMM', '0.00'),      
  //    new BaseExcelExportColumn('Total tariff (' + usdMM + ')', 'totalTariffUsdMM', '0.00'),
  //    new BaseExcelExportColumn('EBITDA (' + usdMM + ')', 'ebitda', '0.00'),
  //    new BaseExcelExportColumn('Capex (' + usdMM + ')', 'costForLossesOrDepreciationUsdMM', '0.00'),
  //    new BaseExcelExportColumn('Abex (' + usdMM + ')', 'abexUsdMM', '0.00'),

  //    new BaseExcelExportColumn('Pre-tax cashflow (' + usdMM + ')', 'preTaxCashflowUsdMM', '0.00'),
  //    new BaseExcelExportColumn('Cumulative Pre-tax cashflow (' + usdMM + ')', 'cumulativePreTaxCashflowUsdMM', '0.00'),     

  //    new BaseExcelExportColumn('CT Closing Balance (' + usdMM + ')', 'ctClosingBalanceUsdMM', '0.00'),
  //    new BaseExcelExportColumn('CT Opening Balance (' + usdMM + ')', 'ctOpeningBalanceUsdMM', '0.00'),
  //    new BaseExcelExportColumn('CT Cumulative Cost For DDA (' + usdMM + ')', 'ctCumulativeCostForDDAUsdMM', '0.00'),
  //    new BaseExcelExportColumn('CT Depreciation (' + usdMM + ')', 'ctDepreciationUsdMM', '0.00'),
  //    new BaseExcelExportColumn('CT Losses Or Depreciation Added (' + usdMM + ')', 'ctLossesOrDepreciationAddedUsdMM', '0.00'),
  //    new BaseExcelExportColumn('CT Losses Used Or Income Sheltered (' + usdMM + ')', 'ctLossesUsedOrIncomeShelteredUsdMM', '0.00'),
  //    new BaseExcelExportColumn('CT Tax Base (' + usdMM + ')', 'ctTaxBaseUsdMM', '0.00'),
  //    new BaseExcelExportColumn('Corporate tax (' + usdMM + ')', 'corporationTaxUsdMM', '0.00'),

  //    new BaseExcelExportColumn('SCT Closing Balance (' + usdMM + ')', 'sctClosingBalanceUsdMM', '0.00'),
  //    new BaseExcelExportColumn('SCT Opening Balance (' + usdMM + ')', 'sctOpeningBalanceUsdMM', '0.00'),      
  //    new BaseExcelExportColumn('SCT Cumulative Cost For DDA (' + usdMM + ')', 'sctCumulativeCostForDDAUsdMM', '0.00'),
  //    new BaseExcelExportColumn('SCT Depreciation (' + usdMM + ')', 'sctDepreciationUsdMM', '0.00'),      
  //    new BaseExcelExportColumn('SCT Losses Or Depreciation Added (' + usdMM + ')', 'sctLossesOrDepreciationAddedUsdMM', '0.00'),
  //    new BaseExcelExportColumn('SCT Losses Used Or Income Sheltered (' + usdMM + ')', 'sctLossesUsedOrIncomeShelteredUsdMM', '0.00'),
  //    new BaseExcelExportColumn('SCT Income Sheltered (' + usdMM + ')', 'sctIncomeShelteredUsdMM', '0.00'),
  //    new BaseExcelExportColumn('Investments To Uplift (' + usdMM + ')', 'investmentsToUpliftUsdMM', '0.00'),
  //    new BaseExcelExportColumn('Uplift (' + usdMM + ')', 'upliftUsdMM', '0.00'),
  //    new BaseExcelExportColumn('Cumulative Uplift After Usage (' + usdMM + ')', 'cumulativeUpliftAfterUsageUsdMM', '0.00'),
  //    new BaseExcelExportColumn('Investment Allowance Available (' + usdMM + ')', 'investmentAllowanceAvailableUsdMM', '0.00'),
  //    new BaseExcelExportColumn('Investment Allowance Used (' + usdMM + ')', 'investmentAllowanceUsedUsdMM', '0.00'),  
  //    new BaseExcelExportColumn('SCT Tax Base (' + usdMM + ')', 'sctTaxBaseUsdMM', '0.00'),      
  //    new BaseExcelExportColumn('Special tax (' + usdMM + ')', 'specialTaxUsdMM', '0.00'),

  //    new BaseExcelExportColumn('Undiscounted Post-tax cashflow (' + usdMM + ')', 'postTaxCashflowUsdMM', '0.00'),      
  //    new BaseExcelExportColumn('Discounted Post-tax cashflow (' + usdMM + ')', 'discountedPostTaxCashflowUsdMM', '0.00'),
  //    new BaseExcelExportColumn('Exploration Capex (' + usdMM + ')', 'explorationCapexUsdMM', '0.00'),
  //    new BaseExcelExportColumn('Development Capex (' + usdMM + ')', 'developmentCapexUsdMM', '0.00'),
  //    //new BaseExcelExportColumn('abexReliefUsdMM', 'abexReliefUsdMM'),
  //    //new BaseExcelExportColumn('ctAndSCT', 'ctAndSCT'),
  //    //new BaseExcelExportColumn('explorationRebateUsdMM', 'explorationRebateUsdMM'),
  //    //new BaseExcelExportColumn('cumulativePostTaxCashflowUsdMM', 'cumulativePostTaxCashflowUsdMM'),
  //    //new BaseExcelExportColumn('cumulativeDiscountedPostTaxCashflowUsdMM', 'cumulativeDiscountedPostTaxCashflowUsdMM'),
  //    //new BaseExcelExportColumn('opexTypeId', 'opexTypeId'),
  //    //new BaseExcelExportColumn('resourceOpexUsdMM', 'resourceOpexUsdMM'),
  //    //new BaseExcelExportColumn('opexCostSharePercent', 'opexCostSharePercent'),
  //    //new BaseExcelExportColumn('portfolioEquityMultiplier', 'portfolioEquityMultiplier'),
  //    //new BaseExcelExportColumn('rfesUpliftUsdMM', 'rfesUpliftUsdMM'),
  //    //new BaseExcelExportColumn('liquidPriceDiscountPremiumPercent', 'liquidPriceDiscountPremiumPercent'),
  //    //new BaseExcelExportColumn('gasPriceDiscountPremiumPercent', 'gasPriceDiscountPremiumPercent'),
  //    //new BaseExcelExportColumn('discountRate', 'discountRate'),
  //    //new BaseExcelExportColumn('gasProductionMboed', 'gasProductionMboed'),
  //    //new BaseExcelExportColumn('liquidRevenueUsdMM', 'liquidRevenueUsdMM'),
  //    //new BaseExcelExportColumn('gasRevenueUsdMM', 'gasRevenueUsdMM'),
  //    //new BaseExcelExportColumn('liquidTariffUsdBOE', 'liquidTariffUsdBOE'),
  //    //new BaseExcelExportColumn('gasTariffUsdBOE', 'gasTariffUsdBOE'),
  //    //new BaseExcelExportColumn('liquidTariffUsdMM', 'liquidTariffUsdMM'),
  //    //new BaseExcelExportColumn('gasTariffUsdMM', 'gasTariffUsdMM'),
  //  ];

  //  this.writeSheet(workbook, 'Cashflow Annuals', this._fiscalResource.cashflow.cashflowAnnuals, columns);
  //}

}

export { FiscalResourceExcelExport };
