import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';

import { BaseButtonControl, TOOLTIP_OPTIONS } from "./base-button-control";
import { zoomLevels } from '../../leaflet-helper';

const DEFAULT_ICON = L.icon({ iconUrl: icon, shadowUrl: iconShadow, iconSize: [25, 41], iconAnchor: [12, 41], shadowSize: [41, 41] });

class PointSelectButtonControl extends BaseButtonControl {
  constructor(searchButtonsControl) {
    super(searchButtonsControl);

    this._drawing = false;
  }

  get iconDef() { return faMapMarkerAlt; }
  get title() { return 'Select'; }

  cancel() {
    this.clearLayerGroup();
    this._drawing = false;
    this.toggled = false;
  }

  onToggledChanged(toggled) {
    if (toggled) {
      this.addEvent('mousedown', this._mapMouseDown);
      this.addEvent('mousemove', this._mapMouseMove);
      this.addEvent('mouseup', this._mapMouseUp);
    } else {
      this.removeEvent('mousedown', this._mapMouseDown);
      this.removeEvent('mousemove', this._mapMouseMove);
      this.removeEvent('mouseup', this._mapMouseUp);
    }
  }

  _getTooltip(latLng) {
    const dp = 3;
    return 'Lat: ' + latLng.lat.toFixed(dp) + ' Lng: ' + latLng.lng.toFixed(dp);
  }

  _getRadius() {
    const zoom = this.leafletMap.getZoom();
    switch (zoom) {
      case zoomLevels._5000km:
      case zoomLevels._3000km:
      case zoomLevels._2000km:
      case zoomLevels._1000km:
      case zoomLevels._500km:
      case zoomLevels._200km:
      case zoomLevels._100km: return 5000;
      case zoomLevels._50km: return 2500;
      case zoomLevels._30km: return 1500;
      case zoomLevels._10km: return 500;
      case zoomLevels._5km: return 250;
      case zoomLevels._3km: return 150;
      case zoomLevels._2km: return 100;
      case zoomLevels._1km:
      case zoomLevels._500m:
      case zoomLevels._200m:
      case zoomLevels._100m:
      default: return 50;
    }
  }

  _mapMouseDown(e) {
    if (this.ignoreMouseEvents) {
      return;
    }

    this._drawing = true;

    const layerGroup = this.layerGroup;
    const marker = L.marker(e.latlng, { icon: DEFAULT_ICON, interactive: false });
    marker.bindTooltip(this._getTooltip(e.latlng), TOOLTIP_OPTIONS);
    layerGroup.addLayer(marker);
  }

  _mapMouseMove(e) {
    if (this.ignoreMouseEvents || !this._drawing) {
      return;
    }

    const layers = this.layerGroup.getLayers();
    if (layers.length === 0) {
      return;
    }

    const marker = layers[layers.length - 1];
    marker.setLatLng(e.latlng);
    marker.setTooltipContent(this._getTooltip(e.latlng));
  }

  _mapMouseUp(e) {
    if (this.ignoreMouseEvents) {
      return;
    }

    this._drawing = false;

    const layerGroup = this.layerGroup;
    const layers = layerGroup.getLayers();
    if (layers.length > 0) {
      const marker = layers[layers.length - 1];
      marker.closeTooltip();

      for (var loop = 0; loop < layers.length - 1; loop++) {
        layerGroup.removeLayer(layers[loop]);
      }

      if (typeof this.onComplete === 'function') {
        this.onComplete(e.latlng, this._getRadius());
      }
    }

    //TODO: added until we revisit 181 - Retain Select Tool Function
    this.toggled = false;
  }
}

export { PointSelectButtonControl };