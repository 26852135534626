import { cloneObject } from "../../../shared/helpers/common";
import { ScenarioValue } from './scenario-value';

class Scenario {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to Scenario.ctor');
    }
    if (typeof obj.scenarioId !== 'number') {
      throw new Error('Invalid obj.scenarioId param supplied to Scenario.ctor');
    }
    if (typeof obj.variableId !== 'number') {
      throw new Error('Invalid obj.variableId param supplied to Scenario.ctor');
    }
    if (typeof obj.yearFrom !== 'number') {
      throw new Error('Invalid obj.yearFrom param supplied to Scenario.ctor');
    }
    if (typeof obj.yearTo !== 'number') {
      throw new Error('Invalid obj.yearTo param supplied to Scenario.ctor');
    }
    if (typeof obj.valuesHaveOwnYears !== 'boolean') {
      throw new Error('Invalid obj.valuesHaveOwnYears param supplied to Scenario.ctor');
    }
    if (!(Array.isArray(obj.scenarioValues))) {
      throw new Error('Invalid obj.scenarioValues param supplied to Scenario.ctor');
    }

    this.scenarioId = obj.scenarioId;
    this.variableId = obj.variableId;
    this.yearFrom = obj.yearFrom;
    this.yearTo = obj.yearTo;
    this.valuesHaveOwnYears = obj.valuesHaveOwnYears;
    this.applyToAllYears = obj.yearFrom === 0 && obj.yearTo === 0;
    this.scenarioValues = obj.scenarioValues.map(obj => new ScenarioValue(obj));
    this.isActive = obj.scenarioValues.some(x => x.attributeValue !== 0);
  }

  clone() {
    const result = cloneObject(this);
    result.scenarioValues = this.scenarioValues.map(obj => obj.clone());
    return result;
  }
}

export { Scenario };