import { showError } from '../../helpers/common';

class Country {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid Country param supplied to Country.ctor');
    }
    if (typeof obj.countryId !== 'number') { showError('Country', 'countryId'); }
    if (typeof obj.countryName !== 'string') { showError('Country', 'countryName'); }

    this.countryId = obj.countryId;
    this.countryName = obj.countryName;
  }
}

export { Country }