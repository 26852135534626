import React from 'react';

import WellDetailComponent from './well-detail-component';

import { DetailModel } from '../detail-model';
import HeaderComponent, { BUTTON_ICON_SIZE } from '../header-component';
import HeaderButtonComponent from '../header-button-component';

import { appIcons } from '../../app/app-icons';
import { appViews } from '../../app/app-views';
import { WellDetail } from '../../models/well-detail';
import { TrackingType } from '../../shared/helpers/enums';
import { downloadFile } from '../../shared/helpers/download';
import { ContextLayerModel } from '../../shared/helpers/leaflet/leaflet-helper';

class WellDetailModel extends DetailModel {
  constructor(atlasPanel, wellId) {
    if (typeof wellId !== 'number') {
      throw new Error('Invalid "wellId" supplied to "WellDetailModel.ctor"');
    }

    super(appViews.Well, atlasPanel);
    this._wellId = wellId;
  }

  matches(detail) {
    const result =
      typeof detail === 'object' &&
      detail instanceof WellDetailModel &&
      detail._wellId === this._wellId;
    return result;
  }

  load(appSetState, onLoadComplete) {
    this._validateLoadParams(appSetState, onLoadComplete);

    const onSuccess = (result) => {
      this.clearError();
      this._data = {
        well: new WellDetail(result.well),
        contextLayers: ContextLayerModel.FromArray(result.contextLayers)
      }
      this.addNavigationHistory(appSetState, this._wellId, this._data.well.wellName);
      onLoadComplete();
    };
    const onFail = (err) => this.handleLoadError(err, onLoadComplete);
    const url = 'api/well/details/load/?wellId=' + this._wellId + '&page=' + appSetState.view.current + '&panel=' + this.atlasPanel;
    appSetState.api.getCached(url, onSuccess, onFail);
  }

  getHeaderComponent(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close) {
    this._validateGetHeaderComponentParams(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close);

    const copyLinkClick = this.loaded && this._data.well.link !== null
      ? () => appSetState.view.details.showWellLink(this._wellId, this._data.well.link)
      : null;

    const downloadReport = () => downloadFile(this._data.well.reportId, appSetState, true, 0, appSetState.view.current, TrackingType.ReportDownload, this.atlasPage);
    const downloadButton = <HeaderButtonComponent iconDef={appIcons.FilePdf} iconSize={BUTTON_ICON_SIZE} title="Download Report" disabled={!this.loaded || this._data.well.reportId === 0} onClick={downloadReport} />;

    const wellResource = this._data !== null && this._data.well !== null && this._data.well.resource !== null ? this._data.well.resource.map(x => x.name).join('; ') : '';
    const title = this._data !== null && this._data.well !== null ? wellResource !== '' ? this._data.well.wellName + ' (' + wellResource +  ')' : this._data.well.resourceString !== null && this._data.well.resourceString !== '' ? this._data.well.wellName + ' (' + this._data.well.resourceString + ')' : this._data.well.wellName : '' ;

    return (
      <HeaderComponent
        appView={this.appView}
        title={this.getTitle(() => title)}
        backClick={backClick}
        forwardClick={forwardClick}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        close={close}
        copyLinkClick={copyLinkClick}
      >
        {downloadButton}
      </HeaderComponent>
    );
  }

  getBodyComponent(appSetState, geoserverUrl) {
    this._validateGetBodyComponentParams(appSetState, geoserverUrl);

    if (this._error !== null) {
      return this._getErrorComponent();
    }

    if (this.loaded) {
      return (
        <WellDetailComponent
          well={this._data.well}
          contextLayers={this._data.contextLayers}
          appSetState={appSetState}
          geoserverUrl={geoserverUrl}
        />
      );
    }

    return this._getLoadingComponent();
  }
}

export { WellDetailModel };
