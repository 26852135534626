import React from 'react';

import VideoDetailComponent from './video-detail-component';

import { DetailModel } from '../detail-model';
import HeaderComponent from '../header-component';

class VideoDetailModel extends DetailModel {
  constructor(atlasPanel, source, title) {
    if (typeof source !== 'string') {
      throw new Error('Invalid "source" supplied to "VideoDetailModel.ctor"');
    }
    if (typeof title !== 'string') {
      throw new Error('Invalid "title" supplied to "VideoDetailModel.ctor"');
    }

    super(null, atlasPanel);
    this._source = source;
    this._title = title;
    this._close = null;
  }

  matches(detail) {
    const result =
      typeof detail === 'object' &&
      detail instanceof VideoDetailModel &&
      detail._title === this._title &&
      detail._close === this._close;
    return result;
  }

  load(appSetState, onLoadComplete) {
    this._data = { dummy: true };
    onLoadComplete();
  }

  getHeaderComponent(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close) {
    this._validateGetHeaderComponentParams(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close);

    return (
      <HeaderComponent
        appView={this.appView}
        title={this._title}
        backClick={backClick}
        forwardClick={forwardClick}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        close={close}
      />
    );
  }

  getBodyComponent(appSetState, geoserverUrl) {
    this._validateGetBodyComponentParams(appSetState, geoserverUrl);

    return (
      <VideoDetailComponent
        source={this._source}
      />
    );
  }
}

export { VideoDetailModel };