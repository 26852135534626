import moment from 'moment';
import { handleDecimal } from '../../../../shared/helpers/decimal-places';
import { showError } from '../../../../shared/helpers/common';
import { ResourceText } from '../resource-text';
import { FiscalResource } from './fiscal-resource';
import { ChartData } from '../../../../shared/components/generic-chart/chart-data'
import { WgeAnalyticsGreen, WgeSectorsRed, WgeCorporateBlue, WgeLightGrey } from '../../../../shared/scss/wge-colors';
import { FieldParticipant, DisplayParticipant, CombinedProduction } from '../../../../shared/components/models/gmatrix3-extension-field-participant';
import { DisplayWell } from '../../../../shared/components/models/gmatrix3-extension-display-well';
import { UserValuations } from '../../../../models/user-valuations';

class PortfolioLog {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid \'obj\' param supplied to \'PortfolioLog.ctor\''); }

    let logTimeMoment = null;
    if (obj.logTime !== null) {
      if (typeof obj.logTime !== 'string') { throw new Error('Invalid logTime param supplied to PortfolioLog.ctor'); }
      logTimeMoment = moment(obj.logTime);
      if (!logTimeMoment.isValid()) { throw new Error('Invalid logTime param supplied to PortfolioLog.ctor'); }
    }

    if (typeof obj.Info !== 'string') { throw new Error('Invalid obj.Info param supplied to PortfolioLog.ctor'); }

    this.logTime = logTimeMoment === null ? null : logTimeMoment.format('DD/MM/YYYY, HH:mm:ss:SSS');
    this.info = obj.Info;
  }
}

class FieldDetailChartData {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to FieldDetailChartData.ctor');
    }
    if (typeof obj.fieldProduction !== 'object') { throw new Error('Invalid fieldProduction param supplied to FieldDetailChartData.ctor'); }
    if (typeof obj.fieldReserves !== 'object') { throw new Error('Invalid fieldReserves param supplied to FieldDetailChartData.ctor'); }
    if (obj.emissions !== null && typeof obj.emissions !== 'object') { throw new Error('Invalid emissions param supplied to FieldDetailChartData.ctor'); }

    this.fieldProduction = new ChartData(obj.fieldProduction);
    if (this.fieldProduction !== null) {
      this.fieldProduction.yVal[0].color = WgeAnalyticsGreen;
      this.fieldProduction.yVal[1].color = WgeSectorsRed;
    }

    this.fieldReserves = new ChartData(obj.fieldReserves);
    if (this.fieldReserves !== null) {
      this.fieldReserves.yVal[0].color = WgeAnalyticsGreen;
      this.fieldReserves.yVal[1].color = WgeSectorsRed;
    }

    this.emissions = null;
    if (obj.emissions !== null && obj.emissions !== undefined) {
      this.emissions = new ChartData(obj.emissions);
      this.emissions.yVal[0].color = WgeCorporateBlue;
      this.emissions.yVal[1].color = WgeLightGrey;
    }

    //this.emissions = new ChartData(obj.emissions);
    //if (this.emissions !== null) {
    //  this.emissions.yVal[0].color = WgeCorporateBlue;
    //  this.emissions.yVal[1].color = WgeLightGrey;
    //}
  }
}

class FieldAttributes {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('FieldAttributes', 'FieldAttributes'); }

    if (typeof obj.resourceName !== 'string') { showError('FieldAttributes', 'resourceName'); }
    if (typeof obj.type !== 'string') { showError('FieldAttributes', 'type'); }
    if (typeof obj.countryLabel !== 'string') { showError('FieldAttributes', 'countryLabel'); }
    if (typeof obj.areaName !== 'string') { showError('FieldAttributes', 'areaName'); }
    if (typeof obj.hubName !== 'string') { showError('FieldAttributes', 'hubName'); }
    if (typeof obj.operatorName !== 'string') { showError('FieldAttributes', 'operatorName'); }
    if (typeof obj.status !== 'string') { showError('FieldAttributes', 'status'); }
    if (typeof obj.blocks !== 'string') { showError('FieldAttributes', 'blocks'); }    
    if (typeof obj.blockNo !== 'string') { showError('FieldAttributes', 'blockNo'); }    
    if (typeof obj.licence !== 'string') { showError('FieldAttributes', 'licence'); }
    if (typeof obj.licences !== 'string') { showError('FieldAttributes', 'licences'); }
    let fieldApprovalMoment = null;
    if (obj.fieldApprovalDate !== null) {
      if (typeof obj.fieldApprovalDate !== 'string') { showError('FieldAttributes', 'fieldApprovalDate'); }
      fieldApprovalMoment = moment(obj.fieldApprovalDate);
      if (!fieldApprovalMoment.isValid()) { showError('FieldAttributes', 'fieldApprovalMoment'); }
    }
    if (typeof obj.fieldApprovalYear !== 'number') { showError('FieldAttributes', 'fieldApprovalYear'); }
    if (typeof obj.firstProductionYear !== 'number') { showError('FieldAttributes', 'firstProductionYear'); }
    if (typeof obj.totalRecoverableLiquidMmb !== 'number') { showError('FieldAttributes', 'totalRecoverableLiquidMmb'); }
    if (typeof obj.totalRecoverableGasBcf !== 'number') { showError('FieldAttributes', 'totalRecoverableGasBcf'); }
    if (typeof obj.totalRecoverableCombinedMmboe !== 'number') { showError('FieldAttributes', 'totalRecoverableCombinedMmboe'); }
    if (typeof obj.remainingLiquidMmb !== 'number') { showError('FieldAttributes', 'remainingLiquidMmb'); }
    if (typeof obj.remainingGasBcf !== 'number') { showError('FieldAttributes', 'remainingGasBcf'); }
    if (typeof obj.remainingCombinedMmboe !== 'number') { showError('FieldAttributes', 'remainingCombinedMmboe'); }
    if (typeof obj.reservoirAge !== 'string') { showError('FieldAttributes', 'reservoirAge'); }
    if (typeof obj.reservoirDepthFt !== 'number') { showError('FieldAttributes', 'reservoirDepthFt'); }
    if (typeof obj.trapType !== 'string') { showError('FieldAttributes', 'trapType'); }    
    if (typeof obj.pressureStatus !== 'string') { showError('FieldAttributes', 'pressureStatus'); }
    if (typeof obj.temperatureStatus !== 'string') { showError('FieldAttributes', 'temperatureStatus'); }
    if (typeof obj.licenceRound !== 'string' && obj.licenceRound !== null) { showError('FieldAttributes', 'licenceRound'); }

    if (typeof obj.discoveryWell !== 'string') { showError('FieldAttributes', 'discoveryWell'); }
    if (typeof obj.discoveryYear !== 'number') { showError('FieldAttributes', 'discoveryYear'); }
    if (typeof obj.decommissionYear !== 'number') { showError('FieldAttributes', 'decommissionYear'); }
    if (typeof obj.lastProductionYear !== 'number') { showError('FieldAttributes', 'lastProductionYear'); }
    if (typeof obj.oilPipeline !== 'string') { showError('FieldAttributes', 'oilPipeline'); }
    if (typeof obj.gasPipeline !== 'string') { showError('FieldAttributes', 'gasPipeline'); }
    if (typeof obj.developmentConcept !== 'string') { showError('FieldAttributes', 'developmentConcept'); }

    if (typeof obj.remainingCAPEX !== 'number') { showError('FieldAttributes', 'remainingCAPEX'); }
    if (typeof obj.remainingOPEX !== 'number') { showError('FieldAttributes', 'remainingOPEX'); }
    if (typeof obj.remainingTariff !== 'number') { showError('FieldAttributes', 'remainingTariff'); }
    if (typeof obj.remainingABEX !== 'number') { showError('FieldAttributes', 'remainingABEX'); }
    if (typeof obj.remainingPreTaxRevenue !== 'number') { showError('FieldAttributes', 'remainingPreTaxRevenue'); }
    if (typeof obj.remainingPreTaxCashflow !== 'number') { showError('FieldAttributes', 'remainingPreTaxCashflow'); }
    if (typeof obj.remainingPostTaxCashflow !== 'number') { showError('FieldAttributes', 'remainingPostTaxCashflow'); }
    if (typeof obj.remainingNetPresetValue !== 'number') { showError('FieldAttributes', 'remainingNetPresetValue'); }

    if (typeof obj.currentYearLiquidProductionMbod !== 'number') { showError('FieldAttributes', 'currentYearLiquidProductionMbod'); }
    if (typeof obj.currentYearGasProductionMmscfd !== 'number') { showError('FieldAttributes', 'currentYearGasProductionMmscfd'); }
    if (typeof obj.currentYearTotalProductionMboepd !== 'number') { showError('FieldAttributes', 'currentYearTotalProductionMboepd'); }
    if (typeof obj.currentYearCO2EmissionsKTCO2 !== 'number') { showError('FieldAttributes', 'currentYearCO2EmissionsKTCO2'); }
    if (typeof obj.currentYearCAPEX !== 'number') { showError('FieldAttributes', 'currentYearCAPEX'); }
    if (typeof obj.currentYearOpexUsdMM !== 'number') { showError('FieldAttributes', 'currentYearOpexUsdMM'); }
    if (typeof obj.currentYearTotalTariffUsdMM !== 'number') { showError('FieldAttributes', 'currentYearTotalTariffUsdMM'); }
    if (typeof obj.currentYearAbexUsdMM !== 'number') { showError('FieldAttributes', 'currentYearAbexUsdMM'); }
    if (typeof obj.currentYearPreTaxRevenue !== 'number') { showError('FieldAttributes', 'currentYearPreTaxRevenue'); }
    if (typeof obj.currentYearPreTaxCashflowUsdMM !== 'number') { showError('FieldAttributes', 'currentYearPreTaxCashflowUsdMM'); }
    if (typeof obj.currentYearPostTaxCashflowUsdMM !== 'number') { showError('FieldAttributes', 'currentYearPostTaxCashflowUsdMM'); }

    this.resourceName = obj.resourceName;
    this.type = obj.type;
    this.countryName = obj.countryLabel;
    this.areaName = obj.areaName;    
    this.hubName = obj.hubName;
    this.operatorName = obj.operatorName;
    this.status = obj.status;
    this.blocks = obj.blocks;
    this.blockNo = obj.blockNo;
    this.licences = obj.licences;
    this.licence = obj.licence;
    this.fieldApprovalYear = obj.fieldApprovalYear;
    this.firstProductionYear = obj.firstProductionYear;
    this.totalRecoverableLiquidMmb = obj.totalRecoverableLiquidMmb;
    this.totalRecoverableGasBcf = obj.totalRecoverableGasBcf;
    this.totalRecoverableCombinedMmboe = obj.totalRecoverableCombinedMmboe;
    this.remainingLiquidMmb = obj.remainingLiquidMmb;
    this.remainingGasBcf = obj.remainingGasBcf;
    this.remainingCombinedMmboe = obj.remainingCombinedMmboe;
    this.reservoirAge = obj.reservoirAge;
    this.reservoirDepthFt = obj.reservoirDepthFt;
    this.trapType = obj.trapType;    
    this.pressureStatus = obj.pressureStatus;
    this.temperatureStatus = obj.temperatureStatus;
    this.licenceRound = obj.licenceRound;
    this.fieldApprovalDate = fieldApprovalMoment === null ? null : fieldApprovalMoment.format('DD/MM/YYYY');
    this.discoveryWell = obj.discoveryWell;
    this.discoveryYear = obj.discoveryYear;
    this.decommissionYear = obj.decommissionYear;
    this.lastProductionYear = obj.lastProductionYear;
    this.oilPipeline = obj.oilPipeline;
    this.gasPipeline = obj.gasPipeline;
    this.developmentConcept = obj.developmentConcept;

    this.remainingCAPEX = obj.remainingCAPEX;
    this.remainingOPEX = obj.remainingOPEX;
    this.remainingTariff = obj.remainingTariff;
    this.remainingABEX = obj.remainingABEX;
    this.remainingPreTaxRevenue = obj.remainingPreTaxRevenue;
    this.remainingPreTaxCashflow = obj.remainingPreTaxCashflow;
    this.remainingPostTaxCashflow = obj.remainingPostTaxCashflow;
    this.remainingNetPresetValue = obj.remainingNetPresetValue;   

    this.currentYearLiquidProductionMbod = obj.currentYearLiquidProductionMbod;
    this.currentYearGasProductionMmscfd = obj.currentYearGasProductionMmscfd;
    this.currentYearTotalProductionMboepd = obj.currentYearTotalProductionMboepd;
    this.currentYearCO2EmissionsKTCO2 = obj.currentYearCO2EmissionsKTCO2;
    this.currentYearCAPEX = obj.currentYearCAPEX === null ? 0 : obj.currentYearCAPEX;
    this.currentYearOpexUsdMM = obj.currentYearOpexUsdMM === null ? 0 : obj.currentYearOpexUsdMM;
    this.currentYearTotalTariffUsdMM = obj.currentYearTotalTariffUsdMM === null ? 0 : obj.currentYearTotalTariffUsdMM;    
    this.currentYearAbexUsdMM = obj.currentYearAbexUsdMM === null ? 0 : obj.currentYearAbexUsdMM;
    this.currentYearPreTaxRevenue = obj.currentYearPreTaxRevenue === null ? 0 : obj.currentYearPreTaxRevenue;
    this.currentYearPreTaxCashflowUsdMM = obj.currentYearPreTaxCashflowUsdMM === null ? 0 : obj.currentYearPreTaxCashflowUsdMM;
    this.currentYearPostTaxCashflowUsdMM = obj.currentYearPostTaxCashflowUsdMM === null ? 0 : obj.currentYearPostTaxCashflowUsdMM;

  }
}

class FieldDetail {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('FieldDetail', 'Field Detail'); }
    if (obj.fiscalResource !== null && typeof obj.fiscalResource !== 'object') { showError('FieldDetail', 'fiscalResource'); }
    if (obj.resourceDescription !== null && typeof obj.resourceDescription !== 'object') { showError('FieldDetail', 'resourceDescription'); }
    if (obj.lastChange !== null && typeof obj.lastChange !== 'object') { showError('FieldDetail', 'lastChange'); }

    if (!Array.isArray(obj.owners)) { showError('FieldDetail', 'owners'); }
    if (!Array.isArray(obj.wells)) { showError('FieldDetail', 'wells'); }
    if (!Array.isArray(obj.events)) { showError('FieldDetail', 'events'); }

    if (typeof obj.resourceId !== 'number') { showError('FieldDetail', 'resourceId'); }
    if (typeof obj.resourceName !== 'string') { showError('FieldDetail', 'resourceName'); }
    if (typeof obj.blockId !== 'number') { showError('FieldDetail', 'blockId'); }
    if (typeof obj.blockNo !== 'string') { showError('FieldDetail', 'blockNo'); }
    if (typeof obj.typeId !== 'number') { showError('FieldDetail', 'typeId'); }
    if (typeof obj.type !== 'string') { showError('FieldDetail', 'type'); }
    if (typeof obj.conditionId !== 'number') { showError('FieldDetail', 'conditionId'); }
    if (typeof obj.condition !== 'string') { showError('FieldDetail', 'condition'); }
    if (typeof obj.productivityGroupId !== 'number') { showError('FieldDetail', 'productivityGroupId'); }
    if (typeof obj.productivityGroup !== 'string') { showError('FieldDetail', 'productivityGroup'); }
    if (typeof obj.classificationId !== 'number') { showError('FieldDetail', 'classificationId'); }
    if (typeof obj.classification !== 'string') { showError('FieldDetail', 'classification'); }
    if (typeof obj.waterDepthFt !== 'number') { showError('FieldDetail', 'waterDepthFt'); }
    if (typeof obj.developmentWells !== 'number') { showError('FieldDetail', 'developmentWells'); }

    if (typeof obj.licence !== 'string') { showError('FieldDetail', 'licence'); }
    if (obj.licenceId !== null && typeof obj.licenceId !== 'number') { showError('FieldDetail', 'licenceId'); }
    let fieldApprovalMoment = null;
    if (obj.fieldApprovalDate !== null) {
      if (typeof obj.fieldApprovalDate !== 'string') { showError('FieldDetail', 'fieldApprovalDate'); }
      fieldApprovalMoment = moment(obj.fieldApprovalDate);
      if (!fieldApprovalMoment.isValid()) { showError('FieldDetail', 'fieldApprovalDate'); }
    }
    if (typeof obj.fieldApprovalYear !== 'number') { throw new Error('Invalid obj.fieldApprovalYear param supplied to Resource.ctor'); }
    if (obj.discoveryWellId !== null && typeof obj.discoveryWellId !== 'number') { throw new Error('Invalid obj.discoveryWellId param supplied to Resource.ctor'); }
    if (typeof obj.discoveryWell !== 'string') { throw new Error('Invalid obj.discoveryWell param supplied to Resource.ctor'); }
    let discoveryDateMoment = null;
    if (obj.discoveryDate !== null) {
      if (typeof obj.discoveryDate !== 'string') { showError('FieldDetail', 'discoveryDate'); }
      discoveryDateMoment = moment(obj.discoveryDate);
      if (!discoveryDateMoment.isValid()) { showError('FieldDetail', 'discoveryDateMoment'); }
    }
    if (typeof obj.discoveryYear !== 'number') { throw new Error('Invalid obj.discoveryYear param supplied to Resource.ctor'); }
    if (typeof obj.showGrossEconomicIndicators !== 'boolean') { throw new Error('Invalid obj.showGrossEconomicIndicators param supplied to Resource.ctor'); }

    // Liquids    
    if (typeof obj.oiipMmb !== 'number') { showError('FieldDetail', 'oiipMmb'); }
    if (typeof obj.sanctionedInitialLiquidMmb !== 'number') { showError('FieldDetail', 'sanctionedInitialLiquidMmb'); }
    if (typeof obj.liquidPercentRemaining !== 'number') { showError('FieldDetail', 'liquidPercentRemaining'); }

    if (typeof obj.baseTotalRecoverableLiquidMmb !== 'number') { showError('FieldDetail', 'baseTotalRecoverableLiquidMmb'); }
    if (typeof obj.baseRemainingLiquidMmb !== 'number') { showError('FieldDetail', 'baseRemainingLiquidMmb'); }
    if (typeof obj.baseLiquidPercentRemaining !== 'number') { showError('FieldDetail', 'baseLiquidPercentRemaining'); }

    // Gas
    if (typeof obj.giipBCF !== 'number') { throw new Error('Invalid obj.giipBCF param supplied to Resource.ctor'); }
    if (typeof obj.sanctionedInitialGasBCF !== 'number') { throw new Error('Invalid obj.sanctionedInitialGasBCF param supplied to Resource.ctor'); }
    if (typeof obj.gasPercentRemaining !== 'number') { throw new Error('Invalid gasPercentRemaining param supplied to Resource.ctor'); }

    if (typeof obj.baseTotalRecoverableGasBcf !== 'number') { showError('FieldDetail', 'baseTotalRecoverableGasBcf'); }
    if (typeof obj.baseRemainingGasBCF !== 'number') { showError('FieldDetail', 'baseRemainingGasBCF'); }
    if (typeof obj.baseGasPercentRemaining !== 'number') { showError('FieldDetail', 'baseGasPercentRemaining'); }

    // Combined
    if (typeof obj.totalRecoverableCombinedMmboe !== 'number') { showError('FieldDetail', 'totalRecoverableCombinedMmboe'); }
    if (typeof obj.remainingCombinedMmboe !== 'number') { showError('FieldDetail', 'remainingCombinedMmboe'); }
    if (typeof obj.combinedPercentRemaining !== 'number') { showError('FieldDetail', 'combinedPercentRemaining'); }

    if (typeof obj.baseTotalRecoverableCombinedMmboe !== 'number') { showError('FieldDetail', 'baseTotalRecoverableCombinedMmboe'); }
    if (typeof obj.baseRemainingCombinedMmboe !== 'number') { showError('FieldDetail', 'baseRemainingCombinedMmboe'); }
    if (typeof obj.baseCombinedPercentRemaining !== 'number') { showError('FieldDetail', 'baseCombinedPercentRemaining'); }

    // Technical
    if (typeof obj.reservoirAgeId !== 'number') { throw new Error('Invalid obj.reservoirAgeId param supplied to Resource.ctor'); }
    if (typeof obj.reservoirAge !== 'string') { throw new Error('Invalid obj.reservoirAge param supplied to Resource.ctor'); }
    if (typeof obj.trapTypeId !== 'number') { throw new Error('Invalid obj.trapTypeId param supplied to Resource.ctor'); }
    if (typeof obj.trapType !== 'string') { throw new Error('Invalid obj.trapType param supplied to Resource.ctor'); }
    if (typeof obj.reservoirDepthFt !== 'number') { throw new Error('Invalid obj.reservoirDepthFt param supplied to Resource.ctor'); }
    if (typeof obj.gasOilContactFtTvdss !== 'number') { throw new Error('Invalid obj.gasOilContactFtTvdss param supplied to Resource.ctor'); }
    if (typeof obj.gasWaterContactFtTvdss !== 'number') { throw new Error('Invalid obj.gasWaterContactFtTvdss param supplied to Resource.ctor'); }
    if (typeof obj.oilWaterContactFtTvdss !== 'number') { throw new Error('Invalid obj.oilWaterContactFtTvdss param supplied to Resource.ctor'); }
    if (typeof obj.contactComment !== 'string') { throw new Error('Invalid obj.contactComment param supplied to Resource.ctor'); }
    if (typeof obj.gasColumnFt !== 'number') { throw new Error('Invalid obj.gasColumnFt param supplied to Resource.ctor'); }
    if (typeof obj.oilColumnFt !== 'number') { throw new Error('Invalid obj.oilColumnFt param supplied to Resource.ctor'); }
    if (typeof obj.grossReservoirIntervalAvgFt !== 'number') { throw new Error('Invalid obj.grossReservoirIntervalAvgFt param supplied to Resource.ctor'); }
    if (typeof obj.netToGrossRatioAvgFt !== 'number') { throw new Error('Invalid obj.netToGrossRatioAvgFt param supplied to Resource.ctor'); }
    if (typeof obj.porosityAveragePercent !== 'number') { throw new Error('Invalid obj.porosityAveragePercent param supplied to Resource.ctor'); }
    if (typeof obj.permeabilityAverageMillidarcies !== 'number') { throw new Error('Invalid obj.permeabilityAverageMillidarcies param supplied to Resource.ctor'); }
    if (typeof obj.hydrocarbonSaturationPercent !== 'number') { throw new Error('Invalid obj.hydrocarbonSaturationPercent param supplied to Resource.ctor'); }
    if (typeof obj.oilDensityDegApi !== 'number') { throw new Error('Invalid obj.oilDensityDegApi param supplied to Resource.ctor'); }
    if (typeof obj.gasGravityGramsPerCc !== 'number') { throw new Error('Invalid obj.gasGravityGramsPerCc param supplied to Resource.ctor'); }
    if (typeof obj.viscosityCentipoise !== 'number') { throw new Error('Invalid obj.viscosityCentipoise param supplied to Resource.ctor'); }
    if (typeof obj.viscosityTemperatureFahrenheit !== 'number') { throw new Error('Invalid obj.viscosityTemperatureFahrenheit param supplied to Resource.ctor'); }
    if (typeof obj.gasToOilRatioScfPerBbl !== 'number') { throw new Error('Invalid obj.gasToOilRatioScfPerBbl param supplied to Resource.ctor'); }
    if (typeof obj.condensateToGasToRatioStbPerMmscf !== 'number') { throw new Error('Invalid obj.condensateToGasToRatioStbPerMmscf param supplied to Resource.ctor'); }
    if (typeof obj.initialReservoirPressurePsi !== 'number') { throw new Error('Invalid obj.initialReservoirPressurePsi param supplied to Resource.ctor'); }
    if (typeof obj.pressureStatus !== 'string') { throw new Error('Invalid obj.pressureStatus param supplied to Resource.ctor'); }
    if (typeof obj.initialReservoirPressureDepthFtTvdss !== 'number') { throw new Error('Invalid obj.initialReservoirPressureDepthFtTvdss param supplied to Resource.ctor'); }
    if (typeof obj.reservoirTemperatureFahrenheit !== 'number') { throw new Error('Invalid obj.reservoirTemperatureFahrenheit param supplied to Resource.ctor'); }
    if (typeof obj.temperatureStatus !== 'string') { throw new Error('Invalid obj.temperatureStatus param supplied to Resource.ctor'); }
    if (typeof obj.initialReservoirTemparatureDepthFtTvdss !== 'number') { throw new Error('Invalid obj.initialReservoirTemparatureDepthFtTvdss param supplied to Resource.ctor'); }
    if (typeof obj.sulphurPercent !== 'number') { throw new Error('Invalid obj.sulphurPercent param supplied to Resource.ctor'); }
    if (typeof obj.chartDataCollection !== 'object') { throw new Error('Invalid chartDataCollection param supplied to Resource.ctor'); }
    // End Technical

    // Reported
    if (typeof obj.reportedRemainingLiquidMMB !== 'number') { throw new Error('Invalid obj.reportedRemainingLiquidMMB param supplied to Resource.ctor'); }
    if (typeof obj.reportedRemainingLiquidSource !== 'string') { throw new Error('Invalid obj.reportedRemainingLiquidSource param supplied to Resource.ctor'); }
    if (typeof obj.reportedRemainingLiquidSourceDate !== 'string') { throw new Error('Invalid obj.reportedRemainingLiquidSourceDate param supplied to Resource.ctor'); }

    if (typeof obj.reportedRemainingGasBCF !== 'number') { throw new Error('Invalid obj.reportedRemainingGasBCF param supplied to Resource.ctor'); }
    if (typeof obj.reportedRemainingGasSource !== 'string') { throw new Error('Invalid obj.reportedRemainingGasSource param supplied to Resource.ctor'); }
    if (typeof obj.reportedRemainingGasSourceDate !== 'string') { throw new Error('Invalid obj.reportedRemainingGasSourceDate param supplied to Resource.ctor'); }

    if (typeof obj.reportedCombinedMmboe !== 'number') { throw new Error('Invalid obj.reportedCombinedMmboe param supplied to Resource.ctor'); }
    // End Reported

    if (typeof obj.fullView !== 'boolean') { throw new Error('Invalid fullView param supplied to Resource.ctor'); }
    if (typeof obj.valuation !== 'object') { throw new Error('Invalid valuation param supplied to Resource.ctor'); }
    if (typeof obj.showBaseReserves !== 'boolean') { throw new Error('Invalid showBaseReserves param supplied to Resource.ctor'); }
    if (typeof obj.showLiquidReportedReserves !== 'boolean') { throw new Error('Invalid showLiquidReportedReserves param supplied to Resource.ctor'); }
    if (typeof obj.showGasReportedReserves !== 'boolean') { throw new Error('Invalid showGasReportedReserves param supplied to Resource.ctor'); }
    if (typeof obj.baseValuationName !== 'string') { throw new Error('Invalid obj.baseValuationName param supplied to Resource.ctor'); }

    if (!Array.isArray(obj.blockList)) { showError('FieldDetail', 'blockList'); }
    if (!Array.isArray(obj.licenceList)) { showError('FieldDetail', 'licenceList'); }

    this.chartDataCollection = obj.chartDataCollection !== null ? new FieldDetailChartData(obj.chartDataCollection) : null;
    this.wells = obj.wells.map(obj => new DisplayWell(obj));
    this.owners = obj.owners.map(obj => new FieldParticipant(obj));
    this.events = obj.events.map(obj => new EventItem(obj));
    this.valuation = new UserValuations(obj.valuation);

    this.fiscalResource = obj.fiscalResource !== null ? new FiscalResource(obj.fiscalResource) : null;
    this.resourceId = obj.resourceId;
    this.resourceName = obj.resourceName;
    this.blockId = obj.blockId;
    this.blockNo = obj.blockNo;
    this.statusId = obj.statusId;
    this.status = obj.status;
    this.typeId = obj.typeId;
    this.type = obj.type;
    this.conditionId = obj.conditionId;
    this.condition = obj.condition;
    this.productivityGroupId = obj.productivityGroupId;
    this.productivityGroup = obj.productivityGroup;
    this.classificationId = obj.classificationId;
    this.classification = obj.classification;
    this.waterDepthFt = obj.waterDepthFt;
    this.developmentWells = obj.developmentWells;

    this.licence = obj.licence;
    this.licenceId = obj.licenceId;

    this.fieldApprovalDate = fieldApprovalMoment === null ? null : fieldApprovalMoment.format('DD/MM/YYYY');
    this.fieldApprovalYear = obj.fieldApprovalYear;
    this.discoveryWellId = obj.discoveryWellId;
    this.discoveryWell = obj.discoveryWell;
    this.discoveryDate = discoveryDateMoment === null ? null : discoveryDateMoment.format('DD/MM/YYYY');
    this.discoveryYear = obj.discoveryYear;
    this.showGrossEconomicIndicators = obj.showGrossEconomicIndicators;
    this.resourceDescription = obj.fiscalResource !== null ? new ResourceText(obj.resourceDescription) : null;
    this.lastChange = obj.fiscalResource !== null ? new ResourceText(obj.lastChange) : null;

    // Liquids
    this.oiipMmb = obj.oiipMmb;
    this.sanctionedInitialLiquidMmb = obj.sanctionedInitialLiquidMmb;
    this.liquidPercentRemaining = obj.liquidPercentRemaining;

    this.baseTotalRecoverableLiquidMmb = obj.baseTotalRecoverableLiquidMmb;
    this.baseRemainingLiquidMmb = obj.baseRemainingLiquidMmb;
    this.baseLiquidPercentRemaining = obj.baseLiquidPercentRemaining;

    // Gas
    this.giipBCF = obj.giipBCF;
    this.sanctionedInitialGasBCF = obj.sanctionedInitialGasBCF;
    this.gasPercentRemaining = obj.gasPercentRemaining;

    this.baseTotalRecoverableGasBcf = obj.baseTotalRecoverableGasBcf;
    this.baseRemainingGasBCF = obj.baseRemainingGasBCF;
    this.baseGasPercentRemaining = obj.baseGasPercentRemaining;

    // Combined
    this.totalRecoverableCombinedMmboe = obj.totalRecoverableCombinedMmboe;
    this.remainingCombinedMmboe = obj.remainingCombinedMmboe;
    this.combinedPercentRemaining = obj.combinedPercentRemaining;

    this.baseTotalRecoverableCombinedMmboe = obj.baseTotalRecoverableCombinedMmboe;
    this.baseRemainingCombinedMmboe = obj.baseRemainingCombinedMmboe;
    this.baseCombinedPercentRemaining = obj.baseCombinedPercentRemaining;

    // Technical
    this.reservoirAgeId = obj.reservoirAgeId;
    this.reservoirAge = obj.reservoirAge;
    this.trapTypeId = obj.trapTypeId;
    this.trapType = obj.trapType;
    this.reservoirDepthFt = obj.reservoirDepthFt;
    this.gasOilContactFtTvdss = obj.gasOilContactFtTvdss;
    this.gasWaterContactFtTvdss = obj.gasWaterContactFtTvdss;
    this.oilWaterContactFtTvdss = obj.oilWaterContactFtTvdss;
    this.contactComment = obj.contactComment;
    this.gasColumnFt = obj.gasColumnFt;
    this.oilColumnFt = obj.oilColumnFt;
    this.grossReservoirIntervalAvgFt = obj.grossReservoirIntervalAvgFt;
    this.netToGrossRatioAvgFt = obj.netToGrossRatioAvgFt;
    this.porosityAveragePercent = obj.porosityAveragePercent;
    this.permeabilityAverageMillidarcies = obj.permeabilityAverageMillidarcies;
    this.hydrocarbonSaturationPercent = obj.hydrocarbonSaturationPercent;
    this.oilDensityDegApi = obj.oilDensityDegApi;
    this.gasGravityGramsPerCc = obj.gasGravityGramsPerCc;
    this.viscosityCentipoise = obj.viscosityCentipoise;
    this.viscosityTemperatureFahrenheit = obj.viscosityTemperatureFahrenheit;
    this.gasToOilRatioScfPerBbl = obj.gasToOilRatioScfPerBbl;
    this.condensateToGasToRatioStbPerMmscf = obj.condensateToGasToRatioStbPerMmscf;
    this.initialReservoirPressurePsi = obj.initialReservoirPressurePsi;
    this.pressureStatus = obj.pressureStatus;
    this.initialReservoirPressureDepthFtTvdss = obj.initialReservoirPressureDepthFtTvdss;
    this.reservoirTemperatureFahrenheit = obj.reservoirTemperatureFahrenheit;
    this.temperatureStatus = obj.temperatureStatus;
    this.initialReservoirTemparatureDepthFtTvdss = obj.initialReservoirTemparatureDepthFtTvdss;
    this.sulphurPercent = obj.sulphurPercent;
    this.fullView = obj.fullView;
    this.showBaseReserves = obj.showBaseReserves;
    this.baseValuationName = obj.baseValuationName;

    // Reported
    this.reportedRemainingLiquidMMB = obj.reportedRemainingLiquidMMB;
    this.reportedRemainingLiquidSource = obj.reportedRemainingLiquidSource;
    this.reportedRemainingLiquidSourceDate = obj.reportedRemainingLiquidSourceDate;
    this.reportedRemainingGasBCF = obj.reportedRemainingGasBCF;
    this.reportedRemainingGasSource = obj.reportedRemainingGasSource;
    this.reportedRemainingGasSourceDate = obj.reportedRemainingGasSourceDate;
    this.reportedCombinedMmboe = obj.reportedCombinedMmboe;
    this.showLiquidReportedReserves = obj.showLiquidReportedReserves;
    this.showGasReportedReserves = obj.showGasReportedReserves;

    this.blockList = obj.blockList !== undefined && obj.blockList !== null ? obj.blockList.map(obj => { return { key: obj.key, value: obj.value }; }) : null;        
    this.licenceList = obj.licenceList !== undefined && obj.licenceList !== null ? obj.licenceList.map(obj => { return { key: obj.key, value: obj.value }; }) : null;
  }
}

class EventItem {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to EventItem.ctor');
    }

    if (typeof obj.year !== 'number') { throw new Error('Invalid year param supplied to EventItem.ctor'); }
    if (typeof obj.eventName !== 'string') { throw new Error('Invalid eventName param supplied to EventItem.ctor'); }

    this.year = obj.year;
    this.eventName = obj.eventName;
    this.yVal = 1;
  }
}

export { CombinedProduction, DisplayParticipant, FieldParticipant, DisplayWell, PortfolioLog, FieldDetail, handleDecimal, EventItem, FieldDetailChartData, FieldAttributes };