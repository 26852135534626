import React from 'react';
import PropTypes from 'prop-types';

import { PortfolioCashflow } from '../models/portfolio/portfolio-cashflow';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';
import { valueTypes } from '../../../shared/helpers/value-handler';

const EconomicSummaryComponent = (props) => {
  const { cashflow, currentYear, showExtendedFiscal } = props;

  //<td>Post-tax NPV{discountRate} ({valueTypes.UnitString(valueTypes.usdMM)})</td>
  //<td>Post-tax NPV{discountRate} ({valueTypes.UnitString(valueTypes.usdBOE)})</td>

  const getAnnualByYear = (theArray, year) => {
    var result = theArray.filter(x => x.year === year);
    return result !== undefined && result !== null && result.length > 0 ? result[0] : null;
  };

  const currentYearAnnual = getAnnualByYear(cashflow.cashflowAnnuals, currentYear);

  const labelWidth = 50; // Percent
  let valueWidth = 100 - labelWidth;
  if (currentYearAnnual) {
    valueWidth = valueWidth / 2;
  }

  const getRows = () => {
    const discountRate = valueTypes.ValueOnly(valueTypes.usdBOE, cashflow.discountRate);
    return (
      <React.Fragment>
        <tr style={{ fontWeight: 'bold' }}>
          <td style={{ width: labelWidth + '%' }} />
          <td style={{ width: valueWidth + '%' }}>Remaining</td>
          {currentYearAnnual && <td style={{ width: valueWidth + '%' }}>Current Year ({currentYear})</td>}
        </tr>
        <tr>
          <td>Capex ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, cashflow.remainingCAPEX)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.generic1, currentYearAnnual.costForLossesOrDepreciationUsdMM)}</td>}
        </tr>
        <tr>
          <td>Opex ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, cashflow.remainingOPEX)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.generic1, currentYearAnnual.opexUsdMM)}</td>}
        </tr>
        <tr>
          <td>Tariff ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, cashflow.remainingTariff)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.generic1, currentYearAnnual.totalTariffUsdMM)}</td>}
        </tr>
        <tr>
          <td>Abex ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, cashflow.remainingABEX)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.generic1, currentYearAnnual.abexUsdMM)}</td>}
        </tr>
        <tr>
          <td>Discounted Post-tax NPV ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, cashflow.remainingNetPresetValue)}</td>
          {currentYearAnnual && <td>-</td>}
        </tr>
        <tr>
          <td>Discounted Post-tax NPV ({valueTypes.UnitString(valueTypes.usdBOE)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, cashflow.remainingNetPresetValuePerBarrel)}</td>
          {currentYearAnnual && <td>-</td>}
        </tr>
        {showExtendedFiscal &&
          <tr>
            <td>IRR ({valueTypes.UnitString(valueTypes.percent)})</td>
            <td>{valueTypes.ValueOnly(valueTypes.generic1, cashflow.grossInternalRateOfReturnPercentage)}</td>
            {currentYearAnnual && <td>-</td>}
          </tr>
        }
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent>
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

EconomicSummaryComponent.propTypes = {
  cashflow: PropTypes.instanceOf(PortfolioCashflow).isRequired,
  currentYear: PropTypes.number.isRequired,
  showExtendedFiscal: PropTypes.bool.isRequired
};

EconomicSummaryComponent.defaultProps = {
  showExtendedFiscal: false
};

export default EconomicSummaryComponent;
