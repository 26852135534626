import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import './message-box-component.scss';
import { MessageBoxIcon, MessageBoxButton, MessageBoxButtons } from './message-box-classes';

import { appendClassNames } from '../../helpers/common';

const MessageBoxComponent = (props) => {
  const { clear, message } = props;

  //const onKeyUp = (e) => {
  //  if (e.key === 'Escape' && typeof clear === 'function' && message.showFooter === true) {
  //    clear();
  //  }
  //}

  //React.useEffect(() => {
  //  document.addEventListener("keyup", onKeyUp, false);
  //  return () => document.removeEventListener("keyup", onKeyUp, false);
  //}, [props.data])

  if (message === undefined || message === null) {
    return null;
  }

  const displayName = MessageBoxIcon.displayName(message.icon);
  let header = message.header;
  if ((header === undefined || header === null) && message.icon !== MessageBoxIcon.None) {
    header = displayName + '...';
  }
  const buttonClick = (button) => {
    if (message.callback(button) !== false) {
      clear();
    };
  };

  const buttons = message.buttons.map(button => {
    return {
      key: typeof button === 'string' ? -1 : button,
      value: typeof button === 'string' ? button : MessageBoxButton.displayName(button)
    };
  });
  const customButtons = buttons.filter(button => button.key === -1);
  for (var loop = 0; loop < customButtons.length; loop++) {
    customButtons[loop].key += -loop;
  }

  const className = appendClassNames('message-box-component', props.className) +
    (message.icon === MessageBoxIcon.None ? ' no-icon' : ' ' + displayName.toLowerCase() + '-icon') +
    (message.size === 'full-screen' ? ' modal-full-screen' : '');

  const showFooter = message.showFooter !== undefined && message.showFooter !== '' ? message.showFooter : true;

  const footer = !showFooter ?
    null
    :
    <ModalFooter>
      {buttons.map(kvp => (
        <Button key={'button' + kvp.key} onClick={() => buttonClick(kvp.key)}>{kvp.value}</Button>
      ))}
    </ModalFooter>;

  const modalHeader = !showFooter ?
    <ModalHeader>{header}</ModalHeader>
    : <ModalHeader toggle={() => buttonClick(MessageBoxButton.Close)}>{header}</ModalHeader>

  return (
    <Modal isOpen centered size={message.size === 'full-screen' ? '' : message.size} className={className}>
      {modalHeader}
      <ModalBody>
        {MessageBoxIcon.getIcon(message.icon)}
        <div className="message-body">{message.body}</div>
      </ModalBody>
      {footer}
    </Modal>
  );
};

MessageBoxComponent.propTypes = {
  message: PropTypes.shape({
    body: PropTypes.node.isRequired,
    buttons: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    callback: PropTypes.func.isRequired,
    header: PropTypes.node,
    icon: PropTypes.number.isRequired,
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'full-screen']).isRequired
  }),
  clear: PropTypes.func.isRequired
};

const createMessage = (body, callback, buttons = [MessageBoxButton.OK], header = null, icon = MessageBoxIcon.None, size = 'md') => {
  return { body: body, callback: callback, buttons: buttons, header: header, icon: icon, size: size };
};

const createError = (body, callback, buttons = [MessageBoxButton.OK], header = null, size = 'md') => {
  return { body: body, callback: callback, buttons: buttons, header: header, icon: MessageBoxIcon.Error, size: size };
};

const createQuestion = (body, callback, buttons = [MessageBoxButton.OK], header = null, size = 'md') => {
  return { body: body, callback: callback, buttons: buttons, header: header, icon: MessageBoxIcon.Question, size: size };
};

const createWarning = (body, callback, buttons = [MessageBoxButton.OK], header = null, size = 'md') => {
  return { body: body, callback: callback, buttons: buttons, header: header, icon: MessageBoxIcon.Warning, size: size };
};

const createInformation = (body, callback, buttons = [MessageBoxButton.OK], header = null, size = 'md') => {
  return { body: body, callback: callback, buttons: buttons, header: header, icon: MessageBoxIcon.Information, size: size };
};

export default MessageBoxComponent;
export {
  createMessage,
  createError,
  createQuestion,
  createWarning,
  createInformation,
  MessageBoxIcon,
  MessageBoxButton,
  MessageBoxButtons
};
