import React from 'react';
import PropTypes from 'prop-types';

import { appViews } from '../../../app/app-views';
import { FieldDetail } from '../../../components/fiscal/models/portfolio/faster-portfolio';
import CompletedActiveWellsTableComponent from '../../shared/completed-active-wells-table-component';
import TechnicalComponent from '../../../shared/components/resource-technical-component/technical-component';
import { createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedTechnicalComponent = (props) => {
  const { resource } = props;

  return (
    <div ref={props.innerRef}>
      {createSection100(<TechnicalComponent resource={resource} />, 'Technical details')}
      {createSection100(<CompletedActiveWellsTableComponent wells={resource.wells} onWellClick={props.onWellClick} onParticipantClick={props.onCorporateClick} openReport={props.openReport} />, appViews.getDisplayName(appViews.Well))}
    </div>
  );
};

AdvancedTechnicalComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  resource: PropTypes.instanceOf(FieldDetail).isRequired,
  onWellClick: PropTypes.func,
  onCorporateClick: PropTypes.func,
  openReport: PropTypes.func
};

export default AdvancedTechnicalComponent;
