import { BaseFilter } from "../base/base-filter";

class HubFilter extends BaseFilter {
  constructor() {
    super();

    this._hubIds = undefined;
    this._countryIds = undefined;
    this._areaIds = undefined;
    this._operatorIds = undefined;
    this._participantIds = undefined;
    this._liquidExportIds = undefined;
    this._gasExportIds = undefined;
    this._minRemainingReserves = undefined;
    this._maxRemainingReserves = undefined;
    this._minProduction = undefined;
    this._maxProduction = undefined;
    this._copStartYear = undefined;
    this._copEndYear = undefined;
    this._DevelopmentStatus = undefined;
    this._ElectrificationStatus = undefined;
    this.clear();
  }

  get isSet() {
    const result = super.isSet === true ||
      this.hubIdsIsSet === true ||
      this.countryIdsIsSet === true ||
      this.areaIdsIsSet === true ||
      this.operatorIdsIsSet === true ||
      this.participantIdsIsSet === true ||
      this.liquidExportIdsIsSet === true ||
      this.gasExportIdsIsSet === true ||
      this.minRemainingReservesIsSet === true ||
      this.maxRemainingReservesIsSet === true ||
      this.minProductionIsSet === true ||
      this.maxProductionIsSet === true ||
      this.copStartYearIsSet === true ||
      this.copEndYearIsSet === true ||
      this.developmentStatusIsSet === true ||
      this.electrificationStatusIsSet === true;
    return result;
  }

  get liquidExportIds() { return this._liquidExportIds; }
  set liquidExportIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "HubFilter.liquidExportIds.set"');
    }

    if (this._liquidExportIds !== value) {
      this._liquidExportIds = value;
    }
  }
  get liquidExportIdsIsSet() { return this._liquidExportIds.length > 0; }

  get gasExportIds() { return this._gasExportIds; }
  set gasExportIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "HubFilter.gasExportIds.set"');
    }

    if (this._gasExportIds !== value) {
      this._gasExportIds = value;
    }
  }
  get gasExportIdsIsSet() { return this._gasExportIds.length > 0; }

  get hubIds() { return this._hubIds; }
  set hubIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "HubFilter.hubIds.set"');
    }

    if (this._hubIds !== value) {
      this._hubIds = value;
    }
  }
  get hubIdsIsSet() { return this._hubIds.length > 0; }

  get countryIds() { return this._countryIds; }
  set countryIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "HubFilter.countryIds.set"');
    }

    if (this._countryIds !== value) {
      this._countryIds = value;
    }
  }
  get countryIdsIsSet() { return this._countryIds.length > 0; }

  get areaIds() { return this._areaIds; }
  set areaIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "HubFilter.areaIds.set"');
    }

    if (this._areaIds !== value) {
      this._areaIds = value;
    }
  }
  get areaIdsIsSet() { return this._areaIds.length > 0; }

  get operatorIds() { return this._operatorIds; }
  set operatorIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "HubFilter.operatorIds.set"');
    }

    if (this._operatorIds !== value) {
      this._operatorIds = value;
    }
  }
  get operatorIdsIsSet() { return this._operatorIds.length > 0; }

  get participantIds() { return this._participantIds; }
  set participantIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "HubFilter.participantIds.set"');
    }

    if (this._participantIds !== value) {
      this._participantIds = value;
    }
  }
  get participantIdsIsSet() { return this._participantIds.length > 0; }

  get minRemainingReserves() { return this._minRemainingReserves; }
  set minRemainingReserves(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "HubFilter.minRemainingReserves.set"');
    }

    if (this._minRemainingReserves !== value) {
      this._minRemainingReserves = value;
    }
  }
  get minRemainingReservesIsSet() { return this._minRemainingReserves !== null; }

  get maxRemainingReserves() { return this._maxRemainingReserves; }
  set maxRemainingReserves(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "HubFilter.maxRemainingReserves.set"');
    }

    if (this._maxRemainingReserves !== value) {
      this._maxRemainingReserves = value;
    }
  }
  get maxRemainingReservesIsSet() { return this._maxRemainingReserves !== null; }

  get minProduction() { return this._minProduction; }
  set minProduction(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "HubFilter.minProduction.set"');
    }

    if (this._minProduction !== value) {
      this._minProduction = value;
    }
  }
  get minProductionIsSet() { return this._minProduction !== null; }

  get maxProduction() { return this._maxProduction; }
  set maxProduction(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "HubFilter.maxProduction.set"');
    }

    if (this._maxProduction !== value) {
      this._maxProduction = value;
    }
  }
  get maxProductionIsSet() { return this._maxProduction !== null; }

  get copStartYear() { return this._copStartYear; }
  set copStartYear(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "HubFilter.copStartYear.set"');
    }

    if (this._copStartYear !== value) {
      this._copStartYear = value;
    }
  }
  get copStartYearIsSet() { return this._copStartYear !== null; }

  get copEndYear() { return this._copEndYear; }
  set copEndYear(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "HubFilter.copEndYear.set"');
    }

    if (this._copEndYear !== value) {
      this._copEndYear = value;
    }
  }
  get copEndYearIsSet() { return this._copEndYear !== null; }

  get developmentStatusIds() { return this._developmentStatusIds; }
  set developmentStatusIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "HubFilter.developmentStatusIds.set"');
    }

    if (this._developmentStatusIds !== value) {
      this._developmentStatusIds = value;
    }
  }
  get developmentStatusIsSet() { return this._developmentStatusIds.length > 0; }

  get electrificationStatusIds() { return this._electrificationStatusIds; }
  set electrificationStatusIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "HubFilter.electrificationStatusIds.set"');
    }

    if (this._electrificationStatusIds !== value) {
      this._electrificationStatusIds = value;
    }
  }
  get electrificationStatusIsSet() { return this._electrificationStatusIds.length > 0; }

  clear() {
    super.clear();

    this._hubIds = [];
    this._countryIds = [];
    this._areaIds = [];
    this._operatorIds = [];
    this._participantIds = [];
    this._liquidExportIds = [];
    this._gasExportIds = [];
    this._minRemainingReserves = null;
    this._maxRemainingReserves = null;
    this._minProduction = null;
    this._maxProduction = null;
    this._copStartYear = null;
    this._copEndYear = null;
    this._developmentStatusIds = [];
    this._electrificationStatusIds = [];
  }

  clone() {
    const result = new HubFilter();
    super.clone(result);
    result._hubIds = [...this._hubIds];
    result._countryIds = [...this._countryIds];
    result._areaIds = [...this._areaIds];
    result._operatorIds = [...this._operatorIds];
    result._participantIds = [...this._participantIds];
    result._liquidExportIds = [...this._liquidExportIds];
    result._gasExportIds = [...this._gasExportIds];
    result._minRemainingReserves = this._minRemainingReserves;
    result._maxRemainingReserves = this._maxRemainingReserves;
    result._minProduction = this._minProduction;
    result._maxProduction = this._maxProduction;
    result._copStartYear = this._copStartYear;
    result._copEndYear = this._copEndYear;
    result._developmentStatusIds = this._developmentStatusIds;
    result._electrificationStatusIds = this._electrificationStatusIds;
    return result;
  }

  clearInvalidValues() {
    if (this.copStartYearIsSet && this.copEndYearIsSet && this._copStartYear > this._copEndYear) {
      this.copStartYear = null;
      this.copEndYear = null;
    }

    if (this.minRemainingReservesIsSet && this.maxRemainingReservesIsSet && this._minRemainingReserves > this._maxRemainingReserves) {
      this.minRemainingReserves = null;
      this.maxRemainingReserves = null;
    }

    if (this.minProductionIsSet && this.maxProductionIsSet && this._minProduction > this._maxProduction) {
      this.minProduction = null;
      this.maxProduction = null;
    }
  }

  toApiParam() {
    const result = super.toApiParam();
    result.hubIds = [...this._hubIds];
    result.countryIds = [...this._countryIds];
    result.areaIds = [...this._areaIds];
    result.operatorIds = [...this._operatorIds];
    result.participantIds = [...this._participantIds];
    result.liquidExportIds = [...this._liquidExportIds];
    result.gasExportIds = [...this._gasExportIds];
    result.minRemainingReserves = this._minRemainingReserves;
    result.maxRemainingReserves = this._maxRemainingReserves;
    result.minProduction = this._minProduction;
    result.maxProduction = this._maxProduction;
    result.copStartYear = this._copStartYear;
    result.copEndYear = this._copEndYear;
    result.developmentStatusIds = [...this._developmentStatusIds];
    result.electrificationStatusIds = [...this._electrificationStatusIds];
    return result;
  }
}

export { HubFilter };
