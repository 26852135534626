import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';

import { CorporateFilter } from './corporate-filter';
import CorporateGridComponent, { CorporateGridExcelExport } from './corporate-grid-component';
import CorporateComparisonGridComponent, { CorporateComparisonGridExcelExport } from './corporate-comparison-grid-component';
//import OverviewComponent from './overview-component';
import ComparisonComponent from '../../sharedComponents/comparison-component';

import BaseViewComponent, { LeafletHelper, ContextLayerModel } from '../base/base-view-component';

import { appViews } from '../../app/app-views';
import { AppSetState } from '../../app/app-set-state';
import { searchResultType } from '../../shared/helpers/leaflet/leaflet-helper';
import { FasterPortfolioCorporateSummary } from '../../components/fiscal/models/portfolio/faster-portfolio';
import { SearchResultsExcelExport } from '../../map/search-results-excel-export';
import { CorporateValuationComparison } from '../../models/valuation-comparison';

class CorporateViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.leafletHelper = new LeafletHelper(this.props.geoserverUrl, 'corporate-view-component-map', new SearchResultsExcelExport(this.props.appSetState, appViews.Corporate).export);
    this.state = { contextLayers: [], dataRows: [], filterEnabled: false, settingsEnabled: false, comparisonDataRows: [], valuationOneId: '', valuationTwoId: '' };
    this.props.appSetState.appValuation.clearDetailValuation();
    // force the main grid to be shown when the Field View is clicked
    this.props.settings.showComparison = false;
    this.props.settings.showOverview = false;
    this.props.settings.showGrid = true;
  }

  componentDidMount() {
    const valuationId = this.props.appSetState.appValuation.valuation.variableId;
    const url = 'api/corporate/portfolio/load-all?excel=false&valuationId=' + valuationId;
    const onSuccess = (result) => {
      this.props.setAdvancedPlus(result.advancedPlus);

      this.setState({   
        contextLayers: ContextLayerModel.FromArray(result.contextLayers),
        dataRows: FasterPortfolioCorporateSummary.FromArray(result.dataRows),
        comparisonDataRows: [],
        valuationOneId: '',
        valuationTwoId: '',
        advanced: result.advancedPlus === true,
      }, this.initialSetMapPosition);
    };
    this.props.appSetState.api.post(url, this.props.filter.toApiParam(), onSuccess, undefined, true);
    this.loadFilter();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter
      || this.props.appSetState.appValuation.valuationHasChanged === true) {
      this.props.appSetState.appValuation.valuationHasChanged = false;
      this.update();
    }
  }

  componentWillUnmount() {
    if (this.leafletHelper !== null && this.leafletHelper !== undefined) {
      this.leafletHelper.destroy();
      delete this.leafletHelper;
    }
  }

  loadFilter = () => {
    const url = 'api/corporate/filters/load';
    const onSuccess = () => {
      this.setState({ filterEnabled: true, settingsEnabled: true });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true, false);
  }

  update = () => {
    const valuationId = this.props.appSetState.appValuation.valuation.variableId;
    const url = 'api/corporate/portfolio/load?valuationId=' + valuationId;

    const onSuccess = (result) => {
      this.setState({
        dataRows: FasterPortfolioCorporateSummary.FromArray(result),
        comparisonDataRows: []
      }, this.setMapPosition);
    };
    this.props.appSetState.api.post(url, this.props.filter.toApiParam(), onSuccess, undefined, true);
  }

  initialSetMapPosition = () => {
    const { filter } = this.props;

    if (filter.mapBoundsIsSet) {
      this.leafletHelper.leafletMap.fitBounds(filter.mapBounds);
    } else {
      this.setMapPosition();
    }
  }

  setMapPosition = () => {
    const { dataRows } = this.state;

    if (this.props.filter.followMap === false) {
      if (dataRows.length > 0) {
        const bounds = L.latLngBounds([]);
        dataRows.forEach(obj => bounds.extend(obj.shapes.latLngs));
        this.leafletHelper.flyToBounds(bounds);
      }
    }
  }

  showOnMap = (dataRow) => {
    this.leafletHelper.flyToBounds(L.latLngBounds(dataRow.shapes.latLngs), searchResultType.defaultZoomLevel(searchResultType.Corporate));
  }

  excelExport = () => {
    if (this.props.settings.showComparison && this.state.comparisonDataRows !== null) {
      let { valuationOneId, valuationTwoId } = this.state;
      let comparisonGridExcelExport = new CorporateComparisonGridExcelExport();
      comparisonGridExcelExport.export(this.props.appSetState, this.state.comparisonDataRows, valuationOneId, valuationTwoId);
    }
    else {
      new CorporateGridExcelExport().export(this.props.appSetState, this.state.dataRows, null, null, null, this.props.appSetState.appValuation.valuation, this.props.appSetState.appValuation.baseValuation);
    }
  }

  onValuationOneChange = (value) => {
    this.setState({ valuationOneId: value, comparisonDataRows: [] });
  }

  onValuationTwoChange = (value) => {
    this.setState({ valuationTwoId: value, comparisonDataRows: [] });
  }

  onComparison = () => {
    const { valuationOneId, valuationTwoId } = this.state;
    if (valuationOneId > 0 && valuationTwoId > 0 && valuationOneId !== valuationTwoId) {
      const url = 'api/corporate/portfolio/compare?valuationOneId=' + valuationOneId + '&valuationTwoId=' + valuationTwoId;
      const onSuccess = (result) => {
        this.setState({
          comparisonDataRows: CorporateValuationComparison.FromArray(result.dataRows),
        }, this.setMapPosition);
      };
      this.props.appSetState.api.post(url, this.props.filter.toApiParam(), onSuccess, undefined, true);
    }
  }

  invalidateComparison = (valuationOneInvalid, valuationTwoInvalid) => {
    const { valuationOneId, valuationTwoId } = this.state;
    let val1 = valuationOneInvalid === true ? '' : valuationOneId;
    let val2 = valuationTwoInvalid === true ? '' : valuationTwoId;

    this.setState({ valuationOneId: val1, valuationTwoId: val2, comparisonDataRows: [] });
  }

  clearComparisonGrid = () => {
    this.setState({ comparisonDataRows: [] });
  }

  render() {
    const { dataRows, comparisonDataRows, valuationOneId, valuationTwoId } = this.state;

    const overview = undefined;
    //const overview = (
    //  <OverviewComponent
    //    appSetState={this.props.appSetState}
    //    dataRows={dataRows}
    //  />
    //);

    const comparisonGrid = (
      <CorporateComparisonGridComponent
        appSetState={this.props.appSetState}
        dataRows={comparisonDataRows}
        currentYear={this.props.settings.currentYear}
        getFilter={() => this.props.filter}       
      />
    );

    const grid = (
      <CorporateGridComponent
        appSetState={this.props.appSetState}
        dataRows={dataRows}
        showOnMap={this.showOnMap}
        currentYear={this.props.settings.currentYear}
        getFilter={() => this.props.filter}
      />
    );

    const comparison = (
      <ComparisonComponent
        appSetState={this.props.appSetState}
        onComparison={this.onComparison}
        comparisonGrid={comparisonGrid}
        valuationOneId={valuationOneId}
        valuationTwoId={valuationTwoId}
        onValuationOneChange={this.onValuationOneChange}
        onValuationTwoChange={this.onValuationTwoChange}
        invalidateComparison={this.invalidateComparison}
        clearComparisonGrid={this.clearComparisonGrid}
        isAdvanced={this.state.advanced}
      />
    );

    return (
      <BaseViewComponent
        className="corporate-view-component"
        leafletHelper={this.leafletHelper}
        contextLayers={this.state.contextLayers}
        shapes={dataRows.map(obj => obj.shapes)}
        appView={appViews.Corporate}
        appSetState={this.props.appSetState}
        update={this.update}
        settings={this.props.settings}
        filter={this.props.filter}
        showFiltersButton
        showFilters={this.props.showFilters}
        filterButtonEnabled={this.state.filterEnabled}
        setShowFilters={this.props.setShowFilters}

        showSettingsButton
        settingsButtonEnabled={this.state.settingsEnabled}
        showSettings={this.props.showSettings}
        setShowSettings={this.props.setShowSettings}

        showFollowMapButton
        followMap={this.props.followMap}
        setFollowMap={this.props.setFollowMap}
        overview={overview}
        comparison={comparison}
        grid={grid}
        excelExport={this.excelExport}
        reset={this.props.reset}
      />
    );
  }
}

CorporateViewComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    showMap: PropTypes.bool.isRequired,
    showOverview: PropTypes.bool.isRequired,
    showComparison: PropTypes.bool.isRequired
  }).isRequired,
  filter: PropTypes.instanceOf(CorporateFilter).isRequired,
  showFilters: PropTypes.bool.isRequired,
  setShowFilters: PropTypes.func.isRequired,
  followMap: PropTypes.bool.isRequired,
  setFollowMap: PropTypes.func.isRequired,
  reset: PropTypes.func,
  setAdvancedPlus: PropTypes.func.isRequired
};

export default CorporateViewComponent;
