import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'reactstrap';

import AdvancedCashflowComponent from './advanced-cashflow-component';
import AdvancedKeyFactsComponent from './advanced-key-facts-component';
import AdvancedTechnicalComponent from './advanced-technical-component';
import AdvancedParticipationComponent from './advanced-participation-component';
import AdvancedReservesAndProductionComponent from './advanced-reserves-and-production-component';
import AdvancedLastUpdateComponent from './advanced-last-update-component';
import AdvancedEmissionsComponent from '../../../components/fiscal/emissions/advanced-emissions-component';

import { objectToEnum } from '../../../shared/helpers/common';
import { browserIsMicrosoft } from '../../../shared/helpers/browser-detect';
import { ContextLayerModel } from '../../../shared/helpers/leaflet/leaflet-helper';
import { FasterPortfolio, FieldDetail } from '../../../components/fiscal/models/portfolio/faster-portfolio';
import DetailViewGridLayoutComponent from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import PriceSettingsComponent from '../../../sharedComponents/price-settings-component';

const sections = objectToEnum({
  KeyFacts: 0,
  ReservesAndProduction: 1,
  Participation: 2,
  Cashflow: 3,
  LastUpdate: 4,
  Technical: 5,
  Emissions: 6
}, false);

sections.getDisplayName = (value) => {
  switch (value) {
    case sections.KeyFacts:
      return 'Key Facts';
    case sections.ReservesAndProduction:
      return 'Reserves & Production';
    case sections.Participation:
      return 'Participation';
    case sections.Cashflow:
      return 'Cashflow';
    case sections.LastUpdate:
      return 'Last Update';
    case sections.Technical:
      return 'Technical Details';
    case sections.Emissions:
      return 'Emissions';
    default:
      throw new Error('Invalid "value" param supplied to "sections.getDisplayName"');
  }
};

Object.freeze(sections);

class AdvancedFieldComponent extends React.Component {
  constructor(props) {
    super(props);

    this._scrollOffset = browserIsMicrosoft() ? 10 : 5;
    this.handleContentScroll = this.handleContentScroll.bind(this);
    this.contentRef = React.createRef();
    this.toolbarRef = React.createRef();
    this.keyFactsRef = React.createRef();
    this.reservesAndProductionRef = React.createRef();
    this.participationRef = React.createRef();
    this.cashflowRef = React.createRef();
    this.lastUpdateRef = React.createRef();
    this.technicalRef = React.createRef();
    this.emissionsRef = React.createRef();
    this.state = { selectedSection: null };
  }

  componentDidMount() {
    this.contentRef.current.addEventListener('scroll', this.handleContentScroll, true);
    this.handleContentScroll();
  }

  componentWillUnmount() {
    this.contentRef.current.removeEventListener('scroll', this.handleContentScroll);
  }

  getSectionInView() {
    const bottom = this.toolbarRef.current.getBoundingClientRect().bottom + this._scrollOffset;

    let section = sections.Emissions;
    let sectionTop = this.getSectionTop(section);
    if (sectionTop !== null && sectionTop <= bottom) {
      return section;
    }

    //fix for when the bottom section is not big enough to fill the panel
    var detailViewPanel = document.getElementById('detailViewGrid');
    if((detailViewPanel.offsetHeight + detailViewPanel.scrollTop) >= detailViewPanel.scrollHeight) {
      return section;
    }

    section = sections.Technical;
    sectionTop = this.getSectionTop(section);
    if (sectionTop !== null && sectionTop <= bottom) {
      return section;
    }

    section = sections.LastUpdate;
    sectionTop = this.getSectionTop(section);
    if (sectionTop !== null && sectionTop <= bottom) {
      return section;
    }

    section = sections.Cashflow;
    sectionTop = this.getSectionTop(section);
    if (sectionTop !== null && sectionTop <= bottom) {
      return section;
    }

    section = sections.Participation;
    sectionTop = this.getSectionTop(section);
    if (sectionTop !== null && sectionTop <= bottom) {
      return section;
    }

    section = sections.ReservesAndProduction;
    sectionTop = this.getSectionTop(section);
    if (sectionTop !== null && sectionTop <= bottom) {
      return section;
    }

    return sections.KeyFacts;
  }

  getRef(section) {
    switch (section) {
      case sections.KeyFacts:
        return this.keyFactsRef;
      case sections.ReservesAndProduction:
        return this.reservesAndProductionRef;
      case sections.Participation:
        return this.participationRef;
      case sections.Cashflow:
        return this.cashflowRef;
      case sections.LastUpdate:
        return this.lastUpdateRef;
      case sections.Technical:
        return this.technicalRef;
      case sections.Emissions:
        return this.emissionsRef;
      default:
        throw new Error('Invalid "section" param supplied to "AdvancedFieldComponent.getRef"');
    }
  }

  getSectionTop(section) {
    const ref = this.getRef(section);
    if (ref.current === null) {
      return null;
    }

    const sectionRect = ref.current.getBoundingClientRect();
    const result = sectionRect.top;
    return result;
  }

  handleContentScroll() {
    const sectionInView = this.getSectionInView();
    this.setState(prevState => {
      return prevState.selectedSection === sectionInView
        ? null
        : { selectedSection: sectionInView };
    })
  }

  btnClick(section) {
    this.getRef(section).current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }

  getSectionButton(section) {
    const { selectedSection } = this.state;

    if (section === sections.Cashflow && !this.props.resource.fiscalResource.hasFiscal) {
      return null;
    }

    if (section === sections.LastUpdate
      && (this.props.resource === null
        || this.props.resource.fiscalResource === null
        || this.props.resource.fiscalResource.hub === null
        || this.props.resource.fiscalResource.hub.clientChangeDescription === null
        || this.props.resource.fiscalResource.hub.clientChangeDescription === ''
      )) { return null; }

    const result = (
      <Button key={'section-btn-' + section} active={section === selectedSection} style={{ width: '150px', marginRight: '3px', borderRadius: '0px', border: 'none' }} onClick={() => this.btnClick(section)}>
        {sections.getDisplayName(section)}
      </Button>
    );
    return result;
  }

  getSection(section) {
    if (section === sections.Cashflow && !this.props.resource.fiscalResource.hasFiscal) {
      return null;
    }

    if (section === sections.LastUpdate
      && (this.props.resource === null
      || this.props.resource.fiscalResource === null
      || this.props.resource.fiscalResource.hub === null
      || this.props.resource.fiscalResource.hub.clientChangeDescription === null
      || this.props.resource.fiscalResource.hub.clientChangeDescription === ''
      )) { return null; }

    const _props = {
      key: 'section-' + section,
      innerRef: this.getRef(section),
      resource: this.props.resource,
    };

    switch (section) {
      case sections.KeyFacts:
        return <AdvancedKeyFactsComponent {..._props} portfolio={this.props.portfolio} onHubClick={this.props.onHubClick} onWellClick={this.props.onWellClick} onLicenceClick={this.props.onLicenceClick} onBlockClick={this.props.onBlockClick} geoserverUrl={this.props.geoserverUrl} contextLayers={this.props.contextLayers} />;
      case sections.ReservesAndProduction:
        return <AdvancedReservesAndProductionComponent {..._props} portfolio={this.props.portfolio} />;
      case sections.Participation:
        return <AdvancedParticipationComponent {..._props} portfolio={this.props.portfolio} onCorporateClick={this.props.onCorporateClick} />;
      case sections.Cashflow:
        return <AdvancedCashflowComponent {..._props} portfolio={this.props.portfolio} />;
      case sections.LastUpdate:
        return <AdvancedLastUpdateComponent {..._props} />;
      case sections.Technical:
        return <AdvancedTechnicalComponent {..._props} onWellClick={this.props.onWellClick} onCorporateClick={this.props.onCorporateClick} openReport={this.props.openReport} />;
      case sections.Emissions:
        return <AdvancedEmissionsComponent {..._props} emissions={_props.resource.chartDataCollection.emissions} emissionsComment={_props.resource.fiscalResource.hub.emissionsComment} />;
      default:
        throw new Error('Invalid "section" param supplied to "AdvancedFieldComponent.getSection"');
    }
  }

  _updateSettings = () => {
    this.props.closeSettings();
    this.props.update(this.props.appSetState, this.props.resource.resourceId);
  };

  render() {
    const newSection = this.props.showSettings
      ? <PriceSettingsComponent appSetState={this.props.appSetState} isDetail onUpdate={this._updateSettings} advancedPlus={this.props.advancedPlus}  />
      : null;
    return (
      <div style={{ height: '100%' }}>
        {newSection}
        <div ref={this.toolbarRef} style={{ height: '60px', padding: '5px', textAlign: 'center' }}>
          <div style={{ fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'left', position: 'relative', marginLeft: '2px', width: '90%' }} className="valuation-banner">
            Valuation Settings: 
            <small style={{ marginLeft: '2px', textAlign: 'left', position: 'relative', fontSize: '1.25rem' }}><a alt="" onClick={() => this.props.openSettings()} title="Show Valuations"> {this.props.currentDetailValuationName}</a></small>
          </div>
          <ButtonGroup style={{top: '5px', float: 'left'}}>
            {sections.map(obj => this.getSectionButton(obj))}  
          </ButtonGroup> 
        </div>
        <div ref={this.contentRef} style={{ height: 'calc(100% - 60px)', overflow: 'auto' }}>
          <DetailViewGridLayoutComponent>
            {sections.map(obj => this.getSection(obj))}
          </DetailViewGridLayoutComponent>
        </div>
      </div>
    );
  }
}

AdvancedFieldComponent.propTypes = {
  portfolio: PropTypes.instanceOf(FasterPortfolio).isRequired,
  resource: PropTypes.instanceOf(FieldDetail).isRequired,
  onHubClick: PropTypes.func,
  onWellClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onCorporateClick: PropTypes.func,
  geoserverUrl: PropTypes.string.isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
  appSetState: PropTypes.instanceOf(Object).isRequired,
  closeSettings: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  openReport: PropTypes.func
};

export default AdvancedFieldComponent;
