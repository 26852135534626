import { BaseFilter } from "../base/base-filter";

class LicenceFilter extends BaseFilter {
  constructor() {
    super();

    this._licenceIds = undefined;
    this._countryIds = undefined;
    this._areaIds = undefined;
    this._roundIds = undefined;
    this._statusIds = undefined;
    this._typeIds = undefined;
    this._participantIds = undefined;
    this.clear();
  }

  get isSet() {
    const result = super.isSet === true ||
      this.licenceIdsIsSet === true ||
      this.countryIdsIsSet === true ||
      this.areaIdsIsSet === true ||
      this.roundIdsIsSet === true ||
      this.statusIdsIsSet === true ||
      this.typeIdsIsSet === true ||
      this.participantIdsIsSet === true;
    return result;
  }

  get licenceIds() { return this._licenceIds; }
  set licenceIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "LicenceFilter.licenceIds.set"');
    }

    if (this._licenceIds !== value) {
      this._licenceIds = value;
    }
  }
  get licenceIdsIsSet() { return this._licenceIds.length > 0; }

  get countryIds() { return this._countryIds; }
  set countryIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "LicenceFilter.countryIds.set"');
    }

    if (this._countryIds !== value) {
      this._countryIds = value;
    }
  }
  get countryIdsIsSet() { return this._countryIds.length > 0; }

  get areaIds() { return this._areaIds; }
  set areaIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "LicenceFilter.areaIds.set"');
    }

    if (this._areaIds !== value) {
      this._areaIds = value;
    }
  }
  get areaIdsIsSet() { return this._areaIds.length > 0; }

  get roundIds() { return this._roundIds; }
  set roundIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "LicenceFilter.roundIds.set"');
    }

    if (this._roundIds !== value) {
      this._roundIds = value;
    }
  }
  get roundIdsIsSet() { return this._roundIds.length > 0; }

  get statusIds() { return this._statusIds; }
  set statusIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "LicenceFilter.statusIds.set"');
    }

    if (this._statusIds !== value) {
      this._statusIds = value;
    }
  }
  get statusIdsIsSet() { return this._statusIds.length > 0; }

  get typeIds() { return this._typeIds; }
  set typeIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "LicenceFilter.typeIds.set"');
    }

    if (this._typeIds !== value) {
      this._typeIds = value;
    }
  }
  get typeIdsIsSet() { return this._typeIds.length > 0; }

  get participantIds() { return this._participantIds; }
  set participantIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "LicenceFilter.participantIds.set"');
    }

    if (this._participantIds !== value) {
      this._participantIds = value;
    }
  }
  get participantIdsIsSet() { return this._participantIds.length > 0; }

  clear() {
    super.clear();

    this._licenceIds = [];
    this._countryIds = [];
    this._areaIds = [];
    this._roundIds = [];
    this._statusIds = [];
    this._typeIds = [];
    this._participantIds = [];
  }

  clone() {
    const result = new LicenceFilter();
    super.clone(result);
    result._licenceIds = [...this._licenceIds];
    result._countryIds = [...this._countryIds];
    result._areaIds = [...this._areaIds];
    result._roundIds = [...this._roundIds];
    result._statusIds = [...this._statusIds];
    result._typeIds = [...this._typeIds];
    result._participantIds = [...this._participantIds];
    return result;
  }

  toApiParam() {
    const result = super.toApiParam();
    result.licenceIds = [...this.licenceIds];
    result.countryIds = [...this._countryIds];
    result.areaIds = [...this._areaIds];
    result.roundIds = [...this._roundIds];
    result.statusIds = [...this._statusIds];
    result.typeIds = [...this._typeIds];
    result.participantIds = [...this._participantIds];
    return result;
  }
}

export { LicenceFilter };