import { appViews } from "../../app/app-views";
import { AppSetState } from "../../app/app-set-state";

import { valueTypes } from '../../shared/helpers/value-handler';
import { BaseExcelExportColumn, BaseExcelExport } from "../../shared/helpers/base-excel-export";

class GridExcelExport {
  export(appSetState, dataRows, mapBaseExcelExport = null, mapWorkbook = null, sheetname = null) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "GridExcelExport.export"');
    }

    const baseExcelExport = mapBaseExcelExport !== null ? mapBaseExcelExport : new BaseExcelExport();
    const workbook = mapWorkbook !== null ? mapWorkbook : baseExcelExport.createWorkbook();

    const appView = appViews.getDisplayName(appViews.Prospect);
    const colDefs = [
      new BaseExcelExportColumn('Prospect Identifier', 'prospectIdentifier'),
      new BaseExcelExportColumn('Prospect Name', 'name'),
      new BaseExcelExportColumn('Country', 'countryName'),
      new BaseExcelExportColumn('Area', 'areaName'),
      new BaseExcelExportColumn('Operator', 'operatorName'),
      new BaseExcelExportColumn('Licence', 'licence'),
      new BaseExcelExportColumn('Block', 'blockName'),
      new BaseExcelExportColumn('Unrisked Liquid Resources (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'initialLiquidMmb', '0.0'),
      new BaseExcelExportColumn('Unrisked Gas Resources (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'initialGasBcf', '0.0'),
      new BaseExcelExportColumn('Unrisked Total Resources (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'combinedMmboe', '0.0'),
      new BaseExcelExportColumn('Reservoir Age', 'reservoirAgeName'),
      new BaseExcelExportColumn('Water Depth (ft)', 'waterDepthFt', '0'),
      new BaseExcelExportColumn('Reservoir Depth (ft)', 'reservoirDepthFt', '0'),
      new BaseExcelExportColumn('Fluid Reservoir Condition', 'reservoirCondition'),
      new BaseExcelExportColumn('Trap Type', 'trapType'),
    ];

    baseExcelExport.writeSheet(workbook, appView, dataRows, colDefs);
    if (mapBaseExcelExport === null) baseExcelExport.writeWorkbook(workbook, baseExcelExport.getAtlas5WorkbookPrefix() + appView + baseExcelExport.getAtlas5WorkbookSuffix());
  }
}

export { GridExcelExport };
