import React from 'react';
import PropTypes from 'prop-types';

import HubDetailGridComponent, { createLink, HEADER_ROW_QUAD, NUMERIC_SORT } from '../hub-detail-grid-component';

import { valueTypes } from '../../../../shared/helpers/value-handler';
import { HubProximityProspects } from '../../../../models/hub-detail';

const HubProspectTableComponent = (props) => {
  const { onProspectClick, onLicenceClick, onBlockClick, onOperatorClick } = props;

  const prospectIdRenderer = (params) => {
    let result = params.data.prospectIdentifier;
    if (typeof onProspectClick === 'function' && typeof params.data.prospectId === 'number') {
      result = createLink(result, 'Show Details...', () => onProspectClick(params.data.prospectId));
    }
    return result;
  };

  const licenceRenderer = (params) => {
    let result = params.data.licence;
    if (typeof onLicenceClick === 'function' && typeof params.data.licenceId === 'number') {
      result = createLink(result, 'Show Details...', () => onLicenceClick(params.data.licenceId));
      return result;
    }
    return result;
  };

  const blockRenderer = (params) => {
    let result = params.data.blockName;
    if (typeof onBlockClick === 'function' && typeof params.data.blockId === 'number') {
      result = createLink(result, 'Show Details...', () => onBlockClick(params.data.blockId));
      return result;
    }
    return result;
  };

  const operatorRenderer = (params) => {
    let result = params.data.operatorName;
    if (typeof onOperatorClick === 'function' && typeof params.data.operatorId === 'number') {
      result = createLink(result, 'Show Details...', () => onOperatorClick(params.data.operatorId));
      return result;
    }
    return result;
  };

  const liquidMmbRenderer = (params) => valueTypes.ValueOnly(valueTypes.mmmboe, params.data.initialLiquidMmb);
  const gasBcfRenderer = (params) => valueTypes.ValueOnly(valueTypes.bcf, params.data.initialGasBcf);
  const combinedMmboeRenderer = (params) => valueTypes.ValueOnly(valueTypes.mmmboe, params.data.combinedMmboe);

  const columnDefs = [
    {
      headerName: 'Prospect Identifier',
      field: 'prospectIdentifier',
      sortable: true,
      minWidth: 110,
      cellRenderer: prospectIdRenderer
    },
    {
      headerName: 'Prospect Name',
      field: 'prospectName',
      minWidth: 195
    },
    {
      headerName: 'Area',
      field: 'area',
      minWidth: 100
    },
    {
      headerName: 'Licence',
      field: 'licence',
      minWidth: 85,
      cellRenderer: licenceRenderer
    },
    {
      headerName: 'Block',
      field: 'blockName',
      minWidth: 100,
      cellRenderer: blockRenderer
    },
    {
      headerName: 'Operator',
      field: 'operatorName',
      minWidth: 120,
      cellRenderer: operatorRenderer,
      cellRendererParams: {
        currentCorporateId: props.currentCorporateId
      }
    },
    {
      headerName: 'Unrisked\nLiquid Resources\n(' + valueTypes.UnitString(valueTypes.mmmboe) + ')',
      field: 'initialLiquidMmb',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: liquidMmbRenderer
    },
    {
      headerName: 'Unrisked\nGas Resources\n(' + valueTypes.UnitString(valueTypes.bcf) + ')',
      field: 'initialGasBcf',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: gasBcfRenderer
    },
    {
      headerName: 'Net Unrisked\nResources\n(' + valueTypes.UnitString(valueTypes.mmmboe) + ')',
      field: 'combinedMmboe',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: combinedMmboeRenderer
    },
    {
      headerName: 'Reservoir Age',
      field: 'reservoirAgeEra',
      minWidth: 150
    },
    {
      headerName: 'Distance (km)',
      field: 'distance',
      minWidth: 100
    }
  ];

  return <HubDetailGridComponent columnDefs={columnDefs} dataRows={props.prospects} headerRowHeight={HEADER_ROW_QUAD} />;
};

HubProspectTableComponent.propTypes = {
  prospects: PropTypes.arrayOf(PropTypes.instanceOf(HubProximityProspects)).isRequired,
  onProspectClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default HubProspectTableComponent;
