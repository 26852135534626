import React from 'react';
import PropTypes from 'prop-types';

import { GridExcelExport } from './grid-excel-export';

import { appIcons } from '../../app/app-icons';
import { Discovery } from '../../models/Discovery';
import { AppSetState } from '../../app/app-set-state';
import { valueTypes } from '../../shared/helpers/value-handler';
import BaseGridComponent, { createLink, createLinkAndIcon, COLUMN_LARGE, nullNumberRenderer, NUMERIC_SORT } from '../../components/base-grid/base-grid-component';
import { COLUMN_SMALL, COLUMN_EXTRA_SMALL_SMALL, COLUMN_EXTRA_LARGE, HEADER_ROW_TREBLE } from '../../shared/components/data-grid/ag-grid-component';

const DiscoveryGridComponent = (props) => {
  const { view } = props.appSetState;

  const renderDiscoveryName = (params) => {
    const result = createLinkAndIcon(
      params.data.name, 'Show Details', () => view.details.showDiscovery(params.data.id),
      appIcons.MapMarkerAlt, 'Show on map', () => props.showOnMap(params.data));
    return result;
  };

  const renderLicence = (params) => {
    if (typeof params.data.licenceId === 'number') {
      return createLink(params.data.licence, 'Show details', () => view.details.showLicence(params.data.licenceId));
    }
    return params.data.licence;
  };

  const renderOperatorName = (params) => {
    const { operatorName, operatorId } = params.data;

    if (typeof operatorName === 'string' && operatorName.length > 0 && typeof operatorId === 'number') {
      return createLink(operatorName, 'Show details', () => view.details.showCorporate(operatorId));
    }
    return operatorName;
  };

  const renderResources = (params) => {
    const result = params.value === null
    ? '-'
    : valueTypes.ValueOnly(valueTypes.generic1, params.value);
    return result;
  };

  const DISCOVERY_COLUMN_DEFS = [
    {
      headerName: "Discovery Name",
      field: 'name',
      sortable: true,
      minWidth: COLUMN_EXTRA_LARGE,
      cellRenderer: renderDiscoveryName
    },
    {
      headerName: "Country",
      field: "country",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Area",
      field: "area",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Operator",
      field: 'operatorName',
      sortable: true,
      minWidth: COLUMN_SMALL,
      cellRenderer: renderOperatorName
    },
    {
      headerName: "Licence",
      field: 'licence',
      sortable: true,
      minWidth: COLUMN_LARGE,
      cellRenderer: renderLicence
    },
    {
      headerName: "Discovery\nYear",
      field: "discoveryYear",
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      sortingOrder: NUMERIC_SORT
    },
    {
      headerName: "Discovery\nClassification",
      field: "classification",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Liquid Resources\n(" + valueTypes.UnitString(valueTypes.mmb) + ")",
      field: 'initialLiquidMmb',
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: renderResources
    },
    {
      headerName: "Gas Resources\n(" + valueTypes.UnitString(valueTypes.bcf) + ")",
      field: 'initialGasBcf',
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: renderResources
    },
    {
      headerName: "Total Resources\n(" + valueTypes.UnitString(valueTypes.mmmboe) + ")",
      field: 'reserves',
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: renderResources
    },
    {
      headerName: "Reservoir\nAge\n",
      field: "reservoirAgeEra",
      sortable: true,
      minWidth: COLUMN_LARGE
    }
  ];

  return <BaseGridComponent columnDefs={DISCOVERY_COLUMN_DEFS} dataRows={props.dataRows} autoReSizeGrid={true} headerRowHeight={HEADER_ROW_TREBLE} />;
};

DiscoveryGridComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.instanceOf(Discovery).isRequired).isRequired,
  showOnMap: PropTypes.func.isRequired
}

export default DiscoveryGridComponent;
export { GridExcelExport };
