import moment from 'moment';

import { dateTypes, valueTypes } from '../../shared/helpers/value-handler';
import { MapShapeBorder } from '../../shared/scss/app-colors';
import { PolygonsModel, PolygonModel } from '../../shared/helpers/leaflet/models/polygons-model';

class LicenceDataRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "LicenceDataRow.ctor"');
    }
    if (typeof obj.id !== 'number') {
      throw new Error('Invalid "obj.id" param supplied to "LicenceDataRow.ctor"');
    }
    this.id = obj.id;
    if (typeof obj.licenceNumber !== 'string') {
      throw new Error('Invalid "obj.licenceNumber" param supplied to "LicenceDataRow.ctor"');
    }
    this.licenceNumber = obj.licenceNumber;
    if (typeof obj.countryId !== 'number') {
      throw new Error('Invalid "obj.countryId" param supplied to "LicenceDataRow.ctor"');
    }
    this.countryId = obj.countryId;
    if (typeof obj.countryName !== 'string') {
      throw new Error('Invalid "obj.countryName" param supplied to "LicenceDataRow.ctor"');
    }
    this.countryName = obj.countryName;
    if (typeof obj.area !== 'string') {
      throw new Error('Invalid "obj.area" param supplied to "LicenceDataRow.ctor"');
    }
    this.area = obj.area;
    if (typeof obj.roundId !== 'number') {
      throw new Error('Invalid "obj.roundId" param supplied to "LicenceDataRow.ctor"');
    }
    this.roundId = obj.roundId;
    if (typeof obj.roundName !== 'string') {
      throw new Error('Invalid "obj.roundName" param supplied to "LicenceDataRow.ctor"');
    }
    this.roundName = obj.roundName;
    if (typeof obj.licenceTypeId !== 'number') {
      throw new Error('Invalid "obj.licenceTypeId" param supplied to "LicenceDataRow.ctor"');
    }
    this.licenceTypeId = obj.licenceTypeId;
    if (typeof obj.licenceTypeName !== 'string') {
      throw new Error('Invalid "obj.licenceTypeName" param supplied to "LicenceDataRow.ctor"');
    }
    this.licenceTypeName = obj.licenceTypeName;
    if (!Array.isArray(obj.shapes)) {
      throw new Error('Invalid obj.shapes param supplied to LicenceDataRow.ctor');
    }
    if (typeof obj.status !== 'string') {
      throw new Error('Invalid "obj.status" param supplied to "LicenceDataRow.ctor"');
    }
    this.status = obj.status;
    if (obj.link !== null && typeof obj.link !== 'string') {
      throw new Error('Invalid "obj.link" param supplied to "LicenceDataRow.ctor"');
    }
    this.link = obj.link;

    let startDateMoment = null;
    if (obj.startDate !== null) {
      if (typeof obj.startDate !== 'string') {
        throw new Error('Invalid "obj.startDate" param supplied to "LicenceDataRow.ctor"');
      }
      startDateMoment = moment(obj.startDate);
      if (!startDateMoment.isValid()) {
        throw new Error('Invalid "obj.startDate" param supplied to "LicenceDataRow.ctor"');
      }
    }
    this.startDate = startDateMoment === null ? null : startDateMoment.format(dateTypes.GetFormatString(dateTypes.AtlasDisplay));

    this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });
    if (obj.areaSQKM !== null && typeof obj.areaSQKM !== 'number') { throw new Error('Invalid obj.areaSQKM param supplied to Licence.ctor'); }
    this.areaSQKM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.areaSQKM);
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "LicenceDataRow.FromArray"');
    }

    const result = array.map(obj => new LicenceDataRow(obj));
    return result;
  }
}

export { LicenceDataRow };