import React from 'react';
import PropTypes from 'prop-types';
import './corporate-cashflow-chart.scss';
import { valueTypes } from '../../../../shared/helpers/value-handler';
import { CashflowAnnual } from '../../../../components/fiscal/models/portfolio/cashflow-annual';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2'; //bar is a column chart!!!

import {
  WgeCorporateBlue,
  WgeLightGrey,
  WgeWildcatBlue,
  WgeRigLogixYellow,
  WgeAtlasPurple,
  WgeEnergentBlue,
  WgeRigEdgeBlue,
  WgeRigOutlookMaroon
} from '../../../../shared/scss/wge-colors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement

);
const CorporateCashflowChart = (props) => {
  const { cashflowData } = props;

  // NEED TO REFERENCE VARIABLES USED TO CREATE THE CHART, SUCH AS OIL PRICE, DISCOUNT RATE ETC SO THAT WHEN PRINTING CAN BE REUSED INCLUSIVE OF ASSUMPTIONS USED TO CREATE

  cashflowData.sort((a, b) => a.year - b.year);

  var chartData = {
    labels: cashflowData.map(e => e.year),
    datasets: [
      {
        label: 'Pre-tax Cashflow',
        fill: false,
        backgroundColor: WgeRigEdgeBlue,
        borderColor: WgeRigEdgeBlue,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.preTaxCashflowUsdMM)),
        pointRadius: 0,
        type: 'line'
      },
      {
        label: 'Revenue',
        backgroundColor: WgeCorporateBlue,
        borderColor: WgeCorporateBlue,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.totalRevenueUsdMM)),
        pointRadius: 0
      }, {
        label: 'Opex',
        backgroundColor: WgeWildcatBlue,
        borderColor: WgeWildcatBlue,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.opexUsdMM * -1)),
        pointRadius: 0
      },
      {
        label: 'Emissions Charge',
        backgroundColor: WgeRigOutlookMaroon,
        borderColor: WgeRigOutlookMaroon,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.totalEmissionsChargeUsdMM * -1)),
        pointRadius: 0
      },
      {
        label: 'Liquid Tariff',
        backgroundColor: WgeRigLogixYellow,
        borderColor: WgeRigLogixYellow,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.liquidTariffUsdMM * -1)),
        pointRadius: 0
      }, {
        label: 'Gas Tariff',
        backgroundColor: WgeAtlasPurple,
        borderColor: WgeAtlasPurple,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.gasTariffUsdMM * -1)),
        pointRadius: 0
      }, {
        label: 'Capex',
        backgroundColor: WgeLightGrey,
        borderColor: WgeLightGrey,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.developmentCapexUsdMM * -1)),
        pointRadius: 0
      }, {
        label: 'Abex',
        backgroundColor: WgeEnergentBlue,
        borderColor: WgeEnergentBlue,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.abexUsdMM * -1)),
        pointRadius: 0
      }
    ]
  };

  var chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        stacked: true,
        position: 'left',
        type: 'linear',
        title: {
          text: valueTypes.UnitString(valueTypes.usdMM),
          display: true
        },
        grid: {
          drawOnChartArea: true,
        },
      },
      x: {
        stacked: true,
        ticks: {
          minRotation: 45,
          autoSkip: true
        },
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: false,
          //labelString: 'Year'
        }
      }
    },
    tooltips: { enabled: true },
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      }
    }
  };

  let contentDiv = null;
  contentDiv = (<Bar data={chartData} options={chartOptions} />);

  return (
    <div className="corporate-cashflow-chart-component">
      {contentDiv}
    </div>
  );

};

CorporateCashflowChart.propTypes = {
  cashflowData: PropTypes.arrayOf(PropTypes.instanceOf(CashflowAnnual)).isRequired,
  cumulativeCashflowTitle: PropTypes.string
};

export default CorporateCashflowChart;