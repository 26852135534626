import React from 'react';
import PropTypes from 'prop-types';
import './comparison-component.scss';
import { SelectComponent } from '../views/base/base-view-settings-component';
import { AppSetState } from '../app/app-set-state';
import { UserValuations } from '../models/user-valuations';
import { Button } from 'reactstrap';
import ErrorMessageComponent from '../components/error-message/error-message-component';

class ComparisonComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { valuations: []};
    this.loadValuations = this.loadValuations.bind(this);
  }

  componentDidMount() {
    this.loadValuations();
  }

  componentDidUpdate(prevProps) {
    if (this.props.appSetState.appValuation.refreshComparison === true) {
      this.props.clearComparisonGrid();
      this.loadValuations();

      this.props.appSetState.appValuation.refreshComparison = false;
    }
  }

  loadValuations() {
    const url = 'api/valuations/load-valid-valuations';
    const onSuccess = (result) => {
      this.setState({
        valuations: result.variableList.map(obj => new UserValuations(obj)),
      }, () => this.validate());
    };
    const onFail = () => {
      this.props.invalidateComparison(true, true);
    };

    this.props.appSetState.api.getCached(url, onSuccess, onFail, true);
  }

  compareEnabled = () => {
    const { valuationOneId, valuationTwoId } = this.props;
    return valuationOneId > 0 && valuationTwoId > 0 && valuationOneId !== valuationTwoId;
  }

  validate = () => {
    const { valuationOneId, valuationTwoId } = this.props;
    const { valuations } = this.state;
    // NEED TO CHECK HERE & IF NOT VALID CALL props.inValidate to clear the grid valuation isnt there anymore
    let val1Invalid = false;
    let val2Invalid = false;

    if (valuations !== undefined && valuations.length > 0) {
      if (valuationOneId !== '' && valuationOneId > 0 && valuations.find(x => x.variableId === valuationOneId) === undefined) val1Invalid = true;
      if (valuationTwoId !== '' && valuationTwoId > 0 && valuations.find(x => x.variableId === valuationTwoId) === undefined) val2Invalid = true;

      if (val1Invalid || val2Invalid) this.props.invalidateComparison(val1Invalid, val2Invalid);
    }
  }

  render() {
    const { valuationOneId, valuationTwoId, comparisonGrid, isAdvanced } = this.props;

    return (
      
      isAdvanced? 
        <div className='comparison-component'>
          <div className='flex-row'>
            <div className='col80'>
              <SelectComponent label="Valuation One" value={valuationOneId} values={this.state.valuations} onChange={this.props.onValuationOneChange} showButtons={false} valueName={"variableId"} labelName={"variableName"} />
              <SelectComponent label="Valuation Two" value={valuationTwoId} values={this.state.valuations} onChange={this.props.onValuationTwoChange} showButtons={false} valueName={"variableId"} labelName={"variableName"} />
            </div>
            <div className='col10'>
              <Button onClick={() => this.props.onComparison()} disabled={!this.compareEnabled()} >Compare</Button>
            </div>
          </div>
          <div className='flex-row grid-row'>
            <div className='col100'>
              {comparisonGrid}
            </div>
          </div>
        </div>
        : <div className='comparison-component'>
            <div style={{ marginLeft: '10px'}}>
              Valuations allows you to generate asset and company valuations using different price scenarios, including your own.<br /><br />
              You are not currently subscribed to Valuations.
              <ErrorMessageComponent message={''} marginLeft={'0px'} />
            </div>
          </div>
    );
  }
};

ComparisonComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  onComparison: PropTypes.func.isRequired,
  comparisonGrid: PropTypes.node,
  valuationOneId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  valuationTwoId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  onValuationOneChange: PropTypes.func.isRequired,
  onValuationTwoChange: PropTypes.func.isRequired,
  invalidateComparison: PropTypes.func.isRequired,
  clearComparisonGrid: PropTypes.func.isRequired,
  isAdvanced: PropTypes.bool.isRequired,
}

ComparisonComponent.defaultProps = {
  isAdvanced: false,
};

export default ComparisonComponent;