import React from 'react';
import PropTypes from 'prop-types';

import AdvancedFieldDetailGridComponent, { HEADER_ROW_TREBLE, COLUMN_EXTRA_SMALL_SMALL, COLUMN_SMALL, COLUMN_MEDIUM, COLUMN_MEDIUM_MEDIUM, COLUMN_LARGE } from './advanced-field-detail-grid-component';

import { valueTypes } from '../../../shared/helpers/value-handler';
import { CashflowAnnual } from '../../../components/fiscal/models/portfolio/cashflow-annual';

const AdvancedFieldCashflowTableComponent = (props) => {
  const yearPinnedRenderer = (params) => params.node.rowPinned == 'bottom' ? params.data.year = 'Total' : params.data.year;
  const liquidProductionMbodRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.year === 'Total' ? params.data.liquidProductionMMB : params.data.liquidProductionMbod);
  const gasProductionMmscfdRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.year === 'Total' ? params.data.gasProductionBCF : params.data.gasProductionMmscfd);
  const totalProductionMboedRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.year === 'Total' ? params.data.totalMmboe : params.data.combinedMboed);
  const liquidPriceUSDBOERenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.liquidPriceUsdBOE);
  const gasPriceUSDMCFRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.gasPriceUsdMCF);
  const totalRevenueUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.totalRevenueUsdMM);
  const opexUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.opexUsdMM);
  const totalTariffUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.totalTariffUsdMM);
  const costForLossesOrDepreciationUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.costForLossesOrDepreciationUsdMM);
  const abexUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.abexUsdMM);
  const preTaxCashflowUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.preTaxCashflowUsdMM);
  const cumulativeEBITDAUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.cumulativeEBITDA);

  const corpTaxUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.corporationTaxUsdMM);
  const specialTaxUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.specialTaxUsdMM);
  const postTaxCashflowUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.postTaxCashflowUsdMM);
  const discountedPostTaxCashflowUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.discountedPostTaxCashflowUsdMM);

  const totalEmissionsChargeUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.totalEmissionsChargeUsdMM);

  const usdMMStr = valueTypes.UnitString(valueTypes.usdMM);
  const columnDefs = [
    {
      headerName: 'Year',
      field: 'year',
      cellRendererSelector: yearPinnedRenderer,
      minWidth: 70
    },
    {
      headerName: 'Production',
      children: [
        {
          headerName: 'Liquid\n(' + valueTypes.UnitString(valueTypes.mboed) + ')',
          field: 'liquidProductionMbod',
          cellRenderer: liquidProductionMbodRenderer,
          minWidth: 80
        },
        {
          headerName: 'Gas\n(' + valueTypes.UnitString(valueTypes.mmscfd) + ')',
          field: 'gasProductionMmscfd',
          cellRenderer: gasProductionMmscfdRenderer,
          minWidth: 80
        },
        {
          headerName: 'Total\n(' + valueTypes.UnitString(valueTypes.mboed) + ')',
          field: 'combinedMboed',
          cellRenderer: totalProductionMboedRenderer,
          minWidth: 80
        }
      ]
    },
    {
      headerName: 'Price',
      children: [
        {
          headerName: 'Liquid\n(' + valueTypes.UnitString(valueTypes.usdBOE) + ')',
          field: 'liquidPriceUsdBOE',
          cellRenderer: liquidPriceUSDBOERenderer,
          minWidth: 80
        },
        {
          headerName: 'Gas\n(' + valueTypes.UnitString(valueTypes.usdMCF) + ')',
          field: 'gasPriceUsdMCF',
          cellRenderer: gasPriceUSDMCFRenderer,
          minWidth: 80
        }
      ]
    },
    {
      headerName: 'Revenue\n(' + usdMMStr + ')',
      field: 'totalRevenueUsdMM',
      cellRenderer: totalRevenueUsdMMRenderer,
      minWidth: 80
    },
    {
      headerName: 'Opex\n(' + usdMMStr + ')',
      field: 'opexUsdMM',
      cellRenderer: opexUsdMMRenderer,
      minWidth: 80
    },
    {
      headerName: 'Emissions charge\n(' + usdMMStr + ')',
      field: 'totalEmissionsChargeUsdMM',
      cellRenderer: totalEmissionsChargeUsdMMRenderer,
      minWidth: 80
    },
    {
      headerName: 'Export tariff\n(' + usdMMStr + ')',
      field: 'totalTariffUsdMM',
      cellRenderer: totalTariffUsdMMRenderer,
      minWidth: 80
    },
    {
      headerName: 'Capex\n(' + usdMMStr + ')',
      field: 'costForLossesOrDepreciationUsdMM',
      cellRenderer: costForLossesOrDepreciationUsdMMRenderer,
      minWidth: 80
    },
    {
      headerName: 'Abex\n(' + usdMMStr + ')',
      field: 'abexUsdMM',
      cellRenderer: abexUsdMMRenderer,
      minWidth: 80
    },
    {
      headerName: 'Pre-tax cashflow\n(' + usdMMStr + ')',
      field: 'preTaxCashflowUsdMM',
      cellRenderer: preTaxCashflowUsdMMRenderer
    },
    //asked for removal in task 402 - commented out in case it is asked to be put back in 
    //{
    //  headerName: 'Cumulative operating cashflow (' + usdMMStr + ')',
    //  field: 'cumulativeEBITDA',
    //  cellRenderer: cumulativeEBITDAUsdMMRenderer,
    //  minWidth: COLUMN_MEDIUM_MEDIUM
    //},
    {
      headerName: 'Tax',
      children: [
        {
          headerName: 'Corporate\n(' + usdMMStr + ')',
          field: 'corporationTaxUsdMM',
          cellRenderer: corpTaxUsdMMRenderer,
          minWidth: 80
        },
        {
          headerName: 'Special\n(' + usdMMStr + ')',
          field: 'specialTaxUsdMM',
          cellRenderer: specialTaxUsdMMRenderer,
          minWidth: 80
        }
      ]
    },
    {
      headerName: 'Post-tax cashflow',
      children: [
        {
          headerName: 'Undiscounted\n(' + usdMMStr + ')',
          field: 'postTaxCashflowUsdMM',
          cellRenderer: postTaxCashflowUsdMMRenderer
        },
        {
          headerName: 'Discounted\n(' + usdMMStr + ')',
          field: 'discountedPostTaxCashflowUsdMM',
          cellRenderer: discountedPostTaxCashflowUsdMMRenderer
        }
      ]
    }


  ];

  return <AdvancedFieldDetailGridComponent hasGroupedHeaderRow columnDefs={columnDefs} dataRows={props.annual} totalRow={props.totalRow} headerRowHeight={HEADER_ROW_TREBLE}/>;
}

AdvancedFieldCashflowTableComponent.propTypes = {
  annual: PropTypes.arrayOf(PropTypes.instanceOf(CashflowAnnual)).isRequired,
  totalRow: PropTypes.instanceOf(CashflowAnnual)
};

export default AdvancedFieldCashflowTableComponent;
