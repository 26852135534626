import React from 'react';
import PropTypes from 'prop-types';

import { DiscoveryFilter } from './discovery-filter';

import BaseViewSettingsComponent, { createSplitDiv, FollowMapComponent, NumericFieldComponent, DatePickerComponent, MultiSelectComponent, SelectComponent } from '../base/base-view-settings-component';

import { AppSetState } from '../../app/app-set-state';
import { KeyValuePair } from '../../models/key-value-pair';
import { valueTypes } from '../../shared/helpers/value-handler';

class DiscoveryViewSettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      discoveries: [],
      operators: [],
      participants: [],
      countries: [],
      areas: [],
      quadrants: [],
      blocks: [],
      licences: [],
      classifications: [{ key: true, value: "Commercial" }, { key: false, value: "Technical" }],
      reservoirAges: [],
      basicOnly: false,
      licenceStatuses: []
    };
    this.loadFromFilter(this.state);
  }

  componentDidMount() {
    const url = 'api/discovery/filters/load';
    const onSuccess = (result) => {
      this.setState({
        discoveries: result.discoveries.map(obj => new KeyValuePair(obj)),
        operators: result.operators.map(obj => new KeyValuePair(obj)),
        participants: result.participants.map(obj => new KeyValuePair(obj)),
        countries: result.countries.map(obj => new KeyValuePair(obj)),
        areas: result.areas.map(obj => new KeyValuePair(obj)),
        quadrants: result.quadrants.map(obj => new KeyValuePair(obj)),
        blocks: result.blocks.map(obj => new KeyValuePair(obj)),
        licences: result.licences.map(obj => new KeyValuePair(obj)),
        reservoirAges: result.reservoirAges.map(obj => new KeyValuePair(obj)),
        basicOnly: result.basicOnly,
        licenceStatuses: result.licenceStatuses.map(obj => new KeyValuePair(obj))
      });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter !== prevProps.filter) {
      this.setState(prevState => {
        this.loadFromFilter(prevState);
        return prevState;
      });
    }
  }

  loadFromFilter = (state) => {
    const { filter } = this.props;

    state.followMap = filter.followMap;
    state.discoveryIds = filter.discoveryIds;
    state.operatorIds = filter.operatorIds;
    state.participantIds = filter.participantIds;
    state.countryIds = filter.countryIds;
    state.areaIds = filter.areaIds;
    state.quadrantIds = filter.quadrantIds;
    state.blockIds = filter.blockIds;
    state.licenceIds = filter.licenceIds;
    state.classification = filter.classification;
    state.reservoirAgeIds = filter.reservoirAgeIds;
    state.startYear = filter.startYear;
    state.endYear = filter.endYear;
    state.minReserves = filter.minReserves;
    state.maxReserves = filter.maxReserves;
    state.licenceStatus = filter.licenceStatus;
  }

  _update = (closeFilters) => {
    const { filter } = this.props;

    filter.followMap = this.state.followMap;
    filter.discoveryIds = this.state.discoveryIds;
    filter.operatorIds = this.state.operatorIds;
    filter.participantIds = this.state.participantIds;
    filter.countryIds = this.state.countryIds;
    filter.areaIds = this.state.areaIds;
    filter.quadrantIds = this.state.quadrantIds;
    filter.blockIds = this.state.blockIds;
    filter.licenceIds = this.state.licenceIds;
    filter.classification = this.state.classification;
    filter.reservoirAgeIds = this.state.reservoirAgeIds;
    filter.minReserves = this.state.minReserves;
    filter.maxReserves = this.state.maxReserves;
    filter.startYear = this.state.startYear;
    filter.endYear = this.state.endYear;
    filter.licenceStatus = this.state.licenceStatus;

    filter.clearInvalidValues();

    this.props.onUpdate(closeFilters);
  };

  _clear = () => {
    this.props.filter.clear();
    this.loadFromFilter(this.state);
    this._update();
  }

  getYearFromDate = (value) => {
    const result = value instanceof Date ? value.getFullYear() : null;
    return result;
  }

  onToggleFollowMap = () => this.setState(prevState => { return { followMap: !prevState.followMap }; });
  onDiscoveryIdsChange = (value) => this.setState({ discoveryIds: value });
  onOperatorIdsChange = (value) => this.setState({ operatorIds: value });
  onParticipantIdsChange = (value) => this.setState({ participantIds: value });
  onCountryIdsChange = (value) => this.setState({ countryIds: value });
  onAreaIdsChange = (value) => this.setState({ areaIds: value });
  onQuadrantIdsChange = (value) => this.setState({ quadrantIds: value });
  onBlockIdsChange = (value) => this.setState({ blockIds: value });
  onLicenceIdsChange = (value) => this.setState({ licenceIds: value });
  onClassificationChange = (value) => this.setState({ classification: value });
  onReservoirAgeIdsChange = (value) => this.setState({ reservoirAgeIds: value });
  onMinReservesChange = (value) => this.setState({ minReserves: value });
  onMaxReservesChange = (value) => this.setState({ maxReserves: value });
  onStartYearChange = (value) => this.setState({ startYear: this.getYearFromDate(value) });
  onEndYearChange = (value) => this.setState({ endYear: this.getYearFromDate(value) });
  onLicenceStatusChange = (value) => this.setState({ licenceStatus: (value === null ? 0 : value) });

  render() {
    const { minReserves, maxReserves, basicOnly } = this.state;

    const classificationValue = this.state.classification === null ? '' : this.state.classification;
    const licenceValue = this.state.licenceStatus === 0 ? '' : this.state.licenceStatus;
    const startYear = this.state.startYear === null ? null : new Date(this.state.startYear, 1, 1);
    const endYear = this.state.endYear === null ? null : new Date(this.state.endYear, 1, 1);

    return (
      <BaseViewSettingsComponent
        appSetState={this.props.appSetState}
        className="field-view-settings-component"
        onUpdate={this._update}
        onClear={this._clear}
      >
        <FollowMapComponent value={this.state.followMap} enabled={this.props.followMapEnabled} onClick={this.onToggleFollowMap} />
        <MultiSelectComponent label="Discovery Name" value={this.state.discoveryIds} values={this.state.discoveries} onChange={this.onDiscoveryIdsChange} />
        <MultiSelectComponent label="Country" value={this.state.countryIds} values={this.state.countries} onChange={this.onCountryIdsChange} />
        <MultiSelectComponent label="Area" value={this.state.areaIds} values={this.state.areas} onChange={this.onAreaIdsChange} />
        <MultiSelectComponent label="Licence" value={this.state.licenceIds} values={this.state.licences} onChange={this.onLicenceIdsChange} />
        <MultiSelectComponent label="Quadrant" value={this.state.quadrantIds} values={this.state.quadrants} onChange={this.onQuadrantIdsChange} />
        <MultiSelectComponent label="Block" value={this.state.blockIds} values={this.state.blocks} onChange={this.onBlockIdsChange} />
        <MultiSelectComponent label="Operator" value={this.state.operatorIds} values={this.state.operators} onChange={this.onOperatorIdsChange} />
        <MultiSelectComponent label="Participant" value={this.state.participantIds} values={this.state.participants} onChange={this.onParticipantIdsChange} />
        <SelectComponent label="Discovery Classification" value={classificationValue} values={this.state.classifications} onChange={this.onClassificationChange} />
        <MultiSelectComponent label="Reservoir Age" value={this.state.reservoirAgeIds} values={this.state.reservoirAges} onChange={this.onReservoirAgeIdsChange} />
        <SelectComponent label="Licence Status" value={licenceValue} values={this.state.licenceStatuses} onChange={this.onLicenceStatusChange} />

        {createSplitDiv(
          <DatePickerComponent label="Min" views={["year"]} format="YYYY" value={startYear} maxDate={endYear} onChange={this.onStartYearChange} />,
          <DatePickerComponent label="Max" views={["year"]} format="YYYY" value={endYear} minDate={startYear} onChange={this.onEndYearChange} />,
          'Discovery Year'
        )}

        {!basicOnly ?
          (createSplitDiv(
            <NumericFieldComponent label="Min" value={minReserves} max={maxReserves} onChange={this.onMinReservesChange} type="number" />,
            <NumericFieldComponent label="Max" value={maxReserves} min={minReserves} onChange={this.onMaxReservesChange} type="number" />,
            'Resources (' + valueTypes.UnitString(valueTypes.mmmboe) + ')'
          ))
          : null
        }


      </BaseViewSettingsComponent>
    );
  }
}

DiscoveryViewSettingsComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  filter: PropTypes.instanceOf(DiscoveryFilter).isRequired,
  onUpdate: PropTypes.func.isRequired,
  followMapEnabled: PropTypes.bool.isRequired
};

export default DiscoveryViewSettingsComponent;