import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import './text-field-component.scss';
import { appIcons, appIconSizes } from '../../../app/app-icons';

const TextFieldComponent = (props) => {
  const newProps = { ...props };

  if (newProps.type === 'number' && typeof newProps.value !== 'number') {
    newProps.value = '';
  }

  newProps.onChange = (e) => {
    e.preventDefault();

    let value = e.target.value;
    if (newProps.type === 'number') {
      value = value === '' ? null : Number(value);
    }

    props.onChange(value);
  }

  const handleClearClick = () => {
    const value = newProps.type === 'number' ? null : '';
    props.onChange(value);
  }

  return (
    <div className="text-field-component">
      <div className="buttons-div">
        <span title="Clear" onClick={handleClearClick}>
          {appIcons.getIcon(appIcons.Close, appIconSizes.xs, false)}
        </span>
      </div>

      <TextField {...newProps} fullWidth spellCheck={false} margin="dense" variant="outlined" InputLabelProps={{ shrink: true }} disabled={newProps.disabled} />
    </div>
  );
};

TextFieldComponent.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TextFieldComponent;
