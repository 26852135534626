import React from 'react';
import PropTypes from 'prop-types';

import { ProspectFilter } from './prospect-filter';

import BaseViewSettingsComponent, { createSplitDiv, FollowMapComponent, NumericFieldComponent, MultiSelectComponent, SelectComponent } from '../base/base-view-settings-component';

import { AppSetState } from '../../app/app-set-state';
import { valueTypes } from '../../shared/helpers/value-handler';
import { KeyValuePair } from '../../models/key-value-pair';

class ProspectViewSettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prospects: [],
      names: [],
      countries: [],
      areas: [],
      licences: [],
      quadrants: [],
      blocks: [],
      operators: [],
      participants: [],
      reservoirAges: [],
      licenceStatuses: []
    };
    this.loadFromFilter(this.state);
  }

  componentDidMount() {
    const url = 'api/prospect/filters/load';
    const onSuccess = (result) => {
      this.setState({
        prospects: KeyValuePair.FromArray(result.prospects),
        names: result.names.map(obj => { return { key: obj, value: obj }; }),
        countries: KeyValuePair.FromArray(result.countries),
        areas: KeyValuePair.FromArray(result.areas),
        licences: KeyValuePair.FromArray(result.licences),
        quadrants: KeyValuePair.FromArray(result.quadrants),
        blocks: KeyValuePair.FromArray(result.blocks),
        operators: KeyValuePair.FromArray(result.operators),
        participants: KeyValuePair.FromArray(result.participants),
        reservoirAges: KeyValuePair.FromArray(result.reservoirAges),
        licenceStatuses: KeyValuePair.FromArray(result.licenceStatuses)
      });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter !== prevProps.filter) {
      this.setState(prevState => {
        this.loadFromFilter(prevState);
        return prevState;
      });
    }
  }

  loadFromFilter = (state) => {
    const { filter } = this.props;

    state.followMap = filter.followMap;
    state.prospectIds = filter.prospectIds;
    state.prospectNames = filter.prospectNames;
    state.countryIds = filter.countryIds;
    state.areaIds = filter.areaIds;
    state.licenceIds = filter.licenceIds;
    state.quadrantIds = filter.quadrantIds;
    state.blockIds = filter.blockIds;
    state.operatorIds = filter.operatorIds;
    state.participantIds = filter.participantIds;
    state.minCombinedMmboe = filter.minCombinedMmboe;
    state.maxCombinedMmboe = filter.maxCombinedMmboe;
    state.reservoirAgeIds = filter.reservoirAgeIds;
    state.licenceStatus = filter.licenceStatus;
  }

  _update = (closeFilters) => {
    const { filter } = this.props;

    filter.followMap = this.state.followMap;
    filter.prospectIds = this.state.prospectIds;
    filter.prospectNames = this.state.prospectNames;
    filter.countryIds = this.state.countryIds;
    filter.areaIds = this.state.areaIds;
    filter.licenceIds = this.state.licenceIds;
    filter.quadrantIds = this.state.quadrantIds;
    filter.blockIds = this.state.blockIds;
    filter.operatorIds = this.state.operatorIds;
    filter.participantIds = this.state.participantIds;
    filter.minCombinedMmboe = this.state.minCombinedMmboe;
    filter.maxCombinedMmboe = this.state.maxCombinedMmboe;
    filter.reservoirAgeIds = this.state.reservoirAgeIds;
    filter.licenceStatus = this.state.licenceStatus;

    filter.clearInvalidValues();

    this.props.onUpdate(closeFilters);
  };

  _clear = () => {
    this.props.filter.clear();
    this.loadFromFilter(this.state);
    this._update();
  }

  onToggleFollowMap = () => this.setState(prevState => { return { followMap: !prevState.followMap }; });
  onProspectIdsChange = (value) => this.setState({ prospectIds: value });
  onProspectNamesChange = (value) => this.setState({ prospectNames: value });
  onCountryIdsChange = (value) => this.setState({ countryIds: value });
  onAreaIdsChange = (value) => this.setState({ areaIds: value });
  onLicenceIdsChange = (value) => this.setState({ licenceIds: value });
  onQuadrantIdsChange = (value) => this.setState({ quadrantIds: value });
  onBlockIdsChange = (value) => this.setState({ blockIds: value });
  onOperatorIdsChange = (value) => this.setState({ operatorIds: value });
  onParticipantIdsChange = (value) => this.setState({ participantIds: value });
  onMinCombinedMmboeChange = (value) => this.setState({ minCombinedMmboe: value });
  onMaxCombinedMmboeChange = (value) => this.setState({ maxCombinedMmboe: value });
  onReservoirAgeIdsChange = (value) => this.setState({ reservoirAgeIds: value });
  onLicenceStatusChange = (value) => this.setState({ licenceStatus: (value === null ? 0 : value) });

  render() {
    const licenceValue = this.state.licenceStatus === 0 ? '' : this.state.licenceStatus;

    return (
      <BaseViewSettingsComponent
        appSetState={this.props.appSetState}
        className="prospect-view-settings-component"
        onUpdate={this._update}
        onClear={this._clear}
      >
        <FollowMapComponent value={this.state.followMap} enabled={this.props.followMapEnabled} onClick={this.onToggleFollowMap} />
        <MultiSelectComponent label="Prospect Identifier" value={this.state.prospectIds} values={this.state.prospects} onChange={this.onProspectIdsChange} />
        <MultiSelectComponent label="Prospect Name" value={this.state.prospectNames} values={this.state.names} onChange={this.onProspectNamesChange} />
        <MultiSelectComponent label="Country" value={this.state.countryIds} values={this.state.countries} onChange={this.onCountryIdsChange} />
        <MultiSelectComponent label="Area" value={this.state.areaIds} values={this.state.areas} onChange={this.onAreaIdsChange} />
        <MultiSelectComponent label="Licence" value={this.state.licenceIds} values={this.state.licences} onChange={this.onLicenceIdsChange} />
        <MultiSelectComponent label="Quadrant" value={this.state.quadrantIds} values={this.state.quadrants} onChange={this.onQuadrantIdsChange} />
        <MultiSelectComponent label="Block" value={this.state.blockIds} values={this.state.blocks} onChange={this.onBlockIdsChange} />
        <MultiSelectComponent label="Operator" value={this.state.operatorIds} values={this.state.operators} onChange={this.onOperatorIdsChange} />
        <MultiSelectComponent label="Participant" value={this.state.participantIds} values={this.state.participants} onChange={this.onParticipantIdsChange} />
        <MultiSelectComponent label="Reservoir Age" value={this.state.reservoirAgeIds} values={this.state.reservoirAges} onChange={this.onReservoirAgeIdsChange} />
        <SelectComponent label="Licence Status" value={licenceValue} values={this.state.licenceStatuses} onChange={this.onLicenceStatusChange} />

        {createSplitDiv(
          <NumericFieldComponent label={'Min'} value={this.state.minCombinedMmboe} max={this.state.maxCombinedMmboe} onChange={this.onMinCombinedMmboeChange} type="number" />,
          <NumericFieldComponent label={'Max'} value={this.state.maxCombinedMmboe} min={this.state.minCombinedMmboe} onChange={this.onMaxCombinedMmboeChange} type="number" />,
          'Resources (' + valueTypes.UnitString(valueTypes.mmmboe) + ')'
        )}
      </BaseViewSettingsComponent>
    );
  }
}

ProspectViewSettingsComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  filter: PropTypes.instanceOf(ProspectFilter).isRequired,
  onUpdate: PropTypes.func.isRequired,
  followMapEnabled: PropTypes.bool.isRequired
};

export default ProspectViewSettingsComponent;
