import { showError } from '../../helpers/common';

class DisplayRig {
  constructor(wellRig) {
    if (typeof wellRig !== 'object') {
      throw new Error('Invalid wellRig param supplied to wellRig.ctor');
    }
    if (typeof wellRig.rigId !== 'number') { throw new Error('Invalid rigId param supplied to wellRig.ctor'); }
    if (typeof wellRig.rigName !== 'string') { throw new Error('Invalid rigName param supplied to wellRig.ctor'); }
    if (wellRig.rigRateDpd !== null && typeof wellRig.rigRateDpd !== 'number') { throw new Error('Invalid rigRateDpd param supplied to wellRig.ctor'); }
    if (wellRig.rigUplift !== null && typeof wellRig.rigUplift !== 'number') { throw new Error('Invalid rigUplift param supplied to wellRig.ctor'); }

    this.rigId = wellRig.rigId;
    this.rigName = wellRig.rigName;
    this.rigRateDpd = wellRig.rigRateDpd;
    this.rigUplift = wellRig.rigUplift;
  }
}

export { DisplayRig }