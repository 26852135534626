import React from 'react';
import PropTypes from 'prop-types';

import { CompletedAndActiveWellRow } from '../../../models/block-detail';

import { appIcons, appIconSizes } from '../../../app/app-icons';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const CompletedAndActiveWellsTableComponent = (props) => {
  const { wells, onWellClick, onReportClick, onLicenceClick, onProspectClick, onDiscoveryClick, onFieldClick, onOperatorClick } = props;

  function createResourceLink(resource, idx) {
    const resourceName = resource.resourceName;
    const key = 'completed-and-active-wells-row-' + idx;

    if (typeof resource.resourceId === 'number') {
      let onClick = null;
      if (resource.isProspect && typeof onProspectClick === 'function') {
        onClick = onProspectClick;
      } else if (resource.isDiscovery && typeof onDiscoveryClick === 'function') {
        onClick = onDiscoveryClick;
      } else if (typeof onFieldClick === 'function') {
        onClick = onFieldClick;
      }
      if (onClick !== null) {
        return <a key={key} href="/" title="Show Details" onClick={(e) => { e.preventDefault(); onClick(resource.resourceId); }}>{resourceName}</a>;
      }
    }

    return <span key={key}>{resourceName}</span>;
  }

  function createResourceLinks(resources) {
    if (resources.length === 0) {
      return null;
    }

    const result = [];
    for (var loop = 0; loop < resources.length; loop++) {
      result.push(createResourceLink(resources[loop], loop))
      if (loop < resources.length - 1) {
        result.push(', ');
      }
    }
    return result;
  }

  const getRow = (row, rowIndex) => {
    let wellName = row.wellName;
    if (typeof onWellClick === 'function' && typeof row.wellId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onWellClick(row.wellId);
      };
      wellName = <a href="/" title="Show Details" onClick={onClick}>{wellName}</a>;
    }

    let reportLink = null;
    if (typeof onReportClick === 'function' && typeof row.reportId === 'number' && row.reportId !== 0) {
      const onClick = (e) => {
        e.preventDefault();
        onReportClick(row.reportId);
      };
      reportLink = <a href="/" title="Download Report" onClick={onClick}>{appIcons.getIcon(appIcons.FilePdf, appIconSizes.sm, false)}</a>;
    }

    let originalLicence = row.originalLicenceName;
    if (typeof onLicenceClick === 'function' && typeof row.originalLicenceId === 'number' && row.originalLicenceId !== props.licenceId) {
      const onClick = (e) => {
        e.preventDefault();
        onLicenceClick(row.originalLicenceId);
      };
      originalLicence = <a href="/" title="Show Details" onClick={onClick}>{originalLicence}</a>;
    }

    let currentLicence = row.currentLicenceName;
    if (typeof onLicenceClick === 'function' && typeof row.currentLicenceId === 'number' && row.currentLicenceId !== props.licenceId) {
      const onClick = (e) => {
        e.preventDefault();
        onLicenceClick(row.currentLicenceId);
      };
      currentLicence = <a href="/" title="Show Details" onClick={onClick}>{currentLicence}</a>;
    }

    let operatorName = row.operatorName;
    if (typeof onOperatorClick === 'function' && typeof row.operatorId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onOperatorClick(row.operatorId);
      };
      operatorName = <a href="/" onClick={onClick}>{operatorName}</a>;
    }

    return (
      <tr key={'completed-and-active-well-row-' + rowIndex.toString()}>
        <td style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ flex: '1 1 auto' }} >{wellName}</span>
          <span>{reportLink}</span>
        </td>
        <td>{createResourceLinks(row.resources)}</td>
        <td>{row.typeName}</td>
        <td>{operatorName}</td>
        <td>{row.startDate}</td>
        <td>{row.endDate}</td>
        <td>{row.statusName}</td>
        <td>{row.resultName}</td>
        <td>{originalLicence}</td>
        <td>{currentLicence}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Well Name</td>
          <td>Associated Resources</td>
          <td>Well Type</td>
          <td>Operator</td>
          <td>Spud Date</td>
          <td>Completion Date</td>
          <td>Well Status</td>
          <td>Well Result</td>
          <td>Original Licence</td>
          <td>Current Licence</td>
        </tr>
      </thead>
      <tbody>
        {wells.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
}

CompletedAndActiveWellsTableComponent.propTypes = {
  licenceId: PropTypes.number.isRequired,
  wells: PropTypes.arrayOf(PropTypes.instanceOf(CompletedAndActiveWellRow)).isRequired,
  onWellClick: PropTypes.func,
  onReportClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onProspectClick: PropTypes.func,
  onDiscoveryClick: PropTypes.func,
  onFieldClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default CompletedAndActiveWellsTableComponent;
