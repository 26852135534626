// Stackoverflow save the day again! - https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
// Opera 8.0+
const isOpera = () => (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
// Firefox 1.0+
const isFirefox = () => typeof InstallTrigger !== 'undefined';
// Safari 3.0+ "[object HTMLElementConstructor]" 
const isSafari = () => /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification));
// Internet Explorer 6-11
const isIE = () => /*@cc_on!@*/false || !!document.documentMode;
// Edge 20+
const isEdge = () => !isIE() && !!window.StyleMedia;
// Chrome 1+
const isChrome = () => !!window.chrome && !!window.chrome.webstore;
// Blink engine detection
const isBlink = () => (isChrome() || isOpera()) && !!window.css;

const BrowserType =
{
  Unknown: 0,
  Opera: 1,
  Firefox: 2,
  Safari: 3,
  IE: 4,
  Edge: 5,
  Chrome: 6,
  Blink: 7
};
Object.freeze(BrowserType);

let browserType = null;

const getBrowserType = () => {
  if (browserType === null) {
    if (isOpera()) {
      browserType = BrowserType.Opera;
    } else if (isFirefox()) {
      browserType = BrowserType.Firefox;
    } else if (isSafari()) {
      browserType = BrowserType.Safari;
    } else if (isIE()) {
      browserType = BrowserType.IE;
    } else if (isEdge()) {
      browserType = BrowserType.Edge;
    } else if (isChrome()) {
      browserType = BrowserType.Chrome;
    } else if (isBlink()) {
      browserType = BrowserType.Blink;
    } else {
      browserType = BrowserType.Unknown;
    }
  }
  return browserType
};

const browserIsMicrosoft = () => {
  const tmpBrowserType = getBrowserType();
  switch (tmpBrowserType) {
    case BrowserType.IE:
    case BrowserType.Edge:
      return true;
    case BrowserType.Unknown:
    case BrowserType.Opera:
    case BrowserType.Firefox:
    case BrowserType.Safari:
    case BrowserType.Chrome:
    case BrowserType.Blink:
      return false;
    default:
      throw new Error('Unsupported browser type (' + tmpBrowserType + ') in browserIsMicrosoft()');
  }
}

export { BrowserType, getBrowserType, browserIsMicrosoft }
