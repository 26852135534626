import { showError } from '../shared/helpers/common';
import { MapShapeBorder } from '../shared/scss/app-colors';
import { Area } from '../shared/components/models/gmatrix3-extension-area';
import { CircleModel } from '../shared/helpers/leaflet/models/circle-model';
import { Country } from '../shared/components/models/gmatrix3-extension-country';
import { NonProducingParticipant } from '../shared/components/models/gmatrix3-extension-non-producing-participant';
import ResourcePlannedWell from './resource-planned-well';
import { DisplayWell } from '../shared/components/models/gmatrix3-extension-display-well';

class ProspectDetail {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('ProspectDetail', 'ProspectDetail'); }
    if (typeof obj.id !== 'number') { showError('ProspectDetail', 'id'); }
    if (typeof obj.name !== 'string') { showError('ProspectDetail', 'name'); }
    if (typeof obj.alias !== 'string') { showError('ProspectDetail', 'alias'); }
    if (typeof obj.resourceCountry !== 'object') { showError('ProspectDetail', 'resourceCountry'); }
    if (typeof obj.resourceArea !== 'object') { showError('ProspectDetail', 'resourceArea'); }
    if (obj.licence !== null && typeof obj.licence !== 'string') { showError('ProspectDetail', 'licence'); }
    if (obj.licenceId !== null && typeof obj.licenceId !== 'number') { showError('ProspectDetail', 'licence'); }
    if (typeof obj.blockId !== 'number') { showError('ProspectDetail', 'blockId'); }
    if (obj.blockNo !== null && typeof obj.blockNo !== 'string') { showError('ProspectDetail', 'blockNo'); }
    if (obj.type !== null && typeof obj.type !== 'string') { showError('ProspectDetail', 'type'); }
    if (typeof obj.trapType !== 'string') { showError('ProspectDetail', 'trapType'); }
    if (typeof obj.reservoirAge !== 'string') { showError('ProspectDetail', 'reservoirAge'); }
    if (typeof obj.waterDepthFt !== 'number') { showError('ProspectDetail', 'waterDepthFt'); }
    if (typeof obj.reservoirDepthFt !== 'number') { showError('ProspectDetail', 'reservoirDepthFt'); }
    if (typeof obj.condition !== 'string') { showError('ProspectDetail', 'condition'); }
    if (typeof obj.initialLiquidMmb !== 'number') { showError('ProspectDetail', 'initialLiquidMmb'); }
    if (typeof obj.initialGasBcf !== 'number') { showError('ProspectDetail', 'initialGasBcf'); }
    if (typeof obj.initialGasMmboe !== 'number') { showError('ProspectDetail', 'initialGasMmboe'); }
    if (typeof obj.initialReservesMmboe !== 'number') { showError('ProspectDetail', 'initialReservesMmboe'); }
    if (obj.plannedWells !== null && !Array.isArray(obj.plannedWells)) { showError('ProspectDetail', 'plannedWells'); }
    if (obj.activeWells !== null && !Array.isArray(obj.activeWells)) { showError('ProspectDetail', 'activeWells'); }

    if (!Array.isArray(obj.owners)) { showError('ProspectDetail', 'owners'); }
    if (typeof obj.point !== 'object') { showError('ProspectDetail', 'point'); }

    this.id = obj.id;
    this.name = obj.name;
    this.alias = obj.alias;
    this.resourceCountry = new Country(obj.resourceCountry);
    this.resourceArea = new Area(obj.resourceArea);
    this.licence = obj.licence;
    this.licenceId = obj.licenceId;
    this.blockId = obj.blockId;
    this.blockNo = obj.blockNo;
    this.type = obj.type;
    this.trapType = obj.trapType;
    this.reservoirAge = obj.reservoirAge;
    this.waterDepthFt = obj.waterDepthFt;
    this.reservoirDepthFt = obj.reservoirDepthFt;
    this.condition = obj.condition;
    this.initialLiquidMmb = obj.initialLiquidMmb;
    this.initialGasBcf = obj.initialGasBcf;
    this.initialGasMmboe = obj.initialGasMmboe;
    this.initialReservesMmboe = obj.initialReservesMmboe;
    this.plannedWells = obj.plannedWells !== null ? obj.plannedWells.map(obj => new ResourcePlannedWell(obj)) : null;
    this.owners = obj.owners.map(obj => new NonProducingParticipant(obj));
    this.activeWells = obj.activeWells !== null ? obj.activeWells.map(obj => new DisplayWell(obj)) : null;
    this.shape = new CircleModel(obj.point.y, obj.point.x, { borderColor: MapShapeBorder, fillColor: 'transparent', radius: 900 });
  }
}

export { ProspectDetail };