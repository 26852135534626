import { handleDecimal } from '../../../../shared/helpers/decimal-places';
import { showError } from '../../../../shared/helpers/common';
import { PortfolioCashflow } from './portfolio-cashflow';
import { PolygonsModel, PolygonModel } from '../../../../shared/helpers/leaflet/models/polygons-model';
import { Country } from '../../../../shared/components/models/gmatrix3-extension-country';
import { Area } from '../../../../shared/components/models/gmatrix3-extension-area';
import { Participant } from '../../../../shared/components/models/gmatrix3-extension-participant';
import { MapShapeBorder } from '../../../../shared/scss/app-colors';

class ProcessingHub {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid Processing Hub param supplied to ProcessingHub.ctor');
    }
    if (typeof obj.hubId !== 'number') { showError('ProcessingHub', 'hubId'); }
    if (typeof obj.hubName !== 'string') { showError('ProcessingHub', 'hubName'); }
    if (typeof obj.finalYear !== 'number') { showError('ProcessingHub', 'finalYear'); }
    if (obj.oilPipelineId !== null && typeof obj.oilPipelineId !== 'number') { showError('ProcessingHub', 'oilPipelineId'); }
    if (obj.gasPipelineId !== null && typeof obj.gasPipelineId !== 'number') { showError('ProcessingHub', 'gasPipelineId'); }
    if (typeof obj.countryId !== 'number') { showError('ProcessingHub', 'countryId'); }
    if (obj.clientChangeDescription !== null && typeof obj.clientChangeDescription !== 'string') { showError('HubDetail', 'clientChangeDescription'); }
    if (obj.clientChangeDescriptionDate !== null && typeof obj.clientChangeDescriptionDate !== 'string') { showError('HubDetail', 'clientChangeDescriptionDate'); }
    if (typeof obj.emissionsComment !== 'string') { showError('HubDetail', 'emissionsComment'); }
    if (typeof obj.electrificationStatus !== 'string') { showError('HubDetail', 'electrificationStatus'); }

    this.hubId = obj.hubId;
    this.hubName = obj.hubName;
    this.finalYear = obj.finalYear;
    this.oilPipelineId = obj.oilPipelineId;
    this.gasPipelineId = obj.gasPipelineId;
    this.oilPipeline = obj.oilPipeline;
    this.gasPipeline = obj.gasPipeline;
    this.countryId = obj.countryId;
    this.clientChangeDescription = obj.clientChangeDescription;
    this.clientChangeDescriptionDate = new Date(obj.clientChangeDescriptionDate);
    this.emissionsComment = obj.emissionsComment;
    this.electrificationStatus = obj.electrificationStatus;
  }
}

class ResourceAnnual {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to ResourceAnnual.ctor');
    }
    if (typeof obj.resourceAnnualId !== 'number') { throw new Error('Invalid resourceAnnualId param supplied to ResourceAnnual.ctor'); }
    if (typeof obj.year !== 'number') { throw new Error('Invalid year param supplied to ResourceAnnual.ctor'); }
    if (typeof obj.liquidProductionMbod !== 'number') { throw new Error('Invalid liquidProductionMbod param supplied to ResourceAnnual.ctor'); }
    if (typeof obj.gasProductionMmscfd !== 'number') { throw new Error('Invalid gasProductionMmscfd param supplied to ResourceAnnual.ctor'); }
    if (typeof obj.gasProductionMboed !== 'number') { throw new Error('Invalid gasProductionMboed param supplied to ResourceAnnual.ctor'); }
    if (typeof obj.liquidTariffUsdBOE !== 'number') { throw new Error('Invalid liquidTariffUsdBOE param supplied to ResourceAnnual.ctor'); }
    if (typeof obj.gasTariffUsdBOE !== 'number') { throw new Error('Invalid gasTariffUsdBOE param supplied to ResourceAnnual.ctor'); }
    if (typeof obj.developmentCapexUsdMM !== 'number') { throw new Error('Invalid developmentCapexUsdMM param supplied to ResourceAnnual.ctor'); }
    if (typeof obj.abexUsdMM !== 'number') { throw new Error('Invalid abexUsdMM param supplied to ResourceAnnual.ctor'); }
    if (typeof obj.opexTypeId !== 'number') { throw new Error('Invalid opexTypeId param supplied to ResourceAnnual.ctor'); }
    if (typeof obj.resourceOpexUsdMM !== 'number') { throw new Error('Invalid resourceOpexUsdMM param supplied to ResourceAnnual.ctor'); }
    if (typeof obj.opexCostSharePercent !== 'number') { throw new Error('Invalid opexCostSharePercent param supplied to ResourceAnnual.ctor'); }

    this.resourceAnnualId = obj.resourceAnnualId;
    this.year = obj.year;
    this.liquidProductionMbod = obj.liquidProductionMbod;
    this.gasProductionMmscfd = obj.gasProductionMmscfd;
    this.gasProductionMboed = obj.gasProductionMboed;
    this.liquidTariffUsdBOE = obj.liquidTariffUsdBOE;
    this.gasTariffUsdBOE = obj.gasTariffUsdBOE;
    this.developmentCapexUsdMM = obj.developmentCapexUsdMM;
    this.abexUsdMM = obj.abexUsdMM;
    this.opexTypeId = obj.opexTypeId;
    this.resourceOpexUsdMM = obj.resourceOpexUsdMM;
    this.opexCostSharePercent = obj.opexCostSharePercent;
    this.combinedMboed = obj.liquidProductionMbod + obj.gasProductionMboed;
  }
}

class EmissionsAnnual {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to EmissionsAnnual.ctor');
    }
    if (typeof obj.year !== 'number') { throw new Error('Invalid year param supplied to EmissionsAnnual.ctor'); }
    if (typeof obj.cO2Tonnes !== 'number') { throw new Error('Invalid cO2Tonnes param supplied to EmissionsAnnual.ctor'); }
    if (typeof obj.cH4Tonnes !== 'number') { throw new Error('Invalid cH4Tonnes param supplied to EmissionsAnnual.ctor'); }
    if (typeof obj.cO2IntensityKGBOE !== 'number') { throw new Error('Invalid cO2IntensityKGBOE param supplied to EmissionsAnnual.ctor'); }
    if (typeof obj.cH4IntensityKGBOE !== 'number') { throw new Error('Invalid cH4IntensityKGBOE param supplied to EmissionsAnnual.ctor'); }

    this.year = obj.year;
    this.cO2Tonnes = obj.cO2Tonnes;
    this.cH4Tonnes = obj.cH4Tonnes;
    this.cO2IntensityKGBOE = obj.cO2IntensityKGBOE;
    this.cH4IntensityKGBOE = obj.cH4IntensityKGBOE;
  }
}

class FiscalResource {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('FiscalResource', 'resourceObj'); }

    if (typeof obj.equity !== 'number') { showError('FiscalResource', 'equity'); }
    if (typeof obj.isSibling !== 'boolean') { showError('FiscalResource', 'isSibling'); }
    if (typeof obj.hasFiscal !== 'boolean') { showError('FiscalResource', 'hasFiscal'); }

    if (obj.resourceAnnuals !== null && !Array.isArray(obj.resourceAnnuals)) { showError('FiscalResource', 'resourceAnnuals'); }
    if (obj.emissionsAnnuals !== null && !Array.isArray(obj.emissionsAnnuals)) { showError('FiscalResource', 'emissionsAnnuals'); }
    if (!Array.isArray(obj.shapes)) { showError('FiscalResource', 'shapes'); }
    if (!Array.isArray(obj.participants)) { showError('FiscalResource', 'participants'); }

    if (obj.cashflow !== null && typeof obj.cashflow !== 'object') { showError('FiscalResource', 'cashflow'); }
    if (typeof obj.resourceCountry !== 'object') { showError('FiscalResource', 'resourceCountry'); }
    if (typeof obj.resourceArea !== 'object') { showError('FiscalResource', 'resourceArea'); }
    if (typeof obj.operatorId !== 'number') { showError('FiscalResource', 'operatorId'); }
    if (typeof obj.operatorName !== 'string') { showError('FiscalResource', 'operatorName'); }

    if (typeof obj.resourceId !== 'number') { throw new Error('Invalid resourceObj.resourceId param supplied to Resource.ctor'); }
    if (typeof obj.resourceName !== 'string') { throw new Error('Invalid resourceObj.resourceName param supplied to CashflowAnnual.ctor'); }
    if (typeof obj.statusId !== 'number') { throw new Error('Invalid resourceObj.statusId param supplied to Resource.ctor'); }
    if (typeof obj.status !== 'string') { throw new Error('Invalid resourceObj.status param supplied to Resource.ctor'); }
    if (typeof obj.developmentConceptId !== 'number') { throw new Error('Invalid resourceObj.developmentConceptId param supplied to Resource.ctor'); }
    if (typeof obj.developmentConcept !== 'string') { throw new Error('Invalid resourceObj.developmentConcept param supplied to Resource.ctor'); }
    if (typeof obj.totalRecoverableLiquidMmb !== 'number') { throw new Error('Invalid resourceObj.totalRecoverableLiquidMmb param supplied to Resource.ctor'); }
    if (typeof obj.liquidToFuel !== 'boolean') { throw new Error('Invalid resourceObj.liquidToFuel param supplied to Resource.ctor'); }
    if (typeof obj.remainingLiquidMbod !== 'number') { throw new Error('Invalid resourceObj.remainingLiquidMbod param supplied to Resource.ctor'); }
    if (typeof obj.remainingLiquidMmb !== 'number') { throw new Error('Invalid resourceObj.remainingLiquidMmb param supplied to Resource.ctor'); }

    if (typeof obj.totalRecoverableGasBcf !== 'number') { throw new Error('Invalid resourceObj.totalRecoverableGasBcf param supplied to Resource.ctor'); }
    if (typeof obj.gasToFuel !== 'boolean') { throw new Error('Invalid resourceObj.gasToFuel param supplied to Resource.ctor'); }
    if (typeof obj.remainingGasMmScfd !== 'number') { throw new Error('Invalid resourceObj.remainingGasMmScfd param supplied to Resource.ctor'); }
    if (typeof obj.remainingGasBcf !== 'number') { throw new Error('Invalid resourceObj.remainingGasBcf param supplied to Resource.ctor'); }
    if (typeof obj.remainingGasMmboe !== 'number') { throw new Error('Invalid resourceObj.remainingGasMmboe param supplied to Resource.ctor'); }

    if (typeof obj.expectedSanctionYear !== 'number') { throw new Error('Invalid resourceObj.expectedSanctionYear param supplied to Resource.ctor'); }
    if (typeof obj.firstProductionYear !== 'number') { throw new Error('Invalid resourceObj.firstProductionYear param supplied to Resource.ctor'); }
    if (typeof obj.peakCombinedProductionYear !== 'number') { throw new Error('Invalid resourceObj.peakCombinedProductionYear param supplied to Resource.ctor'); }
    if (typeof obj.lastProductionYear !== 'number' && obj.lastProductionYear !== null) { throw new Error('Invalid resourceObj.lastProductionYear param supplied to Resource.ctor'); }
    if (typeof obj.decommissionYear !== 'number' && obj.decommissionYear !== null) { throw new Error('Invalid resourceObj.decommissionYear param supplied to Resource.ctor'); }
    if (typeof obj.liquidPriceDiscountPremiumPercent !== 'number') { throw new Error('Invalid resourceObj.liquidPriceDiscountPremiumPercent param supplied to Resource.ctor'); }
    if (typeof obj.gasPriceDiscountPremiumPercent !== 'number') { throw new Error('Invalid resourceObj.gasPriceDiscountPremiumPercent param supplied to Resource.ctor'); }
    if (typeof obj.isPrt !== 'boolean') { throw new Error('Invalid resourceObj.isPrt param supplied to Resource.ctor'); }
    if (typeof obj.totalAbexUSDMM !== 'number') { showError('FiscalResource', 'totalAbexUSDMM'); }
    if (typeof obj.remainingReservesMmboe !== 'number') { showError('FiscalResource', 'remainingReservesMmboe'); }
    if (typeof obj.showExtendedFiscal !== 'boolean') { throw new Error('Invalid resourceObj.showExtendedFiscal param supplied to Resource.ctor'); }

    if (obj.economicCutOffOverrideYear !== null && typeof obj.economicCutOffOverrideYear !== 'number') { showError('FiscalResource', 'economicCutOffOverrideYear'); }
    if (typeof obj.enableDeclineCurve !== 'boolean') { showError('FiscalResource', 'enableDeclineCurve'); }
    if (obj.lastManualInputRateYear !== null && typeof obj.lastManualInputRateYear !== 'number') { showError('FiscalResource', 'lastManualInputRateYear'); }
    if (obj.combinedPhysicalLimitMBOED !== null && typeof obj.combinedPhysicalLimitMBOED !== 'number') { showError('FiscalResource', 'combinedPhysicalLimitMBOED'); }
    if (obj.liquidCumulativeProductionStartYear !== null && typeof obj.liquidCumulativeProductionStartYear !== 'number') { showError('FiscalResource', 'liquidCumulativeProductionStartYear'); }
    if (obj.gasCumulativeProductionStartYear !== null && typeof obj.gasCumulativeProductionStartYear !== 'number') { showError('FiscalResource', 'gasCumulativeProductionStartYear'); }
    if (obj.liquidCumulativeProductionTargetMMB !== null && typeof obj.liquidCumulativeProductionTargetMMB !== 'number') { showError('FiscalResource', 'liquidCumulativeProductionTargetMMB'); }
    if (obj.gasCumulativeProductionTargetBCF !== null && typeof obj.gasCumulativeProductionTargetBCF !== 'number') { showError('FiscalResource', 'gasCumulativeProductionTargetBCF'); }
    if (obj.liquidCumulativeProductionTargetYear !== null && typeof obj.liquidCumulativeProductionTargetYear !== 'number') { showError('FiscalResource', 'liquidCumulativeProductionTargetYear'); }
    if (obj.gasCumulativeProductionTargetYear !== null && typeof obj.gasCumulativeProductionTargetYear !== 'number') { showError('FiscalResource', 'gasCumulativeProductionTargetYear'); }
    if (obj.liquidDeclineRatePercent !== null && typeof obj.liquidDeclineRatePercent !== 'number') { showError('FiscalResource', 'liquidDeclineRatePercent'); }
    if (obj.gasDeclineRatePercent !== null && typeof obj.gasDeclineRatePercent !== 'number') { showError('FiscalResource', 'gasDeclineRatePercent'); }
    if (obj.liquidHyperbolicRatePercent !== null && typeof obj.liquidHyperbolicRatePercent !== 'number') { showError('FiscalResource', 'liquidHyperbolicRatePercent'); }
    if (obj.gasHyperbolicRatePercent !== null && typeof obj.gasHyperbolicRatePercent !== 'number') { showError('FiscalResource', 'gasHyperbolicRatePercent'); }

    this.equity = obj.equity;
    this.isSibling = obj.isSibling;
    this.hasFiscal = obj.hasFiscal;
    this.showExtendedFiscal = obj.showExtendedFiscal;

    this.resourceAnnuals = obj.resourceAnnuals !== null && obj.resourceAnnuals.map(obj => new ResourceAnnual(obj));
    this.emissionsAnnuals = obj.emissionsAnnuals !== null && obj.emissionsAnnuals.map(obj => new EmissionsAnnual(obj));
    this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });
    this.participants = obj.participants.map(obj => new Participant(obj));

    this.cashflow = obj.cashflow !== null && new PortfolioCashflow(obj.cashflow);
    this.hub = new ProcessingHub(obj.hub);
    this.resourceCountry = new Country(obj.resourceCountry);
    this.resourceArea = new Area(obj.resourceArea);
    this.operatorId = obj.operatorId;
    this.operatorName = obj.operatorName;    

    this.resourceId = obj.resourceId;
    this.resourceName = obj.resourceName;    
    this.statusId = obj.statusId;
    this.status = obj.status;
    this.developmentConceptId = obj.developmentConceptId;
    this.developmentConcept = obj.developmentConcept;

    // Liquids
    this.totalRecoverableLiquidMmb = obj.totalRecoverableLiquidMmb;
    this.liquidToFuel = obj.liquidToFuel;
    this.remainingLiquidMbod = obj.remainingLiquidMbod;
    this.remainingLiquidMmb = obj.remainingLiquidMmb;
    
    // Gas
    this.totalRecoverableGasBcf = obj.totalRecoverableGasBcf;
    this.gasToFuel = obj.gasToFuel;
    this.remainingGasMmScfd = obj.remainingGasMmScfd;
    this.remainingGasBcf = obj.remainingGasBcf;
    this.remainingGasMmboe = obj.remainingGasMmboe;
    

    this.expectedSanctionYear = obj.expectedSanctionYear;
    this.firstProductionYear = obj.firstProductionYear;
    this.peakCombinedProductionYear = obj.peakCombinedProductionYear;    
    this.lastProductionYear = obj.lastProductionYear;
    this.decommissionYear = obj.decommissionYear;
    this.liquidPriceDiscountPremiumPercent = obj.liquidPriceDiscountPremiumPercent;
    this.gasPriceDiscountPremiumPercent = obj.gasPriceDiscountPremiumPercent;
    this.isPrt = obj.isPrt;
    this.totalAbexUSDMM = obj.totalAbexUSDMM;
    this.remainingReservesMmboe = obj.remainingReservesMmboe;

    this.economicCutOffOverrideYear = obj.economicCutOffOverrideYear;
    this.enableDeclineCurve = obj.enableDeclineCurve;
    this.lastManualInputRateYear = obj.lastManualInputRateYear;
    this.combinedPhysicalLimitMBOED = obj.combinedPhysicalLimitMBOED;
    this.liquidCumulativeProductionStartYear = obj.liquidCumulativeProductionStartYear;
    this.gasCumulativeProductionStartYear = obj.gasCumulativeProductionStartYear;
    this.liquidCumulativeProductionTargetMMB = obj.liquidCumulativeProductionTargetMMB;
    this.gasCumulativeProductionTargetBCF = obj.gasCumulativeProductionTargetBCF;
    this.liquidCumulativeProductionTargetYear = obj.liquidCumulativeProductionTargetYear;
    this.gasCumulativeProductionTargetYear = obj.gasCumulativeProductionTargetYear;
    this.liquidDeclineRatePercent = obj.liquidDeclineRatePercent;
    this.gasDeclineRatePercent = obj.gasDeclineRatePercent;
    this.liquidHyperbolicRatePercent = obj.liquidHyperbolicRatePercent;
    this.gasHyperbolicRatePercent = obj.gasHyperbolicRatePercent;

    this.displayLiquidPriceDiscountPremiumPercent = this.liquidPriceDiscountPremiumPercent - 100.0;
    this.displayGasPriceDiscountPremiumPercent = this.gasPriceDiscountPremiumPercent - 100.0;
  }
}

class CashFlowAnnual {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to CashFlowAnnual.ctor');
    }
    if (typeof obj.resourceName !== 'string') { throw new Error('Invalid resourceName param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.year !== 'number') { throw new Error('Invalid year param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.liquidPriceUsdBOE !== 'number') { throw new Error('Invalid liquidPriceUsdBOE param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.gasPriceUsdMCF !== 'number') { throw new Error('Invalid gasPriceUsdMCF param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.liquidProductionMbod !== 'number') { throw new Error('Invalid liquidProductionMbod param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.gasProductionMmscfd !== 'number') { throw new Error('Invalid gasProductionMmscfd param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.combinedMboed !== 'number') { throw new Error('Invalid combinedMboed param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.totalRevenueUsdMM !== 'number') { throw new Error('Invalid totalRevenueUsdMM param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.opexUsdMM !== 'number') { throw new Error('Invalid opexUsdMM param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.totalEmissionsChargeUsdMM !== 'number') { throw new Error('Invalid totalEmissionsChargeUsdMM param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.totalTariffUsdMM !== 'number') { throw new Error('Invalid totalTariffUsdMM param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.costForLossesOrDepreciationUsdMM !== 'number') { throw new Error('Invalid costForLossesOrDepreciationUsdMM param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.abexUsdMM !== 'number') { throw new Error('Invalid abexUsdMM param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.preTaxCashflowUsdMM !== 'number') { throw new Error('Invalid preTaxCashflowUsdMM param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.corporationTaxUsdMM !== 'number') { throw new Error('Invalid corporationTaxUsdMM param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.specialTaxUsdMM !== 'number') { throw new Error('Invalid specialTaxUsdMM param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.postTaxCashflowUsdMM !== 'number') { throw new Error('Invalid postTaxCashflowUsdMM param supplied to CashFlowAnnual.ctor'); }
    if (typeof obj.discountedPostTaxCashflowUsdMM !== 'number') { throw new Error('Invalid discountedPostTaxCashflowUsdMM param supplied to CashFlowAnnual.ctor'); }

    this.resourceName = obj.resourceName;
    this.year = obj.year;
    this.liquidPriceUsdBOE = obj.liquidPriceUsdBOE;
    this.gasPriceUsdMCF = obj.gasPriceUsdMCF;
    this.liquidProductionMbod = obj.liquidProductionMbod;
    this.gasProductionMmscfd = obj.gasProductionMmscfd;
    this.combinedMboed = obj.combinedMboed;
    this.totalRevenueUsdMM = obj.totalRevenueUsdMM;
    this.opexUsdMM = obj.opexUsdMM;
    this.totalEmissionsChargeUsdMM = obj.totalEmissionsChargeUsdMM;
    this.totalTariffUsdMM = obj.totalTariffUsdMM;
    this.costForLossesOrDepreciationUsdMM = obj.costForLossesOrDepreciationUsdMM;
    this.abexUsdMM = obj.abexUsdMM;
    this.preTaxCashflowUsdMM = obj.preTaxCashflowUsdMM;
    this.corporationTaxUsdMM = obj.corporationTaxUsdMM;
    this.specialTaxUsdMM = obj.specialTaxUsdMM;
    this.postTaxCashflowUsdMM = obj.postTaxCashflowUsdMM;
    this.discountedPostTaxCashflowUsdMM = obj.discountedPostTaxCashflowUsdMM;
  }
}

export { ResourceAnnual, FiscalResource, handleDecimal, ProcessingHub, Country, Area, Participant, CashFlowAnnual, EmissionsAnnual };
