import moment from 'moment';
import { showError } from '../shared/helpers/common';
import { FiscalResource } from '../components/fiscal/models/portfolio/fiscal-resource';
import { PortfolioCashflow } from '../components/fiscal/models/portfolio/portfolio-cashflow';
import { MapShapeBorder } from '../shared/scss/app-colors';
import { PolygonsModel, PolygonModel } from '../shared/helpers/leaflet/models/polygons-model';
import { WgeAnalyticsGreen, WgeSectorsRed } from '../shared/scss/wge-colors';
import { ChartData, PieChartData } from '../shared/components/generic-chart/chart-data';
import { assignColours } from '../shared/helpers/array-color-helper';
import { dateTypes, valueTypes } from '../shared/helpers/value-handler';
import { WellResource } from './well-resource';
import { FasterPortfolioCorporateSummary } from '../components/fiscal/models/portfolio/faster-portfolio';
import { UserValuations } from './user-valuations';

class NetAnnual {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('NetAnnual', 'NetAnnual'); }
    if (typeof obj.year !== 'number') { showError('NetAnnual', 'year'); }
    if (typeof obj.capexUSDMM !== 'number') { showError('NetAnnual', 'capexUSDMM'); }
    if (typeof obj.opexUSDMM !== 'number') { showError('NetAnnual', 'opexUSDMM'); }
    if (typeof obj.abexUSDMM !== 'number') { showError('NetAnnual', 'abexUSDMM'); }
    if (typeof obj.combinedMboed !== 'number') { showError('NetAnnual', 'combinedMboed'); }
    if (typeof obj.cO2KTonnes !== 'number') { showError('NetAnnual', 'cO2KTonnes'); }

    this.year = obj.year;
    this.capexUSDMM = obj.capexUSDMM;
    this.opexUSDMM = obj.opexUSDMM;
    this.abexUSDMM = obj.abexUSDMM;
    this.combinedMboed = obj.combinedMboed;
    this.cO2KTonnes = obj.cO2KTonnes;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) { showError('NetAnnual', 'array'); }
    const result = array.map(obj => new NetAnnual(obj));
    return result;
  }
}

class NetField {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('NetField', 'NetField'); }
    if (typeof obj.resourceId !== 'number') { showError('NetField', 'resourceId'); }
    if (typeof obj.resourceName !== 'string') { showError('NetField', 'resourceName'); }
    if (typeof obj.statusId !== 'number') { showError('NetField', 'statusId'); }
    if (typeof obj.status !== 'string') { showError('NetField', 'status'); }
    if (typeof obj.country !== 'string') { showError('NetField', 'country'); }
    if (typeof obj.area !== 'string') { showError('NetField', 'area'); }
    if (typeof obj.hubId !== 'number') { showError('NetField', 'hubId'); }
    if (typeof obj.hub !== 'string') { showError('NetField', 'hub'); }
    if (typeof obj.productionStartYear !== 'number') { showError('NetField', 'productionStartYear'); }
    if (typeof obj.expectedCOPYear !== 'number') { showError('NetField', 'expectedCOPYear'); }
    if (typeof obj.remainingReservesPercent !== 'number') { showError('NetField', 'remainingReservesPercent'); }
    if (typeof obj.equity !== 'number') { showError('NetField', 'equity'); }
    if (typeof obj.operatorId !== 'number') { showError('NetField', 'operatorId'); }
    if (typeof obj.operator !== 'string') { showError('NetField', 'operator'); }
    if (typeof obj.equityMultiplier !== 'number') { showError('NetField', 'equityMultiplier'); }
    if (typeof obj.currentYearNetProductionMBOEPD !== 'number') { showError('NetField', 'currentYearNetProductionMBOEPD'); }
    if (typeof obj.currentYearNetCapexUSDMM !== 'number') { showError('NetField', 'currentYearNetCapexUSDMM'); }
    if (typeof obj.currentYearNetOpexUSDMM !== 'number') { showError('NetField', 'currentYearNetOpexUSDMM'); }
    if (typeof obj.netRemainingOpexUSDMM !== 'number') { showError('NetField', 'netRemainingOpexUSDMM'); }
    if (typeof obj.netRemainingCapexUSDMM !== 'number') { showError('NetField', 'netRemainingCapexUSDMM'); }
    if (typeof obj.netRemainingAbexUSDMM !== 'number') { showError('NetField', 'netRemainingAbexUSDMM'); }
    if (typeof obj.netRemainingLiquidMmb !== 'number') { showError('NetField', 'netRemainingLiquidMmb'); }
    if (typeof obj.netRemainingGasMmboe !== 'number') { showError('NetField', 'netRemainingGasMmboe'); }
    if (typeof obj.netRemainingReservesMmboe !== 'number') { showError('NetField', 'netRemainingReservesMmboe'); }
    if (typeof obj.netRemainingNPVUSDMM !== 'number') { showError('NetField', 'netRemainingNPVUSDMM'); }
    if (typeof obj.netRemainingGasBCF !== 'number') { showError('NetField', 'netRemainingGasBCF'); }
    if (typeof obj.currentYearNetLiquidProductionMbod !== 'number') { showError('NetField', 'currentYearNetLiquidProductionMbod'); }
    if (typeof obj.currentYearNetGasProductionMmscfd !== 'number') { showError('NetField', 'currentYearNetGasProductionMmscfd'); }
    if (typeof obj.currentYearNetAbexUSDMM !== 'number') { showError('NetField', 'currentYearNetAbexUSDMM'); }
    if (obj.netAnnuals !== null && !Array.isArray(obj.netAnnuals)) { showError('NetField', 'netAnnuals'); }

    //if (typeof obj.blocks !== 'string') { showError('NetField', 'blocks'); }
    //if (typeof obj.licences !== 'string') { showError('NetField', 'licences'); }
    if (typeof obj.discoveryWellLicence !== 'string') { showError('NetField', 'discoveryWellLicence'); }
    if (typeof obj.discoveryWell !== 'string') { showError('NetField', 'discoveryWell'); }
    if (typeof obj.discoveryYear !== 'number') { showError('NetField', 'discoveryYear'); }
    if (typeof obj.fieldApprovalYear !== 'number') { showError('NetField', 'fieldApprovalYear'); }
    if (typeof obj.expectedDecomissionYear !== 'number') { showError('NetField', 'expectedDecomissionYear'); }
    if (typeof obj.liquidExport !== 'string') { showError('NetField', 'liquidExport'); }
    if (typeof obj.gasExport !== 'string') { showError('NetField', 'gasExport'); }
    if (typeof obj.developmentType !== 'string') { showError('NetField', 'developmentType'); }

    if (typeof obj.netRemainingTariffUSDMM !== 'number') { showError('NetField', 'netRemainingTariffUSDMM'); }
    if (typeof obj.netRemainingPreTaxRevenueUSDMM !== 'number') { showError('NetField', 'netRemainingPreTaxRevenueUSDMM'); }
    if (typeof obj.netRemainingPreTaxCashflowUSDMM !== 'number') { showError('NetField', 'netRemainingPreTaxCashflowUSDMM'); }
    if (typeof obj.netRemainingPostTaxUndiscountedCashflowUSDMM !== 'number') { showError('NetField', 'netRemainingPostTaxUndiscountedCashflowUSDMM'); }

    if (typeof obj.currentYearNetTariffUSDMM !== 'number') { showError('NetField', 'currentYearNetTariffUSDMM'); }
    if (typeof obj.currentYearNetRevenueUSDMM !== 'number') { showError('NetField', 'currentYearNetRevenueUSDMM'); }
    if (typeof obj.currentYearNetPreTaxCashflowUSDMM !== 'number') { showError('NetField', 'currentYearNetPreTaxCashflowUSDMM'); }
    if (typeof obj.currentYearNetPostTaxUndiscountedCashflowUSDMM !== 'number') { showError('NetField', 'currentYearNetPostTaxUndiscountedCashflowUSDMM'); }

    if (typeof obj.reservoirAge !== 'string') { showError('NetField', 'reservoirAge'); }
    if (typeof obj.reservoirDepthFt !== 'number') { showError('NetField', 'reservoirDepthFt'); }
    if (typeof obj.trapType !== 'string') { showError('NetField', 'trapType'); }
    if (typeof obj.pressureStatus !== 'string') { showError('NetField', 'pressureStatus'); }
    if (typeof obj.temperatureStatus !== 'string') { showError('NetField', 'temperatureStatus'); }

    this.resourceId = obj.resourceId;
    this.resourceName = obj.resourceName;
    this.statusId = obj.statusId;
    this.status = obj.status;
    this.country = obj.country;
    this.area = obj.area;
    this.hubId = obj.hubId;
    this.hub = obj.hub;
    this.productionStartYear = obj.productionStartYear;
    this.expectedCOPYear = obj.expectedCOPYear;
    this.remainingReservesPercent = obj.remainingReservesPercent;
    this.equity = obj.equity;
    this.operatorId = obj.operatorId;
    this.operator = obj.operator;
    this.equityMultiplier = obj.equityMultiplier;
    this.currentYearNetProductionMBOEPD = obj.currentYearNetProductionMBOEPD;
    this.currentYearNetCapexUSDMM = obj.currentYearNetCapexUSDMM;
    this.currentYearNetOpexUSDMM = obj.currentYearNetOpexUSDMM;
    this.netRemainingOpexUSDMM = obj.netRemainingOpexUSDMM;
    this.netRemainingCapexUSDMM = obj.netRemainingCapexUSDMM;
    this.netRemainingAbexUSDMM = obj.netRemainingAbexUSDMM;
    this.netRemainingLiquidMmb = obj.netRemainingLiquidMmb;
    this.netRemainingGasMmboe = obj.netRemainingGasMmboe;
    this.netRemainingReservesMmboe = obj.netRemainingReservesMmboe;
    this.netRemainingNPVUSDMM = obj.netRemainingNPVUSDMM;
    this.netRemainingGasBCF = obj.netRemainingGasBCF;
    this.currentYearNetLiquidProductionMbod = obj.currentYearNetLiquidProductionMbod;
    this.currentYearNetGasProductionMmscfd = obj.currentYearNetGasProductionMmscfd;
    this.currentYearNetAbexUSDMM = obj.currentYearNetAbexUSDMM;
    if (obj.netAnnuals !== null && !Array.isArray(obj.netAnnuals)) this.netAnnuals = NetAnnual.FromArray(obj.netAnnuals);

    this.netRemainingTariffUSDMM = obj.netRemainingTariffUSDMM;
    this.netRemainingPreTaxRevenueUSDMM = obj.netRemainingPreTaxRevenueUSDMM;
    this.netRemainingPreTaxCashflowUSDMM = obj.netRemainingPreTaxCashflowUSDMM;
    this.netRemainingPostTaxUndiscountedCashflowUSDMM = obj.netRemainingPostTaxUndiscountedCashflowUSDMM;

    this.currentYearNetTariffUSDMM = obj.currentYearNetTariffUSDMM;
    this.currentYearNetRevenueUSDMM = obj.currentYearNetRevenueUSDMM;
    this.currentYearNetPreTaxCashflowUSDMM = obj.currentYearNetPreTaxCashflowUSDMM;
    this.currentYearNetPostTaxUndiscountedCashflowUSDMM = obj.currentYearNetPostTaxUndiscountedCashflowUSDMM;

    this.discoveryWellLicence = obj.discoveryWellLicence;
    this.discoveryWell = obj.discoveryWell;
    this.discoveryYear = obj.discoveryYear;
    this.fieldApprovalYear = obj.fieldApprovalYear;
    this.expectedDecomissionYear = obj.expectedDecomissionYear;
    this.liquidExport = obj.liquidExport;
    this.gasExport = obj.gasExport;
    this.developmentType = obj.developmentType;

    this.reservoirAge = obj.reservoirAge;
    this.reservoirDepthFt = obj.reservoirDepthFt;
    this.trapType = obj.trapType;
    this.pressureStatus = obj.pressureStatus;
    this.temperatureStatus = obj.temperatureStatus;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) { showError('NetField', 'array'); }
    const result = array.map(obj => new NetField(obj));
    return result;
  }
}

class NetDiscovery {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('NetDiscovery', 'NetDiscovery'); }
    if (typeof obj.resourceId !== 'number') { showError('NetDiscovery', 'resourceId'); }
    if (typeof obj.resourceName !== 'string') { showError('NetDiscovery', 'resourceName'); }
    if (typeof obj.country !== 'string') { showError('NetDiscovery', 'country'); }
    if (typeof obj.area !== 'string') { showError('NetDiscovery', 'area'); }
    if (typeof obj.licenceId !== 'number') { showError('NetDiscovery', 'licenceId'); }
    if (typeof obj.licence !== 'string') { showError('NetDiscovery', 'licence'); }
    if (typeof obj.blockId !== 'number') { showError('NetDiscovery', 'blockId'); }
    if (typeof obj.block !== 'string') { showError('NetDiscovery', 'block'); }
    if (typeof obj.equity !== 'number') { showError('NetDiscovery', 'equity'); }
    if (typeof obj.operatorId !== 'number') { showError('NetDiscovery', 'operatorId'); }
    if (typeof obj.operator !== 'string') { showError('NetDiscovery', 'operator'); }
    if (typeof obj.discoveryYear !== 'number') { showError('NetDiscovery', 'discoveryYear'); }
    if (typeof obj.discoveryWellId !== 'number') { showError('NetDiscovery', 'discoveryWellId'); }
    if (typeof obj.discoveryWell !== 'string') { showError('NetDiscovery', 'discoveryWell'); }
    if (typeof obj.classification !== 'string') { showError('NetDiscovery', 'classification'); }
    if (typeof obj.classificationRationale !== 'string') { showError('NetDiscovery', 'classificationRationale'); }
    if (typeof obj.netLiquidMmb !== 'number') { showError('NetDiscovery', 'netLiquidMmb'); }
    if (typeof obj.netGasBcf !== 'number') { showError('NetDiscovery', 'netGasBcf'); }
    if (typeof obj.netGasMmboe !== 'number') { showError('NetDiscovery', 'netGasMmboe'); }
    if (typeof obj.netCombinedMmboe !== 'number') { showError('NetDiscovery', 'netCombinedMmboe'); }
    if (typeof obj.reservoirAge !== 'string') { showError('NetDiscovery', 'reservoirAge'); }
    if (typeof obj.grossLiquidMmb !== 'number') { showError('NetDiscovery', 'grossLiquidMmb'); }
    if (typeof obj.grossGasBcf !== 'number') { showError('NetDiscovery', 'grossGasBcf'); }
    if (typeof obj.grossCombinedMmboe !== 'number') { showError('NetDiscovery', 'grossCombinedMmboe'); }
    if (typeof obj.waterDepthFt !== 'number') { showError('NetDiscovery', 'waterDepthFt'); }
    if (typeof obj.reservoirDepthFt !== 'number') { showError('NetDiscovery', 'reservoirDepthFt'); }
    if (typeof obj.reservoirCondition !== 'string') { showError('NetDiscovery', 'reservoirCondition'); }
    if (typeof obj.trapType !== 'string') { showError('NetDiscovery', 'trapType'); }
    if (typeof obj.pressureStatus !== 'string') { showError('NetDiscovery', 'pressureStatus'); }
    if (typeof obj.temperatureStatus !== 'string') { showError('NetDiscovery', 'temperatureStatus'); }

    this.resourceId = obj.resourceId;
    this.resourceName = obj.resourceName;
    this.country = obj.country;
    this.area = obj.area;
    this.licenceId = obj.licenceId;
    this.licence = obj.licence;
    this.blockId = obj.blockId;
    this.block = obj.block;
    this.equity = obj.equity;
    this.operatorId = obj.operatorId;
    this.operator = obj.operator;
    this.discoveryYear = obj.discoveryYear;
    this.discoveryWellId = obj.discoveryWellId;
    this.discoveryWell = obj.discoveryWell;
    this.classification = obj.classification;
    this.classificationRationale = obj.classificationRationale;
    this.netLiquidMmb = obj.netLiquidMmb;
    this.netGasBcf = obj.netGasBcf;
    this.netGasMmboe = obj.netGasMmboe;
    this.netCombinedMmboe = obj.netCombinedMmboe;
    this.reservoirAge = obj.reservoirAge;
    this.grossLiquidMmb = obj.grossLiquidMmb;
    this.grossGasBcf = obj.grossGasBcf;
    this.grossCombinedMmboe = obj.grossCombinedMmboe;
    this.waterDepthFt = obj.waterDepthFt;
    this.reservoirDepthFt = obj.reservoirDepthFt;
    this.reservoirCondition = obj.reservoirCondition;
    this.trapType = obj.trapType;
    this.pressureStatus = obj.pressureStatus;
    this.temperatureStatus = obj.temperatureStatus;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) { showError('NetDiscovery', 'array'); }
    const result = array.map(obj => new NetDiscovery(obj));
    return result;
  }
}

class NetProspect {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('NetProspect', 'NetProspect'); }
    if (typeof obj.resourceId !== 'number') { showError('NetProspect', 'resourceId'); }
    if (typeof obj.prospectId !== 'string') { showError('NetProspect', 'prospectId'); }
    if (typeof obj.prospectName !== 'string') { showError('NetProspect', 'prospectName'); }
    if (typeof obj.country !== 'string') { showError('NetProspect', 'country'); }
    if (typeof obj.area !== 'string') { showError('NetProspect', 'area'); }
    if (typeof obj.licenceId !== 'number') { showError('NetProspect', 'licenceId'); }
    if (typeof obj.licence !== 'string') { showError('NetProspect', 'licence'); }
    if (typeof obj.blockId !== 'number') { showError('NetProspect', 'blockId'); }
    if (typeof obj.block !== 'string') { showError('NetProspect', 'block'); }
    if (typeof obj.equity !== 'number') { showError('NetProspect', 'equity'); }
    if (typeof obj.operatorId !== 'number') { showError('NetProspect', 'operatorId'); }
    if (typeof obj.operator !== 'string') { showError('NetProspect', 'operator'); }
    if (typeof obj.netLiquidMmb !== 'number') { showError('NetProspect', 'netLiquidMmb'); }
    if (typeof obj.netGasBcf !== 'number') { showError('NetProspect', 'netGasBcf'); }
    if (typeof obj.netGasMmboe !== 'number') { showError('NetProspect', 'netGasMmboe'); }
    if (typeof obj.netCombinedMmboe !== 'number') { showError('NetProspect', 'netCombinedMmboe'); }
    if (typeof obj.reservoirAge !== 'string') { showError('NetProspect', 'reservoirAge'); }
    if (typeof obj.grossLiquidMmb !== 'number') { showError('NetProspect', 'grossLiquidMmb'); }
    if (typeof obj.grossGasBcf !== 'number') { showError('NetProspect', 'grossGasBcf'); }
    if (typeof obj.grossCombinedMmboe !== 'number') { showError('NetProspect', 'grossCombinedMmboe'); }
    if (typeof obj.waterDepthFt !== 'number') { showError('NetProspect', 'waterDepthFt'); }
    if (typeof obj.reservoirDepthFt !== 'number') { showError('NetProspect', 'reservoirDepthFt'); }
    if (typeof obj.reservoirCondition !== 'string') { showError('NetProspect', 'reservoirCondition'); }
    if (typeof obj.trapType !== 'string') { showError('NetProspect', 'trapType'); }

    this.resourceId = obj.resourceId;
    this.prospectId = obj.prospectId;
    this.prospectName = obj.prospectName;
    this.country = obj.country;
    this.area = obj.area;
    this.licenceId = obj.licenceId;
    this.licence = obj.licence;
    this.blockId = obj.blockId;
    this.block = obj.block;
    this.equity = obj.equity;
    this.operatorId = obj.operatorId;
    this.operator = obj.operator;
    this.classification = obj.classification;
    this.netLiquidMmb = obj.netLiquidMmb;
    this.netGasBcf = obj.netGasBcf;
    this.netGasMmboe = obj.netGasMmboe;
    this.netCombinedMmboe = obj.netCombinedMmboe;
    this.reservoirAge = obj.reservoirAge;
    this.grossLiquidMmb = obj.grossLiquidMmb;
    this.grossGasBcf = obj.grossGasBcf;
    this.grossCombinedMmboe = obj.grossCombinedMmboe;
    this.waterDepthFt = obj.waterDepthFt;
    this.reservoirDepthFt = obj.reservoirDepthFt;
    this.reservoirCondition = obj.reservoirCondition;
    this.trapType = obj.trapType;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) { showError('NetProspect', 'array'); }
    const result = array.map(obj => new NetProspect(obj));
    return result;
  }
}

class CompletedAndActiveWell {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('CompletedAndActiveWell', 'CompletedAndActiveWell'); }
    if (typeof obj.wellId !== 'number') { showError('CompletedAndActiveWell', 'wellId'); }
    if (typeof obj.well !== 'string') { showError('CompletedAndActiveWell', 'well'); }
    if (typeof obj.country !== 'string') { showError('CompletedAndActiveWell', 'country'); }
    if (typeof obj.area !== 'string') { showError('CompletedAndActiveWell', 'area'); }
    if (typeof obj.typeName !== 'string') { showError('CompletedAndActiveWell', 'typeName'); }
    if (!Array.isArray(obj.resources)) { showError('CompletedAndActiveWell', 'resources'); }
    if (typeof obj.operatorId !== 'number') { showError('CompletedAndActiveWell', 'operatorId'); }
    if (typeof obj.operatorName !== 'string') { showError('CompletedAndActiveWell', 'operatorName'); }
    if (typeof obj.equity !== 'number') { showError('CompletedAndActiveWell', 'equity'); }
    if (typeof obj.statusName !== 'string') { showError('CompletedAndActiveWell', 'statusName'); }
    if (typeof obj.resultName !== 'string') { showError('CompletedAndActiveWell', 'resultName'); }
    if (typeof obj.reportId !== 'number') { showError('CompletedAndActiveWell', 'reportId'); }
    if (obj.originalLicenceId !== null && typeof obj.originalLicenceId !== 'number') { showError('CompletedAndActiveWell', 'originalLicenceId'); }
    if (obj.originalLicenceName !== null && typeof obj.originalLicenceName !== 'string') { showError('CompletedAndActiveWell', 'originalLicenceName'); }
    if (obj.currentLicenceId !== null && typeof obj.currentLicenceId !== 'number') { showError('CompletedAndActiveWell', 'currentLicenceId'); }
    if (obj.currentLicenceName !== null && typeof obj.currentLicenceName !== 'string') { showError('CompletedAndActiveWell', 'currentLicenceName'); }

    if (typeof obj.originalLicenceRound !== 'string') { showError('CompletedAndActiveWell', 'originalLicenceRound'); }
    if (typeof obj.currentLicenceRound !== 'string') { showError('CompletedAndActiveWell', 'currentLicenceRound'); }
    if (typeof obj.licenceCommitment !== 'string') { showError('CompletedAndActiveWell', 'licenceCommitment'); }
    if (typeof obj.subTypeName !== 'string') { showError('CompletedAndActiveWell', 'subTypeName'); }
    if (typeof obj.durationDays !== 'number') { showError('CompletedAndActiveWell', 'durationDays'); }
    if (typeof obj.drillingRig !== 'string') { showError('CompletedAndActiveWell', 'drillingRig'); }
    if (typeof obj.wellCostUSDMM !== 'number') { showError('CompletedAndActiveWell', 'wellCostUSDMM'); }
    if (typeof obj.primaryObjective !== 'string') { showError('CompletedAndActiveWell', 'primaryObjective'); }
    if (typeof obj.totalDepthFt !== 'number') { showError('CompletedAndActiveWell', 'totalDepthFt'); }
    if (typeof obj.waterDepthFt !== 'number') { showError('CompletedAndActiveWell', 'waterDepthFt'); }

    let startDateMoment = null;
    if (obj.startDate !== null) {
      if (typeof obj.startDate !== 'string') { showError('CompletedAndActiveWell', 'startDate'); }
      startDateMoment = moment(obj.startDate);
      if (!startDateMoment.isValid()) { showError('CompletedAndActiveWell', 'startDateMoment'); }
    }

    let endDateMoment = null;
    if (obj.endDate !== null) {
      if (typeof obj.endDate !== 'string') { showError('CompletedAndActiveWellRow', 'endDate'); }
      endDateMoment = moment(obj.endDate);
      if (!endDateMoment.isValid()) { showError('CompletedAndActiveWellRow', 'endDateMoment'); }
    }

    this.wellId = obj.wellId;
    this.well = obj.well;
    this.country = obj.country;
    this.area = obj.area;
    this.typeName = obj.typeName;
    this.resources = WellResource.FromArray(obj.resources);
    this.resourceString = this.resources === null ? null : this.resources.map(x => x.resourceName).join('; ');
    this.operatorId = obj.operatorId;
    this.operatorName = obj.operatorName;
    this.equity = obj.equity;
    this.statusName = obj.statusName;
    this.resultName = obj.resultName;
    this.reportId = obj.reportId;
    this.originalLicenceId = obj.originalLicenceId;
    this.originalLicenceName = obj.originalLicenceName;
    this.currentLicenceId = obj.currentLicenceId;
    this.currentLicenceName = obj.currentLicenceName;

    this.originalLicenceRound = obj.originalLicenceRound;
    this.currentLicenceRound = obj.currentLicenceRound;
    this.licenceCommitment = obj.licenceCommitment;
    this.subTypeName = obj.subTypeName;
    this.durationDays = obj.durationDays;
    this.drillingRig = obj.drillingRig;
    this.wellCostUSDMM = obj.wellCostUSDMM; 
    this.primaryObjective = obj.primaryObjective; 
    this.totalDepthFt = obj.totalDepthFt;
    this.waterDepthFt = obj.waterDepthFt;

    this.startDate = startDateMoment === null ? null : startDateMoment.format(dateTypes.GetFormatString(dateTypes.AtlasDisplay));
    this.endDate = endDateMoment === null ? null : endDateMoment.format(dateTypes.GetFormatString(dateTypes.AtlasDisplay));

    this.resourceList = this.resources === null ? null : this.resources.map(x => x.resourceName).join(', ');
  }

  static FromArray(array) {
    if (!Array.isArray(array)) { showError('CompletedAndActiveWell', 'array'); }
    const result = array.map(obj => new CompletedAndActiveWell(obj));
    return result;
  }
}

class PlannedWell {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('PlannedWell', 'PlannedWell'); }
    if (typeof obj.wellId !== 'number') { showError('PlannedWell', 'wellId'); }
    if (typeof obj.well !== 'string') { showError('PlannedWell', 'well'); }
    if (typeof obj.country !== 'string') { showError('PlannedWell', 'country'); }
    if (typeof obj.area !== 'string') { showError('PlannedWell', 'area'); }
    if (typeof obj.typeName !== 'string') { showError('PlannedWell', 'typeName'); }
    if (typeof obj.statusName !== 'string') { showError('PlannedWell', 'statusName'); }
    if (!Array.isArray(obj.resources)) { showError('PlannedWell', 'resources'); }
    if (typeof obj.operatorId !== 'number') { showError('PlannedWell', 'operatorId'); }
    if (typeof obj.operatorName !== 'string') { showError('PlannedWell', 'operatorName'); }
    if (typeof obj.equity !== 'number') { showError('PlannedWell', 'equity'); }
    if (typeof obj.estimatedTiming !== 'string') { showError('PlannedWell', 'estimatedTiming'); }
    if (typeof obj.licence !== 'string') { showError('PlannedWell', 'licence'); }
    if (typeof obj.licenceId !== 'number') { showError('PlannedWell', 'licenceId'); }
    if (typeof obj.block !== 'string') { showError('PlannedWell', 'Block'); }
    if (typeof obj.blockId !== 'number') { showError('PlannedWell', 'blockId'); }
    if (typeof obj.licenceRound !== 'string') { showError('PlannedWell', 'licenceRound'); }
    if (typeof obj.licenceCommitment !== 'string') { showError('PlannedWell', 'licenceCommitment'); }
    if (typeof obj.subTypeName !== 'string') { showError('PlannedWell', 'subTypeName'); }
    if (typeof obj.approvedForDrilling !== 'string') { showError('PlannedWell', 'approvedForDrilling'); }
    if (typeof obj.drillingRig !== 'string') { showError('PlannedWell', 'drillingRig'); }
    if (typeof obj.primaryObjective !== 'string') { showError('PlannedWell', 'primaryObjective'); }
    if (typeof obj.waterDepthFt !== 'number') { showError('PlannedWell', 'waterDepthFt'); }

    this.wellId = obj.wellId;
    this.well = obj.well;
    this.country = obj.country;
    this.area = obj.area;
    this.typeName = obj.typeName;
    this.statusName = obj.statusName;
    this.resources = WellResource.FromArray(obj.resources);
    this.resourceString = this.resources === null ? null : this.resources.map(x => x.resourceName).join('; ');
    this.operatorId = obj.operatorId;
    this.operatorName = obj.operatorName;
    this.equity = obj.equity;
    this.estimatedTiming = obj.estimatedTiming;
    this.block = obj.block;
    this.blockId = obj.blockId;
    this.licence = obj.licence;
    this.licenceId = obj.licenceId;
    this.licenceRound = obj.licenceRound;
    this.licenceCommitment = obj.licenceCommitment;
    this.subTypeName = obj.subTypeName;
    this.approvedForDrilling = obj.approvedForDrilling;
    this.drillingRig = obj.drillingRig;
    this.primaryObjective = obj.primaryObjective;
    this.waterDepthFt = obj.waterDepthFt;

    this.resourceList = this.resources === null ? null : this.resources.map(x => x.resourceName).join(', ');
  }

  static FromArray(array) {
    if (!Array.isArray(array)) { showError('PlannedWell', 'array'); }
    const result = array.map(obj => new PlannedWell(obj));
    return result;
  }
}

class CorporateDetailChartData {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to CorporateDetailChartData.ctor');
    }

    if (obj.netRemainingReservesByStatus !== null && typeof obj.netRemainingReservesByStatus !== 'object') { showError('CorporateDetailChartData', 'netRemainingReservesByStatus'); }
    if (obj.corporateProduction !== null && typeof obj.corporateProduction !== 'object') { showError('CorporateDetailChartData', 'corporateProduction'); }
    if (obj.remainingReservesByField !== null && typeof obj.remainingReservesByField !== 'object') { showError('CorporateDetailChartData', 'remainingReservesByField'); }
    if (obj.productionByField !== null && typeof obj.productionByField !== 'object') { showError('CorporateDetailChartData', 'productionByField'); }
    if (obj.capexByField !== null && typeof obj.capexByField !== 'object') { showError('CorporateDetailChartData', 'capexByField'); }
    if (obj.opexByField !== null && typeof obj.opexByField !== 'object') { showError('CorporateDetailChartData', 'opexByField'); }
    if (obj.abexByField !== null && typeof obj.abexByField !== 'object') { showError('CorporateDetailChartData', 'abexByField'); }
    if (obj.opexPerBOE !== null && typeof obj.opexPerBOE !== 'object') { showError('CorporateDetailChartData', 'opexPerBOE'); }

    if (obj.netDiscoveryResource !== null && typeof obj.netDiscoveryResource !== 'object') { showError('CorporateDetailChartData', 'netDiscoveryResource'); }
    if (obj.netDiscoveryResourceByClassificationArea !== null && typeof obj.netDiscoveryResourceByClassificationArea !== 'object') { showError('CorporateDetailChartData', 'netDiscoveryResourceByClassificationArea'); }
    if (obj.netProspectResource !== null && typeof obj.netProspectResource !== 'object') { showError('CorporateDetailChartData', 'netProspectResource'); }
    if (obj.netProspectResourceByReservoirArea !== null && typeof obj.netProspectResourceByReservoirArea !== 'object') { showError('CorporateDetailChartData', 'netProspectResourceByReservoirArea'); }
    if (obj.prospectsByReservoirArea !== null && typeof obj.prospectsByReservoirArea !== 'object') { showError('CorporateDetailChartData', 'prospectsByReservoirArea'); }
    if (obj.emissionsByField !== null && typeof obj.emissionsByField !== 'object') { showError('CorporateDetailChartData', 'emissionsByField'); }
    if (obj.emissionsByHub !== null && typeof obj.emissionsByHub !== 'object') { showError('CorporateDetailChartData', 'emissionsByHub'); }

    this.netRemainingReservesByStatus = null;
    if (obj.netRemainingReservesByStatus !== null && obj.netRemainingReservesByStatus !== undefined) {
      this.netRemainingReservesByStatus = obj.netRemainingReservesByStatus.map(obj => new PieChartData(obj)); // new PieChartData(obj.netRemainingReservesByStatus);
      assignColours(this.netRemainingReservesByStatus, 'color');
    }

    this.corporateProduction = null;
    if (obj.corporateProduction !== null && obj.corporateProduction !== undefined) {
      this.corporateProduction = new ChartData(obj.corporateProduction);
      this.corporateProduction.yVal[0].color = WgeAnalyticsGreen;
      this.corporateProduction.yVal[1].color = WgeSectorsRed;
    }

    this.remainingReservesByField = null;
    if (obj.remainingReservesByField !== null && obj.remainingReservesByField !== undefined) {
      this.remainingReservesByField = new ChartData(obj.remainingReservesByField);
      this.remainingReservesByField.yVal[0].color = WgeAnalyticsGreen;
      this.remainingReservesByField.yVal[1].color = WgeSectorsRed;
    }

    this.productionByField = null;
    if (obj.productionByField !== null && obj.productionByField !== undefined) {
      this.productionByField = new ChartData(obj.productionByField);
      assignColours(this.productionByField.yVal, 'color');
    }

    this.capexByField = null;
    if (obj.capexByField !== null && obj.capexByField !== undefined) {
      this.capexByField = new ChartData(obj.capexByField);
      assignColours(this.capexByField.yVal, 'color');
    }

    this.opexByField = null;
    if (obj.opexByField !== null && obj.opexByField !== undefined) {
      this.opexByField = new ChartData(obj.opexByField);
      assignColours(this.opexByField.yVal, 'color');
    }

    this.abexByField = null;
    if (obj.abexByField !== null && obj.abexByField !== undefined) {
      this.abexByField = new ChartData(obj.abexByField);
      assignColours(this.abexByField.yVal, 'color');
    }

    this.opexPerBOE = null;
    if (obj.opexPerBOE !== null && obj.opexPerBOE !== undefined) {
      this.opexPerBOE = new ChartData(obj.opexPerBOE);
      assignColours(this.opexPerBOE.yVal, 'color');
    }

    this.netDiscoveryResource = null;
    if (obj.netDiscoveryResource !== null && obj.netDiscoveryResource !== undefined) {
      this.netDiscoveryResource = new ChartData(obj.netDiscoveryResource);
      this.netDiscoveryResource.yVal[0].color = WgeAnalyticsGreen;
      this.netDiscoveryResource.yVal[1].color = WgeSectorsRed;
    }

    this.netDiscoveryResourceByClassificationArea = null;
    if (obj.netDiscoveryResourceByClassificationArea !== null && obj.netDiscoveryResourceByClassificationArea !== undefined) {
      this.netDiscoveryResourceByClassificationArea = new ChartData(obj.netDiscoveryResourceByClassificationArea);
      assignColours(this.netDiscoveryResourceByClassificationArea.yVal, 'color');
    }

    this.netProspectResource = null;
    if (obj.netProspectResource !== null && obj.netProspectResource !== undefined) {
      this.netProspectResource = new ChartData(obj.netProspectResource);
      assignColours(this.netProspectResource.yVal, 'color');
    }

    this.netProspectResourceByReservoirArea = null;
    if (obj.netProspectResourceByReservoirArea !== null && obj.netProspectResourceByReservoirArea !== undefined) {
      this.netProspectResourceByReservoirArea = new ChartData(obj.netProspectResourceByReservoirArea);
      assignColours(this.netProspectResourceByReservoirArea.yVal, 'color');
    }

    this.prospectsByReservoirArea = null;
    if (obj.prospectsByReservoirArea !== null && obj.prospectsByReservoirArea !== undefined) {
      this.prospectsByReservoirArea = new ChartData(obj.prospectsByReservoirArea);
      assignColours(this.prospectsByReservoirArea.yVal, 'color');
    }

    this.emissionsByField = null;
    if (obj.emissionsByField !== null && obj.emissionsByField !== undefined) {
      this.emissionsByField = new ChartData(obj.emissionsByField);
      assignColours(this.emissionsByField.yVal, 'color');
    }

    this.emissionsByHub = null;
    if (obj.emissionsByHub !== null && obj.emissionsByHub !== undefined) {
      this.emissionsByHub = new ChartData(obj.emissionsByHub);
      assignColours(this.emissionsByHub.yVal, 'color');
    }
  }
}

class CorporateDetail {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('CorporateDetail', 'CorporateDetail'); }

    if (typeof obj.corporateId !== 'number') { showError('CorporateDetail', 'corporateId'); }
    if (typeof obj.corporateName !== 'string') { showError('CorporateDetail', 'corporateName'); }
    if (typeof obj.countryList !== 'string') { showError('CorporateDetail', 'countryList'); }
    if (typeof obj.areaList !== 'string') { showError('CorporateDetail', 'areaList'); }
    if (typeof obj.corporateCategory !== 'string') { showError('CorporateDetail', 'corporateCategory'); }
    if (typeof obj.tierList !== 'string') { showError('CorporateDetail', 'tierList'); }
    if (typeof obj.producingFields !== 'number') { showError('CorporateDetail', 'producingFields'); }
    if (typeof obj.underDevelopmentFields !== 'number') { showError('CorporateDetail', 'underDevelopmentFields'); }
    if (typeof obj.nearTermFields !== 'number') { showError('CorporateDetail', 'nearTermFields'); }
    if (typeof obj.otherFields !== 'number') { showError('CorporateDetail', 'otherFields'); }
    if (typeof obj.percentOperatedFields !== 'number') { showError('CorporateDetail', 'percentOperatedFields'); }
    if (typeof obj.discoveryResourcesMMBOE !== 'number') { showError('CorporateDetail', 'discoveryResourcesMMBOE'); }
    if (!Array.isArray(obj.shapes)) { showError('CorporateDetail', 'shapes'); }

    if (typeof obj.remainingLiquidMmb !== 'number') { showError('CorporateDetail', 'remainingLiquidMmb'); }
    if (typeof obj.remainingGasBcf !== 'number') { showError('CorporateDetail', 'remainingGasBcf'); }
    if (typeof obj.remainingCombinedMmboe !== 'number') { showError('CorporateDetail', 'remainingCombinedMmboe'); }
    if (typeof obj.currentYearLiquidMbod !== 'number') { showError('CorporateDetail', 'currentYearLiquidMbod'); }
    if (typeof obj.currentYearGasMmscfd !== 'number') { showError('CorporateDetail', 'currentYearGasMmscfd'); }
    if (typeof obj.currentYearCombinedMboed !== 'number') { showError('CorporateDetail', 'currentYearCombinedMboed'); }

    if (typeof obj.currentYear !== 'number') { showError('CorporateDetail', 'currentYear'); }
    if (typeof obj.hasFiscal !== 'boolean') { showError('CorporateDetail', 'hasFiscal'); }

    if (obj.cashflow !== null && typeof obj.cashflow !== 'object') { showError('CorporateDetail', 'cashflow'); }
    if (obj.fiscalResources !== null && !Array.isArray(obj.fiscalResources)) { showError('CorporateDetail', 'fiscalResources'); }
    if (!Array.isArray(obj.netFields)) { showError('CorporateDetail', 'netFields'); }
    if (!Array.isArray(obj.netDiscoveries)) { showError('CorporateDetail', 'netDiscoveries'); }
    if (!Array.isArray(obj.netProspects)) { showError('CorporateDetail', 'netProspects'); }
    if (!Array.isArray(obj.completedAndActiveWells)) { showError('CorporateDetail', 'completedAndActiveWells'); }
    if (!Array.isArray(obj.plannedWells)) { showError('CorporateDetail', 'plannedWells'); }
    if (!Array.isArray(obj.excelSummary)) { showError('CorporateDetail', 'excelSummary'); }

    if (typeof obj.chartDataCollection !== 'object') { showError('CorporateDetail', 'chartDataCollection'); }
    if (typeof obj.isActive !== 'boolean') { showError('CorporateDetail', 'isActive'); }
    if (typeof obj.grossAreaSQKM !== 'number') { showError('CorporateDetail', 'grossAreaSQKM'); }
    if (typeof obj.netAreaSQKM !== 'number') { showError('CorporateDetail', 'netAreaSQKM'); }
    if (typeof obj.valuation !== 'object') { showError('CorporateDetail', 'valuation'); }

    this.isActive = obj.isActive;
    this.cashflow = obj.cashflow !== null ? new PortfolioCashflow(obj.cashflow) : null;
    this.fiscalResources = obj.fiscalResources !== null ? obj.fiscalResources.map(obj => new FiscalResource(obj)) : null;
    this.netFields = NetField.FromArray(obj.netFields);
    this.netDiscoveries = NetDiscovery.FromArray(obj.netDiscoveries);
    this.netProspects = NetProspect.FromArray(obj.netProspects);
    this.completedAndActiveWells = CompletedAndActiveWell.FromArray(obj.completedAndActiveWells);
    this.plannedWells = PlannedWell.FromArray(obj.plannedWells);
    this.excelSummary = FasterPortfolioCorporateSummary.FromArray(obj.excelSummary);

    this.chartDataCollection = obj.chartDataCollection !== null ? new CorporateDetailChartData(obj.chartDataCollection) : null;
    this.valuation = new UserValuations(obj.valuation);

    this.corporateId = obj.corporateId;
    this.corporateName = obj.corporateName;
    this.countryList = obj.countryList;
    this.areaList = obj.areaList;
    this.corporateCategory = obj.corporateCategory;
    this.tierList = obj.tierList;
    this.producingFields = obj.producingFields;
    this.underDevelopmentFields = obj.underDevelopmentFields;
    this.nearTermFields = obj.nearTermFields;
    this.otherFields = obj.otherFields;
    this.percentOperatedFields = obj.percentOperatedFields;
    this.discoveryResourcesMMBOE = obj.discoveryResourcesMMBOE;

    this.remainingLiquidMmb = obj.remainingLiquidMmb;
    this.remainingGasBcf = obj.remainingGasBcf;
    this.remainingCombinedMmboe = obj.remainingCombinedMmboe;
    this.currentYearLiquidMbod = obj.currentYearLiquidMbod;
    this.currentYearGasMmscfd = obj.currentYearGasMmscfd;
    this.currentYearCombinedMboed = obj.currentYearCombinedMboed;

    this.currentYear = obj.currentYear;
    this.hasFiscal = obj.hasFiscal;

    this.grossAreaSQKM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.grossAreaSQKM);
    this.netAreaSQKM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netAreaSQKM);

    this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });
  }
}

export { CorporateDetail, CorporateDetailChartData, NetAnnual, NetField, NetDiscovery, NetProspect, CompletedAndActiveWell, PlannedWell };