import { WellResource } from '../../models/well-resource';
import { MapShapeBorder } from '../../shared/scss/app-colors';
import { CircleModel } from '../../shared/helpers/leaflet/leaflet-helper';

class WellDataRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to WellDataRow.ctor');
    }

    if (typeof obj.id !== 'number') {
      throw new Error('Invalid obj.id param supplied to WellDataRow.ctor');
    }
    this.id = obj.id;

    if (typeof obj.name !== 'string') {
      throw new Error('Invalid obj.name param supplied to WellDataRow.ctor');
    }
    this.name = obj.name;

    if (typeof obj.typeId !== 'number') {
      throw new Error('Invalid obj.typeId param supplied to WellDataRow.ctor');
    }
    this.typeId = obj.typeId;

    if (typeof obj.typeName !== 'string') {
      throw new Error('Invalid obj.typeName param supplied to WellDataRow.ctor');
    }
    this.typeName = obj.typeName;

    if (typeof obj.subTypeId !== 'number') {
      throw new Error('Invalid obj.subTypeId param supplied to WellDataRow.ctor');
    }
    this.subTypeId = obj.subTypeId;

    if (typeof obj.subTypeName !== 'string') {
      throw new Error('Invalid obj.subTypeName param supplied to WellDataRow.ctor');
    }
    this.subTypeName = obj.subTypeName;

    if (typeof obj.statusId !== 'number') {
      throw new Error('Invalid obj.statusId param supplied to WellDataRow.ctor');
    }
    this.statusId = obj.statusId;

    if (typeof obj.statusName !== 'string') {
      throw new Error('Invalid obj.statusName param supplied to WellDataRow.ctor');
    }
    this.statusName = obj.statusName;

    if (typeof obj.resultId !== 'number') {
      throw new Error('Invalid obj.resultId param supplied to WellDataRow.ctor');
    }
    this.resultId = obj.resultId;

    if (typeof obj.resultName !== 'string') {
      throw new Error('Invalid obj.resultName param supplied to WellDataRow.ctor');
    }
    this.resultName = obj.resultName;

    if (obj.startDate == null) {
      this.startDate = null;
    } else if (typeof obj.startDate !== 'string') {
      throw new Error('Invalid "startDate" supplied to "WellDataRow.ctor"');
    }
    else {
      this.startDate = new Date(obj.startDate);
    }

    if (obj.endDate == null) {
      this.endDate = null;
    } else if (typeof obj.endDate !== 'string') {
      throw new Error('Invalid "endDate" supplied to "WellDataRow.ctor"');
    }
    else {
      this.endDate = new Date(obj.endDate);
    }

    if (typeof obj.primaryObjectiveId !== 'number') {
      throw new Error('Invalid obj.primaryObjectiveId param supplied to WellDataRow.ctor');
    }
    this.primaryObjectiveId = obj.primaryObjectiveId;

    if (typeof obj.primaryObjectiveName !== 'string') {
      throw new Error('Invalid obj.primaryObjectiveName param supplied to WellDataRow.ctor');
    }
    this.primaryObjectiveName = obj.primaryObjectiveName;

    if (typeof obj.countryId !== 'number') {
      throw new Error('Invalid obj.countryId param supplied to WellDataRow.ctor');
    }
    this.countryId = obj.countryId;

    if (typeof obj.countryName !== 'string') {
      throw new Error('Invalid obj.countryName param supplied to WellDataRow.ctor');
    }
    this.countryName = obj.countryName;

    if (typeof obj.areaId !== 'number') {
      throw new Error('Invalid obj.areaId param supplied to WellDataRow.ctor');
    }
    this.areaId = obj.areaId;

    if (typeof obj.areaName !== 'string') {
      throw new Error('Invalid obj.areaName param supplied to WellDataRow.ctor');
    }
    this.areaName = obj.areaName;

    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') {
      throw new Error('Invalid obj.operatorId param supplied to WellDataRow.ctor');
    }
    this.operatorId = obj.operatorId;

    if (obj.licence !== null && typeof obj.licence !== 'string') {
      throw new Error('Invalid "obj.licence" param supplied to "WellDataRow.ctor"');
    }
    this.licence = obj.licence;

    if (obj.licenceId !== null && typeof obj.licenceId !== 'number') {
      throw new Error('Invalid "obj.licenceId" param supplied to "WellDataRow.ctor"');
    }
    this.licenceId = obj.licenceId;

    if (typeof obj.operatorName !== 'string') {
      throw new Error('Invalid "obj.operatorName" param supplied to "WellDataRow.ctor"');
    }
    this.operatorName = obj.operatorName;

    if (!Array.isArray(obj.resources))
      throw new Error('Invalid "obj.resourcse" param supplied to "WellDataRow.ctor"');
    this.resources = WellResource.FromArray(obj.resources);
    this.resourceString = this.resources === null ? null : this.resources.map(x => x.resourceName).join('; ');

    if (typeof obj.shape !== 'object') {
      throw new Error('Invalid "obj.shape" param supplied to "WellDataRow.ctor"');
    }
    this.shape = new CircleModel(obj.shape.x, obj.shape.y, { borderColor: MapShapeBorder, fillColor: 'transparent' });

    if (typeof obj.reportId !== 'number') {
      throw new Error('Invalid obj.reportId param supplied to WellDataRow.ctor');
    }
    this.reportId = obj.reportId;

    if (obj.link !== null && typeof obj.link !== 'string') {
      throw new Error('Invalid obj.link param supplied to WellDataRow.ctor');
    }
    this.link = obj.link;

    if (obj.expectedStartYear !== null && typeof obj.expectedStartYear !== 'number') {
      throw new Error('Invalid "obj.expectedStartYear" param supplied to "WellDataRow.ctor"');
    }
    this.expectedStartYear = obj.expectedStartYear === 0 ? null : obj.expectedStartYear;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "WellDataRow.FromArray"');
    }

    const result = array.map(obj => new WellDataRow(obj));
    return result;
  }
}

export { WellDataRow };