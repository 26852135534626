import moment from 'moment';
import { DisplayRig } from './gmatrix3-extension-display-rig';

class DisplayWell {
  constructor(wellObj) {
    if (typeof wellObj !== 'object') { throw new Error('Invalid wellObj param supplied to Well.ctor'); }
    if (typeof wellObj.wellId !== 'number') { throw new Error('Invalid wellObj.wellId param supplied to Well.ctor'); }
    if (typeof wellObj.wellName !== 'string') { throw new Error('Invalid wellObj.wellName param supplied to Well.ctor'); }
    if (typeof wellObj.class !== 'string') { throw new Error('Invalid wellObj.class param supplied to Well.ctor'); }
    let startDateMoment = null;
    if (wellObj.startDate !== null) {
      if (typeof wellObj.startDate !== 'string') { throw new Error('Invalid wellObj.startDate param supplied to Well.ctor'); }
      startDateMoment = moment(wellObj.startDate);
      if (!startDateMoment.isValid()) { throw new Error('Invalid wellObj.startDate param supplied to Well.ctor'); }
    }
    let tdDateMoment = null;
    if (wellObj.tdDate !== null) {
      if (typeof wellObj.tdDate !== 'string') { throw new Error('Invalid wellObj.tdDate param supplied to Well.ctor'); }
      tdDateMoment = moment(wellObj.tdDate);
      if (!tdDateMoment.isValid()) { throw new Error('Invalid wellObj.tdDate param supplied to Well.ctor'); }
    }
    let endDateMoment = null;
    if (wellObj.endDate !== null) {
      if (typeof wellObj.endDate !== 'string') { throw new Error('Invalid wellObj.endDate param supplied to Well.ctor'); }
      endDateMoment = moment(wellObj.endDate);
      if (!endDateMoment.isValid()) { throw new Error('Invalid wellObj.endDate param supplied to Well.ctor'); }
    }
    if (typeof wellObj.status !== 'string') { throw new Error('Invalid wellObj.status param supplied to Well.ctor'); }
    if (typeof wellObj.successState !== 'boolean') { throw new Error('Invalid wellObj.successState param supplied to Well.ctor'); }
    if (typeof wellObj.licenceNumber !== 'string') { throw new Error('Invalid wellObj.licenceNumber param supplied to Well.ctor'); }
    if (typeof wellObj.blockNo !== 'string') { throw new Error('Invalid wellObj.blockNo param supplied to Well.ctor'); }
    if (typeof wellObj.primaryObjective !== 'string') { throw new Error('Invalid wellObj.primaryObjective param supplied to Well.ctor'); }
    if (typeof wellObj.originalOperatorName !== 'string') { throw new Error('Invalid wellObj.originalOperatorName param supplied to Well.ctor'); }
    if (wellObj.originalOperatorId !== null && typeof wellObj.originalOperatorId !== 'number') { throw new Error('Invalid wellObj.originalOperatorId param supplied to Well.ctor'); }
    if (wellObj.totalDepthFt !== null && typeof wellObj.totalDepthFt !== 'number') { throw new Error('Invalid wellObj.totalDepthFt param supplied to Well.ctor'); }
    if (wellObj.waterDepthFt !== null && typeof wellObj.waterDepthFt !== 'number') { throw new Error('Invalid wellObj.waterDepthFt param supplied to Well.ctor'); }
    if (wellObj.estimatedWellCost !== null && typeof wellObj.estimatedWellCost !== 'number') { throw new Error('Invalid wellObj.estimatedWellCost param supplied to Well.ctor'); }
    if (typeof wellObj.rig !== 'object') { throw new Error('Invalid rig param supplied to Well.ctor'); }
    if (typeof wellObj.isDiscoveryWell !== 'boolean') { throw new Error('Invalid wellObj.isDiscoveryWell param supplied to Well.ctor'); }
    if (typeof wellObj.reportId !== 'number') { throw new Error('Invalid wellObj.reportId param supplied to Well.ctor'); }

    this.wellId = wellObj.wellId;
    this.wellName = wellObj.wellName;
    this.class = wellObj.class;
    this.startDate = startDateMoment === null ? null : startDateMoment.format('DD/MM/YYYY');
    this.tdDate = tdDateMoment === null ? null : tdDateMoment.format('DD/MM/YYYY');
    this.endDate = endDateMoment === null ? null : endDateMoment.format('DD/MM/YYYY');
    this.status = wellObj.status;
    this.hydrocarbonPresent = wellObj.successState ? 'Hydrocarbon Present' : 'Hydrocarbon Not Present';
    this.licenceNumber = wellObj.licenceNumber;
    this.blockNo = wellObj.blockNo;
    this.primaryObjective = wellObj.primaryObjective;
    this.originalOperatorName = wellObj.originalOperatorName;
    this.originalOperatorId = wellObj.originalOperatorId;
    this.totalDepthFt = wellObj.totalDepthFt;
    this.waterDepthFt = wellObj.waterDepthFt;
    this.estimatedWellCost = wellObj.estimatedWellCost;
    this.rig = new DisplayRig(wellObj.rig);
    this.isDiscoveryWell = wellObj.isDiscoveryWell;
    this.discoveryWellStatus = wellObj.isDiscoveryWell ? 'Yes' : '';
    this.reportId = wellObj.reportId;
  }
}

export { DisplayWell }
