class ExcelDataRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to ExcelDataRow.ctor');
    }

    if (typeof obj.name !== 'string') {
      throw new Error('Invalid obj.name param supplied to ExcelDataRow.ctor');
    }
    this.name = obj.name;

    if (typeof obj.id !== 'number') {
      throw new Error('Invalid obj.id param supplied to ExcelDataRow.ctor');
    }
    this.id = obj.id;

    if (typeof obj.typeName !== 'string') {
      throw new Error('Invalid obj.typeName param supplied to ExcelDataRow.ctor');
    }
    this.typeName = obj.typeName;

    if (typeof obj.subTypeName !== 'string') {
      throw new Error('Invalid obj.subTypeName param supplied to ExcelDataRow.ctor');
    }
    this.subTypeName = obj.subTypeName;

    if (typeof obj.statusName !== 'string') {
      throw new Error('Invalid obj.statusName param supplied to ExcelDataRow.ctor');
    }
    this.statusName = obj.statusName;

    if (typeof obj.resultName !== 'string') {
      throw new Error('Invalid obj.resultName param supplied to ExcelDataRow.ctor');
    }
    this.resultName = obj.resultName;

    if (obj.startDate == null) {
      this.startDate = null;
    } else if (typeof obj.startDate !== 'string') {
      throw new Error('Invalid "startDate" supplied to "ExcelDataRow.ctor"');
    }
    else {
      this.startDate = new Date(obj.startDate);
    }

    if (obj.endDate == null) {
      this.endDate = null;
    } else if (typeof obj.endDate !== 'string') {
      throw new Error('Invalid "endDate" supplied to "ExcelDataRow.ctor"');
    }
    else {
      this.endDate = new Date(obj.endDate);
    }

    if (typeof obj.primaryObjectiveName !== 'string') {
      throw new Error('Invalid obj.primaryObjectiveName param supplied to ExcelDataRow.ctor');
    }
    this.primaryObjectiveName = obj.primaryObjectiveName;

    if (typeof obj.countryName !== 'string') {
      throw new Error('Invalid obj.countryName param supplied to ExcelDataRow.ctor');
    }
    this.countryName = obj.countryName;

    if (typeof obj.areaName !== 'string') {
      throw new Error('Invalid obj.areaName param supplied to ExcelDataRow.ctor');
    }
    this.areaName = obj.areaName;

    if (obj.licence !== null && typeof obj.licence !== 'string') {
      throw new Error('Invalid "obj.licence" param supplied to "ExcelDataRow.ctor"');
    }
    this.licence = obj.licence;

    if (obj.licenceRound !== null && typeof obj.licenceRound !== 'string') {
      throw new Error('Invalid "obj.licenceRound" param supplied to "ExcelDataRow.ctor"');
    }
    this.licenceRound = obj.licenceRound;

    if (typeof obj.operatorName !== 'string') {
      throw new Error('Invalid "obj.operatorName" param supplied to "ExcelDataRow.ctor"');
    }
    this.operatorName = obj.operatorName;

    if (typeof obj.resource !== 'string') {
      throw new Error('Invalid "obj.resource" param supplied to "ExcelDataRow.ctor"');
    }
    this.resource = obj.resource;

    if (typeof obj.rigName !== 'string') {
      throw new Error('Invalid "obj.rigName" param supplied to "ExcelDataRow.ctor"');
    }
    this.rigName = obj.rigName;

    if (typeof obj.waterDepthFt !== 'number') {
      throw new Error('Invalid obj.waterDepthFt param supplied to ExcelDataRow.ctor');
    }
    this.waterDepthFt = obj.waterDepthFt;

    if (typeof obj.totalDepthFt !== 'number') {
      throw new Error('Invalid obj.totalDepthFt param supplied to ExcelDataRow.ctor');
    }
    this.totalDepthFt = obj.totalDepthFt;

    if (typeof obj.approvedForDrilling !== 'string') {
      throw new Error('Invalid obj.approvedForDrilling param supplied to ExcelDataRow.ctor');
    }
    this.approvedForDrilling = obj.approvedForDrilling;

    if (typeof obj.commitment !== 'string') {
      throw new Error('Invalid obj.commitment param supplied to ExcelDataRow.ctor');
    }
    this.commitment = obj.commitment;

    if (typeof obj.estimatedTiming !== 'string') {
      throw new Error('Invalid obj.estimatedTiming param supplied to ExcelDataRow.ctor');
    }
    this.estimatedTiming = obj.estimatedTiming;

    if (typeof obj.duration !== 'number') {
      throw new Error('Invalid obj.duration param supplied to ExcelDataRow.ctor');
    }
    this.duration = obj.duration;

    if (obj.estimatedWellCost !== null && typeof obj.estimatedWellCost !== 'number') {
      throw new Error('Invalid obj.estimatedWellCost param supplied to ExcelDataRow.ctor');
    }
    this.estimatedWellCost = obj.estimatedWellCost;

    if (obj.link !== null && typeof obj.link !== 'string') {
      throw new Error('Invalid obj.link param supplied to ExcelDataRow.ctor');
    }
    this.link = obj.link;

    if (obj.isFuture !== null && typeof obj.isFuture !== 'boolean') {
      throw new Error('Invalid obj.isFuture param supplied to ExcelDataRow.ctor');
    }
    this.isFuture = obj.isFuture;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "ExcelDataRow.FromArray"');
    }

    const result = array.map(obj => new ExcelDataRow(obj));
    return result;
  }
}

export { ExcelDataRow };