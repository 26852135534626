import moment from 'moment';
class ResourceText {
  constructor(textObj) {
    if (textObj === undefined || textObj === null) return null; 
    if (typeof textObj !== 'object') {
      throw new Error('Invalid textObj param supplied to ResourceText.ResourceText');
    }
    if (typeof textObj.resourceTextId !== 'number') {
      throw new Error('Invalid textObj.resourceTextId param supplied to ResourceText.ctor');
    }
    if (typeof textObj.textCategoryId !== 'number') {
      throw new Error('Invalid textObj.textCategoryId param supplied to ResourceText.ctor');
    }
    if (typeof textObj.textValue !== 'string') {
      throw new Error('Invalid textObj.textValue param supplied to ResourceText.ctor');
    }
    if (typeof textObj.dateAdded !== 'string') {
      throw new Error('Invalid dateAdded.textValue param supplied to ResourceText.ctor');
    }
    if (typeof textObj.dateAmended !== 'string') {
      throw new Error('Invalid dateAdded.dateAmended param supplied to ResourceText.ctor');
    }    

    this.resourceTextId = textObj.resourceTextId;
    this.textCategoryId = textObj.textCategoryId;
    this.textValue = textObj.textValue;
    this.dateAdded = moment(textObj.dateAdded).format('DD/MM/YYYY');
    this.dateAmended = moment(textObj.dateAmended).format('DD MMMM YYYY');
 
  }
}
export { ResourceText };