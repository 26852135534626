import React from 'react';
import PropTypes from 'prop-types';

import { valueTypes } from '../../../shared/helpers/value-handler';
import { CorporateDetail } from '../../../models/corporate-detail';
import GenericChart, { ChartType } from '../../../shared/components/generic-chart/generic-chart';
import { createHeader, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedCorporateCostsComponent = (props) => {
  const { corporate } = props;

  const chartStyle = { height: '350px' };

  const capex = corporate.chartDataCollection.capexByField !== null ? (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={corporate.chartDataCollection.capexByField}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.usdMM)}
          showYAxisGridLines={true}
        />
      </div>
    </div>
  ) : <div>No further Capex expected</div>;

  const abex = corporate.chartDataCollection.abexByField !== null ? (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={corporate.chartDataCollection.abexByField}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.usdMM)}
          showYAxisGridLines={true}
        />
      </div>
    </div>
  ) : <div>No further Abex expected</div>;

  const opex = corporate.chartDataCollection.opexByField !== null ? (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={corporate.chartDataCollection.opexByField}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.usdMM)}
          showYAxisGridLines={true}
        />
      </div>
    </div>
  ) : <div>No further Opex expected</div>;

  const opexBOE = corporate.chartDataCollection.opexPerBOE !== null ? (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={corporate.chartDataCollection.opexPerBOE}
          chartType={ChartType.Bar}
          isXStacked={false}
          isYStacked={false}
          showYAxisLabel={true}
          yAxisLabel={'Net ' + valueTypes.UnitString(valueTypes.usdBOE)}
          showYAxisGridLines={true}
        />
      </div>
    </div>
  ) : <div>No further Opex expected</div>;

  return (
    <div ref={props.innerRef}>
      {createHeader('Net costs')}

      {createSection([
        { content: capex, sectionType: sectionTypes.section50, title: 'Net Capex' },
        { content: abex, sectionType: sectionTypes.section50, title: 'Net Abex' }
      ])}

      {createSection([
        { content: opex, sectionType: sectionTypes.section50, title: 'Net Opex' },
        { content: opexBOE, sectionType: sectionTypes.section50, title: 'Net Opex/boe' }
      ])}
    </div>
  );
};

AdvancedCorporateCostsComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  corporate: PropTypes.instanceOf(CorporateDetail).isRequired
};

export default AdvancedCorporateCostsComponent;