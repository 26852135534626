import L from 'leaflet';

import { CircleMarkerModel } from '../models/circle-marker-model';
import { removeLayersOfType } from '../helpers/layer-helper';

const createCircleMarkerLayer = (circleMarkerModel) => {
  if (!(circleMarkerModel instanceof CircleMarkerModel)) {
    throw new Error('Invalid \'circleMarkerModel\' supplied to \'createCircleMarker\'');
  }

  const latLng = L.latLng(circleMarkerModel.lat, circleMarkerModel.lng);
  const options = {
    radius: circleMarkerModel.options.radius,
    stroke: circleMarkerModel.options.border,
    color: circleMarkerModel.options.borderColor,
    opacity: circleMarkerModel.options.borderOpacity,
    weight: circleMarkerModel.options.borderWidth,
    fill: circleMarkerModel.options.fill,
    fillColor: circleMarkerModel.options.fillColor,
    fillOpacity: circleMarkerModel.options.fillOpacity,
    interactive: circleMarkerModel.options.popup !== undefined
  };
  const result = L.circleMarker(latLng, options);
  result._circleMarkerModel = circleMarkerModel;
  if (circleMarkerModel.options.popup !== undefined) {
    result.bindPopup(circleMarkerModel.options.popup);
  }
  return result;
};

const removeCircleMarkerLayers = (leafletMap) => removeLayersOfType(leafletMap, L.CircleMarker);

export { createCircleMarkerLayer, removeCircleMarkerLayers, CircleMarkerModel };