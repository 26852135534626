import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import './splitter-panel-component.scss'
import { appendClassNames } from '../../helpers/common';

class SplitterPanelComponent extends Component {
  constructor(props) {
    super(props);

    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onSecondaryPaneSizeChange = this.onSecondaryPaneSizeChange.bind(this);

    this.ref = null;
    this.dragging = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.secondarySize !== this.props.secondarySize && this.ref !== undefined && this.ref !== null && this.ref.state.secondaryPaneSize !== this.props.secondarySize) {
      this.ref.setState({ secondaryPaneSize: this.props.secondarySize });
    }
  }

  onDragStart = () => {
    this.dragging = true;
    if (typeof this.props.onDragStart === 'function') {
      this.props.onDragStart();
    }
  }

  onDragEnd = () => {
    this.dragging = false;
    if (typeof this.props.onDragEnd === 'function') {
      this.props.onDragEnd();
    }
    this.onSecondaryPaneSizeChange(this.ref.state.secondaryPaneSize);
  }

  onSecondaryPaneSizeChange = (size) => {
    if (typeof this.props.onSecondaryPaneSizeChange === 'function') {
      this.props.onSecondaryPaneSizeChange(size, this.dragging);
    }
  }

  render() {
    const { children, visiblePanes, animateSizeChange } = this.props;
    const className = appendClassNames('splitter-panel-component', this.props.className) +
      (visiblePanes === 'both' ? '' : ' ' + visiblePanes + '-pane-only') +
      (animateSizeChange ? ' animate-size-change' : '');

    return (
      <SplitterLayout
        ref={ref => this.ref = ref}
        customClassName={className}
        vertical={!this.props.vertical}
        percentage={this.props.percentage}
        primaryIndex={this.props.primaryIndex}
        primaryMinSize={this.props.primaryMinSize}
        secondaryInitialSize={this.props.secondarySize}
        secondaryMinSize={this.props.secondaryMinSize}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        onSecondaryPaneSizeChange={this.onSecondaryPaneSizeChange}
      >
        {Array.isArray(children) && children.length >= 0 ? <div>{children[0]}</div> : null}
        {Array.isArray(children) && children.length >= 1 ? <div>{children[1]}</div> : null}
      </SplitterLayout>
    );
  }
}

SplitterPanelComponent.propTypes = {
  animateSizeChange: PropTypes.bool.isRequired,
  className: PropTypes.string,
  percentage: PropTypes.bool,
  primaryIndex: PropTypes.oneOf([0, 1]).isRequired,
  primaryMinSize: PropTypes.number,
  secondarySize: PropTypes.number.isRequired,
  secondaryMinSize: PropTypes.number,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onSecondaryPaneSizeChange: PropTypes.func,
  vertical: PropTypes.bool,
  visiblePanes: PropTypes.oneOf(['both', 'primary', 'secondary']).isRequired
};

SplitterPanelComponent.defaultProps = {
  animateSizeChange: false,
  percentage: true,
  primaryIndex: 0,
  secondarySize: 50,
  visiblePanes: 'both'
};

export default SplitterPanelComponent;
