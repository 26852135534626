class ReportsGridRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" supplied to "ReportsGridRow.ctor"');
    }

    if (typeof obj.id !== 'number') {
      throw new Error('Invalid "id" supplied to "ReportsGridRow.ctor"');
    }
    this.id = obj.id;

    if (typeof obj.name !== 'string') {
      throw new Error('Invalid "name" supplied to "ReportsGridRow.ctor"');
    }
    this.name = obj.name;

    if (typeof obj.countryName !== 'string') {
      throw new Error('Invalid "countryName" supplied to "ReportsGridRow.ctor"');
    }
    this.countryName = obj.countryName;

    if (typeof obj.category !== 'string') {
      throw new Error('Invalid "category" supplied to "ReportsGridRow.ctor"');
    }
    this.category = obj.category;

    if (typeof obj.fileName !== 'string') {
      throw new Error('Invalid "fileName" supplied to "ReportsGridRow.ctor"');
    }
    this.fileName = obj.fileName;

    if (typeof obj.categoryID !== 'number') {
      throw new Error('Invalid "categoryID" supplied to "ReportsGridRow.ctor"');
    }
    this.categoryID = obj.categoryID;

    if (typeof obj.isSubscribed !== 'boolean') {
      throw new Error('Invalid "isSubscribed" supplied to "ReportsGridRow.ctor"');
    }
    this.isSubscribed = obj.isSubscribed;

    if (obj.recoDocs !== null && typeof obj.recoDocs !== 'string') {
      throw new Error('Invalid "recoDocs" supplied to "ReportsGridRow.ctor"');
    }
    this.recoDocs = obj.recoDocs;

    if (obj.assocDocs !== null && typeof obj.assocDocs !== 'string') {
      throw new Error('Invalid "assocDocs" supplied to "ReportsGridRow.ctor"');
    }
    this.assocDocs = obj.assocDocs;

    if (typeof obj.summaryText !== 'string') {
      throw new Error('Invalid "summaryText" supplied to "ReportsGridRow.ctor"');
    }
    this.summaryText = obj.summaryText;

    if (obj.imageFileName !== null && typeof obj.imageFileName !== 'string') {
      throw new Error('Invalid "imageFileName" supplied to "ReportsGridRow.ctor"');
    }
    this.imageFileName = obj.imageFileName;

    if (typeof obj.authorID !== 'number') {
      throw new Error('Invalid "authorID" supplied to "ReportsGridRow.ctor"');
    }
    this.authorID = obj.authorID;

    if (typeof obj.countryID !== 'number') {
      throw new Error('Invalid "countryID" supplied to "ReportsGridRow.ctor"');
    }
    this.countryID = obj.countryID;

    if (typeof obj.totalReports !== 'number') {
      throw new Error('Invalid "totalReports" supplied to "ReportsGridRow.ctor"');
    }
    this.totalReports = obj.totalReports;

    if (typeof obj.associatedFileID !== 'number') {
      throw new Error('Invalid "associatedFileID" supplied to "ReportsGridRow.ctor"');
    }
    this.associatedFileID = obj.associatedFileID;

    if (typeof obj.associatedFileName !== 'string') {
      throw new Error('Invalid "associatedFileName" supplied to "ReportsGridRow.ctor"');
    }
    this.associatedFileName = obj.associatedFileName;

    if (typeof obj.associatedFileDesc !== 'string') {
      throw new Error('Invalid "associatedFileDesc" supplied to "ReportsGridRow.ctor"');
    }
    this.associatedFileDesc = obj.associatedFileDesc;

    if (typeof obj.linkOrder !== 'number') {
      throw new Error('Invalid "linkOrder" supplied to "ReportsGridRow.ctor"');
    }
    this.linkOrder = obj.linkOrder;

    if (typeof obj.analystContainerID !== 'number') {
      throw new Error('Invalid "analystContainerID" supplied to "ReportsGridRow.ctor"');
    }
    this.analystContainerID = obj.analystContainerID;

    if (typeof obj.overviewContainerID !== 'number') {
      throw new Error('Invalid "overviewContainerID" supplied to "ReportsGridRow.ctor"');
    }
    this.overviewContainerID = obj.overviewContainerID;

    if (typeof obj.quickBoardGroupID !== 'number') {
      throw new Error('Invalid "quickBoardGroupID" supplied to "ReportsGridRow.ctor"');
    }
    this.quickBoardGroupID = obj.quickBoardGroupID;

    if (obj.quickBoardFilterString !== null && typeof obj.quickBoardFilterString !== 'string') {
      throw new Error('Invalid "quickBoardFilterString" supplied to "ReportsGridRow.ctor"');
    }
    this.quickBoardFilterString = obj.quickBoardFilterString;

    if (typeof obj.reportContainerID !== 'number') {
      throw new Error('Invalid "reportContainerID" supplied to "ReportsGridRow.ctor"');
    }
    this.reportContainerID = obj.reportContainerID;

    if (typeof obj.isActive !== 'boolean') {
      throw new Error('Invalid "isActive" supplied to "ReportsGridRow.ctor"');
    }
    this.isActive = obj.isActive;

    if (typeof obj.isAnalyticSubscribed !== 'number') {
      throw new Error('Invalid "isAnalyticSubscribed" supplied to "ReportsGridRow.ctor"');
    }
    this.isAnalyticSubscribed = obj.isAnalyticSubscribed;

    if (typeof obj.publishDateDisplay !== 'string') {
      throw new Error('Invalid "publishDateDisplay" supplied to "ReportsGridRow.ctor"');
    }
    this.publishDateDisplay = obj.publishDateDisplay;

    if (typeof obj.instances !== 'number') {
      throw new Error('Invalid "instances" supplied to "ReportsGridRow.ctor"');
    }
    this.instances = obj.instances;

    if (typeof obj.trial !== 'boolean') {
      throw new Error('Invalid "trial" supplied to "ReportsGridRow.ctor"');
    }
    this.trial = obj.trial;

    if (typeof obj.link !== 'string') {
      throw new Error('Invalid "link" supplied to "ReportsGridRow.ctor"');
    }
    this.link = obj.link;

    if (typeof obj.wellIds !== 'object') {
      throw new Error('Invalid "wellId" supplied to "ReportsGridRow.ctor"');
    }
    this.wellIds = obj.wellIds;

  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "ReportsGridRow.FromArray"');
    }

    const result = array.map(obj => new ReportsGridRow(obj));
    return result;
  }
}

export { ReportsGridRow };