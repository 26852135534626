import { showError } from '../../helpers/common';
import { DisplayParticipant } from './gmatrix3-display-participant';

class NonProducingParticipant extends DisplayParticipant {
  constructor(obj) {
    super(obj);
    if (typeof obj.netLiquidMmb !== 'number') { showError('NonProducingParticipant', 'netLiquidMmb'); }
    if (typeof obj.netGasBcf !== 'number') { showError('NonProducingParticipant', 'netGasBcf'); }
    if (typeof obj.netGasMmboe !== 'number') { showError('NonProducingParticipant', 'netGasMmboe'); }    
    if (typeof obj.netReservesMmboe !== 'number') { showError('NonProducingParticipant', 'netReservesMmboe'); }
           
    this.netLiquidMmb = obj.netLiquidMmb;
    this.netGasBcf = obj.netGasBcf;
    this.netGasMmboe = obj.netGasMmboe;
    this.netReservesMmboe = obj.netReservesMmboe;
  }
}

export { NonProducingParticipant }