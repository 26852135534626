import React from 'react';
import ReactDOM from 'react-dom';
//import ReactDOM from 'react-dom/client'; //THIS IS FOR MOVING TO REACT 18 - ReactDOM>render is deprecated
import { BrowserRouter } from 'react-router-dom';
import AppComponent from './app/app-component';
//import registerServiceWorker from './registerServiceWorker';

import { unregister } from './registerServiceWorker';
unregister();

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const version = { major: 5, minor: 5, build: 14 };
document.title = 'Atlas ' + version.major + '.' + version.minor + '.' + version.build;

const originalUrl = new URL(window.location.href);
//need to discuss this as the new report id encrypter could put // in the encrypted string
//const originalUrl = new URL(window.location.href.replace(/[//]/g, 'doubleslash').replace(/doubleslashdoubleslash/g, 'doubleslash').replace(/doubleslash/g, '/').replace("https:/", "https://").replace("http:/", "http://"));
window.history.replaceState({}, document.title, originalUrl.origin); // This is to remove the querystring from the url

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <AppComponent version={version} originalUrl={originalUrl} />
  </BrowserRouter>,
  rootElement);

//THIS IS FOR MOVING TO REACT 18 - ReactDOM>render is deprecated
//const root = ReactDOM.createRoot(document.getElementById("root"));
//root.render(
//  <BrowserRouter basename={baseUrl}>
//    <AppComponent version={version} originalUrl={originalUrl} />
//  </BrowserRouter>
//);

//registerServiceWorker();
