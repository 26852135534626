import { showError } from '../../helpers/common';

class Participant {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('Participant', 'Participant'); }
    if (typeof obj.corporateId !== 'number') { showError('Participant', 'corporateId'); }
    if (typeof obj.corporateName !== 'string') { showError('Participant', 'corporateName'); }
    if (typeof obj.equity !== 'number') { showError('Participant', 'equity'); }
    if (typeof obj.operator !== 'boolean') { showError('Participant', 'operator'); }
    if (typeof obj.core !== 'boolean') { showError('Participant', 'core'); }
    if (typeof obj.equityMultiplier !== 'number') { showError('Participant', 'equityMultiplier'); }

    this.corporateId = obj.corporateId;
    this.corporateName = obj.corporateName;
    this.equity = obj.equity;
    this.operator = obj.operator;
    this.core = obj.core;
    this.equityMultiplier = obj.equityMultiplier;
  }
}

export { Participant }