import React from 'react';
import PropTypes from 'prop-types'
import { Button } from 'reactstrap';

import './reports-view-component.scss';
import { ReportsFilter } from './reports-filter';

import { appViews } from '../../app/app-views';
import { AppSetState } from '../../app/app-set-state';
import { appIcons, appIconSizes } from '../../app/app-icons';

import { TrackingType } from '../../shared/helpers/enums';
import ReportsGridComponent, { ReportsGridRow, ReportsGridExcelExport } from '../../components/reports-grid/reports-grid-component';

class ReportViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { dataRows: [] };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.update();
    }
  }

  getTrackingType() {
    const { filter } = this.props;

    let result = TrackingType.PageView;

    if (filter.isSet) {
      if (filter.advancedSearch) {
        result = TrackingType.FilterSearch;
      } else {
        result = TrackingType.TextSearch;
        if (filter.subscriptionIdsIsSet || filter.categoryIdsIsSet || filter.dateFromIsSet || filter.dateToIsSet) {
          result = TrackingType.TextandFilterSearch;
        }
      }
    }

    return result;
  }

  update = () => {
    const url = 'api/reports/load';
    const apiParam = {
      filters: this.props.filter.toApiParam(),
      EmailReportEncryption: '',
      EmailReportExistingID: '',
      pageSize: this.props.showReportsFull ? 99999 : 10,
      pageIndex: 0,
      page: this.props.appSetState.view.current,
      tracktype: this.getTrackingType()
    };

    const onSuccess = (result) => {
      this.setState({
        dataRows: result.dataRows.map(obj => new ReportsGridRow(obj))
      });
    };
    this.props.appSetState.api.post(url, apiParam, onSuccess, undefined, true);
  }

  toggleShowFilters = () => {
    this.props.setShowFilters(!this.props.showFilters);
  }

  exportToExcel = () => {
    new ReportsGridExcelExport().export(this.props.appSetState, this.state.dataRows);
  }

  render() {
    const { dataRows } = this.state;

    return (
      <div className={'reports-view-component ma-view ' + (this.props.appSetState.misc.showLabels ? 'show-labels' : 'hide-labels')}>
        <div className="toolbar-div">
          <span className="header-span">{appViews.getDisplayName(appViews.Reports)}<small> ({dataRows.length})</small></span>
          <Button className="show-filters-btn" onClick={this.toggleShowFilters}>
            <span className="toolbar-button-icon">{appIcons.getIcon(appIcons.Filter, appIconSizes.sm)}</span>
            <span className="toolbar-button-label">{this.props.showFilters ? 'Hide Filters' : 'Show Filters'}</span>
          </Button>
          <Button className="excel-export-btn" onClick={this.exportToExcel}>
            <span className="toolbar-button-icon">{appIcons.getIcon(appIcons.FileExcel, appIconSizes.sm)}</span>
            <span className="toolbar-button-label">Export to Excel</span>
          </Button>
        </div>
        <div className="content-div">
          <ReportsGridComponent
            appSetState={this.props.appSetState}
            dataRows={dataRows}
            showInstancesCol={this.props.filter.isShowInstances}
            showReportsFull={this.props.showReportsFull}
          />
        </div>
      </div>
    );
  }
}

ReportViewComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  filter: PropTypes.instanceOf(ReportsFilter).isRequired,
  showFilters: PropTypes.bool.isRequired,
  setShowFilters: PropTypes.func.isRequired,
  showHeader: PropTypes.bool.isRequired,
  showReportsFull: PropTypes.bool.isRequired
};

export default ReportViewComponent;