import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';

import './base-view-component.scss';
import { BaseFilter } from './base-filter';
import ToolbarComponent from './toolbar-component';
import { MapBookmarksMenu } from './map-bookmarks-menu-control';

import { appViews } from '../../app/app-views';
import { AppSetState } from '../../app/app-set-state';
import { appendClassNames } from '../../shared/helpers/common';
import { MessageBoxIcon } from '../../shared/components/message-box/message-box-classes';
import SplitterPanelComponent from '../../shared/components/splitter-panel/splitter-panel-component';
import { LeafletHelper, ContextLayerModel, SearchResultModel, searchResultType } from '../../shared/helpers/leaflet/leaflet-helper';

class BaseViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.ignoreMapMove = true; //HACK: ignore the first map move
    this.mainPaneRef = null;
    this.dataPaneRef = null;
    this.mapMoveTimer = null;
    this.mapBookmarksMenu = null;
    this.shapesLayer = L.featureGroup();
    this.state = {
      secondarySize: 60,
      showOverview: typeof this.props.settings.showOverview === 'boolean' ? this.props.settings.showOverview : false,
      showComparison: typeof this.props.settings.showComparison === 'boolean' ? this.props.settings.showComparison : false,
      showGrid: typeof this.props.settings.showGrid === 'boolean' ? this.props.settings.showGrid : true,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResize);
    document.addEventListener('keyup', this.onKeyUp, false);
    this.props.appSetState.appValuation.valuationHasChanged = false;
  }

  componentWillUnmount() {
    clearTimeout(this.mapMoveTimer);
    window.removeEventListener('resize', this.windowResize);
    document.removeEventListener('keyup', this.onKeyUp);
  }

  componentDidUpdate() {
    const { leafletHelper, contextLayers, shapes, filter } = this.props;

    if (leafletHelper instanceof LeafletHelper && contextLayers.length > 0) {
      if (!leafletHelper.initialized) {
        leafletHelper.initialize(contextLayers);

        if (filter.mapBoundsIsSet) {
          leafletHelper.leafletMap.fitBounds(filter.mapBounds);
        }
        leafletHelper.leafletMap.on('moveend', this.onMapMove, this);

        leafletHelper.controls.layers.updateDownloadShapeFileButtons(this.onDownloadShapeFileClick);

        leafletHelper.controls.search.onTextSearch = this.onTextSearch;
        leafletHelper.controls.searchButtons.pointSelect.onComplete = this.onPointSelect;
        leafletHelper.controls.searchButtons.boxSelect.onComplete = this.onBoxSelect;
        leafletHelper.controls.searchButtons.radiusSelect.onComplete = this.onRadiusSelect;
        leafletHelper.controls.searchResults.onResultLabelClick = this.onSearchResultLabelClick;
        leafletHelper.controls.searchResults.onResultIconClick = this.onSearchResultIconClick;

        this.mapBookmarksMenu = new MapBookmarksMenu(leafletHelper.leafletMap, this.props.appSetState);
      }

      this.shapesLayer.clearLayers();
      const layers = shapes.map(obj => leafletHelper.createLayer(obj)).filter(obj => obj !== null);
      layers.forEach(obj => this.shapesLayer.addLayer(obj));
      this.shapesLayerVisible = filter.isSet;
    }
  }

  windowResize = () => {
    if (this.mainPaneRef !== null && this.dataPaneRef !== null) {
      const size = this.dataPaneRef.offsetWidth / this.mainPaneRef.offsetWidth * 100;
      this.setState({ secondarySize: size });
    }
  }

  onKeyUp = (e) => {
    if (e.key === 'Escape') {
      this.props.leafletHelper.controls.searchButtons.cancelAll();
    }
  }

  get shapesLayerVisible() {
    return this.props.leafletHelper.leafletMap.hasLayer(this.shapesLayer);
  }
  set shapesLayerVisible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "BaseViewComponent.shapesLayerVisible.set"');
    }

    if (value !== this.shapesLayerVisible) {
      if (value) {
        this.props.leafletHelper.leafletMap.addLayer(this.shapesLayer);
      } else {
        this.props.leafletHelper.leafletMap.removeLayer(this.shapesLayer);
      }
    }
  }

  onSecondaryPaneSizeChange = (size, dragging) => {
    if (typeof size !== 'number') {
      throw new Error('Invalid "size" param supplied to "BaseViewComponent.onSecondaryPaneSizeChange"');
    }
    if (typeof dragging !== 'boolean') {
      throw new Error('Invalid "dragging" param supplied to "BaseViewComponent.onSecondaryPaneSizeChange"');
    }

    if (!dragging) {
      this.setState(prevState => {
        return prevState.secondarySize === size
          ? null
          : { secondarySize: size };
      }, this.invalidateMap);
    }
  }

  invalidateMap = () => {
    const handler = () => {
      if (this.props.leafletHelper.initialized) {
        this.props.leafletHelper.leafletMap.invalidateSize();
      }
    };
    setTimeout(handler, 300);
  }

  toggleShowMap = () => {
    const { settings } = this.props;

    settings.showMap = !settings.showMap;
    const callback = () => {
      if (settings.showMap) {
        this.invalidateMap();
      }
    }
    this.props.appSetState.view.forceUpdate(callback);
  }

  setShowOverview = (value) => {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "BaseViewComponent.setShowOverview"');
    }

    this.setState(prevState => {
      if (prevState.showOverview === value) {
        return null;
      }
      if (value === true) {
        this.props.settings.showGrid = false;
        this.props.settings.showComparison = false;
      }
      this.props.settings.showOverview = value;
      return { showGrid: this.props.settings.showGrid, showOverview: this.props.settings.showOverview, showComparison: this.props.settings.showComparison  };
    });
  }

  setShowComparison = (value) => {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "BaseViewComponent.setShowComparison"');
    }

    this.setState(prevState => {
      if (prevState.showComparison === value) {
        return null;
      }
      if (value === true) {
        this.props.settings.showGrid = false;
        this.props.settings.showOverview = false;
      }
      this.props.settings.showComparison = value;
      return { showGrid: this.props.settings.showGrid, showOverview: this.props.settings.showOverview, showComparison: this.props.settings.showComparison };
    });
  }

  setShowGrid = (value) => {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "BaseViewComponent.setShowGrid"');
    }

    this.setState(prevState => {
      if (prevState.showGrid === value) {
        return null;
      }
      if (value === true) {
        this.props.settings.showComparison = false;
        this.props.settings.showOverview = false;
      }
      this.props.settings.showGrid = value;
      return { showGrid: this.props.settings.showGrid, showOverview: this.props.settings.showOverview, showComparison: this.props.settings.showComparison };
    });
  }

  onMapMove = () => {
    if (this.ignoreMapMove === true) {
      this.ignoreMapMove = false;
    }
    else {
      this.props.filter.mapBounds = this.props.leafletHelper.leafletMap.getBounds();
      if (this.props.settings.showMap && this.props.filter.followMap) {
        clearTimeout(this.mapMoveTimer);
        this.mapMoveTimer = setTimeout(this.props.update, 750);
      }
    }
  }

  toggleShowFilters = () => {
    this.props.setShowFilters(!this.props.showFilters);
    if (!this.props.showFilters) this.props.setShowSettings(false);
  }

  toggleShowSettings = (fromLink) => {
    if (fromLink == false || this.props.showSettings == false) {
      this.props.appSetState.appValuation.clearDetailValuation();
      this.props.setShowSettings(!this.props.showSettings); //show a different panel
      if (!this.props.showSettings) this.props.setShowFilters(false);
    }
  }

  search = (url, headerBody) => {
    const onSuccess = (result) => {
      const models = result.map(obj => new SearchResultModel(obj));
      this.props.leafletHelper.controls.searchResults.update(models);
    }
    this.props.appSetState.api.post(url, headerBody, onSuccess);
  };

  onTextSearch = (text) => this.search('api/map/search/text', { text: text });
  onPointSelect = (center, radius) => this.search('api/map/search/radius', { center: center, radius: radius });
  onBoxSelect = (northEast, southWest) => this.search('api/map/search/box', { northEast: northEast, southWest: southWest });
  onRadiusSelect = (center, radius) => this.search('api/map/search/radius', { center: center, radius: radius });

  onSearchResultLabelClick = (searchResult) => {
    if (!(searchResult instanceof SearchResultModel)) {
      throw new Error('Invalid "searchResult" param supplied to "BaseViewComponent.onSearchResultLabelClick"');
    }

    if (searchResult.type === searchResultType.Block) {
      this.props.appSetState.view.details.showBlock(searchResult.id);
      return true;
    }

    if (searchResult.type === searchResultType.Field) {
      this.props.appSetState.view.details.showField(searchResult.id);
      return true;
    }

    if (searchResult.type === searchResultType.Well) {
      this.props.appSetState.view.details.showWell(searchResult.id);
      return true;
    }

    if (searchResult.type === searchResultType.Hub) {
      this.props.appSetState.view.details.showHub(searchResult.id);
      return true;
    }

    if (searchResult.type === searchResultType.Corporate) {
      this.props.appSetState.view.details.showCorporate(searchResult.id);
      return true;
    }

    if (searchResult.type === searchResultType.Prospect) {
      this.props.appSetState.view.details.showProspect(searchResult.id);
      return true;
    }

    if (searchResult.type === searchResultType.Discovery) {
      this.props.appSetState.view.details.showDiscovery(searchResult.id);
      return true;
    }

    if (searchResult.type === searchResultType.Licence) {
      this.props.appSetState.view.details.showLicence(searchResult.id);
      return true;
    }
  }

  onSearchResultIconClick = (searchResult) => {
    if (searchResult.shapeModel.latLngs.length === 0) {
      return this.onSearchResultLabelClick(searchResult);
    }
  }

  _saveToZip(fileName, encodedBytes) {
    const byteCharacters = atob(encodedBytes);
    const byteNumbers = new Array(byteCharacters.length);
    for (var loop1 = 0; loop1 < byteCharacters.length; loop1++) {
      byteNumbers[loop1] = byteCharacters.charCodeAt(loop1);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Internet Explorer
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const blob = new Blob([byteArray], { type: 'application/pdf;base64' });
      const fileURL = URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.setAttribute('download', fileName);
      anchor.setAttribute('href', fileURL);
      document.body.appendChild(anchor);
      anchor.remove();
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
    }
  }

  onDownloadShapeFileClick = (countryName, countryId) => {
    if (typeof countryName !== 'string') {
      throw new Error('Invalid "countryName" param supplied to "BaseViewComponent.onDownloadShapeFileClick"');
    }
    if (typeof countryId !== 'number') {
      throw new Error('Invalid "countryId" param supplied to "BaseViewComponent.onDownloadShapeFileClick"');
    }

    const url = 'api/map/shape-files?countryId=' + countryId;
    const onSuccess = (result) => this._saveToZip(result.fileName, result.data);
    this.props.appSetState.api.get(url, onSuccess);
  }

  reset() {
    const doReset = () => {
      this.props.leafletHelper.controls.searchResults.close();
      this.props.reset();
    };

    this.props.appSetState.messageBox.showYesNoPrompt('Are you sure you want to reset?', doReset, 'Reset', MessageBoxIcon.Question);
  }

  excelExport() {
    this.props.appSetState.api.execOrFailIfTrialUser(this.props.appView, this.props.excelExport);
  }

  render() {
    const { showMap } = this.props.settings;
    const { showOverview, showComparison, showGrid } = this.state;
    const className = appendClassNames('base-view-component ma-view', this.props.className);
    const reset = typeof this.props.reset === 'function' ? () => this.reset() : null;
    const excelExport = typeof this.props.excelExport === 'function' ? () => this.excelExport() : null;

    const addClassName = this.props.appView === appViews.Home ? '' : this.props.appView === appViews.Field || this.props.appView === appViews.Hub || this.props.appView === appViews.Corporate ? 'double' : 'single';

    return (
      <div ref={ref => this.mainPaneRef = ref} className={className}>
        <SplitterPanelComponent
          vertical
          percentage
          animateSizeChange={false}
          primaryMinSize={this.props.mapMinWidth}
          secondarySize={this.state.secondarySize}
          secondaryMinSize={this.props.contentMinWidth}
          onSecondaryPaneSizeChange={this.onSecondaryPaneSizeChange}
          visiblePanes={showMap ? 'both' : 'secondary'}
        >
          <div className="map-pane" id={this.props.leafletHelper.id} />

          <div ref={ref => this.dataPaneRef = ref} className="data-pane">
            <ToolbarComponent
              className="data-toolbar"
              showLabels={this.props.appSetState.misc.showLabels}
              title={appViews.getDisplayName(this.props.appView)}
              rowCount={this.props.shapes.length}
              showMap={showMap}
              toggleShowMap={this.toggleShowMap}
              showFilters={this.props.showFilters}
              toggleShowFilters={this.toggleShowFilters}
              showFiltersButton={this.props.showFiltersButton}
              filterButtonEnabled={this.props.filterButtonEnabled}
              filterIsSet={this.props.filter.isSet}
              showSettings={this.props.showSettings}
              toggleShowSettings={this.toggleShowSettings}
              showSettingsButton={this.props.showSettingsButton}
              settingsButtonEnabled={this.props.settingsButtonEnabled}
              advancedPlus={this.state.advancedPlus}
              showFollowMapButton={this.props.showFollowMapButton}
              followMap={this.props.followMap}
              setFollowMap={this.props.setFollowMap}
              excelExport={excelExport}
              reset={reset}
              percentageWidth={showMap ? this.state.secondarySize : 100}
              appSetState={this.props.appSetState}
              appView={this.props.appView}

              showGrid={showGrid}
              setShowGrid={this.setShowGrid}
              canShowGrid={this.props.grid !== undefined}

              showOverview={showOverview}
              setShowOverview={this.setShowOverview}
              canShowOverview={this.props.overview !== undefined}

              showComparison={showComparison}
              setShowComparison={this.setShowComparison}
              canShowComparison={this.props.comparison !== undefined}
            />

            <div className={'data-content ' + addClassName}>
              {showOverview ? this.props.overview : showComparison ? this.props.comparison : this.props.grid}
            </div>
          </div>
        </SplitterPanelComponent>
      </div>
    );
  }
}

BaseViewComponent.propTypes = {
  className: PropTypes.string.isRequired,
  leafletHelper: PropTypes.instanceOf(LeafletHelper),
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired),
  shapes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  appView: appViews.propType,
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  update: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    showMap: PropTypes.bool.isRequired,
    showOverview: PropTypes.bool
  }).isRequired,
  filter: PropTypes.instanceOf(BaseFilter).isRequired,
  mapMinWidth: PropTypes.number.isRequired,
  contentMinWidth: PropTypes.number.isRequired,
  showFiltersButton: PropTypes.bool.isRequired,
  showFilters: PropTypes.bool.isRequired,
  setShowFilters: PropTypes.func.isRequired,
  setShowSettings: PropTypes.func.isRequired,
  showFollowMapButton: PropTypes.bool.isRequired,
  followMap: PropTypes.bool.isRequired,
  setFollowMap: PropTypes.func.isRequired,
  overview: PropTypes.node,
  grid: PropTypes.node,
  excelExport: PropTypes.func,
  reset: PropTypes.func,
  filterButtonEnabled: PropTypes.bool.isRequired
};

BaseViewComponent.defaultProps = {
  mapMinWidth: 20,
  contentMinWidth: 20,
  filterButtonEnabled: true,
  setShowSettings: () => { }

};

export default BaseViewComponent;
export { LeafletHelper, ContextLayerModel };
