import React from 'react';
import PropTypes from 'prop-types';

import { ProspectDetail } from '../../../models/prospect-detail';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const LocationComponent = (props) => {
  const { resource, onBlockClick, onLicenceClick } = props;

  let licence = resource.licence;
  if (typeof onLicenceClick === 'function' && typeof resource.licenceId === 'number') {
    const onClick = (e) => {
      e.preventDefault();
      onLicenceClick(resource.licenceId);
    };
    licence = <a href="/" title="Show Details" onClick={onClick}>{licence}</a>;
  }

  let blockNo = resource.blockNo;
  if (typeof onBlockClick === 'function' && typeof resource.blockId === 'number') {
    const onClick = (e) => {
      e.preventDefault();
      onBlockClick(resource.blockId);
    };
    blockNo = <a href="/" title="Show Details" onClick={onClick}>{blockNo}</a>;
  }

  return (
    <BaseTableComponent className="one-col-key-value">
      <tbody>
        <tr>
          <td>Country</td>
          <td>{resource.resourceCountry.countryName}</td>
        </tr>
        <tr>
          <td>Area</td>
          <td>{resource.resourceArea.areaName}</td>
        </tr>
        <tr>
          <td>Licence Number</td>
          <td>{licence}</td>
        </tr>
        <tr>
          <td>Block</td>
          <td>{blockNo}</td>
        </tr>
      </tbody>
    </BaseTableComponent>
  );
}

LocationComponent.propTypes = {
  resource: PropTypes.instanceOf(ProspectDetail).isRequired,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func
};

export default LocationComponent;
