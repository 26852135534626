import React from 'react';
import PropTypes from 'prop-types';

import ReservesFactsComponent from './reserves-and-production/reserves-facts-component';
import ProductionFactsComponent from './reserves-and-production/production-facts-component';

import { valueTypes } from '../../../shared/helpers/value-handler';
import { CorporateDetail } from '../../../models/corporate-detail';
import GenericChart, { ChartType } from '../../../shared/components/generic-chart/generic-chart';
import GenericPieChart from '../../../shared/components/generic-chart/generic-pie-chart';
import { createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedReservesAndProductionComponent = (props) => {
  const { corporate } = props;

  const chartStyle = { height: '250px' };
  const hasReserves = corporate.remainingCombinedMmboe > 0;
  var reserves, production, fieldReserves, fieldProduction;

  if (hasReserves) {
    reserves =
      (
        <div>
          <div style={chartStyle}>
          <GenericPieChart
            chartDataItems={corporate.chartDataCollection.netRemainingReservesByStatus} valueType={valueTypes.mmmboe}
            />
          </div>
          <br />
          <ReservesFactsComponent
            corporate={corporate}
          />
        </div>
      );
  }
  else { reserves = (<div>No remaining reserves</div>); }

  if (hasReserves) {
    fieldReserves =
      (
        <div style={chartStyle}>
          <GenericChart
            chartDataItems={corporate.chartDataCollection.remainingReservesByField}
            chartType={ChartType.Bar}
            isXStacked={true}
            isYStacked={true}
            showYAxisLabel={true}
            yAxisLabel={valueTypes.UnitString(valueTypes.mmmboe)}
            showYAxisGridLines={true}
          />
        </div>
      );
  }
  else { fieldReserves = (<div>No remaining field reserves</div>); }

  if (hasReserves) {
    production =
      (
        <div>
          <div style={chartStyle}>
            <GenericChart
              chartDataItems={corporate.chartDataCollection.corporateProduction}
              chartType={ChartType.Line}
              isXStacked={false}
              isYStacked={true}
              showYAxisLabel={true}
              yAxisLabel={valueTypes.UnitString(valueTypes.mboed)}
              showYAxisGridLines={true}
            />
          </div>
          <br />
          <ProductionFactsComponent
            corporate={corporate}
          />
        </div>
      );
  }
  else {
    production = (<div>No future production</div>);
  }

  if (hasReserves) {
    fieldProduction =
      (
        <div style={chartStyle}>
          <GenericChart
            chartDataItems={corporate.chartDataCollection.productionByField}
            chartType={ChartType.Line}
            isXStacked={false}
            isYStacked={true}
            showYAxisLabel={true}
            yAxisLabel={valueTypes.UnitString(valueTypes.mboed)}
            showYAxisGridLines={true}
          />
        </div>
      );
  }
  else {
    fieldProduction = (<div>No future field production</div>);
  }

  return (
    <div ref={props.innerRef}>
      {createSection([
        { content: reserves, sectionType: sectionTypes.section33, title: 'Remaining net reserves' },
        { content: production, sectionType: sectionTypes.section66, title: 'Net future production' }
      ])}
      {createSection([
        { content: fieldReserves, sectionType: sectionTypes.section33, title: 'Remaining net reserves by field' },
        { content: fieldProduction, sectionType: sectionTypes.section66, title: 'Future net production by field' }
      ])}
    </div>
  );
};

AdvancedReservesAndProductionComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  corporate: PropTypes.instanceOf(CorporateDetail).isRequired
};

export default AdvancedReservesAndProductionComponent;