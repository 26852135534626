import React from 'react';
import PropTypes from 'prop-types';
import { valueTypes } from '../../shared/helpers/value-handler';
import { CorporateComparisonGridExcelExport } from './corporate-comparison-grid-excel-export';
import BaseGridComponent, { createLink, NUMERIC_SORT, nullNumberRenderer} from '../../components/base-grid/base-grid-component';
import { AppSetState } from '../../app/app-set-state';
import { CorporateValuationComparison } from '../../models/valuation-comparison';
import { COLUMN_LARGE, COLUMN_SMALL, HEADER_ROW_SINGLE } from '../../shared/components/data-grid/ag-grid-component';

const CorporateComparisonGridComponent = (props) => {
  const { view } = props.appSetState;

  function showCorporate(id) {
    const filter = props.getFilter();
    view.details.showCorporate(id, null, null, filter)
  }

  const COLUMN_DEFS = [];

  const companyGroup = [];
  companyGroup.push(
    { headerName: "Company Name", field: 'corporateName', suppressMenu: true, sortable: true, minWidth: COLUMN_LARGE, cellRenderer: (params) => createLink(params.data.corporateName, 'Show Details', () => showCorporate(params.data.corporateId)), suppressMovable: true },
  );
  COLUMN_DEFS.push({ headerName: 'Company', children: companyGroup });

  const liquidGroup = [];
  liquidGroup.push(
    { headerName: "Valuation One", field: 'valuationOneRemainingLiquidMMB', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Valuation Two", field: 'valuationTwoRemainingLiquidMMB', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Difference", field: 'remainingLiquidDifferenceMMB', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
  );
  COLUMN_DEFS.push({ headerName: 'Net Remaining Liquid Reserves (' + valueTypes.UnitString(valueTypes.mmb) + ')', children: liquidGroup });

  const gasGroup = [];
  gasGroup.push(
    { headerName: "Valuation One", field: 'valuationOneRemainingGasBCF', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Valuation Two", field: 'valuationTwoRemainingGasBCF', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Difference", field: 'remainingGasDifferenceBCF', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
  );
  COLUMN_DEFS.push({ headerName: 'Net Remaining Gas Reserves (' + valueTypes.UnitString(valueTypes.bcf) + ')', children: gasGroup });

  const mmboeGroup = [];
  mmboeGroup.push(
    { headerName: "Valuation One", field: 'valuationOneRemainingReservesMMBOE', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Valuation Two", field: 'valuationTwoRemainingReservesMMBOE', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Difference", field: 'remainingReservesDifferenceMMBOE', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
  );
  COLUMN_DEFS.push({ headerName: 'Net Remaining Reserves (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', children: mmboeGroup });

  const npvGroup = [];
  npvGroup.push(
    { headerName: "Valuation One", field: 'valuationOneRemainingUndiscountedPreTaxCashflowUSDMM', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Valuation Two", field: 'valuationTwoRemainingUndiscountedPreTaxCashflowUSDMM', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Difference", field: 'remainingUndiscountedPreTaxCashflowDifferenceUSDMM', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
  );
  COLUMN_DEFS.push({ headerName: 'Net Remaining Undiscounted Pre-Tax Cashflow (' + valueTypes.UnitString(valueTypes.usdMM) + ')', children: npvGroup });

  return <BaseGridComponent columnDefs={COLUMN_DEFS} dataRows={props.dataRows} autoReSizeGrid={true} headerRowHeight={HEADER_ROW_SINGLE} groupHeaderRowHeight={HEADER_ROW_SINGLE}/>;
};

CorporateComparisonGridComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.instanceOf(CorporateValuationComparison).isRequired).isRequired,
  currentYear: PropTypes.number.isRequired,
  getFilter: PropTypes.func.isRequired //TODO: use correct instanceOf
};

CorporateComparisonGridComponent.defaultProps = {
  currentYear: new Date().getFullYear(),
};

export default CorporateComparisonGridComponent;
export { CorporateComparisonGridExcelExport };
