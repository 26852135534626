//TODO - Is this the right place or should create a models folder? speak to Phil about
class Filedownload {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Filedownload.ctor'); }
    if (obj.zipByteArray !== null && typeof obj.zipByteArray !== 'string') { throw new Error('Invalid obj.zipByteArray param supplied to Filedownload.ctor'); }
    if (obj.base64EncodedPDF !== null && typeof obj.base64EncodedPDF !== 'string') { throw new Error('Invalid obj.base64EncodedPDF param supplied to Filedownload.ctor'); }
    if (obj.shortFileName !== null && typeof obj.shortFileName !== 'string') { throw new Error('Invalid obj.ShortFileName param supplied to Filedownload.ctor'); }
    if (obj.title !== null && typeof obj.title !== 'string') { throw new Error('Invalid obj.Title param supplied to Filedownload.ctor'); }

    if (typeof obj.fileExistsInDB !== 'boolean') { throw new Error('Invalid obj.fileExistsInDB param supplied to Filedownload.ctor'); }
    if (typeof obj.fileExistsOnServer !== 'boolean') { throw new Error('Invalid obj.fileExistsOnServer param supplied to Filedownload.ctor'); }
    if (typeof obj.userSubscribed !== 'boolean') { throw new Error('Invalid obj.userSubscribed param supplied to Filedownload.ctor'); }
    
    if (obj.errorMessage !== null && typeof obj.errorMessage !== 'string') { throw new Error('Invalid obj.errorMessage param supplied to Filedownload.ctor'); }

    this.zipByteArray = obj.zipByteArray;
    this.base64EncodedPDF = obj.base64EncodedPDF;
    this.shortFileName = obj.shortFileName;
    this.title = obj.title;
    this.fileExistsInDB = obj.fileExistsInDB;
    this.fileExistsOnServer = obj.fileExistsOnServer;
    this.userSubscribed = obj.userSubscribed;
    this.errorMessage = obj.errorMessage;
  }

  destroy() {
    delete this.zipByteArray;
    delete this.base64EncodedPDF;
    delete this.shortFileName;
    delete this.title;
    delete this.fileExistsInDB;
    delete this.fileExistsOnServer;
    delete this.userSubscribed;
    delete this.errorMessage;

    delete this;
  }
}

export { Filedownload };
