import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Button } from 'reactstrap';

import FieldDetailComponent from './field-detail-component';

import { DetailModel } from '../detail-model';
import HeaderButtonComponent from '../header-button-component';
import HeaderComponent, { BUTTON_ICON_SIZE } from '../header-component';

import { appViews } from '../../app/app-views';
import { appIcons, appIconSizes } from '../../app/app-icons';
import { assignColours } from '../../shared/helpers/array-color-helper';
import { ContextLayerModel } from '../../shared/helpers/leaflet/leaflet-helper';
import { FasterPortfolio } from '../../components/fiscal/models/portfolio/faster-portfolio';
import { FiscalResourceExcelExport } from '../../components/fiscal/helpers/fiscal-resource-excel-export';

class FieldDetailModel extends DetailModel {
  constructor(atlasPanel, fieldId) {
    if (typeof fieldId !== 'number') {
      throw new Error('Invalid "fieldId" supplied to "FieldDetailModel.ctor"');
    }

    super(appViews.Field, atlasPanel);
    this._fieldId = fieldId;
    this._valuationId = 0;
    this._appSetState = null;
    this.openSettings = this.openSettings.bind(this);
  }

  matches(detail) {
    const result =
      typeof detail === 'object' &&
      detail instanceof FieldDetailModel &&
      detail._fieldId === this._fieldId &&
      detail._valuationId === this._valuationId
    return result;
  }

  load(appSetState, onLoadComplete) {
    this._appSetState = appSetState;
    this._validateLoadParams(appSetState, onLoadComplete);

    // Load from server
    const onSuccess = (result) => {
      this.clearError();

      this._data = {
        portfolio: new FasterPortfolio(result.portfolio),
        contextLayers: ContextLayerModel.FromArray(result.contextLayers),
        advancedPlus: result.advancedPlus
      };
      this._data.resource = this._data.portfolio.fieldDetail.find(obj => obj.resourceId === this._fieldId);

      appSetState.appValuation.detailValuation = this._data.resource.valuation;

      assignColours(this._data.resource.owners, 'color');
      assignColours(this._data.resource.events, 'color');
      this.addNavigationHistory(appSetState, this._fieldId, this._data.resource.resourceName);
      onLoadComplete();
    };
    const onFail = (err) => this.handleLoadError(err, onLoadComplete);
    const valuationId = appSetState.appValuation.getDetailValuationId();
    this._valuationId = valuationId;
    const url = 'api/field/details/load/?resourceId=' + this._fieldId + '&page=' + appSetState.view.current + '&panel=' + this.atlasPanel + '&valuationId=' + valuationId;
    appSetState.api.get(url, onSuccess, onFail);
  }

  openSettings() {
    this.showSettings = !this.showSettings;
    this.forceUpdate();
  }

  reLoader = (appSetState, Id) => {
    appSetState.view.details.showField(Id);
  }

  getHeaderComponent(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close) {
    this._validateGetHeaderComponentParams(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close);

    let excelExport = null;
    if (this.loaded && this._data.resource.fullView && this._data.resource.fiscalResource !== null) {
      excelExport = () => appSetState.api.execOrFailIfTrialUser(this.appView, () => new FiscalResourceExcelExport(this._data.resource.fiscalResource, this._data.resource.valuation, appSetState.appValuation.baseValuation).exportAll());
    }

    let relatedResources = <DropdownItem disabled>None found</DropdownItem>;
    if (this.loaded && this._data.resource.fiscalResource !== null) {
      const related = this._data.portfolio.fieldDetail.filter(pr => pr.resourceId !== this._fieldId);
      if (related.length > 0) {
        relatedResources = related.map((obj, idx) => <DropdownItem key={'related-link-' + idx} onClick={(e) => { e.preventDefault(); appSetState.view.details.showField(obj.resourceId); }}>{obj.resourceName}</DropdownItem>);
      }
    }

    const copyLinkClick = null;
    //const copyLinkClick = this.loaded
    //  ? () => appSetState.view.details.showFieldLink(this._fieldId)
    //  : null;

    const dropdownStyle = (this._data !== null && this._data.resource.fullView === true) ? 'block' : 'none';
    const settingsTitle = this.showSettings ? 'Hide Valuations' : 'Show Valuations';

    return (
      <HeaderComponent
        appView={this.appView}
        title={this.getTitle(() => this._data.resource.resourceName)}
        backClick={backClick}
        forwardClick={forwardClick}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        close={close}
        copyLinkClick={copyLinkClick}
      >

        <HeaderButtonComponent
          iconDef={appIcons.Valuations}
          iconSize={BUTTON_ICON_SIZE}
          iconSolid={false}
          title={settingsTitle}
          onClick={this.openSettings}
        />
        <UncontrolledDropdown disabled={relatedResources === null} style={{ display: dropdownStyle }}>
          <DropdownToggle size="sm" caret title="Fields within same production hub">
            {appViews.getIcon(this.appView, BUTTON_ICON_SIZE)}
          </DropdownToggle>
          <DropdownMenu end>
            {relatedResources}
          </DropdownMenu>
        </UncontrolledDropdown>
        <HeaderButtonComponent iconDef={appIcons.FileExcel} iconSize={BUTTON_ICON_SIZE} title="Export to Excel" disabled={excelExport === null} onClick={excelExport} iconSolid={true} colour={'green'} />
      </HeaderComponent>
    );
  }

  getBodyComponent(appSetState, geoserverUrl) {
    this._validateGetBodyComponentParams(appSetState, geoserverUrl);

    if (this._error !== null) {
      return this._getErrorComponent();
    }

    if (this.loaded) {
      return (
        <FieldDetailComponent
          portfolio={this._data.portfolio}
          resource={this._data.resource}
          contextLayers={this._data.contextLayers}
          appSetState={appSetState}
          geoserverUrl={geoserverUrl}
          showSettings={this.showSettings}
          closeSettings={this.openSettings}
          update={this.reLoader}
          advancedPlus={this._data.advancedPlus}
          currentDetailValuationName={this._data.resource.valuation.variableName}
          openSettings={this.openSettings}
        />
      );
    }

    return this._getLoadingComponent();
  }
}

export { FieldDetailModel };
