import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHome as faHomeLight,
  faOilCan as faOilCanLight,
  faChartNetwork as faChartNetworkLight,
  faGlobeEurope as faGlobeEuropeLight,
  faAnalytics as faAnalyticsLight,
  faTable as faTableLight,
  faFilter as faFilterLight,
  faFileChartPie as faFileChartPieLight,
  faTrash as faTrashLight,
  faCog as faCogLight,
  faCheckSquare as faCheckSquareLight,
  faSquare as faSquareLight,
  faHistory as faHistoryLight,
  faLockAlt as faLockAltLight,
  faEnvelope as faEnvelopeLight,
  faEnvelopeSquare as faEnvelopeSquareLight,
  faSignOutAlt as faSignOutAltLight,
  faBriefcase as faBriefcaseLight,
  faBuilding as faBuildingLight,
  faBookmark as faBookmarkLight,
  faMapMarker as faMapMarkerLight,
  faMapMarkerAlt as faMapMarkerAltLight,
  faMapMarked as faMapMarkedLight,
  faPlusCircle as faPlusCircleLight,
  faCircle as faCircleLight,
  faTh as faThLight,
  faToggleOff as faToggleOffLight,
  faToggleOn as faToggleOnLight,
  faTimes as faTimesLight,
  faTimesSquare as faTimesSquareLight,
  faCheckDouble as faCheckDoubleLight,
  faFilePdf as faFilePdfLight,
  faFileAlt as faFileAltLight,
  faFileDownload as faFileDownloadLight,
  faFileExcel as faFileExcelLight,
  faExpandArrowsAlt as faExpandArrowsAltLight,
  faCompressArrowsAlt as faCompressArrowsAltLight,
  faLink as faLinkLight,
  faDotCircle as faDotCircleLight,
  faBullseye as faBullseyeLight,
  faUndo as faUndoLight,
  faQuestionCircle as faQuestionCircleLight,
  faUserCircle as faUserCircleLight,
  faWindowRestore as faWindowRestoreLight,
  faWindowMinimize as faWindowMinimizeLight,
  faWindowMaximize as faWindowMaximizeLight,
  faVideo as faVideoLight,
  faPodcast as faPodcastLight,
  faArrowLeft as faArrowLeftLight,
  faArrowRight as faArrowRightLight,
  faCopy as faCopyLight,
  faExclamationTriangle as faExclamationTriangleLight,
  faSpinner as faSpinnerLight,
  faFileInvoiceDollar as faFileInvoiceDollarLight,
  faCoins as faCoinsLight,
  faDollarSign as faDollarSignLight,
} from '@fortawesome/pro-light-svg-icons';
import {
  faHome as faHomeSolid,
  faOilCan as faOilCanSolid,
  faChartNetwork as faChartNetworkSolid,
  faGlobeEurope as faGlobeEuropeSolid,
  faAnalytics as faAnalyticsSolid,
  faTable as faTableSolid,
  faFilter as faFilterSolid,
  faFileChartPie as faFileChartPieSolid,
  faTrash as faTrashSolid,
  faCog as faCogSolid,
  faCheckSquare as faCheckSquareSolid,
  faSquare as faSquareSolid,
  faHistory as faHistorySolid,
  faLockAlt as faLockAltSolid,
  faSignOutAlt as faSignOutAltSolid,
  faBriefcase as faBriefcaseSolid,
  faEnvelope as faEnvelopeSolid,
  faEnvelopeSquare as faEnvelopeSquareSolid,
  faBuilding as faBuildingSolid,
  faBookmark as faBookmarkSolid,
  faMapMarker as faMapMarkerSolid,
  faMapMarkerAlt as faMapMarkerAltSolid,
  faMapMarked as faMapMarkedSolid,
  faPlusCircle as faPlusCircleSolid,
  faCircle as faCircleSolid,
  faTh as faThSolid,
  faToggleOff as faToggleOffSolid,
  faToggleOn as faToggleOnSolid,
  faTimes as faTimesSolid,
  faTimesSquare as faTimesSquareSolid,
  faCheckDouble as faCheckDoubleSolid,
  faFilePdf as faFilePdfSolid,
  faFileAlt as faFileAltSolid,
  faFileDownload as faFileDownloadSolid,
  faFileExcel as faFileExcelSolid,
  faExpandArrowsAlt as faExpandArrowsAltSolid,
  faCompressArrowsAlt as faCompressArrowsAltSolid,
  faLink as faLinkSolid,
  faDotCircle as faDotCircleSolid,
  faBullseye as faBullseyeSolid,
  faUndo as faUndoSolid,
  faQuestionCircle as faQuestionCircleSolid,
  faUserCircle as faUserCircleSolid,
  faWindowRestore as faWindowRestoreSolid,
  faWindowMinimize as faWindowMinimizeSolid,
  faWindowMaximize as faWindowMaximizeSolid,
  faVideo as faVideoSolid,
  faPodcast as faPodcastSolid,
  faArrowLeft as faArrowLeftSolid,
  faArrowRight as faArrowRightSolid,
  faCopy as faCopySolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faSpinner as faSpinnerSolid,
  faFileInvoiceDollar as faFileInvoiceDollarSolid,
  faCoins as faCoinsSolid,
  faDollarSign as faDollarSignSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { objectToEnum } from '../shared/helpers/common';

const appIconSizes = objectToEnum({
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4
});

const appIcons = objectToEnum({
  Home: 0,
  Field: 10,
  Hub: 20,
  Corporate: 30,
  Discovery: 40,
  Block: 50,
  Well: 60,
  Prospect: 70,

  Map: 500,
  Filter: 510,
  Overview: 520,
  Table: 530,
  Details: 540,
  Clear: 550,
  Settings: 560,
  Checked: 570,
  Unchecked: 580,
  History: 590,
  ChangePassword: 600,
  Logout: 610,
  Reports: 620,
  MapMarker: 630,
  MapMarkerAlt: 640,
  MapMarked: 645,
  PlusCircle: 650,
  ToggleOff: 660,
  ToggleOn: 670,
  Close: 680,
  CloseSquare: 690,
  ChangeNotifications: 700,
  Email: 710,
  CheckDouble: 720,
  FilePdf: 730,
  FileDownload: 740,
  FileExcel: 750,
  ExpandArrows: 760,
  CompressArrows: 770,
  Link: 780,
  Undo: 790,
  Help: 800,
  UserCircle: 810,
  WindowRestore: 820,
  WindowMinimize: 830,
  WindowMaximize: 840,
  Video: 850,
  Podcast: 860,
  ArrowLeft: 870,
  ArrowRight: 880,
  Copy: 890,
  ErrorTriangle: 900,
  Spinner: 910,
  FileAlt: 920,
  Valuations: 930
}, false);

appIcons.getIconDefinition = (appIcon, solid = true) => {
  if (typeof solid !== 'boolean') {
    throw new Error('Invalid "solid" param supplied to "appIcons.getIconDefinition"');
  }

  if (solid) {
    switch (appIcon) {
      case appIcons.Home: return faHomeSolid;
      case appIcons.Field: return faOilCanSolid;
      case appIcons.Hub: return faChartNetworkSolid;
      case appIcons.Corporate: return faBuildingSolid;
      case appIcons.Discovery: return faCircleSolid;
      case appIcons.Block: return faThSolid;
      case appIcons.Well: return faBullseyeSolid;
      case appIcons.Prospect: return faDotCircleSolid;
      case appIcons.Map: return faGlobeEuropeSolid;
      case appIcons.Filter: return faFilterSolid;
      case appIcons.Overview: return faAnalyticsSolid;
      case appIcons.Table: return faTableSolid;
      case appIcons.Details: return faFileChartPieSolid;
      case appIcons.Clear: return faTrashSolid;
      case appIcons.Settings: return faCogSolid;
      case appIcons.Checked: return faCheckSquareSolid;
      case appIcons.Unchecked: return faSquareSolid;
      case appIcons.History: return faHistorySolid;
      case appIcons.ChangePassword: return faLockAltSolid;
      case appIcons.ChangeNotifications: return faEnvelopeSquareSolid;
      case appIcons.Logout: return faSignOutAltSolid;
      case appIcons.Reports: return faBriefcaseSolid;
      case appIcons.Bookmark: return faBookmarkSolid;
      case appIcons.MapMarker: return faMapMarkerSolid;
      case appIcons.MapMarkerAlt: return faMapMarkerAltSolid;
      case appIcons.MapMarked: return faMapMarkedSolid;
      case appIcons.PlusCircle: return faPlusCircleSolid;
      case appIcons.ToggleOff: return faToggleOffSolid;
      case appIcons.ToggleOn: return faToggleOnSolid;
      case appIcons.Close: return faTimesSolid;
      case appIcons.CloseSquare: return faTimesSquareSolid;
      case appIcons.Email: return faEnvelopeSolid;
      case appIcons.CheckDouble: return faCheckDoubleSolid;
      case appIcons.FilePdf: return faFilePdfSolid;
      case appIcons.FileAlt: return faFileAltSolid;
      case appIcons.FileDownload: return faFileDownloadSolid;
      case appIcons.FileExcel: return faFileExcelSolid;
      case appIcons.ExpandArrows: return faExpandArrowsAltSolid;
      case appIcons.CompressArrows: return faCompressArrowsAltSolid;
      case appIcons.Link: return faLinkSolid;
      case appIcons.Undo: return faUndoSolid;
      case appIcons.Help: return faQuestionCircleSolid;
      case appIcons.UserCircle: return faUserCircleSolid;
      case appIcons.WindowRestore: return faWindowRestoreSolid;
      case appIcons.WindowMinimize: return faWindowMinimizeSolid;
      case appIcons.WindowMaximize: return faWindowMaximizeSolid;
      case appIcons.Video: return faVideoSolid;
      case appIcons.Podcast: return faPodcastSolid;
      case appIcons.ArrowLeft: return faArrowLeftSolid;
      case appIcons.ArrowRight: return faArrowRightSolid;
      case appIcons.Copy: return faCopySolid;
      case appIcons.ErrorTriangle: return faExclamationTriangleSolid;
      case appIcons.Spinner: return faSpinnerSolid;
      case appIcons.Valuations: return faDollarSignSolid;
      default: throw new Error('Unsupported "appIcon" param (' + appIcon + ') supplied to "appIcons.getIconDefinition"');
    }
  }

  switch (appIcon) {
    case appIcons.Home: return faHomeLight;
    case appIcons.Field: return faOilCanLight;
    case appIcons.Hub: return faChartNetworkLight;
    case appIcons.Corporate: return faBuildingLight;
    case appIcons.Discovery: return faCircleLight;
    case appIcons.Block: return faThLight;
    case appIcons.Well: return faBullseyeLight;
    case appIcons.Prospect: return faDotCircleLight;
    case appIcons.Map: return faGlobeEuropeLight;
    case appIcons.Filter: return faFilterLight;
    case appIcons.Overview: return faAnalyticsLight;
    case appIcons.Table: return faTableLight;
    case appIcons.Details: return faFileChartPieLight;
    case appIcons.Clear: return faTrashLight;
    case appIcons.Settings: return faCogLight;
    case appIcons.Checked: return faCheckSquareLight;
    case appIcons.Unchecked: return faSquareLight;
    case appIcons.History: return faHistoryLight;
    case appIcons.ChangePassword: return faLockAltLight;
    case appIcons.ChangeNotifications: return faEnvelopeSquareLight;
    case appIcons.Logout: return faSignOutAltLight;
    case appIcons.Reports: return faBriefcaseLight;
    case appIcons.Bookmark: return faBookmarkLight;
    case appIcons.MapMarker: return faMapMarkerLight;
    case appIcons.MapMarkerAlt: return faMapMarkerAltLight;
    case appIcons.MapMarked: return faMapMarkedLight;
    case appIcons.PlusCircle: return faPlusCircleLight;
    case appIcons.ToggleOff: return faToggleOffLight;
    case appIcons.ToggleOn: return faToggleOnLight;
    case appIcons.Close: return faTimesLight;
    case appIcons.CloseSquare: return faTimesSquareLight;
    case appIcons.Email: return faEnvelopeLight;
    case appIcons.CheckDouble: return faCheckDoubleLight;
    case appIcons.FilePdf: return faFilePdfLight;
    case appIcons.FileAlt: return faFileAltLight;
    case appIcons.FileDownload: return faFileDownloadLight;
    case appIcons.FileExcel: return faFileExcelLight;
    case appIcons.ExpandArrows: return faExpandArrowsAltLight;
    case appIcons.CompressArrows: return faCompressArrowsAltLight;
    case appIcons.Link: return faLinkLight;
    case appIcons.Undo: return faUndoLight;
    case appIcons.Help: return faQuestionCircleLight;
    case appIcons.UserCircle: return faUserCircleLight;
    case appIcons.WindowRestore: return faWindowRestoreLight;
    case appIcons.WindowMinimize: return faWindowMinimizeLight;
    case appIcons.WindowMaximize: return faWindowMaximizeLight;
    case appIcons.Video: return faVideoLight;
    case appIcons.Podcast: return faPodcastLight;
    case appIcons.ArrowLeft: return faArrowLeftLight;
    case appIcons.ArrowRight: return faArrowRightLight;
    case appIcons.Copy: return faCopyLight;
    case appIcons.ErrorTriangle: return faExclamationTriangleLight;
    case appIcons.Spinner: return faSpinnerLight;
    case appIcons.Valuations: return faDollarSignLight;
    default: throw new Error('Unsupported "appIcon" param (' + appIcon + ') supplied to "appIcons.getIconDefinition"');
  }
};

appIcons.getIcon = (iconType, size, solid = true, colour = null) => {
  if (!appIcons.isValid(iconType)) {
    throw new Error('Invalid \'iconType\' param supplied to \'appIcons.getIcon\'');
  }

  switch (size) {
    case appIconSizes.xs: size = 'sm'; break;
    case appIconSizes.sm: size = '1x'; break;
    case appIconSizes.md: size = '2x'; break;
    case appIconSizes.lg: size = '3x'; break;
    case appIconSizes.xl: size = '4x'; break;
    default: throw new Error('Invalid \'size\' param supplied to \'appIcons.getIcon\'');
  }

  const iconDefinition = appIcons.getIconDefinition(iconType, solid);
  return <FontAwesomeIcon icon={iconDefinition} size={size} fixedWidth color={colour} />;
};

Object.freeze(appIcons);

export { appIconSizes, appIcons };