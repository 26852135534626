import React from 'react';
import PropTypes from 'prop-types';

import { BlockDetail } from '../../../models/block-detail';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';
import { valueTypes } from '../../../shared/helpers/value-handler';

const LocationComponent = (props) => {
  const { block, onLicenceClick } = props;

  const getRows = () => {
    let licence = block.licence;
    if (typeof onLicenceClick === 'function' && typeof block.licenceId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onLicenceClick(block.licenceId);
      };
      licence = <a href="/" title="Show Details" onClick={onClick}>{licence}</a>;
    }

    return (
      <React.Fragment>
        <tr>
          <td>Country</td>
          <td>{block.country}</td>
        </tr>
        <tr>
          <td>Area</td>
          <td>{block.area}</td>
        </tr>
        <tr>
          <td>Block Status</td>
          <td>{block.blockStatus}</td>
        </tr>
        <tr>
          <td>Licence</td>
          <td>{licence}</td>
        </tr>
        <tr>
          <td>Licence Round</td>
          <td>{block.licenceRound}</td>
        </tr>
        <tr>
          <td>Licence Date</td>
          <td>{block.licenceDate}</td>
        </tr>
        <tr>
          <td>Licence Type</td>
          <td>{block.licenceType}</td>
        </tr>
        <tr>
          <td>Sub Area</td>
          <td>{block.subArea}</td>
        </tr>
        <tr>
          <td>Area ({valueTypes.UnitString(valueTypes.kmsq)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, block.areaSQKM)}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent className="one-col-key-value">
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

LocationComponent.propTypes = {
  block: PropTypes.instanceOf(BlockDetail).isRequired,
  onLicenceClick: PropTypes.func
};

export default LocationComponent;
