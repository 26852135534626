import React from 'react';
import NumericFieldComponent from '../../views/base/components/numeric-field-component';
import HeaderButtonComponent from '../header-button-component';

class ProximityIncrementor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newproximity: this.props.currentProximity,
      disabled: this.props.disabled
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentProximity !== this.props.currentProximity) {
      if (this.state.newproximity !== this.props.currentProximity) {
        this.setState({ newproximity: this.props.currentProximity });
      }
    }
  }

  onProximityChange = (value) => {
    if (value === null || value === '' || value === 0) {
      this.setState({ newproximity: null, disabled: true });
    }
    else if (value < this.props.proximityLower) {
      this.setState({ newproximity: this.props.proximityLower, disabled: false });
    }
    else if (value > this.props.proximityUpper) {
      this.setState({ newproximity: this.props.proximityUpper, disabled: false });
    }
    else {
      this.setState({ newproximity: value, disabled: false });
    }
  }

  onClick = () => {
    const { appSetState, currentProximity, hubId, proximityLower, proximityUpper } = this.props;
    const { newproximity } = this.state;

    this.props.onClick(appSetState, currentProximity, newproximity, proximityLower, proximityUpper, hubId);
  };

  render() {
    const { title, disabled, iconDef, iconSize, iconSolid } = this.props;

    return (
      <div className='proximity-area' style={{ fontWeight: 'bold', fontSize: '16px' }}>
        <span>Select distance from hub centre point to view discoveries, prospects and E&A wells:</span>
        <NumericFieldComponent label="" value={this.state.newproximity} onChange={this.onProximityChange} type="number" disabled={disabled} /> 
        <span>km</span>
        <HeaderButtonComponent iconDef={iconDef} iconSize={iconSize} title={title} disabled={this.state.disabled} onClick={this.onClick} iconSolid={iconSolid} />
      </div>
    );
  }
}

ProximityIncrementor.defaultProps = {
  currentProximity: 25
};

export default ProximityIncrementor;
