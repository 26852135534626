import React from 'react';
import PropTypes from 'prop-types';
import InitialReservesByParticipantChart from './initial-reserves-by-participant-chart';
import { NonProducingParticipant } from '../models/gmatrix3-extension-non-producing-participant';

const InitialReservesByParticipantComponent = (props) => {
  const { owners } = props;

  const participants = owners.map(par => {
    return {
      name: par.operatorDisplayName,
      liquidMmb: par.netLiquidMmb,
      gasMmboe: par.netGasMmboe
    }
  });

  return <InitialReservesByParticipantChart owners={participants} isStacked={true} />;
}

InitialReservesByParticipantComponent.propTypes = {
  owners: PropTypes.arrayOf(PropTypes.instanceOf(NonProducingParticipant)).isRequired
};

export default InitialReservesByParticipantComponent;
