import React from 'react';
import PropTypes from 'prop-types';

import CorporateDetailGridComponent from '../corporate-detail-grid-component';

import { valueTypes } from '../../../../shared/helpers/value-handler';
import { CashflowAnnual } from '../../../../components/fiscal/models/portfolio/cashflow-annual';

const CorporateCashflowTableComponent = (props) => {
  const yearPinnedRenderer = (params) => params.node.rowPinned == 'bottom' ? params.data.year = 'Total' : params.data.year;
  const liquidProductionMbodRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.year === 'Total' ? params.data.liquidProductionMMB : params.data.liquidProductionMbod);
  const gasProductionMmscfdRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.year === 'Total' ? params.data.gasProductionBCF : params.data.gasProductionMmscfd);
  const totalProductionMboedRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.year === 'Total' ? params.data.totalMmboe : params.data.combinedMboed);
  const totalRevenueUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.totalRevenueUsdMM);
  const opexUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.opexUsdMM);
  const totalTariffUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.totalTariffUsdMM);
  const costForLossesOrDepreciationUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.costForLossesOrDepreciationUsdMM);
  const abexUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.abexUsdMM);
  const preTaxCashflowUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.preTaxCashflowUsdMM);
  const totalEmissionsChargeUsdMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.totalEmissionsChargeUsdMM);

  const usdMMStr = valueTypes.UnitString(valueTypes.usdMM);
  const columnDefs = [
    {
      headerName: 'Year',
      field: 'year',
      cellRendererSelector: yearPinnedRenderer
    },
    {
      headerName: 'Production',
      children: [
        {
          headerName: 'Liquid\n(' + valueTypes.UnitString(valueTypes.mboed) + ')',
          field: 'liquidProductionMbod',
          cellRenderer: liquidProductionMbodRenderer
        },
        {
          headerName: 'Gas\n(' + valueTypes.UnitString(valueTypes.mmscfd) + ')',
          field: 'gasProductionMmscfd',
          cellRenderer: gasProductionMmscfdRenderer
        },
        {
          headerName: 'Total\n(' + valueTypes.UnitString(valueTypes.mboed) + ')',
          field: 'combinedMboed',
          cellRenderer: totalProductionMboedRenderer
        }
      ]
    },
    {
      headerName: 'Revenue\n(' + usdMMStr + ')',
      field: 'totalRevenueUsdMM',
      cellRenderer: totalRevenueUsdMMRenderer
    },
    {
      headerName: 'Opex\n(' + usdMMStr + ')',
      field: 'opexUsdMM',
      cellRenderer: opexUsdMMRenderer
    },
    {
      headerName: 'Emissions charge\n(' + usdMMStr + ')',
      field: 'totalEmissionsChargeUsdMM',
      cellRenderer: totalEmissionsChargeUsdMMRenderer,
    },
    {
      headerName: 'Export tariff\n(' + usdMMStr + ')',
      field: 'totalTariffUsdMM',
      cellRenderer: totalTariffUsdMMRenderer
    },
    {
      headerName: 'Capex\n(' + usdMMStr + ')',
      field: 'costForLossesOrDepreciationUsdMM',
      cellRenderer: costForLossesOrDepreciationUsdMMRenderer
    },
    {
      headerName: 'Abex\n(' + usdMMStr + ')',
      field: 'abexUsdMM',
      cellRenderer: abexUsdMMRenderer
    },
    {
      headerName: 'Pre-tax cashflow\n(' + usdMMStr + ')',
      field: 'preTaxCashflowUsdMM',
      cellRenderer: preTaxCashflowUsdMMRenderer
    }
  ];

  return <CorporateDetailGridComponent hasGroupedHeaderRow columnDefs={columnDefs} dataRows={props.annual} totalRow={props.totalRow} />;
}

CorporateCashflowTableComponent.propTypes = {
  annual: PropTypes.arrayOf(PropTypes.instanceOf(CashflowAnnual)).isRequired,
  totalRow: PropTypes.instanceOf(CashflowAnnual)
};

export default CorporateCashflowTableComponent;
