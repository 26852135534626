import React from 'react';
import PropTypes from 'prop-types';

import { FieldDetail } from '../models/portfolio/field-detail'
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const LocationComponent = (props) => {
  const { resource, onHubClick, onWellClick, onLicenceClick, onBlockClick } = props;

  let hubName = resource.fiscalResource.hub.hubName;
  if (typeof onHubClick === 'function' && typeof resource.fiscalResource.hub.hubId === 'number' && resource.fiscalResource.hub.countryId !== 0) {
    const onClick = (e) => {
      e.preventDefault();
      onHubClick(resource.fiscalResource.hub.hubId);
    };
    hubName = <a href="/" onClick={onClick}>{hubName}</a>;
  }

  let wellName = resource.discoveryWell;
  if (typeof onWellClick === 'function' && typeof resource.discoveryWellId === 'number') {
    const onClick = (e) => {
      e.preventDefault();
      onWellClick(resource.discoveryWellId);
    };
    wellName = <a href="/" onClick={onClick}>{wellName}</a>;
  }

  let licence = resource.licence;
  if (typeof onLicenceClick === 'function' && typeof resource.licenceId === 'number') {
    const onClick = (e) => {
      e.preventDefault();
      onLicenceClick(resource.licenceId);
    };
    licence = <a href="/" onClick={onClick}>{licence}</a>;
  }

  let blocks = [];
  if (typeof onBlockClick === 'function' && Array.isArray(resource.blockList) && resource.blockList.length > 0 && resource.blockList.filter(obj => typeof obj === 'number').length === 0) {
    const renderBlock = (block, idx) => {
      const onClick = (e) => {
        e.preventDefault();
        onBlockClick(block.key);
      };
      const link = <a href="/" onClick={onClick}>{block.value}</a>;
      const comma = idx < resource.blockList.length - 1 ? ', ' : null;
      return <React.Fragment key={'block-id-' + idx}>{link}{comma}</React.Fragment>;
    };
    blocks = resource.blockList.map((obj, idx) => renderBlock(obj, idx));
  }

  let licences = [];
  if (typeof onLicenceClick === 'function' && Array.isArray(resource.licenceList) && resource.licenceList.length > 0 && resource.licenceList.filter(obj => typeof obj === 'number').length === 0) {
    const renderLicence = (licence, idx) => {
      const onClick = (e) => {
        e.preventDefault();
        onLicenceClick(licence.key);
      };
      const link = <a href="/" onClick={onClick}>{licence.value}</a>;
      const comma = idx < resource.licenceList.length - 1 ? ', ' : null;
      return <React.Fragment key={'licence-id-' + idx}>{link}{comma}</React.Fragment>;
    };
    licences = resource.licenceList.map((obj, idx) => renderLicence(obj, idx));
  }

  const getRows = () => {
    return (
      <React.Fragment>
        <tr>
          <td>Country</td>
          <td>{resource.fiscalResource.resourceCountry.countryName}</td>
        </tr>
        <tr>
          <td>Area</td>
          <td>{resource.fiscalResource.resourceArea.areaName}</td>
        </tr>
        <tr>
          <td>Licences</td>
          <td>{licences}</td>
        </tr>
        <tr>
          <td>Blocks</td>
          <td>{blocks}</td>
        </tr>
        <tr>
          <td>Discovery Well</td>
          <td>{wellName}</td>
        </tr>
        <tr>
          <td>Discovery Well Licence</td>
          <td>{licence}</td>
        </tr>
        <tr>
          <td>Production Hub</td>
          <td>{hubName}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent className="one-col-key-value">
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

LocationComponent.propTypes = {
  resource: PropTypes.instanceOf(FieldDetail).isRequired,
  //blocks: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.number, value: PropTypes.string }).isRequired).isRequired,
  //licences: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.number, value: PropTypes.string }).isRequired).isRequired,
  onHubClick: PropTypes.func,
  onWellClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func
};

export default LocationComponent;
