import React from 'react';
import PropTypes from 'prop-types';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './timeline-chart.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
);

const TimelineChart = (props) => {
  const { events } = props;

  events.sort((a, b) => a.year - b.year);

  var chartData = {
    datasets: []
  };

  // all assets on same scale to show comparison between amouns of time in each phase
  var minYear = Math.min(...events.map(function (x) { return x.year })) - 2;
  var maxYear = Math.max(...events.map(function (x) { return x.year })) + 2;

  events.forEach(function (eventItem) {
    chartData.datasets.push({
      label: eventItem.eventName,
      data: [{ x: eventItem.year, y: eventItem.yVal }],
      backgroundColor: eventItem.color,
      showLine: false,
      pointRadius: 10,
      pointHoverRadius: 10,
      pointHitRadius: 5
    });
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: {
          text: 'Year',
          display: false
        },

        ticks: {
          suggestedMin: minYear.toFixed(0).toLocaleString(),
          suggestedMax: maxYear.toFixed(0).toLocaleString(),
          precision: 0,
          callback: function (value, index, values) {
            return value.toFixed(0).toLocaleString();
          }
        }
      },
      y: {
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          suggestedMin: 0.99,
          suggestedMax: 1.01,
          display: false
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (tooltipItems) {
            return tooltipItems[0].parsed.x;
          },
          label: function (tooltipItem) {
            return tooltipItem.dataset.label;
          }
        }
      },
      legend: {
        display: true,
        position: 'bottom'
      }
    }
  };

  const chart = <Line data={chartData} options={chartOptions} />;

  return (
    <div className="timeline">{chart}</div>
  );
};

TimelineChart.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({
    year: PropTypes.number.isRequired,
    eventName: PropTypes.string.isRequired,
    yVal: PropTypes.number.isRequired,
  })).isRequired,
};

TimelineChart.defaultProps = {
  events: []
};

export { TimelineChart };