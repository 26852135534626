import React from 'react';
import PropTypes from 'prop-types';

import DetailsComponent from './details-component';
import LocationComponent from './location-component';
import ResourcesComponent from './resources-component';
import OwnersTableComponent from './owners-table-component';

import { DiscoveryDetail } from '../../../models/discovery-detail';

import MapComponent from '../../../components/fiscal/map/map-component';
import CompletedActiveWellsTableComponent from '../../shared/completed-active-wells-table-component';
import PlannedWellsTableComponent from '../../shared/planned-wells-table-component';
import { ContextLayerModel, latLng, searchResultType } from '../../../shared/helpers/leaflet/leaflet-helper';
import OwnersPieChartComponent from '../../../shared/components/owners-pie-chart/owners-pie-chart-component';
import TechnicalComponent from '../../../shared/components/resource-technical-component/technical-component';
import InitialReservesByParticipantChart from '../../../shared/components/initial-reserves-by-owner-chart/initial-reserves-by-participant-component';
import DetailViewGridLayoutComponent, { createSection100, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedComponent = (props) => {
  const { discovery } = props;
  const hasOwners = discovery.owners && discovery.owners.length > 0;
  const hasPlannedWells = discovery.plannedWells && discovery.plannedWells.length > 0;

  const leftPanel = (
    <React.Fragment>
      {createSection([
        { content: <LocationComponent resource={discovery} onBlockClick={props.onBlockClick} onLicenceClick={props.onLicenceClick} />, sectionType: sectionTypes.section50, title: 'Location', style: { marginRight: '5px' }  },
        { content: <ResourcesComponent resource={discovery} />, sectionType: sectionTypes.section50, title: 'Resources', style: { marginLeft: '5px' }  }
      ])}
      {createSection100(<DetailsComponent resource={discovery} onWellClick={props.onWellClick} />, 'Details')}
    </React.Fragment>
  );
  const rightPanel = <div style={{ height: '100%' }}><MapComponent geoserverUrl={props.geoserverUrl} contextLayers={props.contextLayers} center={latLng(discovery.shape.lat, discovery.shape.lng)} zoom={searchResultType.defaultZoomLevel(searchResultType.Discovery)} shapes={[discovery.shape]} /></div>;

  return (
    <DetailViewGridLayoutComponent>
      {createSection([
        { content: leftPanel, sectionType: sectionTypes.section50, noMarginVertical: true },
        { content: rightPanel, sectionType: sectionTypes.section50 }
      ])}
      {hasOwners &&
        createSection([
          { content: <div style={{ height: '200px' }}><OwnersPieChartComponent owners={discovery.owners} /></div>, sectionType: sectionTypes.section50, title: 'Participation' },
          { content: <div style={{ height: '250px' }}><InitialReservesByParticipantChart owners={discovery.owners} /></div>, sectionType: sectionTypes.section50, title: 'Net resources by participant' }
        ])}
      {hasOwners && discovery.owners && createSection100(<OwnersTableComponent owners={discovery.owners} onParticipantClick={props.onCorporateClick} />, '')}
      {createSection100(<TechnicalComponent resource={discovery} />, 'Technical')}
      {discovery.wells && createSection100(<CompletedActiveWellsTableComponent wells={discovery.wells} onWellClick={props.onWellClick} onParticipantClick={props.onCorporateClick} openReport={props.openReport} />, 'E&A wells')}
      {hasPlannedWells && createSection100(<PlannedWellsTableComponent plannedWells={discovery.plannedWells} onWellClick={props.onWellClick} onParticipantClick={props.onCorporateClick} onBlockClick={props.onBlockClick}/>, 'Planned E&A wells')}
    </DetailViewGridLayoutComponent>
  );
}

AdvancedComponent.propTypes = {
  discovery: PropTypes.instanceOf(DiscoveryDetail).isRequired,
  onBlockClick: PropTypes.func,
  onWellClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onCorporateClick: PropTypes.func,
  geoserverUrl: PropTypes.string.isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
  openReport: PropTypes.func
};

export default AdvancedComponent;
