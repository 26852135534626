import { objectToEnum } from '../../helpers/common';

const WellStatus = objectToEnum({
  Active: 1,
  Completed: 2,
  Completed_Dry: 3,
  Completed_Gas: 4,
  Completed_Gas_Cond: 5,
  Completed_Oil: 6,
  Completed_Oil_And_Gas: 7,
  Completed_Oil_Producer: 8,
  Completed_Producer: 9,
  Completed_Water: 10,
  Demoted: 11,
  Gas_Producer: 12,
  JAndA: 13,
  Oil_Producer: 14,
  PAndA: 15,
  PAndA_Dry: 16,
  PAndA_Gas: 17,
  PAndA_Gas_Cond: 18,
  PAndA_Gas_Shows: 19,
  PAndA_Oil: 20,
  PAndA_Oil_And_Gas: 21,
  PAndA_Oil_And_Gas_Cond: 22,
  PAndA_Oil_And_Gas_Shows: 23,
  PAndA_Oil_Shows: 24,
  Future: 25,
  Producer: 26,
  Suspended: 27,
  Suspended_Dry: 28,
  Suspended_Gas: 29,
  Suspended_Gas_Cond: 30,
  Suspended_Gas_Shows: 31,
  Suspended_Oil: 32,
  Suspended_Oil_And_Gas: 33,
  Suspended_Oil_And_Gas_Cond: 34,
  Suspended_Oil_And_Gas_Shows: 35,
  Suspended_Oil_Shows: 36,
  Suspended_Water_Injector: 37,
  Water_Injector: 38,
  Completed_Tight: 39,
  PAndA_Shows: 40,
  Suspended_Shows: 41,
  Planned: 42,
  Forecast: 43,
  Drill_or_Drop: 44
}, false);

export { WellStatus };