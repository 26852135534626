import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'reactstrap';

import { ReportsFilter, searchTypes } from './reports-filter';
import BaseViewSettingsComponent, { createSplitDiv, TextFieldComponent, SelectComponent, MultiSelectComponent, DatePickerComponent } from '../base/base-view-settings-component';

import { AppSetState } from '../../app/app-set-state';
import { KeyValuePair } from '../../models/key-value-pair';
import { appIcons, appIconSizes } from '../../app/app-icons';

class ReportsViewSettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      countries: [],
      fields: [],
      discoveries: [],
      quadrants: [],
      blocks: [],
      wells: [],
      subscriptions: [],
      categories: []
    };
    this.loadFromFilter(this.state);
  }

  componentDidMount() {
    const url = 'api/reports/filters/load/initial';
    const apiParam = { countryId: this.state.countryId, quadrant: this.state.quadrant, blockId: this.state.blockId };
    const onSuccess = (result) => {
      this.setState({
        companies: result.companies.map(obj => new KeyValuePair(obj)),
        countries: result.countries.map(obj => new KeyValuePair(obj)),
        fields: result.fields.map(obj => new KeyValuePair(obj)),
        discoveries: result.discoveries.map(obj => new KeyValuePair(obj)),
        quadrants: result.quadrants.map(obj => new KeyValuePair(obj)),
        blocks: result.blocks.map(obj => new KeyValuePair(obj)),
        wells: result.wells.map(obj => new KeyValuePair(obj)),
        subscriptions: result.subscriptions.map(obj => new KeyValuePair(obj)),
        categories: result.categories.map(obj => new KeyValuePair(obj))
      });
    };
    this.props.appSetState.api.post(url, apiParam, onSuccess);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter !== prevProps.filter) {
      this.setState(prevState => {
        this.loadFromFilter(prevState);
        return prevState;
      });
    }
  }

  loadFromFilter = (state) => {
    const { filter } = this.props;

    state.advancedSearch = filter.advancedSearch;
    state.searchType = filter.searchType;
    state.searchString = filter.searchString;
    state.companyId = filter.companyId;
    state.countryId = filter.countryId;
    state.fieldId = filter.fieldId;
    state.discoveryId = filter.discoveryId;
    state.quadrantId = filter.quadrantId;
    state.blockId = filter.blockId;
    state.wellId = filter.wellId;
    state.subscriptionIds = filter.subscriptionIds;
    state.categoryIds = filter.categoryIds;
    state.dateFrom = filter.dateFrom;
    state.dateTo = filter.dateTo;
  }

  _update = (closeFilters) => {
    const { filter } = this.props;

    filter.advancedSearch = this.state.advancedSearch;
    filter.searchType = this.state.searchType;
    filter.searchString = this.state.searchString;
    filter.companyId = this.state.companyId;
    filter.countryId = this.state.countryId;
    filter.fieldId = this.state.fieldId;
    filter.discoveryId = this.state.discoveryId;
    filter.quadrantId = this.state.quadrantId;
    filter.blockId = this.state.blockId;
    filter.wellId = this.state.wellId;
    filter.subscriptionIds = this.state.subscriptionIds;
    filter.categoryIds = this.state.categoryIds;
    filter.dateFrom = this.state.dateFrom;
    filter.dateTo = this.state.dateTo;

    this.props.onUpdate(closeFilters);
  };

  _clear = () => {
    this.props.filter.clear();
    this.loadFromFilter(this.state);
    this._update();
  }

  setCountryIdAndUpdate(countryId) {
    if (typeof countryId === 'number') {
      const url = 'api/reports/filters/load/countries';
      const onSuccess = (result) => {
        this.setState({
          fields: result.fields.map(obj => new KeyValuePair(obj)),
          discoveries: result.discoveries.map(obj => new KeyValuePair(obj)),
          quadrants: result.quadrants.map(obj => new KeyValuePair(obj)),
          blocks: [],
          wells: [],
          countryId: countryId,
          quadrantId: null,
          blockId: null,
          wellId: null
        });
      };
      this.props.appSetState.api.post(url, { countryId: countryId }, onSuccess, null, true);
    } else {
      this.setState({
        fields: [],
        discoveries: [],
        quadrants: [],
        blocks: [],
        wells: [],
        countryId: countryId
      });
    }
  }

  setQuadrantIdAndUpdate(quadrantId) {
    const { countryId } = this.state;
    if (typeof quadrantId === 'string') {
      const url = 'api/reports/filters/load/blocks';
      const onSuccess = (result) => {
        this.setState({
          blocks: result.blocks.map(obj => new KeyValuePair(obj)),
          wells: [],
          quadrantId: quadrantId,
          blockId: null,
          wellId: null
        });
      };
      this.props.appSetState.api.post(url, { countryId: countryId, quadrant: quadrantId }, onSuccess, null, true);
    } else {
      this.setState({
        blocks: [],
        wells: [],
        quadrantId: quadrantId
      } );
    }
  }

  setBlockIdAndUpdate(blockId) {
    if (typeof blockId === 'number') {
      const url = 'api/reports/filters/load/wells';
      const onSuccess = (result) => {
        this.setState({
          wells: result.wells.map(obj => new KeyValuePair(obj)),
          blockId: blockId,
          wellId: null
        });
      };
      this.props.appSetState.api.post(url, { blockId: blockId }, onSuccess, null, true);
    } else {
      this.setState({
        wells: [],
        blockId: blockId
      });
    }
  }

  onToggleAdvancedSearch = (e) => { e.preventDefault(); this.setState(prevState => { return { advancedSearch: !prevState.advancedSearch }; }) };
  onSearchStringChange = (value) => this.setState({ searchString: value });
  onCompanyIdChange = (value) => this.setState({ companyId: value });
  onCountryIdChange = (value) => this.setCountryIdAndUpdate(value);
  onFieldIdChange = (value) => this.setState({ fieldId: value });
  onDiscoveryIdChange = (value) => this.setState({ discoveryId: value });
  onQuadrantIdChange = (value) => this.setQuadrantIdAndUpdate(value);
  onBlockIdChange = (value) => this.setBlockIdAndUpdate(value);
  onWellIdChange = (value) => this.setState({ wellId: value });
  onSearchTypeChange = (value) => this.setState({ searchType: value });
  onSubscriptionIdsChange = (value) => this.setState({ subscriptionIds: value });
  onCategoryIdsChange = (value) => this.setState({ categoryIds: value });
  onDateFromChange = (value) => this.setState({ dateFrom: value });
  onDateToChange = (value) => this.setState({ dateTo: value });

  render() {
    const { advancedSearch, countryId, quadrantId, blockId } = this.state;
    const countryIdIsSet = ReportsFilter.CountryIdIsSet(countryId);
    const quadrantIdIsSet = ReportsFilter.QuadrantIdIsSet(quadrantId);
    const blockIdIsSet = ReportsFilter.BlockIdIsSet(blockId);

    const topDiv = advancedSearch
      ? (
        <React.Fragment>
          <SelectComponent label="Company" value={this.state.companyId} values={this.state.companies} onChange={this.onCompanyIdChange} />
          <SelectComponent label="Country" value={countryId} values={this.state.countries} onChange={this.onCountryIdChange} />
          <SelectComponent label={'Field' + (countryIdIsSet ? '' : ' (Select Country)')} value={this.state.fieldId} values={this.state.fields} onChange={this.onFieldIdChange} disabled={!countryIdIsSet} />
          <SelectComponent label={'Discovery' + (countryIdIsSet ? '' : ' (Select Country)')} value={this.state.discoveryId} values={this.state.discoveries} onChange={this.onDiscoveryIdChange} disabled={!countryIdIsSet} />
          <SelectComponent label={'Quadrant' + (countryIdIsSet ? '' : ' (Select Country)')} /*valueName="value"*/ value={this.state.quadrantId} values={this.state.quadrants} onChange={this.onQuadrantIdChange} disabled={!countryIdIsSet} />
          <SelectComponent label={'Block' + (countryIdIsSet ? (quadrantIdIsSet ? '' : ' (Select Quadrant)') : ' (Select Country)')} value={blockId} values={this.state.blocks} onChange={this.onBlockIdChange} disabled={!quadrantIdIsSet} />
          <SelectComponent label={'Well' + (countryIdIsSet ? (blockIdIsSet ? '' : ' (Select Block)') : ' (Select Country)')} value={this.state.wellId} values={this.state.wells} onChange={this.onWellIdChange} disabled={!blockIdIsSet} />
        </React.Fragment>
      )
      : <TextFieldComponent label="Search" value={this.state.searchString} onChange={this.onSearchStringChange} />;

    const renderSearchTypeButtons = () => {
      const searchTypesToShow = searchTypes.getValues(advancedSearch);
      return (
        <ButtonGroup style={{ width: '100%', marginTop: '8px', marginBottom: '12px' }}>
          {searchTypesToShow.map(obj => (
            <Button
              key={'search-type-type-button-' + obj}
              active={this.state.searchType === obj}
              onClick={() => this.onSearchTypeChange(obj)}
              style={{ width: (100 / searchTypesToShow.length) + '%' }}
            >
              {searchTypes.getDisplayName(obj)}
            </Button>
          ))}
        </ButtonGroup>
      );
    };

    return (
      <BaseViewSettingsComponent
        appSetState={this.props.appSetState}
        className="reports-view-settings-component"
        onUpdate={this._update}
        onClear={this._clear}
      >
        <div>
          <a href="/" onClick={this.onToggleAdvancedSearch}>
            <span style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '1px' }}>
              {appIcons.getIcon(advancedSearch ? appIcons.ToggleOn : appIcons.ToggleOff, appIconSizes.md)}
            </span>
            <span style={{ display: 'inline-block', verticalAlign: 'text-top', marginLeft: '1px' }}>
              {(advancedSearch ? 'Advanced' : 'Quick') + ' Search'}
            </span>
          </a>
        </div>
        <div>
          {topDiv}
          {renderSearchTypeButtons()}
          <MultiSelectComponent label="Report Subscription" value={this.state.subscriptionIds} values={this.state.subscriptions} onChange={this.onSubscriptionIdsChange} />
          <MultiSelectComponent label="Report Category" value={this.state.categoryIds} values={this.state.categories} onChange={this.onCategoryIdsChange} />
          {createSplitDiv(
            <DatePickerComponent label="Date From" value={this.state.dateFrom} maxDate={this.state.dateTo} onChange={this.onDateFromChange} />,
            <DatePickerComponent label="Date To" value={this.state.dateTo} minDate={this.state.dateFrom} onChange={this.onDateToChange} />
          )}
        </div>
      </BaseViewSettingsComponent>
    );
  }
}

ReportsViewSettingsComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  filter: PropTypes.instanceOf(ReportsFilter).isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default ReportsViewSettingsComponent;