import React from 'react';
import PropTypes from 'prop-types';

import './table-component.scss';

import { valueTypes } from '../../../../shared/helpers/value-handler';
import { FiscalResource } from '../../../../components/fiscal/models/portfolio/fiscal-resource';
import BaseTableComponent from '../../../../shared/components/detail-view-grid-layout/base-table-component';

const HubFieldsTableComponent = (props) => {
  const { resource, currentYear, discountRate, onFieldClick, onOperatorClick } = props;

  //<td>Remaining Discounted NPV{valueTypes.ValueOnly(valueTypes.usdBOE, discountRate)} ({valueTypes.UnitString(valueTypes.usdMM)})</td>

  const getAnnualByYear = (theArray, year) => {
    var result = theArray.filter(x => x.year === year);
    return result !== undefined && result !== null && result.length > 0 ? result[0] : null;
  };

  const getCombinedMboed = (row, year) => {
    var result = getAnnualByYear(row.resourceAnnuals, year);
    return result !== undefined && result !== null ? result.combinedMboed : 0;
  };

  const getOpexUSDMM = (row, year) => {
    var result = getAnnualByYear(row.cashflow.cashflowAnnuals, year);
    return result !== undefined && result !== null ? result.opexUsdMM : 0;
  };

  const getRow = (row, rowIndex) => {
    const key = 'row-' + rowIndex.toString();

    let resourceName = row.resourceName;
    if (typeof onFieldClick === 'function' && typeof row.resourceId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onFieldClick(row.resourceId);
      };
      resourceName = <a href="/" onClick={onClick}>{resourceName}</a>;
    }

    let operatorName = row.operatorName;
    if (typeof onOperatorClick === 'function' && typeof row.operatorId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onOperatorClick(row.operatorId);
      };
      operatorName = <a href="/" title="Show Details" onClick={onClick}>{operatorName}</a>;
    }

    return (
      <tr key={key}>
        <td style={{ width: '16%' }}>{resourceName}</td>
        <td style={{ width: '15%' }}>{operatorName}</td>
        <td style={{ width: '15%' }}>{row.status}</td>
        <td style={{ width: '6%' }}>{row.firstProductionYear}</td>
        <td style={{ width: '6%' }}>{row.lastProductionYear}</td>
        <td style={{ width: '6%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.remainingLiquidMmb)}</td>
        <td style={{ width: '6%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.remainingGasBcf)}</td>
        <td style={{ width: '6%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.remainingReservesMmboe)}</td>
        <td style={{ width: '6%' }}>{valueTypes.ValueOnly(valueTypes.generic1, getCombinedMboed(row, currentYear))}</td>
        <td style={{ width: '6%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.cashflow.remainingCAPEX)}</td>
        <td style={{ width: '6%' }}>{valueTypes.ValueOnly(valueTypes.generic1, getOpexUSDMM(row, currentYear))}</td>
        <td style={{ width: '6%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.cashflow.remainingNetPresetValue)}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Field</td>
          <td>Operator</td>
          <td>Development status</td>
          <td>First year of production</td>
          <td>COP year</td>
          <td>Remaining liquid ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
          <td>Remaining gas ({valueTypes.UnitString(valueTypes.bcf)})</td>
          <td>Remaining reserves ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
          <td>{currentYear} production ({valueTypes.UnitString(valueTypes.mboed)})</td>
          <td>Remaining capex ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{currentYear} opex ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>Remaining Discounted NPV ({valueTypes.UnitString(valueTypes.usdMM)})</td>
        </tr>
      </thead>
      <tbody>
        {resource.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
};

HubFieldsTableComponent.propTypes = {
  resource: PropTypes.arrayOf(PropTypes.instanceOf(FiscalResource)).isRequired,
  currentYear: PropTypes.number.isRequired,
  discountRate: PropTypes.number.isRequired,
  onFieldClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default HubFieldsTableComponent;
