import { searchResultType } from '../shared/helpers/leaflet/models/search-result-model';
import { BaseExcelExport } from "../shared/helpers/base-excel-export";
import { GridExcelExport as BlockGridExcelExport } from '../views/block/grid-excel-export';
import { CorporateGridExcelExport } from '../views/corporate/corporate-grid-excel-export';
import { GridExcelExport as DiscoveryGridExcelExport } from '../views/discovery/grid-excel-export';
import { GridExcelExport as FieldGridExcelExport } from '../views/field/grid-excel-export';
import { GridExcelExport as HubGridExcelExport } from '../views/hub/grid-excel-export';
import { GridExcelExport as ProspectGridExcelExport } from '../views/prospect/grid-excel-export';
import { GridExcelExport as WellGridExcelExport } from '../views/well/grid-excel-export';
import { GridExcelExport as LicenceGridExcelExport } from '../views/licence/grid-excel-export';
import { AppSetState } from "../app/app-set-state";

import { Block } from '../models/Block';
import { FasterPortfolioHubSummary } from '../components/fiscal/models/portfolio/faster-portfolio';
import { ProspectDataRow } from '../views/prospect/prospect-data-row';
import { Discovery } from '../models/Discovery';
import { FasterResourceSummary } from '../components/fiscal/models/portfolio/faster-portfolio';
import { LicenceDataRow } from '../views/licence/licence-data-row';
import { FasterPortfolioCorporateSummary } from '../components/fiscal/models/portfolio/faster-portfolio';

import { FieldFilter } from "../views/field/field-filter";
import { HubFilter } from "../views/hub/hub-filter";
import { WellFilter } from "../views/well/well-filter";
import { CorporateFilter } from '../views/corporate/corporate-filter';
import { ProspectFilter } from '../views/prospect/prospect-filter';
import { LicenceFilter } from '../views/licence/licence-filter';
import { DiscoveryFilter } from '../views/discovery/discovery-filter';
import { BlockFilter } from '../views/block/block-filter';

import { appViews } from "../app/app-views";

class SearchResultsExcelExport {
  constructor(appSetState, appView) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "SearchResultsExcelExport"');
    }

    if (appViews.isInvalid(appView)) {
      throw new Error('Invalid "appView" param supplied to "AppComponent.createViewSettings"');
    }

    this._appSetState = appSetState;
    this._view = appView; 
  }

  export = (searchResults) => {
    this._searchResults = searchResults;
    this._appSetState.api.execOrFailIfTrialUser(null, this.exporter);
  }

  exporter = async () => {
    this.errorLog = '';
    this.blockErrorLog = '';
    this.fieldErrorLog = '';
    this.prospectErrorLog = '';
    this.discoveryErrorLog = '';
    this.wellErrorLog = '';
    this.hubErrorLog = '';
    this.licenceErrorLog = '';
    this.corporateErrorLog = '';

    this.blockFinished = false;
    this.prospectFinished = false;
    this.discoveryFinished = false;
    this.fieldFinished = false;
    this.hubFinished = false;
    this.wellFinished = false;
    this.licenceFinished = false;
    this.corporateFinished = false;

    this._appSetState.loading.showLoading();
    await this.doExcelStuff(this._searchResults);
  }

  doExcelStuff = async (searchResults) => {
    this._start = Date.now();
    this.baseExcelExport = new BaseExcelExport();
    this.workbook = this.baseExcelExport.createWorkbook();

    this.getFields(searchResults);
    this.getBlocks(searchResults);
    this.getProspects(searchResults);
    this.getDiscoveries(searchResults);
    this.getHubs(searchResults);
    this.getWells(searchResults);
    this.getLicences(searchResults);
    this.getCorporates(searchResults);
  }

  checkForErrors = () => {
    this._appSetState.loading.hide();
    if (this.blockErrorLog !== '' || this.fieldErrorLog !== '' || this.prospectErrorLog !== '' || this.discoveryErrorLog !== '' || this.wellErrorLog !== '' ||
      this.hubErrorLog !== '' || this.licenceErrorLog !== '' || this.corporateErrorLog !== '') {
      this.ShowError();
      return true;
    }
    return false;
  }

  ShowError = () => {
    this.errorLog = (<div>{this.blockErrorLog}{this.fieldErrorLog}{this.prospectErrorLog}{this.discoveryErrorLog}{this.wellErrorLog}{this.hubErrorLog}{this.licenceErrorLog}{this.corporateErrorLog}</div>);
    this._appSetState.messageBox.showMessage(this.errorLog, 'Search Results Excel Export');
  }

  writeWorkbook = () => {
    if (this.blockFinished === true && this.prospectFinished === true && this.discoveryFinished === true &&
      this.fieldFinished === true && this.hubFinished === true &&
      this.wellFinished === true && this.licenceFinished === true && this.corporateFinished === true) {
      if (this.workbook.SheetNames !== null && this.workbook.SheetNames.length > 0) {
        this.baseExcelExport.writeWorkbook(this.workbook, this.baseExcelExport.getAtlas5WorkbookPrefix() + 'Search Resuts Export' + this.baseExcelExport.getAtlas5WorkbookSuffix(), null, this._appSetState.appValuation.valuation, this._appSetState.appValuation.baseValuation);
        this.checkForErrors();
      }
      else {
        if (!this.checkForErrors()) this._appSetState.messageBox.showMessage('This functionality is not available for your subscription level', 'Search Results Excel Export');
      }

      const millis = Date.now() - this._start;
      const seconds = millis / 1000;
      //console.log('seconds elapsed = ' + seconds);
    }
  };

  getBlocks = (searchResults) => {
    var excelBlockDataIds = searchResults.filter(obj => obj.type === searchResultType.Block).map(a => a.id);
    if (excelBlockDataIds !== null && excelBlockDataIds.length > 0) {
      var filter = new BlockFilter();
      filter.blockIds = excelBlockDataIds;

      const url = 'api/block/load-all-ids';

      const onSuccess = (result) => {
        var dataRows = Block.FromArray(result.dataRows);

        if (dataRows !== null && dataRows.length > 0) new BlockGridExcelExport().export(this._appSetState, dataRows, this.baseExcelExport, this.workbook, 'Blocks');
        this.blockFinished = true;
        this.writeWorkbook();
      };

      const onFail = (e) => {
        if (e.status !== 402) this.blockErrorLog = <div>There was a problem with the Blocks export.</div>;
        this._appSetState.track.exceptions(e);
        this.blockFinished = true;
        this.writeWorkbook();
      };

      this._appSetState.api.post(url, filter.toApiParam(), onSuccess, onFail, false, false);
    }
    else {
      this.blockFinished = true;
      this.writeWorkbook();
    }
  }

  getProspects = (searchResults) => {
    var excelProspectDataIds = searchResults.filter(obj => obj.type === searchResultType.Prospect).map(a => a.id);
    if (excelProspectDataIds !== null && excelProspectDataIds.length > 0) {
      var filter = new ProspectFilter();
      filter.prospectIds = excelProspectDataIds;

      const url = 'api/prospect/load-all-ids';

      const onSuccess = (result) => {
        var dataRows = ProspectDataRow.FromArray(result.dataRows);

        if (dataRows !== null && dataRows.length > 0) new ProspectGridExcelExport().export(this._appSetState, dataRows, this.baseExcelExport, this.workbook, 'Prospects');
        this.prospectFinished = true;
        this.writeWorkbook();
      };

      const onFail = (e) => {
        if (e.status !== 402) this.prospectErrorLog = <div>There was a problem with the Prospects export.</div>;
        this._appSetState.track.exceptions(e);
        this.prospectFinished = true;
        this.writeWorkbook();
      };

      this._appSetState.api.post(url, filter.toApiParam(), onSuccess, onFail, false, false);
    }
    else {
      this.prospectFinished = true;
      this.writeWorkbook();
    }
  }

  getDiscoveries = (searchResults) => {
    var excelDiscoveryDataIds = searchResults.filter(obj => obj.type === searchResultType.Discovery).map(a => a.id);
    if (excelDiscoveryDataIds !== null && excelDiscoveryDataIds.length > 0) {
      var filter = new DiscoveryFilter();
      filter.discoveryIds = excelDiscoveryDataIds;

      const url = 'api/discovery/load-all-ids';
      const onSuccess = (result) => {
        var dataRows = Discovery.FromArray(result.dataRows);
        const advancedUser = result.advancedUser === true;

        if (dataRows !== null && dataRows.length > 0) new DiscoveryGridExcelExport().export(this._appSetState, dataRows, advancedUser, this.baseExcelExport, this.workbook, 'Discoveries');
        this.discoveryFinished = true;
        this.writeWorkbook();
      };

      const onFail = (e) => {
        if (e.status !== 402) this.discoveryErrorLog = <div>There was a problem with the Discoveries export.</div>;
        this._appSetState.track.exceptions(e);
        this.discoveryFinished = true;
        this.writeWorkbook();
      };

      this._appSetState.api.post(url, filter.toApiParam(), onSuccess, onFail, false, false);
    }
    else {
      this.discoveryFinished = true;
      this.writeWorkbook();
    }
  }

  getFields = (searchResults) => {
    var excelFieldDataIds = searchResults.filter(obj => obj.type === searchResultType.Field).map(a => a.id);
    if (excelFieldDataIds !== null && excelFieldDataIds.length > 0) {
      var filter = new FieldFilter();
      filter.fieldIds = excelFieldDataIds;

      var advanced = false;
      var dataRows = null;

      const url = 'api/field/get-level';

      const onSuccess = (result) => {
        advanced = result.advanced === true;

        if (advanced) {
          new FieldGridExcelExport().advanceExport(this._appSetState, dataRows, filter, this.baseExcelExport, this.workbook, 'Field', FieldsFinishedWriteWorkbook);
        } else {
          const basicurl = 'api/field/portfolio/load-all?excel=true';

          const basicOnSuccess = (result) => {
            dataRows = FasterResourceSummary.FromArray(result.dataRows);
            if (dataRows !== null && dataRows.length > 0) {
              new FieldGridExcelExport().export(this._appSetState, dataRows, this.baseExcelExport, this.workbook, 'Field');
              this.fieldFinished = true;
              this.writeWorkbook();
            }
          }

          const basicOnFail = (e) => {
            if (e.status !== 402) this.fieldErrorLog = <div>There was a problem with the Fields export.</div>;
            this._appSetState.track.exceptions(e);
          }

          this._appSetState.api.post(basicurl, filter.toApiParam(), basicOnSuccess, basicOnFail, false, false);
        }
      };

      const onFail = (e) => {
        this.fieldErrorLog = <div>There was a problem with the Fields export.</div>;
        this._appSetState.track.exceptions(e);
      };

      const FieldsFinishedWriteWorkbook = () => {
        this.fieldFinished = true;
        this.writeWorkbook();
      }

      this._appSetState.api.post(url, null, onSuccess, onFail, false, false);
    }
    else {
      this.fieldFinished = true;
      this.writeWorkbook();
    }
  }

  getHubs = (searchResults) => {
    var excelHubDataIds = searchResults.filter(obj => obj.type === searchResultType.Hub).map(a => a.id);
    if (excelHubDataIds !== null && excelHubDataIds.length > 0) {
      var filter = new HubFilter();
      filter.hubIds = excelHubDataIds;

      const valuationId = this._appSetState.appValuation.valuation.variableId;
      const url = 'api/hub/portfolio/load-all?excel=true&valuationId=' + valuationId;
      const onSuccess = (result) => {
        var dataRows = FasterPortfolioHubSummary.FromArray(result.dataRows);

        if (dataRows !== null && dataRows.length > 0) new HubGridExcelExport().export(this._appSetState, dataRows, this.baseExcelExport, this.workbook, 'Production Hubs');
        this.hubFinished = true;
        this.writeWorkbook();
      };

      const onFail = (e) => {
        if (e.status !== 402) this.hubErrorLog = <div>There was a problem with the Hubs export.</div>;
        this._appSetState.track.exceptions(e);
        this.hubFinished = true;
        this.writeWorkbook();
      };

      this._appSetState.api.post(url, filter.toApiParam(), onSuccess, onFail, false, false);
    }
    else {
      this.hubFinished = true;
      this.writeWorkbook();
    }
  }

  getWells = (searchResults) => {
    var excelWellDataIds = searchResults.filter(obj => obj.type === searchResultType.Well).map(a => a.id);
    if (excelWellDataIds !== null && excelWellDataIds.length > 0) {
      var filter = new WellFilter();
      filter.wellIds = excelWellDataIds;

      const url = 'api/well/excel/load';
      const onSuccess = (result) => {
        var dataRows = result.excelDataRows;

        if (dataRows !== null && dataRows.length > 0) new WellGridExcelExport().mapExport(this._appSetState, dataRows, this.baseExcelExport, this.workbook, 'E&A Wells');
        this.wellFinished = true;
        this.writeWorkbook();
      };

      const onFail = (e) => {
        if (e.status !== 402) this.wellErrorLog = <div>There was a problem with the Well export.</div>;
        this._appSetState.track.exceptions(e);
        this.wellFinished = true;
        this.writeWorkbook();
      };

      this._appSetState.api.post(url, filter.toApiParam(), onSuccess, onFail, false, false);
    }
    else {
      this.wellFinished = true;
      this.writeWorkbook();
    }
  }

  getLicences = (searchResults) => {
    var excelLicenceDataIds = searchResults.filter(obj => obj.type === searchResultType.Licence).map(a => a.id);
    if (excelLicenceDataIds !== null && excelLicenceDataIds.length > 0) {
      var filter = new LicenceFilter();
      filter.licenceIds = excelLicenceDataIds;

      const url = 'api/licence/load-all-ids';

      const onSuccess = (result) => {
        var dataRows = LicenceDataRow.FromArray(result.dataRows);

        if (dataRows !== null && dataRows.length > 0) new LicenceGridExcelExport().export(this._appSetState, dataRows, this.baseExcelExport, this.workbook, 'Licences');
        this.licenceFinished = true;
        this.writeWorkbook();
      };

      const onFail = (e) => {
        if (e.status !== 402) this.licenceErrorLog = <div>There was a problem with the Licences export.</div>;
        this._appSetState.track.exceptions(e);
        this.licenceFinished = true;
        this.writeWorkbook();
      };

      this._appSetState.api.post(url, filter.toApiParam(), onSuccess, onFail, false, false);
    }
    else {
      this.licenceFinished = true;
      this.writeWorkbook();
    }
  }

  getCorporates = (searchResults) => {
    var excelCorporateDataIds = searchResults.filter(obj => obj.type === searchResultType.Corporate).map(a => a.id);
    if (excelCorporateDataIds !== null && excelCorporateDataIds.length > 0) {
      var filter = new CorporateFilter();
      filter.corporateIds = excelCorporateDataIds;

      const valuationId = this._appSetState.appValuation.valuation.variableId;
      const url = 'api/corporate/portfolio/load-all?excel=true&valuationId=' + valuationId

      const onSuccess = (result) => {
        var dataRows = FasterPortfolioCorporateSummary.FromArray(result.dataRows);

        if (dataRows !== null && dataRows.length > 0) new CorporateGridExcelExport().export(this._appSetState, dataRows, this.baseExcelExport, this.workbook, 'Companies');
        this.corporateFinished = true;
        this.writeWorkbook();
      };

      const onFail = (e) => {
        if (e.status !== 402) this.corporateErrorLog = <div>There was a problem with the Corporates export.</div>;
        this._appSetState.track.exceptions(e);
        this.corporateFinished = true;
        this.writeWorkbook();
      };

      this._appSetState.api.post(url, filter.toApiParam(), onSuccess, onFail, false, false);
    }
    else {
      this.corporateFinished = true;
      this.writeWorkbook();
    }
  }

}

export { SearchResultsExcelExport };

