import React from 'react';
import PropTypes from 'prop-types';
import { RevenueSummaryChart, RevenueSummaryChartAnnual } from './chart/revenue-summary-chart'
import { CashflowAnnual } from './models/portfolio/cashflow-annual'
import { CashflowType } from '../../shared/helpers/enums'

const RevenueSummaryChartComponent = (props) => {
  const { annual, cashflowType } = props;

  const getCashflow = (obj) => {
    switch (cashflowType) {
      case CashflowType.PreTax:
        return null; //new AdvancedCashflowChartAnnual(obj.year, obj.preTaxCashflowUsdMM, obj.cumulativePreTaxCashflowUsdMM);
      case CashflowType.PostTax:
        return null; //new AdvancedCashflowChartAnnual(obj.year, obj.postTaxCashflowUsdMM, obj.cumulativePostTaxCashflowUsdMM);
      case CashflowType.PostTaxDiscounted:
        return new RevenueSummaryChartAnnual(obj.year,
          obj.totalRevenueUsdMM,
          obj.developmentCapexUsdMM * -1,
          obj.opexUsdMM * -1,
          obj.liquidTariffUsdMM * -1,
          obj.gasTariffUsdMM * -1,
          obj.abexUsdMM * -1,
          obj.abexReliefUsdMM,
          obj.corporationTaxUsdMM * -1,
          obj.specialTaxUsdMM * -1,
          obj.postTaxCashflowUsdMM,
          obj.totalEmissionsChargeUsdMM * -1,
        );
      default: return null;
    }
  }

  const getCashflowTitle = () => {
    switch (cashflowType) {
      case CashflowType.PreTax:
        return 'Pre Tax Cashflow';
      case CashflowType.PostTax:
        return 'Post Tax Cashflow';
      case CashflowType.PostTaxDiscounted:
        return 'Discounted Post Tax Cashflow';
      default: return null;
    }
  }

  const getCumulativeCashflowTitle = () => {
    switch (cashflowType) {
      case CashflowType.PreTax:
        return 'Cumulative Pre Tax Cashflow';
      case CashflowType.PostTax:
        return 'Cumulative Post Tax Cashflow';
      case CashflowType.PostTaxDiscounted:
        return 'Annual Post Tax Cashflow';
      default: return null;
    }
  }

  var chartCashflow = annual.map(obj => getCashflow(obj));

  return (
    <div className="revenue-summary-chart-component">
      <RevenueSummaryChart
        cashflowData={chartCashflow}
        cumulativeCashflowTitle={getCumulativeCashflowTitle()} />
    </div>
  );
}

RevenueSummaryChartComponent.propTypes = {
  annual: PropTypes.arrayOf(PropTypes.instanceOf(CashflowAnnual)).isRequired,
  cashflowType: PropTypes.number.isRequired
};

export default RevenueSummaryChartComponent;
export { CashflowType };