import React from 'react';
import PropTypes from 'prop-types';
import { WgeSectorsRed, WgeAnalyticsGreen } from '../../scss/wge-colors';
import { valueTypes } from '../../helpers/value-handler';

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const InitialReservesByParticipantChart = (props) => {
  const { owners, isStacked } = props;

  const chartData = {
    labels: owners.map(obj => obj.name),
    datasets: [
      {
        label: 'Liquid',
        backgroundColor: WgeAnalyticsGreen,
        borderColor: WgeAnalyticsGreen,
        data: owners.map(obj => valueTypes.ValueOnly(valueTypes.generic1, obj.liquidMmb))
      },
      {
        label: 'Gas',
        backgroundColor: WgeSectorsRed,
        borderColor: WgeSectorsRed,
        data: owners.map(obj => valueTypes.ValueOnly(valueTypes.generic1, obj.gasMmboe))
      }
    ]
  };

  const chartOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: isStacked,
        title: {
          text: valueTypes.UnitString(valueTypes.mmmboe),
          display: true
        },
      },
      y: {
        grid: {
          drawOnChartArea: false,
        },
        title: {
          drawOnChartArea: false,
        },
        stacked: isStacked
      }
    },
    tooltips: { enabled: true },
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      }
    }
  };

  return (
    <Bar data={chartData} options={chartOptions} />
  );
}

InitialReservesByParticipantChart.propTypes = {
  owners: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    liquidMmb: PropTypes.number.isRequired,
    gasMmboe: PropTypes.number.isRequired,
  })).isRequired,
  isStacked: PropTypes.bool.isRequired
};

InitialReservesByParticipantChart.defaultProps = {
  owners: [],
  isStacked: true
};

export default InitialReservesByParticipantChart;
