import React from 'react';
import PropTypes from 'prop-types';

import { PlannedWellRow } from '../../../models/block-detail';

import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const PlannedWellsTableComponent = (props) => {
  const { wells, onWellClick, onBlockClick, onProspectClick, onDiscoveryClick, onFieldClick, onOperatorClick } = props;

  function createResourceLink(resource, idx) {
    const resourceName = resource.resourceName;
    const key = 'planned-wells-row-' + idx;

    if (typeof resource.resourceId === 'number') {
      let onClick = null;
      if (resource.isProspect && typeof onProspectClick === 'function') {
        onClick = onProspectClick;
      } else if (resource.isDiscovery && typeof onDiscoveryClick === 'function') {
        onClick = onDiscoveryClick;
      } else if (typeof onFieldClick === 'function') {
        onClick = onFieldClick;
      }
      if (onClick !== null) {
        return <a key={key} href="/" title="Show Details" onClick={(e) => { e.preventDefault(); onClick(resource.resourceId); }}>{resourceName}</a>;
      }
    }

    return <span key={key}>{resourceName}</span>;
  }

  function createResourceLinks(resources) {
    if (resources.length === 0) {
      return null;
    }

    const result = [];
    for (var loop = 0; loop < resources.length; loop++) {
      result.push(createResourceLink(resources[loop], loop))
      if (loop < resources.length - 1) {
        result.push(', ');
      }
    }
    return result;
  }

  const getRow = (row, rowIndex) => {
    let wellName = row.wellName;
    if (typeof onWellClick === 'function' && typeof row.wellId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onWellClick(row.wellId);
      };
      wellName = <a href="/" onClick={onClick}>{wellName}</a>;
    }

    let blockName = row.blockName;
    if (typeof onBlockClick === 'function' && typeof row.blockId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onBlockClick(row.blockId);
      };
      blockName = <a href="/" title="Show Details" onClick={onClick}>{blockName}</a>;
    }

    let operatorName = row.operatorName;
    if (typeof onOperatorClick === 'function' && typeof row.operatorId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onOperatorClick(row.operatorId);
      };
      operatorName = <a href="/" onClick={onClick}>{operatorName}</a>;
    }

    return (
      <tr key={'planned-well-row-' + rowIndex.toString()}>
        <td>{wellName}</td>
        <td>{createResourceLinks(row.resources)}</td>
        <td>{row.typeName}</td>
        <td>{operatorName}</td>
        <td>{row.estimatedTiming}</td>
        <td>{blockName}</td>
        <td>{row.statusName}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Well Name</td>
          <td>Associated Resources</td>
          <td>Well Type</td>
          <td>Operator</td>
          <td>Estimated Timing</td>
          <td>Block</td>
          <td>Well Status</td>
        </tr>
      </thead>
      <tbody>
        {wells.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
}

PlannedWellsTableComponent.propTypes = {
  wells: PropTypes.arrayOf(PropTypes.instanceOf(PlannedWellRow)).isRequired,
  onWellClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onProspectClick: PropTypes.func,
  onDiscoveryClick: PropTypes.func,
  onFieldClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default PlannedWellsTableComponent;
