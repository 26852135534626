import L from 'leaflet';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { faMinusSquare, faPlusSquare } from '@fortawesome/pro-light-svg-icons';

import { ContextLayerModel } from '../../models/context-layer-model';
import { SearchResultModel } from '../../models/search-result-model';
import { getIcon, addEventHandler } from '../../helpers/control-helper';

const EXPANDED_CLASS_NAME = 'expanded';
const COLLAPSED_CLASS_NAME = 'collapsed';
const SHOW_MORE_COUNT = 3;

class ResultGroup {
  constructor(contextLayerId, groupDisplayName, layerDisplayName, container, onResultLabelClick, onResultIconClick) {
    if (typeof contextLayerId !== 'number') {
      throw new Error('Invalid "contextLayerId" param supplied to "ResultGroup.ctor"');
    }
    if (typeof groupDisplayName !== 'string') {
      throw new Error('Invalid "groupDisplayName" param supplied to "ResultGroup.ctor"');
    }
    if (typeof layerDisplayName !== 'string') {
      throw new Error('Invalid "layerDisplayName" param supplied to "ResultGroup.ctor"');
    }
    if (typeof container !== 'object') {
      throw new Error('Invalid "container" param supplied to "ResultGroup.ctor"');
    }
    if (typeof onResultLabelClick !== 'function') {
      throw new Error('Invalid "onResultLabelClick" param supplied to "ResultGroup.ctor"');
    }
    if (typeof onResultIconClick !== 'function') {
      throw new Error('Invalid "onResultIconClick" param supplied to "ResultGroup.ctor"');
    }

    this._contextLayerId = contextLayerId;
    this._onResultLabelClick = onResultLabelClick;
    this._onResultIconClick = onResultIconClick;

    this._card = L.DomUtil.create('div', 'card', container);
    const cardHeader = L.DomUtil.create('div', 'card-header', this._card);
    const label = L.DomUtil.create('span', 'label-div', cardHeader);
    L.DomUtil.create('span', 'label-text', label).innerHTML = groupDisplayName + ' ' + layerDisplayName;
    this._countLabel = L.DomUtil.create('span', 'label-badge', label);
    this._collapseButton = L.DomUtil.create('span', 'btn-collapse', cardHeader);
    addEventHandler(this._collapseButton, 'click', () => this.collapsed = !this.collapsed);
    this._body = L.DomUtil.create('div', 'card-body ' + EXPANDED_CLASS_NAME, this._card);
    this._topDiv = L.DomUtil.create('div', 'top-results-div', this._body);
    this._remainingDiv = L.DomUtil.create('div', 'remaining-results-div', this._body);
    this._showMoreLess = L.DomUtil.create('div', 'show-more-less-div', this._body);
    addEventHandler(this._showMoreLess, 'click', () => this._showMoreClick());

    this._updateCollapsed();
    this.update([]);
  }

  get contextLayerId() { return this._contextLayerId; }

  get collapsed() {
    const result = L.DomUtil.hasClass(this._body, COLLAPSED_CLASS_NAME);
    return result;
  }
  set collapsed(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "' + this._objectClassName + '.collapsed.set"');
    }

    if (this.collapsed !== value) {
      if (value) {
        L.DomUtil.addClass(this._body, COLLAPSED_CLASS_NAME);
        L.DomUtil.removeClass(this._body, EXPANDED_CLASS_NAME);
      } else {
        L.DomUtil.addClass(this._body, EXPANDED_CLASS_NAME);
        L.DomUtil.removeClass(this._body, COLLAPSED_CLASS_NAME);
      }
      this._updateCollapsed();
    }
  }

  _renderResult(searchResult, container) {
    container = L.DomUtil.create('div', 'result-div', container);
    const label = L.DomUtil.create('span', 'result-label', container);
    label.innerHTML = searchResult.displayName;
    addEventHandler(label, 'click', () => this._onResultLabelClick(searchResult));
    if (searchResult.shapeModel !== undefined && searchResult.shapeModel !== null) {
      const icon = L.DomUtil.create('span', 'result-icon', container);
      icon.innerHTML = getIcon(faMapMarkerAlt, { size: 'sm', title: 'Show on map' });
      addEventHandler(icon, 'click', () => this._onResultIconClick(searchResult));
    }
  }

  update(searchResults) {
    if (!Array.isArray(searchResults) || searchResults.filter(obj => !(obj instanceof SearchResultModel)).length > 0) {
      throw new Error('Invalid "searchResults" supplied to "ResultGroup..update"');
    }
    if (searchResults.length > 0) {
      this._countLabel.innerHTML = searchResults.length;
      this._topDiv.innerHTML = '';
      this._remainingDiv.innerHTML = '';
      this._showMoreLess.innerHTML = '';

      const remainingResults = searchResults.splice(SHOW_MORE_COUNT);

      searchResults.forEach(obj => this._renderResult(obj, this._topDiv));
      if (remainingResults.length > 0) {
        L.DomUtil.setClass(this._remainingDiv, 'remaining-results-div ' + COLLAPSED_CLASS_NAME)
        remainingResults.forEach(obj => this._renderResult(obj, this._remainingDiv));
        this._showMoreLess.innerHTML = 'Show More...';
      }

      this._card.style.display = 'block';
    } else {
      this._card.style.display = 'none';
    }
  }

  _updateCollapsed() {
    let iconDef = null;
    let title = null
    if (this.collapsed) {
      iconDef = faPlusSquare;
      title = 'Expand';
    } else {
      iconDef = faMinusSquare;
      title = 'Collapse';
    }
    this._collapseButton.innerHTML = getIcon(iconDef, { title: title });
  }

  _showMoreClick() {
    const collapsed = L.DomUtil.hasClass(this._remainingDiv, COLLAPSED_CLASS_NAME);
    if (collapsed) {
      L.DomUtil.addClass(this._remainingDiv, EXPANDED_CLASS_NAME);
      L.DomUtil.removeClass(this._remainingDiv, COLLAPSED_CLASS_NAME);
      this._showMoreLess.innerHTML = 'Show Less...';
    } else {
      L.DomUtil.addClass(this._remainingDiv, COLLAPSED_CLASS_NAME);
      L.DomUtil.removeClass(this._remainingDiv, EXPANDED_CLASS_NAME);
      this._showMoreLess.innerHTML = 'Show More...';
    }
  }

  static FromContextLayerModel(contextLayer, container, onResultLabelClick, onResultIconClick) {
    if (!(contextLayer instanceof ContextLayerModel)) {
      throw new Error('Invalid "contextLayer" param supplied to "ResultGroup.FromContextLayerModel"');
    }

    return new ResultGroup(contextLayer.contextLayerId, contextLayer.groupDisplayName, contextLayer.layerDisplayName,
      container, onResultLabelClick, onResultIconClick);
  }
}

export { ResultGroup };