import React from 'react';
import PropTypes from 'prop-types';

import { HubFilter } from './hub-filter';

import BaseViewSettingsComponent, { createSplitDiv, FollowMapComponent, NumericFieldComponent, MultiSelectComponent, DatePickerComponent } from '../base/base-view-settings-component';

import { AppSetState } from '../../app/app-set-state';
import { KeyValuePair } from '../../models/key-value-pair';
import { valueTypes } from '../../shared/helpers/value-handler';

class HubViewSettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hubs: [],
      countries: [],
      areas: [],
      operators: [],
      participants: [],
      liquidExports: [],
      gasExports: [],
      developmentStatuses: [],
      electrificationStatuses: []
    };
    this.loadFromFilter(this.state);
  }

  componentDidMount() {
    const url = 'api/hub/filters/load';
    const onSuccess = (result) => {
      this.setState({
        hubs: KeyValuePair.FromArray(result.hubs),
        countries: KeyValuePair.FromArray(result.countries),
        areas: KeyValuePair.FromArray(result.areas),
        operators: KeyValuePair.FromArray(result.operators),
        participants: KeyValuePair.FromArray(result.participants),
        liquidExports: KeyValuePair.FromArray(result.liquidExports),
        gasExports: KeyValuePair.FromArray(result.gasExports),
        developmentStatuses: KeyValuePair.FromArray(result.developmentStatuses),
        electrificationStatuses: KeyValuePair.FromArray(result.electrificationStatuses)
      });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter !== prevProps.filter) {
      this.setState(prevState => {
        this.loadFromFilter(prevState);
        return prevState;
      });
    }
  }

  loadFromFilter = (state) => {
    const { filter } = this.props;

    state.followMap = filter.followMap;
    state.hubIds = filter.hubIds;
    state.countryIds = filter.countryIds;
    state.areaIds = filter.areaIds;
    state.operatorIds = filter.operatorIds;
    state.participantIds = filter.participantIds;
    state.liquidExportIds = filter.liquidExportIds;
    state.gasExportIds = filter.gasExportIds;
    state.minRemainingReserves = filter.minRemainingReserves;
    state.maxRemainingReserves = filter.maxRemainingReserves;
    state.minProduction = filter.minProduction;
    state.maxProduction = filter.maxProduction;
    state.copStartYear = filter.copStartYear;
    state.copEndYear = filter.copEndYear;
    state.developmentStatusIds = filter.developmentStatusIds;
    state.electrificationStatusIds = filter.electrificationStatusIds;
  }

  _update = (closeFilters) => {
    const { filter } = this.props;

    filter.followMap = this.state.followMap;
    filter.hubIds = this.state.hubIds;
    filter.countryIds = this.state.countryIds;
    filter.areaIds = this.state.areaIds;
    filter.operatorIds = this.state.operatorIds;
    filter.participantIds = this.state.participantIds;
    filter.liquidExportIds = this.state.liquidExportIds;
    filter.gasExportIds = this.state.gasExportIds;
    filter.minRemainingReserves = this.state.minRemainingReserves;
    filter.maxRemainingReserves = this.state.maxRemainingReserves;
    filter.minProduction = this.state.minProduction;
    filter.maxProduction = this.state.maxProduction;
    filter.copStartYear = this.state.copStartYear;
    filter.copEndYear = this.state.copEndYear;
    filter.developmentStatusIds = this.state.developmentStatusIds;
    filter.electrificationStatusIds = this.state.electrificationStatusIds;

    filter.clearInvalidValues();

    this.props.onUpdate(closeFilters);
  };

  _clear = () => {
    this.props.filter.clear();
    this.loadFromFilter(this.state);
    this._update();
  }

  getYearFromDate = (value) => {
    const result = value instanceof Date ? value.getFullYear() : null;
    return result;
  }

  onToggleFollowMap = () => this.setState(prevState => { return { followMap: !prevState.followMap }; });
  onHubIdsChange = (value) => this.setState({ hubIds: value });
  onCountryIdsChange = (value) => this.setState({ countryIds: value });
  onAreaIdsChange = (value) => this.setState({ areaIds: value });
  onOperatorIdsChange = (value) => this.setState({ operatorIds: value });
  onParticipantIdsChange = (value) => this.setState({ participantIds: value });
  onLiquidExportIdsChange = (value) => this.setState({ liquidExportIds: value });
  onGasExportIdsChange = (value) => this.setState({ gasExportIds: value });
  onMinRemainingReservesChange = (value) => this.setState({ minRemainingReserves: value });
  onMaxRemainingReservesChange = (value) => this.setState({ maxRemainingReserves: value });
  onMinProductionChange = (value) => this.setState({ minProduction: value });
  onMaxProductionChange = (value) => this.setState({ maxProduction: value });
  onCopStartYearChange = (value) => this.setState({ copStartYear: this.getYearFromDate(value) });
  onCopEndYearChange = (value) => this.setState({ copEndYear: this.getYearFromDate(value) });
  onDevelopmentStatusIdsChange = (value) => this.setState({ developmentStatusIds: value });
  onElectrificationStatusIdsChange = (value) => this.setState({ electrificationStatusIds: value });

  render() {
    const currYear = this.props.currentYear === undefined ? new Date().getFullYear() : this.props.currentYear;
    const copStartYear = this.state.copStartYear === null ? null : new Date(this.state.copStartYear, 1, 1);
    const copEndYear = this.state.copEndYear === null ? null : new Date(this.state.copEndYear, 1, 1)

    return (
      <BaseViewSettingsComponent
        appSetState={this.props.appSetState}
        className="field-view-settings-component"
        onUpdate={this._update}
        onClear={this._clear}
      >
        <FollowMapComponent value={this.state.followMap} enabled={this.props.followMapEnabled} onClick={this.onToggleFollowMap} />
        <MultiSelectComponent label="Production Hub" value={this.state.hubIds} values={this.state.hubs} onChange={this.onHubIdsChange} />
        <MultiSelectComponent label="Country" value={this.state.countryIds} values={this.state.countries} onChange={this.onCountryIdsChange} />
        <MultiSelectComponent label="Area" value={this.state.areaIds} values={this.state.areas} onChange={this.onAreaIdsChange} />
        <MultiSelectComponent label="Operator" value={this.state.operatorIds} values={this.state.operators} onChange={this.onOperatorIdsChange} />
        <MultiSelectComponent label="Participant" value={this.state.participantIds} values={this.state.participants} onChange={this.onParticipantIdsChange} />
        <MultiSelectComponent label="Liquid Export" value={this.state.liquidExportIds} values={this.state.liquidExports} onChange={this.onLiquidExportIdsChange} />
        <MultiSelectComponent label="Gas Export" value={this.state.gasExportIds} values={this.state.gasExports} onChange={this.onGasExportIdsChange} />
        <MultiSelectComponent label="Development Status" value={this.state.developmentStatusIds} values={this.state.developmentStatuses} onChange={this.onDevelopmentStatusIdsChange} />
        <MultiSelectComponent label="Electrification Status" value={this.state.electrificationStatusIds} values={this.state.electrificationStatuses} onChange={this.onElectrificationStatusIdsChange} />

        {createSplitDiv(
          <DatePickerComponent label="Min" views={["year"]} format="YYYY" value={copStartYear} maxDate={copEndYear} onChange={this.onCopStartYearChange} />,
          <DatePickerComponent label="Max" views={["year"]} format="YYYY" value={copEndYear} minDate={copStartYear} onChange={this.onCopEndYearChange} />,
          'COP Year'
        )}

        {createSplitDiv(
          <NumericFieldComponent label="Min" value={this.state.minRemainingReserves} max={this.state.maxRemainingReserves} onChange={this.onMinRemainingReservesChange} type="number" />,
          <NumericFieldComponent label="Max" value={this.state.maxRemainingReserves} min={this.state.minRemainingReserves} onChange={this.onMaxRemainingReservesChange} type="number" />,
          'Remaining Reserves (' + valueTypes.UnitString(valueTypes.mmmboe) + ')'
        )}
        {createSplitDiv(
          <NumericFieldComponent label={'Min'} value={this.state.minProduction} max={this.state.maxProduction} onChange={this.onMinProductionChange} type="number" />,
          <NumericFieldComponent label={'Max'} value={this.state.maxProduction} min={this.state.minProduction} onChange={this.onMaxProductionChange} type="number" />,
          currYear + ' Production (' + valueTypes.UnitString(valueTypes.mboed) + ')'
        )}
      </BaseViewSettingsComponent>
    );
  }
}

HubViewSettingsComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  filter: PropTypes.instanceOf(HubFilter).isRequired,
  onUpdate: PropTypes.func.isRequired,
  followMapEnabled: PropTypes.bool.isRequired,
  currentYear: PropTypes.number.isRequired
};

export default HubViewSettingsComponent;
