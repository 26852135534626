import { valueTypes } from '../shared/helpers/value-handler';
import { MapShapeBorder } from '../shared/scss/app-colors';
import { PolygonsModel, PolygonModel } from '../shared/helpers/leaflet/models/polygons-model';

class FieldValuationComparison {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.resourceId !== 'number') { throw new Error('Invalid obj.resourceId param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.hubId !== 'number') { throw new Error('Invalid obj.hubId param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.resourceName !== 'string') { throw new Error('Invalid obj.resourceName param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.hubName !== 'string') { throw new Error('Invalid obj.hubName param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.hubCountryId !== 'number') { throw new Error('Invalid obj.hubCountryId param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.operatorName !== 'string') { throw new Error('Invalid obj.operatorName param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.operatorId !== 'number') { throw new Error('Invalid obj.operatorId param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.countryId !== 'number') { throw new Error('Invalid obj.countryId param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.countryName !== 'string') { throw new Error('Invalid obj.countryName param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.status !== 'string') { throw new Error('Invalid obj.status param supplied to FieldValuationComparison.ctor'); }
    if (!Array.isArray(obj.shapes)) { throw new Error('Invalid obj.shapes param supplied to FieldValuationComparison.ctor'); }

    if (typeof obj.valuationOneCOPYear !== 'number') { throw new Error('Invalid obj.valuationOneCOPYear param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.valuationTwoCOPYear !== 'number') { throw new Error('Invalid obj.valuationTwoCOPYear param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.copYearDifference !== 'number') { throw new Error('Invalid obj.copYearDifference param supplied to FieldValuationComparison.ctor'); }

    if (typeof obj.valuationOneRemainingLiquidMMB !== 'number') { throw new Error('Invalid obj.valuationOneRemainingLiquidMMB param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.valuationTwoRemainingLiquidMMB !== 'number') { throw new Error('Invalid obj.valuationTwoRemainingLiquidMMB param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.remainingLiquidDifferenceMMB !== 'number') { throw new Error('Invalid obj.remainingLiquidDifferenceMMB param supplied to FieldValuationComparison.ctor'); }

    if (typeof obj.valuationOneRemainingGasBCF !== 'number') { throw new Error('Invalid obj.valuationOneRemainingGasBCF param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.valuationTwoRemainingGasBCF !== 'number') { throw new Error('Invalid obj.valuationTwoRemainingGasBCF param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.remainingGasDifferenceBCF !== 'number') { throw new Error('Invalid obj.remainingGasDifferenceBCF param supplied to FieldValuationComparison.ctor'); }

    if (typeof obj.valuationOneRemainingReservesMMBOE !== 'number') { throw new Error('Invalid obj.valuationOneRemainingReservesMMBOE param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.valuationTwoRemainingReservesMMBOE !== 'number') { throw new Error('Invalid obj.valuationTwoRemainingReservesMMBOE param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.remainingReservesDifferenceMMBOE !== 'number') { throw new Error('Invalid obj.remainingReservesDifferenceMMBOE param supplied to FieldValuationComparison.ctor'); }

    if (typeof obj.valuationOneRemainingNPVUSDMM !== 'number') { throw new Error('Invalid obj.valuationOneRemainingNPVUSDMM param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.valuationTwoRemainingNPVUSDMM !== 'number') { throw new Error('Invalid obj.valuationTwoRemainingNPVUSDMM param supplied to FieldValuationComparison.ctor'); }
    if (typeof obj.remainingNPVDifferenceUSDMM !== 'number') { throw new Error('Invalid obj.remainingNPVDifferenceUSDMM param supplied to FieldValuationComparison.ctor'); }

    this.resourceId = obj.resourceId;
    this.hubId = obj.hubId;
    this.resourceName = obj.resourceName;
    this.hubName = obj.hubName;
    this.hubCountryId = obj.hubCountryId;
    this.operatorName = obj.operatorName;
    this.operatorId = obj.operatorId;
    this.countryId = obj.countryId;
    this.countryName = obj.countryName;
    this.status = obj.status;
    this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });

    this.valuationOneCOPYear = obj.valuationOneCOPYear;
    this.valuationTwoCOPYear = obj.valuationTwoCOPYear;
    this.copYearDifference = obj.copYearDifference;

    this.valuationOneRemainingLiquidMMB = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationOneRemainingLiquidMMB);
    this.valuationTwoRemainingLiquidMMB = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationTwoRemainingLiquidMMB);
    this.remainingLiquidDifferenceMMB = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingLiquidDifferenceMMB);

    this.valuationOneRemainingGasBCF = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationOneRemainingGasBCF);
    this.valuationTwoRemainingGasBCF = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationTwoRemainingGasBCF);
    this.remainingGasDifferenceBCF = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingGasDifferenceBCF);

    this.valuationOneRemainingReservesMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationOneRemainingReservesMMBOE);
    this.valuationTwoRemainingReservesMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationTwoRemainingReservesMMBOE);
    this.remainingReservesDifferenceMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingReservesDifferenceMMBOE);

    this.valuationOneRemainingNPVUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationOneRemainingNPVUSDMM);
    this.valuationTwoRemainingNPVUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationTwoRemainingNPVUSDMM);
    this.remainingNPVDifferenceUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingNPVDifferenceUSDMM);

  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "FieldValuationComparison.FromArray"');
    }

    const result = array.map(obj => new FieldValuationComparison(obj));
    return result;
  }
}

class HubValuationComparison {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.hubId !== 'number') { throw new Error('Invalid obj.hubId param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.hubName !== 'string') { throw new Error('Invalid obj.hubName param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.countryName !== 'string') { throw new Error('Invalid obj.countryName param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.operatorName !== 'string') { throw new Error('Invalid obj.operatorName param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.operatorId !== 'number') { throw new Error('Invalid obj.operatorId param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.developmentStatus !== 'string') { throw new Error('Invalid obj.developmentStatus param supplied to HubValuationComparison.ctor'); }
    if (!Array.isArray(obj.shapes)) { throw new Error('Invalid obj.shapes param supplied to HubValuationComparison.ctor'); }

    if (typeof obj.valuationOneCOPYear !== 'number') { throw new Error('Invalid obj.valuationOneCOPYear param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.valuationTwoCOPYear !== 'number') { throw new Error('Invalid obj.valuationTwoCOPYear param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.copYearDifference !== 'number') { throw new Error('Invalid obj.copYearDifference param supplied to HubValuationComparison.ctor'); }

    if (typeof obj.valuationOneRemainingLiquidMMB !== 'number') { throw new Error('Invalid obj.valuationOneRemainingLiquidMMB param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.valuationTwoRemainingLiquidMMB !== 'number') { throw new Error('Invalid obj.valuationTwoRemainingLiquidMMB param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.remainingLiquidDifferenceMMB !== 'number') { throw new Error('Invalid obj.remainingLiquidDifferenceMMB param supplied to HubValuationComparison.ctor'); }

    if (typeof obj.valuationOneRemainingGasBCF !== 'number') { throw new Error('Invalid obj.valuationOneRemainingGasBCF param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.valuationTwoRemainingGasBCF !== 'number') { throw new Error('Invalid obj.valuationTwoRemainingGasBCF param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.remainingGasDifferenceBCF !== 'number') { throw new Error('Invalid obj.remainingGasDifferenceBCF param supplied to HubValuationComparison.ctor'); }

    if (typeof obj.valuationOneRemainingReservesMMBOE !== 'number') { throw new Error('Invalid obj.valuationOneRemainingReservesMMBOE param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.valuationTwoRemainingReservesMMBOE !== 'number') { throw new Error('Invalid obj.valuationTwoRemainingReservesMMBOE param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.remainingReservesDifferenceMMBOE !== 'number') { throw new Error('Invalid obj.remainingReservesDifferenceMMBOE param supplied to HubValuationComparison.ctor'); }

    if (typeof obj.valuationOneRemainingUndiscountedPreTaxCashflowUSDMM !== 'number') { throw new Error('Invalid obj.valuationOneRemainingUndiscountedPreTaxCashflowUSDMM param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.valuationTwoRemainingUndiscountedPreTaxCashflowUSDMM !== 'number') { throw new Error('Invalid obj.valuationTwoRemainingUndiscountedPreTaxCashflowUSDMM param supplied to HubValuationComparison.ctor'); }
    if (typeof obj.remainingUndiscountedPreTaxCashflowDifferenceUSDMM !== 'number') { throw new Error('Invalid obj.remainingUndiscountedPreTaxCashflowDifferenceUSDMM param supplied to HubValuationComparison.ctor'); }

    this.hubId = obj.hubId;
    this.hubName = obj.hubName;
    this.countryName = obj.countryName;
    this.operatorName = obj.operatorName;
    this.operatorId = obj.operatorId;
    this.developmentStatus = obj.developmentStatus;
    this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });

    this.valuationOneCOPYear = obj.valuationOneCOPYear;
    this.valuationTwoCOPYear = obj.valuationTwoCOPYear;
    this.copYearDifference = obj.copYearDifference;

    this.valuationOneRemainingLiquidMMB = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationOneRemainingLiquidMMB);
    this.valuationTwoRemainingLiquidMMB = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationTwoRemainingLiquidMMB);
    this.remainingLiquidDifferenceMMB = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingLiquidDifferenceMMB);

    this.valuationOneRemainingGasBCF = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationOneRemainingGasBCF);
    this.valuationTwoRemainingGasBCF = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationTwoRemainingGasBCF);
    this.remainingGasDifferenceBCF = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingGasDifferenceBCF);

    this.valuationOneRemainingReservesMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationOneRemainingReservesMMBOE);
    this.valuationTwoRemainingReservesMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationTwoRemainingReservesMMBOE);
    this.remainingReservesDifferenceMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingReservesDifferenceMMBOE);

    this.valuationOneRemainingUndiscountedPreTaxCashflowUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationOneRemainingUndiscountedPreTaxCashflowUSDMM);
    this.valuationTwoRemainingUndiscountedPreTaxCashflowUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationTwoRemainingUndiscountedPreTaxCashflowUSDMM);
    this.remainingUndiscountedPreTaxCashflowDifferenceUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingUndiscountedPreTaxCashflowDifferenceUSDMM);
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "HubValuationComparison.FromArray"');
    }

    const result = array.map(obj => new HubValuationComparison(obj));
    return result;
  }
}

class CorporateValuationComparison {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to CorporateValuationComparison.ctor'); }
    if (typeof obj.corporateId !== 'number') { throw new Error('Invalid obj.corporateId param supplied to CorporateValuationComparison.ctor'); }
    if (typeof obj.corporateName !== 'string') { throw new Error('Invalid obj.corporateName param supplied to CorporateValuationComparison.ctor'); }
    if (!Array.isArray(obj.shapes)) { throw new Error('Invalid obj.shapes param supplied to CorporateValuationComparison.ctor'); }

    if (typeof obj.valuationOneRemainingLiquidMMB !== 'number') { throw new Error('Invalid obj.valuationOneRemainingLiquidMMB param supplied to CorporateValuationComparison.ctor'); }
    if (typeof obj.valuationTwoRemainingLiquidMMB !== 'number') { throw new Error('Invalid obj.valuationTwoRemainingLiquidMMB param supplied to CorporateValuationComparison.ctor'); }
    if (typeof obj.remainingLiquidDifferenceMMB !== 'number') { throw new Error('Invalid obj.remainingLiquidDifferenceMMB param supplied to CorporateValuationComparison.ctor'); }

    if (typeof obj.valuationOneRemainingGasBCF !== 'number') { throw new Error('Invalid obj.valuationOneRemainingGasBCF param supplied to CorporateValuationComparison.ctor'); }
    if (typeof obj.valuationTwoRemainingGasBCF !== 'number') { throw new Error('Invalid obj.valuationTwoRemainingGasBCF param supplied to CorporateValuationComparison.ctor'); }
    if (typeof obj.remainingGasDifferenceBCF !== 'number') { throw new Error('Invalid obj.remainingGasDifferenceBCF param supplied to CorporateValuationComparison.ctor'); }

    if (typeof obj.valuationOneRemainingReservesMMBOE !== 'number') { throw new Error('Invalid obj.valuationOneRemainingReservesMMBOE param supplied to CorporateValuationComparison.ctor'); }
    if (typeof obj.valuationTwoRemainingReservesMMBOE !== 'number') { throw new Error('Invalid obj.valuationTwoRemainingReservesMMBOE param supplied to CorporateValuationComparison.ctor'); }
    if (typeof obj.remainingReservesDifferenceMMBOE !== 'number') { throw new Error('Invalid obj.remainingReservesDifferenceMMBOE param supplied to CorporateValuationComparison.ctor'); }

    if (typeof obj.valuationOneRemainingUndiscountedPreTaxCashflowUSDMM !== 'number') { throw new Error('Invalid obj.valuationOneRemainingUndiscountedPreTaxCashflowUSDMM param supplied to CorporateValuationComparison.ctor'); }
    if (typeof obj.valuationTwoRemainingUndiscountedPreTaxCashflowUSDMM !== 'number') { throw new Error('Invalid obj.valuationTwoRemainingUndiscountedPreTaxCashflowUSDMM param supplied to CorporateValuationComparison.ctor'); }
    if (typeof obj.remainingUndiscountedPreTaxCashflowDifferenceUSDMM !== 'number') { throw new Error('Invalid obj.remainingUndiscountedPreTaxCashflowDifferenceUSDMM param supplied to CorporateValuationComparison.ctor'); }

    this.corporateId = obj.corporateId;
    this.corporateName = obj.corporateName;
    this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });

    this.valuationOneRemainingLiquidMMB = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationOneRemainingLiquidMMB);
    this.valuationTwoRemainingLiquidMMB = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationTwoRemainingLiquidMMB);
    this.remainingLiquidDifferenceMMB = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingLiquidDifferenceMMB);

    this.valuationOneRemainingGasBCF = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationOneRemainingGasBCF);
    this.valuationTwoRemainingGasBCF = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationTwoRemainingGasBCF);
    this.remainingGasDifferenceBCF = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingGasDifferenceBCF);

    this.valuationOneRemainingReservesMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationOneRemainingReservesMMBOE);
    this.valuationTwoRemainingReservesMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationTwoRemainingReservesMMBOE);
    this.remainingReservesDifferenceMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingReservesDifferenceMMBOE);

    this.valuationOneRemainingUndiscountedPreTaxCashflowUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationOneRemainingUndiscountedPreTaxCashflowUSDMM);
    this.valuationTwoRemainingUndiscountedPreTaxCashflowUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.valuationTwoRemainingUndiscountedPreTaxCashflowUSDMM);
    this.remainingUndiscountedPreTaxCashflowDifferenceUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingUndiscountedPreTaxCashflowDifferenceUSDMM);
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "CorporateValuationComparison.FromArray"');
    }

    const result = array.map(obj => new CorporateValuationComparison(obj));
    return result;
  }
}

export { FieldValuationComparison, HubValuationComparison, CorporateValuationComparison };
