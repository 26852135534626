import React from 'react';
import PropTypes from 'prop-types';
import { FasterPortfolio, FieldDetail } from '../../../components/fiscal/models/portfolio/faster-portfolio';
import RevenueSummaryChartComponent, { CashflowType } from '../../../components/fiscal/revenue-summary-chart-component';
import FieldCashflowAssumptionsComponent from '../../../components/fiscal/fact-components/field-cashflow-assumptions-component';
import { createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import AdvancedFieldCashflowTableComponent from './advanced-field-cashflow-table-component'

const AdvancedCashflowComponent = (props) => {
  const { resource, portfolio } = props;

  const cashflowChart = <div style={{ height: '400px' }}><RevenueSummaryChartComponent annual={resource.fiscalResource.cashflow.cashflowAnnuals} cashflowType={CashflowType.PostTaxDiscounted} /></div>;
  const cashflowTable = <AdvancedFieldCashflowTableComponent annual={resource.fiscalResource.cashflow.cashflowAnnuals} totalRow={resource.fiscalResource.cashflow.cashflowAnnualsTotalRow} />;
  const cashflowAssumptions = <FieldCashflowAssumptionsComponent
    liquidDiscountPremium={resource.fiscalResource.displayLiquidPriceDiscountPremiumPercent}
    gasDiscountPremium={resource.fiscalResource.displayGasPriceDiscountPremiumPercent}
    discountRate={resource.fiscalResource.cashflow.discountRate}
    discountYear={portfolio.discountStartYear}
    liquidToFuel={resource.fiscalResource.liquidToFuel}
    gasToFuel={resource.fiscalResource.gasToFuel}
    valuationName={resource.valuation.variableName}
  />;

  return (
    <div ref={props.innerRef}>
      {createSection100(cashflowChart, 'Cashflow')}
      {createSection100(cashflowTable, '')}
      {createSection100(cashflowAssumptions, 'Economic Assumptions')}
    </div>
  );
};

AdvancedCashflowComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  resource: PropTypes.instanceOf(FieldDetail).isRequired,
  portfolio: PropTypes.instanceOf(FasterPortfolio).isRequired,
};

export default AdvancedCashflowComponent;
