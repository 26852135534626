import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Navbar, NavbarToggler } from 'reactstrap';

import './main-toolbar-component.scss';

const MainToolbarComponent = (props) => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const toggleNavbarOpen = () => setNavbarOpen(!navbarOpen);

  return (
    <Navbar className={'main-toolbar-component' + (props.showLabels ? '' : ' hide-labels')} color="dark" dark expand="md">
      <NavbarToggler onClick={toggleNavbarOpen} />
      <Collapse isOpen={navbarOpen} navbar>
        {props.children}
      </Collapse>
    </Navbar>
  );
};

MainToolbarComponent.propTypes = {
  showLabels: PropTypes.bool.isRequired
};

export default MainToolbarComponent;