import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';

import './home-view-component.scss';
import { HomeFilter } from './home-filter';
import OverviewComponent, { ReportsGridRow, ActivityDataRow, ActivityDataRowType } from './overview-component';

import { appViews } from '../../app/app-views';
import { AppSetState } from '../../app/app-set-state';
import { AtlasPanel } from '../../shared/helpers/enums';
import { zoomLevels } from '../../shared/helpers/leaflet/leaflet-helper';
import BaseViewComponent, { LeafletHelper, ContextLayerModel } from '../base/base-view-component';
import { SearchResultsExcelExport } from '../../map/search-results-excel-export';

class HomeViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.leafletHelper = new LeafletHelper(this.props.geoserverUrl, 'home-view-component-map', new SearchResultsExcelExport(this.props.appSetState, appViews.Home).export);

    this.state = {
      contextLayers: [],
      reportDataRows: [],
      allActivityDataRows: [],
      activityDataRows: [],
      activitiesType: this.props.settings.activitiesType,
      activitiesFromDate: this.props.settings.activitiesFromDate,
      activitiesToDate: this.props.settings.activitiesToDate
    };
  }

  componentDidMount() {
    var id = this.props.emailreportid;
    var emailID = this.props.mainreportid;
    const url = 'api/home/load-all?reportID=' + id + '&notificationID=' + emailID;
    const apiParam = this.props.filter.toApiParam();
    apiParam.activitiesFromDate = this.state.activitiesFromDate;
    apiParam.activitiesToDate = this.state.activitiesToDate;
    const onSuccess = (result) => {
      const allActivityDataRows = ActivityDataRow.FromArray(result.activityDataRows, this.showActivityDetails);
      this.setState(prevState => {
        return {
          contextLayers: ContextLayerModel.FromArray(result.contextLayers),
          reportDataRows: ReportsGridRow.FromArray(result.reportDataRows),
          allActivityDataRows: allActivityDataRows,
          activityDataRows: this.getFilteredActivityDataRows(prevState.activitiesType, allActivityDataRows)
        };
      }, this.initialSetMapPosition);
    };
    this.props.appSetState.api.post(url, apiParam, onSuccess, undefined, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.updateActivities();
    }
  }

  componentWillUnmount() {
    if (this.leafletHelper !== null && this.leafletHelper !== undefined) {
      this.leafletHelper.destroy();
      delete this.leafletHelper;
    }
  }

  updateReports = () => {
    const url = 'api/home/load-reports';
    const onSuccess = (result) => {
      this.setState({
        reportDataRows: ReportsGridRow.FromArray(result.reportDataRows)
      });
    };
    this.props.appSetState.api.get(url, onSuccess);
  }

  updateActivities = () => {
    const url = 'api/home/load-activities';
    const apiParam = this.props.filter.toApiParam();
    apiParam.activitiesFromDate = this.state.activitiesFromDate;
    apiParam.activitiesToDate = this.state.activitiesToDate;
    const onSuccess = (result) => {
      this.setState(prevState => {
        const allActivityDataRows = ActivityDataRow.FromArray(result.activityDataRows, this.showActivityDetails);
        return {
          allActivityDataRows: allActivityDataRows,
          activityDataRows: this.getFilteredActivityDataRows(prevState.activitiesType, allActivityDataRows)
        };
      }, this.setMapPosition);
    };
    this.props.appSetState.api.post(url, apiParam, onSuccess, undefined, true);
  }

  showOnMap = (dataRow) => {
    const latLng = L.latLng(dataRow.shape.latLng);
    const zoom = ActivityDataRowType.defaultZoomLevel(dataRow.type);
    this.leafletHelper.flyTo(latLng, zoom);
  }

  setActivitiesType = (value) => {
    if (value !== null && ActivityDataRowType.isInvalid(value)) {
      throw new Error('Invalid "value" param supplied to "HomeViewComponent.setActivitiesType"');
    }

    const { settings } = this.props;

    if (settings.activitiesType !== value) {
      settings.activitiesType = value;
      this.setState(prevState => {
        return {
          activitiesType: value,
          activityDataRows: this.getFilteredActivityDataRows(value, prevState.allActivityDataRows)
        };
      }, this.setMapPosition);
    }
  }

  isValidDate(date) {
    const result = date instanceof Date && date.toString() !== 'Invalid Date';
    return result;
  }

  setActivitiesFromDate = (value) => {
    if (this.state.activitiesFromDate !== value) {
      if (this.isValidDate(value)) {
        this.props.settings.activitiesFromDate = value;
      }
      const callback = this.isValidDate(value) && this.isValidDate(this.state.activitiesToDate)
        ? this.updateActivities
        : undefined;
      this.setState({ activitiesFromDate: value }, callback);
    }
  }

  setActivitiesToDate = (value) => {
    if (this.state.activitiesToDate !== value) {
      if (this.isValidDate(value)) {
        this.props.settings.activitiesToDate = value;
      }
      const callback = this.isValidDate(value) && this.isValidDate(this.state.activitiesFromDate)
        ? this.updateActivities
        : undefined;
      this.setState({ activitiesToDate: value }, callback);
    }
  }

  initialSetMapPosition = () => {
    const { filter } = this.props;

    if (filter.mapBoundsIsSet) {
      this.leafletHelper.leafletMap.fitBounds(filter.mapBounds);
    } else {
      this.setMapPosition();
    }
  }

  setMapPosition = () => {
    const { activityDataRows } = this.state;

    //if (this.props.filter.followMap === false) {
    if (activityDataRows.length === 1) {
      const latLng = L.latLng(activityDataRows[0].shape.latLng);
      const zoom = ActivityDataRowType.defaultZoomLevel(activityDataRows[0].type);
      this.leafletHelper.flyTo(latLng, zoom);
    }
    else if (activityDataRows.length > 0) {
      const bounds = L.latLngBounds([]);
      activityDataRows.forEach(obj => bounds.extend(obj.shape.latLng));
      this.leafletHelper.flyToBounds(bounds);
    }
    //}
  }

  showActivityDetails = (dataRow) => {
    switch (dataRow.type) {
      case ActivityDataRowType.WellApproval:
      case ActivityDataRowType.WellSpud:
      case ActivityDataRowType.WellCompletion: {
        this.props.appSetState.view.details.showWell(dataRow.gmEntityId, AtlasPanel.Activities);
        break;
      }
      case ActivityDataRowType.DiscoveryAnnouncement: {
        this.props.appSetState.view.details.showDiscovery(dataRow.gmEntityId, AtlasPanel.Activities);
        break;
      }
      case ActivityDataRowType.FieldApproval:
      case ActivityDataRowType.FieldStartUp: {
        this.props.appSetState.view.details.showField(dataRow.gmEntityId, AtlasPanel.Activities);
        break;
      }
      default:
        break;
    }
  }

  getFilteredActivityDataRows(activitiesType, allActivityDataRows) {
    const result = activitiesType === null
      ? allActivityDataRows
      : allActivityDataRows.filter(obj => obj.type === activitiesType);
    return result;
  }

  render() {
    const { activityDataRows } = this.state;

    const overview = (
      <OverviewComponent
        appSetState={this.props.appSetState}
        reportDataRows={this.state.reportDataRows}
        activityDataRows={activityDataRows}
        showOnMap={this.showOnMap}
        activitiesType={this.state.activitiesType}
        setActivitiesType={this.setActivitiesType}
        activitiesFromDate={this.state.activitiesFromDate}
        setActivitiesFromDate={this.setActivitiesFromDate}
        activitiesToDate={this.state.activitiesToDate}
        setActivitiesToDate={this.setActivitiesToDate}
      />
    );

    const dummyFunc = () => { };

    return (
      <BaseViewComponent
        className="home-view-component"
        leafletHelper={this.leafletHelper}
        contextLayers={this.state.contextLayers}
        shapes={activityDataRows.map(obj => obj.shape)}
        appView={appViews.Home}
        appSetState={this.props.appSetState}
        update={this.updateActivities}
        settings={this.props.settings}
        filter={this.props.filter}
        showFiltersButton={false}
        showFilters={false}
        setShowFilters={dummyFunc}
        showFollowMapButton={false}
        followMap
        setFollowMap={dummyFunc}
        overview={overview}
      />
    );
  }
}

HomeViewComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    showMap: PropTypes.bool.isRequired,
    activitiesType: ActivityDataRowType.nullablePropType,
    activitiesFromDate: PropTypes.instanceOf(Date).isRequired,
    activitiesToDate: PropTypes.instanceOf(Date).isRequired
  }).isRequired,
  filter: PropTypes.instanceOf(HomeFilter).isRequired
};

export default HomeViewComponent;
