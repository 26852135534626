import React from 'react';
import PropTypes from 'prop-types';

import CorporateDetailGridComponent, { createLink, HEADER_ROW_QUAD, NUMERIC_SORT } from '../corporate-detail-grid-component';

import { valueTypes } from '../../../../shared/helpers/value-handler';
import { NetDiscovery } from '../../../../models/corporate-detail';

const CorporateDiscoveryTableComponent = (props) => {
  const { onDiscoveryClick, onLicenceClick, onBlockClick, onWellClick, onOperatorClick } = props;

  const resourceNameRenderer = (params) => {
    let result = params.data.resourceName;
    if (typeof onDiscoveryClick === 'function' && typeof params.data.resourceId === 'number') {
      result = createLink(result, 'Show Details...', () => onDiscoveryClick(params.data.resourceId));
    }
    return result;
  };
  const licenceRenderer = (params) => {
    let result = params.data.licence;
    if (typeof onLicenceClick === 'function' && typeof params.data.licenceId === 'number') {
      result = createLink(result, 'Show Details...', () => onLicenceClick(params.data.licenceId));
    }
    return result;
  };
  const blockRenderer = (params) => {
    let result = params.data.block;
    if (typeof onBlockClick === 'function' && typeof params.data.blockId === 'number') {
      result = createLink(result, 'Show Details...', () => onBlockClick(params.data.blockId));
    }
    return result;
  };
  const operatorRenderer = (params) => {
    let result = params.data.operator;
    // params.data.operatorId === params.currentCorporateId means we're currently showing that company, so no point having a link
    if (typeof onOperatorClick === 'function' && typeof params.data.operatorId === 'number' && params.data.operatorId !== params.currentCorporateId) {
      result = createLink(result, 'Show Details...', () => onOperatorClick(params.data.operatorId));
    }
    return result;
  }
  const equityRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.equity);
  const discoveryWellRenderer = (params) => {
    let result = params.data.discoveryWell;
    if (typeof onWellClick === 'function' && typeof params.data.discoveryWellId === 'number') {
      result = createLink(result, 'Show Details...', () => onWellClick(params.data.discoveryWellId));
    }
    return result;
  };
  const netLiquidMmbRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.netLiquidMmb);
  const netGasBcfRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.netGasBcf);
  const netCombinedMmboeRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.netCombinedMmboe);

  const columnDefs = [
    {
      headerName: 'Discovery',
      field: 'resourceName',
      minWidth: 135,
      cellRenderer: resourceNameRenderer
    },
    {
      headerName: 'Area',
      minWidth: 100,
      field: 'area'
    },
    {
      headerName: 'Licence',
      field: 'licence',
      minWidth: 85,
      cellRenderer: licenceRenderer
    },
    {
      headerName: 'Block',
      field: 'block',
      minWidth: 70,
      cellRenderer: blockRenderer
    },
    {
      headerName: 'Operator',
      field: 'operator',
      minWidth: 120,
      cellRenderer: operatorRenderer,
      cellRendererParams: {
        currentCorporateId: props.currentCorporateId
      }
    },
    {
      headerName: 'Equity (' + valueTypes.UnitString(valueTypes.percent) + ')',
      field: 'equity',
      minWidth: 85,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: equityRenderer
    },
    {
      headerName: 'Discovery\nYear',
      field: 'discoveryYear',
      sortingOrder: NUMERIC_SORT,
      minWidth: 95
    },
    {
      headerName: 'Discovery\nWell',
      field: 'discoveryWell',
      minWidth: 95,
      cellRenderer: discoveryWellRenderer
    },
    {
      headerName: 'Discovery\nClassification',
      field: 'classification',
      minWidth: 120
    },
    {
      headerName: 'Net Liquid\nResources\n(' + valueTypes.UnitString(valueTypes.mmmboe) + ')',
      field: 'netLiquidMmb',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: netLiquidMmbRenderer
    },
    {
      headerName: 'Net Gas\nResources\n(' + valueTypes.UnitString(valueTypes.bcf) + ')',
      field: 'netGasBcf',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: netGasBcfRenderer
    },
    {
      headerName: 'Net\nResources\n(' + valueTypes.UnitString(valueTypes.mmmboe) + ')',
      field: 'netCombinedMmboe',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: netCombinedMmboeRenderer
    },
    {
      headerName: 'Reservoir\nAge',
      field: 'reservoirAge',
      minWidth: 150
    }
  ];

  return <CorporateDetailGridComponent columnDefs={columnDefs} dataRows={props.netDiscoveries} headerRowHeight={HEADER_ROW_QUAD} />;
};

CorporateDiscoveryTableComponent.propTypes = {
  currentCorporateId: PropTypes.number.isRequired,
  netDiscoveries: PropTypes.arrayOf(PropTypes.instanceOf(NetDiscovery)).isRequired,
  onDiscoveryClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onWellClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default CorporateDiscoveryTableComponent;
