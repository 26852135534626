import React from 'react';
import PropTypes from 'prop-types';

import { GridExcelExport } from './grid-excel-export';

import { appIcons } from '../../app/app-icons';
import { ProspectDataRow } from './prospect-data-row';
import { AppSetState } from '../../app/app-set-state';
import { valueTypes } from '../../shared/helpers/value-handler';
import BaseGridComponent, { createLink, createLinkAndIcon, COLUMN_SMALL, COLUMN_EXTRA_SMALL_SMALL, COLUMN_LARGE, COLUMN_LARGE_LARGE, HEADER_ROW_QUAD, NUMERIC_SORT } from '../../components/base-grid/base-grid-component';

const ProspectGridComponent = (props) => {
  const { view } = props.appSetState;

  const renderName = (params) => {
    return createLinkAndIcon(params.data.prospectIdentifier, 'Show Details', () => view.details.showProspect(params.data.id),
      appIcons.MapMarkerAlt, 'Show on map', () => props.showOnMap(params.data));
  };

  const renderUnriskedResources = (params) => {
    return valueTypes.ValueOnly(valueTypes.generic1, params.data.combinedMmboe);
  };

  const renderLiquidUnriskedResources = (params) => {
    return valueTypes.ValueOnly(valueTypes.generic1, params.data.initialLiquidMmb);
  };

  const renderGasUnriskedResources = (params) => {
    return valueTypes.ValueOnly(valueTypes.generic1, params.data.initialGasBcf);
  };

  const renderOperatorName = (params) => {
    const { operatorName, operatorId } = params.data;

    if (typeof operatorName === 'string' && operatorName.length > 0 && typeof operatorId === 'number' && operatorId !== -1) {
      return createLink(operatorName, 'Show details', () => view.details.showCorporate(operatorId));
    }
    return operatorName;
  };

  const renderLicence = (params) => {
    if (typeof params.data.licenceId === 'number') {
      return createLink(params.data.licence, 'Show details', () => view.details.showLicence(params.data.licenceId));
    }
    return params.data.licence;
  };

  const PROSPECT_COLUMN_DEFS = [
    {
      headerName: 'Prospect Identifier',
      field: 'prospectIdentifier',
      sortable: true,
      minWidth: COLUMN_LARGE,
      cellRenderer: renderName
    },
    {
      headerName: 'Prospect Name',
      field: 'name',
      sortable: true,
      minWidth: COLUMN_LARGE
    },
    {
      headerName: 'Country',
      field: 'countryName',
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: 'Area',
      field: 'areaName',
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: 'Operator',
      field: 'operatorName',
      sortable: true,
      minWidth: COLUMN_LARGE_LARGE,
      cellRenderer: renderOperatorName
    },
    {
      headerName: 'Licence',
      field: 'licence',
      sortable: true,
      minWidth: COLUMN_LARGE,
      cellRenderer: renderLicence
    },
    {
      headerName: 'Unrisked\nLiquid\nResources\n(' + valueTypes.UnitString(valueTypes.mmb) + ')',
      field: 'initialLiquidMmb',
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: renderLiquidUnriskedResources
    },
    {
      headerName: 'Unrisked\nGas\nResources\n(' + valueTypes.UnitString(valueTypes.bcf) + ')',
      field: 'initialGasBcf',
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: renderGasUnriskedResources
    },
    {
      headerName: 'Unrisked\nTotal\nResources\n(' + valueTypes.UnitString(valueTypes.mmmboe) + ')',
      field: 'combinedMmboe',
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: renderUnriskedResources
    },
    {
      headerName: 'Reservoir Age',
      field: 'reservoirAgeName',
      sortable: true,
      minWidth: COLUMN_LARGE
    },
  ];

  return <BaseGridComponent columnDefs={PROSPECT_COLUMN_DEFS} dataRows={props.dataRows} autoReSizeGrid={true} headerRowHeight={HEADER_ROW_QUAD} />;
};

ProspectGridComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.instanceOf(ProspectDataRow).isRequired).isRequired,
  showOnMap: PropTypes.func.isRequired
}

export default ProspectGridComponent;
export { GridExcelExport };
