import { showError } from '../../helpers/common';
import { Participant } from './gmatrix3-extension-participant';

class DisplayParticipant extends Participant {
  constructor(obj) {
    super(obj);
    if (typeof obj.operatorStatus !== 'string') { showError('DisplayParticipant', 'operatorStatus'); }
    if (typeof obj.coreStatus !== 'string') { showError('DisplayParticipant', 'coreStatus'); }
    if (typeof obj.participantTier !== 'string') { showError('DisplayParticipant', 'participantTier'); }
    if (typeof obj.participantCategory !== 'string') { showError('DisplayParticipant', 'participantCategory'); }
    if (typeof obj.operatorDisplayName !== 'string') { showError('DisplayParticipant', 'operatorDisplayName'); }

    this.operatorStatus = obj.operatorStatus;
    this.coreStatus = obj.coreStatus;
    this.participantTier = obj.participantTier;
    this.participantCategory = obj.participantCategory;
    this.operatorDisplayName = obj.operatorDisplayName;
  }
}

export { DisplayParticipant }