import { appViews } from "../../app/app-views";
import { AppSetState } from "../../app/app-set-state";
import { SettingsModel } from "../../models/settings-model";
import { settingsStates } from "../../shared/components/main/main-classes";

class BaseViewSettings {
  constructor(appView, appSetState, filter) {
    if (appViews.isInvalid(appView)) {
      throw new Error('Invalid "appView" param supplied to "BaseViewSettings.ctor"');
    }
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid \'appSetState\' param supplied to "BaseViewSettings.ctor"');
    }
    //TODO: if (!(filter instanceof BaseFilter)) {
    //TODO:   throw new Error('Invalid \'filter\' param supplied to "BaseViewSettings.ctor"');
    //TODO: }

    this._filter = filter;
    this._settings = { showMap: true };
    this._appView = appView;
    this._appSetState = appSetState;
    this._showFilters = false;

    this._showSettings = false;
  }

  get filter() { return this._filter; }
  get appView() { return this._appView; }
  get appSetState() { return this._appSetState; }

  get showFilters() { return this._showFilters; }
  set showFilters(value) { this.setShowFilters(value); }
  setShowFilters = (value) => {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "BaseViewSettings.showFilters"');
    }

    if (this._showFilters !== value) {
      this._showFilters = value;
      //if (this._showFilters) this._showSettings = false; 
      this.appSetState.view.forceUpdate();
    }
  }

  get showSettings() { return this._showSettings; }
  set showSettings(value) { this.setShowSettings(value); }
  setShowSettings = (value) => {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "BaseViewSettings.showSettings"');
    }

    if (this._showSettings !== value) {
      this._showSettings = value;
      //if (this._showSettings) this._showFilters = false; 
      this.appSetState.view.forceUpdate();
    }
  }

  get followMap() { return this._filter.followMap; }
  set followMap(value) { this.setFollowMap(value); }
  setFollowMap = (value) => {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "BaseViewSettings.setFollowMap"');
    }

    if (this._filter.followMap !== value) {
      this._filter.followMap = value;
      this._filter = this._filter.clone();
      this.appSetState.view.forceUpdate();
    }
  }

  getViewSettingsComponent(settings) {
    if (!(settings instanceof SettingsModel)) {
      throw new Error('Invalid \'settings\' param supplied to \'BaseViewSettings.getViewComponent\'');
    }
  }

  getViewComponent(settings) {
    if (!(settings instanceof SettingsModel)) {
      throw new Error('Invalid \'settings\' param supplied to \'BaseViewSettings.getViewComponent\'');
    }
  }

  getSettingsState = () => {
    /*if (this._showFilters === false) {*/
    if (this._showFilters === false && this._showSettings === false) {
      return settingsStates.Closed;
    }

    if (appViews.openOverlayed(this._appView) && this._settings.showMap === true) {
      return settingsStates.PinnedOverlay;
    }

    return settingsStates.Pinned
  }

  reset() {
    this._settings.showMap = true;
    this._showFilters = false;
    this._showSettings = false;
    this.filter.clear();
  }
}

export { BaseViewSettings };