import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, /*InputGroupAddon,*/ InputGroupText, Input, Button, Tooltip } from 'reactstrap';

import { appIcons, appIconSizes } from '../../app/app-icons';

const CopyLinkComponent = (props) => {
  const [linkCopied, setLinkCopied] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref !== null) {
      ref.current.select();
    }
  }, []);

  const onClick = () => {
    ref.current.select();
    document.execCommand('copy');
    setLinkCopied(true);
  };

  let tooltip = null;
  if (linkCopied) {
    tooltip = (
      <Tooltip placement="top" isOpen={linkCopied} autohide={false} target="copy-link-button" toggle={() => { }}>
        Link copied to clipboard
      </Tooltip>
    );
  }

  /*<InputGroupAddon addonType="append">*/
  /*  <Button id="copy-link-button" onClick={onClick}>{appIcons.getIcon(appIcons.Copy, appIconSizes.sm)}</Button>*/
  /*</InputGroupAddon>*/

  return (
    <div style={{ padding: 2 }}>
      <h5 style={{ textAlign: 'center', marginBottom: 20 }}>Press Ctrl+C or click the copy button to get a link to this {props.linkType} that you can share.</h5>
      <InputGroup>
        <Input innerRef={ref} defaultValue={props.url} readOnly />
        <InputGroupText>
          <Button id="copy-link-button" onClick={onClick}>{appIcons.getIcon(appIcons.Copy, appIconSizes.sm)}</Button>
        </InputGroupText>
      </InputGroup>
      {tooltip}
    </div>
  );
};

CopyLinkComponent.propTypes = {
  linkType: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default CopyLinkComponent;
