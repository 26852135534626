import { objectToEnum } from "../../helpers/common";

const ChartType = objectToEnum({
  Bar: 0,
  HorizontalBar: 1,
  Line: 2,
}, false);

Object.freeze(ChartType);

export { ChartType };