import { DisplayParticipant } from './gmatrix3-display-participant';

class BlockParticipant extends DisplayParticipant {
  constructor(obj) {
    super(obj);
    
    if (typeof obj.areaSQKM !== 'number') { throw new Error('Invalid areaSQKM param supplied to BlockParticipant.ctor'); }

    this.areaSQKM = obj.areaSQKM;
  }
}

export { BlockParticipant }