import React from 'react';
import PropTypes from 'prop-types'

import LocationComponent from './body/location-component';
import FieldsTableComponent from './body/fields-table-component';
import OwnersTableComponent from './body/owners-table-component';
import ProspectsTableComponent from './body/prospects-table-component';
import DiscoveriesTableComponent from './body/discoveries-table-component';
import PlannedWellsTableComponent from './body/planned-wells-table-component';
import CompletedAndActiveWellsTableComponent from './body/completed-and-active-wells-table-component';

import { BlockDetail } from '../../models/block-detail';
import { TrackingType } from '../../shared/helpers/enums';
import { downloadFile } from '../../shared/helpers/download';
import { assignColours } from '../../shared/helpers/array-color-helper';
import MapComponent from '../../components/fiscal/map/map-component';
import OwnersPieChartComponent from '../../shared/components/owners-pie-chart/owners-pie-chart-component';
import { searchResultType, ContextLayerModel, latLngBounds } from '../../shared/helpers/leaflet/leaflet-helper';
import DetailViewGridLayoutComponent, { createSection100, createSection, sectionTypes } from '../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const BlockDetailComponent = (props) => {
  const { block, appSetState } = props;

  const onLicenceClick = (licenceId) => {
    if (typeof licenceId !== 'number') {
      throw new Error('Invalid "licenceId" supplied to "BlockDetailComponent.onLicenceClick"');
    }

    appSetState.view.details.showLicence(licenceId);
  }

  const onCorporateClick = (corporateId) => {
    if (typeof corporateId !== 'number') {
      throw new Error('Invalid "corporateId" supplied to "BlockDetailComponent.onCorporateClick"');
    }

    appSetState.view.details.showCorporate(corporateId);
  };

  const hasOwners = block.owners && block.owners.length > 0;
  assignColours(block.owners, 'color');

  const location = <LocationComponent block={block} onLicenceClick={onLicenceClick} />;
  const leftPanel = { content: location, sectionType: sectionTypes.section50, title: 'Location' };
  if (hasOwners) {
    leftPanel.content = (
      <React.Fragment>
        {createSection100(location, 'Location')}
        {createSection100(<div style={{ height: '200px' }}><OwnersPieChartComponent owners={block.owners} /></div>, 'Participation')}
      </React.Fragment>
    );
    leftPanel.title = '';
    leftPanel.noMarginVertical = true;
  }
  const rightPanel = { content: <div style={{ height: '100%' }}><MapComponent geoserverUrl={props.geoserverUrl} contextLayers={props.contextLayers} bounds={latLngBounds(block.shapes.latLngs)} zoom={searchResultType.defaultZoomLevel(searchResultType.Block)} shapes={[block.shapes]} /></div>, sectionType: sectionTypes.section50 };

  const onFieldClick = (resourceId) => {
    if (typeof resourceId !== 'number') {
      throw new Error('Invalid "resourceId" param supplied to "BlockDetailComponent.onFieldClick"');
    }

    appSetState.view.details.showField(resourceId);
  }

  const onDiscoveryClick = (resourceId) => {
    if (typeof resourceId !== 'number') {
      throw new Error('Invalid "resourceId" param supplied to "BlockDetailComponent.onDiscoveryClick"');
    }

    appSetState.view.details.showDiscovery(resourceId);
  }

  const onProspectClick = (resourceId) => {
    if (typeof resourceId !== 'number') {
      throw new Error('Invalid "resourceId" param supplied to "BlockDetailComponent.onProspectClick"');
    }

    appSetState.view.details.showProspect(resourceId);
  }

  const onWellClick = (wellId) => {
    if (typeof wellId !== 'number') {
      throw new Error('Invalid "wellId" param supplied to "BlockeDetailsComponent.onWellClick"');
    }

    appSetState.view.details.showWell(wellId);
  }

  const onReportClick = (reportId) => {
    if (typeof reportId !== 'number') {
      throw new Error('Invalid "reportId" param supplied to "BlockeDetailsComponent.onReportClick"');
    }

    downloadFile(reportId, appSetState, true, 0, appSetState.view.current, TrackingType.ReportDownload, props.atlasPanel);
  }

  const onHubClick = (hubId) => {
    if (typeof hubId !== 'number') {
      throw new Error('Invalid "hubId" param supplied to "BlockDetailComponent.onHubClick"');
    }

    appSetState.view.details.showHub(hubId);
  }

  const onBlockClick = (blockId) => {
    if (typeof blockId !== 'number') {
      throw new Error('Invalid "blockId" param supplied to "BlockeDetailsComponent.onBlockClick"');
    }

    appSetState.view.details.showBlock(blockId);
  }

  return (
    <DetailViewGridLayoutComponent>
      {createSection([leftPanel, rightPanel])}
      {hasOwners && createSection100(<OwnersTableComponent owners={block.owners} onOperatorClick={onCorporateClick} />)}
      {block.fields.length > 0 && createSection100(<FieldsTableComponent fields={block.fields} currentYear={block.currentYear} onFieldClick={onFieldClick} onHubClick={onHubClick} onOperatorClick={onCorporateClick} />, 'Fields on block')}
      {block.discoveries.length > 0 && createSection100(<DiscoveriesTableComponent discoveries={block.discoveries} onDiscoveryClick={onDiscoveryClick} onOperatorClick={onCorporateClick} />, 'Discoveries on block')}
      {block.prospects.length > 0 && createSection100(<ProspectsTableComponent prospects={block.prospects} onProspectClick={onProspectClick} onOperatorClick={onCorporateClick} />, 'Prospects on block')}
      {block.completedAndActiveWells.length > 0 && createSection100(<CompletedAndActiveWellsTableComponent licenceId={block.id} wells={block.completedAndActiveWells} onWellClick={onWellClick} onReportClick={onReportClick} onLicenceClick={onLicenceClick} onProspectClick={onProspectClick} onDiscoveryClick={onDiscoveryClick} onFieldClick={onFieldClick} onOperatorClick={onCorporateClick} />, 'Completed and active E&A wells')}
      {block.plannedWells.length > 0 && createSection100(<PlannedWellsTableComponent wells={block.plannedWells} onWellClick={onWellClick} onBlockClick={onBlockClick} onProspectClick={onProspectClick} onDiscoveryClick={onDiscoveryClick} onFieldClick={onFieldClick} onOperatorClick={onCorporateClick} />, 'Planned E&A wells on block')}
    </DetailViewGridLayoutComponent>
  );
};

BlockDetailComponent.propTypes = {
  block: PropTypes.instanceOf(BlockDetail).isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
  appSetState: PropTypes.instanceOf(Object).isRequired,
  geoserverUrl: PropTypes.string.isRequired
};

export default BlockDetailComponent;