import React from 'react';
import PropTypes from 'prop-types';
import { UserValuations, AnnualVariables } from '../models/user-valuations';
import { Button } from 'reactstrap';
import { Table, Input } from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';
import { appIcons, appIconSizes } from '../app/app-icons';
import isEqual from 'lodash/isEqual';
import { valueTypes } from '../shared/helpers/value-handler';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const IDS = {
  YEAR: 1,
  LIQUID_PRICE: 2,
  GAS_PRICE: 3,
  VALUATION_NAME: 4,
  DISCOUNT_YEAR: 5,
  DISCOUNT_RATE: 6,
  YEAR_FROM: 7,
  YEAR_TO: 8,
  SCENARIO: 9,
  All_YEARS: 10,
};
Object.freeze(IDS);

const VALUATION_ATTRIBUTES = {
  VALUATION_NAME: 1,
  DISCOUNT_YEAR: 2,
  DISCOUNT_RATE: 3,
  PRICE: 4,
  SCENARIO: 5,
  SCENARIO_YEAR_FROM: 6,
  SCENARIO_YEAR_TO: 7
};
Object.freeze(VALUATION_ATTRIBUTES);

class ValuationEditComponent extends React.Component {
  constructor(props) {
    super(props);

    var newValuation = this.props.selectedValuation !== null && this.props.selectedValuation.variableId === -1 ? true : false;
    this.state = { selectedValuation: this.props.selectedValuation, newValuation: newValuation, editing: this.props.activeEdit };

    this.addYear = this.addYear.bind(this);
    this.deleteYear = this.deleteYear.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedValuation !== this.props.selectedValuation) {
      var newValuation = this.props.selectedValuation !== null && this.props.selectedValuation.variableId === -1 ? true : false;
      this.setState({ selectedValuation: this.props.selectedValuation, newValuation: newValuation, editing: this.props.activeEdit });
    }
    else if (this.state.selectedValuation !== null && isEqual(this.props.selectedValuation, this.state.selectedValuation) && this.state.editing === true) {
      this.setState({ editing: false });
    }
  }

  cancel = () => {
    this.props.onReset();
    this.setState({
      selectedValuation: this.props.selectedValuation, editing: false
    });
  }

  addYear = () => {
    var selectedValuationChanged = cloneDeep(this.state.selectedValuation);
    var year = 0;

    if (selectedValuationChanged.valuationAnnuals.length === 0) {
      year = selectedValuationChanged.currentYear;
    }
    else if (selectedValuationChanged.valuationAnnuals.length > 0) {
      selectedValuationChanged.valuationAnnuals.sort((a, b) => a.year - b.year);
      var lastIndex = selectedValuationChanged.valuationAnnuals.length - 1;
      year = selectedValuationChanged.valuationAnnuals[lastIndex].year + 1;
    }

    var obj = { gasPriceUsdMCF: 0, liquidPriceUsdBOE: 0, year: 0 };
    var newAnnualVariable = new AnnualVariables(obj);
    newAnnualVariable.year = year;

    selectedValuationChanged.valuationAnnuals.push(newAnnualVariable);

    this.props.setActiveEdit();
    this.setState({ selectedValuation: selectedValuationChanged, editing: true });
  };

  deleteYear = () => {
    var selectedValuationChanged = cloneDeep(this.state.selectedValuation);
    var year = selectedValuationChanged.currentYear;

    if (selectedValuationChanged.valuationAnnuals.length > 0) {
      selectedValuationChanged.valuationAnnuals.sort((a, b) => a.year - b.year);
      var lastIndex = selectedValuationChanged.valuationAnnuals.length - 1;
      if (selectedValuationChanged.valuationAnnuals[lastIndex].year >= year) {
        selectedValuationChanged.valuationAnnuals.pop();
      }
    }

    this.props.setActiveEdit();
    this.setState({ selectedValuation: selectedValuationChanged, editing: true });
  };

  resetScenario = () => {
    var selectedValuationChanged = cloneDeep(this.state.selectedValuation);

    selectedValuationChanged.scenario.applyToAllYears = true;
    selectedValuationChanged.scenario.yearFrom = 0;
    selectedValuationChanged.scenario.yearTo = 0;
    selectedValuationChanged.scenario.scenarioValues?.forEach(x => x.attributeValue = 0);

    selectedValuationChanged.validationState.results = selectedValuationChanged.validationState.results.filter(x => x.valuationAttribute < 4);

    this.props.setActiveEdit();
    this.setState({ selectedValuation: selectedValuationChanged, editing: true });
  };

  valueChanged = (value, id, findIndex, onBlur = false) => {
    id = Number(id);

    //if (onBlur && (id === IDS.SCENARIO || id !== IDS.LIQUID_PRICE || id !== IDS.GAS_PRICE)) {
    if (onBlur && id !== IDS.VALUATION_NAME) {
      if (value === '' || value.toString().trim() === '') value = 0;
      value = Number(value);
    }

    //if (id !== IDS.VALUATION_NAME && id !== IDS.All_YEARS && (!onBlur && id !== IDS.SCENARIO && id !== IDS.LIQUID_PRICE && id !== IDS.GAS_PRICE)) value = Number(value);
    var selectedValuationChanged = cloneDeep(this.state.selectedValuation);

    let foundIndex = 0;
    if (id === IDS.LIQUID_PRICE || id === IDS.GAS_PRICE) {
      foundIndex = selectedValuationChanged.valuationAnnuals.findIndex(x => x.year === findIndex);
    }
    else if (id === IDS.SCENARIO) {
      foundIndex = selectedValuationChanged.scenario.scenarioValues.findIndex(x => x.scenarioValueId === findIndex);
    }

    switch (id) {
      case IDS.LIQUID_PRICE: selectedValuationChanged.valuationAnnuals[foundIndex].liquidPriceUsdBOE = value; break;
      case IDS.GAS_PRICE: selectedValuationChanged.valuationAnnuals[foundIndex].gasPriceUsdMCF = value; break;
      case IDS.VALUATION_NAME: selectedValuationChanged.variableName = value; break;
      case IDS.DISCOUNT_YEAR: selectedValuationChanged.discountStartYear = parseInt(value); break;
      case IDS.DISCOUNT_RATE: selectedValuationChanged.discountRate = value; break;
      case IDS.SCENARIO: selectedValuationChanged.scenario.scenarioValues[foundIndex].attributeValue = value; break;
      case IDS.All_YEARS:
        selectedValuationChanged.scenario.applyToAllYears = value;
        if (value) {
          selectedValuationChanged.scenario.yearFrom = 0;
          selectedValuationChanged.scenario.yearTo = 0;
        } else {
          selectedValuationChanged.scenario.yearFrom = selectedValuationChanged.currentYear;
          selectedValuationChanged.scenario.yearTo = selectedValuationChanged.currentYear;
        }
        break;
      case IDS.YEAR_FROM: selectedValuationChanged.scenario.yearFrom = parseInt(value); break;
      case IDS.YEAR_TO: selectedValuationChanged.scenario.yearTo = parseInt(value); break;
    }

    this.props.setActiveEdit();
    this.setState({ selectedValuation: selectedValuationChanged, editing: true });
  };

  handleFocus = (event) => event.target.select();

  getItems = (selectedValuation, readOnly) => {
    let items = [];
    if (selectedValuation !== null) {
      let currentYear = selectedValuation.currentYear;
      for (const item of selectedValuation.valuationAnnuals) {
        if (item.year >= currentYear) {

          let errorMessage = this.getError(selectedValuation, VALUATION_ATTRIBUTES.PRICE, item.year);
          const year_show = errorMessage.length > 0 ? '' : 'hide';
          const errorDisplay = <span className={'year ' + year_show} title={errorMessage} >{appIcons.getIcon(appIcons.ErrorTriangle, appIconSizes.sm)}</span>;
          const show_full = errorMessage.length > 0 ? '' : 'show-full'; //no errors - set this to ' hide' if we want to dispay the year input box 100% i.e. no errors

          items.push(<tr key={item.year}>
            <td width="20%"><Input className={'year-input ' + show_full} id={IDS.YEAR} type="number" disabled value={item.year} onChange={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id, item.year)} />{errorDisplay}</td>
            <td width="40%">
              <Input id={IDS.LIQUID_PRICE}
                type="number"
                disabled={readOnly}
                value={item.liquidPriceUsdBOE}
                onClick={this.handleFocus}
                onChange={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id, item.year)}
                onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id, item.year, true)}
              />
            </td>
            <td>
              <Input id={IDS.GAS_PRICE}
                type="number"
                disabled={readOnly}
                value={item.gasPriceUsdMCF}
                onClick={this.handleFocus}
                onChange={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id, item.year)}
                onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id, item.year, true)}
              />
            </td>
          </tr>)
          errorMessage = "";
        }
      }
    }
    return items;
  }

  getScenarioItems = (selectedValuation, readOnly) => {
    let scenarioItems = [];
    const percentageLabel = ' (%)';
    if (selectedValuation !== null) {
      for (const scenarioItem of selectedValuation.scenario.scenarioValues) {
        let errorMessage = ''; //this.getError(selectedValuation, VALUATION_ATTRIBUTES.PRICE, item.year);
        const year_show = errorMessage.length > 0 ? '' : 'hide';
        const errorDisplay = <span className={'year ' + year_show} title={errorMessage} >{appIcons.getIcon(appIcons.ErrorTriangle, appIconSizes.sm)}</span>;
        const show_full = errorMessage.length > 0 ? '' : 'show-full'; //no errors - set this to ' hide' if we want to dispay the year input box 100% i.e. no errors

        scenarioItems.push(
          <tr key={scenarioItem.scenarioValueId}>
            <td width="60%"><Input className={'scenario-label'} disabled value={scenarioItem.attributeName + percentageLabel} /></td>
            <td width="40%">
              <Input className={'scenario-label'}
                id={IDS.SCENARIO}
                type="number"
                disabled={readOnly}
                value={scenarioItem.attributeValue}
                onClick={this.handleFocus}
                onChange={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id, scenarioItem.scenarioValueId)}
                onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id, scenarioItem.scenarioValueId, true)}
              />
            </td>
          </tr>
        )
        errorMessage = "";
      }
    }

    return scenarioItems;
  }

  getError = (selectedValuation, attribute, year = 0) => {
    if (selectedValuation === null || selectedValuation.validationState === undefined || selectedValuation.validationState.results === undefined) return '';

    let messages = selectedValuation.validationState.results.filter(x => x.valuationAttribute === attribute && (year === 0 || x.year === year)).map(x => x.message);
    return messages === null || messages === undefined ? '' : messages.join(' ').toString();
  }

  onNameChange = (event) => this.valueChanged(event.target.value, IDS.VALUATION_NAME, 0);
  onDiscountYearChange = (event) => this.valueChanged(event.target.value, IDS.DISCOUNT_YEAR, 0);
  onDiscountYearBlur = (event) => this.valueChanged(event.target.value, IDS.DISCOUNT_YEAR, 0, true);
  onRateChange = (event) => this.valueChanged(event.target.value, IDS.DISCOUNT_RATE, 0);
  onRateBlur = (event) => this.valueChanged(event.target.value, IDS.DISCOUNT_RATE, 0, true);
  onYearFromChange = (event) => this.valueChanged(event.target.value, IDS.YEAR_FROM, 0);
  onYearFromBlur = (event) => this.valueChanged(event.target.value, IDS.YEAR_FROM, 0, true);
  onYearToChange = (event) => this.valueChanged(event.target.value, IDS.YEAR_TO, 0);
  onYearToBlur = (event) => this.valueChanged(event.target.value, IDS.YEAR_TO, 0, true);
  onAllYears = (event) => this.valueChanged(event.target.checked, IDS.All_YEARS, 0);  

  render() {
    const { readOnly, selectedValid } = this.props;
    const { selectedValuation, newValuation, editing } = this.state;

    const items = this.getItems(selectedValuation, readOnly);
    const scenarioItems = this.getScenarioItems(selectedValuation, readOnly);

    const isWGE = selectedValuation === null ? '' : selectedValuation.isWGEVariable;
    const name = selectedValuation === null ? '' : selectedValuation.variableName;
    const discountStartYear = selectedValuation === null ? '' : selectedValuation.discountStartYear;
    const discountRate = selectedValuation === null ? '' : selectedValuation.discountRate;
    const applyToAllYears = selectedValuation === null || selectedValuation.scenario === null ? true : selectedValuation.scenario.applyToAllYears;
    const scenarioYearsHide = applyToAllYears ? 'hide' : '';
    const scenarioFrom = selectedValuation === null || selectedValuation.scenario === null ? 0 : selectedValuation.scenario.yearFrom;
    const scenarioTo = selectedValuation === null || selectedValuation.scenario === null ? 0 : selectedValuation.scenario.yearTo;

    const noSelectionReadOnly = selectedValuation === null ? true : false;
    const defaultSelected = selectedValuation !== null ? selectedValuation.isDefault : false;
    const allowDeleteYears = items.length > 0 ? false : true;

    const selectedValuationId = selectedValuation === null ? 0 : selectedValuation.variableId;

    const top_errors = selectedValuation === null || selectedValuation.validationState === undefined || selectedValuation.validationState.results === undefined ? [] :
      selectedValuation.validationState.results.map(x => x.valuationAttribute);

    const name_show = top_errors.includes(VALUATION_ATTRIBUTES.VALUATION_NAME) ? 'show' : 'hide'; //make this a conditional based on if there a validtion error
    const discountYear_show = top_errors.includes(VALUATION_ATTRIBUTES.DISCOUNT_YEAR) ? 'show' : 'hide'; //make this a conditional based on if there a validtion error - hide if no error
    const discountRate_show = top_errors.includes(VALUATION_ATTRIBUTES.DISCOUNT_RATE) ? 'show' : 'hide'; //make this a conditional based on if there a validtion error
    const yearFrom_show = top_errors.includes(VALUATION_ATTRIBUTES.SCENARIO_YEAR_FROM) ? 'show' : 'hide'; //make this a conditional based on if there a validtion error
    const yearTo_show = top_errors.includes(VALUATION_ATTRIBUTES.SCENARIO_YEAR_TO) ? 'show' : 'hide'; //make this a conditional based on if there a validtion error

    return (
      <div className='valuation-edit' >
        <div className="flex-row">
          <div className="col95"><TextField label="Valuation Name" value={name} onChange={this.onNameChange} type="text" disabled={readOnly} fullWidth spellCheck={false} margin="dense" variant="outlined" onClick={this.handleFocus} InputLabelProps={{ shrink: true }} /></div>
          <div className="col-warning"><span className={'name ' + name_show} title={this.getError(selectedValuation, VALUATION_ATTRIBUTES.VALUATION_NAME)} >{appIcons.getIcon(appIcons.ErrorTriangle, appIconSizes.sm)}</span></div>
        </div>

        <div className="flex-row">
          <div className="col45"><TextField label="Discount Year" fullWidth value={discountStartYear} onChange={this.onDiscountYearChange} onBlur={this.onDiscountYearBlur} type="number" disabled={readOnly} spellCheck={false} margin="dense" variant="outlined" onClick={this.handleFocus} InputLabelProps={{ shrink: true }} /></div>
          <div className="col-warning"><span className={'discount-year ' + discountYear_show} title={this.getError(selectedValuation, VALUATION_ATTRIBUTES.DISCOUNT_YEAR)} >{appIcons.getIcon(appIcons.ErrorTriangle, appIconSizes.sm)}</span></div>
          <div className="col45"><TextField label="Discount Rate (%)" fullWidth value={discountRate} onChange={this.onRateChange} onBlur={this.onRateBlur} type="number" disabled={readOnly} spellCheck={false} margin="dense" variant="outlined" onClick={this.handleFocus} InputLabelProps={{ shrink: true }} /></div>
          <div className="col-warning"><span className={'discount-rate ' + discountRate_show} title={this.getError(selectedValuation, VALUATION_ATTRIBUTES.DISCOUNT_RATE)} >{appIcons.getIcon(appIcons.ErrorTriangle, appIconSizes.sm)}</span></div>
        </div>

        <div className="flex-row">
          <div className="col95 header">
            <div className="section-title"><span>Liquid and Gas Price</span></div>
            <div className="hyperlink-btns">
              <Button className="hyperlink-btn delete" onClick={this.deleteYear} disabled={readOnly || allowDeleteYears} >Delete Year</Button>
              <Button className="hyperlink-btn" onClick={this.addYear} disabled={readOnly} >+ Add Year</Button>
            </div>
          </div>
          <div className="col-warning" />
        </div>

        <div className="table-div-header">
          <Table striped size="sm">
            <thead>
              <tr>
                <th width="20%" colSpan="1">Year</th>
                <th colSpan="1">Liquid Price (US$/bbl)</th>
                <th colSpan="1">Gas Price (US$/mcf)</th>
              </tr>
            </thead>
          </Table >
        </div>

        <div className="table-div">
          <Table className="main-table" striped size="sm">
            <thead className="hidden-head">
              <tr>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
              </tr>
            </thead>
            <tbody>
              {items}
            </tbody>
          </Table >
        </div>

        <div className="flex-row">
          <div className="col95 header">
            <div className="section-title"><span>Scenario</span></div>
            <div className="hyperlink-btns">
              <Button className="hyperlink-btn" onClick={this.resetScenario} disabled={readOnly} >Reset Scenario</Button>
            </div>
          </div>
          <div className="col-warning" />
        </div>

        {isWGE ?
          <div className="flex-row">
            <div className="col95 isWGE">
              <span>Scenario is not applicable to a Westwood Valuation</span>
            </div>
          </div>
          :
          <div>
            <div className="flex-row checkbox">
              <div className="col50">
                <FormControlLabel control={<Checkbox disabled={readOnly} checked={applyToAllYears} onChange={this.onAllYears} name="scenarioYearsCheck" color="primary" />} label="Apply scenario to all years" />
              </div>
              <div className={'col20 ' + scenarioYearsHide}><TextField label="Year from" fullWidth value={scenarioFrom} onChange={this.onYearFromChange} onBlur={this.onYearFromBlur} type="number" disabled={readOnly} spellCheck={false} margin="dense" variant="outlined" onClick={this.handleFocus} InputLabelProps={{ shrink: true }} /></div>
              <div className="col-warning"><span className={'year-from ' + yearFrom_show} title={this.getError(selectedValuation, VALUATION_ATTRIBUTES.SCENARIO_YEAR_FROM)} >{appIcons.getIcon(appIcons.ErrorTriangle, appIconSizes.sm)}</span></div>
              <div className={'col20 ' + scenarioYearsHide}><TextField label="Year to" fullWidth value={scenarioTo} onChange={this.onYearToChange} onBlur={this.onYearToBlur} type="number" disabled={readOnly} spellCheck={false} margin="dense" variant="outlined" onClick={this.handleFocus} InputLabelProps={{ shrink: true }} /></div>
              <div className="col-warning"><span className={'year-to ' + yearTo_show} title={this.getError(selectedValuation, VALUATION_ATTRIBUTES.SCENARIO_YEAR_TO)} >{appIcons.getIcon(appIcons.ErrorTriangle, appIconSizes.sm)}</span></div>
            </div>

            <div className="table-div scenario">
              <Table className="main-table" striped size="sm">
                <thead className="hidden-head">
                  <tr>
                    <th colSpan="1"></th>
                    <th colSpan="1"></th>
                    <th colSpan="1"></th>
                  </tr>
                </thead>
                <tbody>
                  {scenarioItems}
                </tbody>
              </Table >
            </div>
          </div>
        }
        <div className="buttons">
          <Button className="new-btn" onClick={() => this.props.onSetDefault(selectedValuationId)} disabled={noSelectionReadOnly || defaultSelected || editing || newValuation || !selectedValid} >Set as Default</Button>
          <Button className="new-btn" onClick={() => this.props.onCopy(selectedValuationId)} disabled={noSelectionReadOnly || editing || newValuation || !selectedValid} >Copy</Button>
          <Button className="new-btn" onClick={() => this.props.onDelete(selectedValuationId)} disabled={readOnly || defaultSelected || editing || newValuation} >Delete</Button>
          <Button className="new-btn" onClick={() => this.props.onSave(selectedValuation)} disabled={readOnly || !editing} >Save</Button>
          <Button className="new-btn" onClick={this.cancel} disabled={noSelectionReadOnly || (!editing && !newValuation)} >Cancel</Button>
        </div>
      </div >
    );
  }
}

ValuationEditComponent.propTypes = {
  selectedValuation: PropTypes.instanceOf(UserValuations),
  readOnly: PropTypes.bool.isRequired,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSetDefault: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  setActiveEdit: PropTypes.func.isRequired,
  activeEdit: PropTypes.bool.isRequired,
  selectedValid: PropTypes.bool.isRequired
};

export default ValuationEditComponent;
