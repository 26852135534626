import React from 'react';
import PropTypes from 'prop-types';

import './advanced-field-detail-grid-component.scss';
import BaseGridComponent, { createLink, createLinkAndIcons, HEADER_ROW_SINGLE, HEADER_ROW_DOUBLE, HEADER_ROW_TREBLE, HEADER_ROW_QUAD, NUMERIC_SORT, COLUMN_EXTRA_SMALL_SMALL, COLUMN_SMALL, COLUMN_MEDIUM, COLUMN_MEDIUM_MEDIUM, COLUMN_LARGE } from '../../../components/base-grid/base-grid-component';

const AdvancedFieldDetailGridComponent = (props) => {
  const className = 'advanced-field-detail-grid-component' + (props.hasGroupedHeaderRow ? ' has-grouped-header-row' : '');

  function getColumnDef(columnDef) {
    const result = { ...columnDef };

    if (typeof result.sortable !== 'boolean') {
      result.sortable = true;
    }

    if (Array.isArray(result.children)) {
      result.children = result.children.map(obj => getColumnDef(obj));
    }

    return result;
  }

  return (
    <div className={className}>
      <BaseGridComponent
        columnDefs={props.columnDefs.map(obj => getColumnDef(obj))}
        dataRows={props.dataRows}
        headerRowHeight={props.headerRowHeight}
        autoReSizeGrid
        pinnedBottomRows={props.totalRow === undefined ? [] : [props.totalRow]}
        groupHeaderRowHeight={props.groupHeaderRowHeight}
      />
    </div>
  );
};

AdvancedFieldDetailGridComponent.propTypes = {
  hasGroupedHeaderRow: PropTypes.bool.isRequired,
  headerRowHeight: PropTypes.number.isRequired,
  totalRow: PropTypes.object,
  groupHeaderRowHeight: PropTypes.number.isRequired,
};

AdvancedFieldDetailGridComponent.defaultProps = {
  hasGroupedHeaderRow: false,
  headerRowHeight: HEADER_ROW_DOUBLE,
  groupHeaderRowHeight: HEADER_ROW_SINGLE
};

export default AdvancedFieldDetailGridComponent;
export { createLink, createLinkAndIcons, HEADER_ROW_SINGLE, HEADER_ROW_DOUBLE, HEADER_ROW_TREBLE, HEADER_ROW_QUAD, NUMERIC_SORT, COLUMN_EXTRA_SMALL_SMALL, COLUMN_SMALL, COLUMN_MEDIUM, COLUMN_MEDIUM_MEDIUM, COLUMN_LARGE };