import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { appIcons, appIconSizes } from './app-icons';

const AccountDropdownComponent = (props) => {
  const valuationSettings = props.advancedPlus ?
    <DropdownItem onClick={props.manageValuations}>
      {appIcons.getIcon(appIcons.Valuations, appIconSizes.sm)}
      {' Valuation Settings'}
    </DropdownItem>
    : null;

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle style={{ padding: '2px 0 1px 0', fontSize: '10px', marginTop: '7px', marginRight: '5px' }} nav caret title="Account">
        {props.showLabels && <span style={{ verticalAlign: 'super', paddingLeft: '5px', paddingRight: '5px' }}>Account</span>}
        {appIcons.getIcon(appIcons.UserCircle, appIconSizes.md)}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-right">
        {valuationSettings}
        <DropdownItem onClick={props.changePassword}>
          {appIcons.getIcon(appIcons.ChangePassword, appIconSizes.sm)}
          {' Change password'}
        </DropdownItem>
        <DropdownItem onClick={props.changeNotifications}>
          {appIcons.getIcon(appIcons.ChangeNotifications, appIconSizes.sm)}
          {' Change notifications'}
        </DropdownItem>
        <DropdownItem onClick={props.logout}>
          {appIcons.getIcon(appIcons.Logout, appIconSizes.sm)}
          {' Logout'}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

AccountDropdownComponent.propTypes = {
  logout: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  changeNotifications: PropTypes.func.isRequired,
  manageValuations: PropTypes.func.isRequired,
  showLabels: PropTypes.bool.isRequired,
  right: PropTypes.bool.isRequired,
  advancedPlus: PropTypes.bool.isRequired,
};

AccountDropdownComponent.defaultProps = {
  right: true,
  advancedPlus: false,
};

export default AccountDropdownComponent;
