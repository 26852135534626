import L from 'leaflet';

class BaseFilter {
  constructor() {
    this._followMap = false;
    this._mapBounds = null;
  }

  get isSet() {
    return this._followMap;
  }

  get followMap() { return this._followMap; }
  set followMap(value) { this.setFollowMap(value); }
  setFollowMap = (value) => {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "BaseFilter.setFollowMap"');
    }

    if (this._followMap !== value) {
      this._followMap = value;
    }
  }

  get mapBounds() { return this._mapBounds; }
  set mapBounds(value) {
    if (!(value instanceof L.LatLngBounds)) {
      throw new Error('Invalid "value" param supplied to "BaseFilter.mapBounds.set"');
    }

    if (this._mapBounds !== value) {
      this._mapBounds = value;
    }
  }
  get mapBoundsIsSet() { return this._mapBounds instanceof L.LatLngBounds; }

  clone(destFilter) {
    destFilter._followMap = this._followMap;
    destFilter._mapBounds = this._mapBounds === null
      ? null
      : new L.LatLngBounds(this._mapBounds.getSouthWest(), this._mapBounds.getNorthEast());
  }

  clear() {
    this._followMap = false;
  }

  toApiParam() {
    const result = {
      mapBounds: null // JB 16 03 2020 - Pass null into filters - L.latLngBounds(getDefaultMaxBounds());
    };

    if (this._followMap && this._mapBounds !== null) {
      const northEast = this._mapBounds.getNorthEast();
      const southWest = this._mapBounds.getSouthWest();
      result.mapBounds = {
        northEastLat: northEast.lat,
        northEastLng: northEast.lng,
        southWestLat: southWest.lat,
        southWestLng: southWest.lng
      };
    }

    return result;
  }
}

export { BaseFilter };