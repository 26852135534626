import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { downloadFile } from '../shared/helpers/download';
import { TrackingType, AtlasPanel } from '../shared/helpers/enums';
import { appViews } from './app-views';

import { appIcons, appIconSizes } from './app-icons';

const DocumentationDropdownComponent = (props) => {
  const _filename = "assumptions.pdf";
  const _title = "Economics Assumptions and Methodology";

  const onClick = () => {
    //props.appSetState.view.details.showReportByFile(-1, 0, _filename, _title);
    const page = props.appSetState.view.current;
    const panel = props.appSetState.view.current === appViews.Home ? AtlasPanel.Reports : AtlasPanel.None;

    downloadFile(-1, props.appSetState, false, 0, page, TrackingType.ReportDownload, panel, _filename, _title);
  }

  return (
    <Button style={{ padding: '2px 0 1px 0', fontSize: '10px', marginTop: '7px', marginRight: '5px' }} title="Economics Assumptions and Methodology" onClick={onClick}>
      {props.showLabels && <span style={{ verticalAlign: 'super', paddingLeft: '5px', paddingRight: '5px' }}>Assumptions</span>}
      {appIcons.getIcon(appIcons.FilePdf, appIconSizes.md)}
    </Button>
  );
}

DocumentationDropdownComponent.propTypes = {
  showLabels: PropTypes.bool.isRequired,
  right: PropTypes.bool.isRequired
};

DocumentationDropdownComponent.defaultProps = {
  right: false
};

export default DocumentationDropdownComponent;
