import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './base-view-settings-component.scss';
import SelectComponent from './components/select-component';
import TextFieldComponent from './components/text-field-component';
import DatePickerComponent from './components/date-picker-component';
import MultiSelectComponent from './components/multi-select-component';
import NumericFieldComponent from './components/numeric-field-component';
import FollowMapComponent from '../base/components/follow-map-component';

import { AppSetState } from '../../app/app-set-state';
import { KeyValuePair } from '../../models/key-value-pair';
import { appendClassNames } from '../../shared/helpers/common';

const createSplitDiv = (left, right, label = '') => {
  const labelCtrl = typeof label === 'string' && label.length > 0 ? <span className="split-label">{label}</span> : null;
  return (
    <div className="split-div">
      <div className="inner-div">
        {labelCtrl}
        <div className="ctrls-div">
          <div className="split-left">{left}</div>
          <div className="split-right">{right}</div>
        </div>
      </div>
    </div>
  );
};

const BaseViewSettingsComponent = (props) => {
  const onSubmit = (e) => {
    e.preventDefault();
    props.onUpdate(true);
  };

  const onClear = (e) => {
    e.preventDefault();
    const onConfirm = () => {
      props.onClear();
      props.onUpdate(false);
    };
    props.appSetState.messageBox.showYesNoPrompt('Are you sure you want to clear filters?', onConfirm, 'Clear filters...');
  };

  return (
    <div className={appendClassNames('base-view-settings-component', props.className)}>
      <form className="inner-div" onSubmit={onSubmit} autoComplete="false">
        <div className="content-div">
          {props.children}
        </div>
        <div className="footer-div">
          <Button className="update-btn" disabled={!props.advancedPlus} style={props.showUpdate ? { visibility: 'visible' } : { visibility: 'hidden' }} type="submit">{props.updateButtonName}</Button>
          <Button className="clear-btn" onClick={onClear} style={props.showClear ? { visibility: 'visible' } : { visibility: 'hidden' }} >Clear</Button>
        </div>
      </form>
    </div>
  );
};

const lookupPropType = PropTypes.arrayOf(PropTypes.instanceOf(KeyValuePair).isRequired).isRequired;

BaseViewSettingsComponent.propTypes = {
  appSetState: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  updateButtonName: PropTypes.string.isRequired,
  showClear: PropTypes.bool.isRequired,
  advancedPlus: PropTypes.bool.isRequired,
  showUpdate: PropTypes.bool.isRequired
};

BaseViewSettingsComponent.defaultProps = {
  updateButtonName: 'Update',
  showClear: true,
  onClear: () => { },
  onUpdate: () => { },
  advancedPlus: true,
  showUpdate: true
};

export default BaseViewSettingsComponent;
export { lookupPropType, createSplitDiv, FollowMapComponent, SelectComponent, TextFieldComponent, NumericFieldComponent, DatePickerComponent, MultiSelectComponent };