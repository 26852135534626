import React from 'react';

const InactiveCorporateWarningComponent = (props) => {
  return (
    <div>
      This company is not active in the UK or Norway and does not currently hold any licences, fields, discoveries or prospects. It has however, previously been active and drilled at least one exploration or appraisal well in the past.
    </div>
  );
}

export default InactiveCorporateWarningComponent;