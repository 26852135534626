import React from 'react';
import PropTypes from 'prop-types';
import { valueTypes } from '../../helpers/value-handler';
import { PieChartData } from './chart-data';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import './generic-pie-chart.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

const GenericPieChart = (props) => {
  const { chartDataItems, valueType } = props;

  const chartData = {
    labels: chartDataItems.map(obj => obj.label + ' (' + valueTypes.FullDisplay(valueType, obj.value) + ')'),
    datasets: [{
      data: chartDataItems.map(obj => obj.value),
      backgroundColor: chartDataItems.map(obj => obj.color)
    }]
  };

  const tooltipLabel = (tooltipModel) => {
    const x = chartDataItems[tooltipModel.index];
    return x.label + ' ' + valueTypes.FullDisplay(valueType, x.value);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: false, 
      callbacks: {
        label: tooltipLabel
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start'
      }
    }
  };

  return (
    <div className="generic-pie"><Pie data={chartData} options={chartOptions} /></div>
  );
}

GenericPieChart.propTypes = {
  chartDataItems: PropTypes.arrayOf(PropTypes.instanceOf(PieChartData).isRequired).isRequired,
  valueType: valueTypes.propType
};

export default GenericPieChart;
