const defaultOptions = {
  radius: 6,
  border: true,
  borderColor: '#3388ff',
  borderOpacity: 1,
  borderWidth: 2,
  fill: true,
  fillColor: '#3388ff',
  fillOpacity: 1,
  popup: undefined
};

class CircleMarkerModel {
  constructor(lat, lng, options = {}) {
    if (typeof lat !== 'number') { throw new Error('Invalid \'lat\' supplied to \'CircleMarkerModel.ctor\''); }
    if (typeof lng !== 'number') { throw new Error('Invalid \'lng\' supplied to \'CircleMarkerModel.ctor\''); }
    if (typeof options !== 'object') { throw new Error('Invalid \'options\' supplied to \'CircleMarkerModel.ctor\''); }

    this.lat = lat;
    this.lng = lng;
    this.options = { ...defaultOptions, ...options };
  }

  get latLng() { return [this.lat, this.lng]; }
}

export { CircleMarkerModel };