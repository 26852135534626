import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessageComponent = (props) => {
  return (
    <div style={{ marginLeft: props.marginLeft }}>
      {props.message}
      <br />
      For more information, please contact <a href="mailto:sales@westwoodenergy.com">sales@westwoodenergy.com</a> or call +44 (0)2037945380.<br />
    </div>
  );
}

ErrorMessageComponent.propTypes = {
  message: PropTypes.string.isRequired,
  marginLeft: PropTypes.string,
};

ErrorMessageComponent.defaultProps = {
  marginLeft: '10px'
}

export default ErrorMessageComponent;