import { BaseFilter } from "../base/base-filter";

class DiscoveryFilter extends BaseFilter {
  constructor() {
    super();

    this._discoveryIds = undefined;
    this._operatorIds = undefined;
    this._participantIds = undefined;
    this._countryIds = undefined;
    this._areaIds = undefined;
    this._minReserves = undefined;
    this._maxReserves = undefined;
    this._reservoirAgeIds = undefined;
    this._licenceIds = undefined;
    this._quadrantIds = undefined;
    this._blockIds = undefined;
    this._startYear = undefined;
    this._endYear = undefined;
    this.clear();
  }

  get isSet() {
    const result = super.isSet === true ||
      this.discoveryIdsIsSet === true ||
      this.operatorIdsIsSet === true ||
      this.participantIdsIsSet === true ||
      this.countryIdsIsSet === true ||
      this.areaIdsIsSet === true ||
      this.quadrantIdsIsSet === true ||
      this.licenceIdsIsSet === true ||
      this.classificationIsSet === true ||
      this.blockIdsIsSet === true ||
      this.minReservesIsSet === true ||
      this.maxReservesIsSet === true ||
      this.startYearIsSet === true ||
      this.endYearIsSet === true ||
      this.reservoirAgeIdsIsSet === true ||
      this.licenceStatusIsSet === true;
    return result;
  }

  get discoveryIds() { return this._discoveryIds; }
  set discoveryIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "DiscoveryFilter.discoveryIds.set"');
    }

    if (this._discoveryIds !== value) {
      this._discoveryIds = value;
    }
  }
  get discoveryIdsIsSet() { return this._discoveryIds.length > 0; }

  get operatorIds() { return this._operatorIds; }
  set operatorIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "DiscoveryFilter.operatorIds.set"');
    }

    if (this._operatorIds !== value) {
      this._operatorIds = value;
    }
  }
  get operatorIdsIsSet() { return this._operatorIds.length > 0; }

  get participantIds() { return this._participantIds; }
  set participantIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "DiscoveryFilter.participantIds.set"');
    }

    if (this._participantIds !== value) {
      this._participantIds = value;
    }
  }
  get participantIdsIsSet() { return this._participantIds.length > 0; }

  get countryIds() { return this._countryIds; }
  set countryIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "DiscoveryFilter.countryIds.set"');
    }

    if (this._countryIds !== value) {
      this._countryIds = value;
    }
  }
  get countryIdsIsSet() { return this._countryIds.length > 0; }

  get areaIds() { return this._areaIds; }
  set areaIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "DiscoveryFilter.areaIds.set"');
    }

    if (this._areaIds !== value) {
      this._areaIds = value;
    }
  }
  get areaIdsIsSet() { return this._areaIds.length > 0; }
  
  get quadrantIds() { return this._quadrantIds; }
  set quadrantIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'string').length > 0) {
      throw new Error('Invalid "value" param supplied to "BlockFilter.quadrantIds.set"');
    }

    if (this._quadrantIds !== value) {
      this._quadrantIds = value;
    }
  }
  get quadrantIdsIsSet() { return this._quadrantIds.length > 0; }

  get licenceIds() { return this._licenceIds; }
  set licenceIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "BlockFilter.licenceIds.set"');
    }

    if (this._licenceIds !== value) {
      this._licenceIds = value;
    }
  }
  get licenceIdsIsSet() { return this._licenceIds.length > 0; }

  get classification() { return this._classification; }
  set classification(key) {
    if (key !== null && typeof key !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "DiscoveryFilter.classification.set"');
    }

    if (this._classification !== key) {
      this._classification = key;
    }
  }
  get classificationIsSet() { return this._classification !== null; }

  get blockIds() { return this._blockIds; }
  set blockIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "BlockFilter.blockIds.set"');
    }

    if (this._blockIds !== value) {
      this._blockIds = value;
    }
  }
  get blockIdsIsSet() { return this._blockIds.length > 0; }

  get minReserves() { return this._minReserves; }
  set minReserves(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "DiscoveryFilter.minReserves.set"');
    }

    if (this._minReserves !== value) {
      this._minReserves = value;
    }
  }
  get minReservesIsSet() { return this._minReserves !== null; }

  get maxReserves() { return this._maxReserves; }
  set maxReserves(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "DiscoveryFilter.maxReserves.set"');
    }

    if (this._maxReserves !== value) {
      this._maxReserves = value;
    }
  }
  get maxReservesIsSet() { return this._maxReserves !== null; }

  get startYear() { return this._startYear; }
  set startYear(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "DiscoveryFilter.startYear.set"');
    }

    if (this._startYear !== value) {
      this._startYear = value;
    }
  }
  get startYearIsSet() { return this._startYear !== null; }

  get endYear() { return this._endYear; }
  set endYear(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "DiscoveryFilter.endYear.set"');
    }

    if (this._endYear !== value) {
      this._endYear = value;
    }
  }
  get endYearIsSet() { return this._endYear !== null; }

  get reservoirAgeIds() { return this._reservoirAgeIds; }
  set reservoirAgeIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "DiscoveryFilter.reservoirAgeIds.set"');
    }

    if (this._reservoirAgeIds !== value) {
      this._reservoirAgeIds = value;
    }
  }
  get reservoirAgeIdsIsSet() { return this._reservoirAgeIds.length > 0; }

  get licenceStatus() { return this._licenceStatus; }
  set licenceStatus(key) {
    if (key !== null && typeof key !== 'number') {
      throw new Error('Invalid "value" param supplied to "FieldFilter.licenceStatus.set"');
    }

    if (this._licenceStatus !== key) {
      this._licenceStatus = key;
    }
  }
  get licenceStatusIsSet() { return this._licenceStatus !== 0; }

  clear() {
    super.clear();

    this._discoveryIds = [];
    this._operatorIds = [];
    this._participantIds = [];
    this._countryIds = [];
    this._areaIds = [];
    this._classification = null;
    this._reservoirAgeIds = [];
    this._quadrantIds = [];
    this._blockIds = [];
    this._licenceIds = [];
    this._minReserves = null;
    this._maxReserves = null;
    this._startYear = null;
    this._endYear = null;
    this._licenceStatus = 0;
  }

  clone() {
    const result = new DiscoveryFilter();
    super.clone(result);
    result._discoveryIds = [...this._discoveryIds];
    result._operatorIds = [...this._operatorIds]
    result._participantIds = [...this._participantIds];
    result._countryIds = [...this._countryIds];
    result._areaIds = [...this._areaIds];
    result._classification = this._classification;
    result._reservoirAgeIds = [...this._reservoirAgeIds];
    result._quadrantIds = [...this._quadrantIds];
    result._blockIds = [...this._blockIds];
    result._licenceIds = [...this.licenceIds];
    result._minReserves = this._minReserves;
    result._maxReserves = this._maxReserves;
    result._startYear = this._startYear;
    result._endYear = this._endYear;
    result._licenceStatus = this._licenceStatus;
    return result;
  }

  clearInvalidValues() {
    if (this.minReservesIsSet && this.maxReservesIsSet && this._minReserves > this._maxReserves) {
      this.minReserves = null;
      this.maxReserves = null;
    }

    if (this.startYearIsSet && this.endYearIsSet && this._startYear > this._endYear) {
      this.startYear = null;
      this.endYear = null;
    }
  }

  toApiParam() {
    const result = super.toApiParam();
    result.discoveryIds = this._discoveryIds;
    result.operatorIds = this._operatorIds;
    result.participantIds = this._participantIds;
    result.countryIds = this._countryIds;
    result.areaIds = this._areaIds;
    result.classification = this._classification;
    result.reservoirAgeIds = this._reservoirAgeIds;
    result.quadrantIds = this._quadrantIds;
    result.blockIds = this._blockIds;
    result.licenceIds = this._licenceIds;
    result.minReserves = this._minReserves;
    result.maxReserves = this._maxReserves;
    result.startYear = this._startYear;
    result.endYear = this._endYear;
    result.licenceStatus = this._licenceStatus;
    return result;
  }
}

export { DiscoveryFilter };