import React from 'react';
import PropTypes from 'prop-types';

import { WellDetailResource } from '../../../models/well-detail';
import { valueTypes } from '../../../shared/helpers/value-handler';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const ResourceTableComponent = (props) => {
  const { resources, onProspectClick, onDiscoveryClick, onFieldClick, onOperatorClick } = props;

  const getRow = (row, rowIndex) => {
    let name = row.name;
    let onNameClick = null;
    if (typeof row.id === 'number') {
      if (row.status === 'Discovery' && typeof onDiscoveryClick === 'function') {
        onNameClick = (e) => { e.preventDefault(); onDiscoveryClick(row.id); };
      } else if (row.status === 'Prospect' && typeof onProspectClick === 'function') {
        onNameClick = (e) => { e.preventDefault(); onProspectClick(row.id); };
      } else if (typeof onFieldClick === 'function') {
        onNameClick = (e) => { e.preventDefault(); onFieldClick(row.id); };
      }
    }
    if (onNameClick !== null) {
      name = <a href="/" onClick={onNameClick}>{name}</a>;
    }

    let currentOperatorName = row.currentOperatorName;
    if (typeof onOperatorClick === 'function' && typeof row.currentOperatorId === 'number') {
      const onCurrentOperatorClick = (e) => {
        e.preventDefault();
        onOperatorClick(row.currentOperatorId);
      };
      currentOperatorName = <a href="/" onClick={onCurrentOperatorClick}>{currentOperatorName}</a>;
    }

    return (
      <tr key={'ownership-row-' + rowIndex.toString()}>
        <td style={{ width: '17%' }}>{name}</td>
        <td style={{ width: '10%' }}>{row.status}</td>
        <td style={{ width: '15%' }}>{currentOperatorName}</td>
        <td style={{ width: '12%' }}>{row.reservoirAge}</td>
        <td style={{ width: '8%' }}>{row.type}</td>
        <td style={{ width: '10%' }}>{row.trapType}</td>
        <td style={{ width: '5%' }}>{valueTypes.ValueOnly(valueTypes.feet, row.reservoirDepthFt)}</td>
        <td style={{ width: '8%' }}>{row.reservoirConditon}</td>
        <td style={{ width: '5%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.liquidResourcesMmb)}</td>
        <td style={{ width: '5%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.gasResourcesBcf)}</td>
        <td style={{ width: '5%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.totalResourcesMmboe)}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Name</td>
          <td>Status</td>
          <td>Current operator</td>
          <td>Primary objective</td>
          <td>Phase</td>
          <td>Trap type</td>
          <td>Reservoir depth ({valueTypes.UnitString(valueTypes.feet)})</td>
          <td>Reservoir condition</td>
          <td>Liquid resources ({valueTypes.UnitString(valueTypes.mmb)})</td>
          <td>Gas resources ({valueTypes.UnitString(valueTypes.bcf)})</td>
          <td>Total resources ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
        </tr>
      </thead>
      <tbody>
        {resources.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
}

ResourceTableComponent.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.instanceOf(WellDetailResource)).isRequired,
  onProspectClick: PropTypes.func,
  onDiscoveryClick: PropTypes.func,
  onFieldClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default ResourceTableComponent;
