import React from 'react';
import PropTypes from 'prop-types';

import { GridExcelExport } from './grid-excel-export';

import { Block } from '../../models/Block';
import { appIcons } from '../../app/app-icons';
import { AppSetState } from '../../app/app-set-state';
import BaseGridComponent, { createLink, createLinkAndIcon } from '../../components/base-grid/base-grid-component';
import { COLUMN_SMALL, COLUMN_EXTRA_SMALL_SMALL, COLUMN_EXTRA_LARGE, HEADER_ROW_DOUBLE } from '../../shared/components/data-grid/ag-grid-component';
import { valueTypes } from '../../shared/helpers/value-handler';

const BlockGridComponent = (props) => {
  const { view } = props.appSetState;

  const renderBlockName = (params) => {
    const result = createLinkAndIcon(
      params.data.name, 'Show Details', () => view.details.showBlock(params.data.id),
      appIcons.MapMarkerAlt, 'Show on map', () => props.showOnMap(params.data));
    return result;
  };

  const renderLicence = (params) => {
    if (typeof params.data.licenceId === 'number') {
      return createLink(params.data.licence, 'Show details', () => view.details.showLicence(params.data.licenceId));
    }
    return params.data.licence;
  };

  const renderOperatorName = (params) => {
    const { operatorName, operatorId } = params.data;

    if (typeof operatorName === 'string' && operatorName.length > 0 && typeof operatorId === 'number') {
      return createLink(operatorName, 'Show details', () => view.details.showCorporate(operatorId));
    }
    return operatorName;
  };

  const renderArea = (params) => {
    return valueTypes.ValueOnly(valueTypes.generic1, params.value);
  }

  const BLOCK_COLUMN_DEFS = [
    {
      headerName: "Block Name",
      field: 'name',
      sortable: true,
      minWidth: COLUMN_EXTRA_LARGE,
      cellRenderer: renderBlockName
    },
    {
      headerName: "Country",
      field: "country",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Area",
      field: "area",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Operator",
      field: 'operatorName',
      sortable: true,
      minWidth: COLUMN_SMALL,
      cellRenderer: renderOperatorName
    },
    {
      headerName: "Block \nStatus",
      field: "blockStatus",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: 'Licence',
      field: 'licence',
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      cellRenderer: renderLicence
    },
    {
      headerName: "Licence \nRound",
      field: "licenceRound",
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL
    },
    {
      headerName: "Area \n(" + valueTypes.UnitString(valueTypes.kmsq) + ")",
      field: "areaSQKM",
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      cellRenderer: renderArea
    }

  ];

  return <BaseGridComponent columnDefs={BLOCK_COLUMN_DEFS} dataRows={props.dataRows} autoReSizeGrid={true} headerRowHeight={HEADER_ROW_DOUBLE} />;
};

BlockGridComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.instanceOf(Block).isRequired).isRequired,
  showOnMap: PropTypes.func.isRequired
}

export default BlockGridComponent;
export { GridExcelExport };
