import React from 'react';
import ReactDOMServer from 'react-dom/server';
import parse from 'html-react-parser';

import { valueTypes } from '../../shared/helpers/value-handler';
import { appIcons, appIconSizes } from '../../app/app-icons';
import { getIcon } from '../../shared/helpers/leaflet/helpers/control-helper';
import AGGridComponent, {
  AgGridExcelExport,
  COLUMN_EXTRA_EXTRA_SMALL,
  COLUMN_EXTRA_SMALL,
  COLUMN_EXTRA_SMALL_SMALL,
  COLUMN_SMALL,
  COLUMN_MEDIUM,
  COLUMN_MEDIUM_MEDIUM,
  COLUMN_LARGE,
  COLUMN_LARGE_LARGE,
  COLUMN_EXTRA_LARGE,
  COLUMN_WIDE,
  COLUMN_EXTRA_WIDE,
  COLUMN_FULL_WIDTH,
  HEADER_ROW_SINGLE,
  HEADER_ROW_DOUBLE,
  HEADER_ROW_TREBLE,
  HEADER_ROW_QUAD,
  NUMERIC_SORT
} from '../../shared/components/data-grid/ag-grid-component';
import { appViews } from '../../app/app-views';

const BaseGridComponent = (props) => {
  return <AGGridComponent columnDefs={props.columnDefs} gridData={props.dataRows} autoReSizeGrid={props.autoReSizeGrid} headerRowHeight={props.headerRowHeight} groupHeaderRowHeight={props.groupHeaderRowHeight} pinnedTopRows={props.pinnedTopRows} pinnedBottomRows={props.pinnedBottomRows} />;
};

//Not used
//const createSeparator = () => {
//  const result = document.createElement('div');
//  result.style.display = 'inline-block';
//  result.style.width = '1px';
//  result.style.height = '10px';
//  result.style.marginLeft = '3px';
//  result.style.marginRight = result.style.marginLeft;
//  return result;
//};

const createLink = (name, title, onClick) => {
  const result = React.createElement('a', { 'href': '#', 'title': title, 'aria-label': title, 'onClick': (e) => { e.preventDefault(); onClick(); }, 'key': name }, name);
  return result;
}

const createIcon = (appIcon, title, onClick) => {
  const icon = getIcon(appIcons.getIconDefinition(appIcon, false), { size: 'sm', title: title });
  var iconimageLink = parse(icon);
  const link = createLink(iconimageLink, title, onClick);

  const result = React.createElement('span', {}, link);

  return result;
};

const createAppViewIcon = (appView, title, onClick) => {
  const icon = appViews.getIcon(appView, appIconSizes.sm);
  const result = createLink(ReactDOMServer.renderToStaticMarkup(icon), title, onClick);
  return result;
};

const isInvalidIcon = (icon) => {
  // null === blank icon
  if (icon !== null) {
    if (typeof icon !== 'object') {
      return true;
    }
    if (appIcons.isInvalid(icon.appIcon)) {
      return true;
    }
    if (typeof icon.title !== 'string') {
      return true;
    }
    if (icon.onClick !== null && typeof icon.onClick !== 'function') {
      return true;
    }
  }
  return false;
};

const createLinkAndIcons = (name, title, onClick, icons) => {
  if (typeof name !== 'string') {
    throw new Error('Invalid "name" param supplied to "createLinkAndIcons"');
  }
  if (typeof title !== 'string') {
    throw new Error('Invalid "title" param supplied to "createLinkAndIcons"');
  }
  if (onClick !== null && typeof onClick !== 'function') {
    throw new Error('Invalid "onClick" param supplied to "createLinkAndIcons"');
  }
  if (!Array.isArray(icons) || icons.filter(obj => isInvalidIcon(obj)).length > 0) {
    throw new Error('Invalid "icons" param supplied to "createLinkAndIcons"');
  }

  var key = 1;
  const atag = createLink(name, title, onClick);
  const nameSpan = React.createElement('span', { style: { flex: '1 2 auto', overflow: 'hidden', flexDirection: 'row' }, 'key': key }, atag); 

  var iconSpan2 = [];
  for (var loop = 0; loop < icons.length; loop++) {
    key += 1;
    const icon = icons[loop];
    const iconatag2 = icon !== null ? createIcon(icon.appIcon, icon.title, icon.onClick) : null;

    iconSpan2[loop] = React.createElement('span', { style: { marginLeft: '3px', minWidth: '16px', textAlign: 'right' }, 'key': key }, iconatag2);
  }

  const result = React.createElement('div', {
    style: { width: '100%', display: 'flex', flexDirection: 'row' }
  },
    [
      nameSpan,
      iconSpan2.map(x => x)
    ]
  );
  return result;

};

const createLinkAndIcon = (name, title, onClick, appIcon, iconTitle, onIconClick) => {
  const result = createLinkAndIcons(name, title, onClick, [{ appIcon: appIcon, title: iconTitle, onClick: onIconClick }]);
  return result;
};

const dateRenderer = (params) => {
  const value = params.value instanceof Date
    //? date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }) // dd MMM yyyy
    ? params.value.toLocaleDateString('en-GB') // dd/mm/yyyy
    : params.value;
  return value;
};

const nullNumberRenderer = (params) => {
  const result = params.value === null
    ? '-'
    : valueTypes.ValueOnly(valueTypes.generic1, params.value);
  return result;
};

const excelExport = (fileName, columnDefs, dataRows, includeHiddenCols = false) => {
  new AgGridExcelExport().export('Atlas 5 - ' + fileName + '.xlsx', fileName, columnDefs, dataRows, includeHiddenCols);
};

export default BaseGridComponent;
export {
  //createSeparator,
  createLink,
  createIcon,
  createAppViewIcon,
  createLinkAndIcon,
  createLinkAndIcons,
  dateRenderer,
  nullNumberRenderer,
  excelExport,
  COLUMN_EXTRA_EXTRA_SMALL,
  COLUMN_EXTRA_SMALL,
  COLUMN_EXTRA_SMALL_SMALL,
  COLUMN_SMALL,
  COLUMN_MEDIUM,
  COLUMN_MEDIUM_MEDIUM,
  COLUMN_LARGE,
  COLUMN_LARGE_LARGE,
  COLUMN_EXTRA_LARGE,
  COLUMN_WIDE,
  COLUMN_EXTRA_WIDE,
  COLUMN_FULL_WIDTH,
  HEADER_ROW_SINGLE,
  HEADER_ROW_DOUBLE,
  HEADER_ROW_TREBLE,
  HEADER_ROW_QUAD,
  NUMERIC_SORT
};
