class WellResource {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "WellResource.ctor"');
    }

    if (obj.resourceId !== null && typeof obj.resourceId !== 'number') {
      throw new Error('Invalid "obj.resourceId" param supplied to "WellResource.ctor"');
    }
    this.resourceId = obj.resourceId;

    if (typeof obj.resourceName !== 'string') {
      throw new Error('Invalid "obj.resourceName" param supplied to "WellResource.ctor"');
    }
    this.resourceName = obj.resourceName;

    if (typeof obj.isProspect !== 'boolean') {
      throw new Error('Invalid "obj.isProspect" param supplied to "WellResource.ctor"');
    }
    this.isProspect = obj.isProspect;

    if (typeof obj.isDiscovery !== 'boolean') {
      throw new Error('Invalid "obj.isDiscovery" param supplied to "WellResource.ctor"');
    }
    this.isDiscovery = obj.isDiscovery;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "WellResource.FromArray"');
    }

    const result = array.map(obj => new WellResource(obj));
    return result;
  }
}

export { WellResource };