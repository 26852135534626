import React from 'react';
import PropTypes from 'prop-types';

import { FieldDetail } from '../models/portfolio/field-detail'
import BaseTableComponent from  '../../../shared/components/detail-view-grid-layout/base-table-component';

const DevelopmentDetailsComponent = (props) => {
  const { resource } = props;

  const getRows = () => {
    return (
      <React.Fragment>
        <tr>
          <td>Status</td>
          <td>{resource.fiscalResource.status}</td>
        </tr>
        <tr>
          <td>Phase</td>
          <td>{resource.type}</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>{resource.fiscalResource.developmentConcept}</td>
        </tr>
        <tr>
          <td>Liquid Export</td>
          <td>{resource.fiscalResource.hub.oilPipeline}</td>
        </tr>
        <tr>
          <td>Gas Export</td>
          <td>{resource.fiscalResource.hub.gasPipeline}</td>
        </tr>
        <tr>
          <td>Production Hub Electrification Status</td>
          <td>{resource.fiscalResource.hub.electrificationStatus}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent className="one-col-key-value">
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

DevelopmentDetailsComponent.propTypes = {
  resource: PropTypes.instanceOf(FieldDetail).isRequired
};

export default DevelopmentDetailsComponent;
