import L from 'leaflet';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons';

import './layers-control.scss';
import { GroupModel } from './models';

import { ContextLayerController } from '../../helpers/context-layer-controller';
import { createButton, addEventHandler, getObjectClassName, disableMapEvents, getMapEvents, restoreMapEvents, hasClass, addClass, removeClass } from '../../helpers/control-helper';

L.Control.LayersControl = L.Control.extend({
  initialize: function (options) { this.options = options; },
  onAdd: function () { return this.options.container; }
});

const VISIBLE_CLASS_NAME = 'layers-visible';

class LayersControl {
  constructor(leafletMap, contextLayerController) {
    this._objectClassName = getObjectClassName(this);

    if (!(leafletMap instanceof L.Map)) {
      throw new Error('Invalid "leafletMap" param supplied to "' + this._objectClassName + '.ctor"');
    }
    if (!(contextLayerController instanceof ContextLayerController)) {
      throw new Error('Invalid "contextLayerController" param supplied to "' + this._objectClassName + '.ctor"');
    }

    this._mapEvents = null;
    this._groups = [];
    this._leafletMap = leafletMap;
    this._contextLayerController = contextLayerController;

    this._container = L.DomUtil.create('div', 'layers-control');

    const btnTmp = L.DomUtil.create('div', 'lc-button leaflet-bar', this._container);
    addEventHandler(btnTmp, 'mouseenter', this._disableMapEvents);
    addEventHandler(btnTmp, 'mouseleave', this._enableMapEvents);
    this._button = createButton(btnTmp, '', faLayerGroup, () => this.toggleOpen(), { title: 'Layers' });

    this._content = L.DomUtil.create('div', 'lc-content leaflet-bar', this._container);
    addEventHandler(this._content, 'mouseenter', this._disableMapEvents);
    addEventHandler(this._content, 'mouseleave', this._enableMapEvents);
    this._contentInner = L.DomUtil.create('div', 'lc-content-inner', this._content);

    this._updateContent();

    this._leafletMap.addControl(new L.Control.SearchControl({ position: 'topleft', container: this._container }));
  }

  destroy() {

    if (this._groups !== null && this._groups !== undefined) {
      Object.keys(this._groups).forEach(key => {
        this._groups[key].destroy();
      });
    }

    delete this._objectClassName;
    delete this._mapEvents;
    delete this._groups;
    delete this._leafletMap;
    
    delete this._contextLayerController;
    delete this._container;
    delete this._button;
    delete this._content;
    delete this._contentInner;
    delete this._disableMapEvents;
    delete this._enableMapEvents;
    delete this.__proto__;

    delete this;
  }


  toggleOpen() {
    if (hasClass(this._container, VISIBLE_CLASS_NAME)) {
      this._content.style.maxHeight = 0;
      this._contentInner.style.maxHeight = 0;
      removeClass(this._container, VISIBLE_CLASS_NAME);
    } else {
      const maxHeight = this._leafletMap._size.y * 0.55;
      this._content.style.maxHeight = maxHeight + 'px';
      this._contentInner.style.maxHeight = (maxHeight - 8) + 'px';
      addClass(this._container, VISIBLE_CLASS_NAME);
    }
  }

  updateDownloadShapeFileButtons(onClick = null) {
    this._groups.forEach(obj => obj.updateDownloadShapeFileButton(onClick));
  }

  _disableMapEvents = () => {
    if (this._mapEvents === null) {
      this._mapEvents = getMapEvents(this._leafletMap);
      disableMapEvents(this._leafletMap);
    }
  }

  _enableMapEvents = () => {
    if (this._mapEvents !== null) {
      restoreMapEvents(this._leafletMap, this._mapEvents);
      this._mapEvents = null;
    }
  }

  _updateContent() {
    this._contentInner.innerHTML = '';

    const labels = L.DomUtil.create('div', 'lc-labels', this._contentInner);
    L.DomUtil.create('span', '', labels).innerHTML = 'Label';
    L.DomUtil.create('span', '', labels).innerHTML = 'Layer';

    this._groups.length = 0;
    for (var loop = 0; loop < this._contextLayerController.contextLayerModels.length; loop++) {
      const contextLayerModel = this._contextLayerController.contextLayerModels[loop];
      let group = this._groups.find(obj => obj.name === contextLayerModel.groupDisplayName);
      if (group === undefined) {
        group = new GroupModel(this._contextLayerController, contextLayerModel.groupDisplayName);
        this._groups.push(group);
      }
      group.addLayer(contextLayerModel);
    }
    this._groups.forEach(obj => { obj.sortLayers(); obj.render(this._contentInner); });
  }
}

export { LayersControl };