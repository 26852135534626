function versionsMatch(version1, version2) {
  //TODO: param checks;
  const result = version1.major === version2.major && version1.minor === version2.minor && version1.build === version2.build;
  return result;
}

function checkVersion(version1, version2, onFail) {
  //TODO: check onFail is a func
  if (!versionsMatch(version1, version2)) {
    onFail();
  }
}

function checkParamsMatch(params1, params2) {
  const params1Keys = Object.keys(params1).filter(obj => obj !== 'panel');
  const params2Keys = Object.keys(params2).filter(obj => obj !== 'panel');

  if (params1Keys.length !== params2Keys.length) {
    return false;
  }

  for (var loop = 0; loop < params1Keys.length; loop++) {
    const params1Val = params1[params1Keys[loop]];
    const params2Val = params2[params1Keys[loop]];
    if (params1Val !== params2Val) {
      return false;
    }
  }

  return true;
}

export { checkVersion, versionsMatch, checkParamsMatch };