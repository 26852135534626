import React from 'react';
import PropTypes from 'prop-types';

import { LicenceDataRow } from './licence-data-row';
import { GridExcelExport } from './grid-excel-export';

import { appIcons } from '../../app/app-icons';
import { AppSetState } from '../../app/app-set-state';
import { COLUMN_SMALL, COLUMN_EXTRA_LARGE, HEADER_ROW_DOUBLE } from '../../shared/components/data-grid/ag-grid-component';
import BaseGridComponent, { createLinkAndIcons } from '../../components/base-grid/base-grid-component';
import { valueTypes } from '../../shared/helpers/value-handler';

const LicenceGridComponent = (props) => {

  const licenceNumberCellRenderer = (params) => {
    const icons = [];
    if (params.data.shapes.polygons.length > 0) {
      icons.push({ appIcon: appIcons.MapMarkerAlt, title: 'Show on map', onClick: () => props.showOnMap(params.data) });
    }
    if (params.data.link !== null) {
      icons.push({ appIcon: appIcons.Link, title: 'Copy link...', onClick: () => props.copyLink(params.data) });
    }
    const result = createLinkAndIcons(params.data.licenceNumber, 'Show Details', () => props.appSetState.view.details.showLicence(params.data.id), icons);
    return result;
  };

  const renderArea = (params) => {
    return valueTypes.ValueOnly(valueTypes.generic1, params.value);
  }

  const LICENCE_COLUMN_DEFS = [
    {
      headerName: "Licence Number",
      field: 'licenceNumber',
      sortable: true,
      minWidth: COLUMN_EXTRA_LARGE,
      cellRenderer: licenceNumberCellRenderer
    },
    {
      headerName: "Country",
      field: "countryName",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Area",
      field: "area",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Licence Status",
      field: "status",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Licence Type",
      field: "licenceTypeName",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Licence Round",
      field: "roundName",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Area \n(" + valueTypes.UnitString(valueTypes.kmsq) + ")",
      field: "areaSQKM",
      sortable: true,
      minWidth: COLUMN_SMALL,
      cellRenderer: renderArea
    }
  ];

  return <BaseGridComponent columnDefs={LICENCE_COLUMN_DEFS} dataRows={props.dataRows} autoReSizeGrid={true} headerRowHeight={HEADER_ROW_DOUBLE} />;
};

LicenceGridComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.instanceOf(LicenceDataRow).isRequired).isRequired,
  showOnMap: PropTypes.func.isRequired,
  copyLink: PropTypes.func.isRequired
}

export default LicenceGridComponent;
export { GridExcelExport };
