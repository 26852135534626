import React from 'react';
import PropTypes from 'prop-types';

import { CorporateGridExcelExport } from './corporate-grid-excel-export';

import { AppSetState } from '../../app/app-set-state';
import { valueTypes } from '../../shared/helpers/value-handler';
import BaseGridComponent, { createLink, nullNumberRenderer, NUMERIC_SORT } from '../../components/base-grid/base-grid-component';
import { FasterPortfolioCorporateSummary } from '../../components/fiscal/models/portfolio/faster-portfolio';
import { COLUMN_SMALL, HEADER_ROW_QUAD, COLUMN_LARGE, COLUMN_MEDIUM, COLUMN_MEDIUM_MEDIUM } from '../../shared/components/data-grid/ag-grid-component';

const CorporateGridComponent = (props) => {
  const { view } = props.appSetState;

  function showCorporate(id) {
    const filter = props.getFilter();
    view.details.showCorporate(id, null, null, filter)
  }

  const CORPORATE_COLUMN_DEFS = [
    {
      headerName: "Company Name",
      field: 'name',
      sortable: true,
      minWidth: COLUMN_LARGE,
      cellRenderer: (params) => createLink(params.data.name, 'Show Details', () => showCorporate(params.data.id))
    },
    {
      headerName: "Country(s)",
      field: "country",
      sortable: true,
      minWidth: COLUMN_LARGE
    },
    {
      headerName: "Company Category",
      field: "corporateCategory",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: 'Net Remaining \nLiquid Reserves (' + valueTypes.UnitString(valueTypes.mmb) + ')',
      field: 'netRemainingLiquidMMB',
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: 'Net Remaining \nGas Reserves (' + valueTypes.UnitString(valueTypes.bcf) + ')',
      field: 'netRemainingGasBCF',
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Net Remaining \nReserves \n(" + valueTypes.UnitString(valueTypes.mmmboe) + ")",
      field: "netRemainingReservesMMBOE",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: 'Net ' + props.currentYear + '\nProduction\n(' + valueTypes.UnitString(valueTypes.mboed) + ')',
      field: 'netCurrentYearProductionBOEPD',
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: 'Net ' + props.currentYear + '\nEquity Based\nCO2 Emissions \n(' + valueTypes.UnitString(valueTypes.ktCO2) + ')',
      field: 'netCurrentYearEquityCO2EmissionsKTCO2',
      sortable: true,
      minWidth: COLUMN_MEDIUM_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: 'Net ' + props.currentYear + '\nOperational\nCO2 Emissions \n(' + valueTypes.UnitString(valueTypes.ktCO2) + ')',
      field: 'netCurrentYearOperationalCO2EmissionsKTCO2',
      sortable: true,
      minWidth: COLUMN_MEDIUM_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Net Remaining \nPre-Tax Cashflow \n(" + valueTypes.UnitString(valueTypes.usdMM) + ")",
      field: "netRemainingPreTaxCashflowUSDMM",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Net Remaining \nCapex \n(" + valueTypes.UnitString(valueTypes.usdMM) + ")",
      field: "netRemainingCapexUSDMM",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: 'Net ' + props.currentYear + ' Opex \n(' + valueTypes.UnitString(valueTypes.usdMM) + ')',
      field: 'netCurrentYearOpexUSDMM',
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: 'Net ' + props.currentYear + ' Opex/boe \n(' + valueTypes.UnitString(valueTypes.usdBOE) + ')',
      field: 'netCurrentYearOpexPerBoe',
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Net Remaining \nOpex \n(" + valueTypes.UnitString(valueTypes.usdMM) + ")",
      field: "netRemainingOpexUSDMM",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Net Remaining \nOpex/boe \n(" + valueTypes.UnitString(valueTypes.usdBOE) + ")",
      field: "netRemainingOpexPerBoe",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Net Remaining \nAbex \n(" + valueTypes.UnitString(valueTypes.usdMM) + ")",
      field: "netRemainingAbexUSDMM",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Number of \nProducing Fields",
      field: "producingFields",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT
    },
    {
      headerName: "Net Remaining \nReserves in \nProducing Fields \n(" + valueTypes.UnitString(valueTypes.mmmboe) + ")",
      field: "netRemainingReservesInProducingFieldsMMBOE",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Number of \nUnder Development \nFields",
      field: "underDevelopmentFields",
      sortable: true,
      minWidth: COLUMN_SMALL,
      sortingOrder: NUMERIC_SORT
    },
    {
      headerName: "Net Remaining \nReserves in Under \nDevelopment Fields (" + valueTypes.UnitString(valueTypes.mmmboe) + ")",
      field: "netRemainingReservesInUnderDevelopmentFieldsMMBOE",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Number of \nNear Term \nDevelopments",
      field: "nearTermFields",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT
    },
    {
      headerName: "Net Remaining \nReserves in Near \nTerm Developments (" + valueTypes.UnitString(valueTypes.mmmboe) + ")",
      field: "netRemainingReservesInNearTermFieldsMMBOE",
      sortable: true,
      minWidth: 150,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Number of \nOther Fields",
      field: "otherFields",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT
    },
    {
      headerName: "Percent Operated \nFields",
      field: "percentOperatedFields",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Number of \nUndeveloped Discoveries",
      field: "discoveries",
      sortable: true,
      sortingOrder: NUMERIC_SORT,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Net Resources in \nUndeveloped Discoveries \n(" + valueTypes.UnitString(valueTypes.mmmboe) + ")",
      field: "discoveryResourcesMMBOE",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Gross Area \n(" + valueTypes.UnitString(valueTypes.kmsq) + ")",
      field: "grossAreaSQKM",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Net Area \n(" + valueTypes.UnitString(valueTypes.kmsq) + ")",
      field: "netAreaSQKM",
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
  ];

  return <BaseGridComponent columnDefs={CORPORATE_COLUMN_DEFS} dataRows={props.dataRows} autoReSizeGrid={true} headerRowHeight={HEADER_ROW_QUAD} />;
};

CorporateGridComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.instanceOf(FasterPortfolioCorporateSummary).isRequired).isRequired,
  currentYear: PropTypes.number.isRequired,
  getFilter: PropTypes.func.isRequired //TODO: use correct instanceOf
}

CorporateGridComponent.defaultProps = {
  currentYear: new Date().getFullYear()
};

export default CorporateGridComponent;
export { CorporateGridExcelExport };
