import React from 'react';
import PropTypes from 'prop-types';

import './table-component.scss';

import { valueTypes } from '../../../../shared/helpers/value-handler';
import { CashflowAnnual } from '../../../../components/fiscal/models/portfolio/cashflow-annual';
import BaseTableComponent from '../../../../shared/components/detail-view-grid-layout/base-table-component';

const HubCashflowTableComponent = (props) => {
  const { annual, totalRow } = props;
 
  const getRow = (row, rowIndex) => {
    const key = 'row-' + rowIndex.toString();
    return (
      <tr key={key}>
        <td className="center-align">{rowIndex === -1 ? 'Total' : row.year}</td>
        <td className="center-align">{rowIndex === -1 ? valueTypes.ValueOnly(valueTypes.generic1, row.liquidProductionMMB) : valueTypes.ValueOnly(valueTypes.generic1, row.liquidProductionMbod)}</td>
        <td className="center-align">{rowIndex === -1 ? valueTypes.ValueOnly(valueTypes.generic1, row.gasProductionBCF) : valueTypes.ValueOnly(valueTypes.generic1, row.gasProductionMmscfd)}</td>
        <td className="center-align">{rowIndex === -1 ? valueTypes.ValueOnly(valueTypes.generic1, row.totalMmboe) : valueTypes.ValueOnly(valueTypes.generic1, row.combinedMboed)}</td>
        <td className="center-align">{valueTypes.ValueOnly(rowIndex === -1 ? valueTypes.generic1 : valueTypes.generic1, row.totalRevenueUsdMM)}</td>
        <td className="center-align">{valueTypes.ValueOnly(rowIndex === -1 ? valueTypes.generic1 : valueTypes.generic1, row.opexUsdMM)}</td>
        <td className="center-align">{valueTypes.ValueOnly(rowIndex === -1 ? valueTypes.generic1 : valueTypes.generic1, row.totalEmissionsChargeUsdMM)}</td>
        <td className="center-align">{valueTypes.ValueOnly(rowIndex === -1 ? valueTypes.generic1 : valueTypes.generic1, row.totalTariffUsdMM)}</td>
        <td className="center-align">{valueTypes.ValueOnly(rowIndex === -1 ? valueTypes.generic1 : valueTypes.generic1, row.costForLossesOrDepreciationUsdMM)}</td>
        <td className="center-align">{valueTypes.ValueOnly(rowIndex === -1 ? valueTypes.generic1 : valueTypes.generic1, row.abexUsdMM)}</td>
        <td className="center-align">{valueTypes.ValueOnly(rowIndex === -1 ? valueTypes.generic1 : valueTypes.generic1, row.preTaxCashflowUsdMM)}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr>
          <th className="center-align" rowSpan="3">Year</th>
          <th colSpan="3" className="center-align underscore">Production</th>
          <th rowSpan="2" className="center-align">Revenue</th>
          <th rowSpan="2" className="center-align">Opex</th>
          <th rowSpan="2" className="center-align">Emissions charge</th>
          <th rowSpan="2" className="center-align">Export tariff</th>
          <th rowSpan="2" className="center-align">Capex</th>
          <th rowSpan="2" className="center-align">Abex</th>          
          <th rowSpan="2" className="center-align">Pre-tax cashflow</th> 
        </tr>
        <tr>
          <th className="center-align">Liquid</th>
          <th className="center-align">Gas</th>
          <th className="center-align">Total</th>
        </tr>
        <tr>

          <th className="center-align">({valueTypes.UnitString(valueTypes.mboed)})</th>
          <th className="center-align">({valueTypes.UnitString(valueTypes.mmscfd)})</th>
          <th className="center-align">({valueTypes.UnitString(valueTypes.mboed)})</th>
          <th className="center-align">({valueTypes.UnitString(valueTypes.usdMM)})</th>
          <th className="center-align">({valueTypes.UnitString(valueTypes.usdMM)})</th>
          <th className="center-align">({valueTypes.UnitString(valueTypes.usdMM)})</th>
          <th className="center-align">({valueTypes.UnitString(valueTypes.usdMM)})</th>
          <th className="center-align">({valueTypes.UnitString(valueTypes.usdMM)})</th>
          <th className="center-align">({valueTypes.UnitString(valueTypes.usdMM)})</th>
          <th className="center-align">({valueTypes.UnitString(valueTypes.usdMM)})</th>
        </tr>
      </thead>
      <tbody>
        {annual.map((row, rowIndex) => getRow(row, rowIndex))}
        {
          getRow(totalRow, -1) // JB - 21 04 2020 - removed pending further information from Emma & Yvonne
        }
      </tbody>
    </BaseTableComponent>

  );
};

HubCashflowTableComponent.propTypes = {
  annual: PropTypes.arrayOf(PropTypes.instanceOf(CashflowAnnual)).isRequired,
  totalRow: PropTypes.instanceOf(CashflowAnnual)
};

export default HubCashflowTableComponent;
