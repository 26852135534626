import React from 'react';
import PropTypes from 'prop-types';

import { appViews } from '../app/app-views';
import { appIcons, appIconSizes } from '../app/app-icons';
import HeaderButtonComponent from './header-button-component';

const BUTTON_ICON_SIZE = appIconSizes.md;

const HeaderComponent = (props) => {
  const { appView, fullScreen, backClick, forwardClick, copyLinkClick } = props;

  let title = '';
  if (appViews.isValid(appView)) {
    title = appViews.getDisplayName(appView, true);
  }
  if (props.title.length > 0) {
    if (title.length > 0) {
      title += ': ';
    }
    title += props.title;
  }

  return (
    <React.Fragment>
      <span className="detail-header-icon">{appViews.isValid(appView) && appViews.getIcon(appView, BUTTON_ICON_SIZE)}</span>
      <span className="detail-header-title">{title}</span>
      {props.children}
      <HeaderButtonComponent iconDef={appIcons.ArrowLeft} iconSize={BUTTON_ICON_SIZE} title="Back" disabled={typeof backClick !== 'function'} onClick={backClick} />
      <HeaderButtonComponent iconDef={appIcons.ArrowRight} iconSize={BUTTON_ICON_SIZE} title="Forward" disabled={typeof forwardClick !== 'function'} onClick={forwardClick} />
      <HeaderButtonComponent iconDef={appIcons.Link} iconSize={BUTTON_ICON_SIZE} title="Copy Link" disabled={typeof copyLinkClick !== 'function'} onClick={copyLinkClick} />
      <HeaderButtonComponent iconDef={fullScreen ? appIcons.WindowRestore : appIcons.WindowMaximize} iconSize={BUTTON_ICON_SIZE} title={fullScreen ? 'Restore' : 'Maximize'} onClick={() => props.setFullScreen(!fullScreen)} />
      <HeaderButtonComponent iconDef={appIcons.Close} iconSize={BUTTON_ICON_SIZE} title="Close" onClick={props.close} />
    </React.Fragment>
  );
};

HeaderComponent.propTypes = {
  appView: appViews.propType,
  title: PropTypes.string.isRequired,
  backClick: PropTypes.func,
  forwardClick: PropTypes.func,
  fullScreen: PropTypes.bool.isRequired,
  setFullScreen: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  copyLinkClick: PropTypes.func
};

export default HeaderComponent;
export { BUTTON_ICON_SIZE };