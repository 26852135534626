import React from 'react';
import PropTypes from 'prop-types';

import { HubDetail } from '../../../../models/hub-detail';
import BaseTableComponent from '../../../../shared/components/detail-view-grid-layout/base-table-component';

const DetailsComponent = (props) => {
  const { hub, onOperatorClick } = props;

  const getRows = () => {
    let operatorName = hub.operatorName;
    if (typeof onOperatorClick === 'function' && typeof hub.operatorId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onOperatorClick(hub.operatorId);
      };
      operatorName = <a href="/" title="Show Details" onClick={onClick}>{operatorName}</a>;
    }

    return (
      <React.Fragment>
        <tr>
          <td>Country</td>
          <td>{hub.countryName}</td>
        </tr>
        <tr>
          <td>Area</td>
          <td>{hub.areaName}</td>
        </tr>
        <tr>
          <td>Number of producing fields</td>
          <td>{hub.producingFields}</td>
        </tr>
        <tr>
          <td>Number of under development fields</td>
          <td>{hub.underDevelopmentFields}</td>
        </tr>
        <tr>
          <td>Number of near term developments</td>
          <td>{hub.nearTermFields}</td>
        </tr>
        <tr>
          <td>Operator</td>
          <td>{operatorName}</td>
        </tr>
        <tr>
          <td>Liquid export</td>
          <td>{hub.oilPipeline}</td>
        </tr>
        <tr>
          <td>Gas export</td>
          <td>{hub.gasPipeline}</td>
        </tr>
        <tr>
          <td>Electrification Status</td>
          <td>{hub.electrificationStatus}</td>
        </tr>
        <tr>
          <td>COP year</td>
          <td>{hub.hubCOPYear}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent className="one-col-key-value">
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

DetailsComponent.propTypes = {
  hub: PropTypes.instanceOf(HubDetail).isRequired,
  onOperatorClick: PropTypes.func
};

export default DetailsComponent;
