class AppValuation {
  constructor() {
    this._valuation = {};
    this._baseValuation = {};
    this._detailValuation = null;
    this._modelOpen = false;
    this._advancedPlus = false;
    this._valuationHasChanged = false;
    this._refreshComparison = false;
  }

  get valuation() { return this._valuation; }
  set valuation(value) {
    if (typeof value !== 'object') { throw new Error('Invalid valuation supplied to AppValuation'); }
    if (!(this._valuation.variableId === value.variableId && this._valuation.dateAmended === value.dateAmended)) {
      this._valuation = value;
      this._valuationHasChanged = false;
    }
  }

  get baseValuation() { return this._baseValuation; }
  set baseValuation(value) {
    if (typeof value !== 'object') { throw new Error('Invalid baseValuation supplied to AppValuation'); }
    if (!(this._baseValuation.variableId === value.variableId && this._baseValuation.dateAmended === value.dateAmended)) {
      this._baseValuation = value;
    }
  }

  get detailValuation() { return this._detailValuation; }
  set detailValuation(value) {
    if (typeof value !== 'object') { throw new Error('Invalid detailValuation supplied to AppValuation'); }
    if (this._detailValuation === null || (!(this._detailValuation.variableId === value.variableId && this._detailValuation.dateAmended === value.dateAmended))) {
      this._detailValuation = value;
    }
  }

  get modelOpen() { return this._modelOpen; }
  set modelOpen(value) {
    if (typeof value !== 'boolean') { throw new Error('Invalid modelOpen supplied to AppValuation'); }
    if (this._modelOpen === true) { this.refreshComparison = true; }
    if (this._modelOpen !== value) {
      this._modelOpen = value;
    }
  }

  get refreshComparison() { return this._refreshComparison; }
  set refreshComparison(value) {
    if (typeof value !== 'boolean') { throw new Error('Invalid refreshComparison supplied to AppValuation'); }
    if (this._refreshComparison !== value) {
      this._refreshComparison = value;
    }
  }

  get advancedPlus() { return this._advancedPlus; }
  set advancedPlus(value) {
    if (typeof value !== 'boolean') { throw new Error('Invalid advancedPlus supplied to AppValuation'); }
    if (this._advancedPlus !== value) {
      this._advancedPlus = value;
    }
  }

  get valuationHasChanged() { return this._valuationHasChanged; }
  set valuationHasChanged(value) {
    if (typeof value !== 'boolean') { throw new Error('Invalid valuationHasChanged supplied to AppValuation'); }
    if (this._valuationHasChanged !== value) {
      this._valuationHasChanged = value;
    }
  }
  
  // FUNCTIONS
  clearDetailValuation = () => {
    this._detailValuation = null;
  }

  getDetailValuationId = () => {
    return this.detailValuation === null ? this.valuation.variableId : this.detailValuation.variableId;
  }
}

export { AppValuation };