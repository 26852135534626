import { appViews } from "../../app/app-views";
import { AppSetState } from "../../app/app-set-state";
import { valueTypes } from '../../shared/helpers/value-handler';

import { BaseExcelExportColumn, BaseExcelExport } from "../../shared/helpers/base-excel-export";

class GridExcelExport {
  export(appSetState, dataRows, mapBaseExcelExport = null, mapWorkbook = null, sheetname = null) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "GridExcelExport.export"');
    }

    const baseExcelExport = mapBaseExcelExport !== null ? mapBaseExcelExport : new BaseExcelExport();
    const workbook = mapWorkbook !== null ? mapWorkbook : baseExcelExport.createWorkbook();

    const appView = sheetname !== null ? sheetname : appViews.getDisplayName(appViews.Block);
    const colDefs = [
      new BaseExcelExportColumn("Block Name", 'name'),
      new BaseExcelExportColumn("Country", "country"),
      new BaseExcelExportColumn("Area", "area"),
      new BaseExcelExportColumn("Operator", "operatorName"),
      new BaseExcelExportColumn("Block Status", "blockStatus"),
      new BaseExcelExportColumn('Licence', 'licence'),
      new BaseExcelExportColumn("Licence Round", "licenceRound"),
      new BaseExcelExportColumn("Area (" + valueTypes.UnitString(valueTypes.kmsq) + ")", "areaSQKM", '0.00')
    ];

    baseExcelExport.writeSheet(workbook, appView, dataRows, colDefs);
    if (mapBaseExcelExport === null) baseExcelExport.writeWorkbook(workbook, baseExcelExport.getAtlas5WorkbookPrefix() + appView + baseExcelExport.getAtlas5WorkbookSuffix());
  }
}

export { GridExcelExport };
