import React from 'react';
import PropTypes from 'prop-types';

import CorporateFieldsTableComponent from './corporate-fields/corporate-fields-table-component';

import { CorporateDetail } from '../../../models/corporate-detail';
import { createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedCorporateFieldsTableComponent = (props) => {
  const { corporate } = props;
  var fieldTable;

  const hasFields = corporate.netFields !== null && corporate.netFields.length > 0;
  if (hasFields) {
    fieldTable = <CorporateFieldsTableComponent currentCorporateId={corporate.corporateId} netFields={corporate.netFields} currentYear={corporate.currentYear} discountRate={corporate.cashflow.discountRate} onFieldClick={props.onFieldClick} onHubClick={props.onHubClick} onOperatorClick={props.onOperatorClick} />;
  } else {
    fieldTable = (<div>No fields</div>);
  }

  return (
    <div ref={props.innerRef}>
      {createSection100(fieldTable, 'Fields')}
    </div>
  );
};

AdvancedCorporateFieldsTableComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  corporate: PropTypes.instanceOf(CorporateDetail).isRequired,
  onFieldClick: PropTypes.func,
  onHubClick: PropTypes.func,
  onOperatorClick: PropTypes.func,
};

export default AdvancedCorporateFieldsTableComponent;