import { WgeCorporateBlue, WgeDarkGrey } from './wge-colors';

const LightGrey = '#DADEE3';
const DarkGrey = WgeDarkGrey;
const Highlight = WgeDarkGrey;
const Toolbar = WgeCorporateBlue;
const MapShapeBorder = '#40FCFD';

export {
  LightGrey,
  DarkGrey,
  Highlight,
  Toolbar,
  MapShapeBorder
}