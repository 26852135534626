class LocalStorage {
  constructor(key) {
    if (typeof key !== 'string' || key.trim().length === 0) {
      throw new Error('Invalid \'key\' param supplied to \'LocalStorage.ctor\'');
    }

    this._key = key;
  }

  get localStorage() { return JSON.parse(localStorage.getItem(this._key)); }
  set localStorage(value) { localStorage.setItem(this._key, JSON.stringify(value)); }
}

export { LocalStorage }