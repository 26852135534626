import React from 'react';
import PropTypes from 'prop-types';

import { FieldRow } from '../../../models/block-detail';

import { valueTypes } from '../../../shared/helpers/value-handler';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const FieldsTableComponent = (props) => {
  const { fields, onFieldClick, onHubClick, onOperatorClick } = props;

  const getRow = (row, rowIndex) => {
    let name = row.name;
    if (typeof onFieldClick === 'function' && typeof row.id === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onFieldClick(row.id);
      };
      name = <a href="/" title="Show Details" onClick={onClick}>{name}</a>;
    }

    let hubName = row.hubName;
    if (typeof onHubClick === 'function' && typeof row.hubId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onHubClick(row.hubId);
      };
      hubName = <a href="/" title="Show Details" onClick={onClick}>{hubName}</a>;
    }

    let operatorName = row.operatorName;
    if (typeof onOperatorClick === 'function' && typeof row.operatorId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onOperatorClick(row.operatorId);
      };
      operatorName = <a href="/" title="Show Details" onClick={onClick}>{operatorName}</a>;
    }

    return (
      <tr key={'field-row-' + rowIndex.toString()}>
        <td style={{ width: '18%' }}>{name}</td>
        <td style={{ width: '12%' }}>{row.status}</td>
        <td style={{ width: '18%' }}>{operatorName}</td>
        <td style={{ width: '18%' }}>{hubName}</td>
        <td style={{ width: '17%' }}>{typeof row.reserves === 'number' ? valueTypes.ValueOnlyForTable(valueTypes.mmmboe, row.reserves) : '-'}</td>
        <td style={{ width: '17%' }}>{typeof row.production === 'number' ? valueTypes.ValueOnlyForTable(valueTypes.mboed, row.production) : '-'}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Field Name</td>
          <td>Development Status</td>
          <td>Current Operator</td>
          <td>Hub</td>
          <td>Remaining Reserves ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
          <td>{props.currentYear + ' Production (' + valueTypes.UnitString(valueTypes.mboed) + ')'}</td>
        </tr>
      </thead>
      <tbody>
        {fields.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
}

FieldsTableComponent.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.instanceOf(FieldRow)).isRequired,
  currentYear: PropTypes.number.isRequired,
  onFieldClick: PropTypes.func,
  onHubClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default FieldsTableComponent;
