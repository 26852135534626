import React from 'react';
import PropTypes from 'prop-types'

import KeyFactsComponent from './body/key-facts-component';
import ResourceTableComponent from './body/resource-table-component';
import WellInformationComponent from './body/well-information-component';
import PlannedWellInformationComponent from './body/planned-well-information-component';

import { WellDetail } from '../../models/well-detail';

import { assignColours } from '../../shared/helpers/array-color-helper';
import { WellStatus } from '../../shared/components/models/gmatrix3-enums';
import MapComponent from '../../components/fiscal/map/map-component';
import { searchResultType, ContextLayerModel, latLng } from '../../shared/helpers/leaflet/leaflet-helper';
import OwnersPieChartComponent from '../../shared/components/owners-pie-chart/owners-pie-chart-component';
import DetailViewGridLayoutComponent, { createSection100, createSection, sectionTypes } from '../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const WellDetailComponent = (props) => {
  const { well, appSetState } = props;

  const onProspectClick = (id) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" supplied to "WellDetailsComponent.onProspectClick"');
    }

    appSetState.view.details.showProspect(id);
  }

  const onDiscoveryClick = (id) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" supplied to "WellDetailsComponent.onDiscoveryClick"');
    }

    appSetState.view.details.showDiscovery(id);
  }

  const onFieldClick = (id) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" supplied to "WellDetailsComponent.onFieldClick"');
    }

    appSetState.view.details.showField(id);
  }

  const onLicenceClick = (licenceId) => {
    if (typeof licenceId !== 'number') {
      throw new Error('Invalid "licenceId" supplied to "WellDetailsComponent.onLicenceClick"');
    }

    appSetState.view.details.showLicence(licenceId);
  }

  const onCorporateClick = (corporateId) => {
    if (typeof corporateId !== 'number') {
      throw new Error('Invalid "corporateId" supplied to "WellDetailsComponent.onCorporateClick"');
    }

    appSetState.view.details.showCorporate(corporateId);
  };

  assignColours(well.owners, 'color');

  const resources = <ResourceTableComponent resources={well.resource} onFieldClick={onFieldClick} onProspectClick={onProspectClick} onDiscoveryClick={onDiscoveryClick} onOperatorClick={onCorporateClick} />;
  const hasOwners = well.owners && well.owners.length > 0 && well.operatorOnly === false;
  const leftPanel = (
    <React.Fragment>
      {createSection100(<KeyFactsComponent well={well} onLicenceClick={onLicenceClick} onOperatorClick={onCorporateClick} />, 'Key facts')}
      {createSection100(well.gmStatus === WellStatus.Future ? <PlannedWellInformationComponent well={well} /> : <WellInformationComponent well={well} />, 'Details')}
      {hasOwners && createSection100(<div style={{ height: '200px' }}><OwnersPieChartComponent owners={well.owners} /></div>, 'Participation')}
    </React.Fragment>
  );
  const rightPanel = <div style={{ height: '100%' }}><MapComponent geoserverUrl={props.geoserverUrl} contextLayers={props.contextLayers} center={latLng(well.shape.lat, well.shape.lng)} zoom={searchResultType.defaultZoomLevel(searchResultType.Well)} shapes={[well.shape]} /></div>;

  return (
    <DetailViewGridLayoutComponent>
      {createSection([
        { content: leftPanel, sectionType: sectionTypes.section50, noMarginVertical: true },
        { content: rightPanel, sectionType: sectionTypes.section50 }
      ])}
      {well.resource && well.resource.length > 0 && createSection100(resources, 'Resources')}
    </DetailViewGridLayoutComponent>
  );
};

WellDetailComponent.propTypes = {
  well: PropTypes.instanceOf(WellDetail).isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
  appSetState: PropTypes.instanceOf(Object).isRequired, 
  geoserverUrl: PropTypes.string.isRequired
};

export default WellDetailComponent;