import { objectToEnum } from './common';

const valueTypes = objectToEnum({
  generic1: 1,
  generic2: 2,
  generic2ZeroDash: 3,
  generic3: 4,
  usdMM: 10,
  usdBOE: 20,
  usdMCF: 25,
  percent: 30,
  percent1: 31,
  percent2: 32,
  percentZeroDash: 33,
  equity: 40,
  mmb: 50,
  bcf: 60,
  mmmboe: 70,
  mboed: 80,
  mmscfd: 90,
  feet: 100,
  psi: 110,
  farenheit: 120,
  millidarcies: 130,
  api: 140,
  days: 150,
  kmsq: 160,
  ktCO2: 170
}, false);

const dateTypes = objectToEnum({
  AtlasDisplay: 1,
}, false);

valueTypes.UnitString = (valueType) => {
  switch (valueType) {
    case valueTypes.generic1:
    case valueTypes.generic2:
    case valueTypes.generic2ZeroDash:
      return null;
    case valueTypes.usdMM: return 'US$MM';
    case valueTypes.usdBOE: return 'US$/boe';
    case valueTypes.usdMCF: return 'US$/mcf';
    case valueTypes.percent:
    case valueTypes.percent1:
    case valueTypes.percent2:
    case valueTypes.percentZeroDash:
    case valueTypes.equity: return '%';
    case valueTypes.mmb: return 'mmbbl';
    case valueTypes.bcf: return 'bcf';
    case valueTypes.mmmboe: return 'mmboe';
    case valueTypes.mboed: return 'kboepd';
    case valueTypes.mmscfd: return 'mmscfpd';
    case valueTypes.feet: return 'ft';
    case valueTypes.psi: return 'psi';
    case valueTypes.farenheit: return '°F';
    case valueTypes.millidarcies: return 'mD';
    case valueTypes.api: return '°';
    case valueTypes.days: return 'days';
    case valueTypes.kmsq: return 'km2';
    case valueTypes.ktCO2: return 'ktCO2';
    default: throw new Error('Unsupported \'valueType\' param (' + valueType + ') supplied to \'valueTypes.GetDisplayName\'');
  }
}

valueTypes.GetPrecision = (valueType, value) => {
  switch (valueType) {
    case valueTypes.generic0: return 0;
    case valueTypes.generic1: return 1;
    case valueTypes.generic2:
    case valueTypes.generic2ZeroDash: return 2;
    case valueTypes.generic3: return 3;
    case valueTypes.equity:
    case valueTypes.days:
      return null;
    case valueTypes.usdMM:
    case valueTypes.usdBOE:
    case valueTypes.usdMCF:
    case valueTypes.percent:
    case valueTypes.percentZeroDash:
    case valueTypes.mboed:
    case valueTypes.mmscfd:
    case valueTypes.feet:
    case valueTypes.psi:
    case valueTypes.farenheit:
    case valueTypes.millidarcies:
    case valueTypes.api:
    case valueTypes.kmsq:
    case valueTypes.ktCO2:
      return (value > -1 && value < 1 && value !== 0) ? 2 : 0;
    case valueTypes.percent1:
      return 1;
    case valueTypes.percent2:
      return 2;
    case valueTypes.mmb:
    case valueTypes.bcf:
    case valueTypes.mmmboe:
      return (value < 5) ? 1 : 0;
    default: throw new Error('Unsupported \'valueType\' param (' + valueType + ') supplied to \'valueType.GetPrecision\'');
  }
}

valueTypes.ReplaceZeroWithDash = (valueType, value) => {
  if (value !== 0) return value;
  switch (valueType) {
    case valueTypes.generic1:
    case valueTypes.generic2:
    case valueTypes.equity:
    case valueTypes.usdMM:
    case valueTypes.usdBOE:
    case valueTypes.usdMCF:
    case valueTypes.percent:
    case valueTypes.mboed:
    case valueTypes.mmscfd:
    case valueTypes.percent1:
    case valueTypes.percent2:
    case valueTypes.mmb:
    case valueTypes.bcf:
    case valueTypes.mmmboe:
    case valueTypes.ktCO2:
      return value;
    case valueTypes.feet:
    case valueTypes.psi:
    case valueTypes.farenheit:
    case valueTypes.millidarcies:
    case valueTypes.api:
    case valueTypes.days:
    case valueTypes.percentZeroDash:
    case valueTypes.generic2ZeroDash:
    case valueTypes.kmsq:
      return null;
    default: throw new Error('Unsupported \'valueType\' param (' + valueType + ') supplied to \'valueType.ReplaceZeroWithDash\'');
  }
}

valueTypes.SpaceOrNot = (valueType) => {
  switch (valueType) {
    case valueTypes.generic1:
    case valueTypes.generic2:
    case valueTypes.usdMM:
    case valueTypes.usdBOE:
    case valueTypes.usdMCF:
    case valueTypes.mboed:
    case valueTypes.mmscfd:
    case valueTypes.percent1:
    case valueTypes.percent2:
    case valueTypes.mmb:
    case valueTypes.bcf:
    case valueTypes.mmmboe:
    case valueTypes.feet:
    case valueTypes.psi:
    case valueTypes.farenheit:
    case valueTypes.millidarcies:
    case valueTypes.api:
    case valueTypes.days:
    case valueTypes.kmsq:
    case valueTypes.ktCO2:
      return ' ';
    case valueTypes.equity:
    case valueTypes.percent:
      return '';
    default: throw new Error('Unsupported \'valueType\' param (' + valueType + ') supplied to \'valueType.ReplaceZeroWithDash\'');
  }
}

valueTypes.FullDisplay = (valueType, value) => {
  if (typeof value !== 'number' && value !== null) {
    throw new Error('Invalid value param supplied to valueTypes.Display');
  }
  var places = valueTypes.GetPrecision(valueType, value);
  var suffix = valueTypes.UnitString(valueType);
  value = valueTypes.ReplaceZeroWithDash(valueType, value);

  var result = value === null ? '-' : places === null ? value.toLocaleString() : value.toFixed(places).toLocaleString();
  result += valueTypes.SpaceOrNot(valueType) + suffix;
  return result;
}

valueTypes.ValueOnly = (valueType, value) => {
  if (typeof value !== 'number' && value !== null) {
    throw new Error('Invalid value param supplied to valueTypes.Display');
  }
  var places = valueTypes.GetPrecision(valueType, value);
  value = valueTypes.ReplaceZeroWithDash(valueType, value);

  var result = value === null ? '-' : places === null ? value.toLocaleString() : value.toFixed(places).toLocaleString();
  return result;
}

valueTypes.ValueOnlyForTable = (valueType, value) => {
  if (typeof value !== 'number' && value !== null) {
    throw new Error('Invalid value param supplied to valueTypes.Display');
  }
  var places = valueTypes.GetPrecision(valueType, value);
  //value = valueTypes.ReplaceZeroWithDash(valueType, value);

  //var result = value === null ? 0 : places === null ? value.toLocaleString() : value.toFixed(places).toLocaleString();
  var result = value === null ? 0.0 : places === null ? value : value.toFixed(places);
  return parseFloat(result);
}

dateTypes.GetFormatString = (dateType) => {
  switch (dateType) {
    case dateTypes.AtlasDisplay:
      return 'DD-MMM-YY';
    default: throw new Error('Unsupported \'dateType\' param (' + dateType + ') supplied to \'dateType.GetFormatString\'');
  }
}

Object.freeze(valueTypes);
Object.freeze(dateTypes);

export { valueTypes, dateTypes };
