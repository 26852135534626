import L from 'leaflet';
import { faMinusSquare, faPlusSquare, faArrowToBottom, faToggleOn, faToggleOff } from '@fortawesome/pro-light-svg-icons';

import { ContextLayerModel } from '../../models/context-layer-model';
import { hasClass, toggleClass, getIcon, addEventHandler } from '../../helpers/control-helper';

const GROUP_BODY_COLLAPSED_CLASS_NAME = 'collapsed';
const LAYER_SYMBOLOGY_VISIBLE_CLASS_NAME = 'visible';

class GroupModel {
  constructor(contextLayerController, name) {
    if (typeof name !== 'string') {
      throw new Error('Invalid "name" param supplied to "GroupModel.ctor"');
    }

    this._contextLayerController = contextLayerController;
    this._name = name;
    this._updating = false;
    this._container = null;
    this._layers = [];
    this._bodyToggle = null;
    this._downloadShapeFileButton = null;
    this._onDownloadShapeFileClick = null;
    this._labelsToggle = null;
    this._layersToggle = null;
  }

  destroy() {
    if (this._layers !== null) {
      Object.keys(this._layers).forEach(key => {
        this._layers[key].destroy();
      });
    }

    delete this._contextLayerController.__proto__;
    delete this._contextLayerController.properties;
    delete this._contextLayerController.map;

    delete this._contextLayerController;
    delete this._name;
    delete this._updating;
    delete this._container;
    delete this._layers;
    delete this._bodyToggle;
    delete this._downloadShapeFileButton;
    delete this._onDownloadShapeFileClick;
    delete this._labelsToggle;
    delete this._layersToggle;

    delete this;
  }

  get geoserverUrl() {
    return this._contextLayerController.geoserverUrl;
  }

  get name() {
    return this._name;
  }

  get updating() {
    return this._updating;
  }

  get bodyVisible() {
    return !hasClass(this._container, GROUP_BODY_COLLAPSED_CLASS_NAME);
  }
  set bodyVisible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "GroupModel.bodyVisible.set"');
    }

    if (this.bodyVisible !== value) {
      toggleClass(this._container, GROUP_BODY_COLLAPSED_CLASS_NAME);
      this.updateBodyToggle();
    }
  }

  get labelsVisible() {
    return this._layers.filter(obj => obj.labelsVisible).length === this._layers.length;
  }
  set labelsVisible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "LayerModel.labelsVisible.set"');
    }

    if (this.labelsVisible !== value) {
      this._updating = true;
      this._layers.forEach(obj => obj.labelsVisible = value);
      this._updating = false;
      this.updateLabelsToggle();
      this.updateMap();
    }
  }

  get layersVisible() {
    return this._layers.filter(obj => obj.layerVisible).length === this._layers.length;
  }
  set layersVisible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "LayerModel.layersVisible.set"');
    }

    if (this.layersVisible !== value) {
      this._updating = true;
      this._layers.forEach(obj => obj.layerVisible = value);
      this._updating = false;
      this.updateLayersToggle();
      this.updateMap();
    }
  }

  addLayer(contextLayerModel) {
    this._layers.push(new LayerModel(this, contextLayerModel));
  }

  sortLayers() {
    function sortFunc(a, b) {
      if (a.layerOrder > b.layerOrder) return 1;
      if (b.layerOrder > a.layerOrder) return -1;
      return 0;
    }

    this._layers = this._layers.sort(sortFunc);
  }

  updateMap() {
    this._contextLayerController.update();
  }

  _downloadShapeFile() {
    if (typeof this._onDownloadShapeFileClick === 'function') {
      const contextLayerModel = this._layers[0]._contextLayerModel;
      this._onDownloadShapeFileClick(contextLayerModel.groupDisplayName, contextLayerModel.countryId);
    }
  }

  updateBodyToggle() {
    const visible = this.bodyVisible;
    const icon = visible ? faMinusSquare : faPlusSquare;
    const title = visible ? 'Collapse' : 'Expand';
    this._bodyToggle.innerHTML = getIcon(icon, { size: '1x', style: '', title: title });
  }

  updateLabelsToggle() {
    const icon = this.labelsVisible ? faToggleOn : faToggleOff;
    const title = this.labelsVisible ? 'Hide All Labels' : 'Show All Labels';
    this._labelsToggle.innerHTML = getIcon(icon, { size: '1x', style: 'margin-right: 5px', title: title });
  }

  updateLayersToggle() {
    const icon = this.layersVisible ? faToggleOn : faToggleOff;
    const title = this.layersVisible ? 'Hide All Layers' : 'Show All Layers';
    this._layersToggle.innerHTML = getIcon(icon, { size: '1x', style: '', title: title });
  }

  updateDownloadShapeFileButton(onClick = null) {
    if (typeof onClick === 'function') {
      this._onDownloadShapeFileClick = onClick;
      this._downloadShapeFileButton.style.display = 'block';
    } else {
      this._onDownloadShapeFileClick = null;
      this._downloadShapeFileButton.style.display = 'none';
    }
  }

  render(container) {
    this._container = L.DomUtil.create('div', 'card', container)

    const cardHeader = L.DomUtil.create('div', 'card-header', this._container);

    this._bodyToggle = L.DomUtil.create('span', 'lc-img', cardHeader);
    this.updateBodyToggle();
    addEventHandler(this._bodyToggle, 'click', () => this.bodyVisible = !this.bodyVisible);

    L.DomUtil.create('span', 'lc-text', cardHeader).innerHTML = this._name;

    this._downloadShapeFileButton = L.DomUtil.create('span', 'lc-img', cardHeader);
    this._downloadShapeFileButton.innerHTML = getIcon(faArrowToBottom, { size: '1x', style: 'margin-right: 5px', title: 'Download Shape Files' });
    addEventHandler(this._downloadShapeFileButton, 'click', () => this._downloadShapeFile());
    this.updateDownloadShapeFileButton(null);

    this._labelsToggle = L.DomUtil.create('span', 'lc-img', cardHeader);
    this.updateLabelsToggle();
    addEventHandler(this._labelsToggle, 'click', () => this.labelsVisible = !this.labelsVisible);

    this._layersToggle = L.DomUtil.create('span', 'lc-img', cardHeader);
    this.updateLayersToggle();
    addEventHandler(this._layersToggle, 'click', () => this.layersVisible = !this.layersVisible);

    const cardBody = L.DomUtil.create('div', 'card-body', this._container);
    this._layers.forEach(obj => obj.render(cardBody));
  }
}

class LayerModel {
  constructor(group, contextLayerModel) {
    if (!(group instanceof GroupModel)) {
      throw new Error('Invalid "group" param supplied to "LayerModel.ctor"');
    }
    if (!(contextLayerModel instanceof ContextLayerModel)) {
      throw new Error('Invalid "contextLayerModel" param supplied to "LayerModel.ctor"');
    }

    this._group = group;
    this._contextLayerModel = contextLayerModel;
    this._container = null;
    this._symbologyToggle = null;
    this._labelsToggle = null;
    this._layerToggle = null;
    this._symbologyDiv = null;
  }

  destroy() {
    delete this._group;
    delete this._contextLayerModel.__proto__;
    delete this._contextLayerModel.properties;
    delete this._contextLayerModel.map;

    delete this._contextLayerModel;
    delete this._container;
    delete this._symbologyToggle;
    delete this._labelsToggle;
    delete this._layerToggle;
    delete this._symbologyDiv;

    delete this;
  }

  get symbologyVisible() {
    return hasClass(this._symbologyDiv, LAYER_SYMBOLOGY_VISIBLE_CLASS_NAME);
  }
  set symbologyVisible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "LayerModel.symbologyVisible.set"');
    }

    if (this.symbologyVisible !== value) {
      toggleClass(this._symbologyDiv, LAYER_SYMBOLOGY_VISIBLE_CLASS_NAME);
      this._updateSymbologyToggle();
    }
  }

  get labelsVisible() {
    return this._contextLayerModel.labelsVisible;
  }
  set labelsVisible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "LayerModel.labelsVisible.set"');
    }

    if (this.labelsVisible !== value) {
      this._contextLayerModel.labelsVisible = value;
      this.updateLabelsToggle();
      if (!this._group.updating) {
        this._group.updateLabelsToggle();
        this._group.updateMap();
      }
    }
  }

  get layerVisible() {
    return this._contextLayerModel.visible;
  }
  set layerVisible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "LayerModel.layerVisible.set"');
    }

    if (this.layerVisible !== value) {
      this._contextLayerModel.visible = value;
      this.updateLayerToggle();
      if (!this._group.updating) {
        this._group.updateLayersToggle();
        this._group.updateMap();
      }
    }
  }

  _updateSymbologyToggle() {
    const visible = hasClass(this._symbologyDiv, 'visible');
    const icon = visible ? faMinusSquare : faPlusSquare;
    const title = visible ? 'Show Symbology' : 'Hide Symbology';
    this._symbologyToggle.innerHTML = getIcon(icon, { size: '1x', style: '', title: title });
  }

  updateLabelsToggle() {
    if (typeof this._contextLayerModel.noLabelsStyle === 'string' && this._contextLayerModel.noLabelsStyle.trim().length > 0) {
      const icon = this.labelsVisible ? faToggleOn : faToggleOff;
      const title = this.labelsVisible ? 'Hide Labels' : 'Show Labels';
      this._labelsToggle.innerHTML = getIcon(icon, { size: '1x', style: 'margin-right: 5px', title: title });
    }
  }

  updateLayerToggle() {
    const icon = this.layerVisible ? faToggleOn : faToggleOff;
    const title = this.layerVisible ? 'Hide Layer' : 'Show Layer';
    this._layerToggle.innerHTML = getIcon(icon, { size: '1x', style: '', title: title });
  }

  render(container) {
    const layerDiv = L.DomUtil.create('div', 'layer-div', container);

    this._symbologyDiv = L.DomUtil.create('div', 'symbology-div', container);
    const symbologyImg = L.DomUtil.create('img', '', this._symbologyDiv);
    symbologyImg.setAttribute('src', this._group.geoserverUrl + '?REQUEST=GetLegendGraphic&FORMAT=image/png&LAYER=' + this._contextLayerModel.geoServerLayerNames);
    symbologyImg.setAttribute('alt', this._contextLayerModel.geoServerLayerNames);

    this._symbologyToggle = L.DomUtil.create('span', 'lc-img', layerDiv);
    this._updateSymbologyToggle();
    addEventHandler(this._symbologyToggle, 'click', () => this.symbologyVisible = !this.symbologyVisible);

    L.DomUtil.create('span', 'lc-text', layerDiv).innerHTML = this._contextLayerModel.layerDisplayName;

    this._labelsToggle = L.DomUtil.create('span', 'lc-img', layerDiv);
    this.updateLabelsToggle();
    addEventHandler(this._labelsToggle, 'click', () => this.labelsVisible = !this.labelsVisible);

    this._layerToggle = L.DomUtil.create('span', 'lc-img', layerDiv);
    this.updateLayerToggle();
    addEventHandler(this._layerToggle, 'click', () => this.layerVisible = !this.layerVisible);
  }
}

export { GroupModel, LayerModel };