import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';

import { WellFilter } from './well-filter';
import WellGridComponent, { GridExcelExport } from './well-grid-component';
import { WellDataRow } from './well-data-row';
import { downloadFile } from '../../shared/helpers/download';
import { TrackingType, AtlasPanel } from '../../shared/helpers/enums';

import OverviewComponent from './overview-component';

import BaseViewComponent, { LeafletHelper, ContextLayerModel } from '../base/base-view-component';

import { appViews } from '../../app/app-views';
import { AppSetState } from '../../app/app-set-state';
import { zoomLevels } from '../../shared/helpers/leaflet/helpers/zoom-levels';
import { searchResultType } from '../../shared/helpers/leaflet/leaflet-helper';
import { SearchResultsExcelExport } from '../../map/search-results-excel-export';

class WellViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.leafletHelper = new LeafletHelper(this.props.geoserverUrl, 'well-view-component-map', new SearchResultsExcelExport(this.props.appSetState, appViews.Well).export);
    this.state = { contextLayers: [], dataRows: [], ids: [], filterEnabled: false };
  }

  componentDidMount() {
    this.update(true);
    this.loadFilter();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.update(false);
    }
  }

  componentWillUnmount() {
    if (this.leafletHelper !== null && this.leafletHelper !== undefined) {
      this.leafletHelper.destroy();
      delete this.leafletHelper;
    }
  }

  loadFilter = () => {
    const url = 'api/well/filters/load';
    const onSuccess = () => {
      this.setState({ filterEnabled: true });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true, false);
  }

  update = (initialLoad) => {
    const url = 'api/well/load-all';
    const onSuccess = (result) => {

      var dataRows = WellDataRow.FromArray(result.dataRows);
      var contextLayers = ContextLayerModel.FromArray(result.contextLayers);
      var ids;
      var filter = this.props.filter.toApiParam();
      var isFilterSet = this.props.filter.isSet;
      if (isFilterSet) {
        const url = 'api/well/load-ids';
        const onFilteredSuccess = (result) => {
          ids = result;
          dataRows = dataRows.filter(({ id }) => ids.includes(id));
          this.setState({
            contextLayers: contextLayers,
            dataRows: dataRows
          }, this.setMapPosition);
        };

        this.props.appSetState.api.post(url, filter, onFilteredSuccess, undefined, false, true);
      }
      else {
        this.setState({
          contextLayers: contextLayers,
          dataRows: dataRows
        }, initialLoad ? this.initialSetMapPosition : this.setMapPosition);
      }
      //this.loadFilter();
    };

    this.props.appSetState.api.getCached(url, onSuccess, null, false, true);
  }

  initialSetMapPosition = () => {
    const { filter } = this.props;

    if (filter.mapBoundsIsSet) {
      this.leafletHelper.leafletMap.fitBounds(filter.mapBounds);
    } else {
      this.setMapPosition();
    }
  }

  setMapPosition = () => {
    const { dataRows } = this.state;

    if (this.props.filter.followMap === false) {
      if (dataRows.length > 0) {
        const bounds = L.latLngBounds([]);
        dataRows.forEach(obj => bounds.extend(obj.shape.latLng));
        this.leafletHelper.flyToBounds(bounds);
      }
    }
  }

  showOnMap = (dataRow) => {
    const latLng = L.latLng(dataRow.shape.latLng);
    const zoom = searchResultType.defaultZoomLevel(searchResultType.Well);
    this.leafletHelper.flyTo(latLng, zoom);
  }

  openReport = (id) => {
    const page = this.props.appSetState.view.current;
    const panel = this.props.appSetState.view.current === appViews.Home ? AtlasPanel.Reports : AtlasPanel.None;
    downloadFile(id, this.props.appSetState, true, 0, page, TrackingType.ReportDownload, panel);
  }

  copyLink = (dataRow) => {
    this.props.appSetState.view.details.showWellLink(dataRow.id, dataRow.link);
  }

  excelExport = () => {
    new GridExcelExport().export(this.props.appSetState, this.props.filter);
  }

  render() {
    const { dataRows } = this.state;

    const overview = undefined;
    //const overview = (
    //  <OverviewComponent
    //    appSetState={this.props.appSetState}
    //    dataRows={dataRows}
    //  />
    //);
    const grid = (
      <WellGridComponent
        appSetState={this.props.appSetState}
        dataRows={dataRows}
        showOnMap={this.showOnMap}
        openReport={this.openReport}
        copyLink={this.copyLink}
      />
    );

    return (
      <BaseViewComponent
        className="well-view-component"
        leafletHelper={this.leafletHelper}
        contextLayers={this.state.contextLayers}
        shapes={dataRows.map(obj => obj.shape)}
        appView={appViews.Well}
        appSetState={this.props.appSetState}
        update={this.update}
        settings={this.props.settings}
        filter={this.props.filter}
        showFiltersButton
        filterButtonEnabled={this.state.filterEnabled}
        showFilters={this.props.showFilters}
        setShowFilters={this.props.setShowFilters}
        showFollowMapButton
        followMap={this.props.followMap}
        setFollowMap={this.props.setFollowMap}
        overview={overview}
        grid={grid}
        excelExport={this.excelExport}
        reset={this.props.reset}
      />
    );
  }
}

WellViewComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    showMap: PropTypes.bool.isRequired,
    showOverview: PropTypes.bool.isRequired
  }).isRequired,
  filter: PropTypes.instanceOf(WellFilter).isRequired,
  showFilters: PropTypes.bool.isRequired,
  setShowFilters: PropTypes.func.isRequired,
  followMap: PropTypes.bool.isRequired,
  setFollowMap: PropTypes.func.isRequired,
  reset: PropTypes.func
};

export default WellViewComponent;
