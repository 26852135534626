import React from 'react';
import PropTypes from 'prop-types';

import { ReportsGridRow } from './reports-grid-row';
import { ReportsGridExcelExport } from './reports-grid-excel-export';
import BaseGridComponent, { createLinkAndIcons, COLUMN_SMALL, COLUMN_MEDIUM, COLUMN_WIDE, HEADER_ROW_SINGLE } from '../base-grid/base-grid-component';

import { appViews } from '../../app/app-views';
import { appIcons } from '../../app/app-icons';
import { AppSetState } from '../../app/app-set-state';
import { TrackingType, AtlasPanel } from '../../shared/helpers/enums';
import { downloadFile, downloadSpecial } from '../../shared/helpers/download';

const ReportsGridComponent = (props) => {
  const { view } = props.appSetState;

  const renderReportNameCol = (params) => {
    if (params.data.isSubscribed !== true) {
      return params.value
    }

    const icons = [];
    icons.push({ appIcon: appIcons.FilePdf, title: 'Download Report', onClick: () => openReport(params.data.id), key: params.data.id });

    if (typeof params.data.link === 'string' && params.data.link.length > 0) {
      icons.push({ appIcon: appIcons.Link, title: 'Copy link...', onClick: () => copyLink(params.data.id, params.data.link), key: params.data.id });
    }

    if (params.data.categoryID === 31) {
      icons.push({ appIcon: appIcons.FileDownload, title: 'Download Special Reports', onClick: () => downloadSpecialReports(params.data.associatedFileID) });
    } else if ((params.data.categoryID === 1 || params.data.categoryID === 2) && params.data.wellIds.length === 1) { 
      icons.push({ appIcon: appIcons.Details, title: 'Open Well Detail', onClick: () => view.details.showWell(params.data.wellIds[0])});
    } else if ((params.data.categoryID === 1 || params.data.categoryID === 2) && params.data.wellIds.length > 1) {
      icons.push({ appIcon: appIcons.Table, title: 'Show Wells', onClick: () => view.wells.showWells(params.data.wellIds) }); 
    } else if (params.data.isAnalyticSubscribed === 1 && params.data.isActive === true && params.data.categoryID !== 1 && params.data.categoryID !== 2) {
      icons.push({ appIcon: appIcons.Overview, title: 'View Analytics', onClick: () => goToAnalytics(params.data.analystContainerID, params.data.overviewContainerID, params.data.quickBoardGroupID, params.data.quickBoardFilterString, params.data.countryID, params.data.id) });
    } else {
      icons.push(null);
    }

    return createLinkAndIcons(params.value, params.data.summaryText, () => openPdf(params.data.id), icons);
  };

  const openPdf = (id) => {
    const page = props.appSetState.view.current;
    const panel = props.appSetState.view.current === appViews.Home ? AtlasPanel.Reports : AtlasPanel.None;
    //props.appSetState.view.details.showReport(id, 0, panel);
    downloadFile(id, props.appSetState, false, 0, page, TrackingType.ReportDownload, panel);
  };

  const openReport = (id) => {
    const page = props.appSetState.view.current;
    const panel = props.appSetState.view.current === appViews.Home ? AtlasPanel.Reports : AtlasPanel.None;
    downloadFile(id, props.appSetState, true, 0, page, TrackingType.ReportDownload, panel);
  };

  const goToAnalytics = (containerid, overviewcontainerid, group, base, countryid, id) => {
    const onSuccess = (data) => window.open('' + data, '_blank');
    const onFail = (err) => console.error('Open analytics failed', err);
    props.appSetState.api.get('api/reports/getanalytic?analystcontainerid=' + containerid + '&overviewcontainerid=' + overviewcontainerid + '&group=' + group + '&qbbase=' + base + '&countryid=' + countryid + '&id=' + id, onSuccess, onFail);
  };

  const downloadSpecialReports = (id) => {
    downloadSpecial(id, props.appSetState);
  };

  const copyLink = (id, link) => {
    props.appSetState.view.details.showReportLink(id, link);
  };

  const dateComparator = (date1, date2) => {
    var date1Number;
    var date2Number;
    if (date1 === undefined || date1 === null || date1.length !== 10) date1Number = null;
    else {
      var yearNumber = date1.substring(6, 10);
      var monthNumber = date1.substring(3, 5);
      var dayNumber = date1.substring(0, 2);

      date1Number = (yearNumber * 10000) + (monthNumber * 100) + dayNumber;
    }
    if (date2 === undefined || date2 === null || date2.length !== 10) date2Number = null;
    else {
      var yearNumber2 = date2.substring(6, 10);
      var monthNumber2 = date2.substring(3, 5);
      var dayNumber2 = date2.substring(0, 2);

      date2Number = (yearNumber2 * 10000) + (monthNumber2 * 100) + dayNumber2;
    }

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  const REPORTS_GRID_NAME_FIELD = 'name';
  const REPORTS_GRID_PUBLISH_DATE_DISPLAY_FIELD = 'publishDateDisplay';
  const REPORTS_GRID_INSTANCES_FIELD = 'instances';
  const REPORTS_GRID_COLUMN_DEFS = [
    { headerName: 'Report Name', field: REPORTS_GRID_NAME_FIELD, sortable: true, maxWidth: 1200, resizable: true, minWidth: COLUMN_WIDE, suppressSizeToFit: false, flex: 4, cellRenderer: renderReportNameCol },
    { headerName: 'Country', field: 'countryName', resizable: true, sortable: true, suppressSizeToFit: false, minWidth: COLUMN_SMALL },
    { headerName: 'Category', field: 'category', resizable: true, sortable: true, suppressSizeToFit: false, minWidth: COLUMN_SMALL },
    { headerName: 'Publish Date', field: REPORTS_GRID_PUBLISH_DATE_DISPLAY_FIELD, resizable: true, sortable: true, minWidth: COLUMN_MEDIUM, suppressSizeToFit: false, comparator: dateComparator },
    //{ headerName: 'Description', field: 'summaryText', sortable: true, hide: true },
    { headerName: 'Instances', field: REPORTS_GRID_INSTANCES_FIELD, sortable: true, minWidth: COLUMN_SMALL, suppressSizeToFit: false }
  ];

  const columnDefs = props.showInstancesCol
    ? REPORTS_GRID_COLUMN_DEFS
    : REPORTS_GRID_COLUMN_DEFS.filter(obj => obj.field !== REPORTS_GRID_INSTANCES_FIELD);

  return (
    <BaseGridComponent
      columnDefs={columnDefs}
      dataRows={props.dataRows}
      headerRowHeight={HEADER_ROW_SINGLE}
      autoReSizeGrid//={false}
    />
  );
};

ReportsGridComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.instanceOf(ReportsGridRow).isRequired).isRequired,
  showInstancesCol: PropTypes.bool.isRequired,
  showReportsFull: PropTypes.bool.isRequired
};

export default ReportsGridComponent;
export { ReportsGridRow, ReportsGridExcelExport };
