import { PolygonsModel, PolygonModel } from '../shared/helpers/leaflet/models/polygons-model';
import { MapShapeBorder } from '../shared/scss/app-colors';
import { valueTypes } from '../shared/helpers/value-handler';

function getBlockStatus(blockStatus){
  // Requirement from EC
  // Block status - can we drop the word Acreage from Open Acreage and Licensed Acreage?
  if (blockStatus === null || typeof blockStatus !== 'string') {
    return 'N/A';
  };

  if (blockStatus.endsWith(' Acreage')) {
    blockStatus = blockStatus.substring(0, blockStatus.length - 8);
  }

  return blockStatus;
};

class Block {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Block.ctor'); }
    if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to Block.ctor'); }
    if (typeof obj.name !== 'string') { throw new Error('Invalid obj.name param supplied to Block.ctor'); }
    if (typeof obj.country !== 'string') { throw new Error('Invalid obj.country param supplied to Block.ctor'); }
    if (obj.area !== null && typeof obj.area !== 'string') { throw new Error('Invalid obj.area param supplied to Block.ctor'); }
    if (obj.operatorName !== null && typeof obj.operatorName !== 'string') { throw new Error('Invalid obj.operatorName param supplied to Block.ctor'); }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') { throw new Error('Invalid obj.operatorId param supplied to Block.ctor'); }
    if (obj.blockStatus !== null && typeof obj.blockStatus !== 'string') { throw new Error('Invalid obj.classification param supplied to Block.ctor'); }
    if (obj.licence !== null && typeof obj.licence !== 'string') { throw new Error('Invalid obj.licence param supplied to Block.ctor'); }
    if (obj.licenceId !== null && typeof obj.licenceId !== 'number') { throw new Error('Invalid obj.licenceId param supplied to Block.ctor'); }
    if (obj.licenceRound !== null && typeof obj.licenceRound !== 'string') { throw new Error('Invalid obj.licenceRound param supplied to Block.ctor'); }
    if (!Array.isArray(obj.shapes)) { throw new Error('Invalid obj.shapes param supplied to Block.ctor'); }
    if (obj.areaSQKM !== null && typeof obj.areaSQKM !== 'number') { throw new Error('Invalid obj.areaSQKM param supplied to Block.ctor'); }

    this.id = obj.id;
    this.name = obj.name;
    this.country = obj.country;
    this.area = obj.area !== null ? obj.area : 'N/A';
    this.operatorName = obj.operatorName !== null ? obj.operatorName : 'N/A';
    this.operatorId = obj.operatorId;
    this.blockStatus = getBlockStatus(obj.blockStatus);
    this.licence = obj.licence !== null ? obj.licence : 'N/A';
    this.licenceId = obj.licenceId;
    this.licenceRound = obj.licenceRound !== null ? obj.licenceRound : 'N/A';
    this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });
    this.areaSQKM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.areaSQKM);
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "Block.FromArray"');
    }

    const result = array.map(obj => new Block(obj));
    return result;
  }
}

export { Block };