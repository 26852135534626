import React from 'react';

import { ReportsFilter } from './reports-filter';

import ReportsViewComponent from './reports-view-component';
import ReportsViewSettingsComponent from './reports-view-settings-component';

import { BaseViewSettings } from '../base/base-view-settings';

class ReportsViewSettings extends BaseViewSettings {
  constructor(appView, appSetState) {
    super(appView, appSetState, new ReportsFilter());

    this.pdfId = null;
    this.emailId = null;
    this.goBack = this.goBack.bind(this);
    this.clearPDFIds = this.clearPDFIds.bind(this);
    this._showFilters = true;
  }

  get showFilters() { return this._showFilters; }
  set showFilters(value) { this.setShowFilters(value); }
  setShowFilters = (value) => {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "ReportsViewSettings.showFilters"');
    }

    if (this._showFilters !== value) {
      this._showFilters = value;
      this.appSetState.view.forceUpdate();
    }
  }

  _update = () => {
    this._filter = this._filter.clone();
    this.appSetState.view.forceUpdate();
  };

  goBack() {
    this.pdfId = null;
    this.emailId = null;
    this.appSetState.view.forceUpdate();
  }

  clearPDFIds() {
    this.pdfId = null;
    this.emailId = null;
  }

  getViewSettingsComponent(settings) {
    super.getViewSettingsComponent(settings);

    if (!this.showFilters) {
      return null;
    }

    return <ReportsViewSettingsComponent appSetState={this.appSetState} filter={this._filter} onUpdate={this._update} />;
  }

  getViewComponent(settings, fullScreen) {
    super.getViewComponent(settings, fullScreen);

    return <ReportsViewComponent appSetState={this.appSetState} filter={this._filter} showFilters={this._showFilters} setShowFilters={this.setShowFilters} showHeader showReportsFull />;
  }
}

export { ReportsViewSettings };
