//import { id } from "date-fns/esm/locale";

export class EmailReport {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid \'obj\' supplied to \'EmailReport.ctor\'');
    }
    if (typeof obj.emailReportId !== 'number') {
      throw new Error('Invalid \'emailReportId\' supplied to \'EmailReport.ctor\'');
    }
    if (typeof obj.mainReportId !== 'number') {
      throw new Error('Invalid \'mainReportId\' supplied to \'EmailReport.ctor\'');
    }

    this.emailReportId = obj.emailReportId;
    this.mainReportId = obj.mainReportId;
  }
}

export class EmailCountries {
  constructor() {
    this.Value = 0;
    this.Label = "";
  }
}

export class EmailCategories {
  constructor() {
    this.id = 0;
    this.Name = "";
    this.Send = false;
  }

}