import L from 'leaflet';
import { default as GeometryUtil } from 'leaflet-geometryutil';
import { faRuler } from '@fortawesome/pro-regular-svg-icons';

import { BaseButtonControl, BORDER_OPTIONS, MARKER_OPTIONS, TOOLTIP_OPTIONS } from "./base-button-control";

class MeasureButtonControl extends BaseButtonControl {
  constructor(searchButtonsControl) {
    super(searchButtonsControl);

    this._drawing = false;
  }

  get iconDef() { return faRuler; }
  get title() { return 'Measure'; }

  cancel() {
    this.clearLayerGroup();
    this._drawing = false;
    this.toggled = false;
  }

  onToggledChanged(toggled) {
    if (toggled) {
      this.addEvent('click', this._mapClick);
      this.addEvent('mousemove', this._mapMouseMove);
      this.addEvent('dblclick', this._mapDblClick);
    } else {
      this.removeEvent('click', this._mapClick);
      this.removeEvent('mousemove', this._mapMouseMove);
      this.removeEvent('dblclick', this._mapDblClick);
    }
  }

  _getGroup(layerGroup = null) {
    if (layerGroup === null) {
      layerGroup = this.layerGroup;
    }

    const groups = layerGroup.getLayers();
    return groups[groups.length - 1];
  }

  _getTooltip(latLngs) {
    if (latLngs.length < 2) {
      return '';
    }

    let metres = GeometryUtil.length([latLngs[latLngs.length - 1], latLngs[latLngs.length - 2]]);
    let result = this.getDescriptionString('Distance', this.getDistanceString(metres));

    if (latLngs.length > 2) {
      for (var loop = 0; loop < latLngs.length - 2; loop++) {
        metres += GeometryUtil.length([latLngs[loop], latLngs[loop + 1]]);
      }
      result += '<br />' + this.getDescriptionString('From start', this.getDistanceString(metres));
    }

    return result;
  }

  _createCircleMarker(latLng, fixed) {
    const markerOptions = { ...MARKER_OPTIONS, fixed: fixed };
    const result = L.circleMarker(latLng, markerOptions);
    return result;
  }

  _mapClick(e) {
    if (this.ignoreMouseEvents) {
      return;
    }

    const layerGroup = this.layerGroup;

    if (!this._drawing) {
      this._drawing = true;
      const group = L.layerGroup([L.polyline([e.latlng], BORDER_OPTIONS), this._createCircleMarker(e.latlng, true)]);
      layerGroup.addLayer(group);
      return;
    }

    const group = this._getGroup(layerGroup);
    const layers = group.getLayers();

    const line = layers[0];
    line.addLatLng(e.latlng);

    const marker = layers[layers.length - 1];
    marker.options.fixed = true;
  }

  _mapMouseMove(e) {
    if (this.ignoreMouseEvents || !this._drawing) {
      return;
    }

    const group = this._getGroup();
    const layers = group.getLayers();

    const line = layers[0];
    const latLngs = line.getLatLngs();
    if (latLngs.length === 1) {
      latLngs.push(e.latlng);
    } else {
      latLngs[latLngs.length - 1] = e.latlng;
    }
    line.setLatLngs(latLngs);

    const tooltip = this._getTooltip(latLngs);
    let marker = layers[layers.length - 1];
    if (marker.options.fixed === true) {
      marker = this._createCircleMarker(e.latlng, false);
      marker.bindTooltip(tooltip, TOOLTIP_OPTIONS);
      group.addLayer(marker);
    } else {
      marker.setLatLng(e.latlng);
      marker.setTooltipContent(tooltip);
    }
  }

  _mapDblClick() {
    if (this.ignoreMouseEvents || !this._drawing) {
      return;
    }

    this._drawing = false;

    const layerGroup = this.layerGroup;
    const layers = layerGroup.getLayers();
    const latLngs = layers[layers.length - 1].getLayers()[0].getLatLngs();
    latLngs.length -= 2;

    if (latLngs.length > 0) {
      if (layers.length > 1) {
        layerGroup.removeLayer(layers[0]);
      }

      if (typeof this.onComplete === 'function') {
        this.onComplete(latLngs);
      }
    } else {
      this.clearLayerGroup();
    }

    //TODO: added until we revisit 181 - Retain Select Tool Function
    this.toggled = false;
  }
}

export { MeasureButtonControl };