import { BaseFilter } from "../base/base-filter";

class BlockFilter extends BaseFilter {
  constructor() {
    super();

    this._blockIds = undefined;
    this._operatorIds = undefined;
    this._participantIds = undefined;
    this._countryIds = undefined;
    this._areaIds = undefined;
    this._quadrantIds = undefined;
    this._licenceIds = undefined;
    this._licenceRoundIds = undefined;
    this.clear();
  }

  get isSet() {
    const result = super.isSet === true ||
      this.blockIdsIsSet === true ||
      this.operatorIdsIsSet === true ||
      this.participantIdsIsSet === true ||
      this.countryIdsIsSet === true ||
      this.areaIdsIsSet === true ||
      this.quadrantIdsIsSet === true ||
      this.licenceIdsIsSet === true ||
      this.licenceRoundIdsIsSet === true ||
      this.blockStatusIsSet === true;
    return result;
  }

  get blockIds() { return this._blockIds; }
  set blockIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "BlockFilter.blockIds.set"');
    }

    if (this._blockIds !== value) {
      this._blockIds = value;
    }
  }
  get blockIdsIsSet() { return this._blockIds.length > 0; }

  get operatorIds() { return this._operatorIds; }
  set operatorIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "BlockFilter.operatorIds.set"');
    }

    if (this._operatorIds !== value) {
      this._operatorIds = value;
    }
  }
  get operatorIdsIsSet() { return this._operatorIds.length > 0; }

  get participantIds() { return this._participantIds; }
  set participantIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "BlockFilter.participantIds.set"');
    }

    if (this._participantIds !== value) {
      this._participantIds = value;
    }
  }
  get participantIdsIsSet() { return this._participantIds.length > 0; }

  get countryIds() { return this._countryIds; }
  set countryIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "BlockFilter.countryIds.set"');
    }

    if (this._countryIds !== value) {
      this._countryIds = value;
    }
  }
  get countryIdsIsSet() { return this._countryIds.length > 0; }

  get areaIds() { return this._areaIds; }
  set areaIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "BlockFilter.areaIds.set"');
    }

    if (this._areaIds !== value) {
      this._areaIds = value;
    }
  }
  get areaIdsIsSet() { return this._areaIds.length > 0; }

  get quadrantIds() { return this._quadrantIds; }
  set quadrantIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'string').length > 0) {
      throw new Error('Invalid "value" param supplied to "BlockFilter.quadrantIds.set"');
    }

    if (this._quadrantIds !== value) {
      this._quadrantIds = value;
    }
  }
  get quadrantIdsIsSet() { return this._quadrantIds.length > 0; }

  get licenceIds() { return this._licenceIds; }
  set licenceIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "BlockFilter.licenceIds.set"');
    }

    if (this._licenceIds !== value) {
      this._licenceIds = value;
    }
  }
  get licenceIdsIsSet() { return this._licenceIds.length > 0; }

  get licenceRoundIds() { return this._licenceRoundIds; }
  set licenceRoundIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "BlockFilter.licenceRoundIds.set"');
    }

    if (this._licenceRoundIds !== value) {
      this._licenceRoundIds = value;
    }
  }
  get licenceRoundIdsIsSet() { return this._licenceRoundIds.length > 0; }

  get blockStatus() { return this._blockStatus; }
  set blockStatus(key) {
    if (key !== null && typeof key !== 'number') {
      throw new Error('Invalid "value" param supplied to "BlockFilter.blockStatus.set"');
    }

    if (this._blockStatus !== key) {
      this._blockStatus = key;
    }
  }
  get blockStatusIsSet() { return this._blockStatus !== 0; }

  clear() {
    super.clear();

    this._blockIds = [];
    this._operatorIds = [];
    this._participantIds = [];
    this._countryIds = [];
    this._areaIds = [];
    this._quadrantIds = [];
    this._licenceIds = [];
    this._licenceRoundIds = [];
    this._blockStatus = 0;
  }

  clone() {
    const result = new BlockFilter();
    super.clone(result);
    result._blockIds = [...this._blockIds];
    result._operatorIds = [...this._operatorIds]
    result._participantIds = [...this._participantIds];
    result._countryIds = [...this._countryIds];
    result._areaIds = [...this._areaIds];
    result._quadrantIds = [...this._quadrantIds];
    result._licenceIds = [...this.licenceIds];
    result._licenceRoundIds = [...this.licenceRoundIds];
    result._blockStatus = this._blockStatus;
    return result;
  }

  toApiParam() {
    const result = super.toApiParam();
    result.blockIds = this._blockIds;
    result.operatorIds = this._operatorIds;
    result.participantIds = this._participantIds;
    result.countryIds = this._countryIds;
    result.areaIds = this._areaIds;
    result.quadrantIds = this._quadrantIds;
    result.licenceIds = this._licenceIds;
    result.licenceRoundIds = this._licenceRoundIds;
    result.blockStatus = this._blockStatus;
    return result;
  }
}

export { BlockFilter };