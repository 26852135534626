import React from 'react';
import PropTypes from 'prop-types';

import DetailsComponent from './key-facts/details-component';
import EconomicSummaryComponent from './key-facts/economic-summary-component';
import MapComponent from '../../../components/fiscal/map/map-component';
import { CorporateDetail } from '../../../models/corporate-detail';
import { ContextLayerModel, latLngBounds, searchResultType } from '../../../shared/helpers/leaflet/leaflet-helper';
import { createSection100, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedKeyFactsComponent = (props) => {
  const { corporate } = props;

  const leftPanel = (
    <React.Fragment>
      {createSection100(<DetailsComponent corporate={corporate} />, 'Key facts')}
      {corporate.hasFiscal && createSection100(<EconomicSummaryComponent cashflow={corporate.cashflow} currentYear={corporate.currentYear} />, 'Economic summary')}
    </React.Fragment>
  );
  const rightPanel = corporate.shapes !== null && corporate.shapes.polygons.length > 0
    ? <div style={{ height: '100%' }}><MapComponent geoserverUrl={props.geoserverUrl} contextLayers={props.contextLayers} bounds={latLngBounds(corporate.shapes.latLngs)} zoom={searchResultType.defaultZoomLevel(searchResultType.Corporate)} shapes={[corporate.shapes]} /></div>
    : null;

  return (
    <div ref={props.innerRef}>
      {createSection([
        { content: leftPanel, sectionType: sectionTypes.section50, noMarginVertical: true },
        { content: rightPanel, sectionType: sectionTypes.section50 }
      ])}
    </div>
  );
};

AdvancedKeyFactsComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  corporate: PropTypes.instanceOf(CorporateDetail).isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
};

export default AdvancedKeyFactsComponent;