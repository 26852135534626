const IN_DEV_MODE = process.env.NODE_ENV === 'development';

function appendClassNames(className1, className2) {
  className1 = typeof className1 === 'string' ? className1.trim() : '';
  className2 = typeof className2 === 'string' ? className2.trim() : '';
  let result = (className1 + ' ' + className2).trim();
  return result;
};

function arrayHasDuplicates(array) {
  if (!Array.isArray(array)) {
    throw new Error('Supplied \'array\' param to arrayHasDuplicates is not an array')
  }

  const sortedArray = [...array].sort();
  for (var i = 0; i < sortedArray.length - 1; i++) {
    if (sortedArray[i + 1] === sortedArray[i]) {
      return true;
    }
  }
  return false;
};

function getEnumValues(object) {
  const result = Object.values(object).filter(obj => typeof obj === 'number');
  return result;
};

function getEnumDisplayName(object, value, lowerCase = false) {
  let result = Object.keys(object).find(key => object[key] === value);
  if (typeof result === 'string' && lowerCase === true) {
    result = result.toLowerCase();
  }
  return result;
};

const getMap = (object, func) => {
  const result = getEnumValues(object).map((obj, idx) => func(obj, idx));
  return result;
};

const getForEach = (object, func) => {
  const result = getEnumValues(object).forEach((obj, idx) => func(obj, idx));
  return result;
};

const getPropType = (object, props, propName, componentName) => {
  const value = props[propName];
  //TODO: if (typeof value === 'string') {
  //  value = getValueFromDisplayName(object, value);
  //}
  const isValid = getEnumValues(object).includes(value);
  const result = isValid
    ? null
    : new Error('Invalid enum property \'' + propName + '\' supplied to \'' + componentName + '\'. Validation failed.');
  return result;
};

const getNullablePropType = (object, props, propName, componentName) => {
  const result = props[propName] === null
    ? undefined
    : getPropType(object, props, propName, componentName);
  return result;
};

const objectToEnum = (object, freeze = true) => {
  if (typeof object !== 'object') {
    throw new Error('Supplied \'object\' param to objectToEnum is not an object')
  }
  const values = getEnumValues(object);
  if (values.length === 0) {
    throw new Error('Supplied \'object\' param to objectToEnum contains no numeric values')
  }
  if (arrayHasDuplicates(values)) {
    throw new Error('Supplied \'object\' param to objectToEnum contains duplicate values')
  }

  const result = {};

  const keys = Object.keys(object);
  for (var loop = 0; loop < keys.length; loop++) {
    result[keys[loop]] = values[loop];
  }
  result.getValues = () => getEnumValues(result);
  result.isValid = (value) => result.getValues().includes(value);
  result.isInvalid = (value) => !result.isValid(value);
  result.getName = (value, lowerCase = false) => getEnumDisplayName(result, value, lowerCase);
  result.getDisplayName = (value, lowerCase = false) => getEnumDisplayName(result, value, lowerCase);
  result.map = (func) => getMap(result, func);
  result.propType = (props, propName, componentName) => getPropType(result, props, propName, componentName);
  result.nullablePropType = (props, propName, componentName) => getNullablePropType(result, props, propName, componentName);
  result.length = () => getEnumValues(result).length;
  result.forEach = (func) => getForEach(result, func);

  if (freeze === true) {
    Object.freeze(result);
  }

  return result;
};

const enumToObject = (enumType, func) => {
  if (typeof enumType !== 'object' || typeof enumType.isValid !== 'function') {
    throw new Error('Invalid \'enumType\' param supplied to \'enumToObject\'');
  }
  if (typeof func !== 'function') {
    throw new Error('Invalid \'func\' param supplied to \'enumToObject\'');
  }

  const result = {};
  enumType.map(enumValue => {
    result[enumValue] = func(enumValue);
    return null;
  });
  return result;
};

const print = (title) => {
  const currTitle = document.title;
  document.title = title;
  window.print();
  document.title = currTitle;
}

const cloneObject = (obj) => {
  if (typeof obj !== 'object') {
    throw new Error('Invalid \'obj\' param supplied to \'cloneObject\'');
  }

  const clone = JSON.parse(JSON.stringify(obj));
  const result = Object.create(obj);
  Object.assign(result, clone);
  return result;
};

const scrollToRef = (e, ref) => {
  e !== undefined && e !== null && e.preventDefault();
  ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const isIconDef = (iconDef) => {
  const result =
    typeof iconDef === 'object' &&
    typeof iconDef.icon === 'object' &&
    typeof iconDef.prefix === 'string' &&
    typeof iconDef.iconName === 'string' &&
    Array.isArray(iconDef.icon)
  return result;
};

const iconDefPropType = (props, propName, componentName) => {
  const value = props[propName];
  const isValid = isIconDef(value);
  const result = isValid
    ? null
    : new Error('Invalid property "' + propName + '" supplied to "' + componentName + '". Validation failed.');
  return result;
};

const nullableIconDefPropType = (props, propName, componentName) => {
  const result = props[propName] === null
    ? undefined
    : iconDefPropType(props, propName, componentName);
  return result;
};

function showError(className, propertyName) {
  throw new Error('Invalid ' + propertyName + ' param supplied to ' + className + '.ctor');
};

export { IN_DEV_MODE, arrayHasDuplicates, appendClassNames, objectToEnum, enumToObject, getEnumDisplayName, getEnumValues, print, cloneObject, scrollToRef, isIconDef, iconDefPropType, nullableIconDefPropType, showError };