import { objectToEnum, getEnumValues } from "../../shared/helpers/common";

const searchTypes = objectToEnum({
  AtLeastOne: 1,
  AllTerms: 2,
  ExactPhrase: 3
}, false);

searchTypes.getDisplayName = (value) => {
  switch (value) {
    case searchTypes.AtLeastOne: return 'At least one term';
    case searchTypes.AllTerms: return 'All terms';
    case searchTypes.ExactPhrase: return 'Exact phrase';
    default: throw new Error('Invalid "value" param supplied to "searchTypes.getDisplayName"');
  }
};

searchTypes.getValues = (advancedSearch) => {
  let result = getEnumValues(searchTypes);
  if (advancedSearch) {
    result = result.filter(obj => obj !== searchTypes.ExactPhrase);
  }
  return result;
};

Object.freeze(searchTypes);

class ReportsFilter {
  constructor() {
    this._advancedSearch = undefined;
    this._searchType = undefined;
    this._searchString = undefined;
    this._companyId = undefined;
    this._countryId = undefined;
    this._fieldId = undefined;
    this._discoveryId = undefined;
    this._quadrantId = undefined;
    this._blockId = undefined;
    this._wellId = undefined;
    this._subscriptionIds = undefined;
    this._categoryIds = undefined;
    this._dateFrom = undefined;
    this._dateTo = undefined;
    this.clear();
  }

  get advancedSearch() { return this._advancedSearch; }
  set advancedSearch(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "ReportsFilter.advancedSearch.set"');
    }

    if (this._advancedSearch !== value) {
      this._advancedSearch = value;
    }
  }

  get searchType() { return this._searchType; }
  set searchType(value) {
    if (searchTypes.isInvalid(value)) {
      throw new Error('Invalid "value" param supplied to "ReportsFilter.searchType.set"');
    }

    if (this._searchType !== value) {
      this._searchType = value;
    }
  }

  get searchString() { return this._searchString; }
  set searchString(value) {
    if (typeof value !== 'string') {
      throw new Error('Invalid "value" param supplied to "ReportsFilter.searchString.set"');
    }

    if (this._searchString !== value) {
      this._searchString = value;
    }
  }
  get searchStringIsSet() { return typeof this._searchString === 'string' && this._searchString.length > 0; }

  get companyId() { return this._companyId; }
  set companyId(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "ReportsFilter.companyId.set"');
    }

    if (this._companyId !== value) {
      this._companyId = value;
    }
  }
  get companyIdIsSet() { return typeof this._companyId === 'number'; }

  get countryId() { return this._countryId; }
  set countryId(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "ReportsFilter.countryId.set"');
    }

    if (this._countryId !== value) {
      this._countryId = value;
      this._quadrantId = null;
      this._blockId = null;
      this._wellId = null;
    }
  }
  get countryIdIsSet() { return ReportsFilter.CountryIdIsSet(this._countryId); }
  static CountryIdIsSet(countryId) { return typeof countryId === 'number'; }

  get fieldId() { return this._fieldId; }
  set fieldId(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "ReportsFilter.fieldId.set"');
    }

    if (this._fieldId !== value) {
      this._fieldId = value;
    }
  }
  get fieldIdIsSet() { return typeof this._fieldId === 'number'; }

  get discoveryId() { return this._discoveryId; }
  set discoveryId(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "ReportsFilter.discoveryId.set"');
    }

    if (this._discoveryId !== value) {
      this._discoveryId = value;
    }
  }
  get discoveryIdIsSet() { return typeof this._discoveryId === 'number'; }

  get quadrantId() { return this._quadrantId; }
  set quadrantId(value) {
    if (value !== null && typeof value !== 'string') {
      throw new Error('Invalid "value" param supplied to "ReportsFilter.quadrantId.set"');
    }

    if (this._quadrantId !== value) {
      this._quadrantId = value;
      this._blockId = null;
      this._wellId = null;
    }
  }
  get quadrantIdIsSet() { return ReportsFilter.QuadrantIdIsSet(this._countryId); }
  static QuadrantIdIsSet(quadrantId) { return typeof quadrantId === 'string' && quadrantId.length > 0; }

  get blockId() { return this._blockId; }
  set blockId(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "ReportsFilter.blockId.set"');
    }

    if (this._blockId !== value) {
      this._wellId = null;
      this._blockId = value;
    }
  }
  get blockIdIsSet() { return ReportsFilter.BlockIdIsSet(this._blockId); }
  static BlockIdIsSet(blockId) { return typeof blockId === 'number'; }

  get wellId() { return this._wellId; }
  set wellId(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "ReportsFilter.wellId.set"');
    }

    if (this._wellId !== value) {
      this._wellId = value;
    }
  }
  get wellIdIsSet() { return typeof this._wellId === 'number'; }

  get subscriptionIds() { return this._subscriptionIds; }
  set subscriptionIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.subscriptionIds.set"');
    }

    if (this._subscriptionIds !== value) {
      this._subscriptionIds = value;
    }
  }
  get subscriptionIdsIsSet() { return typeof this._subscriptionIds.length > 0; }

  get categoryIds() { return this._categoryIds; }
  set categoryIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.categoryIds.set"');
    }

    if (this._categoryIds !== value) {
      this._categoryIds = value;
    }
  }
  get categoryIdsIsSet() { return typeof this._categoryIds.length > 0; }

  get dateFrom() { return this._dateFrom; }
  set dateFrom(value) {
    if (value !== null && !(value instanceof Date)) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.dateFrom.set"');
    }

    if (this._dateFrom !== value) {
      this._dateFrom = value;
    }
  }
  get dateFromIsSet() { return this._dateFrom instanceof Date; }

  get dateTo() { return this._dateTo; }
  set dateTo(value) {
    if (value !== null && !(value instanceof Date)) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.dateTo.set"');
    }

    if (this._dateTo !== value) {
      this._dateTo = value;
    }
  }
  get dateToIsSet() { return this._dateTo instanceof Date; }

  get isShowInstances() {
    const result = this.companyIdIsSet ||
      this.fieldIdIsSet ||
      this.discoveryIdIsSet ||
      this.quadrantIdIsSet ||
      this.blockIdIsSet ||
      this.wellIdIsSet ||
      this.searchStringIsSet;
    return result;
  }

  get isSet() {
    if (this._advancedSearch === true) {
      const result = this.companyIdIsSet ||
        this.countryIdIsSet ||
        this.fieldIdIsSet ||
        this.discoveryIdIsSet ||
        this.quadrantIdIsSet ||
        this.blockIdIsSet ||
        this.wellIdIsSet;
      return result;
    } else {
      const result = this.searchStringIsSet;
      return result;
    }
  }

  clear() {
    this._advancedSearch = false;
    this._searchType = searchTypes.AtLeastOne;
    this._searchString = '';
    this._companyId = null;
    this._countryId = null;
    this._fieldId = null;
    this._discoveryId = null;
    this._quadrantId = '';
    this._blockId = null;
    this._wellId = null;
    this._subscriptionIds = [];
    this._categoryIds = [];
    this._dateFrom = null;
    this._dateTo = null;
  }

  clone() {
    const result = new ReportsFilter();
    result._advancedSearch = this._advancedSearch;
    result._searchType = this._searchType;
    result._searchString = this._searchString;
    result._companyId = this._companyId;
    result._countryId = this._countryId;
    result._fieldId = this._fieldId;
    result._discoveryId = this._discoveryId;
    result._quadrantId = this._quadrantId;
    result._blockId = this._blockId;
    result._wellId = this._wellId;
    result._subscriptionIds = [...this._subscriptionIds];
    result._categoryIds = [...this._categoryIds];
    result._dateFrom = this._dateFrom;
    result._dateTo = this._dateTo;
    return result;
  }

  toApiParam() {
    const result = {
      advancedSearch: this._advancedSearch,
      searchType: this._searchType,
      searchString: this._searchString,
      companyId: this._companyId,
      countryId: this._countryId,
      fieldId: this._fieldId,
      discoveryId: this._discoveryId,
      quadrant: this._quadrantId,
      blockId: this._blockId,
      wellId: this._wellId,
      subscriptionIds: [...this._subscriptionIds],
      categoryIds: [...this._categoryIds],
      dateFrom: this._dateFrom,
      dateTo: this._dateTo
    }

    if (this.dateFromIsSet && this.dateToIsSet && this._dateFrom > this._dateTo) {
      result.dateFrom = null;
      result.dateTo = null;
    }

    return result;
  }
}

export { ReportsFilter, searchTypes };