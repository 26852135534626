import React from 'react';
import PropTypes from 'prop-types';

import CompletedAndActiveWellsTableComponent from './corporate-wells/completed-and-active-wells-table-component';
import PlannedWellsTableComponent from './corporate-wells/planned-wells-table-component';

import { CorporateDetail } from '../../../models/corporate-detail';
import { createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedCorporateWellComponent = (props) => {
  const { corporate } = props;
  var completedWellTable, plannedWellTable;
  var completedWellTableTitle = props.corporate.isActive === true ? 'Completed and active E&A wells' : 'Completed E&A wells';

  const hasCompletedWells = corporate.completedAndActiveWells !== null && corporate.completedAndActiveWells.length > 0;
  if (hasCompletedWells) {
    completedWellTable = <CompletedAndActiveWellsTableComponent
      currentCorporateId={corporate.corporateId}
      wells={corporate.completedAndActiveWells}
      onWellClick={props.onWellClick}
      onReportClick={props.onReportClick}
      onLicenceClick={props.onLicenceClick}
      onOperatorClick={props.onOperatorClick}
      onProspectClick={props.onProspectClick}
      onDiscoveryClick={props.onDiscoveryClick}
      onFieldClick={props.onFieldClick}
    />;
  } else {
    completedWellTable = (<div>No completed or active E&A wells</div>);
  }

  const hasPlannedWells = corporate.plannedWells !== null && corporate.plannedWells.length > 0;
  if (hasPlannedWells) {
    plannedWellTable = <PlannedWellsTableComponent
      currentCorporateId={corporate.corporateId}
      wells={corporate.plannedWells}
      onWellClick={props.onWellClick}
      onLicenceClick={props.onLicenceClick}
      onOperatorClick={props.onOperatorClick}
      onProspectClick={props.onProspectClick}
      onDiscoveryClick={props.onDiscoveryClick}
      onFieldClick={props.onFieldClick}
    />;
  } else {
    plannedWellTable = (<div>No planned E&A wells</div>);
  }

  return (
    <div ref={props.innerRef}>
      {createSection100(completedWellTable, completedWellTableTitle)}
      {corporate.isActive && createSection100(plannedWellTable, 'Planned E&A wells')}
    </div>
  );
};

AdvancedCorporateWellComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  corporate: PropTypes.instanceOf(CorporateDetail).isRequired,
  onWellClick: PropTypes.func,
  onReportClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onOperatorClick: PropTypes.func,
  onProspectClick: PropTypes.func,
  onDiscoveryClick: PropTypes.func,
  onFieldClick: PropTypes.func
};

export default AdvancedCorporateWellComponent;