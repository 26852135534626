import { appViews } from "../../app/app-views";
import { AppSetState } from "../../app/app-set-state";

import { valueTypes } from '../../shared/helpers/value-handler';
import { BaseExcelExportColumn, BaseExcelExport } from "../../shared/helpers/base-excel-export";
import { UserValuations } from '../../models/user-valuations';

class GridExcelExport {
  export(appSetState, dataRows, mapBaseExcelExport = null, mapWorkbook = null, sheetname = null, selectedValuation = null, baseValuation = null) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "GridExcelExport.export"');
    }
    if (selectedValuation !== null && !(selectedValuation instanceof UserValuations)) {
      throw new Error('Invalid "selectedValuation" param supplied to "FiscalBulkResourceExcelExport.export"');
    }
    if (baseValuation !== null && !(baseValuation instanceof UserValuations)) {
      throw new Error('Invalid "selectedValuation" param supplied to "FiscalBulkResourceExcelExport.export"');
    }

    const baseExcelExport = mapBaseExcelExport !== null ? mapBaseExcelExport : new BaseExcelExport();
    const workbook = mapWorkbook !== null ? mapWorkbook : baseExcelExport.createWorkbook();

    const appView = sheetname !== null ? sheetname : appViews.getDisplayName(appViews.Hub);

    const colDefs = [];
    colDefs.push(new BaseExcelExportColumn("Production Hub", 'hubName'));
    colDefs.push(new BaseExcelExportColumn("Country", "countryName"));
    colDefs.push(new BaseExcelExportColumn("Area", "areaName"));
    colDefs.push(new BaseExcelExportColumn("Operator", "operatorName"));
    colDefs.push(new BaseExcelExportColumn("Development Status", "developmentStatus"));
    colDefs.push(new BaseExcelExportColumn("Electrification Status", "electrificationStatus"));
    colDefs.push(new BaseExcelExportColumn("Liquid Export", "liquidExport"));
    colDefs.push(new BaseExcelExportColumn("Gas Export", "gasExport"));
    colDefs.push(new BaseExcelExportColumn("COP Year", 'lastProductionYear'));
    colDefs.push(new BaseExcelExportColumn("Number of Fields", "numberOfFields"));
    colDefs.push(new BaseExcelExportColumn("Remaining Liquid\nReserves (" + valueTypes.UnitString(valueTypes.mmb) + ")", "remainingLiquidMMB", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Remaining Gas\nReserves (" + valueTypes.UnitString(valueTypes.bcf) + ")", "remainingGasBCF", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Remaining Reserves (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "remainingReservesMMBOE", '0.00'));
    colDefs.push(new BaseExcelExportColumn("Current Year Production (" + valueTypes.UnitString(valueTypes.mboed) + ")", 'currentYearProductionBOEPD', '0.00'));
    colDefs.push(new BaseExcelExportColumn('Current Year CO2 Emissions (' + valueTypes.UnitString(valueTypes.ktCO2) + ')', 'currentYearCO2EmissionsKTCO2', '0.00'));

    baseExcelExport.writeSheet(workbook, appView, dataRows, colDefs);
    if (mapBaseExcelExport === null) baseExcelExport.writeWorkbook(workbook, baseExcelExport.getAtlas5WorkbookPrefix() + appView + baseExcelExport.getAtlas5WorkbookSuffix(), null, selectedValuation, baseValuation);
  }
}

export { GridExcelExport };
