import L from 'leaflet';

import { WgeWildcatBlue } from '../../../../scss/wge-colors';
import { DISABLED_CLASS_NAME, isIconDef, createButton, getObjectClassName, hasClass, addClass, removeClass, disableMapEvents, getMapEvents, restoreMapEvents } from '../../helpers/control-helper';

const HIDDEN_CLASS_NAME = 'sbc-button-hidden';
const TOGGLED_CLASS_NAME = 'sbc-button-toggled';
const BORDER_OPTIONS = { color: WgeWildcatBlue, weight: 2, fill: false, dashArray: '10, 5', interactive: true };
const MARKER_OPTIONS = { radius: 4, color: BORDER_OPTIONS.color, fillColor: BORDER_OPTIONS.color, fillOpacity: 1, weight: BORDER_OPTIONS.weight, interactive: false };
const TOOLTIP_OPTIONS = { direction: 'right', permanent: true };

class BaseButtonControl {
  constructor(searchButtonsControl) {
    const objectClassName = getObjectClassName(this);
    if (!isIconDef(this.iconDef)) {
      throw new Error('Property "iconDef" not overridden in "' + objectClassName + '.ctor"');
    }
    if (typeof this.title !== 'string' || this.title.length === 0) {
      throw new Error('Property "title" not overridden in "' + objectClassName + '.ctor"');
    }
    if (typeof this.cancel !== 'function') {
      throw new Error('Function "cancel" not overridden in "' + objectClassName + '.ctor"');
    }
    if (typeof this.onToggledChanged !== 'function') {
      throw new Error('Function "onToggledChanged" not overridden in "' + objectClassName + '.ctor"');
    }

    this._searchButtonsControl = searchButtonsControl;
    this._mapEvents = null;
    this._onComplete = null;
    this._layerGroup = null;
    this._button = createButton(searchButtonsControl.container, 'sbc-button', this.iconDef, () => this._onClick(), { title: this.title });
  }

  get leafletMap() {
    return this._searchButtonsControl.leafletMap;
  }
  get ignoreMouseEvents() {
    return this._searchButtonsControl.ignoreMouseEvents;
  }

  get onComplete() {
    return this._onComplete;
  }
  set onComplete(value) {
    if (typeof value !== 'function') {
      throw new Error('Invalid "value" param supplied to "BaseButtonControl.onComplete.set"');
    }

    this._onComplete = value;
  }

  get disabled() {
    return this._buttonHasClass(DISABLED_CLASS_NAME);
  }
  set disabled(value) {
    this._setButtonClass(DISABLED_CLASS_NAME, value);
  }

  get visible() {
    return !this._buttonHasClass(HIDDEN_CLASS_NAME);
  }
  set visible(value) {
    this._setButtonClass(HIDDEN_CLASS_NAME, !value);
  }

  get toggled() {
    return this._buttonHasClass(TOGGLED_CLASS_NAME);
  }
  set toggled(value) {
    if (!this.visible || this.disabled) {
      return;
    }

    const toggledChanged = this._setButtonClass(TOGGLED_CLASS_NAME, value);
    if (toggledChanged) {
      if (value) {
        this.leafletMap.getContainer().style.cursor = 'crosshair';
        this._mapEvents = getMapEvents(this.leafletMap);
        disableMapEvents(this.leafletMap);
      } else {
        this.leafletMap.getContainer().style.cursor = 'default';
        restoreMapEvents(this.leafletMap, this._mapEvents);
        this.cancel();
      }
      this.onToggledChanged(value);
      this._searchButtonsControl.updateCancelButton();
    }
  }

  get layerGroup() {
    if (this._layerGroup === null) {
      this._layerGroup = L.layerGroup();
      this.leafletMap.addLayer(this._layerGroup);
    }
    return this._layerGroup;
  }

  _buttonHasClass(className) {
    if (typeof className !== 'string') {
      throw new Error('Invalid "className" param supplied to "BaseButtonControl._buttonHasClass"');
    }

    return hasClass(this._button, className);
  }

  _setButtonClass(className, addClassName) {
    if (typeof className !== 'string') {
      throw new Error('Invalid "className" param supplied to "BaseButtonControl._setButtonClass"');
    }
    if (typeof addClassName !== 'boolean') {
      throw new Error('Invalid "addClassName" param supplied to "BaseButtonControl._setButtonClass"');
    }

    const result = addClassName
      ? addClass(this._button, className)
      : removeClass(this._button, className);
    return result;
  }

  _onClick() {
    if (!this.visible || this.disabled) {
      return;
    }

    const isToggled = this.toggled;
    this._searchButtonsControl.cancelAll();
    if (!isToggled) {
      this.toggled = true;
    }
  }

  addEvent(eventName, event) {
    if (typeof eventName !== 'string') {
      throw new Error('Invalid "eventName" param supplied to "BaseButtonControl._addEvent"');
    }
    if (typeof event !== 'function') {
      throw new Error('Invalid "event" param supplied to "BaseButtonControl._addEvent"');
    }

    L.DomEvent.on(this.leafletMap, eventName, event, this);
  }

  removeEvent(eventName, event) {
    if (typeof eventName !== 'string') {
      throw new Error('Invalid "eventName" param supplied to "BaseButtonControl._removeEvent"');
    }
    if (typeof event !== 'function') {
      throw new Error('Invalid "event" param supplied to "BaseButtonControl._removeEvent"');
    }

    L.DomEvent.off(this.leafletMap, eventName, event, this);
  }

  getDescriptionString(name, value) {
    if (typeof name !== 'string') {
      throw new Error('Invalid "name" param supplied to "BaseButtonControl._getDescriptionString"');
    }
    if (typeof value !== 'string') {
      throw new Error('Invalid "value" param supplied to "BaseButtonControl._getDescriptionString"');
    }

    return name + ': <b>' + value + '</b>';
  }

  getDistanceString(metres) {
    if (typeof metres !== 'number') {
      throw new Error('Invalid "metres" param supplied to "BaseButtonControl._getDistanceString"');
    }

    if (metres < 1000) {
      return metres.toFixed(0) + 'm';
    }

    const km = metres / 1000;
    const dp = km < 10 ? 1 : 0;
    return km.toFixed(dp) + 'km';
  }

  clearLayerGroup() {
    if (this._layerGroup !== null) {
      this.leafletMap.removeLayer(this._layerGroup);
      this._layerGroup = null;
    }
  }
}

export { BaseButtonControl, BORDER_OPTIONS, MARKER_OPTIONS, TOOLTIP_OPTIONS };