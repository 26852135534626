import { showError } from '../shared/helpers/common';
import { FiscalResource } from '../components/fiscal/models/portfolio/fiscal-resource';
import { PortfolioCashflow } from '../components/fiscal/models/portfolio/portfolio-cashflow';
import { WgeAnalyticsGreen, WgeSectorsRed, WgeCorporateBlue, WgeLightGrey } from '../shared/scss/wge-colors';
import { ChartData } from '../shared/components/generic-chart/chart-data'
import { assignColours } from '../shared/helpers/array-color-helper'
import { HubParticipant } from '../shared/components/models/gmatrix3-extension-hub-participant';
import { UserValuations } from './user-valuations';

//import { FieldParticipant, DisplayParticipant, CombinedProduction } from '../../../models/gmatrix3-extension-field-participant';
class EventItem {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to EventItem.ctor');
    }

    if (typeof obj.year !== 'number') { throw new Error('Invalid year param supplied to EventItem.ctor'); }
    if (typeof obj.eventName !== 'string') { throw new Error('Invalid eventName param supplied to EventItem.ctor'); }

    this.year = obj.year;
    this.eventName = obj.eventName;
    this.yVal = 1;
  }
}

class HubDetailChartData {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to HubDetailChartData.ctor');
    }
    if (typeof obj.hubProduction !== 'object') { showError('HubDetailChartData', 'hubProduction'); }
    if (typeof obj.hubReserves !== 'object') { showError('HubDetailChartData', 'hubReserves'); }
    if (typeof obj.productionByField !== 'object') { showError('HubDetailChartData', 'productionByField'); }
    if (typeof obj.remainingReservesByField !== 'object') { showError('HubDetailChartData', 'remainingReservesByField'); }
    if (obj.remainingReservesByParticipant !== null && typeof obj.remainingReservesByParticipant !== 'object') { showError('HubDetailChartData', 'remainingReservesByParticipant'); }
    if (obj.productionByParticipant !== null && typeof obj.productionByParticipant !== 'object') { showError('HubDetailChartData', 'productionByParticipant'); }
    if (obj.capexByField !== null && typeof obj.capexByField !== 'object') { showError('HubDetailChartData', 'capexByField'); }
    if (obj.opexByField !== null && typeof obj.opexByField !== 'object') { showError('HubDetailChartData', 'opexByField'); }
    if (obj.abexByField !== null && typeof obj.abexByField !== 'object') { showError('HubDetailChartData', 'abexByField'); }
    if (obj.hubOpexPerBOE !== null && typeof obj.hubOpexPerBOE !== 'object') { showError('HubDetailChartData', 'hubOpexPerBOE'); }
    if (obj.discoveryResource !== null && typeof obj.discoveryResource !== 'object') { showError('HubDetailChartData', 'discoveryResource'); }
    if (obj.discoveryResourceByClassificationReservoirAge !== null && typeof obj.discoveryResourceByClassificationReservoirAge !== 'object') { showError('HubDetailChartData', 'discoveryResourceByClassificationReservoirAge'); }
    if (obj.prospectByReservoirAge !== null && typeof obj.prospectByReservoirAge !== 'object') { showError('HubDetailChartData', 'prospectByReservoirAge'); }
    if (obj.prospectResourceByReservoirArea !== null && typeof obj.prospectResourceByReservoirArea !== 'object') { showError('HubDetailChartData', 'prospectResourceByReservoirArea'); }
    if (obj.emissions !== null && typeof obj.emissions !== 'object') { showError('HubDetailChartData', 'emissions'); }

    this.hubProduction = new ChartData(obj.hubProduction);
    if (this.hubProduction !== null) {
      this.hubProduction.yVal[0].color = WgeAnalyticsGreen;
      this.hubProduction.yVal[1].color = WgeSectorsRed;
    }

    this.hubReserves = new ChartData(obj.hubReserves);
    if (this.hubReserves !== null) {
      this.hubReserves.yVal[0].color = WgeAnalyticsGreen;
      this.hubReserves.yVal[1].color = WgeSectorsRed;
    }

    this.productionByField = new ChartData(obj.productionByField);
    if (this.productionByField !== null) {
      assignColours(this.productionByField.yVal, 'color');
    }

    this.remainingReservesByField = null;
    if (obj.remainingReservesByField !== null) {
      this.remainingReservesByField = new ChartData(obj.remainingReservesByField);
      this.remainingReservesByField.yVal[0].color = WgeAnalyticsGreen;
      this.remainingReservesByField.yVal[1].color = WgeSectorsRed;
    }

    this.remainingReservesByParticipant = null;
    if (obj.remainingReservesByParticipant !== null) {
      this.remainingReservesByParticipant = new ChartData(obj.remainingReservesByParticipant);
      this.remainingReservesByParticipant.yVal[0].color = WgeAnalyticsGreen;
      this.remainingReservesByParticipant.yVal[1].color = WgeSectorsRed;
    }

    this.productionByParticipant = null;
    if (obj.productionByParticipant !== null && obj.productionByParticipant !== undefined) {
      this.productionByParticipant = new ChartData(obj.productionByParticipant);
      assignColours(this.productionByParticipant.yVal, 'color');
    }

    this.capexByField = null;
    if (obj.capexByField !== null && obj.capexByField !== undefined) {
      this.capexByField = new ChartData(obj.capexByField);
      assignColours(this.capexByField.yVal, 'color');
    }

    this.opexByField = null;
    if (obj.opexByField !== null && obj.opexByField !== undefined) {
      this.opexByField = new ChartData(obj.opexByField);
      assignColours(this.opexByField.yVal, 'color');
    }

    this.abexByField = null;
    if (obj.abexByField !== null && obj.abexByField !== undefined) {
      this.abexByField = new ChartData(obj.abexByField);
      assignColours(this.abexByField.yVal, 'color');
    }

    this.hubOpexPerBOE = null;
    if (obj.hubOpexPerBOE !== null && obj.hubOpexPerBOE !== undefined) {
      this.hubOpexPerBOE = new ChartData(obj.hubOpexPerBOE);
      assignColours(this.hubOpexPerBOE.yVal, 'color');
    }

    this.discoveryResource = null;
    if (obj.discoveryResource !== null && obj.discoveryResource !== undefined) {
      this.discoveryResource = new ChartData(obj.discoveryResource);
      this.discoveryResource.yVal[0].color = WgeAnalyticsGreen;
      this.discoveryResource.yVal[1].color = WgeSectorsRed;  
    }

    this.discoveryResourceByClassificationReservoirAge = null;
    if (obj.discoveryResourceByClassificationReservoirAge !== null && obj.discoveryResourceByClassificationReservoirAge !== undefined) {
      this.discoveryResourceByClassificationReservoirAge = new ChartData(obj.discoveryResourceByClassificationReservoirAge);
      assignColours(this.discoveryResourceByClassificationReservoirAge.yVal, 'color');
    }

    this.prospectByReservoirAge = null;
    if (obj.prospectByReservoirAge !== null && obj.prospectByReservoirAge !== undefined) {
      this.prospectByReservoirAge = new ChartData(obj.prospectByReservoirAge);
      assignColours(this.prospectByReservoirAge.yVal, 'color');
    }

    this.prospectResourceByReservoirArea = null;
    if (obj.prospectResourceByReservoirArea !== null && obj.prospectResourceByReservoirArea !== undefined) {
      this.prospectResourceByReservoirArea = new ChartData(obj.prospectResourceByReservoirArea);
      assignColours(this.prospectResourceByReservoirArea.yVal, 'color');
    }

    this.emissions = null;
    if (obj.emissions !== null && obj.emissions !== undefined) {
      this.emissions = new ChartData(obj.emissions);
      this.emissions.yVal[0].color = WgeCorporateBlue;
      this.emissions.yVal[1].color = WgeLightGrey;
    }

  }
}

class HubAnnual {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to HubAnnual.ctor');
    }
    if (typeof obj.year !== 'number') { showError('HubAnnual', 'year'); }
    if (typeof obj.liquidProductionMbod !== 'number') { showError('HubAnnual', 'liquidProductionMbod'); }
    if (typeof obj.gasProductionMmscfd !== 'number') { showError('HubAnnual', 'gasProductionMmscfd'); }
    if (typeof obj.gasProductionMboed !== 'number') { showError('HubAnnual', 'gasProductionMboed'); }
    if (typeof obj.combinedMboed !== 'number') { showError('HubAnnual', 'combinedMboed'); }

    this.year = obj.year;
    this.liquidProductionMbod = obj.liquidProductionMbod;
    this.gasProductionMmscfd = obj.gasProductionMmscfd;
    this.gasProductionMboed = obj.gasProductionMboed;
    this.combinedMboed = obj.combinedMboed;
  }
}

class HubProximityDiscoveries {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to HubProximityDiscovery.ctor');
    }
    if (typeof obj.discoveryId !== 'number') { showError('HubProximityDiscoveries', 'discoveryId'); }
    if (typeof obj.discoveryName !== 'string') { showError('HubProximityDiscoveries', 'discoveryName'); }
    if (typeof obj.area !== 'string') { showError('HubProximityDiscoveries', 'area'); }
    if (typeof obj.licence !== 'string') { showError('HubProximityDiscoveries', 'licence'); }
    if (obj.licenceId !== null && typeof obj.licenceId !== 'number') { showError('HubProximityDiscoveries', 'licenceId'); }
    if (typeof obj.blockId !== 'number') { showError('HubProximityDiscoveries', 'blockId'); }
    if (typeof obj.operatorName !== 'string') { showError('HubProximityDiscoveries', 'operatorName'); }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') { showError('HubProximityDiscoveries', 'operatorId'); }
    if (typeof obj.discoveryYear !== 'number') { showError('HubProximityDiscoveries', 'discoveryYear'); }
    if (typeof obj.discoveryWell !== 'string') { showError('HubProximityDiscoveries', 'discoveryWell'); }
    if (typeof obj.discoveryWellId !== 'number') { showError('HubProximityDiscoveries', 'discoveryWellId'); }
    if (typeof obj.initialLiquidMmb !== 'number') { showError('HubProximityDiscoveries', 'initialLiquidMmb'); }
    if (typeof obj.initialGasBcf !== 'number') { showError('HubProximityDiscoveries', 'initialGasBcf'); }
    if (typeof obj.gasMmboe !== 'number') { showError('HubProximityDiscoveries', 'gasMmboe'); }
    if (typeof obj.combinedMmboe !== 'number') { showError('HubProximityDiscoveries', 'combinedMmboe'); }
    if (typeof obj.reservoirAgeEra !== 'string') { showError('HubProximityDiscoveries', 'reservoirAgeEra'); }
    if (typeof obj.discoveryClassification !== 'string') { showError('discoveryClassification', 'combinedMboed'); }
    if (typeof obj.distance !== 'number') { showError('HubProximityDiscoveries', 'distance'); }

    this.discoveryId = obj.discoveryId;
    this.discoveryName = obj.discoveryName;
    this.area = obj.area;
    this.licence = obj.licence;
    this.licenceId = obj.licenceId;
    this.blockName = obj.blockName;
    this.blockId = obj.blockId;
    this.operatorName = obj.operatorName;
    this.operatorId = obj.operatorId;
    this.discoveryYear = obj.discoveryYear;
    this.discoveryWell = obj.discoveryWell;
    this.discoveryWellId = obj.discoveryWellId;
    this.initialLiquidMmb = obj.initialLiquidMmb;
    this.initialGasBcf = obj.initialGasBcf;
    this.gasMmboe = obj.gasMmboe;
    this.combinedMmboe = obj.combinedMmboe;
    this.reservoirAgeEra = obj.reservoirAgeEra;
    this.discoveryClassification = obj.discoveryClassification;
    this.distance = obj.distance;
  }
}

class HubProximityProspects {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to HubProximityProspects.ctor');
    }
    if (typeof obj.prospectId !== 'number') { showError('HubProximityProspects', 'prospectId'); }
    if (typeof obj.prospectIdentifier !== 'string') { showError('HubProximityProspects', 'prospectIdentifier'); }
    if (typeof obj.prospectName !== 'string') { showError('HubProximityProspects', 'prospectName'); }
    if (typeof obj.area !== 'string') { showError('HubProximityProspects', 'area'); }
    if (typeof obj.licence !== 'string') { showError('HubProximityProspects', 'licence'); }
    if (obj.licenceId !== null && typeof obj.licenceId !== 'number') { showError('HubProximityProspects', 'licenceId'); }
    if (typeof obj.blockName !== 'string') { showError('HubProximityProspects', 'blockName'); }
    if (typeof obj.blockId !== 'number') { showError('HubProximityProspects', 'blockId'); }
    if (typeof obj.operatorName !== 'string') { showError('HubProximityProspects', 'operatorName'); }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') { showError('HubProximityProspects', 'operatorId'); }
    if (typeof obj.initialLiquidMmb !== 'number') { showError('HubProximityProspects', 'initialLiquidMmb'); }
    if (typeof obj.initialGasBcf !== 'number') { showError('HubProximityProspects', 'initialGasBcf'); }
    if (typeof obj.combinedMmboe !== 'number') { showError('HubProximityProspects', 'combinedMmboe'); }
    if (typeof obj.reservoirAgeEra !== 'string') { showError('HubProximityProspects', 'reservoirAgeEra'); }
    if (typeof obj.distance !== 'number') { showError('HubProximityProspects', 'distance'); }

    this.prospectId = obj.prospectId;
    this.prospectIdentifier = obj.prospectIdentifier;
    this.prospectName = obj.prospectName;
    this.area = obj.area;
    this.licence = obj.licence;
    this.licenceId = obj.licenceId;
    this.blockName = obj.blockName;
    this.blockId = obj.blockId;
    this.operatorName = obj.operatorName;
    this.operatorId = obj.operatorId;
    this.initialLiquidMmb = obj.initialLiquidMmb;
    this.initialGasBcf = obj.initialGasBcf;
    this.combinedMmboe = obj.combinedMmboe;
    this.reservoirAgeEra = obj.reservoirAgeEra;
    this.distance = obj.distance;
  }
}

class HubProximityCompletedActiveWells {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to HubProximityCompletedActiveWells.ctor');
    }
    if (typeof obj.wellId !== 'number') { showError('HubProximityCompletedActiveWells', 'wellId'); }
    if (typeof obj.wellName !== 'string') { showError('HubProximityCompletedActiveWells', 'wellName'); }
    if (obj.resources !== null && !Array.isArray(obj.resources)) { showError('HubProximityCompletedActiveWells', 'resources'); }
    if (typeof obj.wellType !== 'string') { showError('HubProximityCompletedActiveWells', 'wellType'); }
    if (typeof obj.operatorName !== 'string') { showError('HubProximityCompletedActiveWells', 'operatorName'); }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') { showError('HubProximityCompletedActiveWells', 'operatorId'); }
    if (typeof obj.spudDate !== 'string') { showError('HubProximityCompletedActiveWells', 'spudDate'); }
    if (obj.completionDate !== null && typeof obj.completionDate !== 'string') { showError('HubProximityCompletedActiveWells', 'completionDate'); }
    if (typeof obj.wellStatus !== 'string') { showError('HubProximityCompletedActiveWells', 'wellStatus'); }
    if (typeof obj.wellResult !== 'string') { showError('HubProximityCompletedActiveWells', 'wellResult'); }
    if (obj.originalLicenceId !== null && typeof obj.originalLicenceId !== 'number') { showError('HubProximityCompletedActiveWells', 'originalLicenceId'); }
    if (obj.originalLicence !== null && typeof obj.originalLicence !== 'string') { showError('HubProximityCompletedActiveWells', 'originalLicence'); }
    if (obj.currentLicenceId !== null && typeof obj.currentLicenceId !== 'number') { showError('HubProximityCompletedActiveWells', 'currentLicenceId'); }
    if (obj.currentLicence !== null && typeof obj.currentLicence !== 'string') { showError('HubProximityCompletedActiveWells', 'currentLicence'); }
    if (typeof obj.distance !== 'number') { showError('HubProximityCompletedActiveWells', 'distance'); }
    if (typeof obj.reportId !== 'number') {
      throw new Error('Invalid "obj.reportId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }

    this.wellId = obj.wellId;
    this.wellName = obj.wellName;
    this.resources = obj.resources;
    this.resourceString = this.resources === null ? null : this.resources.map(x => x.resourceName).join('; ');
    this.wellType = obj.wellType;
    this.operatorName = obj.operatorName;
    this.operatorId = obj.operatorId;
    this.spudDate = new Date(obj.spudDate);
    this.completionDate = new Date(obj.completionDate);
    this.wellStatus = obj.wellStatus;
    this.wellResult = obj.wellResult;
    this.originalLicenceId = obj.originalLicenceId;
    this.originalLicence = obj.originalLicence;
    this.currentLicenceId = obj.currentLicenceId;
    this.currentLicence = obj.currentLicence;
    this.reportId = obj.reportId;
    this.distance = obj.distance;
  }
}

class HubProximityPlannedWells {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to HubProximityPlannedWells.ctor');
    }
    if (typeof obj.wellId !== 'number') { showError('HubProximityPlannedWells', 'wellId'); }
    if (typeof obj.wellName !== 'string') { showError('HubProximityPlannedWells', 'wellName'); }
    if (obj.resources !== null && !Array.isArray(obj.resources)) { showError('HubProximityPlannedWells', 'resources'); }
    if (typeof obj.wellType !== 'string') { showError('HubProximityPlannedWells', 'wellType'); }
    if (typeof obj.wellStatus !== 'string') { showError('HubProximityPlannedWells', 'wellStatus'); }
    if (typeof obj.operatorName !== 'string') { showError('HubProximityPlannedWells', 'operatorName'); }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') { showError('HubProximityPlannedWells', 'operatorId'); }
    if (typeof obj.estimatedTiming !== 'string') { showError('HubProximityPlannedWells', 'estimatedTiming'); }
    if (obj.licenceId !== null && typeof obj.licenceId !== 'number') { showError('HubProximityPlannedWells', 'licenceId'); }
    if (obj.licence !== null && typeof obj.licence !== 'string') { showError('HubProximityPlannedWells', 'licence'); }
    if (typeof obj.distance !== 'number') { showError('HubProximityPlannedWells', 'distance'); }
    if (typeof obj.reportId !== 'number') {
      throw new Error('Invalid "obj.reportId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }

    this.wellId = obj.wellId;
    this.wellName = obj.wellName;
    this.resources = obj.resources;
    this.resourceString = this.resources === null ? null : this.resources.map(x => x.resourceName).join('; ');
    this.wellType = obj.wellType;
    this.wellStatus = obj.wellStatus;
    this.operatorName = obj.operatorName;
    this.operatorId = obj.operatorId;
    this.estimatedTiming = obj.estimatedTiming;
    this.licenceId = obj.licenceId;
    this.licence = obj.licence;
    this.reportId = obj.reportId;
    this.distance = obj.distance;
  }
}

class HubDetail {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('HubDetail', 'Field Detail'); }

    if (obj.fiscalResources !== null && !Array.isArray(obj.fiscalResources)) { showError('HubDetail', 'fiscalResources'); }
    if (!Array.isArray(obj.hubAnnuals)) { showError('HubDetail', 'hubAnnuals'); }
    if (!Array.isArray(obj.owners)) { showError('FieldDetail', 'owners'); }

    if (obj.proximityDiscoveries !== null && !Array.isArray(obj.proximityDiscoveries)) { showError('HubDetail', 'proximityDiscoveries'); }
    if (obj.proximityProspects !== null && !Array.isArray(obj.proximityProspects)) { showError('HubDetail', 'proximityProspects'); }
    if (obj.proximityCompletedActiveWells !== null && !Array.isArray(obj.proximityCompletedActiveWells)) { showError('HubDetail', 'proximityCompletedActiveWells'); }
    if (obj.proximityPlannedWells !== null && !Array.isArray(obj.proximityPlannedWells)) { showError('HubDetail', 'proximityPlannedWells'); }

    if (typeof obj.hubId !== 'number') { showError('HubDetail', 'hubId'); }
    if (typeof obj.hubName !== 'string') { showError('HubDetail', 'hubName'); }
    if (typeof obj.countryId !== 'number') { showError('HubDetail', 'countryId'); }
    if (typeof obj.countryName !== 'string') { showError('HubDetail', 'countryName'); }
    if (typeof obj.areaName !== 'string') { showError('HubDetail', 'areaName'); }
    if (typeof obj.producingFields !== 'number') { showError('HubDetail', 'producingFields'); }
    if (typeof obj.underDevelopmentFields !== 'number') { showError('HubDetail', 'underDevelopmentFields'); }
    if (typeof obj.nearTermFields !== 'number') { showError('HubDetail', 'nearTermFields'); }
    if (typeof obj.operatorName !== 'string') { showError('HubDetail', 'operatorName'); }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') { showError('HubDetail', 'operatorId'); }
    if (typeof obj.oilPipeline !== 'string') { showError('HubDetail', 'oilPipeline'); }
    if (typeof obj.gasPipeline !== 'string') { showError('HubDetail', 'gasPipeline'); }
    if (obj.clientChangeDescription !== null && typeof obj.clientChangeDescription !== 'string') { showError('HubDetail', 'clientChangeDescription'); }
    if (obj.clientChangeDescriptionDate !== null && typeof obj.clientChangeDescriptionDate !== 'string') { showError('HubDetail', 'clientChangeDescriptionDate'); }
    if (typeof obj.emissionsComment !== 'string') { showError('HubDetail', 'emissionsComment'); }
    if (typeof obj.electrificationStatus !== 'string') { showError('HubDetail', 'electrificationStatus'); }

    // Reserves
    if (typeof obj.totalRecoverableLiquidMmb !== 'number') { showError('HubDetail', 'totalRecoverableLiquidMmb'); }
    if (typeof obj.remainingLiquidMmb !== 'number') { showError('HubDetail', 'remainingLiquidMmb'); }
    if (typeof obj.liquidPercentRemaining !== 'number') { showError('HubDetail', 'liquidPercentRemaining'); }

    if (typeof obj.totalRecoverableGasBcf !== 'number') { showError('HubDetail', 'totalRecoverableGasBcf'); }
    if (typeof obj.remainingGasBcf !== 'number') { showError('HubDetail', 'remainingGasBcf'); }
    if (typeof obj.gasPercentRemaining !== 'number') { showError('HubDetail', 'gasPercentRemaining'); }

    if (typeof obj.totalRecoverableCombinedMmboe !== 'number') { showError('HubDetail', 'totalRecoverableCombinedMmboe'); }
    if (typeof obj.remainingCombinedMmboe !== 'number') { showError('HubDetail', 'remainingCombinedMmboe'); }
    if (typeof obj.combinedPercentRemaining !== 'number') { showError('HubDetail', 'combinedPercentRemaining'); }

    if (typeof obj.currentYear !== 'number') { showError('HubDetail', 'currentYear'); }
    if (typeof obj.peakCombinedProductionYear !== 'number') { showError('HubDetail', 'peakCombinedProductionYear'); }
    if (typeof obj.hubCOPYear !== 'number') { showError('HubDetail', 'hubCOPYear'); }
    if (typeof obj.hasFiscal !== 'boolean') { showError('HubDetail', 'hasFiscal'); }

    if (obj.cashflow !== null && typeof obj.cashflow !== 'object') { showError('HubDetail', 'cashflow'); }
    if (typeof obj.chartDataCollection !== 'object') { showError('HubDetail', 'chartDataCollection'); }
    if (!Array.isArray(obj.events)) { showError('HubDetail', 'events'); }
    if (typeof obj.valuation !== 'object') { showError('HubDetail', 'valuation'); }

    this.owners = obj.owners.map(obj => new HubParticipant(obj));

    this.fiscalResources = obj.fiscalResources !== null ? obj.fiscalResources.map(obj => new FiscalResource(obj)) : null;
    this.hubAnnuals = obj.hubAnnuals.map(obj => new HubAnnual(obj));
    this.cashflow = obj.cashflow !== null && new PortfolioCashflow(obj.cashflow);
    this.chartDataCollection = obj.chartDataCollection !== null ? new HubDetailChartData(obj.chartDataCollection) : null;
    this.events = obj.events.map(obj => new EventItem(obj));
    this.valuation = new UserValuations(obj.valuation);

    this.proximityDiscoveries = obj.proximityDiscoveries !== null ? obj.proximityDiscoveries.map(obj => new HubProximityDiscoveries(obj)) : null;
    this.proximityProspects = obj.proximityProspects !== null ? obj.proximityProspects.map(obj => new HubProximityProspects(obj)) : null;
    this.proximityCompletedActiveWells = obj.proximityCompletedActiveWells !== null ?  obj.proximityCompletedActiveWells.map(obj => new HubProximityCompletedActiveWells(obj)) : null;
    this.proximityPlannedWells = obj.proximityPlannedWells !== null ?  obj.proximityPlannedWells.map(obj => new HubProximityPlannedWells(obj)) : null;

    this.hubId = obj.hubId;
    this.hubName = obj.hubName;
    this.countryId = obj.countryId;
    this.countryName = obj.countryName;
    this.areaName = obj.areaName;
    this.producingFields = obj.producingFields;
    this.underDevelopmentFields = obj.underDevelopmentFields;
    this.nearTermFields = obj.nearTermFields;
    this.operatorName = obj.operatorName;
    this.operatorId = obj.operatorId;
    this.oilPipeline = obj.oilPipeline;
    this.gasPipeline = obj.gasPipeline;
    this.clientChangeDescription = obj.clientChangeDescription;
    this.clientChangeDescriptionDate = new Date(obj.clientChangeDescriptionDate);
    this.emissionsComment = obj.emissionsComment;
    this.electrificationStatus = obj.electrificationStatus;

    this.totalRecoverableLiquidMmb = obj.totalRecoverableLiquidMmb;
    this.remainingLiquidMmb = obj.remainingLiquidMmb;
    this.liquidPercentRemaining = obj.liquidPercentRemaining;

    this.totalRecoverableGasBcf = obj.totalRecoverableGasBcf;
    this.remainingGasBcf = obj.remainingGasBcf;
    this.gasPercentRemaining = obj.gasPercentRemaining;

    this.totalRecoverableCombinedMmboe = obj.totalRecoverableCombinedMmboe;
    this.remainingCombinedMmboe = obj.remainingCombinedMmboe;
    this.combinedPercentRemaining = obj.combinedPercentRemaining;
    this.currentYear = obj.currentYear;
    this.peakCombinedProductionYear = obj.peakCombinedProductionYear;
    this.hubCOPYear = obj.hubCOPYear;
    this.hasFiscal = obj.hasFiscal;
  }
}

export { HubDetail, HubDetailChartData, HubAnnual, HubProximityDiscoveries, HubProximityProspects, HubProximityCompletedActiveWells, HubProximityPlannedWells };