import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'reactstrap';

import AdvancedCashflowComponent from './advanced-cashflow-component';
import AdvancedHubCostsComponent from './advanced-hub-costs-component';
import AdvancedKeyFactsComponent from './advanced-key-facts-component';
import AdvancedHubFieldsComponent from './advanced-hub-fields-component';
import AdvancedReservesAndProductionComponent from './advanced-reserves-and-production-component';
import AdvancedHubFieldsParticipationComponent from './advanced-hub-fields-participation-component';
import AdvancedHubDiscoveryComponent from './advanced-hub-discoveries-component';
import AdvancedHubProspectComponent from './advanced-hub-prospects-component';
import AdvancedHubWellComponent from './advanced-hub-well-component';
import AdvancedLastUpdateComponent from './advanced-last-update-component';
import AdvancedEmissionsComponent from '../../../components/fiscal/emissions/advanced-emissions-component';

import { objectToEnum } from '../../../shared/helpers/common';
import { browserIsMicrosoft } from '../../../shared/helpers/browser-detect';
import { ContextLayerModel } from '../../../shared/helpers/leaflet/leaflet-helper';
import { HubDetail } from '../../../models/hub-detail';
import DetailViewGridLayoutComponent from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import PriceSettingsComponent from '../../../sharedComponents/price-settings-component';
import ProximityIncrementor from '../proximity-incrementor';
import { appIcons } from '../../../app/app-icons';
import { BUTTON_ICON_SIZE } from '../../header-component';

const sections = objectToEnum({
  KeyFacts: 0,
  ReservesAndProduction: 1,
  Fields: 3,
  FieldsParticipation: 4,
  Costs: 5,
  Cashflow: 6,
  LastUpdate: 7,
  Discovery: 8,
  Prospect: 9,
  Well: 10,
  Emissions: 11
}, false);

sections.getDisplayName = (value) => {
  switch (value) {
    case sections.KeyFacts:
      return 'Key Facts';
    case sections.ReservesAndProduction:
      return 'Reserves & Production';
    case sections.Fields:
      return 'Fields';
    case sections.FieldsParticipation:
      return 'Participant summary';
    case sections.Costs:
      return 'Costs';
    case sections.Cashflow:
      return 'Cashflow';
    case sections.LastUpdate:
      return 'Last Update';
    case sections.Discovery:
      return 'Discoveries';
    case sections.Prospect:
      return 'Prospects';
    case sections.Well:
      return 'Wells';
    case sections.Emissions:
      return 'Emissions';
    default:
      throw new Error('Invalid "value" param supplied to "sections.getDisplayName"');
  }
};

Object.freeze(sections);

class AdvancedHubComponent extends React.Component {
  constructor(props) {
    super(props);

    this._scrollOffset = browserIsMicrosoft() ? 10 : 5;
    this.handleContentScroll = this.handleContentScroll.bind(this);
    this.contentRef = React.createRef();
    this.toolbarRef = React.createRef();
    this.keyFactsRef = React.createRef();
    this.reservesAndProductionRef = React.createRef();
    this.fieldsRef = React.createRef();
    this.fieldsParticipationRef = React.createRef();
    this.costsRef = React.createRef();
    this.cashflowRef = React.createRef();
    this.lastUpdateRef = React.createRef();
    this.discoveriesRef = React.createRef();
    this.prospectsRef = React.createRef();
    this.wellsRef = React.createRef();
    this.emissionsRef = React.createRef();
    this.state = { selectedSection: null };
  }

  componentDidMount() {
    this.contentRef.current.addEventListener('scroll', this.handleContentScroll, true);
    this.handleContentScroll();
  }

  componentWillUnmount() {
    this.contentRef.current.removeEventListener('scroll', this.handleContentScroll);
  }

  getSectionInView() {
    const bottom = this.toolbarRef.current.getBoundingClientRect().bottom + this._scrollOffset;

    let section = sections.Emissions;
    let sectionTop = this.getSectionTop(section);
    if (sectionTop !== null && sectionTop <= bottom) {
      return section;
    }

    //fix for when the bottom section is not big enough to fill the panel
    var detailViewPanel = document.getElementById('detailViewGrid');
    if((detailViewPanel.offsetHeight + detailViewPanel.scrollTop) >= detailViewPanel.scrollHeight) {
      return section;
    }

    section = sections.Well;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.Prospect;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.Discovery;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.LastUpdate;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.Cashflow;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.Costs;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.FieldsParticipation;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.Fields;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    section = sections.ReservesAndProduction;
    sectionTop = this.getSectionTop(section);
    if (sectionTop <= bottom) {
      return section;
    }

    return sections.KeyFacts;
  }

  getRef(section) {
    switch (section) {
      case sections.KeyFacts:
        return this.keyFactsRef;
      case sections.ReservesAndProduction:
        return this.reservesAndProductionRef;
      case sections.Fields:
        return this.fieldsRef;
      case sections.FieldsParticipation:
        return this.fieldsParticipationRef;
      case sections.Costs:
        return this.costsRef;
      case sections.Cashflow:
        return this.cashflowRef;
      case sections.LastUpdate:
        return this.lastUpdateRef;
      case sections.Discovery:
        return this.discoveriesRef;
      case sections.Prospect:
        return this.prospectsRef;
      case sections.Well:
        return this.wellsRef;
      case sections.Emissions:
        return this.emissionsRef;
      default:
        throw new Error('Invalid "section" param supplied to "AdvancedHubComponent.getRef"');
    }
  }

  getSectionTop(section) {
    const ref = this.getRef(section);
    if (ref.current === null) {
      return null;
    }

    const sectionRect = this.getRef(section).current.getBoundingClientRect();
    const result = sectionRect.top;
    return result;
  }

  handleContentScroll() {
    const sectionInView = this.getSectionInView();
    this.setState(prevState => {
      return prevState.selectedSection === sectionInView
        ? null
        : { selectedSection: sectionInView };
    })
  }

  btnClick(section) {
    this.getRef(section).current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }

  getSectionButton(section) {
    const { selectedSection } = this.state;

    if (section === sections.LastUpdate
      && (this.props.hub === null
        || this.props.hub.clientChangeDescription === null
        || this.props.hub.clientChangeDescription === ''
      )) { return null; }

    const result = (
      <Button key={'section-btn-' + section} active={section === selectedSection} style={{ width: '90%', marginRight: '3px', borderRadius: '0px', border: 'none' }} onClick={() => this.btnClick(section)}>
        {sections.getDisplayName(section)}
      </Button>
    );
    return result;
  }

  getSection(section) {
    const props = {
      key: 'section-' + section,
      innerRef: this.getRef(section),
      hub: this.props.hub
    };

    if (section === sections.LastUpdate
      && (this.props.hub === null
      || this.props.hub.clientChangeDescription === null
      || this.props.hub.clientChangeDescription === ''
      )) { return null; }

    switch (section) {
      case sections.KeyFacts:
        return <AdvancedKeyFactsComponent {...props} geoserverUrl={this.props.geoserverUrl} contextLayers={this.props.contextLayers} onOperatorClick={this.props.onCorporateClick} />;
      case sections.ReservesAndProduction:
        return <AdvancedReservesAndProductionComponent {...props} />;
      case sections.Fields:
        return <AdvancedHubFieldsComponent {...props} onFieldClick={this.props.onFieldClick} onOperatorClick={this.props.onCorporateClick} />;
      case sections.FieldsParticipation:
        return <AdvancedHubFieldsParticipationComponent {...props} onCorporateClick={this.props.onCorporateClick} />;
      case sections.Costs:
        return <AdvancedHubCostsComponent {...props} />;
      case sections.Cashflow:
        return <AdvancedCashflowComponent {...props} />;
      case sections.LastUpdate:
        return <AdvancedLastUpdateComponent {...props} />;
      case sections.Discovery:
        return <AdvancedHubDiscoveryComponent {...props}
          proximity={this.props.proximity}
          dataRows={this.props.hub}
          onDiscoveryClick={this.props.onDiscoveryClick}
          onLicenceClick={this.props.onLicenceClick}
          onBlockClick={this.props.onBlockClick}
          onWellClick={this.props.onWellClick}
          onOperatorClick={this.props.onCorporateClick} />;
      case sections.Prospect:
        return <AdvancedHubProspectComponent {...props}
          dataRows={props.hub}
          proximity={this.props.proximity}
          onProspectClick={this.props.onProspectClick}
          onLicenceClick={this.props.onLicenceClick}
          onBlockClick={this.props.onBlockClick}
          onWellClick={this.props.onWellClick}
          onOperatorClick={this.props.onCorporateClick} />;
      case sections.Well:
        return <AdvancedHubWellComponent {...props}
          dataRows={props.hub}
          proximity={this.props.proximity}
          onOperatorClick={this.props.onCorporateClick}
          onWellClick={this.props.onWellClick}
          onLicenceClick={this.props.onLicenceClick}
          onProspectClick={this.props.onProspectClick}
          onDiscoveryClick={this.props.onDiscoveryClick}
          onFieldClick={this.props.onFieldClick}
          onReportClick={this.props.onReportClick} />;
      case sections.Emissions:
        return <AdvancedEmissionsComponent {...props} emissions={props.hub.chartDataCollection.emissions} emissionsComment={props.hub.emissionsComment}/>;
      default:
        throw new Error('Invalid "section" param supplied to "AdvancedHubComponent.getSection"');
    }
  }

  _updateSettings = () => {
    this.props.closeSettings();
    this.props.reLoader(this.props.appSetState, this.props.currentProximity, this.props.currentProximity, this.props.proximityLower, this.props.proximityUpper, this.props.hubId);
    //is it right to use current proximity for new proximity?
  };

  render() {
    const newSection = this.props.showSettings
      ? <PriceSettingsComponent appSetState={this.props.appSetState} isDetail isHub onUpdate={this._updateSettings} advancedPlus={this.props.advancedPlus} />
      : null;

    return (
      <div style={{ height: '100%' }}>
        {newSection}
        <div ref={this.toolbarRef} style={{ height: '116px', padding: '5px' }}>
          <div style={{ fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'left', position: 'relative', float: 'left', width: '90%', marginLeft: '2px' }} className="valuation-banner">
            Valuation Settings: 
            <small style={{ marginLeft: '2px', textAlign: 'left', position: 'relative', fontSize: '1.25rem' }}><a alt="" onClick={() => this.props.openSettings()} title="Show Valuations"> {this.props.hub.valuation.variableName}</a></small>
          </div>
          <ProximityIncrementor appSetState={this.props.appSetState} currentProximity={this.props.currentProximity} proximityLower={this.props.proximityLower} proximityUpper={this.props.proximityUpper} hubId={this.props.hubId} title={"Refresh Proximity"} iconDef={appIcons.Undo} iconSize={BUTTON_ICON_SIZE} iconSolid={true} disabled={!this.props.loaded} onClick={this.props.reLoader} />
          <ButtonGroup style={{ float: 'left', width:'90%' }}>
            {sections.map(obj => this.getSectionButton(obj))}
          </ButtonGroup>
        </div>
        <div ref={this.contentRef} style={{ width: '100%', height: 'calc(100% - 116px)', overflow: 'auto' }}>
          
          <DetailViewGridLayoutComponent>
            {sections.map(obj => this.getSection(obj))}
          </DetailViewGridLayoutComponent>
        </div>
      </div>
    );
  }
}

AdvancedHubComponent.propTypes = {
  hub: PropTypes.instanceOf(HubDetail).isRequired,
  proximity: PropTypes.number.isRequired,
  onFieldClick: PropTypes.func,
  onCorporateClick: PropTypes.func,
  onDiscoveryClick: PropTypes.func,
  onProspectClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onWellClick: PropTypes.func,
  geoserverUrl: PropTypes.string.isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired
};

export default AdvancedHubComponent;
