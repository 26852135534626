import { CorporateDetail } from '../../models/corporate-detail';
import { BaseExcelExportColumn, BaseExcelExport } from "../../shared/helpers/base-excel-export";
import { valueTypes } from '../../shared/helpers/value-handler';
import { UserValuations } from '../../models/user-valuations';

class CorporateDetailExcelExport extends BaseExcelExport {
  constructor(corporateDetail, isFiltered, valuation, baseValuation) {
    if (!(corporateDetail instanceof CorporateDetail)) {
      throw new Error('Invalid "corporateDetail" param supplied to "CorporateDetailExcelExport.ctor"');
    }
    if (!(valuation instanceof UserValuations)) {
      throw new Error('Invalid "valuation" param supplied to "CorporateDetailExcelExport.ctor"');
    }
    if (!(baseValuation instanceof UserValuations)) {
      throw new Error('Invalid "baseValuation" param supplied to "CorporateDetailExcelExport.ctor"');
    }

    super();
    this._corporateDetail = corporateDetail;
    this._filteredMessage = [];
    if (isFiltered === true) this._filteredMessage[0] = 'Company data is filtered';
    this._valuation = valuation;
    this._baseValuation = baseValuation;
  }

  exportAll() {
    const workbook = this.createWorkbook();

    const corporateSheet = 'Company details';
    const cashflowSheet = 'Company annual cashflow';
    

    this.exportCorporates(workbook, this._corporateDetail.excelSummary, corporateSheet);
    if (this._corporateDetail.cashflow !== null) { this.exportCashflowAnnuals(workbook, this._corporateDetail.cashflow.cashflowAnnuals, cashflowSheet); }
    if (this._corporateDetail.netFields !== null && this._corporateDetail.netFields.length > 0) { this.exportFields(workbook, this._corporateDetail.netFields, 'Fields'); }
    if (this._corporateDetail.netDiscoveries !== null && this._corporateDetail.netDiscoveries.length > 0) { this.exportDiscos(workbook, this._corporateDetail.netDiscoveries, 'Discoveries'); }
    if (this._corporateDetail.netProspects !== null && this._corporateDetail.netProspects.length > 0) { this.exportProspects(workbook, this._corporateDetail.netProspects, 'Prospects'); }
    if (this._corporateDetail.completedAndActiveWells !== null && this._corporateDetail.completedAndActiveWells.length > 0) { this.exportCompletedActiveWells(workbook, this._corporateDetail.completedAndActiveWells, 'E&A Wells - Completed & Active'); }
    if (this._corporateDetail.plannedWells !== null && this._corporateDetail.plannedWells.length > 0) { this.exportFutureWells(workbook, this._corporateDetail.plannedWells, 'E&A Wells - Future'); }

    this.writeWorkbook(workbook, 'Company ' + this._corporateDetail.corporateName + this.getAtlas5WorkbookSuffix(), this._filteredMessage, this._valuation, this._baseValuation);
  }

  exportCorporates(workbook, dataRows, sheetName) {

    const colDefs = [
      new BaseExcelExportColumn("Company Name", 'name'),
      new BaseExcelExportColumn("Country(s)", "country"),
      new BaseExcelExportColumn("Area(s)", "areaList"),
      new BaseExcelExportColumn("Company Category", "corporateCategory"),
      new BaseExcelExportColumn("Net Remaining Liquid (" + valueTypes.UnitString(valueTypes.mmb) + ")", "netRemainingLiquidMMB", '0.00'),
      new BaseExcelExportColumn("Net Remaining Gas (" + valueTypes.UnitString(valueTypes.bcf) + ")", "netRemainingGasBCF", '0.00'),
      new BaseExcelExportColumn("Net Remaining Reserves (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "netRemainingReservesMMBOE", '0.00'),
      new BaseExcelExportColumn("Net Current Year Production (" + valueTypes.UnitString(valueTypes.mboed) + ")", 'netCurrentYearProductionBOEPD', '0.00'),
      new BaseExcelExportColumn("Net Current Year Liquid Production (" + valueTypes.UnitString(valueTypes.mboed) + ")", 'netCurrentYearLiquidProductionBOEPD', '0.00'),
      new BaseExcelExportColumn("Net Current Year Gas Production (" + valueTypes.UnitString(valueTypes.mmscfd) + ")", 'netCurrentYearGasProductionMMSCFD', '0.00'),
      new BaseExcelExportColumn("Net Remaining Revenue (" + valueTypes.UnitString(valueTypes.usdMM) + ")", 'netRemainingRevenueUSDMM', '0.00'),
      new BaseExcelExportColumn("Net Remaining Pre-Tax Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", "netRemainingPreTaxCashflowUSDMM", '0.00'),
      new BaseExcelExportColumn("Net Remaining Capex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", "netRemainingCapexUSDMM", '0.00'),
      new BaseExcelExportColumn("Net Current Year Opex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", "netCurrentYearOpexUSDMM", '0.00'),
      new BaseExcelExportColumn("Net Current Year Opex/boe (" + valueTypes.UnitString(valueTypes.usdBOE) + ")", "netCurrentYearOpexPerBoe", '0.00'),
      new BaseExcelExportColumn("Net Remaining Opex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", "netRemainingOpexUSDMM", '0.00'),
      new BaseExcelExportColumn("Net Remaining Opex/boe (" + valueTypes.UnitString(valueTypes.usdBOE) + ")", "netRemainingOpexPerBoe", '0.00'),
      new BaseExcelExportColumn("Net Remaining Abex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", "netRemainingAbexUSDMM", '0.00'),
      new BaseExcelExportColumn("Number of Producing Fields", "producingFields"),
      new BaseExcelExportColumn("Net Remaining Reserves in Producing Fields (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "netRemainingReservesInProducingFieldsMMBOE", '0.00'),
      new BaseExcelExportColumn("Number of Under Development Fields", "underDevelopmentFields"),
      new BaseExcelExportColumn("Net Remaining Reserves in Under Development Fields (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "netRemainingReservesInUnderDevelopmentFieldsMMBOE", '0.00'),
      new BaseExcelExportColumn("Number of Near Term Developments", "nearTermFields"),
      new BaseExcelExportColumn("Net Remaining Reserves in Near Term Developments (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "netRemainingReservesInNearTermFieldsMMBOE", '0.00'),
      new BaseExcelExportColumn("Number of Other Fields", "otherFields"),
      new BaseExcelExportColumn("Percent Operated Fields", "percentOperatedFields"),
      new BaseExcelExportColumn("Number of Undeveloped Discoveries", "discoveries"),
      new BaseExcelExportColumn("Net Resources in Undeveloped Discoveries (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "discoveryResourcesMMBOE", '0.00'),
      new BaseExcelExportColumn("Number of Undeveloped Prospects", "prospects"),
      new BaseExcelExportColumn("Net Resources in Undeveloped Prospects (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", "prospectsResourcesMMBOE", '0.00'),
      new BaseExcelExportColumn("Gross Area (" + valueTypes.UnitString(valueTypes.kmsq) + ")", "grossAreaSQKM", '0.00'),
      new BaseExcelExportColumn("Net Area (" + valueTypes.UnitString(valueTypes.kmsq) + ")", "netAreaSQKM", '0.00')
    ];

    this.writeSheet(workbook, sheetName, dataRows, colDefs);
  }

  exportCashflowAnnuals(workbook, dataRows, sheetName) {
    const usdMM = valueTypes.UnitString(valueTypes.usdMM);

    const columns = [
      new BaseExcelExportColumn('Company', 'resourceName'),
      new BaseExcelExportColumn('Year', 'year'),
      new BaseExcelExportColumn('Liquid Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'liquidProductionMbod', '0.00'),
      new BaseExcelExportColumn('Gas Production (' + valueTypes.UnitString(valueTypes.mmscfd) + ')', 'gasProductionMmscfd', '0.00'),
      new BaseExcelExportColumn('Total Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'combinedMboed', '0.00'),
      new BaseExcelExportColumn('Revenue (' + usdMM + ')', 'totalRevenueUsdMM', '0.00'),
      new BaseExcelExportColumn('Opex (' + usdMM + ')', 'opexUsdMM', '0.00'),
      new BaseExcelExportColumn('Emissions charge (' + usdMM + ')', 'totalEmissionsChargeUsdMM', '0.00'),
      new BaseExcelExportColumn('Export tariff (' + usdMM + ')', 'totalTariffUsdMM', '0.00'),
      new BaseExcelExportColumn('Capex (' + usdMM + ')', 'costForLossesOrDepreciationUsdMM', '0.00'),
      new BaseExcelExportColumn('Abex (' + usdMM + ')', 'abexUsdMM', '0.00'),
      new BaseExcelExportColumn('Pre-tax cashflow (' + usdMM + ')', 'preTaxCashflowUsdMM', '0.00'),
    ];
        
    this.writeSheet(workbook, sheetName, dataRows, columns);
  }

  exportFields(workbook, dataRows, sheetName) {
    const usdMM = valueTypes.UnitString(valueTypes.usdMM);

    const columns = [
      new BaseExcelExportColumn('Field Name', 'resourceName'),
      new BaseExcelExportColumn('Country', 'country'),
      new BaseExcelExportColumn('Area', 'area'),
      new BaseExcelExportColumn('Hub', 'hub'),
      new BaseExcelExportColumn('Equity (' + valueTypes.UnitString(valueTypes.percent) + ')', 'equity', '0.00'),
      new BaseExcelExportColumn('Operator', 'operator'),
      new BaseExcelExportColumn('Development Status', 'status'),

      //new BaseExcelExportColumn('Blocks', 'blocks'),
      //new BaseExcelExportColumn('Licences', 'licences'),
      new BaseExcelExportColumn('Discovery Well Licence', 'discoveryWellLicence'),
      new BaseExcelExportColumn('Discovery Well', 'discoveryWell'),
      new BaseExcelExportColumn('Discovery Year', 'discoveryYear'),
      new BaseExcelExportColumn('Field Approval Year', 'fieldApprovalYear'),

      new BaseExcelExportColumn('First Production Year', 'productionStartYear'),
      new BaseExcelExportColumn('COP/Expected COP Year', 'expectedCOPYear'),

      new BaseExcelExportColumn('Decommissioned/Expected Decommissioned Year', 'expectedDecomissionYear'),
      new BaseExcelExportColumn('Liquid Export', 'liquidExport'),
      new BaseExcelExportColumn('Gas Export', 'gasExport'),
      new BaseExcelExportColumn('Development Type', 'developmentType'),

      new BaseExcelExportColumn('Net Remaining Liquid (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'netRemainingLiquidMmb', '0.00'),
      new BaseExcelExportColumn('Net Remaining Gas (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'netRemainingGasBCF', '0.00'),
      new BaseExcelExportColumn('Net Remaining Reserves (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'netRemainingReservesMmboe', '0.00'),
      new BaseExcelExportColumn('Net Current year Production Liquid (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'currentYearNetLiquidProductionMbod', '0.00'),
      new BaseExcelExportColumn('Net Current year Production Gas (' + valueTypes.UnitString(valueTypes.mmscfd) + ')', 'currentYearNetGasProductionMmscfd', '0.00'),
      new BaseExcelExportColumn('Net Current year Total Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'currentYearNetProductionMBOEPD', '0.00'),
      new BaseExcelExportColumn('Net Remaining CAPEX (' + usdMM + ')', 'netRemainingCapexUSDMM', '0.00'),
      new BaseExcelExportColumn('Net Remaining OPEX (' + usdMM + ')', 'netRemainingOpexUSDMM', '0.00'),
      new BaseExcelExportColumn('Net Remaining Tariff (' + usdMM + ')', 'netRemainingTariffUSDMM', '0.00'),
      new BaseExcelExportColumn('Net Remaining ABEX (' + usdMM + ')', 'netRemainingAbexUSDMM', '0.00'),
      new BaseExcelExportColumn('Net Remaining pre-tax Revenue (' + usdMM + ')', 'netRemainingPreTaxRevenueUSDMM', '0.00'),
      new BaseExcelExportColumn('Net Remaining pre-tax Cashflow (' + usdMM + ')', 'netRemainingPreTaxCashflowUSDMM', '0.00'),
      //new BaseExcelExportColumn('Net Remaining post-tax Undiscounted Cashflow (' + usdMM + ')', 'netRemainingPostTaxUndiscountedCashflowUSDMM', '0.00'),
      new BaseExcelExportColumn('Net Remaining discounted post-tax NPV (' + usdMM + ')', 'netRemainingNPVUSDMM', '0.00'),

      new BaseExcelExportColumn('Net Current year CAPEX (' + usdMM + ')', 'currentYearNetCapexUSDMM', '0.00'),
      new BaseExcelExportColumn('Net Current year OPEX (' + usdMM + ')', 'currentYearNetOpexUSDMM', '0.00'),
      new BaseExcelExportColumn('Net Current year Tariff (' + usdMM + ')', 'currentYearNetTariffUSDMM', '0.00'),
      new BaseExcelExportColumn('Net Current year ABEX (' + usdMM + ')', 'currentYearNetAbexUSDMM', '0.00'),
      new BaseExcelExportColumn('Net Current year Revenue (' + usdMM + ')', 'currentYearNetRevenueUSDMM', '0.00'),
      new BaseExcelExportColumn('Net Current year pre-tax Cashflow (' + usdMM + ')', 'currentYearNetPreTaxCashflowUSDMM', '0.00'),
      new BaseExcelExportColumn('Net Current year Undiscounted Cashflow (' + usdMM + ')', 'currentYearNetPostTaxUndiscountedCashflowUSDMM', '0.00'),

      new BaseExcelExportColumn('Reservoir Age', 'reservoirAge'),
      new BaseExcelExportColumn('Reservoir Depth (' + valueTypes.UnitString(valueTypes.feet) + ')', 'reservoirDepthFt', '0'),
      new BaseExcelExportColumn('Trap Type', 'trapType'),
      new BaseExcelExportColumn('Pressure Status', 'pressureStatus'),
      new BaseExcelExportColumn('Temperature Status', 'temperatureStatus'),
    ];

    this.writeSheet(workbook, sheetName, dataRows, columns);
  }

  exportDiscos(workbook, dataRows, sheetName) {
    const columns = [
      new BaseExcelExportColumn('Discovery Name', 'resourceName'),
      new BaseExcelExportColumn('Country', 'country'),
      new BaseExcelExportColumn('Area', 'area'),
      new BaseExcelExportColumn('Equity (' + valueTypes.UnitString(valueTypes.percent) + ')', 'equity', '0.00'),
      new BaseExcelExportColumn('Operator', 'operator'),
      new BaseExcelExportColumn('Licence', 'licence'),
      new BaseExcelExportColumn('Block', 'block'),
      new BaseExcelExportColumn('Discovery Well', 'discoveryWell'),
      new BaseExcelExportColumn('Discovery Year', 'discoveryYear'),
      new BaseExcelExportColumn('Discovery Classification', 'classification'),
      new BaseExcelExportColumn('Discovery Classification Rationale', 'classificationRationale'),
      new BaseExcelExportColumn('Net Liquid Resources (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'netLiquidMmb', '0.00'),
      new BaseExcelExportColumn('Net Gas Resources (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'netGasBcf', '0.00'),
      new BaseExcelExportColumn('Net Total Resources (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'netCombinedMmboe', '0.00'),
      new BaseExcelExportColumn('Gross Unrisked Liquid Resources (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'grossLiquidMmb', '0.00'),
      new BaseExcelExportColumn('Gross Unrisked Gas Resources (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'grossGasBcf', '0.00'),
      new BaseExcelExportColumn('Gross Unrisked Total Resources (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'grossCombinedMmboe', '0.00'),
      new BaseExcelExportColumn('Reservoir Age', 'reservoirAge'),
      new BaseExcelExportColumn('Water Depth (' + valueTypes.UnitString(valueTypes.feet) + ')', 'waterDepthFt', '0'),
      new BaseExcelExportColumn('Reservoir Depth (' + valueTypes.UnitString(valueTypes.feet) + ')', 'reservoirDepthFt', '0'),
      new BaseExcelExportColumn('Fluid Reservoir Condition', 'reservoirCondition'),
      new BaseExcelExportColumn('Trap Type', 'trapType'),
      new BaseExcelExportColumn('Pressure Status', 'pressureStatus'),
      new BaseExcelExportColumn('Temperature Status', 'temperatureStatus'),
    ];

    //this.writeSheet(workbook, sheetName, dataRows, columns, this._filteredMessage);
    this.writeSheet(workbook, sheetName, dataRows, columns);
  }

  exportProspects(workbook, dataRows, sheetName) {
    const columns = [
      new BaseExcelExportColumn('Prospect Identifier', 'prospectId'),
      new BaseExcelExportColumn('Prospect Name', 'prospectName'),
      new BaseExcelExportColumn('Country', 'country'),
      new BaseExcelExportColumn('Area', 'area'),
      new BaseExcelExportColumn('Equity (' + valueTypes.UnitString(valueTypes.percent) + ')', 'equity', '0.00'),
      new BaseExcelExportColumn('Operator', 'operator'),
      new BaseExcelExportColumn('Licence', 'licence'),
      new BaseExcelExportColumn('Block', 'block'),
      new BaseExcelExportColumn('Net Unrisked Liquid Resources (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'netLiquidMmb', '0.00'),
      new BaseExcelExportColumn('Net Unrisked Gas Resources (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'netGasBcf', '0.00'),
      new BaseExcelExportColumn('Net Unrisked Total Resources (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'netCombinedMmboe', '0.00'),
      new BaseExcelExportColumn('Gross Unrisked Liquid Resources (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'grossLiquidMmb', '0.00'),
      new BaseExcelExportColumn('Gross Unrisked Gas Resources (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'grossGasBcf', '0.00'),
      new BaseExcelExportColumn('Gross Unrisked Total Resources (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'grossCombinedMmboe', '0.00'),
      new BaseExcelExportColumn('Reservoir Age', 'reservoirAge'),
      new BaseExcelExportColumn('Water Depth (' + valueTypes.UnitString(valueTypes.feet) + ')', 'waterDepthFt', '0'),
      new BaseExcelExportColumn('Reservoir Depth (' + valueTypes.UnitString(valueTypes.feet) + ')', 'reservoirDepthFt', '0'),
      new BaseExcelExportColumn('Fluid Reservoir Condition', 'reservoirCondition'),
      new BaseExcelExportColumn('Trap Type', 'trapType'),
    ];

    this.writeSheet(workbook, sheetName, dataRows, columns);
  }

  exportCompletedActiveWells(workbook, dataRows, sheetName) {
    const columns = [
      new BaseExcelExportColumn('Well Name', 'well'),
      new BaseExcelExportColumn('Country', 'country'),
      new BaseExcelExportColumn('Area', 'area'),
      new BaseExcelExportColumn('Associated Resource', 'resourceList'),
      new BaseExcelExportColumn('Equity (' + valueTypes.UnitString(valueTypes.percent) + ')', 'equity', '0.00'),
      new BaseExcelExportColumn('Operator', 'operatorName'),
      new BaseExcelExportColumn('Current Licence', 'currentLicenceName'),
      new BaseExcelExportColumn('Current Licence Round', 'currentLicenceRound'),
      new BaseExcelExportColumn('Original Licence', 'originalLicenceName'),
      new BaseExcelExportColumn('Original Licence Round', 'originalLicenceRound'),
      new BaseExcelExportColumn('Licence Commitment', 'licenceCommitment'),
      new BaseExcelExportColumn('Well Type', 'typeName'),
      new BaseExcelExportColumn('Well Sub-Type', 'subTypeName'),
      new BaseExcelExportColumn('Well Status', 'statusName'),
      new BaseExcelExportColumn('Spud Date', 'startDate'),
      new BaseExcelExportColumn('Completion Date', 'endDate'),
      new BaseExcelExportColumn('Well Duration (' + valueTypes.UnitString(valueTypes.days) + ')', 'durationDays'),
      new BaseExcelExportColumn('Drilling Rig', 'drillingRig'),
      new BaseExcelExportColumn('Estimated Well Cost (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'wellCostUSDMM', '0.00'),
      new BaseExcelExportColumn('Primary Objective', 'primaryObjective'),
      new BaseExcelExportColumn('Total Depth (' + valueTypes.UnitString(valueTypes.feet) + ')', 'totalDepthFt', '0'),
      new BaseExcelExportColumn('Water Depth (' + valueTypes.UnitString(valueTypes.feet) + ')', 'waterDepthFt', '0'),
      new BaseExcelExportColumn('Well Result', 'resultName'),
    ];

    this.writeSheet(workbook, sheetName, dataRows, columns);
  }

  exportFutureWells(workbook, dataRows, sheetName) {
    const columns = [
      new BaseExcelExportColumn('Well Name', 'well'),
      new BaseExcelExportColumn('Country', 'country'),
      new BaseExcelExportColumn('Area', 'area'),
      new BaseExcelExportColumn('Associated Resource', 'resourceList'),
      new BaseExcelExportColumn('Equity (' + valueTypes.UnitString(valueTypes.percent) + ')', 'equity', '0.00'),
      new BaseExcelExportColumn('Operator', 'operatorName'),
      new BaseExcelExportColumn('Licence', 'licence'),
      new BaseExcelExportColumn('Licence Round', 'licenceRound'),
      new BaseExcelExportColumn('Licence Commitment', 'licenceCommitment'),
      new BaseExcelExportColumn('Well Type', 'typeName'),
      new BaseExcelExportColumn('Well Sub-Type', 'subTypeName'),
      new BaseExcelExportColumn('Well Status', 'statusName'),
      new BaseExcelExportColumn('Estimated Timing', 'estimatedTiming'),
      new BaseExcelExportColumn('Approved for Drilling', 'approvedForDrilling'),
      new BaseExcelExportColumn('Drilling Rig', 'drillingRig'),
      new BaseExcelExportColumn('Primary Objective', 'primaryObjective'),
      new BaseExcelExportColumn('Water Depth (' + valueTypes.UnitString(valueTypes.feet) + ')', 'waterDepthFt', '0'),
    ];

    this.writeSheet(workbook, sheetName, dataRows, columns);
  }
}

export { CorporateDetailExcelExport };
