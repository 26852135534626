import React from 'react';
import PropTypes from 'prop-types';

import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';
import { valueTypes } from '../../../shared/helpers/value-handler';

const FieldCashflowAssumptionsComponent = (props) => {
  const { liquidDiscountPremium, gasDiscountPremium, discountRate, discountYear, liquidToFuel, gasToFuel, valuationName } = props;

  const getRows = () => {
    return (
      <React.Fragment>
        <tr>
          <td>Discount rate ({valueTypes.UnitString(valueTypes.percent)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.percent, discountRate)}</td>
        </tr>
        <tr>
          <td>Discount date (year end)</td>
          <td>{discountYear}</td>
        </tr>
        <tr>
          <td>Oil price discount/premium ({valueTypes.UnitString(valueTypes.percent)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.percent, liquidDiscountPremium)}</td>
        </tr>
        <tr>
          <td>Gas price discount/premium ({valueTypes.UnitString(valueTypes.percent)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.percent, gasDiscountPremium)}</td>
        </tr>
        <tr>
          <td>Is oil production revenue generating</td>
          <td>{liquidToFuel === true ? 'No' : 'Yes'}</td>
        </tr>
        <tr>
          <td>Is gas production revenue generating</td>
          <td>{gasToFuel === true ? 'No' : 'Yes'}</td>
        </tr>
       
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <React.Fragment>
        The cashflow has been run on a standalone basis and does not account for corporate tax positions or synergies.
        <br />
        The cashflow has been run using <strong>{valuationName}</strong> assumptions. Oil and gas price assumptions are reflected within the cashflow table following any discount or premium against the selected price and valuation settings. Other assumptions include:
        <br /><br />
      </React.Fragment>      
      <BaseTableComponent className="one-col-key-value">
        <tbody>
          {getRows()}
        </tbody>
      </BaseTableComponent>     
      <React.Fragment>
        <br />
        Westwood does not use inflation in its model. Further details on the fiscal model and Westwood’s Base, Low and High case assumptions can be found in the <a target="_blank" href="https://atlas.westwoodenergy.com/assumptions" title="Go to Assumptions" rel="noopener noreferrer">Methodology and Assumptions</a> document (also found in the Help menu).
      </React.Fragment>
    </React.Fragment>
  );
}

FieldCashflowAssumptionsComponent.propTypes = {
  liquidDiscountPremium: PropTypes.number.isRequired,
  gasDiscountPremium: PropTypes.number.isRequired,
  discountRate: PropTypes.number.isRequired,
  discountYear: PropTypes.number.isRequired,
  liquidToFuel : PropTypes.bool.isRequired,
  gasToFuel: PropTypes.bool.isRequired,
  valuationName: PropTypes.string.isRequired,
};

export default FieldCashflowAssumptionsComponent;
