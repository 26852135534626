import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './app-component.scss';
import { appViews } from './app-views';
import { AppSetState } from './app-set-state';
import { LocalStorage } from './local-storage';
import { loadingImages } from './loading-images';
import { appIcons, appIconSizes } from './app-icons';
import ToolbarItemsComponent from './toolbar-items-component';
import { setupAppInsights, withAppInsights } from './app-insights';

import { HubViewSettings } from '../views/hub/hub-view-settings';
import { HomeViewSettings } from '../views/home/home-view-settings';
import { WellViewSettings } from '../views/well/well-view-settings';
import { FieldViewSettings } from '../views/field/field-view-settings';
import { BlockViewSettings } from '../views/block/block-view-settings';
import { ReportsViewSettings } from '../views/reports/reports-view-settings';
import { LicenceViewSettings } from '../views/licence/licence-view-settings';
import { ProspectViewSettings } from '../views/prospect/prospect-view-settings';
import { CorporateViewSettings } from '../views/corporate/corporate-view-settings';
import { DiscoveryViewSettings } from '../views/discovery/discovery-view-settings';

import { SettingsModel } from '../models/settings-model';
import { UserValuations } from '../models/user-valuations';
import { enumToObject, IN_DEV_MODE } from '../shared/helpers/common';
import DetailComponent, { DetailModel } from '../details/detail-component';
import LoadingComponent from '../shared/components/loading/loading-component';
import NotificationComponent from '../components/notifications/notification-component';
import MessageBoxComponent, { MessageBoxIcon, MessageBoxButton } from '../shared/components/message-box/message-box-component';
import MainComponent, { settingsStates } from '../shared/components/main/main-component';
import NonSubscribedComponent from '../components/subscription/non-subscribed-component';
import ValuationsManagementComponent from '../sharedComponents/valuations-management-component';
import { checkVersion } from './app-component-helper.js';

if (!IN_DEV_MODE) {
  setupAppInsights();
}

class AppComponent extends Component {
  displayName = AppComponent.name

  constructor(props) {
    super(props);
    this.lastVersionCheck = null;
    this.localStorage = new LocalStorage();
    this.appSetState = new AppSetState(
      this.logout,
      (callback = undefined) => this.forceUpdate(callback),
      this.setStateAppView,
      this.setStateToolbar,
      this.setStateShowLoading,
      this.setStateHideLoading,
      this.setStateMessage,
      this.setStateDetails,
      this.getViewSettings,
      this.localStorage,
      (appView, countryName) => this.showUnsubscribed(appView, countryName),
      () => this.state.appView,
      this.showVideo,
      this.handleUnauthorized
    );
    this.allViewSettings = enumToObject(appViews, (appView) => this.createViewSettings(appView));
    this.currLoadingImage = loadingImages.Logo;
    this.loadingCount = 0;
    this.loadingImage = this.currLoadingImage;
    this.loadingTimeout = null;

    this.state = {
      settings: null,
      appView: appViews.Home,
      leftToolbarItems: [],
      rightToolbarItems: [],
      message: null,
      detailModel: null,
      videoModel: null,
      htmlModel: null,
      loading: false,
    };

    if (IN_DEV_MODE) {
      this.state.allViewSettings = this.allViewSettings;
    }
  }

  componentDidMount() {
    const { originalUrl } = this.props;
    //if (originalUrl.pathname.length > 1) {
    //  this.appSetState.track.event('url', 'anything');
    //  this.appSetState.track.event('url', originalUrl.href);
    //}

    this.lastVersionCheck = new Date();
    let emailreportid = originalUrl.searchParams.get('emailreport');
    let mainreportid = originalUrl.searchParams.get('mainreport');
    if (typeof emailreportid !== 'string' || typeof mainreportid !== 'string') {
      emailreportid = '';
      mainreportid = '';
    }

    const onSuccess = (result) => {
      const settings = new SettingsModel(result.settings);
      const appId = settings.appId;
      const emailAddress = settings.emailAddress;
      const emailReportID = settings.emailReportID;
      const mainReportID = settings.mainReportID;

      this.setState({ settings: settings }, () => {
        this.validateVersion(result.version);
        window.intercomHelper.boot(appId, emailAddress, { app_view: this.state.appView });
        window.intercomHelper.autoUpdate(10000); // Update every 10 seconds

        // INITIALLY SET THE 'SESSION' VALUATION, WHICH ON LOAD IS THE USERS DEFAULT VALUATION UNLESS ITS NOT VALID, IN WHICH CASE ITS THE WGE BASE VALUATION
        this.appSetState.appValuation.valuation = new UserValuations(result.userDefaultValuation);
        this.appSetState.appValuation.baseValuation = new UserValuations(result.wgegBaseValuation);
        this.appSetState.appValuation.advancedPlus = result.advancedPlus;

        if (emailReportID !== 0) {
          this.appSetState.view.details.showReport(emailReportID, mainReportID);

        } else if (originalUrl.pathname.length > 1) {
          this.appSetState.view.details.showFromQueryString(originalUrl.pathname, originalUrl.searchParams);
        }
      });
    }
    this.appSetState.api.get('api/app/settings?email=' + emailreportid + '&main=' + mainreportid, onSuccess);

  }

  componentDidUpdate(prevProps) {

    //if (this.props.originalUrl.pathname.length > 1) {
    //  this.appSetState.track.event('url', 'anything');
    //  this.appSetState.track.event('url', this.props.originalUrl.href);
    //}


    const minutesSinceLastVersionCheck = this.getMinutesSinceLastVersionCheck();
    if (minutesSinceLastVersionCheck >= 2) {
      this.lastVersionCheck = new Date();
      this.appSetState.api.getAsync('api/app/version', this.validateVersion);
    }
  }

  createViewSettings(appView) {
    if (appViews.isInvalid(appView)) {
      throw new Error('Invalid "appView" param supplied to "AppComponent.createViewSettings"');
    }

    switch (appView) {
      case appViews.Home: return new HomeViewSettings(appView, this.appSetState);
      case appViews.Reports: return new ReportsViewSettings(appView, this.appSetState);
      case appViews.Field: return new FieldViewSettings(appView, this.appSetState);
      case appViews.Hub: return new HubViewSettings(appView, this.appSetState);
      case appViews.Corporate: return new CorporateViewSettings(appView, this.appSetState);
      case appViews.Discovery: return new DiscoveryViewSettings(appView, this.appSetState);
      case appViews.Block: return new BlockViewSettings(appView, this.appSetState);
      case appViews.Well: return new WellViewSettings(appView, this.appSetState);
      case appViews.Prospect: return new ProspectViewSettings(appView, this.appSetState);
      case appViews.Licence: return new LicenceViewSettings(appView, this.appSetState);
      default: throw new Error('Invalid "appView" param supplied to "AppComponent.createViewSettings"');
    }
  }

  handleUnauthorized = (rootUrl) => {
    if (typeof rootUrl !== 'string' || rootUrl.length === 0) {
      throw new Error('Invalid "rootUrl" param supplied to "AppComponent.handleUnauthorized"');
    }

    const { originalUrl } = this.props;
    if (originalUrl.search == "") originalUrl.search = "?nothing=0"; //without a query string paramater (e.g. https://localhost:44358/assumptions) route doesnt work in atlaslogin?
    rootUrl += originalUrl.pathname + originalUrl.search;
    window.location.href = rootUrl + '/logout';
  }

  getMinutesSinceLastVersionCheck() {
    if (this.lastVersionCheck === null) {
      return 0;
    }

    const now = new Date();
    const diffMs = (now - this.lastVersionCheck); // milliseconds
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffMins;
  }

  validateVersion = (version) => {
    const onFail = () => {
      this.appSetState.messageBox.showError('A newer version of this application is available. Please click the reload button.',
        () => window.location.reload(true), ['Reload'], 'Version...');
    }
    checkVersion(version, this.props.version, onFail);
  }

  logout = () => {
    this.appSetState.messageBox.showYesNoPrompt('Are you sure you want to logout?',
      () => window.location.href = this.state.settings.rootUrl + '/logout');
  }

  changePassword = () => {
    window.location.href = this.state.settings.rootUrl + '/ChangePassword';
  }

  changeNotifications = () => {
    const header = (
      <React.Fragment>
        {appIcons.getIcon(appIcons.Email, appIconSizes.sm)}
        <span style={{ marginLeft: '10px' }}>Email Notification Preferences Details</span>
      </React.Fragment>
    );
    this.appSetState.messageBox.showMessage(<NotificationComponent api={this.appSetState.api} />, header);
  }

  manageValuationSettings = () => {
    const header = this.appSetState.appValuation.advancedPlus ? (
      <React.Fragment>
        {appIcons.getIcon(appIcons.Valuations, appIconSizes.sm)}
        <span style={{ marginLeft: '10px' }}>Valuation Settings</span>
      </React.Fragment>
    ) : null;
    this.appSetState.messageBox.showPanel(<ValuationsManagementComponent api={this.appSetState.api} appSetState={this.appSetState} isDetail={false} isMainMenu={true} showSelectAndExit={false} />, header);
  }

  getViewSettings = (appView) => {
    if (appViews.isInvalid(appView)) {
      throw new Error('Invalid "appView" param supplied to "AppComponent.getViewSettings"');
    }

    const result = this.allViewSettings[appView];
    return result;
  }

  getCurrentViewSettings = () => {
    const result = this.getViewSettings(this.state.appView);
    return result;
  }

  setStateShowLoading = (image, delay, callback) => {
    if (image !== null && !loadingImages.isValid(image)) {
      throw new Error('Invalid "image" param supplied to "AppComponent.showLoading"');
    }
    if (!Number.isInteger(delay) || delay < 0) {
      throw new Error('Invalid "delay" param supplied to "AppComponent.showLoading"');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "AppComponent.showLoading"');
    }

    if (image !== null) {
      this.currLoadingImage = image;
    }
    this.loadingCount++;
    this.loadingImage = this.currLoadingImage;

    const doShowLoading = () => {
      clearTimeout(this.loadingTimeout);
      this.loadingTimeout = null;

      this.setState(prevState => {
        const newState = { loading: this.loadingCount > 0 };
        return prevState.loading === newState.loading
          ? null // Don't update
          : newState;
      }, callback);
    };

    if (this.loadingImage !== loadingImages.Loading) {
      doShowLoading();
    } else if (this.loadingTimeout === null) {
      this.loadingTimeout = setTimeout(doShowLoading, delay);
    }
  }

  setStateHideLoading = (callback = undefined) => {
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "AppComponent.hideLoading"');
    }

    this.loadingCount = Math.max(this.loadingCount - 1, 0)
    this.loadingImage = this.currLoadingImage;
    if (this.loadingCount === 0) {
      this.currLoadingImage = loadingImages.Loading;
    }

    this.setState(prevState => {
      const newState = { loading: this.loadingCount > 0 };
      return prevState.loading === newState.loading
        ? null // Don't update
        : newState;
    }, callback);
  }

  setStateMessage = (message, callback = undefined) => {
    if (message !== null && message === undefined) {
      throw new Error('Invalid "message" param supplied to "AppComponent.setStateMessage"');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "AppComponent.setStateMessage"');
    }

    this.setState(prevState => {
      const result = prevState.message === message
        ? null
        : { message: message };
      return result;
    }, callback);
  }

  setStateDetails = (detailModel, callback = undefined) => {
    if (!(detailModel.__proto__ instanceof DetailModel)) {
      throw new Error('Invalid "detailModel" param supplied to "AppComponent.setStateDetails"');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "AppComponent.setStateDetails"');
    }

    this.appSetState.track.event('Details View', detailModel)
    this.setState(prevState => {
      const result = prevState.detailModel === detailModel
        ? null
        : { detailModel: detailModel };
      return result;
    }, callback);
  }

  setStateClearDetails = () => {
    this.setState(prevState => {
      const result = prevState.detailModel === null
        ? null
        : { detailModel: null };
      return result;
    });
  }

  setStateAppView = (value, force = false, callback = undefined) => {
    if (appViews.isInvalid(value)) {
      throw new Error('Invalid "value" param supplied to "AppComponent.setStateView"');
    }
    if (typeof force !== 'boolean') {
      throw new Error('Invalid "force" param supplied to "AppComponent.setStateView"');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "AppComponent.setStateView"');
    }

    this.appSetState.track.event('Change View', value)

    const onSuccess = (result) => {
      if (result !== true) {
        this.showUnsubscribed(value, null);
        return;
      }

      this.setState(prevState => {
        const result = prevState.appView === value && force === false
          ? null
          : { appView: value, leftToolbarItems: [], rightToolbarItems: [] };
        if (result !== null && window.intercomSettings.app_view !== value) {
          window.intercomSettings.atlas_page = appViews.getDisplayName(value);
        }
        return result;
      }, callback);
    };
    this.appSetState.api.get('api/app/isSubscribed?productLine=' + appViews.getName(value), onSuccess);
  }

  showUnsubscribed = (appView, countryName) => {
    if (countryName !== null && typeof countryName !== 'string') {
      throw new Error('Invalid "countryName" param supplied to "AppComponent.showUnsubscribed"');
    }

    appView = appViews.isValid(appView) ? appView : this.state.appView

    const newState = {
      message: {
        body: <NonSubscribedComponent appView={appView} countryName={countryName} />,
        callback: () => { },
        buttons: [MessageBoxButton.Close],
        header: 'Not Subscribed',
        icon: MessageBoxIcon.Error,
        size: 'lg'
      }
    };

    this.setState(newState, () => this.setState({ detailModel: null }));
  }

  showVideo = (videoModel, callback = undefined) => {
    if (!(videoModel.__proto__ instanceof DetailModel)) {
      throw new Error('Invalid "videoModel" param supplied to "AppComponent.showVideo"');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "AppComponent.showVideo"');
    }

    this.setState(prevState => {
      const result = prevState.videoModel === videoModel
        ? null
        : { videoModel: videoModel };
      return result;
    }, callback);
  }

  closeVideo = (callback) => {
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "AppComponent.closeVideo"');
    }

    this.setState(prevState => {
      const result = prevState.videoModel === null
        ? null
        : { videoModel: null };
      return result;
    }, callback);
  }

  setStateToolbar = (leftToolbarItems, rightToolbarItems, callback = undefined) => {
    if (!Array.isArray(leftToolbarItems)) {
      throw new Error('Invalid "leftToolbarItems" param supplied to "AppComponent.setStateToolbar"');
    }
    if (!Array.isArray(rightToolbarItems)) {
      throw new Error('Invalid "rightToolbarItems" param supplied to "AppComponent.setStateToolbar"');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "AppComponent.setStateToolbar"');
    }

    this.setState(prevState => {
      const result = prevState.leftToolbarItems === leftToolbarItems && prevState.rightToolbarItems === rightToolbarItems
        ? null
        : { leftToolbarItems: leftToolbarItems, rightToolbarItems: rightToolbarItems };
      return result;
    }, callback);
  }

  setStateToggleLabels = () => {
    this.appSetState.misc.showLabels = !this.appSetState.misc.showLabels;
  };

  setStateToggleShowSettingsOnHover = () => {
    this.appSetState.misc.showSettingsOnHover = !this.appSetState.misc.showSettingsOnHover;
  };

  onSettingsStateChanged = (value) => {
    const viewSettings = this.getCurrentViewSettings();
    viewSettings.showFilters = value === settingsStates.Pinned || value === settingsStates.PinnedOverlay;
    viewSettings.showSettings = value === settingsStates.Pinned || value === settingsStates.PinnedOverlay;
  }

  getDetailsComponent = () => {
    const { detailModel } = this.state;

    if (detailModel === null) {
      return null;
    }

    return (
      <DetailComponent
        appSetState={this.appSetState}
        detailModel={detailModel}
        close={this.setStateClearDetails}
        geoserverUrl={this.state.settings.geoserverUrl}
      />
    );
  }

  getVideoComponent = () => {
    const { videoModel } = this.state;

    if (videoModel === null) {
      return null;
    }

    return (
      <DetailComponent
        appSetState={this.appSetState}
        detailModel={videoModel}
        close={this.closeVideo}
        geoserverUrl={this.state.settings.geoserverUrl}
      />
    );
  }

  render() {
    const { appView, settings } = this.state;
    if (!(settings instanceof SettingsModel)) {
      return null;
    }

    const analyticlink = this.state.settings.analytics + this.state.settings.defaultDashboard;
    const analyticsize = this.appSetState.misc.showLabels ? "large" : "small";
    const analyticimage = this.appSetState.misc.showLabels ? "/images/Analytics-neg.svg" : "/images/analytics-small.png";
    const analyticshow = this.state.settings.analyticSubscribed ? "" : "hide_analytics";

    const { showLabels, showSettingsOnHover } = this.localStorage;
    const messageBoxComponent = <MessageBoxComponent message={this.state.message} clear={this.appSetState.messageBox.hide} />;
    const loadingComponent = <LoadingComponent visible={this.state.loading} image={this.loadingImage} />;
    const detailsComponent = this.getDetailsComponent();
    const videoComponent = this.getVideoComponent();
    //const htmlComponent = this.getHtmlComponent();
    const viewSettings = this.getViewSettings(appView);
    const viewComponent = viewSettings.getViewComponent(settings);

    const viewModels = appViews.toViewModels(showLabels);
    const viewSettingsComponent = viewSettings.getViewSettingsComponent(settings);
    const toolbarItems = (
      <ToolbarItemsComponent
        logout={this.logout}
        changePassword={this.changePassword}
        changeNotifications={this.changeNotifications}
        showLabels={showLabels}
        toggleShowLabels={this.setStateToggleLabels}
        showSettingsOnHover={showSettingsOnHover}
        toggleShowSettingsOnHover={this.setStateToggleShowSettingsOnHover}
        leftToolbarItems={this.state.leftToolbarItems}
        rightToolbarItems={this.state.rightToolbarItems}
        appSetState={this.appSetState}
        manageValuations={this.manageValuationSettings}
      />);

    return (
      <React.Fragment>
        {messageBoxComponent}
        {loadingComponent}
        {detailsComponent}
        {videoComponent}
        <MainComponent
          className={this.state.settings.analyticSubscribed ? 'has-analytics' : ''}
          settingsState={viewSettings.getSettingsState()}
          onSettingsStateChanged={this.onSettingsStateChanged}
          showLabels={showLabels}
          showSettingsOnHover={showSettingsOnHover}
          viewModels={viewModels}
          selectedView={appView}
          setSelectedView={this.setStateAppView}
          toolbarItems={toolbarItems}
          viewSettingsComponent={viewSettingsComponent}
          viewComponent={viewComponent}
        />
        <a className={"analytic-link " + analyticsize + " " + analyticshow} target="_blank" rel="noopener noreferrer" href={analyticlink} title="Go to Analytics">
          <img src={analyticimage} alt="" />
        </a>
        <div className="main-footer-div">
          <a href="https://www.westwoodenergy.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
          <span>|</span>
          <a href="https://www.westwoodenergy.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          <span>|</span>
          <a href="https://www.westwoodenergy.com/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
          <span>|</span>
          <a href="https://www.westwoodenergy.com/contact" target="_blank" rel="noopener noreferrer">Contact Us</a>
          <span>|</span>
          <a href="https://atlas.westwoodenergy.com/help/api-definitions.html" target="_blank" rel="noopener noreferrer">API Definitions</a>
          <span>|</span>
          <a href="https://www.westwoodenergy.com" target="_blank" rel="noopener noreferrer">&#9400; {new Date().getFullYear()} Westwood Global Energy Group</a>
        </div>
      </React.Fragment>
    );
  }
}

AppComponent.propTypes = {
  version: PropTypes.exact({
    major: PropTypes.number.isRequired,
    minor: PropTypes.number.isRequired,
    build: PropTypes.number.isRequired
  }).isRequired,
  originalUrl: PropTypes.instanceOf(URL).isRequired
};

export default withAppInsights(AppComponent);
