import React from 'react';

import { MessageBoxButton, MessageBoxButtons, MessageBoxIcon, ApiErrorComponent } from '../message-box/message-box-classes';
import { IN_DEV_MODE } from '../../helpers/common';
import { callApi as helperCallApi, callApiAsync as helperCallApiAsync, callApiDefaultOnFail as helperCallApiDefaultOnFail, responseIsJson as helperResponseIsJson } from '../../helpers/api-helpers';

const validateFuncParam = (param, paramName, className) => {
  if (typeof param !== 'function') {
    throw new Error('Invalid \'' + paramName + '\' param supplied to \'' + className + '.ctor\'');
  }
};

class LoadingSetState {
  constructor(loadingImages, showLoading, hideLoading, defaultLoadingDelay = 750) {
    validateFuncParam(showLoading, 'showLoading', 'LoadingSetState');
    validateFuncParam(hideLoading, 'hideLoading', 'LoadingSetState');
    if (typeof defaultLoadingDelay !== 'number') {
      throw new Error('Invalid \'' + defaultLoadingDelay + '\' param supplied to \'LoadingSetState.ctor\'');
    }

    this.show = (image = null, loadingDelay = defaultLoadingDelay, callback = undefined) => showLoading(image, loadingDelay, callback);
    this.hide = (callback = undefined) => hideLoading(callback);
    this.showNoDelay = (image = null, callback = undefined) => this.show(image, 0, callback);
    this.showLoading = (loadingDelay = defaultLoadingDelay, callback) => this.show(loadingImages.Loading, loadingDelay, callback);
    this.showLogo = (callback = undefined) => this.show(loadingImages.Logo, 0, callback);
    this.showSave = (callback = undefined) => this.show(loadingImages.Save, 0, callback);
  }
}

const DEFAULT_MESSAGEBOX_SIZE = 'lg';

class MessageBoxSetState {
  constructor(logout, setMessage) {
    validateFuncParam(logout, 'logout', 'MessageBoxSetState');
    validateFuncParam(setMessage, 'setMessage', 'MessageBoxSetState');

    this._logout = logout;
    this.show = (body, callback = () => { }, buttons = [MessageBoxButton.OK], header = null, icon = MessageBoxIcon.None, size = DEFAULT_MESSAGEBOX_SIZE, showFooter) => setMessage({ body: body, callback: callback, buttons: buttons, header: header, icon: icon, size: size, showFooter: showFooter });
    this.hide = (callback = undefined) => setMessage(null, callback);
  }

  showError(body, callback, buttons, header, size = DEFAULT_MESSAGEBOX_SIZE) {
    this.show(body, callback, buttons, header, MessageBoxIcon.Error, size);
  }

  showQuestion(body, callback, buttons, header, size = DEFAULT_MESSAGEBOX_SIZE) {
    this.show(body, callback, buttons, header, MessageBoxIcon.Question, size);
  }

  showWarning(body, callback, buttons, header, size = DEFAULT_MESSAGEBOX_SIZE) {
    this.show(body, callback, buttons, header, MessageBoxIcon.Warning, size);
  }

  showInformation(body, callback, buttons, header, size = DEFAULT_MESSAGEBOX_SIZE) {
    this.show(body, callback, buttons, header, MessageBoxIcon.Information, size);
  }

  showMessage(body, header = null, icon = MessageBoxIcon.None, size = DEFAULT_MESSAGEBOX_SIZE) {
    this.show(body, () => { }, [MessageBoxButton.OK], header, icon, size);
  }

  showOKCancelPrompt(body, okCallback = () => { }, header = null, icon = MessageBoxIcon.Question, size = DEFAULT_MESSAGEBOX_SIZE) {
    const callback = (button) => { if (button === MessageBoxButton.OK) { okCallback(); } };
    this.show(body, callback, MessageBoxButtons.OKCancel, header, icon, size);
  }

  showOKPrompt(body, okCallback = () => { }, header = null, icon = MessageBoxIcon.Warning, size = DEFAULT_MESSAGEBOX_SIZE) {
    const callback = (button) => { if (button === MessageBoxButton.OK) { okCallback(); } };
    this.show(body, callback, MessageBoxButtons.OK, header, icon, size);
  }

  showYesNoPrompt(body, yesCallback = () => { }, header = null, icon = MessageBoxIcon.Question, size = DEFAULT_MESSAGEBOX_SIZE) {
    const callback = (button) => { if (button === MessageBoxButton.Yes) { yesCallback(); } };
    this.show(body, callback, MessageBoxButtons.YesNo, header, icon, size);
  }

  showPanel(body, header, size = DEFAULT_MESSAGEBOX_SIZE) {
    this.show(body, () => { }, [MessageBoxButton.OK], header, MessageBoxIcon.None, size, false);
  }

  showApiError(httpStatusCode, errorDetails) {
    if (typeof httpStatusCode !== 'number') {
      throw new Error('Invalid httpStatusCode param supplied to SearchGlobal.showApiError');
    }

    let errorRef = '';
    let errorMessage = '';

    if (errorDetails !== undefined && errorDetails !== null) {
      if (typeof errorDetails.errorRef === 'string') {
        errorRef = errorDetails.errorRef;
      } else if (typeof errorDetails.ErrorRef === 'string') {
        errorRef = errorDetails.ErrorRef;
      }

      if (typeof errorDetails.errorMessage === 'string') {
        errorMessage = errorDetails.errorMessage;
      } else if (typeof errorDetails.ErrorMessage === 'string') {
        errorMessage = errorDetails.ErrorMessage;
      }
    }

    const body = <ApiErrorComponent errorRef={errorRef} errorMessage={errorMessage} />;
    switch (httpStatusCode) {
      case 400: // BadRequest
        return this.showWarning(body);
      case 401: // Unauthorized
        return this.showError(<ApiErrorComponent />, () => this._logout(true));
      case 404: // Not Found
        return this.showError('Record Not Found.');
      default:
        return this.showError(body, IN_DEV_MODE ? undefined : () => window.location.reload(true));
    }
  };
}

class MiscSetState {
  constructor(logout, setToolbar) {
    validateFuncParam(logout, 'logout', 'MiscSetState');
    validateFuncParam(setToolbar, 'setToolbar', 'MiscSetState');

    this.logout = logout;
    this.toolbar = {
      set: (leftToolbarItems, rightToolbarItems = [], callback = undefined) => setToolbar(leftToolbarItems, rightToolbarItems, callback),
      clear: (callback = undefined) => setToolbar([], [], callback)
    };
  }
}

class ViewSetState {
  constructor(forceUpdate, setAppView) {
    validateFuncParam(forceUpdate, 'forceUpdate', 'ViewSetState');
    validateFuncParam(setAppView, 'setAppView', 'ViewSetState');

    this.forceUpdate = forceUpdate;
    this.show = setAppView;
  }
}

class Api {
  constructor(messageBoxSetState, loadingSetState) {
    if (!(messageBoxSetState instanceof MessageBoxSetState)) {
      throw new Error('Invalid \'messageBoxSetState\' param supplied to \'Api.ctor\'');
    }
    if (!(loadingSetState instanceof LoadingSetState)) {
      throw new Error('Invalid \'loadingSetState\' param supplied to \'Api.ctor\'');
    }

    this.messageBox = messageBoxSetState;
    this.loading = loadingSetState;
  }

  getShowLoading(noDelay) {
    return noDelay === true ? this.loading.showNoDelay : this.loading.show;
  }

  get(apiUrl, onSuccess, onFail, noDelay = false, showLoading = true) {
    const requestInit = { method: 'get', headers: { 'Content-Type': 'application/json', 'cache': 'no-store' } };
    this.callApi(apiUrl, requestInit, onSuccess, onFail, noDelay, showLoading);
  }

  async getAsync(apiUrl, onSuccess, onFail, noDelay = false, showLoading = true) {
    const requestInit = { method: 'get', headers: { 'Content-Type': 'application/json', 'cache': 'no-store' } };
    await this.callApi(apiUrl, requestInit, await onSuccess, await onFail, noDelay, showLoading);
  }

  post(apiUrl, headerBody, onSuccess, onFail, noDelay = false, showLoading = true) {
    if (headerBody !== undefined) {
      if (typeof headerBody === 'object') {
        headerBody = JSON.stringify(headerBody);
      } else if (typeof headerBody !== 'string') {
        throw new Error('Invalid \'headerBody\' param supplied to \'Api.post\'');
      }
    }

    const requestInit = { method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: headerBody };
    this.callApi(apiUrl, requestInit, onSuccess, onFail, noDelay, showLoading);
  }

  async postAsync(apiUrl, headerBody, onSuccess, onFail, noDelay = false, showLoading = true) {
    if (headerBody !== undefined) {
      if (typeof headerBody === 'object') {
        headerBody = JSON.stringify(headerBody);
      } else if (typeof headerBody !== 'string') {
        throw new Error('Invalid \'headerBody\' param supplied to \'Api.post\'');
      }
    }

    const requestInit = { method: 'POST', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: headerBody };
    await this.callApiAsync(apiUrl, requestInit, await onSuccess, await onFail, noDelay, showLoading);
  }

  callApi(apiUrl, requestInit, onSuccess, onFail, noDelay, showLoading = true) {
    if (typeof noDelay !== 'boolean') {
      throw new Error('Invalid \'noDelay\' param supplied to \'Api.callApi\'');
    }
    if (typeof showLoading !== 'boolean') {
      throw new Error('Invalid \'showLoading\' param supplied to \'Api.callApi\'');
    }

    const showLoadingFunc = showLoading === true ? this.getShowLoading(noDelay) : null;
    const hideLoadingFunc = showLoading === true ? this.loading.hide : null;
    helperCallApi(apiUrl, requestInit, showLoadingFunc, hideLoadingFunc, this.messageBox, onSuccess, onFail);
  }

  async callApiAsync(apiUrl, requestInit, onSuccess, onFail, noDelay, showLoading = true) {
    if (typeof noDelay !== 'boolean') {
      throw new Error('Invalid \'noDelay\' param supplied to \'Api.callApi\'');
    }
    if (typeof showLoading !== 'boolean') {
      throw new Error('Invalid \'showLoading\' param supplied to \'Api.callApi\'');
    }

    const showLoadingFunc = showLoading === true ? this.getShowLoading(noDelay) : null;
    const hideLoadingFunc = showLoading === true ? this.loading.hide : null;
    await helperCallApiAsync(apiUrl, requestInit, showLoadingFunc, hideLoadingFunc, this.messageBox, await onSuccess, onFail);
  }

  callApiDefaultOnFail(e) {
    helperCallApiDefaultOnFail(e, this.messageBox);
  }

  responseIsJson(response) {
    return helperResponseIsJson(response);
  }
}

export { validateFuncParam, LoadingSetState, MessageBoxSetState, MiscSetState, ViewSetState, Api }