import React from 'react';
import PropTypes from 'prop-types';

import { appViews } from '../../../app/app-views';
import { KeyValuePair } from '../../../models/key-value-pair';
import StandardWarningComponent from '../../standard-warning-component';
import MapComponent from '../../../components/fiscal/map/map-component';
import { TimelineChart } from '../../../shared/components/timeline-chart/timeline-chart';
import { FieldDetail } from '../../../components/fiscal/models/portfolio/faster-portfolio';
import LocationComponent from '../../../components/fiscal/fact-components/location-component';
import OwnersPieChartComponent from '../../../shared/components/owners-pie-chart/owners-pie-chart-component';
import { ContextLayerModel, searchResultType, latLngBounds } from '../../../shared/helpers/leaflet/leaflet-helper';
import DevelopmentDetailsComponent from '../../../components/fiscal/fact-components/development-details-component';

import DetailViewGridLayoutComponent, { createSection100, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const StandardComponent = (props) => {
  const { resource } = props;

  const leftPanel = (
    <React.Fragment>
      {createSection100(<LocationComponent resource={resource} blocks={props.blocks} licences={props.licences} onHubClick={props.onHubClick} onWellClick={props.onWellClick} onLicenceClick={props.onLicenceClick} onBlockClick={props.onBlockClick} />, 'Location')}
      {createSection100(<DevelopmentDetailsComponent resource={resource} />, 'Development details')}
      {createSection100(<div style={{ height: '140px' }}><TimelineChart events={resource.events} /></div>, 'Timeline', 'timeline-div')}
      {createSection100(<div style={{ height: '200px' }}><OwnersPieChartComponent owners={resource.owners} /></div>, 'Participation', 'participation-div')}
    </React.Fragment>
  );
  const rightPanel = <div style={{ height: '100%' }}><MapComponent geoserverUrl={props.geoserverUrl} contextLayers={props.contextLayers} bounds={latLngBounds(resource.fiscalResource.shapes.latLngs)} zoom={searchResultType.defaultZoomLevel(searchResultType.Field)} shapes={[resource.fiscalResource.shapes]} /></div>;

  return (
    <DetailViewGridLayoutComponent>
      {createSection100(<StandardWarningComponent appView={props.appView} />)}
      {createSection([
        { content: leftPanel, sectionType: sectionTypes.section50, noMarginVertical: true },
        { content: rightPanel, sectionType: sectionTypes.section50 }
      ])}
    </DetailViewGridLayoutComponent>
  );
};

StandardComponent.propTypes = {
  appView: appViews.propType,
  resource: PropTypes.instanceOf(FieldDetail).isRequired,
  blocks: PropTypes.arrayOf(PropTypes.instanceOf(KeyValuePair).isRequired).isRequired,
  licences: PropTypes.arrayOf(PropTypes.instanceOf(KeyValuePair).isRequired).isRequired,
  onHubClick: PropTypes.func,
  onWellClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  geoserverUrl: PropTypes.string.isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired
};

export default StandardComponent;