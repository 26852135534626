class SettingsModel {
  constructor(obj) {
    if (typeof obj.appId !== 'string') {
      throw new Error('Invalid "appId" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.customAuthCookieName !== 'string') {
      throw new Error('Invalid "customAuthCookieName" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.rootUrl !== 'string') {
      throw new Error('Invalid "rootUrl" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.geoserverUrl !== 'string') {
      throw new Error('Invalid "geoserverUrl" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.reportDir !== 'string') {
      throw new Error('Invalid "reportDir" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.specialsDir !== 'string') {
      throw new Error('Invalid "specialsDir" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.analytics !== 'string') {
      throw new Error('Invalid "analytics" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.defaultDashboard !== 'string') {
      throw new Error('Invalid "defaultDashboard" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.emailReportID !== 'number') {
      throw new Error('Invalid "emailReportID" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.mainReportID !== 'number') {
      throw new Error('Invalid "mainReportID" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.currentYear !== 'number') {
      throw new Error('Invalid "currentYear" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.emailAddress !== 'string') {
      throw new Error('Invalid "emailAddress" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.analyticSubscribed !== 'boolean') {
      throw new Error('Invalid "analyticSubscribed" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.proximityKM !== 'number') {
      throw new Error('Invalid "proximityKM" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.proximityKMLower !== 'number') {
      throw new Error('Invalid "proximityKMLower" supplied to "SettingsModel.ctor"');
    }
    if (typeof obj.proximityKMUpper !== 'number') {
      throw new Error('Invalid "proximityKMUpper" supplied to "SettingsModel.ctor"');
    }

    this.appId = obj.appId;
    this.customAuthCookieName = obj.customAuthCookieName;
    this.rootUrl = obj.rootUrl;
    this.geoserverUrl = obj.geoserverUrl;
    this.reportDir = obj.reportDir;
    this.specialsDir = obj.specialsDir;
    this.analytics = obj.analytics;
    this.defaultDashboard = obj.defaultDashboard;
    this.emailReportID = obj.emailReportID;
    this.mainReportID = obj.mainReportID;
    this.currentYear = obj.currentYear;
    this.emailAddress = obj.emailAddress;
    this.analyticSubscribed = obj.analyticSubscribed;
    this.proximityKM = obj.proximityKM;
    this.proximityKMLower = obj.proximityKMLower;
    this.proximityKMUpper = obj.proximityKMUpper;
  }
}

export { SettingsModel };
