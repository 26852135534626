import React from 'react';
import PropTypes from 'prop-types';

import CorporateDetailGridComponent, { createLink, createLinkAndIcons, HEADER_ROW_DOUBLE, NUMERIC_SORT } from '../corporate-detail-grid-component';

import { CompletedAndActiveWell } from '../../../../models/corporate-detail';
import { appIcons } from '../../../../app/app-icons';
import { valueTypes } from '../../../../shared/helpers/value-handler';

const CompletedAndActiveWellsTableComponent = (props) => {
  const { onWellClick, onReportClick, onLicenceClick, onOperatorClick, onProspectClick, onDiscoveryClick, onFieldClick } = props;

  const wellRenderer = (params) => {
    let result = params.data.well;
    if (typeof onWellClick === 'function' && typeof params.data.wellId === 'number') {
      const icons = [];
      if (typeof onReportClick === 'function' && typeof params.data.reportId === 'number' && params.data.reportId > 0) {
        icons.push({ appIcon: appIcons.FilePdf, title: 'Download Report', onClick: () => onReportClick(params.data.reportId) });
      }
      result = createLinkAndIcons(result, 'Show Details...', () => onWellClick(params.data.wellId), icons);
    }
    return result;
  };
  const operatorNameRenderer = (params) => {
    let result = params.data.operatorName;
    // params.data.operatorId === params.currentCorporateId means we're currently showing that company, so no point having a link
    if (typeof onOperatorClick === 'function' && typeof params.data.operatorId === 'number' && params.data.operatorId !== params.currentCorporateId) {
      result = createLink(result, 'Show Details...', () => onOperatorClick(params.data.operatorId));
    }
    return result;
  };
  const originalLicenceNameRenderer = (params) => {
    let result = params.data.originalLicenceName;
    if (typeof onLicenceClick === 'function' && typeof params.data.originalLicenceId === 'number') {
      result = createLink(result, 'Show Details...', () => onLicenceClick(params.data.originalLicenceId));
    }
    return result;
  };
  const currentLicenceNameRenderer = (params) => {
    let result = params.data.currentLicenceName;
    if (typeof onLicenceClick === 'function' && typeof params.data.currentLicenceId === 'number') {
      result = createLink(result, 'Show Details...', () => onLicenceClick(params.data.currentLicenceId));
    }
    return result;
  };

  const createResourceLink = (resource) => {
    const resourceName = resource.resourceName;

    if (typeof resource.resourceId === 'number') {
      let onClick = null;
      if (resource.isProspect && typeof onProspectClick === 'function') {
        onClick = () => onProspectClick(resource.resourceId);
      } else if (resource.isDiscovery && typeof onDiscoveryClick === 'function') {
        onClick = () => onDiscoveryClick(resource.resourceId);
      } else if (typeof onFieldClick === 'function') {
        onClick = () => onFieldClick(resource.resourceId);
      }
      if (onClick !== null) {
        return createLink(resourceName, 'Show details', onClick);
      }
    }

    const result = React.createElement('span', { 'key': resourceName }, resourceName);
    return result;
  }

  const resourcesRender = (params) => {
    if (params.data.resources.length === 0) {
      return null;
    }

    var resources = [];
    var extras = 0;
    for (var loop = 0; loop < params.data.resources.length; loop++) {
      resources[loop + extras] = createResourceLink(params.data.resources[loop], loop + extras);
      if (loop < params.data.resources.length - 1) {
        extras += 1;
        resources[loop + extras] = React.createElement('span', { 'key': loop }, ',');
      }
    }

    const result = React.createElement('span', {},
      [
        resources.map(x => x)
      ]);

    return result;
  };

  const columnDefs = [
    {
      headerName: 'Well Name',
      field: 'well',
      minWidth: 150,
      cellRenderer: wellRenderer
    },
    {
      headerName: 'Associated Resource',
      field: 'resourceString',
      minWidth: 170,
      cellRenderer: resourcesRender
    },
    {
      headerName: 'Well Type',
      field: 'typeName',
      minWidth: 85
    },
    {
      headerName: 'Operator',
      field: 'operatorName',
      minWidth: 140,
      cellRenderer: operatorNameRenderer,
      cellRendererParams: {
        currentCorporateId: props.currentCorporateId
      }
    },
    {
      headerName: 'Equity (' + valueTypes.UnitString(valueTypes.percent) + ')',
      field: 'equity',
      minWidth: 80,
      sortingOrder: NUMERIC_SORT
    },
    {
      headerName: 'Spud Date',
      field: 'startDate',
      minWidth: 100
    },
    {
      headerName: 'Completion Date',
      field: 'endDate',
      minWidth: 120
    },
    {
      headerName: 'Well Status',
      field: 'statusName',
      minWidth: 85
    },
    {
      headerName: 'Well Result',
      field: 'resultName',
      minWidth: 120
    },
    {
      headerName: 'Original Licence',
      field: 'originalLicenceName',
      minWidth: 120,
      cellRenderer: originalLicenceNameRenderer
    },
    {
      headerName: 'Current Licence',
      field: 'currentLicenceName',
      minWidth: 150,
      cellRenderer: currentLicenceNameRenderer
    }
  ];

  return (
    <React.Fragment>
      <div style={{ marginBottom: 5 }}>The completed and active E&A well table lists all wells that the company has participated in. It includes wells where the company has changed name but does not include wells drilled by a company that has been acquired or merged with.</div>
      <CorporateDetailGridComponent columnDefs={columnDefs} dataRows={props.wells} headerRowHeight={HEADER_ROW_DOUBLE} />
    </React.Fragment>
  );
};

CompletedAndActiveWellsTableComponent.propTypes = {
  currentCorporateId: PropTypes.number.isRequired,
  wells: PropTypes.arrayOf(PropTypes.instanceOf(CompletedAndActiveWell)).isRequired,
  onWellClick: PropTypes.func,
  onReportClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onOperatorClick: PropTypes.func,
  onProspectClick: PropTypes.func,
  onDiscoveryClick: PropTypes.func,
  onFieldClick: PropTypes.func
};

export default CompletedAndActiveWellsTableComponent;
