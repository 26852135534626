import moment from 'moment';

import { MapShapeBorder } from '../../shared/scss/app-colors';
import { PolygonsModel, PolygonModel } from '../../shared/helpers/leaflet/models/polygons-model';
import { dateTypes, valueTypes } from '../../shared/helpers/value-handler';
import { showError } from '../../shared/helpers/common';
import { WellResource } from '../../models/well-resource';

class OperatorRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "OperatorRow.ctor"');
    }
    if (typeof obj.operatorName !== 'string') {
      throw new Error('Invalid "obj.operatorName" param supplied to "OperatorRow.ctor"');
    }
    if (typeof obj.operatorId !== 'number') {
      throw new Error('Invalid "obj.operatorId" param supplied to "OperatorRow.ctor"');
    }

    this.operatorName = obj.operatorName;
    this.operatorId = obj.operatorId;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "ProspectRow.OperatorRow"');
    }

    const result = array.map(obj => new OperatorRow(obj));
    return result;
  }
}

class BlockRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "BlockRow.ctor"');
    }
    if (typeof obj.blockId !== 'number') {
      throw new Error('Invalid "obj.blockId" param supplied to "BlockRow.ctor"');
    }
    if (typeof obj.blockNo !== 'string') {
      throw new Error('Invalid "obj.blockNo" param supplied to "BlockRow.ctor"');
    }
    if (typeof obj.area !== 'string') {
      throw new Error('Invalid "obj.area" param supplied to "BlockRow.ctor"');
    }
    if (typeof obj.operator !== 'object') {
      throw new Error('Invalid "obj.operator" param supplied to "BlockRow.ctor"');
    }
    if (typeof obj.areaSQKM !== 'number') {
      throw new Error('Invalid "obj.areaSQKM" param supplied to "BlockRow.ctor"');
    }

    this.blockId = obj.blockId;
    this.blockNo = obj.blockNo;
    this.area = obj.area;
    this.operator = new OperatorRow(obj.operator);
    this.areaSQKM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.areaSQKM);
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "BlockRow.FromArray"');
    }

    const result = array.map(obj => new BlockRow(obj));
    return result;
  }
}

class DiscoveryRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "DiscoveryRow.ctor"');
    }
    if (typeof obj.id !== 'number') {
      throw new Error('Invalid "obj.id" param supplied to "DiscoveryRow.ctor"');
    }
    if (typeof obj.name !== 'string') {
      throw new Error('Invalid "obj.name" param supplied to "DiscoveryRow.ctor"');
    }
    if (typeof obj.operator !== 'object') {
      throw new Error('Invalid "obj.operator" param supplied to "DiscoveryRow.ctor"');
    }
    if (typeof obj.year !== 'number') {
      throw new Error('Invalid "obj.year" param supplied to "DiscoveryRow.ctor"');
    }
    if (typeof obj.classification !== 'string') {
      throw new Error('Invalid "obj.classification" param supplied to "DiscoveryRow.ctor"');
    }
    if (obj.reserves !== null && typeof obj.reserves !== 'number') {
      throw new Error('Invalid "obj.reserves" param supplied to "DiscoveryRow.ctor"');
    }

    this.id = obj.id;
    this.name = obj.name;
    this.operator = new OperatorRow(obj.operator);
    this.year = obj.year;
    this.classification = obj.classification;
    this.reserves = obj.reserves;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "DiscoveryRow.FromArray"');
    }

    const result = array.map(obj => new DiscoveryRow(obj));
    return result;
  }
}

class ProspectRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "ProspectRow.ctor"');
    }
    if (typeof obj.id !== 'number') {
      throw new Error('Invalid "obj.id" param supplied to "ProspectRow.ctor"');
    }
    if (typeof obj.identifier !== 'string') {
      throw new Error('Invalid "obj.identifier" param supplied to "ProspectRow.ctor"');
    }
    if (typeof obj.name !== 'string') {
      throw new Error('Invalid "obj.name" param supplied to "ProspectRow.ctor"');
    }
    if (typeof obj.operator !== 'object') {
      throw new Error('Invalid "obj.operator" param supplied to "ProspectRow.ctor"');
    }
    if (obj.combinedMmboe !== null && typeof obj.combinedMmboe !== 'number') {
      throw new Error('Invalid "obj.combinedMmboe" param supplied to "ProspectRow.ctor"');
    }

    this.id = obj.id;
    this.identifier = obj.identifier;
    this.name = obj.name;
    this.operator = new OperatorRow(obj.operator);
    this.combinedMmboe = obj.combinedMmboe;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "ProspectRow.FromArray"');
    }

    const result = array.map(obj => new ProspectRow(obj));
    return result;
  }
}

class CompletedAndActiveWellRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.wellId !== 'number') {
      throw new Error('Invalid "obj.wellId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.wellName !== 'string') {
      throw new Error('Invalid "obj.wellName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.typeId !== 'number') {
      throw new Error('Invalid "obj.typeId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.typeName !== 'string') {
      throw new Error('Invalid "obj.typeName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (!Array.isArray(obj.resources)) {
      throw new Error('Invalid "obj.resources" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.operator !== 'object') {
      throw new Error('Invalid "obj.operator" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.startDate !== null && typeof obj.startDate !== 'string') {
      throw new Error('Invalid "obj.startDate" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.endDate !== null && typeof obj.endDate !== 'string') {
      throw new Error('Invalid "obj.endDate" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.statusId !== 'number') {
      throw new Error('Invalid "obj.statusId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.statusName !== 'string') {
      throw new Error('Invalid "obj.statusName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.resultName !== 'string') {
      throw new Error('Invalid "obj.resultName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.reportId !== 'number') {
      throw new Error('Invalid "obj.reportId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.originalLicenceId !== null && typeof obj.originalLicenceId !== 'number') {
      throw new Error('Invalid "obj.originalLicenceId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.originalLicenceName !== null && typeof obj.originalLicenceName !== 'string') {
      throw new Error('Invalid "obj.originalLicenceName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.currentLicenceId !== null && typeof obj.currentLicenceId !== 'number') {
      throw new Error('Invalid "obj.currentLicenceId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.currentLicenceName !== null && typeof obj.currentLicenceName !== 'string') {
      throw new Error('Invalid "obj.currentLicenceName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }

    let startDateMoment = null;
    if (obj.startDate !== null) {
      if (typeof obj.startDate !== 'string') { showError('CompletedAndActiveWellRow', 'startDate'); }
      startDateMoment = moment(obj.startDate);
      if (!startDateMoment.isValid()) { showError('CompletedAndActiveWellRow', 'startDateMoment'); }
    }

    let endDateMoment = null;
    if (obj.endDate !== null) {
      if (typeof obj.endDate !== 'string') { showError('CompletedAndActiveWellRow', 'endDate'); }
      endDateMoment = moment(obj.endDate);
      if (!endDateMoment.isValid()) { showError('CompletedAndActiveWellRow', 'endDateMoment'); }
    }

    this.wellId = obj.wellId;
    this.wellName = obj.wellName;
    this.typeId = obj.typeId;
    this.typeName = obj.typeName;
    this.resources = WellResource.FromArray(obj.resources);
    this.operator = new OperatorRow(obj.operator);
    this.startDate = startDateMoment === null ? null : startDateMoment.format(dateTypes.GetFormatString(dateTypes.AtlasDisplay));
    this.endDate = endDateMoment === null ? null : endDateMoment.format(dateTypes.GetFormatString(dateTypes.AtlasDisplay));
    this.statusId = obj.statusId;
    this.statusName = obj.statusName;
    this.resultName = obj.resultName;
    this.reportId = obj.reportId;
    this.originalLicenceId = obj.originalLicenceId;
    this.originalLicenceName = obj.originalLicenceName;
    this.currentLicenceId = obj.currentLicenceId;
    this.currentLicenceName = obj.currentLicenceName;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "CompletedAndActiveWellRow.FromArray"');
    }

    const result = array.map(obj => new CompletedAndActiveWellRow(obj));
    return result;
  }
}

class PlannedWellRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.wellId !== 'number') {
      throw new Error('Invalid "obj.wellId" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.wellName !== 'string') {
      throw new Error('Invalid "obj.wellName" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.typeId !== 'number') {
      throw new Error('Invalid "obj.typeId" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.typeName !== 'string') {
      throw new Error('Invalid "obj.typeName" param supplied to "PlannedWellRow.ctor"');
    }
    if (!Array.isArray(obj.resources)) {
      throw new Error('Invalid "obj.resources" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.operator !== 'object') {
      throw new Error('Invalid "obj.operator" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.estimatedTiming !== 'string') {
      throw new Error('Invalid "obj.estimatedTiming" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.blockId !== 'number') {
      throw new Error('Invalid "obj.blockId" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.blockName !== 'string') {
      throw new Error('Invalid "obj.blockName" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.statusName !== 'string') {
      throw new Error('Invalid "obj.statusName" param supplied to "PlannedWellRow.ctor"');
    }

    this.wellId = obj.wellId;
    this.wellName = obj.wellName;
    this.typeId = obj.typeId;
    this.typeName = obj.typeName;
    this.resources = WellResource.FromArray(obj.resources);
    this.operator = new OperatorRow(obj.operator);
    this.estimatedTiming = obj.estimatedTiming;
    this.blockId = obj.blockId;
    this.blockName = obj.blockName;
    this.statusName = obj.statusName;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "PlannedWellRow.FromArray"');
    }

    const result = array.map(obj => new PlannedWellRow(obj));
    return result;
  }
}

class FieldRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "FieldRow.ctor"');
    }
    if (typeof obj.id !== 'number') {
      throw new Error('Invalid "obj.id" param supplied to "FieldRow.ctor"');
    }
    if (typeof obj.name !== 'string') {
      throw new Error('Invalid "obj.name" param supplied to "FieldRow.ctor"');
    }
    if (typeof obj.status !== 'string') {
      throw new Error('Invalid "obj.status" param supplied to "FieldRow.ctor"');
    }
    if (typeof obj.operator !== 'object') {
      throw new Error('Invalid "obj.operator" param supplied to "BlockRow.ctor"');
    }
    if (typeof obj.hubId !== 'number') {
      throw new Error('Invalid "obj.hubId" param supplied to "FieldRow.ctor"');
    }
    if (typeof obj.hubName !== 'string') {
      throw new Error('Invalid "obj.hubName" param supplied to "FieldRow.ctor"');
    }
    if (obj.reserves !== null && typeof obj.reserves !== 'number') {
      throw new Error('Invalid "obj.reserves" param supplied to "FieldRow.ctor"');
    }
    if (obj.production !== null && typeof obj.production !== 'number') {
      throw new Error('Invalid "obj.production" param supplied to "FieldRow.ctor"');
    }

    this.id = obj.id;
    this.name = obj.name;
    this.status = obj.status;
    this.operator = new OperatorRow(obj.operator);
    this.hubId = obj.hubId;
    this.hubName = obj.hubName;
    this.reserves = obj.reserves;
    this.production = obj.production;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "FieldRow.FromArray"');
    }

    const result = array.map(obj => new FieldRow(obj));
    return result;
  }
}

class LicenceDetail {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.id !== 'number') {
      throw new Error('Invalid "obj.id" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.licenceNumber !== 'string') {
      throw new Error('Invalid "obj.licenceNumber" param supplied to "LicenceDetail.ctor"');
    }
    let licenceDateMoment = null;
    if (obj.licenceDate !== null) {
      if (typeof obj.licenceDate !== 'string') {
        throw new Error('Invalid "obj.licenceDate" param supplied to "LicenceDetail.ctor"');
      }
      licenceDateMoment = moment(obj.licenceDate);
      if (!licenceDateMoment.isValid()) {
        throw new Error('Invalid "obj.licenceDate" param supplied to "LicenceDetail.ctor"');
      }
    }
    if (typeof obj.countryId !== 'number') {
      throw new Error('Invalid "obj.countryId" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.countryName !== 'string') {
      throw new Error('Invalid "obj.countryName" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.roundId !== 'number') {
      throw new Error('Invalid "obj.roundId" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.roundName !== 'string') {
      throw new Error('Invalid "obj.roundName" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.licenceTypeId !== 'number') {
      throw new Error('Invalid "obj.licenceTypeId" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.licenceTypeName !== 'string') {
      throw new Error('Invalid "obj.licenceTypeName" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.originalLicenceTypeId !== 'number') {
      throw new Error('Invalid "obj.originalLicenceTypeId" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.originalLicenceTypeName !== 'string') {
      throw new Error('Invalid "obj.originalLicenceTypeName" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.area !== 'string') {
      throw new Error('Invalid "obj.area" param supplied to "LicenceDetail.ctor"');
    }
    if (!Array.isArray(obj.operators)) {
      throw new Error('Invalid "obj.operators" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.status !== 'string') {
      throw new Error('Invalid "obj.status" param supplied to "LicenceDetail.ctor"');
    }
    if (obj.link !== null && typeof obj.link !== 'string') {
      throw new Error('Invalid "obj.link" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.currentYear !== 'number' || obj.currentYear === 0 ) {
      throw new Error('Invalid "obj.currentYear" param supplied to "LicenceDetail.ctor"');
    }
    if (!Array.isArray(obj.blocks)) {
      throw new Error('Invalid "obj.blocks" param supplied to "LicenceDetail.ctor"');
    }
    if (!Array.isArray(obj.discoveries)) {
      throw new Error('Invalid "obj.discoveries" param supplied to "LicenceDetail.ctor"');
    }
    if (!Array.isArray(obj.prospects)) {
      throw new Error('Invalid "obj.prospects" param supplied to "LicenceDetail.ctor"');
    }
    if (!Array.isArray(obj.completedAndActiveWells)) {
      throw new Error('Invalid "obj.completedAndActiveWells" param supplied to "LicenceDetail.ctor"');
    }
    if (!Array.isArray(obj.plannedWells)) {
      throw new Error('Invalid "obj.plannedWells" param supplied to "LicenceDetail.ctor"');
    }
    if (!Array.isArray(obj.fields)) {
      throw new Error('Invalid "obj.fields" param supplied to "LicenceDetail.ctor"');
    }
    if (!Array.isArray(obj.shapes)) {
      throw new Error('Invalid "obj.shapes" param supplied to "LicenceDetail.ctor"');
    }
    if (typeof obj.areaSQKM !== 'number') {
      throw new Error('Invalid "obj.areaSQKM" param supplied to "LicenceDetail.ctor"');
    }

    this.id = obj.id;
    this.licenceNumber = obj.licenceNumber;
    this.licenceDate = licenceDateMoment === null ? null : licenceDateMoment.format('DD/MM/YYYY');
    this.countryId = obj.countryId;
    this.countryName = obj.countryName;
    this.roundId = obj.roundId;
    this.roundName = obj.roundName;
    this.licenceTypeId = obj.licenceTypeId;
    this.licenceTypeName = obj.licenceTypeName;
    this.originalLicenceTypeId = obj.originalLicenceTypeId;
    this.originalLicenceTypeName = obj.originalLicenceTypeName;
    this.area = obj.area;
    this.operators = OperatorRow.FromArray(obj.operators);
    this.status = obj.status;
    this.link = obj.link;
    this.currentYear = obj.currentYear;
    this.blocks = BlockRow.FromArray(obj.blocks);
    this.discoveries = DiscoveryRow.FromArray(obj.discoveries);
    this.prospects = ProspectRow.FromArray(obj.prospects);
    this.completedAndActiveWells = CompletedAndActiveWellRow.FromArray(obj.completedAndActiveWells);
    this.plannedWells = PlannedWellRow.FromArray(obj.plannedWells);
    this.fields = FieldRow.FromArray(obj.fields);
    this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });
    this.areaSQKM = obj.areaSQKM;
  }
}

export { LicenceDetail, BlockRow, DiscoveryRow, ProspectRow, CompletedAndActiveWellRow, PlannedWellRow, FieldRow };
