import React from 'react';
import PropTypes from 'prop-types';

import HubFieldsParticipationTableComponent from './hub-fields/hub-fields-participation-table-component';

import { valueTypes } from '../../../shared/helpers/value-handler';
import { HubDetail } from '../../../models/hub-detail';
import GenericChart, { ChartType } from '../../../shared/components/generic-chart/generic-chart';
import { createSection, sectionTypes, createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedHubFieldsParticipationComponent = (props) => {
  const { hub } = props;

  const chartStyle = { height: '350px' };
  const noData = (<div>No data available</div>);

  const ownersTable = <HubFieldsParticipationTableComponent owners={hub.owners} currentYear={hub.currentYear} discountRate={hub.cashflow.discountRate} onCorporateClick={props.onCorporateClick} />;

  const reserves = hub.chartDataCollection.remainingReservesByParticipant !== null ? (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.remainingReservesByParticipant}
          chartType={ChartType.HorizontalBar}
          isXStacked={true}
          isYStacked={true}
          showXAxisLabel={true}
          xAxisLabel={valueTypes.UnitString(valueTypes.mmmboe)}
          showXAxisGridLines={true}
        />
      </div>
    </div>
  ) : noData;

  const production = hub.chartDataCollection.productionByParticipant !== null ? (
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.productionByParticipant}
          chartType={ChartType.Line}
          isXStacked={false}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.mboed)}
          showYAxisGridLines={true}
        />
      </div>      
  ) : noData;

  return (
    <div ref={props.innerRef}>
      {createSection100(ownersTable, 'Participant summary')}

      {createSection([
        { content: reserves, sectionType: sectionTypes.section33, title: 'Net remaining reserves by participant' },
        { content: production, sectionType: sectionTypes.section66, title: 'Net production by participant' }
      ])}
    </div>
  );
};

AdvancedHubFieldsParticipationComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  hub: PropTypes.instanceOf(HubDetail).isRequired,
  onCorporateClick: PropTypes.func,
};

export default AdvancedHubFieldsParticipationComponent;
