import moment from 'moment';

import { showError } from '../shared/helpers/common';
import { MapShapeBorder } from '../shared/scss/app-colors';
import { dateTypes } from '../shared/helpers/value-handler';
import { CircleModel } from '../shared/helpers/leaflet/models/circle-model';
import { DisplayRig } from '../shared/components/models/gmatrix3-extension-display-rig';
import { DisplayParticipant } from '../shared/components/models/gmatrix3-display-participant';
import { WellResource } from './well-resource';

class WellDetailResource {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('WellDetailResource', 'obj'); }
    if (typeof obj.id !== 'number') { showError('WellDetailResource', 'id'); }
    if (typeof obj.name !== 'string') { showError('WellDetailResource', 'name'); }
    if (typeof obj.status !== 'string') { showError('WellDetailResource', 'status'); }
    if (typeof obj.classification !== 'string') { showError('WellDetailResource', 'classification'); }
    if (typeof obj.currentOperatorName !== 'string') { showError('WellDetailResource', 'currentOperatorName'); }
    if (obj.currentOperatorId !== null && typeof obj.currentOperatorId !== 'number') { showError('WellDetailResource', 'currentOperatorId'); }
    if (typeof obj.reservoirAge !== 'string') { showError('WellDetailResource', 'reservoirAge'); }
    if (typeof obj.type !== 'string') { showError('WellDetailResource', 'type'); }
    if (typeof obj.trapType !== 'string') { showError('WellDetailResource', 'trapType'); }
    if (typeof obj.reservoirConditon !== 'string') { showError('WellDetailResource', 'reservoirConditon'); }
    if (typeof obj.reservoirDepthFt !== 'number') { showError('WellDetailResource', 'reservoirDepthFt'); }
    if (typeof obj.liquidResourcesMmb !== 'number') { showError('WellDetailResource', 'liquidResourcesMmb'); }
    if (typeof obj.gasResourcesBcf !== 'number') { showError('WellDetailResource', 'gasResourcesBcf'); }
    if (typeof obj.totalResourcesMmboe !== 'number') { showError('WellDetailResource', 'totalResourcesMmboe'); }

    this.id = obj.id;
    this.name = obj.name;
    this.status = obj.status;
    this.classification = obj.classification;
    this.currentOperatorName = obj.currentOperatorName;
    this.currentOperatorId = obj.currentOperatorId;
    this.reservoirAge = obj.reservoirAge;
    this.type = obj.type;
    this.trapType = obj.trapType;
    this.reservoirConditon = obj.reservoirConditon;
    this.reservoirDepthFt = obj.reservoirDepthFt;
    this.liquidResourcesMmb = obj.liquidResourcesMmb;
    this.gasResourcesBcf = obj.gasResourcesBcf;
    this.totalResourcesMmboe = obj.totalResourcesMmboe;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "WellDetailResource.FromArray"');
    }

    const result = array.map(obj => new WellDetailResource(obj));
    return result;
  }
}

class WellDetail {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('WellDetail', 'obj'); }
    if (typeof obj.id !== 'number') { showError('WellDetail', 'id'); }
    if (typeof obj.wellName !== 'string') { showError('WellDetail', 'wellName'); }
    if (typeof obj.gmStatus !== 'number') { showError('WellDetail', 'gmStatus'); }
    if (typeof obj.countryName !== 'string') { showError('WellDetail', 'countryName'); }
    if (typeof obj.areaName !== 'string') { showError('WellDetail', 'areaName'); }
    if (typeof obj.class !== 'string') { showError('WellDetail', 'class'); }
    if (typeof obj.subClass !== 'string') { showError('WellDetail', 'subClass'); }
    if (typeof obj.licence !== 'string') { showError('WellDetail', 'licence'); }
    if (typeof obj.licenceId !== 'number') { showError('WellDetail', 'licence'); }
    if (typeof obj.licenceRound !== 'string') { showError('WellDetail', 'licenceRound'); }
    if (typeof obj.commitment !== 'string') { showError('WellDetail', 'commitment'); }
    if (typeof obj.farmIn !== 'string') { showError('WellDetail', 'farmIn'); }
    if (typeof obj.surfaceLocation !== 'string') { showError('WellDetail', 'surfaceLocation'); }
    if (typeof obj.duration !== 'number') { showError('WellDetail', 'duration'); }
    if (typeof obj.intendedTrack !== 'string') { showError('WellDetail', 'intendedTrack'); }
    if (typeof obj.status !== 'string') { showError('WellDetail', 'status'); }
    if (typeof obj.wellResult !== 'string') { showError('WellDetail', 'wellResult'); }
    if (typeof obj.rig !== 'object') { showError('WellDetail', 'rig'); }
    if (typeof obj.totalDepthFt !== 'number') { showError('WellDetail', 'totalDepthFt'); }
    if (typeof obj.waterDepthFt !== 'number') { showError('WellDetail', 'waterDepthFt'); }
    if (typeof obj.estimatedTiming !== 'string') { showError('WellDetail', 'estimatedTiming'); }
    if (typeof obj.approvedForDrilling !== 'string') { showError('WellDetail', 'approvedForDrilling'); }
    if (typeof obj.operatorOnly !== 'boolean') { showError('WellDetail', 'operatorOnly'); }
    if (typeof obj.operatorName !== 'string') { showError('WellDetail', 'operatorName'); }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') { showError('WellDetail', 'operatorId'); }
    if (obj.estimatedWellCost !== null && typeof obj.estimatedWellCost !== 'number') { showError('WellDetail', 'estimatedWellCost'); }
    if (typeof obj.point !== 'object') { showError('WellDetail', 'point'); }
    if (typeof obj.reportId !== 'number') { showError('WellDetail', 'reportId'); }
    if (obj.link !== null && typeof obj.link !== 'string') { showError('WellDetail', 'link'); }

    let startDateMoment = null;
    if (obj.startDate !== null) {
      if (typeof obj.startDate !== 'string') { showError('WellDetail', 'startDate'); }
      startDateMoment = moment(obj.startDate);
      if (!startDateMoment.isValid()) { showError('WellDetail', 'startDateMoment'); }
    }

    let endDateMoment = null;
    if (obj.endDate !== null) {
      if (typeof obj.endDate !== 'string') { showError('WellDetail', 'endDate'); }
      endDateMoment = moment(obj.endDate);
      if (!endDateMoment.isValid()) { showError('WellDetail', 'endDateMoment'); }
    }

    if (obj.owners !== null && !Array.isArray(obj.owners)) { showError('WellDetail', 'owners'); }
    if (obj.resource !== null && !Array.isArray(obj.resource)) { showError('WellDetail', 'resource'); }

    this.id = obj.id;
    this.wellName = obj.wellName;
    this.gmStatus = obj.gmStatus;
    this.countryName = obj.countryName;
    this.areaName = obj.areaName;
    this.class = obj.class;
    this.subClass = obj.subClass;
    this.licence = obj.licence;
    this.licenceId = obj.licenceId;
    this.licenceRound = obj.licenceRound;
    this.commitment = obj.commitment;
    this.farmIn = obj.farmIn;
    this.surfaceLocation = obj.surfaceLocation;
    this.startDate = startDateMoment === null ? null : startDateMoment.format(dateTypes.GetFormatString(dateTypes.AtlasDisplay));
    this.endDate = endDateMoment === null ? null : endDateMoment.format(dateTypes.GetFormatString(dateTypes.AtlasDisplay));
    this.duration = obj.duration;
    this.intendedTrack = obj.intendedTrack;
    this.status = obj.status;
    this.wellResult = obj.wellResult;
    this.rig = new DisplayRig(obj.rig);
    this.totalDepthFt = obj.totalDepthFt;
    this.waterDepthFt = obj.waterDepthFt;
    this.estimatedWellCost = obj.estimatedWellCost;
    this.estimatedTiming = obj.estimatedTiming;
    this.approvedForDrilling = obj.approvedForDrilling;
    this.operatorOnly = obj.operatorOnly;
    this.operatorName = obj.operatorName;
    this.operatorId = obj.operatorId;
    this.reportId = obj.reportId;
    this.link = obj.link;

    this.owners = obj.owners !== null && obj.owners.map(obj => new DisplayParticipant(obj));
    this.resource = obj.resource !== null && WellDetailResource.FromArray(obj.resource);
    this.shape = new CircleModel(obj.point.y, obj.point.x, { borderColor: MapShapeBorder, fillColor: 'transparent' });

    if (!Array.isArray(obj.resources))
      throw new Error('Invalid "obj.resourcse" param supplied to "WellDetail.ctor"');
    this.resources = WellResource.FromArray(obj.resources);
    this.resourceString = this.resources === null ? null : this.resources.map(x => x.resourceName).join('; ');
  }
}

export { WellDetail, WellDetailResource };
