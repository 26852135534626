import React from 'react';
import PropTypes from 'prop-types';

import { CorporateFilter } from './corporate-filter';

import BaseViewSettingsComponent, { createSplitDiv, FollowMapComponent, MultiSelectComponent, NumericFieldComponent } from '../base/base-view-settings-component';

import { AppSetState } from '../../app/app-set-state';
import { KeyValuePair } from '../../models/key-value-pair';
import { valueTypes } from '../../shared/helpers/value-handler';

class CorporateViewSettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      corporates: [],
      countries: [],
      areas: [],
      corporateCategories: []
    };
    this.loadFromFilter(this.state);
  }

  componentDidMount() {
    const url = 'api/corporate/filters/load';
    const onSuccess = (result) => {
      this.setState({
        corporates: KeyValuePair.FromArray(result.corporates),
        countries: KeyValuePair.FromArray(result.countries),
        areas: KeyValuePair.FromArray(result.areas),
        corporateCategories: KeyValuePair.FromArray(result.corporateCategories)
      });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter !== prevProps.filter) {
      this.setState(prevState => {
        this.loadFromFilter(prevState);
        return prevState;
      });
    }
  }

  loadFromFilter = (state) => {
    const { filter } = this.props;

    state.followMap = filter.followMap;
    state.corporateIds = filter.corporateIds;
    state.countryIds = filter.countryIds;
    state.areaIds = filter.areaIds;
    state.corporateCategoryIds = filter.corporateCategoryIds;
    state.minRemainingReserves = filter.minRemainingReserves;
    state.maxRemainingReserves = filter.maxRemainingReserves;
    state.minCurrentYearProduction = filter.minCurrentYearProduction;
    state.maxCurrentYearProduction = filter.maxCurrentYearProduction;
    state.minResourcesInUnDevelopedDiscoveries = filter.minResourcesInUnDevelopedDiscoveries;
    state.maxResourcesInUnDevelopedDiscoveries = filter.maxResourcesInUnDevelopedDiscoveries;
  }

  _update = (closeFilters) => {
    const { filter } = this.props;

    filter.followMap = this.state.followMap;
    filter.corporateIds = this.state.corporateIds;
    filter.countryIds = this.state.countryIds;
    filter.areaIds = this.state.areaIds;
    filter.corporateCategoryIds = this.state.corporateCategoryIds;
    filter.minRemainingReserves = this.state.minRemainingReserves;
    filter.maxRemainingReserves = this.state.maxRemainingReserves;
    filter.minCurrentYearProduction = this.state.minCurrentYearProduction;
    filter.maxCurrentYearProduction = this.state.maxCurrentYearProduction;
    filter.minResourcesInUnDevelopedDiscoveries = this.state.minResourcesInUnDevelopedDiscoveries;
    filter.maxResourcesInUnDevelopedDiscoveries = this.state.maxResourcesInUnDevelopedDiscoveries;

    this.props.onUpdate(closeFilters);
  };

  _clear = () => {
    this.props.filter.clear();
    this.loadFromFilter(this.state);
    this._update();
  }

  onToggleFollowMap = () => this.setState(prevState => { return { followMap: !prevState.followMap }; });
  onCorporateIdsChange = (value) => this.setState({ corporateIds: value });
  onCountryIdsChange = (value) => this.setState({ countryIds: value });
  onAreaIdsChange = (value) => {
    this.setState({ areaIds: value });
  }
  onCorporateCategoryIdsChange = (value) => {
    this.setState({ corporateCategoryIds: value });
  }

  onMinRemainingReservesChange = (value) => this.setState({ minRemainingReserves: value });
  onMaxRemainingReservesChange = (value) => this.setState({ maxRemainingReserves: value });

  onMinCurrentYearProductionChange = (value) => this.setState({ minCurrentYearProduction: value });
  onMaxCurrentYearProductionChange = (value) => this.setState({ maxCurrentYearProduction: value });

  onMinResourcesInUnDevelopedDiscoveriesChange = (value) => this.setState({ minResourcesInUnDevelopedDiscoveries: value });
  onMaxResourcesInUnDevelopedDiscoveriesChange = (value) => this.setState({ maxResourcesInUnDevelopedDiscoveries: value });

  render() {
    const { minRemainingReserves, maxRemainingReserves, minCurrentYearProduction, maxCurrentYearProduction, minResourcesInUnDevelopedDiscoveries, maxResourcesInUnDevelopedDiscoveries } = this.state;

    return (
      
      <BaseViewSettingsComponent
        appSetState={this.props.appSetState}
        className="field-view-settings-component"
        onUpdate={this._update}
        onClear={this._clear}
      >
        <FollowMapComponent value={this.state.followMap} enabled={this.props.followMapEnabled} onClick={this.onToggleFollowMap} />
        <MultiSelectComponent label="Company Name" value={this.state.corporateIds} values={this.state.corporates} onChange={this.onCorporateIdsChange} />
        <MultiSelectComponent label="Country" value={this.state.countryIds} values={this.state.countries} onChange={this.onCountryIdsChange} />
        <MultiSelectComponent label="Area" value={this.state.areaIds} values={this.state.areas} onChange={this.onAreaIdsChange} />
        <MultiSelectComponent label="Category" value={this.state.corporateCategoryIds} values={this.state.corporateCategories} onChange={this.onCorporateCategoryIdsChange} />
                
        {(createSplitDiv(
          <NumericFieldComponent label="Min" value={minRemainingReserves} max={maxRemainingReserves} onChange={this.onMinRemainingReservesChange} type="number" />,
          <NumericFieldComponent label="Max" value={maxRemainingReserves} min={minRemainingReserves} onChange={this.onMaxRemainingReservesChange} type="number" />,
          'Remaining Reserves (' + valueTypes.UnitString(valueTypes.mmmboe) + ')'
        ))}          

        {(createSplitDiv(
          <NumericFieldComponent label="Min" value={minCurrentYearProduction} max={maxCurrentYearProduction} onChange={this.onMinCurrentYearProductionChange} type="number" />,
          <NumericFieldComponent label="Max" value={maxCurrentYearProduction} min={minCurrentYearProduction} onChange={this.onMaxCurrentYearProductionChange} type="number" />,
          'Current Year Production (' + valueTypes.UnitString(valueTypes.mboed) + ')'
        ))}

        {(createSplitDiv(
          <NumericFieldComponent label="Min" value={minResourcesInUnDevelopedDiscoveries} max={maxResourcesInUnDevelopedDiscoveries} onChange={this.onMinResourcesInUnDevelopedDiscoveriesChange} type="number" />,
          <NumericFieldComponent label="Max" value={maxResourcesInUnDevelopedDiscoveries} min={minResourcesInUnDevelopedDiscoveries} onChange={this.onMaxResourcesInUnDevelopedDiscoveriesChange} type="number" />,
          'Resources in Undeveloped Discoveries'
        ))}

      </BaseViewSettingsComponent>
    );
  }
}

CorporateViewSettingsComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  filter: PropTypes.instanceOf(CorporateFilter).isRequired,
  onUpdate: PropTypes.func.isRequired,
  followMapEnabled: PropTypes.bool.isRequired
};

export default CorporateViewSettingsComponent;