import { CountryAnnualVariable } from './country-annual-variable';
import { cloneObject } from '../../../shared/helpers/common';

const internalDepreciationType = {
  None: 1,
  StraightLine: 2
};
Object.freeze(internalDepreciationType);

const DepreciationType = {
  ...internalDepreciationType,
  values: () => Object.values(internalDepreciationType),
  isValid: (value) => DepreciationType.values().includes(value),
  displayName: (value) => {
    switch (value) {
      case DepreciationType.None: return 'None';
      case DepreciationType.StraightLine: return 'Straight Line'
      default: throw new Error('Unsupported value (' + value + ') supplied to DepreciationType.displayName()');
    }
  }
};
Object.freeze(DepreciationType);

class CountryVariable {
  constructor(countryObj) {
    if (typeof countryObj !== 'object') {
      throw new Error('Invalid countryObj param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.id !== 'number') {
      throw new Error('Invalid countryObj.id param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.name !== 'string') {
      throw new Error('Invalid countryObj.name param supplied to CountryVariable.ctor');
    }
    if (!DepreciationType.isValid(countryObj.depreciationType)) { // TODO: is valid depreciationType
      throw new Error('Invalid countryObj.depreciationType param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.ddaYears !== 'number') {
      throw new Error('Invalid countryObj.ddaYears param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.rfes !== 'boolean') {
      throw new Error('Invalid countryObj.rfes param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.rfesInflationRate !== 'number') {
      throw new Error('Invalid countryObj.rfesInflationRate param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.rfesYears !== 'number') {
      throw new Error('Invalid countryObj.rfesYears param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.investmentUplift !== 'boolean') {
      throw new Error('Invalid countryObj.investmentUplift param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.investmentUpliftRate !== 'number') {
      throw new Error('Invalid countryObj.investmentUpliftRate param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.investmentUpliftYears !== 'number') {
      throw new Error('Invalid countryObj.investmentUpliftYears param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.explorationRebate !== 'boolean') {
      throw new Error('Invalid countryObj.explorationRebate param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.explorationRebateRate !== 'number') {
      throw new Error('Invalid countryObj.explorationRebateRate param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.abexRelief !== 'boolean') {
      throw new Error('Invalid countryObj.abexRelief param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.abexReliefRate !== 'number') {
      throw new Error('Invalid countryObj.abexReliefRate param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.investmentAllowance !== 'boolean') {
      throw new Error('Invalid countryObj.investmentAllowance param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.investmentAllowanceRate !== 'number') {
      throw new Error('Invalid countryObj.investmentAllowanceRate param supplied to CountryVariable.ctor');
    }
    if (typeof countryObj.investmentAllowanceStartYear !== 'number') {
      throw new Error('Invalid countryObj.investmentAllowanceStartYear param supplied to CountryVariable.ctor');
    }
    if (!(Array.isArray(countryObj.countryAnnualVariables))) {
      throw new Error('Invalid countryObj.countryAnnualVariables param supplied to CountryVariable.ctor');
    }

    this.id = countryObj.id;
    this.name = countryObj.name;
    this.depreciationType = countryObj.depreciationType;
    this.ddaYears = countryObj.ddaYears;
    this.rfes = countryObj.rfes;
    this.rfesInflationRate = countryObj.rfesInflationRate;
    this.rfesYears = countryObj.rfesYears;
    this.investmentUplift = countryObj.investmentUplift;
    this.investmentUpliftRate = countryObj.investmentUpliftRate;
    this.investmentUpliftYears = countryObj.investmentUpliftYears;
    this.explorationRebate = countryObj.explorationRebate;
    this.explorationRebateRate = countryObj.explorationRebateRate;
    this.abexRelief = countryObj.abexRelief;
    this.abexReliefRate = countryObj.abexReliefRate;
    this.investmentAllowance = countryObj.investmentAllowance;
    this.investmentAllowanceRate = countryObj.investmentAllowanceRate;
    this.investmentAllowanceStartYear = countryObj.investmentAllowanceStartYear;
    this.countryAnnualVariables = countryObj.countryAnnualVariables.map(obj => new CountryAnnualVariable(obj));
  }

  clone() {
    const result = cloneObject(this);
    result.countryAnnualVariables = this.countryAnnualVariables.map(obj => obj.clone());
    return result;
  }
}

export { DepreciationType, CountryVariable, CountryAnnualVariable };