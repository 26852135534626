import React from 'react';
import PropTypes from 'prop-types';

import CorporateDetailGridComponent, { createLink, NUMERIC_SORT } from '../corporate-detail-grid-component';

import { NetField } from '../../../../models/corporate-detail';
import { valueTypes } from '../../../../shared/helpers/value-handler';

const CorporateFieldsTableComponent = (props) => {
  const { onFieldClick, onHubClick, onOperatorClick } = props;

  const resourceNameRenderer = (params) => {
    let result = params.data.resourceName;
    if (typeof onFieldClick === 'function' && typeof params.data.resourceId === 'number') {
      result = createLink(result, 'Show Details...', () => onFieldClick(params.data.resourceId));
    }
    return result;
  };
  const hubRenderer = (params) => {
    let result = params.data.hub;
    if (typeof onHubClick === 'function' && typeof params.data.hubId === 'number') {
      result = createLink(result, 'Show Details...', () => onHubClick(params.data.hubId));
    }
    return result;
  };
  const operatorRenderer = (params) => {
    let result = params.data.operator;
    // params.data.operatorId === params.currentCorporateId means we're currently showing that company, so no point having a link
    if (typeof onOperatorClick === 'function' && typeof params.data.operatorId === 'number' && params.data.operatorId !== params.currentCorporateId) {
      result = createLink(result, 'Show Details...', () => onOperatorClick(params.data.operatorId));
    }
    return result;
  };
  const equityCellRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.equity);
  const netRemainingLiquidMmbRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.netRemainingLiquidMmb);
  const netRemainingGasBCFRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.netRemainingGasBCF);
  const netRemainingReservesMmboeRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.netRemainingReservesMmboe);
  const remainingReservesPercentRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.remainingReservesPercent);
  const currentYearNetProductionMBOEPDRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.currentYearNetProductionMBOEPD);
  const netRemainingOpexUSDMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.netRemainingOpexUSDMM);
  const netRemainingCapexUSDMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.netRemainingCapexUSDMM);
  const netRemainingAbexUSDMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.netRemainingAbexUSDMM);
  const currentYearNetCapexUSDMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.currentYearNetCapexUSDMM);
  const currentYearNetOpexUSDMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.currentYearNetOpexUSDMM);
  const currentYearNetAbexUSDMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.currentYearNetAbexUSDMM);
  const netRemainingNPVUSDMMRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.netRemainingNPVUSDMM);

  const columnDefs = [
    {
      headerName: 'Field',
      field: 'resourceName',
      minWidth: 130,
      //pinned: 'left',
      cellRenderer: resourceNameRenderer
    },
    {
      headerName: 'Development status',
      field: 'status',
      minWidth: 140
    },
    {
      headerName: 'Area',
      field: 'area',
      minWidth: 100
    },
    {
      headerName: 'Hub',
      field: 'hub',
      minWidth: 100,
      cellRenderer: hubRenderer
    },
    {
      headerName: 'Operator',
      field: 'operator',
      minWidth: 120,
      cellRenderer: operatorRenderer,
      cellRendererParams: {
        currentCorporateId: props.currentCorporateId
      }
    },
    {
      headerName: 'Equity (' + valueTypes.UnitString(valueTypes.percent) + ')',
      field: 'equity',
      minWidth: 85,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: equityCellRenderer
    },
    {
      headerName: 'First production',
      field: 'productionStartYear',
      sortingOrder: NUMERIC_SORT,
      minWidth: 105
    },
    {
      headerName: 'Expected COP',
      field: 'expectedCOPYear',
      sortingOrder: NUMERIC_SORT,
      minWidth: 95
    },
    {
      headerName: 'Remaining reserves (' + valueTypes.UnitString(valueTypes.percent) + ')',
      field: 'remainingReservesPercent',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: remainingReservesPercentRenderer
    },
    {
      headerName: 'Net Remaining',
      children: [
        {
          headerName: 'Liquid (' + valueTypes.UnitString(valueTypes.mmmboe) + ')',
          field: 'netRemainingLiquidMmb',
          minWidth: 105,
          sortingOrder: NUMERIC_SORT,
          cellRenderer: netRemainingLiquidMmbRenderer
        },
        {
          headerName: 'Gas (' + valueTypes.UnitString(valueTypes.bcf) + ')',
          field: 'netRemainingGasBCF',
          minWidth: 85,
          sortingOrder: NUMERIC_SORT,
          cellRenderer: netRemainingGasBCFRenderer
        },
        {
          headerName: 'Total reserves (' + valueTypes.UnitString(valueTypes.mmmboe) + ')',
          field: 'netRemainingReservesMmboe',
          minWidth: 105,
          sortingOrder: NUMERIC_SORT,
          cellRenderer: netRemainingReservesMmboeRenderer
        },
        {
          headerName: 'Opex (' + valueTypes.UnitString(valueTypes.usdMM) + ')',
          field: 'netRemainingOpexUSDMM',
          minWidth: 105,
          sortingOrder: NUMERIC_SORT,
          cellRenderer: netRemainingOpexUSDMMRenderer
        },
        {
          headerName: 'Capex (' + valueTypes.UnitString(valueTypes.usdMM) + ')',
          field: 'netRemainingCapexUSDMM',
          minWidth: 105,
          sortingOrder: NUMERIC_SORT,
          cellRenderer: netRemainingCapexUSDMMRenderer
        },
        {
          headerName: 'Abex (' + valueTypes.UnitString(valueTypes.usdMM) + ')',
          field: 'netRemainingAbexUSDMM',
          minWidth: 105,
          sortingOrder: NUMERIC_SORT,
          cellRenderer: netRemainingAbexUSDMMRenderer
        },
        {
          //headerName: 'NPV ' + valueTypes.ValueOnly(valueTypes.usdBOE, props.discountRate) + ' (' + valueTypes.UnitString(valueTypes.usdMM) + ')',
          headerName: 'Discounted NPV (' + valueTypes.UnitString(valueTypes.usdMM) + ')',
          field: 'netRemainingNPVUSDMM',
          minWidth: 105,
          sortingOrder: NUMERIC_SORT,
          cellRenderer: netRemainingNPVUSDMMRenderer
        },
      ]
    },
    {
      headerName: 'Net ' + props.currentYear,
      children: [
        {
          headerName: 'Production (' + valueTypes.UnitString(valueTypes.mboed) + ') ',
          field: 'currentYearNetProductionMBOEPD',
          minWidth: 105,
          sortingOrder: NUMERIC_SORT,
          cellRenderer: currentYearNetProductionMBOEPDRenderer
        },
        {
          headerName: 'Opex (' + valueTypes.UnitString(valueTypes.usdMM) + ')',
          field: 'currentYearNetOpexUSDMM',
          minWidth: 105,
          sortingOrder: NUMERIC_SORT,
          cellRenderer: currentYearNetOpexUSDMMRenderer
        },
        {
          headerName: 'Capex (' + valueTypes.UnitString(valueTypes.usdMM) + ')',
          field: 'currentYearNetCapexUSDMM',
          minWidth: 105,
          sortingOrder: NUMERIC_SORT,
          cellRenderer: currentYearNetCapexUSDMMRenderer
        },
        {
          headerName: 'Abex (' + valueTypes.UnitString(valueTypes.usdMM) + ')',
          field: 'currentYearNetAbexUSDMM',
          minWidth: 105,
          sortingOrder: NUMERIC_SORT,
          cellRenderer: currentYearNetAbexUSDMMRenderer
        }
      ]
    }
  ];

  return <CorporateDetailGridComponent columnDefs={columnDefs} dataRows={props.netFields} hasGroupedHeaderRow />;
};

CorporateFieldsTableComponent.propTypes = {
  currentCorporateId: PropTypes.number.isRequired,
  netFields: PropTypes.arrayOf(PropTypes.instanceOf(NetField)).isRequired,
  currentYear: PropTypes.number.isRequired,
  discountRate: PropTypes.number.isRequired,
  onFieldClick: PropTypes.func,
  onHubClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default CorporateFieldsTableComponent;
