import React from 'react';
import PropTypes from 'prop-types';

import { WellDataRow } from './well-data-row';
import { GridExcelExport } from './grid-excel-export';

import { appIcons } from '../../app/app-icons';
import { AppSetState } from '../../app/app-set-state';
import BaseGridComponent, { createLink, createLinkAndIcons, dateRenderer, nullNumberRenderer, COLUMN_SMALL, COLUMN_EXTRA_SMALL_SMALL, COLUMN_LARGE, COLUMN_LARGE_LARGE, HEADER_ROW_DOUBLE, NUMERIC_SORT } from '../../components/base-grid/base-grid-component';

const WellGridComponent = (props) => {
  const { view } = props.appSetState;

  const wellNameCellRenderer = (params) => {
    const icons = [];
    if (params.data.reportId > 0) {
      icons.push({ appIcon: appIcons.FilePdf, title: 'Download report', onClick: () => props.openReport(params.data.reportId) });
    }
    icons.push({ appIcon: appIcons.MapMarkerAlt, title: 'Show on map', onClick: () => props.showOnMap(params.data) });
    if (params.data.link !== null) {
      icons.push({ appIcon: appIcons.Link, title: 'Copy link...', onClick: () => props.copyLink(params.data) });
    }
    const result = createLinkAndIcons(params.data.name, 'Show Details', () => view.details.showWell(params.data.id), icons);
    return result;
  };

  const renderOperatorName = (params) => {
    const { operatorName, operatorId } = params.data;

    if (typeof operatorName === 'string' && operatorName.length > 0 && typeof operatorId === 'number') {
      return createLink(operatorName, 'Show details', () => view.details.showCorporate(operatorId));
    }
    return operatorName;
  };

  const renderLicence = (params) => {
    if (typeof params.data.licenceId === 'number') {
      return createLink(params.data.licence, 'Show details', () => view.details.showLicence(params.data.licenceId));
    }
    return params.data.licence;
  };

  const createResourceLink = (resource) => {
    const resourceName = resource.resourceName;

    if (typeof resource.resourceId === 'number') {
      let onClick = () => view.details.showField(resource.resourceId);
      if (resource.isProspect) {
        onClick = () => view.details.showProspect(resource.resourceId);
      } else if (resource.isDiscovery) {
        onClick = () => view.details.showDiscovery(resource.resourceId);
      }
      return createLink(resourceName, 'Show details', onClick);
    }

    const result = React.createElement('span', { 'key': resourceName}, resourceName);
    return result;
  }

  const renderResources = (params) => {
    if (params.data.resources.length === 0) {
      return null;
    }

    var resources = [];
    var extras = 0;
    for (var loop = 0; loop < params.data.resources.length; loop++) {
      resources[loop + extras] = createResourceLink(params.data.resources[loop], loop + extras);
      if (loop < params.data.resources.length - 1) {
        extras += 1;
        resources[loop + extras] = React.createElement('span', { 'key': loop }, ',');
      }
    }

    const result = React.createElement('span', {},
      [
        resources.map(x => x)
      ]);

    return result;
  };

  const WELL_COLUMN_DEFS = [
    {
      headerName: 'Well Name',
      field: 'name',
      sortable: true,
      minWidth: COLUMN_LARGE,
      cellRenderer: wellNameCellRenderer
    },
    {
      headerName: 'Country',
      field: 'countryName',
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: 'Area',
      field: 'areaName',
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: 'Resource',
      field: 'resourceString',
      sortable: true,
      minWidth: COLUMN_LARGE_LARGE,
      cellRenderer: renderResources
    },
    {
      headerName: 'Operator',
      field: 'operatorName',
      sortable: true,
      minWidth: COLUMN_LARGE_LARGE,
      cellRenderer: renderOperatorName
    },
    {
      headerName: 'Licence',
      field: 'licence',
      sortable: true,
      minWidth: COLUMN_LARGE,
      cellRenderer: renderLicence
    },
    {
      headerName: 'Well\nType',
      field: 'typeName',
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: 'Well\nSub-Type',
      field: 'subTypeName',
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: 'Well \nStatus',
      field: 'statusName',
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: 'Well \nResult',
      field: 'resultName',
      sortable: true,
      minWidth: COLUMN_LARGE
    },
    {
      headerName: 'Spud \nDate',
      field: 'startDate',
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: dateRenderer
    },
    {
      headerName: 'Completion \nDate',
      field: 'endDate',
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: dateRenderer
    },
    {
      headerName: 'Expected Spud \n Year',
      field: 'expectedStartYear',
      sortable: true,
      minWidth: COLUMN_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: 'Primary \nObjective',
      field: 'primaryObjectiveName',
      sortable: true,
      minWidth: COLUMN_SMALL
    }
  ];

  return <BaseGridComponent columnDefs={WELL_COLUMN_DEFS} dataRows={props.dataRows} autoReSizeGrid={true} headerRowHeight={HEADER_ROW_DOUBLE} />;
};

WellGridComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.instanceOf(WellDataRow).isRequired).isRequired,
  showOnMap: PropTypes.func.isRequired,
  openReport: PropTypes.func.isRequired,
  copyLink: PropTypes.func.isRequired
}

export default WellGridComponent;
export { GridExcelExport };
