import React from 'react';
import PropTypes from 'prop-types';

import { LicenceDetail } from '../licence-detail';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';
import { valueTypes } from '../../../shared/helpers/value-handler';

const KeyFactsComponent = (props) => {
  const { licence } = props;

  const getRows = () => {
    let originalLicenceType = null;
    if (licence.originalLicenceTypeId !== licence.licenceTypeId) {
      originalLicenceType = (
        <tr>
          <td>Original Licence Type</td>
          <td>{licence.originalLicenceTypeName}</td>
        </tr>
      );
    }

    const onOperatorClick = (e, operator) => {
      e.preventDefault();
      props.onOperatorClick(operator.operatorId);
    };

    let operators = [];
    for (var loop = 0; loop < licence.operators.length; loop++) {
      const operator = licence.operators[loop];
      const operatorName = operator.operatorName;
      const operatorId = operator.operatorId;
      const key = 'operator-' + operatorId;

      if (operatorId > 0 && typeof onOperatorClick === 'function') {
        operators.push(<a href="/" key={key} title="Show Details" onClick={(e) => onOperatorClick(e, operator)}>{operatorName}</a>);
      } else {
        operators.push(<span key={key}>{operatorName}</span>);
      }
      if (loop < licence.operators.length - 1) {
        operators.push(<span key={key + '-comma'}>, </span>);
      }
    }

    return (
      <React.Fragment>
        <tr>
          <td>Country</td>
          <td>{licence.countryName}</td>
        </tr>
        <tr>
          <td>Area</td>
          <td>{licence.area}</td>
        </tr>
        <tr>
          <td>Operator</td>
          <td>{operators}</td>
        </tr>
        <tr>
          <td>Licence Status</td>
          <td>{licence.status}</td>
        </tr>
        <tr>
          <td>Licence Round</td>
          <td>{licence.roundName}</td>
        </tr>
        <tr>
          <td>Licence Valid From</td>
          <td>{licence.licenceDate}</td>
        </tr>
        <tr>
          <td>Licence Type</td>
          <td>{licence.licenceTypeName}</td>
        </tr>
        {originalLicenceType}
        <tr>
          <td>Area ({valueTypes.UnitString(valueTypes.kmsq)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, licence.areaSQKM)}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent className="one-col-key-value">
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
};

KeyFactsComponent.propTypes = {
  licence: PropTypes.instanceOf(LicenceDetail).isRequired,
  onOperatorClick: PropTypes.func
};

export default KeyFactsComponent;