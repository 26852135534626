import React from 'react';
import PropTypes from 'prop-types';
import NetRemainingReservesByParticipantChart from './chart/net-remaining-reserves-by-participant-chart'

import { DisplayParticipant } from './models/portfolio/field-detail'

const NetRemainingReservesByParticipantComponent = (props) => {
  const { owners } = props;

  const participants = owners.map(par => {
    return {
      name: par.operatorDisplayName,
      netRemainingLiquidMmb: par.netRemainingLiquidMmb,
      netRemainingGasMmboe: par.netRemainingGasMmboe
    }
  });

  return <NetRemainingReservesByParticipantChart participants={participants} isStacked />;
}

NetRemainingReservesByParticipantComponent.propTypes = {
  owners: PropTypes.arrayOf(PropTypes.instanceOf(DisplayParticipant)).isRequired
};

export default NetRemainingReservesByParticipantComponent;
