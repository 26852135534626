import { cloneObject } from "../../../shared/helpers/common";
class ScenarioValue {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to ScenarioValue.ctor');
    }
    if (typeof obj.scenarioValueId !== 'number') {
      throw new Error('Invalid obj.scenarioValueId param supplied to ScenarioValue.ctor');
    }
    if (typeof obj.scenarioId !== 'number') {
      throw new Error('Invalid obj.scenarioId param supplied to ScenarioValue.ctor');
    }
    if (typeof obj.attributeName !== 'string') {
      throw new Error('Invalid obj.attributeName param supplied to ScenarioValue.ctor');
    }
    if (typeof obj.attributeValue !== 'number') {
      throw new Error('Invalid obj.attributeValue param supplied to ScenarioValue.ctor');
    }
    if (typeof obj.yearFrom !== 'number') {
      throw new Error('Invalid obj.yearFrom param supplied to ScenarioValue.ctor');
    }
    if (typeof obj.yearTo !== 'number') {
      throw new Error('Invalid obj.yearTo param supplied to ScenarioValue.ctor');
    }
    if (typeof obj.isActual !== 'boolean') {
      throw new Error('Invalid obj.isActual param supplied to ScenarioValue.ctor');
    }
    if (typeof obj.valueOrder !== 'number') {
      throw new Error('Invalid obj.valueOrder param supplied to ScenarioValue.ctor');
    }
    if (typeof obj.attributeId !== 'number') {
      throw new Error('Invalid obj.attributeId param supplied to ScenarioValue.ctor');
    }

    this.scenarioValueId = obj.scenarioValueId;
    this.scenarioId = obj.scenarioId;
    this.attributeName = obj.attributeName;
    this.attributeValue = obj.attributeValue;
    this.yearFrom = obj.yearFrom;
    this.yearTo = obj.yearTo;
    this.isActual = obj.isActual;
    this.valueOrder = obj.valueOrder;
    this.attributeId = obj.attributeId;
  }

  clone() {
    const result = cloneObject(this);
    return result;
  }
}

export { ScenarioValue };