import React from 'react';
import PropTypes from 'prop-types';

import { CorporateDetail } from '../../../../models/corporate-detail';
import { valueTypes } from '../../../../shared/helpers/value-handler';
import BaseTableComponent from '../../../../shared/components/detail-view-grid-layout/base-table-component';

const DetailsComponent = (props) => {
  const { corporate } = props;

  const getRows = () => {
    return (
      <React.Fragment>
        <tr>
          <td>Country(s)</td>
          <td>{corporate.countryList}</td>
        </tr>        
        <tr>
          <td>Area(s)</td>
          <td>{corporate.areaList}</td>
        </tr>
        <tr>
          <td>Company category</td>
          <td>{corporate.corporateCategory}</td>
        </tr>
        <tr>
          <td>Company tier group</td>
          <td>{corporate.tierList}</td>
        </tr>
        <tr>
          <td>% of fields operated</td>
          <td>{valueTypes.ValueOnly(valueTypes.percent, corporate.percentOperatedFields)}</td>
        </tr>
        <tr>
          <td>Number of producing fields</td>
          <td>{corporate.producingFields}</td>
        </tr>
        <tr>
          <td>Number of under development fields</td>
          <td>{corporate.underDevelopmentFields}</td>
        </tr>
        <tr>
          <td>Number of near term developments</td>
          <td>{corporate.nearTermFields}</td>
        </tr>
        <tr>
          <td>Number of other fields</td>
          <td>{corporate.otherFields}</td>
        </tr>
        <tr>
          <td>Gross Area ({valueTypes.UnitString(valueTypes.kmsq)})</td>
          <td>{corporate.grossAreaSQKM}</td>
        </tr>
        <tr>
          <td>Net Area ({valueTypes.UnitString(valueTypes.kmsq)})</td>
          <td>{corporate.netAreaSQKM}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent className="one-col-key-value">
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

DetailsComponent.propTypes = {
  corporate: PropTypes.instanceOf(CorporateDetail).isRequired
};

export default DetailsComponent;
