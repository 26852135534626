import L from 'leaflet';

import { PolygonsModel } from '../models/polygons-model';
import { removeLayersOfType } from '../helpers/layer-helper';

//const createPolygonsLayer = (polygonsModel) => {
//  if (!(polygonsModel instanceof PolygonsModel)) {
//    throw new Error('Invalid \'polygonsModel\' supplied to \'createPolygonsLayer\'');
//  }

//  const latLngs = polygonsModel.latLngs;
//  const options = {
//    color: polygonsModel.options.color,
//    fillColor: polygonsModel.options.fillColor,
//    interactive: polygonsModel.options.popup !== undefined
//  };
//  const result = L.polygon(latLngs, options);
//  if (polygonsModel.options.popup !== undefined) {
//    result.bindPopup(polygonsModel.options.popup);
//  }
//  return result;
//};

const createPolygonsLayer = (polygonsModel) => {
  if (!(polygonsModel instanceof PolygonsModel)) {
    throw new Error('Invalid \'polygonsModel\' supplied to \'createPolygonsLayer\'');
  }

  const options = {
    weight: polygonsModel.options.borderWidth,
    color: polygonsModel.options.borderColor,
    fillColor: polygonsModel.options.fillColor,
    interactive: polygonsModel.options.popup !== undefined
  };

  const setStyle = (layer, hover) => {
    const newOptions = { ...options };
    if (hover === true && typeof newOptions.weight === 'number') {
      newOptions.weight *= 2;
    }
    layer.setStyle(newOptions);
  }

  const result = L.polygon(polygonsModel.latLngs, options);
  result.on('mouseenter', (e) => setStyle(e.target, true));
  result.on('mouseleave', (e) => setStyle(e.target, false));
  result._polygonsModel = polygonsModel;

  if (polygonsModel.options.popup !== undefined) {
    result.bindPopup(polygonsModel.options.popup);
  }
  return result;
};

const removePolygonLayers = (leafletMap) => removeLayersOfType(leafletMap, L.Polygon);

export { createPolygonsLayer, removePolygonLayers, PolygonsModel };