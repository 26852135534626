import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { HubDetail } from '../../../models/hub-detail';
import { createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import LastHubChangeComponent from '../../../components/fiscal/fact-components/last-hub-change-component';

const AdvancedLastUpdateComponent = (props) => {
  const { hub } = props;

  const hubChangeDateAmended = moment(hub.clientChangeDescriptionDate).format('DD MMMM YYYY');
  const lastChange = hub.clientChangeDescription !== null && hub.clientChangeDescription !== '' ? <LastHubChangeComponent dateAmended={hubChangeDateAmended} textValue={hub.clientChangeDescription} /> : null;
    const assumptionsSection = lastChange ? createSection100(lastChange, 'Last update') : null;

  return (
    <div ref={props.innerRef}>
      {assumptionsSection }
    </div>
  );
};

AdvancedLastUpdateComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  hub: PropTypes.instanceOf(HubDetail).isRequired
};

export default AdvancedLastUpdateComponent;