import React from 'react';
import PropTypes from 'prop-types';

import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';
import { ResourceAnnual } from '../models/portfolio/fiscal-resource';
import { valueTypes } from '../../../shared/helpers/value-handler';

const ProductionFactsComponent = (props) => {
  const { annuals, currentYear, peakYear } = props;

  const getAnnualByYear = (theArray, year) => {
    var result = theArray.filter(x => x.year === year);
    return result !== undefined && result !== null && result.length > 0 ? result[0] : null;
  };

  const peakYearAnnual = getAnnualByYear(annuals, peakYear);
  const currentYearAnnual = getAnnualByYear(annuals, currentYear);

  const labelWidth = 35; // Percent
  let valueWidth = 100 - labelWidth;
  if (currentYearAnnual) {
    valueWidth = valueWidth / 2;
  }

  const getRows = () => {
    return (
      <React.Fragment>
        <tr style={{ fontWeight: 'bold' }}>
          <td style={{ width: labelWidth + '%' }} />
          <td style={{ width: valueWidth + '%' }}>Peak production {peakYear}</td>
          {currentYearAnnual && <td style={{ width: valueWidth + '%' }}>{currentYear} Production</td>}
        </tr>
        <tr>
          <td>Liquid ({valueTypes.UnitString(valueTypes.mboed)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, peakYearAnnual !== null ? peakYearAnnual.liquidProductionMbod : 0)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.generic1, currentYearAnnual.liquidProductionMbod)}</td>}
        </tr>
        <tr>
          <td>Gas ({valueTypes.UnitString(valueTypes.mmscfd)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, peakYearAnnual !== null ? peakYearAnnual.gasProductionMmscfd : 0)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.generic1, currentYearAnnual.gasProductionMmscfd)}</td>}
        </tr>
        <tr>
          <td>Total ({valueTypes.UnitString(valueTypes.mboed)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, peakYearAnnual !== null ? peakYearAnnual.combinedMboed : 0)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.generic1, currentYearAnnual.combinedMboed)}</td>}
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent>
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

ProductionFactsComponent.propTypes = {
  annuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  currentYear: PropTypes.number.isRequired,
  peakYear: PropTypes.number.isRequired
};

export default ProductionFactsComponent;
