import React from 'react';

import ProspectDetailComponent from './prospect-detail-component';

import { DetailModel } from '../detail-model';
import HeaderComponent from '../header-component';
import { ProspectDetail } from '../../models/prospect-detail';

import { appViews } from '../../app/app-views';
import { ContextLayerModel } from '../../shared/helpers/leaflet/leaflet-helper';

class ProspectDetailModel extends DetailModel {
  constructor(atlasPanel, prospectId) {
    if (typeof prospectId !== 'number') {
      throw new Error('Invalid "prospectId" supplied to "ProspectDetailModel.ctor"');
    }

    super(appViews.Prospect, atlasPanel);
    this._prospectId = prospectId;
  }

  matches(detail) {
    const result =
      typeof detail === 'object' &&
      detail instanceof ProspectDetailModel &&
      detail._prospectId === this._prospectId;
    return result;
  }

  load(appSetState, onLoadComplete) {
    this._validateLoadParams(appSetState, onLoadComplete);

    const onSuccess = (result) => {
      this.clearError();
      this._data = {
        prospect: new ProspectDetail(result.prospect),
        contextLayers: ContextLayerModel.FromArray(result.contextLayers)
      }
      this.addNavigationHistory(appSetState, this._prospectId, this.getProspectNameAndAlias());
      onLoadComplete();
    };
    const onFail = (err) => this.handleLoadError(err, onLoadComplete);
    const url = 'api/prospect/details/load/?resourceId=' + this._prospectId + '&page=' + appSetState.view.current + '&panel=' + this.atlasPanel;
    appSetState.api.getCached(url, onSuccess, onFail);
  }

  getProspectNameAndAlias() {
    const { prospect } = this._data;

    const result = prospect.name + (prospect.alias.length === 0 ? '' : ' (' + prospect.alias + ')');
    return result;
  }

  getHeaderComponent(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close) {
    this._validateGetHeaderComponentParams(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close);

    const copyLinkClick = null;
    //const copyLinkClick = this.loaded
    //  ? () => appSetState.view.details.showProspectLink(this._prospectId)
    //  : null;

    return (
      <HeaderComponent
        appView={this.appView}
        title={this.getTitle(() => this.getProspectNameAndAlias())}
        backClick={backClick}
        forwardClick={forwardClick}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        close={close}
        copyLinkClick={copyLinkClick}
      />
    );
  }

  getBodyComponent(appSetState, geoserverUrl) {
    this._validateGetBodyComponentParams(appSetState, geoserverUrl);

    if (this._error !== null) {
      return this._getErrorComponent();
    }

    if (this.loaded) {
      return (
        <ProspectDetailComponent
          prospect={this._data.prospect}
          contextLayers={this._data.contextLayers}
          appSetState={appSetState}
          geoserverUrl={geoserverUrl}
        />
      );
    }

    return this._getLoadingComponent();
  }
}

export { ProspectDetailModel };