import React from 'react';
import PropTypes from 'prop-types';

import CorporateDetailGridComponent, { createLink, HEADER_ROW_DOUBLE, NUMERIC_SORT } from '../corporate-detail-grid-component';

import { PlannedWell } from '../../../../models/corporate-detail';
import { valueTypes } from '../../../../shared/helpers/value-handler';

const PlannedWellsTableComponent = (props) => {
  const { onWellClick, onLicenceClick, onOperatorClick, onProspectClick, onDiscoveryClick, onFieldClick } = props;

  const wellRenderer = (params) => {
    let result = params.data.well;
    if (typeof onWellClick === 'function' && typeof params.data.wellId === 'number') {
      result = createLink(result, 'Show Details...', () => onWellClick(params.data.wellId));
    }
    return result;
  };

  const licenceNameRenderer = (params) => {
    let result = params.data.licence;
    if (typeof onLicenceClick === 'function' && typeof params.data.licenceId === 'number') {
      result = createLink(result, 'Show Details...', () => onLicenceClick(params.data.licenceId));
    }
    return result;
  };

  const operatorNameRenderer = (params) => {
    let result = params.data.operatorName;
    // params.data.operatorId === params.currentCorporateId means we're currently showing that company, so no point having a link
    if (typeof onOperatorClick === 'function' && typeof params.data.operatorId === 'number' && params.data.operatorId !== params.currentCorporateId) {
      result = createLink(result, 'Show Details...', () => onOperatorClick(params.data.operatorId));
    }
    return result;
  };

  const createResourceLink = (resource) => {
    const resourceName = resource.resourceName;

    if (typeof resource.resourceId === 'number') {
      let onClick = null;
      if (resource.isProspect && typeof onProspectClick === 'function') {
        onClick = () => onProspectClick(resource.resourceId);
      } else if (resource.isDiscovery && typeof onDiscoveryClick === 'function') {
        onClick = () => onDiscoveryClick(resource.resourceId);
      } else if (typeof onFieldClick === 'function') {
        onClick = () => onFieldClick(resource.resourceId);
      }
      if (onClick !== null) {
        return createLink(resourceName, 'Show details', onClick);
      }
    }

    const result = React.createElement('span', { 'key': resourceName }, resourceName);
    return result;
  }
  const resourcesRender = (params) => {
    if (params.data.resources.length === 0) {
      return null;
    }

    var resources = [];
    var extras = 0;
    for (var loop = 0; loop < params.data.resources.length; loop++) {
      resources[loop + extras] = createResourceLink(params.data.resources[loop], loop + extras);
      if (loop < params.data.resources.length - 1) {
        extras += 1;
        resources[loop + extras] = React.createElement('span', { 'key': loop }, ',');
      }
    }

    const result = React.createElement('span', {},
      [
        resources.map(x => x)
      ]);

    return result;
  };

  const columnDefs = [
    {
      headerName: 'Well Name',
      field: 'well',
      minWidth: 150,
      cellRenderer: wellRenderer
    },
    {
      headerName: 'Associated Resource',
      field: 'resourceString',
      minWidth: 250,
      cellRenderer: resourcesRender
    },
    {
      headerName: 'Well Type',
      field: 'typeName',
      minWidth: 110
    },
    {
      headerName: 'Well Status',
      field: 'statusName',
      minWidth: 110
    },
    {
      headerName: 'Operator',
      field: 'operatorName',
      minWidth: 150,
      cellRenderer: operatorNameRenderer,
      cellRendererParams: {
        currentCorporateId: props.currentCorporateId
      }
    },
    {
      headerName: 'Equity (' + valueTypes.UnitString(valueTypes.percent) + ')',
      field: 'equity',
      minWidth: 80,
      sortingOrder: NUMERIC_SORT
    },
    {
      headerName: 'Estimated Timing',
      field: 'estimatedTiming',
      sortingOrder: NUMERIC_SORT,
      minWidth: 80
    },
    {
      headerName: 'Licence',
      field: 'licence',
      minWidth: 100,
      cellRenderer: licenceNameRenderer
    }
  ];

  return <CorporateDetailGridComponent columnDefs={columnDefs} dataRows={props.wells} headerRowHeight={HEADER_ROW_DOUBLE} />;
};

PlannedWellsTableComponent.propTypes = {
  currentCorporateId: PropTypes.number.isRequired,
  wells: PropTypes.arrayOf(PropTypes.instanceOf(PlannedWell)).isRequired,
  onWellClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onOperatorClick: PropTypes.func,
  onProspectClick: PropTypes.func,
  onDiscoveryClick: PropTypes.func,
  onFieldClick: PropTypes.func
};

export default PlannedWellsTableComponent;
