import React from 'react';
import PropTypes from 'prop-types';

const CorporateCashflowAssumptionsComponent = (props) => {
  const { valuationName } = props;
  return (
    <React.Fragment>
      The cashflow has been run using <strong>{valuationName}</strong> assumptions. It is a pre-tax undiscounted cashflow and is a summation of the company's net position in the underlying fields.
      <br />
      Further details on the fiscal model and Westwood’s Base, Low and High case assumptions can be found in the <a target="_blank" href="https://atlas.westwoodenergy.com/assumptions" title="Go to Assumptions" rel="noopener noreferrer">Methodology and Assumptions</a> document (also found in the Help menu). Please also refer to the underlying fields for any specific price discounts and premiums.
    </React.Fragment>
  );
}

CorporateCashflowAssumptionsComponent.propTypes = {
  valuationName: PropTypes.string.isRequired,
};

export default CorporateCashflowAssumptionsComponent;
