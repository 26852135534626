const CashflowType = {
  PreTax: 1,
  PostTax: 2,
  PostTaxDiscounted: 3
};

const TrackingType = {
  LoginAttempt: 1,
  PageView: 2,
  ReportDownload: 3,
  FileDownload: 4,
  FindWellOnMap: 5,
  FindResourceOnMap: 6,
  MapInfoWell: 7,
  MapInfoResource: 8,
  LatestDashboardLink: 10,
  QuickboardLink: 11,
  AtlasMap: 12,
  OpenPDF: 14,
  TextSearch: 15,
  FilterSearch: 16,
  TextandFilterSearch: 17,
  OpenDiscoveryDetail: 18,
  OpenWellDetail: 19,
  OpenHubDetail: 20,
  OpenFieldDetail: 21,
  OpenProspectDetail: 22,
  OpenBlockDetail: 23
}

const AtlasPanel = {
  None: 1,
  Activities: 2,
  Reports: 3,
  EmailLink: 4,
  View: 5,
  History: 6
}

export { CashflowType, TrackingType, AtlasPanel };