import React from 'react';
import PropTypes from 'prop-types';

import { WellDetail } from '../../../models/well-detail';
import { valueTypes } from '../../../shared/helpers/value-handler';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const WellInformationComponent = (props) => {
  const { well } = props;

  const getRows = () => {
    return (
      <React.Fragment>
        <tr>
          <td>Spud date</td>
          <td>{well.startDate}</td>
        </tr>        
        <tr>
          <td>Completion date</td>
          <td>{well.endDate}</td>
        </tr>
        <tr>
          <td>Well duration ({valueTypes.UnitString(valueTypes.days)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.days, well.duration)}</td>
        </tr>
        <tr>
          <td>Intended track</td>
          <td>{well.intendedTrack}</td>
        </tr>
        <tr>
          <td>Well status</td>
          <td>{well.status}</td>
        </tr>
        <tr>
          <td>Drilling rig</td>
          <td>{well.rig.rigName}</td>
        </tr>
        <tr>
          <td>Total depth ({valueTypes.UnitString(valueTypes.feet)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.feet, well.totalDepthFt)}</td>
        </tr>
        <tr>
          <td>Water depth ({valueTypes.UnitString(valueTypes.feet)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.feet, well.waterDepthFt)}</td>
        </tr>
        <tr>
          <td>Estimated well cost ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.usdMM, well.estimatedWellCost)}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent className="one-col-key-value">
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

WellInformationComponent.propTypes = {
  well: PropTypes.instanceOf(WellDetail).isRequired
};

export default WellInformationComponent;
