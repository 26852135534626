import React from 'react';
//import PropTypes from 'prop-types'
import './notification-component.scss';
import { EmailCountries } from '../../models/EmailReport';
import { EmailCategories } from '../../models/EmailReport';


class NotificationComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailCountries: new EmailCountries(),
      emailCategories: new Array(new EmailCategories()),
      selectedCountry: 1
    }

    this.getNotifications = this.getNotifications.bind(this);
    this.updateChangeNotifications = this.updateChangeNotifications.bind(this);
    this.changeSelectedCountry = this.changeSelectedCountry.bind(this);
  };

  componentDidMount() {
    this.getNotifications();
  }

  getNotifications() {
    const onSuccess = (result) => {
      this.setState({ emailCountries: result[0].dataRows, emailCategories: result[1] });
    }
    const onFail = (err) => {
      console.log(err);
    }

    this.props.api.get('api/reports/EmailNotifications', onSuccess, onFail);
  }

  updateChangeNotifications = (event) => {
    const onSuccess = (result) => {
      this.setState({}, () => this.getNotifications());
    }

    var send = event.target.checked;
    var notificationID = event.currentTarget.id;
    this.props.api.get('api/reports/UpdateEmailNotifications?notificationID=' + notificationID + '&send=' + send, onSuccess);
  }

  changeSelectedCountry = (countryID) => {
    var newselectedcountryID = Number(countryID.currentTarget.id) + 1;
    this.setState({ selectedCountry: newselectedcountryID });
  }

  render() {
    var countries = [];
    var categories = [];
    var selCountry = this.state.selectedCountry;

    for (var i = 0; i < this.state.emailCountries.length; i++) {
      var selected = i === selCountry - 1 ? "select" : "";
      countries.push(<div id={i} onClick={(event) => this.changeSelectedCountry(event)} key={i} className={'countryBox ' + selected}><span>{this.state.emailCountries[i].label}</span></div>);
    }

    var currentcategories = new EmailCategories();
    currentcategories = this.state.emailCategories[selCountry - 1];
    if (currentcategories !== undefined && currentcategories.dataRows !== undefined && currentcategories.dataRows !== null) {
      for (var j = 0; j < currentcategories.dataRows.length; j++) {
        categories.push(<div key={j} className={'category'}><input id={currentcategories.dataRows[j].id} type="checkbox" className="category_check" checked={currentcategories.dataRows[j].send} onChange={(event) => this.updateChangeNotifications(event)} /><span >{currentcategories.dataRows[j].name} </span>  </div>);
      }
    }

    return (
      <div className='notifications'>
        <h1>{'These settings control email alerts'}</h1>
        <div className='countrysection' >
          {countries}
        </div>
        <div className='catsection'>
          {categories}
        </div>
      </div>
    );
    
  }

}

NotificationComponent.propTypes = {

};

export default NotificationComponent;    