import React from 'react';
import PropTypes from 'prop-types';

import { valueTypes } from '../../../shared/helpers/value-handler';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';
import { NonProducingParticipant } from '../../../shared/components/models/gmatrix3-extension-non-producing-participant';

const OwnersTableComponent = (props) => {
  const { owners, onParticipantClick } = props;

  const getRow = (row, rowIndex) => {
    let operatorDisplayName = row.operatorDisplayName;
    if (typeof onParticipantClick === 'function' && typeof row.corporateId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onParticipantClick(row.corporateId);
      };
      operatorDisplayName = <a href="/" title="Show Details" onClick={onClick}>{operatorDisplayName}</a>;
    }

    return (
      <tr key={'ownership-row-' + rowIndex.toString()}>
        <td><span style={{ backgroundColor: row.color, display: 'inline-block', width: '18px', borderRadius: '4px', marginRight: '3px' }}>&nbsp;</span>{operatorDisplayName}</td>
        <td style={{ width: '10%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.equity)}</td>
        <td style={{ width: '10%' }}>{row.participantTier}</td>
        <td style={{ width: '10%' }}>{row.participantCategory}</td>
        <td style={{ width: '10%' }}>{row.coreStatus}</td>
        <td style={{ width: '10%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.netLiquidMmb)}</td>
        <td style={{ width: '10%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.netGasBcf)}</td>
        <td style={{ width: '10%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.netReservesMmboe)}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Participant</td>
          <td>Equity ({valueTypes.UnitString(valueTypes.equity)})</td>
          <td>Participant Tier</td>
          <td>Participant Category</td>
          <td>Core Status</td>
          <td>Net Liquid Resources ({valueTypes.UnitString(valueTypes.mmb)})</td>
          <td>Net Gas Resources ({valueTypes.UnitString(valueTypes.bcf)})</td>
          <td>Net Total Resources ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
        </tr>
      </thead>
      <tbody>
        {owners.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
}

OwnersTableComponent.propTypes = {
  owners: PropTypes.arrayOf(PropTypes.instanceOf(NonProducingParticipant)).isRequired,
  onParticipantClick: PropTypes.func
};

export default OwnersTableComponent;
