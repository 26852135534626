import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { appIcons, appIconSizes } from './app-icons';

const HelpButtonComponent = (props) => {
  return (
    <Button id="help-button" style={{ padding: '2px 0 1px 0', fontSize: '10px', marginTop: '7px', marginRight: '5px' }} title="Help">
      {props.showLabels && <span style={{ verticalAlign: 'super', paddingLeft: '5px', paddingRight: '5px' }}>Help</span>}
      {appIcons.getIcon(appIcons.Help, appIconSizes.md)}
    </Button>
  );
};

HelpButtonComponent.propTypes = {
  showLabels: PropTypes.bool.isRequired,
  right: PropTypes.bool.isRequired
};

HelpButtonComponent.defaultProps = {
  right: false
};

export default HelpButtonComponent;