import React from 'react';
import PropTypes from 'prop-types';

import { DisplayWell } from '../../../shared/components/models/gmatrix3-extension-display-well';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';
import { valueTypes } from '../../../shared/helpers/value-handler';
import { clickHandler } from '../../../helpers/link-helper';

const ActiveWellsTableComponent = (props) => {
  const { activeWells, onWellClick, onParticipantClick } = props;

  const getRow = (row, rowIndex) => {
    return (
      <tr key={'well-row-' + rowIndex.toString()}>
        <td style={{ width: '15%' }}>{clickHandler(row.wellName, row.wellId, onWellClick)}</td>
        <td style={{ width: '15%' }}>{row.class}</td>  
        <td style={{ width: '15%' }}>{clickHandler(row.originalOperatorName, row.originalOperatorId, onParticipantClick)}</td> 
        <td style={{ width: '15%' }}>{row.status}</td>
        <td style={{ width: '10%' }}>{row.startDate}</td>
        <td style={{ width: '20%' }}>{row.rig.rigName}</td>
        <td style={{ width: '10%' }}>{valueTypes.ValueOnly(valueTypes.usdMM, row.estimatedWellCost)}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Well Name</td>
          <td>Class</td>
          <td>Operator Name</td>
          <td>Well Status</td>
          <td>Start Date</td>
          <td>Rig Name</td>
          <td>Estimated Well Cost ({valueTypes.UnitString(valueTypes.usdMM)})</td>
        </tr>
      </thead>
      <tbody>
        {activeWells.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
}

ActiveWellsTableComponent.propTypes = {
  activeWells: PropTypes.arrayOf(PropTypes.instanceOf(DisplayWell)).isRequired,
  onWellClick: PropTypes.func,
  onParticipantClick: PropTypes.func,
};

export default ActiveWellsTableComponent;
