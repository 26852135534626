import { PointModel } from "./point-model";

class PolygonModel {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid \'obj\' param supplied to \'PolygonModel.ctor\''); }
    if (!Array.isArray(obj.coordinates)) { throw new Error('Invalid \'obj.coordinates\' param supplied to \'PolygonModel.ctor\''); }

    this.coordinates = obj.coordinates.map(obj => new PointModel(obj));
  }

  destroy() {

    Object.keys(this.coordinates).forEach(key => {
      this.coordinates[key].destroy();
    });

    delete this.coordinates;
  }


  get latLngs() { return this.coordinates.map(obj => obj.latLng); }
}

const defaultOptions = {
  borderWidth: 2,
  borderColor: 'red',
  fillColor: 'red',
  popup: undefined
};

class PolygonsModel {
  constructor(polygons, options = {}) {
    if (!Array.isArray(polygons)) { throw new Error('Invalid \'polygons\' param supplied to \'PolygonsModel.ctor\''); }
    if (typeof options !== 'object') { throw new Error('Invalid \'options\' supplied to \'PolygonModel.ctor\''); }

    this.polygons = polygons.map(obj => new PolygonModel(obj));
    this.options = { ...defaultOptions, ...options };
  }

  destroy() {
    Object.keys(this.polygons).forEach(key => {
      this.polygons[key].destroy();
    });

    delete this.polygons;
    delete this.options;
  }

  get latLngs() { return this.polygons.map(obj => obj.latLngs); }
}

export { PolygonModel, PolygonsModel };