import L from 'leaflet';
import '@fortawesome/fontawesome-pro/css/all.min.css';

const DISABLED_CLASS_NAME = 'leaflet-disabled';

const addEventHandler = (control, eventName, event) => {
  L.DomEvent
    .addListener(control, eventName, L.DomEvent.stopPropagation)
    .addListener(control, eventName, L.DomEvent.preventDefault)
    .addListener(control, eventName, event);
};

const createButton = (container, className, iconDef, onClick, options) => {
  const result = L.DomUtil.create('a', className, container);

  result.setAttribute('href', '#');
  result.setAttribute('role', 'button');

  if (iconDef !== undefined) {
    result.innerHTML = getButtonIcon(iconDef, options);
  }

  if (typeof onClick === 'function') {
    addEventHandler(result, 'click', onClick);
  }

  return result;
};

const setTitle = (control, title) => {
  control.setAttribute('title', title);
  control.setAttribute('aria-label', title);
};

const defaultIconOptions = {
  size: '2x',
  style: 'font-size: 14px',
  title: ''
};

const getIcon = (iconDef, options = {}) => {
  options = { ...defaultIconOptions, ...options };

  const result = L.DomUtil.create('i', iconDef.prefix + ' fa-' + iconDef.iconName + ' fa-' + options.size);
  result.style = options.style;
  setTitle(result, options.title);
  return result.outerHTML;
}

const getButtonIcon = (iconDef, options = {}) => {
  const result = getIcon(iconDef, options);
  return result;
};

const isIconDef = (iconDef) => {
  const result =
    typeof iconDef === 'object' &&
    typeof iconDef.icon === 'object' &&
    typeof iconDef.prefix === 'string' &&
    typeof iconDef.iconName === 'string' &&
    Array.isArray(iconDef.icon)
  return result;
};

const getObjectClassName = (obj) => {
  const result = typeof obj === 'object'
    ? obj.__proto__.constructor.name
    : '';
  return result;
};

function getMapEvents(map) {
  const result = {
    boxZoom: map.boxZoom.enabled() === true,
    dragging: map.dragging.enabled() === true,
    touchZoom: map.touchZoom.enabled() === true,
    doubleClickZoom: map.doubleClickZoom.enabled() === true,
    keyboard: map.keyboard.enabled() === true,
    scrollWheelZoom: map.scrollWheelZoom.enabled() === true
  };
  return result;
}

function restoreMapEvents(map, events) {
  if (map.boxZoom.enabled() !== events.boxZoom) {
    if (events.boxZoom) {
      map.boxZoom.enable();
    } else {
      map.boxZoom.disable();
    }
  }

  if (map.dragging.enabled() !== events.dragging) {
    if (events.dragging) {
      map.dragging.enable();
    } else {
      map.dragging.disable();
    }
  }

  if (map.touchZoom.enabled() !== events.touchZoom) {
    if (events.touchZoom) {
      map.touchZoom.enable();
    } else {
      map.touchZoom.disable();
    }
  }

  if (map.touchZoom.enabled() !== events.touchZoom) {
    if (events.touchZoom) {
      map.touchZoom.enable();
    } else {
      map.touchZoom.disable();
    }
  }

  if (map.doubleClickZoom.enabled() !== events.doubleClickZoom) {
    if (events.doubleClickZoom) {
      map.doubleClickZoom.enable();
    } else {
      map.doubleClickZoom.disable();
    }
  }

  if (map.keyboard.enabled() !== events.keyboard) {
    if (events.keyboard) {
      map.keyboard.enable();
    } else {
      map.keyboard.disable();
    }
  }

  if (map.scrollWheelZoom.enabled() !== events.scrollWheelZoom) {
    if (events.scrollWheelZoom) {
      map.scrollWheelZoom.enable();
    } else {
      map.scrollWheelZoom.disable();
    }
  }
}

const enableMapEvents = (map) => {
  map.boxZoom.enable();
  map.dragging.enable();
  map.touchZoom.enable();
  map.doubleClickZoom.enable();
  map.keyboard.enable();
  map.scrollWheelZoom.enable();
};

const disableMapEvents = (map) => {
  map.boxZoom.disable();
  map.dragging.disable();
  map.touchZoom.disable();
  map.doubleClickZoom.disable();
  map.keyboard.disable();
  map.scrollWheelZoom.disable();
};

function hasClass(control, className) {
  return L.DomUtil.hasClass(control, className);
};

function addClass(control, className) {
  if (!hasClass(control, className)) {
    L.DomUtil.addClass(control, className);
    return true;
  }
  return false;
}

function removeClass(control, className) {
  if (hasClass(control, className)) {
    L.DomUtil.removeClass(control, className);
    return true;
  }
  return false;
}

function toggleClass(control, className) {
  if (hasClass(control, className)) {
    removeClass(control, className);
  } else {
    addClass(control, className);
  }
}

export { DISABLED_CLASS_NAME, createButton, setTitle, getIcon, getButtonIcon, addEventHandler, isIconDef, getObjectClassName, enableMapEvents, disableMapEvents, hasClass, addClass, removeClass, toggleClass, getMapEvents, restoreMapEvents };
