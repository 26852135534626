import { MapShapeBorder } from '../shared/scss/app-colors';
import { valueTypes } from '../shared/helpers/value-handler';
import { CircleModel } from '../shared/helpers/leaflet/models/circle-model';

class Discovery {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Discovery.ctor'); }
    if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to Discovery.ctor'); }
    if (typeof obj.name !== 'string') { throw new Error('Invalid obj.name param supplied to Discovery.ctor'); }
    if (typeof obj.country !== 'string') { throw new Error('Invalid obj.country param supplied to Discovery.ctor'); }
    if (obj.area !== null && typeof obj.area !== 'string') { throw new Error('Invalid obj.area param supplied to Discovery.ctor'); }
    if (obj.operatorName !== null && typeof obj.operatorName !== 'string') { throw new Error('Invalid obj.operatorName param supplied to Discovery.ctor'); }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') { throw new Error('Invalid obj.operatorId param supplied to Discovery.ctor'); }
    if (obj.licence !== null && typeof obj.licence !== 'string') { throw new Error('Invalid obj.licence param supplied to Discovery.ctor'); }
    if (obj.licenceId !== null && typeof obj.licenceId !== 'number') { throw new Error('Invalid obj.licenceId param supplied to Discovery.ctor'); }
    if (obj.classification !== null && typeof obj.classification !== 'string') { throw new Error('Invalid obj.classification param supplied to Discovery.ctor'); }
    if (obj.reserves !== null && typeof obj.reserves !== 'number') { throw new Error('Invalid obj.reserves param supplied to Discovery.ctor'); }
    if (typeof obj.reservoirAgeEra !== 'string') { throw new Error('Invalid obj.reservoirAgeEra param supplied to Discovery.ctor'); }
    if (typeof obj.discoveryYear !== 'number') { throw new Error('Invalid obj.discoveryYear param supplied to Discovery.ctor'); }
    if (typeof obj.discoveryWell !== 'string') { throw new Error('Invalid obj.discoveryWell param supplied to Discovery.ctor'); }
    if (typeof obj.shape !== 'object') { throw new Error('Invalid obj.shape param supplied to Discovery.ctor'); }
    if (typeof obj.blockName !== 'string') { throw new Error('Invalid obj.blockName param supplied to Discovery.ctor'); }
    if (typeof obj.classificationRationale !== 'string') { throw new Error('Invalid obj.classificationRationale param supplied to Discovery.ctor'); }
    if (typeof obj.initialLiquidMmb !== 'number') { throw new Error('Invalid obj.initialLiquidMmb param supplied to Discovery.ctor'); }
    if (typeof obj.initialGasBcf !== 'number') { throw new Error('Invalid obj.initialGasBcf param supplied to Discovery.ctor'); }
    if (typeof obj.waterDepthFt !== 'number') { throw new Error('Invalid obj.initialGasBcf param supplied to Discovery.ctor'); }
    if (typeof obj.reservoirDepthFt !== 'number') { throw new Error('Invalid obj.reservoirDepthFt param supplied to Discovery.ctor'); }
    if (typeof obj.reservoirCondition !== 'string') { throw new Error('Invalid obj.reservoirCondition param supplied to Discovery.ctor'); }
    if (typeof obj.trapType !== 'string') { throw new Error('Invalid obj.trapType param supplied to Discovery.ctor'); }
    if (typeof obj.pressureStatus !== 'string') { throw new Error('Invalid obj.pressureStatus param supplied to Discovery.ctor'); }
    if (typeof obj.temperatureStatus !== 'string') { throw new Error('Invalid obj.temperatureStatus param supplied to Discovery.ctor'); }

    this.id = obj.id;
    this.name = obj.name;
    this.country = obj.country;
    this.area = obj.area !== null ? obj.area : 'N/A';
    this.operatorName = obj.operatorName !== null ? obj.operatorName : 'N/A';
    this.operatorId = obj.operatorId;
    this.licence = obj.licence !== null ? obj.licence : 'N/A';
    this.licenceId = obj.licenceId;
    this.classification = obj.classification !== null ? obj.classification : 'N/A';
    this.reserves = obj.reserves === null ? null : valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.reserves);
    this.reservoirAgeEra = obj.reservoirAgeEra;
    this.discoveryYear = obj.discoveryYear;
    this.discoveryWell = obj.discoveryWell;
    this.shape = new CircleModel(obj.shape.y, obj.shape.x, { borderColor: MapShapeBorder, fillColor: 'transparent', radius: 900 });
    this.blockName = obj.blockName;
    this.classificationRationale = obj.classificationRationale;
    this.initialLiquidMmb = obj.initialLiquidMmb;
    this.initialGasBcf = obj.initialGasBcf;
    this.waterDepthFt = obj.waterDepthFt;
    this.reservoirDepthFt = obj.reservoirDepthFt;
    this.reservoirCondition = obj.reservoirCondition;
    this.trapType = obj.trapType;
    this.pressureStatus = obj.pressureStatus;
    this.temperatureStatus = obj.temperatureStatus;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "Discovery.FromArray"');
    }

    const result = array.map(obj => new Discovery(obj));
    return result;
  }
}

export { Discovery };