import React from 'react';
import PropTypes from 'prop-types'

import AdvancedCorporateComponent from './body/advanced-corporate-component';

import { ContextLayerModel } from '../../shared/helpers/leaflet/leaflet-helper';
import { CorporateDetail } from '../../models/corporate-detail';
import { TrackingType } from '../../shared/helpers/enums';
import { downloadFile } from '../../shared/helpers/download';

const CorporateDetailComponent = (props) => {
  const { corporate, appSetState } = props;

  const onFieldClick = (resourceId) => {
    if (typeof resourceId !== 'number') {
      throw new Error('Invalid "resourceId" supplied to "CorporateDetailComponent.onFieldClick"');
    }
    appSetState.view.details.showField(resourceId);
  }

  const onHubClick = (hubId) => {
    if (typeof hubId !== 'number') {
      throw new Error('Invalid "hubId" supplied to "CorporateDetailComponent.onHubClick"');
    }
    appSetState.view.details.showHub(hubId);
  }

  const onDiscoveryClick = (resourceId) => {
    if (typeof resourceId !== 'number') {
      throw new Error('Invalid "resourceId" supplied to "CorporateDetailComponent.onDiscoveryClick"');
    }
    appSetState.view.details.showDiscovery(resourceId);
  }

  const onLicenceClick = (licenceId) => {
    if (typeof licenceId !== 'number') {
      throw new Error('Invalid "licenceId" supplied to "CorporateDetailComponent.onLicenceClick"');
    }
    appSetState.view.details.showLicence(licenceId);
  }

  const onBlockClick = (blockId) => {
    if (typeof blockId !== 'number') {
      throw new Error('Invalid "blockId" supplied to "CorporateDetailComponent.onBlockClick"');
    }
    appSetState.view.details.showBlock(blockId);
  }

  const onWellClick = (wellId) => {
    if (typeof wellId !== 'number') {
      throw new Error('Invalid "wellId" supplied to "CorporateDetailComponent.onWellClick"');
    }
    appSetState.view.details.showWell(wellId);
  }

  const onProspectClick = (resourceId) => {
    if (typeof resourceId !== 'number') {
      throw new Error('Invalid "resourceId" supplied to "CorporateDetailComponent.onProspectClick"');
    }
    appSetState.view.details.showProspect(resourceId);
  }

  const onReportClick = (reportId) => {
    if (typeof reportId !== 'number') {
      throw new Error('Invalid "reportId" param supplied to "LicenceDetailsComponent.onReportClick"');
    }

    downloadFile(reportId, appSetState, true, 0, appSetState.view.current, TrackingType.ReportDownload, props.atlasPanel);
  }

  const onCorporateClick = (corporateId) => {
    if (typeof corporateId !== 'number') {
      throw new Error('Invalid "corporateId" supplied to "FieldDetailsComponent.onCorporateClick"');
    }

    appSetState.view.details.showCorporate(corporateId);
  }

  return (
    <AdvancedCorporateComponent
      corporate={corporate}
      onFieldClick={onFieldClick}
      onHubClick={onHubClick}
      onDiscoveryClick={onDiscoveryClick}
      onLicenceClick={onLicenceClick}
      onBlockClick={onBlockClick}
      onWellClick={onWellClick}
      onProspectClick={onProspectClick}
      onReportClick={onReportClick}
      onCorporateClick={onCorporateClick}
      geoserverUrl={props.geoserverUrl}
      contextLayers={props.contextLayers}
      filtered={props.filtered}
      filterAvailable={props.filterAvailable}
      toggleFiltered={props.toggleFiltered}
      showSettings={props.showSettings}
      appSetState={appSetState}
      closeSettings={props.closeSettings}
      update={props.update}
      advancedPlus={props.advancedPlus}
      currentDetailValuationName={props.currentDetailValuationName}
      filter={props.filter}
      openSettings={props.openSettings}
    />
  );
};

CorporateDetailComponent.propTypes = {
  corporate: PropTypes.instanceOf(CorporateDetail).isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
  appSetState: PropTypes.object.isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  filtered: PropTypes.bool.isRequired,
  filterAvailable: PropTypes.bool.isRequired,  
  toggleFiltered: PropTypes.func,
  closeSettings: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired
};

export default CorporateDetailComponent;
