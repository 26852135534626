import * as XLSX from 'xlsx';

class BaseExcelExportColumn {
  _displayName = null;
  _fieldName = null;
  _format = null;

  constructor(displayName, fieldName, format = undefined) {
    this.displayName = displayName;
    this.fieldName = fieldName;
    this.format = format;
  }

  get displayName() { return this._displayName; }
  set displayName(value) {
    if (typeof value !== 'string' || value.trim().length === 0) {
      throw new Error('Invalid "value" param supplied to "BaseExcelExportColumn.displayName.set"');
    }

    this._displayName = value;
  }

  get fieldName() { return this._fieldName; }
  set fieldName(value) {
    if (typeof value !== 'string' || value.trim().length === 0) {
      throw new Error('Invalid "value" param supplied to "BaseExcelExportColumn.fieldName.set"');
    }

    this._fieldName = value;
  }

  get format() { return this._format; }
  set format(value) {
    const validValue = value === undefined || typeof value === 'function' || (typeof value === 'string' && value.trim().length > 0);
    if (!validValue) {
      throw new Error('Invalid "value" param supplied to "BaseExcelExportColumn.format.set"');
    }

    this._format = value;
  }
}

class BaseExcelExport {
  constructor() {
    this.HEADER_COUNT = 1;
    this.SUMMARY_SHEET_NAME = 'Summary';
  }

  _addText = (text, rows) => {
    const line = {};
    line[0] = text;
    rows.push(line);
  }

  createWorkbook() {
    const result = XLSX.utils.book_new();
    return result;
  }

  getAtlas5WorkbookPrefix() { return 'Atlas 5 - '; }
  getAtlas5WorkbookSuffix() { return '.xlsx'; }

  writeWorkbook(workbook, fileName, customMessages = null, selectedValuation = null, baseValuation = null) {
    if (typeof fileName !== 'string') {
      throw new Error('Invalid "fileName" param supplied to "BaseExcelExport.writeWorkbook"');
    }

    this._writeSummarySheet(workbook, customMessages, selectedValuation, baseValuation);

    XLSX.writeFile(workbook, fileName);
  }

  writeComparisonWorkbook(workbook, fileName, valuationOne, valuationTwo, baseValuation) {
    if (typeof fileName !== 'string') {
      throw new Error('Invalid "fileName" param supplied to "BaseExcelExport.writeComparisonWorkbook"');
    }

    this._writeComparisonSummarySheet(workbook, valuationOne, valuationTwo, baseValuation);

    XLSX.writeFile(workbook, fileName);
  }

  _writeSheetHeaders(dataRows, columns) {
    const line = {};
    for (var loop = 0; loop < columns.length; loop++) {
      const fieldName = columns[loop].fieldName;
      const displayName = columns[loop].displayName;
      line[fieldName] = displayName;
    }
    dataRows.unshift(line);
  }

  _writeAssumptionsAndTerms(date, rows) {
    this._addText('https://atlas.westwoodenergy.com/assumptions', rows);
    this._addText('Please review terms & conditions for usage of this data', rows);
    this._addText('Copyright Westwood Global Energy Group ' + date.getFullYear(), rows);

    this._addText('', rows);
  }

  _writeValuation(valuation, baseValuation, rows, isValuationOne) {
    let currentYear = valuation.currentYear;
    let valuationName = valuation.variableName + ' (Valuation ' + (isValuationOne ? 'One' : 'Two') + ')';

    let usingBasePrices = false;
    usingBasePrices = valuation === undefined || valuation === null || valuation.valuationAnnuals === undefined || valuation.valuationAnnuals === null || valuation.valuationAnnuals.length === 0 ? true : false;

    this._addText(valuationName, rows);
    this._addText('', rows);
    this._addText('Discount Year: ' + valuation.discountStartYear, rows);
    this._addText('Discount Rate: ' + valuation.discountRate + '%', rows);
    this._addText('', rows);
    this._addText('Liquid and Gas Price', rows);

    if (usingBasePrices) this._addText('The prices below are inherited from the Westwood Base Valuation.', rows);

    this._addText('', rows);

    let yearsHeader = {};

    yearsHeader[0] = 'Year';
    yearsHeader[1] = 'Liquid Price (US$/bbl)';
    yearsHeader[2] = 'Gas Price (US$/mcf)';

    rows.push(yearsHeader);

    const valuationAnnuals = usingBasePrices ? baseValuation.valuationAnnuals : valuation.valuationAnnuals;

    for (const item of valuationAnnuals) {
      if (item.year >= currentYear) {
        let yearsData = {};
        yearsData[0] = item.year;
        yearsData[1] = item.liquidPriceUsdBOE;
        yearsData[2] = item.gasPriceUsdMCF;

        rows.push(yearsData);
      }
    }

    if (valuation.scenario !== null && valuation.scenario.scenarioValues !== null) {
      this._addText('', rows);
      this._addText('Scenario', rows);
      if (valuation.isWGEVariable === true) this._addText('Scenario is not applicable to a Westwood Valuation.', rows);
      else if (valuation.scenario.isActive === false) this._addText('Scenario is not active.', rows);
      else {
        if (valuation.scenario.applyToAllYears === true) this._addText('Scenario values apply to all years.', rows);
        else this._addText('Scenario values apply from ' + valuation.scenario.yearFrom.toString() + ' to ' + valuation.scenario.yearTo.toString(), rows);

        this._addText('', rows);

        let scenarioHeader = {};
        scenarioHeader[0] = 'Scenario Attribute';
        scenarioHeader[1] = 'Attribute Value';

        rows.push(scenarioHeader);

        for (const item of valuation.scenario.scenarioValues) {
          let scenarioData = {};
          scenarioData[0] = item.attributeName + ' %';
          scenarioData[1] = item.attributeValue;

          rows.push(scenarioData);
        }
      }
    }
  }

  _writeSummarySheet(workbook, customMessages = null, selectedValuation = null, baseValuation = null) {

    if (customMessages !== null && !Array.isArray(customMessages)) {
      throw new Error('Invalid "customMessages" param supplied to "BaseExcelExport.writeSummarySheet"');
    }

    const date = new Date();

    workbook.SheetNames.unshift(this.SUMMARY_SHEET_NAME);
    let headerRows = [];

    const addText = (text) => {
      const line = {};
      line[0] = text;
      headerRows.push(line);
    }

    let usingBasePrices = false;
    usingBasePrices = selectedValuation === undefined || selectedValuation === null || selectedValuation.valuationAnnuals === undefined || selectedValuation.valuationAnnuals === null || selectedValuation.valuationAnnuals.length === 0 ? true : false;

    addText('Exported ' + date.toLocaleDateString('en-GB') + ' at ' + date.toLocaleTimeString('en-GB'));
    if (selectedValuation !== null && baseValuation !== null) {
      addText('Exported using the price & discount values listed below. Please refer to the Atlas Economic Assumptions and Methodology document for further detail:');
    } else {
      addText('Exported using Westwood\'s base price assumptions which can be found in the Atlas Economic Assumptions and Methodology document:');
    }

    this._writeAssumptionsAndTerms(date, headerRows);

    if (customMessages !== null && customMessages.length > 0) {
      for (var loop = 0; loop < customMessages.length; loop++) {
        addText(customMessages[loop]);
      }
      addText('');
    }

    if (selectedValuation !== null && baseValuation !== null) {
      let currentYear = selectedValuation.currentYear;

      addText('Valuation: ' + selectedValuation.variableName);
      addText('');
      addText('Discount Year: ' + selectedValuation.discountStartYear);
      addText('Discount Rate: ' + selectedValuation.discountRate + '%');

      addText('');
      addText('Liquid and Gas Price');

      if (usingBasePrices) addText('The prices below are inherited from the Westwood Base Valuation.');

      addText('');

      let yearsHeader = {};

      yearsHeader[0] = 'Year';
      yearsHeader[1] = 'Liquid Price (US$/bbl)';
      yearsHeader[2] = 'Gas Price (US$/mcf)';

      headerRows.push(yearsHeader);

      const valuationAnnuals = usingBasePrices ? baseValuation.valuationAnnuals : selectedValuation.valuationAnnuals;

      for (const item of valuationAnnuals) {
        if (item.year >= currentYear) {
          let yearsData = {};
          yearsData[0] = item.year;
          yearsData[1] = item.liquidPriceUsdBOE;
          yearsData[2] = item.gasPriceUsdMCF;

          headerRows.push(yearsData);
        }
      }

      if (selectedValuation.scenario !== null && selectedValuation.scenario.scenarioValues !== null) {
        addText('');
        addText('Scenario');
        if (selectedValuation.isWGEVariable === true) addText('Scenario is not applicable to a Westwood Valuation.');
        else if (selectedValuation.scenario.isActive === false) addText('Scenario is not active.');
        else {
          if (selectedValuation.scenario.applyToAllYears === true) addText('Scenario values apply to all years.');
          else addText('Scenario values apply from ' + selectedValuation.scenario.yearFrom.toString() + ' to ' + selectedValuation.scenario.yearTo.toString());

          addText('');

          let scenarioHeader = {};
          scenarioHeader[0] = 'Scenario Attribute';
          scenarioHeader[1] = 'Attribute Value';

          headerRows.push(scenarioHeader);

          for (const item of selectedValuation.scenario.scenarioValues) {
            let scenarioData = {};
            scenarioData[0] = item.attributeName + ' %';
            scenarioData[1] = item.attributeValue;

            headerRows.push(scenarioData);
          }
        }
      }
    }

    workbook.Sheets[this.SUMMARY_SHEET_NAME] = XLSX.utils.json_to_sheet(headerRows, { skipHeader: 1 });
  }

  _writeComparisonSummarySheet(workbook, valuationOne, valuationTwo, baseValuation) {
    const date = new Date();
    
    workbook.SheetNames.unshift(this.SUMMARY_SHEET_NAME);
    let headerRows = [];
    this._addText('Exported ' + date.toLocaleDateString('en-GB') + ' at ' + date.toLocaleTimeString('en-GB'), headerRows);
    this._addText('Exported using the two valuations listed below. Please refer to the Atlas Economic Assumptions and Methodology document for further detail:', headerRows);

    this._writeAssumptionsAndTerms(date, headerRows);

    if (valuationOne !== null && valuationTwo !== null && baseValuation !== null) {
      this._writeValuation(valuationOne, baseValuation, headerRows, true);
      this._addText('', headerRows);
      this._writeValuation(valuationTwo, baseValuation, headerRows, false);
    }

    workbook.Sheets[this.SUMMARY_SHEET_NAME] = XLSX.utils.json_to_sheet(headerRows, { skipHeader: 1 });
  }

  _formatDataRow(dataRow, columns) {
    const result = {};
    for (var loop = 0; loop < columns.length; loop++) {
      const fieldName = columns[loop].fieldName;
      result[fieldName] = dataRow[fieldName];
    }
    return result;
  }

  writeSheet(workbook, sheetName, dataRows, columns) {
    if (typeof sheetName !== 'string') {
      throw new Error('Invalid "sheetName" param supplied to "BaseExcelExport.writeSheet"');
    }
    if (!Array.isArray(dataRows)) {
      throw new Error('Invalid "dataRows" param supplied to "BaseExcelExport.writeSheet"');
    }
    if (!Array.isArray(columns) || columns.filter(obj => !(obj instanceof BaseExcelExportColumn)).length > 0) {
      throw new Error('Invalid "columns" param supplied to "BaseExcelExport.writeSheet"');
    }

    const outputDataRows = dataRows.map(obj => this._formatDataRow(obj, columns));
    this._writeSheetHeaders(outputDataRows, columns);
    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = XLSX.utils.json_to_sheet(outputDataRows, { skipHeader: 1 });

    const sheet = workbook.Sheets[sheetName];
    for (var colLoop = 0; colLoop < columns.length; colLoop++) {
      var colPrefix = '';
      var colLetter = colLoop;

      if (colLoop >= 26 && colLoop < 54) {
        colLetter = colLoop - 26;
        colPrefix = 'A';
      }
      else if (colLoop >= 54 && colLoop < 76) {
        colLetter = colLoop - 54;
        colPrefix = 'B';
      }

      const letter = colPrefix + String.fromCharCode(colLetter + 65); // 65 === 'A';
      const column = columns[colLoop];

      if (typeof column.format !== undefined) {
        for (var rowLoop = 0; rowLoop < dataRows.length + this.HEADER_COUNT; rowLoop++) {
          const cell = sheet[letter + (rowLoop + this.HEADER_COUNT)];
          if (cell !== undefined) {
            if (typeof column.format === 'string') { // Set cell format
              cell.z = column.format;
            } else if (typeof column.format === 'function') { // Set cell value
              cell.v = column.format(dataRows[rowLoop]);
            }
          }
        }
      }
    }

    return sheet;
  }
}

export { BaseExcelExportColumn, BaseExcelExport };
