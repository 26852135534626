import React from 'react';
import PropTypes from 'prop-types';

import OwnersTableComponent from '../../../components/fiscal/table/owners-table-component';
import { FasterPortfolio, FieldDetail } from '../../../components/fiscal/models/portfolio/faster-portfolio';
import NetProductionByParticipantComponent from '../../../components/fiscal/net-production-by-participant-component';
import NetRemainingReservesByParticipantComponent from '../../../components/fiscal/net-remaining-reserves-by-participant-component';
import { createSection100, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedParticipationComponent = (props) => {
  const { portfolio, resource } = props;

  const ownersTable = <OwnersTableComponent owners={resource.owners} currentYear={portfolio.currentYear} discountRate={resource.fiscalResource.cashflow.discountRate} onParticipantClick={props.onCorporateClick} />;
  const ownersNetReservesChart = <div style={{ height: '250px' }}><NetRemainingReservesByParticipantComponent owners={resource.owners} /></div>;
  const ownersProductionChart = <div style={{ height: '250px' }}><NetProductionByParticipantComponent owners={resource.owners} /></div>;

  return (
    <div ref={props.innerRef}>
      {createSection100(ownersTable, 'Participation')}
      {createSection([
        { content: ownersNetReservesChart, sectionType: sectionTypes.section33, title: 'Remaining reserves by participant' },
        { content: ownersProductionChart, sectionType: sectionTypes.section66, title: 'Future production by participant' }
      ])}
    </div>
  );
};

AdvancedParticipationComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  portfolio: PropTypes.instanceOf(FasterPortfolio).isRequired,
  resource: PropTypes.instanceOf(FieldDetail).isRequired,
  onCorporateClick: PropTypes.func
};

export default AdvancedParticipationComponent;