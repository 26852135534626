import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FieldDetail } from '../../../components/fiscal/models/portfolio/faster-portfolio';
import { createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import LastHubChangeComponent from '../../../components/fiscal/fact-components/last-hub-change-component';

const AdvancedLastUpdateComponent = (props) => {
  const { resource } = props;

  const clientChangeDescription = resource.fiscalResource.hub.clientChangeDescription;
  const clientChangeDescriptionDate = resource.fiscalResource.hub.clientChangeDescriptionDate;
  const hubChangeDateAmended = moment(clientChangeDescriptionDate).format('DD MMMM YYYY');
  const lastChange = clientChangeDescription !== null && clientChangeDescription !== '' ? <LastHubChangeComponent dateAmended={hubChangeDateAmended} textValue={clientChangeDescription} /> : null;
  const assumptionsSection = lastChange ? createSection100(lastChange, 'Last update') : null;

  return (
    <div ref={props.innerRef}>
      {assumptionsSection}
    </div>
  );
};

AdvancedLastUpdateComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  resource: PropTypes.instanceOf(FieldDetail).isRequired,
};

export default AdvancedLastUpdateComponent;
