import React from 'react';
import { Table } from 'reactstrap';

import './base-table-component.scss';
import { appendClassNames } from '../../helpers/common';

const BaseTableComponent = (props) => {
  return (
    <Table className={appendClassNames('base-table-component', props.className)} responsive borderless size="sm" >
      {props.children}
    </Table>
  );
};

export default BaseTableComponent;