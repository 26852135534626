import React from 'react';
import PropTypes from 'prop-types';

import GenericChart, { ChartType } from '../../../shared/components/generic-chart/generic-chart';
import { ChartData } from '../../../shared/components/generic-chart/chart-data';
import { createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import { valueTypes } from '../../../shared/helpers/value-handler';

const AdvancedEmissionsComponent = (props) => {
  const { fieldEmissions, hubEmissions } = props;
  let fieldEmissionsContent;
  let hubEmissionsContent;

  const hasFieldEmissionsData = fieldEmissions !== null && fieldEmissions !== undefined;
  const hasHubEmissionsData = hubEmissions !== null && hubEmissions !== undefined;

  const chartStyle = { height: '450px', display: 'inline-block', width: '100%' };

  const fieldEmissionsChart = hasFieldEmissionsData && (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={fieldEmissions}
          chartType={ChartType.Line}
          isXStacked={false}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.ktCO2)}
          showYAxisGridLines={true}
        />
      </div>
    </div>
  );

  const hubEmissionsChart = hasHubEmissionsData && (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hubEmissions}
          chartType={ChartType.Line}
          isXStacked={false}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.ktCO2)}
          showYAxisGridLines={true}
        />
      </div>
    </div>
  );

  if (hasFieldEmissionsData) {
    fieldEmissionsContent = createSection100(fieldEmissionsChart, 'Future CO2 emissions by field - Equity Share');
  } else {
    fieldEmissionsContent = createSection100(<div>No Future annual CO2 emissions by field - Equity Share information available</div>, 'Emissions');
  }

  if (hasHubEmissionsData) {
    hubEmissionsContent = createSection100(hubEmissionsChart, 'Future CO2 emissions by hub - Operational Control');
  } else {
    hubEmissionsContent = createSection100(<div>No Future annual CO2 emissions by hub - Operational Control information available</div>, 'Emissions');
  }

  return (
    <div ref={props.innerRef}>
      {fieldEmissionsContent}
      {hubEmissionsContent}
    </div>
  );
};

AdvancedEmissionsComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  fieldEmissions: PropTypes.instanceOf(ChartData),
  hubEmissions: PropTypes.instanceOf(ChartData),
};

AdvancedEmissionsComponent.defaultProps = {
  fieldEmissions: null,
  hubEmissions: null
};

export default AdvancedEmissionsComponent;
