import { objectToEnum } from '../../../shared/helpers/common';
import { valueTypes } from '../../../shared/helpers/value-handler';
import { BaseExcelExportColumn, BaseExcelExport } from '../../../shared/helpers/base-excel-export';

import { UserValuations } from '../../../models/user-valuations';

const exportType = objectToEnum({
    CashflowAnnuals: 0,
});

class FiscalBulkResourceExcelExport extends BaseExcelExport {
    constructor(fiscalResource, resourceData = null, resourceDetails = null) {
        //if (!(fiscalResource instanceof FiscalResource)) {
        //  throw new Error('Invalid "fiscalResource" param supplied to "FiscalBulkResourceExcelExport.ctor"');
        //}

        super();
        this._fiscalResource = fiscalResource;
        this._resourceData = resourceData;
        this._resourceDetails = resourceDetails;
    }

    exportAll(mapBaseExcelExport = null, mapWorkbook = null, sheetname = null, selectedValuation = null, baseValuation = null) {
        this._doExport(exportType.getValues(), mapBaseExcelExport, mapWorkbook, sheetname, selectedValuation, baseValuation);
    }

    exportCashflowAnnuals() {
        this.export([exportType.CashflowAnnuals]);
    }

    _doExport(exportTypes, mapBaseExcelExport, mapWorkbook, sheetname, selectedValuation, baseValuation) {
        if (!Array.isArray(exportTypes) || exportTypes.length === 0 || exportTypes.filter(obj => exportType.isInvalid(obj)).length > 0) {
            throw new Error('Invalid "exportTypes" param supplied to "FiscalBulkResourceExcelExport.export"');
        }
        if (selectedValuation !== null && !(selectedValuation instanceof UserValuations)) {
            throw new Error('Invalid "selectedValuation" param supplied to "FiscalBulkResourceExcelExport.export"');
        }
        if (baseValuation !== null && !(baseValuation instanceof UserValuations)) {
            throw new Error('Invalid "selectedValuation" param supplied to "FiscalBulkResourceExcelExport.export"');
        }

        const workbook = mapWorkbook !== null ? mapWorkbook : this.createWorkbook();

        if (this._resourceData !== null)
            this._doExportResourceData(workbook);

        if (this._resourceDetails !== null)
            this._doExportResourceDetails(workbook);

        if (exportTypes.includes(exportType.CashflowAnnuals)) {
            this._doExportCashflowAnnuals(workbook);
        }

        if (mapBaseExcelExport === null) this.writeWorkbook(workbook, this.getAtlas5WorkbookPrefix() + 'Fields' + this.getAtlas5WorkbookSuffix(), null, selectedValuation, baseValuation);
    }

    getMerged(fiscalResources) {
        return [].concat(fiscalResources.map(x => x.cashflow.cashflowAnnuals)).flat();
    }

    flatten(array) {
        return !Array.isArray(array) ? array : [].concat.apply([], array.map(this.flatten));
    }

    _doExportCashflowAnnuals(workbook) {
        const usdMM = valueTypes.UnitString(valueTypes.usdMM);

        const columns = [
          new BaseExcelExportColumn('Field', 'resourceName'),
          new BaseExcelExportColumn('Year', 'year'),
          new BaseExcelExportColumn('Liquid Price (' + valueTypes.UnitString(valueTypes.usdBOE) + ')', 'liquidPriceUsdBOE', '0.00'),
          new BaseExcelExportColumn('Gas Price (' + valueTypes.UnitString(valueTypes.usdMCF) + ')', 'gasPriceUsdMCF', '0.00'),
          new BaseExcelExportColumn('Liquid Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'liquidProductionMbod', '0.00'),
          new BaseExcelExportColumn('Gas Production (' + valueTypes.UnitString(valueTypes.mmscfd) + ')', 'gasProductionMmscfd', '0.00'),
          new BaseExcelExportColumn('Total Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'combinedMboed', '0.00'),
          new BaseExcelExportColumn('Revenue (' + usdMM + ')', 'totalRevenueUsdMM', '0.00'),
          new BaseExcelExportColumn('Opex (' + usdMM + ')', 'opexUsdMM', '0.00'),
          new BaseExcelExportColumn('Emissions charge (' + usdMM + ')', 'totalEmissionsChargeUsdMM', '0.00'),
          new BaseExcelExportColumn('Export tariff (' + usdMM + ')', 'totalTariffUsdMM', '0.00'),
          new BaseExcelExportColumn('Capex (' + usdMM + ')', 'costForLossesOrDepreciationUsdMM', '0.00'),
          new BaseExcelExportColumn('Abex (' + usdMM + ')', 'abexUsdMM', '0.00'),
          new BaseExcelExportColumn('Pre-tax cashflow (' + usdMM + ')', 'preTaxCashflowUsdMM', '0.00'),
          new BaseExcelExportColumn('Corporate tax (' + usdMM + ')', 'corporationTaxUsdMM', '0.00'),
          new BaseExcelExportColumn('Special tax (' + usdMM + ')', 'specialTaxUsdMM', '0.00'),
          new BaseExcelExportColumn('Undiscounted Post-tax cashflow (' + usdMM + ')', 'postTaxCashflowUsdMM', '0.00'),
          new BaseExcelExportColumn('Discounted Post-tax cashflow (' + usdMM + ')', 'discountedPostTaxCashflowUsdMM', '0.00')
        ];

        const _sheetname = 'Field annual cashflows';
        this.writeSheet(workbook, _sheetname, this._fiscalResource, columns);
    }

    _doExportResourceData(workbook) {
      const columns = [];
      columns.push(new BaseExcelExportColumn('Field Name', 'resourceName'));
      columns.push(new BaseExcelExportColumn('Country', 'countryName'));
      columns.push(new BaseExcelExportColumn('Area', 'areaName'));
      columns.push(new BaseExcelExportColumn('Hub', 'hubName'));
      columns.push(new BaseExcelExportColumn('Operator', 'operatorName'));
      columns.push(new BaseExcelExportColumn('Development Status', 'status'));
      columns.push(new BaseExcelExportColumn('COP Year', 'lastProductionYear'));
      columns.push(new BaseExcelExportColumn('Remaining Liquid\nReserves (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'remainingLiquidMMB', '0.00'));
      columns.push(new BaseExcelExportColumn('Remaining Gas\nReserves (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'remainingGasBCF', '0.00'));
      columns.push(new BaseExcelExportColumn('Remaining Reserves (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'remainingReservesMMBOE', '0.00'));
      columns.push(new BaseExcelExportColumn('Current Year Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'currentYearProductionBOEPD', '0.00'));
      columns.push(new BaseExcelExportColumn('Remaining Discounted Post Tax NPV (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'npv', '0.00'));
      columns.push(new BaseExcelExportColumn('Current Year CO2 Emissions (' + valueTypes.UnitString(valueTypes.ktCO2) + ')', 'currentYearCO2EmissionsKTCO2', '0.00'));
      
      const _sheetname = 'Field list';
      this.writeSheet(workbook, _sheetname, this._resourceData, columns);
    }

    _doExportResourceDetails(workbook) {
      const columns = [];

      columns.push(new BaseExcelExportColumn('Field Name', 'resourceName'));
      columns.push(new BaseExcelExportColumn('Country', 'countryName'));
      columns.push(new BaseExcelExportColumn('Area', 'areaName'));
      columns.push(new BaseExcelExportColumn('Hub', 'hubName'));
      columns.push(new BaseExcelExportColumn('Operator', 'operatorName'));
      columns.push(new BaseExcelExportColumn('Development Status', 'status'));
      columns.push(new BaseExcelExportColumn('Blocks', 'blocks'));
      columns.push(new BaseExcelExportColumn('Licences', 'licences'));
      columns.push(new BaseExcelExportColumn('Discovery Well Licence', 'licence'));
      columns.push(new BaseExcelExportColumn('Discovery Well', 'discoveryWell'));
      columns.push(new BaseExcelExportColumn('Discovery Year', 'discoveryYear'));
      columns.push(new BaseExcelExportColumn('Field Approval Year', 'fieldApprovalYear'));
      columns.push(new BaseExcelExportColumn('First Production Year', 'firstProductionYear'));
      columns.push(new BaseExcelExportColumn('COP/Expected COP Year', 'lastProductionYear'));
      columns.push(new BaseExcelExportColumn('Decommissioned/Expected Decommissioned Year', 'decommissionYear'));
      columns.push(new BaseExcelExportColumn('Liquid Export', 'oilPipeline'));
      columns.push(new BaseExcelExportColumn('Gas Export', 'gasPipeline'));
      columns.push(new BaseExcelExportColumn('Development Type', 'developmentConcept'));
      columns.push(new BaseExcelExportColumn('Total Recoverable Liquid (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'totalRecoverableLiquidMmb', '0.00'));
      columns.push(new BaseExcelExportColumn('Total Recoverable Gas (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'totalRecoverableGasBcf', '0.00'));
      columns.push(new BaseExcelExportColumn('Total Recoverable Reserves (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'totalRecoverableCombinedMmboe', '0.00'));
      columns.push(new BaseExcelExportColumn('Remaining Liquid (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'remainingLiquidMmb', '0.00'));
      columns.push(new BaseExcelExportColumn('Remaining Gas (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'remainingGasBcf', '0.00'));
      columns.push(new BaseExcelExportColumn('Remaining Reserves (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'remainingCombinedMmboe', '0.00'));

      columns.push(new BaseExcelExportColumn('Current year Production Liquid (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'currentYearLiquidProductionMbod', '0.00'));
      columns.push(new BaseExcelExportColumn('Current year Production Gas(' + valueTypes.UnitString(valueTypes.mmscfd) + ')', 'currentYearGasProductionMmscfd', '0.00'));
      columns.push(new BaseExcelExportColumn('Current year Total Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'currentYearTotalProductionMboepd', '0.00'));
      columns.push(new BaseExcelExportColumn('Current Year CO2 Emissions (' + valueTypes.UnitString(valueTypes.ktCO2) + ')', 'currentYearCO2EmissionsKTCO2', '0.00'));

      columns.push(new BaseExcelExportColumn('Remaining CAPEX (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'remainingCAPEX', '0.00'));
      columns.push(new BaseExcelExportColumn('Remaining OPEX (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'remainingOPEX', '0.00'));
      columns.push(new BaseExcelExportColumn('Remaining Tariff (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'remainingTariff', '0.00'));
      columns.push(new BaseExcelExportColumn('Remaining ABEX (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'remainingABEX', '0.00'));
      columns.push(new BaseExcelExportColumn('Remaining pre-tax revenue  (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'remainingPreTaxRevenue', '0.00'));
      columns.push(new BaseExcelExportColumn('Remaining pre-tax cashflow (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'remainingPreTaxCashflow', '0.00'));
      columns.push(new BaseExcelExportColumn('Remaining post-tax undiscounted cashflow (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'remainingPostTaxCashflow', '0.00'));//TODO
      columns.push(new BaseExcelExportColumn('Remaining discounted post-tax NPV (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'remainingNetPresetValue', '0.00'));

      columns.push(new BaseExcelExportColumn('Current year CAPEX (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'currentYearCAPEX', '0.00'));
      columns.push(new BaseExcelExportColumn('Current year OPEX (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'currentYearOpexUsdMM', '0.00'));
      columns.push(new BaseExcelExportColumn('Current year Tariff (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'currentYearTotalTariffUsdMM', '0.00'));
      columns.push(new BaseExcelExportColumn('Current year ABEX (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'currentYearAbexUsdMM', '0.00'));
      columns.push(new BaseExcelExportColumn('Current year pre-tax revenue (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'currentYearPreTaxRevenue', '0.00'));
      columns.push(new BaseExcelExportColumn('Current year pre-tax cashflow (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'currentYearPreTaxCashflowUsdMM', '0.00'));//TODO
      columns.push(new BaseExcelExportColumn('Current year post-tax undiscounted cashflow (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'currentYearPostTaxCashflowUsdMM', '0.00'));//TODO

      columns.push(new BaseExcelExportColumn('Reservoir Age Era', 'reservoirAge'));
      columns.push(new BaseExcelExportColumn('Reservoir Depth Ft', 'reservoirDepthFt', '0.00'));
      columns.push(new BaseExcelExportColumn('Trap Type', 'trapType'));
      columns.push(new BaseExcelExportColumn('Pressure Status', 'pressureStatus'));
      columns.push(new BaseExcelExportColumn('Temperature Status', 'temperatureStatus'));

      const _sheetname = 'Field details';
      this.writeSheet(workbook, _sheetname, this._resourceDetails, columns);
    }

}

export { FiscalBulkResourceExcelExport };
