import L from 'leaflet';

import { objectToEnum } from '../../shared/helpers/common';
import { CircleMarkerModel, searchResultType } from "../../shared/helpers/leaflet/leaflet-helper";

const ActivityDataRowType = objectToEnum({
  DiscoveryAnnouncement: 1,
  FieldApproval: 2,
  FieldStartUp: 3,
  WellApproval: 4,
  WellSpud: 5,
  WellCompletion: 6
}, false);

ActivityDataRowType.getDisplayName = (value) => {
  switch (value) {
    case ActivityDataRowType.DiscoveryAnnouncement: return 'Discovery: Announcement';
    case ActivityDataRowType.FieldApproval: return 'Field: Approval';
    case ActivityDataRowType.FieldStartUp: return 'Field: Start Up';
    case ActivityDataRowType.WellApproval: return 'Well: Approval';
    case ActivityDataRowType.WellSpud: return 'Well: Spud';
    case ActivityDataRowType.WellCompletion: return 'Well: Completion';
    default: throw new Error('Invalid "value" param supplied to "ActivityDataRowType.getDisplayName"');
  }
};

ActivityDataRowType.getColor = (value) => {
  switch (value) {
    case ActivityDataRowType.DiscoveryAnnouncement: return '#F114C8';
    case ActivityDataRowType.FieldApproval: return '#5CB587';
    case ActivityDataRowType.FieldStartUp: return '#FFFF00';
    case ActivityDataRowType.WellApproval: return '#8CFFFB';
    case ActivityDataRowType.WellSpud: return '#444F89';
    case ActivityDataRowType.WellCompletion: return '#00B0E6';
    default: throw new Error('Invalid "value" param supplied to "ActivityDataRowType.getColor"');
  }
};

ActivityDataRowType.defaultZoomLevel = (value) => {
  switch (value) {
    case ActivityDataRowType.DiscoveryAnnouncement: return searchResultType.defaultZoomLevel(searchResultType.Discovery);
    case ActivityDataRowType.FieldApproval:
    case ActivityDataRowType.FieldStartUp: return searchResultType.defaultZoomLevel(searchResultType.Field);
    case ActivityDataRowType.WellApproval:
    case ActivityDataRowType.WellSpud:
    case ActivityDataRowType.WellCompletion: return searchResultType.defaultZoomLevel(searchResultType.Well);
    default: throw new Error('Invalid "value" param supplied to "ActivityDataRowType.defaultZoomLevel"');
  }
};

Object.freeze(ActivityDataRowType);

const getShapeOptions = (activityDataRow, showDetails) => {
  const result = {
    borderColor: 'black',
    borderWidth: 1,
    fillColor: activityDataRow.color
  };

  result.popup = L.DomUtil.create('table', '');
  const body = L.DomUtil.create('tbody', '', result.popup);

  const createRow = (label, value) => {
    const tr = L.DomUtil.create('tr', '', body);

    const tdLabel = L.DomUtil.create('td', '', tr);
    tdLabel.style.paddingRight = '10px';
    tdLabel.innerHTML = label;

    const tdValue = L.DomUtil.create('td', '', tr);
    tdValue.style.fontWeight = 'bold';
    tdValue.innerHTML = value;
  };

  createRow('Category', activityDataRow.activityCategory);
  createRow('Type', activityDataRow.activityType);
  createRow('Entity', activityDataRow.activityEntity);
  createRow('Sub Type', activityDataRow.activitySubType);
  createRow(activityDataRow.activityCategory + ' Date', activityDataRow.activityDate.toLocaleDateString('en-GB'));
  createRow('Entity Info', activityDataRow.entityInfo);
  createRow('Country', activityDataRow.country);
  createRow('Operator', activityDataRow.operator);
  createRow('Area', activityDataRow.area);

  const trLink = L.DomUtil.create('tr', '', body);
  L.DomUtil.create('td', '', trLink);
  const tdLink = L.DomUtil.create('td', '', trLink);
  tdLink.style.paddingTop = '5px';
  const link = L.DomUtil.create('a', '', tdLink);
  link.innerHTML = 'Show more...';
  link.setAttribute('href', '#');
  link.addEventListener('click', (e) => { e.preventDefault(); showDetails(activityDataRow); });

  return result;
}

class ActivityDataRow {
  constructor(obj, showDetails) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" supplied to "ActivityDataRow.ctor"');
    }

    if (typeof showDetails !== 'function') {
      throw new Error('Invalid "showDetails" supplied to "ActivityDataRow.ctor"');
    }
    this.showDetails = showDetails;

    if (typeof obj.activityCategory !== 'string') {
      throw new Error('Invalid "activityCategory" supplied to "ActivityDataRow.ctor"');
    }
    this.activityCategory = obj.activityCategory;

    if (typeof obj.activityDate !== 'string') {
      throw new Error('Invalid "activityDate" supplied to "ActivityDataRow.ctor"');
    }
    this.activityDate = new Date(obj.activityDate);

    if (typeof obj.activityDateDescription !== 'string') {
      throw new Error('Invalid "activityDateDescription" supplied to "ActivityDataRow.ctor"');
    }
    this.activityDateDescription = obj.activityDateDescription;

    if (typeof obj.activityEntity !== 'string') {
      throw new Error('Invalid "activityEntity" supplied to "ActivityDataRow.ctor"');
    }
    this.activityEntity = obj.activityEntity;

    if (typeof obj.activitySubType !== 'string') {
      throw new Error('Invalid "activitySubType" supplied to "ActivityDataRow.ctor"');
    }
    this.activitySubType = obj.activitySubType;

    if (typeof obj.activityType !== 'string') {
      throw new Error('Invalid "activityType" supplied to "ActivityDataRow.ctor"');
    }
    this.activityType = obj.activityType;

    if (typeof obj.analyticsSubscribed !== 'number') {
      throw new Error('Invalid "analyticsSubscribed" supplied to "ActivityDataRow.ctor"');
    }
    this.analyticsSubscribed = obj.analyticsSubscribed;

    if (typeof obj.area !== 'string') {
      throw new Error('Invalid "area" supplied to "ActivityDataRow.ctor"');
    }
    this.area = obj.area;

    if (typeof obj.country !== 'string') {
      throw new Error('Invalid "country" supplied to "ActivityDataRow.ctor"');
    }
    this.country = obj.country;

    if (typeof obj.countryId !== 'number') {
      throw new Error('Invalid "countryId" supplied to "ActivityDataRow.ctor"');
    }
    this.countryId = obj.countryId;

    if (typeof obj.documentId !== 'number') {
      throw new Error('Invalid "documentId" supplied to "ActivityDataRow.ctor"');
    }
    this.documentId = obj.documentId;

    if (obj.documentIsSubscribed !== null && typeof obj.documentIsSubscribed !== 'boolean') {
      throw new Error('Invalid "documentIsSubscribed" supplied to "ActivityDataRow.ctor"');
    }
    this.documentIsSubscribed = obj.documentIsSubscribed === null ? null : obj.documentIsSubscribed;

    if (typeof obj.entityInfo !== 'string') {
      throw new Error('Invalid "entityInfo" supplied to "ActivityDataRow.ctor"');
    }
    this.entityInfo = obj.entityInfo;

    if (typeof obj.gmEntityId !== 'number') {
      throw new Error('Invalid "gmEntityId" supplied to "ActivityDataRow.ctor"');
    }
    this.gmEntityId = obj.gmEntityId;

    if (typeof obj.groupId !== 'number') {
      throw new Error('Invalid "groupId" supplied to "ActivityDataRow.ctor"');
    }
    this.groupId = obj.groupId;

    if (typeof obj.header !== 'string') {
      throw new Error('Invalid "header" supplied to "ActivityDataRow.ctor"');
    }
    this.header = obj.header;

    if (typeof obj.headline !== 'string') {
      throw new Error('Invalid "headline" supplied to "ActivityDataRow.ctor"');
    }
    this.headline = obj.headline;

    if (typeof obj.operator !== 'string') {
      throw new Error('Invalid "operator" supplied to "ActivityDataRow.ctor"');
    }
    this.operator = obj.operator;

    if (typeof obj.rowCount !== 'number') {
      throw new Error('Invalid "rowCount" supplied to "ActivityDataRow.ctor"');
    }
    this.rowCount = obj.rowCount;

    if (typeof obj.shape !== 'object') {
      throw new Error('Invalid "obj.shape" param supplied to "ActivityDataRow.ctor"');
    }
    this.shape = new CircleMarkerModel(obj.shape.y, obj.shape.x, getShapeOptions(this, showDetails));
  }

  get type() {
    if (this.activityCategory === 'Well') {
      if (this.activityType === 'Approval') {
        return ActivityDataRowType.WellApproval;
      } else if (this.activityType === 'Spud') {
        return ActivityDataRowType.WellSpud;
      } else if (this.activityType === 'Completion') {
        return ActivityDataRowType.WellCompletion;
      }
    } else if (this.activityCategory === 'Discovery') {
      if (this.activityType === 'Announcement') {
        return ActivityDataRowType.DiscoveryAnnouncement;
      }
    } else if (this.activityCategory === 'Field') {
      if (this.activityType === 'Approval') {
        return ActivityDataRowType.FieldApproval;
      } else if (this.activityType === 'Start Up') {
        return ActivityDataRowType.FieldStartUp;
      }
    }
    throw new Error('Unable to return a value from "ActivityDataRowType.type" for "' + this.activityCategory + ' - ' + this.activityType + '"');
  }

  get color() {
    return ActivityDataRowType.getColor(this.type);
  }

  static FromArray(array, showDetails) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "ActivityDataRow.FromArray"');
    }

    const result = array.map(obj => new ActivityDataRow(obj, showDetails));
    return result;
  }
}

export { ActivityDataRowType, ActivityDataRow };