import { showError } from '../shared/helpers/common';

class ResourcePlannedWell {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('PlannedWell', 'PlannedWell'); }
    if (typeof obj.wellId !== 'number') { showError('PlannedWell', 'wellId'); }
    if (typeof obj.well !== 'string') { showError('PlannedWell', 'well'); }
    if (typeof obj.country !== 'string') { showError('PlannedWell', 'country'); }
    if (typeof obj.area !== 'string') { showError('PlannedWell', 'area'); }
    if (typeof obj.typeName !== 'string') { showError('PlannedWell', 'typeName'); }
    if (typeof obj.statusName !== 'string') { showError('PlannedWell', 'statusName'); }
    if (typeof obj.operatorId !== 'number') { showError('PlannedWell', 'operatorId'); }
    if (typeof obj.operatorName !== 'string') { showError('PlannedWell', 'operatorName'); }
    if (typeof obj.estimatedTiming !== 'string') { showError('PlannedWell', 'estimatedTiming'); }
    if (typeof obj.blockId !== 'number') { showError('PlannedWell', 'blockId'); }
    if (typeof obj.block !== 'string') { showError('PlannedWell', 'block'); }

    this.wellId = obj.wellId;
    this.well = obj.well;
    this.country = obj.country;
    this.area = obj.area;
    this.typeName = obj.typeName;
    this.statusName = obj.statusName;
    this.operatorId = obj.operatorId;
    this.operatorName = obj.operatorName;
    this.estimatedTiming = obj.estimatedTiming;
    this.blockId = obj.blockId;
    this.block = obj.block;
  }

}

export default ResourcePlannedWell 