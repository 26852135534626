import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { appIcons, appIconSizes } from '../app/app-icons';

const HeaderButtonComponent = (props) => {
  return (
    <Button title={props.title} disabled={props.disabled === true} onClick={props.onClick} key={props.key} size="sm">
      {appIcons.getIcon(props.iconDef, props.iconSize, props.iconSolid === true, props.colour)}
    </Button>
  );
};

HeaderButtonComponent.propTypes = {
  iconDef: appIcons.propType,
  iconSize: appIconSizes.propType,
  iconSolid: PropTypes.bool,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  key: PropTypes.string,
  colour: PropTypes.string,
};

export default HeaderButtonComponent;