import React from 'react';
import PropTypes from 'prop-types';

import { ProspectRow } from '../../../models/block-detail';

import { valueTypes } from '../../../shared/helpers/value-handler';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const ProspectsTableComponent = (props) => {
  const { prospects, onProspectClick, onOperatorClick } = props;

  const getRow = (row, rowIndex) => {
    let name = row.name;
    if (typeof onProspectClick === 'function' && typeof row.id === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onProspectClick(row.id);
      };
      name = <a href="/" onClick={onClick}>{name}</a>;
    }

    let operatorName = row.operatorName;
    if (typeof onOperatorClick === 'function' && typeof row.operatorId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onOperatorClick(row.operatorId);
      };
      operatorName = <a href="/" onClick={onClick}>{operatorName}</a>;
    }

    return (
      <tr key={'prospect-row-' + rowIndex.toString()}>
        <td style={{ width: '25%' }}>{name}</td>
        <td style={{ width: '25%' }}>{row.identifier}</td>
        <td style={{ width: '25%' }}>{operatorName}</td>
        <td style={{ width: '25%' }}>{valueTypes.ValueOnly(valueTypes.mmmboe, row.combinedMmboe)}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Prospect Identifier</td>
          <td>Prospect Name</td>
          <td>Operator</td>
          <td>Unrisked Resource ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
        </tr>
      </thead>
      <tbody>
        {prospects.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
}

ProspectsTableComponent.propTypes = {
  prospects: PropTypes.arrayOf(PropTypes.instanceOf(ProspectRow)).isRequired,
  onProspectClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default ProspectsTableComponent;
