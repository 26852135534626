import React from 'react';
import PropTypes from 'prop-types';

import ReservesFactsComponent from './reserves-and-production/reserves-facts-component';
import ProductionFactsComponent from './reserves-and-production/production-facts-component';

import { valueTypes } from '../../../shared/helpers/value-handler';
import { HubDetail } from '../../../models/hub-detail';
import GenericChart, { ChartType } from '../../../shared/components/generic-chart/generic-chart';
import { createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedReservesAndProductionComponent = (props) => {
  const { hub } = props;

  const chartStyle = { height: '250px' };

  const reserves = (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.hubReserves}
          chartType={ChartType.HorizontalBar}
          isXStacked={true}
          isYStacked={true}
          showXAxisLabel={true}
          xAxisLabel={valueTypes.UnitString(valueTypes.mmmboe)}
          showXAxisGridLines={true}
        />
      </div>
      <br />
      <ReservesFactsComponent
        hub={hub}
      />
    </div>
  );

  const production = (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.hubProduction}
          chartType={ChartType.Line}
          isXStacked={false}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.mboed)}
          showYAxisGridLines={true}
        />
      </div>
      <br />
      <ProductionFactsComponent
        annuals={hub.hubAnnuals}
        currentYear={hub.currentYear}
        peakYear={hub.peakCombinedProductionYear}
      />
    </div>
  );

  return (
    <div ref={props.innerRef}>
      {createSection([
        { content: reserves, sectionType: sectionTypes.section40, title: 'Reserves' },
        { content: production, sectionType: sectionTypes.section60, title: 'Production' }
      ])}
    </div>
  );
};

AdvancedReservesAndProductionComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  hub: PropTypes.instanceOf(HubDetail).isRequired
};

export default AdvancedReservesAndProductionComponent;
