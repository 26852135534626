import { objectToEnum } from "../../helpers/common";

const settingsStates = objectToEnum({
  Hidden: 0,
  Closed: 1,
  Open: 2,
  Pinned: 3,
  PinnedOverlay: 4
});

class SettingsStateHelper {
  // PL - I really don't like this class. Don't know why I did it this way

  constructor(getValue, setValue, showSettingsOnHover, onChanged = undefined) {
    if (typeof getValue !== 'function') {
      throw new Error('Supplied \'getValue\' param to \'SettingsStateHelper.ctor\' is invalid');
    }
    if (typeof setValue !== 'function') {
      throw new Error('Supplied \'setValue\' param to \'SettingsStateHelper.ctor\' is invalid');
    }
    if (typeof showSettingsOnHover !== 'boolean') {
      throw new Error('Supplied \'showSettingsOnHover\' param to \'SettingsStateHelper.ctor\' is invalid');
    }
    if (onChanged !== undefined && typeof onChanged !== 'function') {
      throw new Error('Supplied \'onChanged\' param to \'SettingsStateHelper.ctor\' is invalid');
    }

    this.getValue = getValue;

    const _setValue = (value) => {
      setValue(value);
      if (typeof onChanged === 'function') {
        onChanged(value);
      }
    };

    const _setValueDelayed = (value) => {
      //TODO: import { Timer } from '../../helpers/timer';
      //TODO: this.timer = new Timer();
      //TODO: this.tmpValue = undefined;
      _setValue(value);
    };

    this.set = (value, force = false) => {
      const currValue = this.getValue();
      if (currValue === value) {
        return;
      }

      if (force === true) {
        _setValue(value);
        return;
      }

      if (currValue === settingsStates.Pinned || currValue === settingsStates.PinnedOverlay) {
        return;
      }

      if (value === settingsStates.Open && showSettingsOnHover === false) {
        return;
      }

      if (value === settingsStates.Open || value === settingsStates.Closed) {
        _setValueDelayed(value);
        return;
      }

      _setValue(value);
    }
  }

  show = () => {
    this.set(settingsStates.Open);
  }

  hide = () => {
    this.set(settingsStates.Closed);
  }

  forceHide = () => {
    this.set(settingsStates.Closed, true);
  }

  togglePinned = () => {
    const value = this.getValue() !== settingsStates.Pinned ? settingsStates.Pinned : settingsStates.Closed;
    this.set(value, true);
  }
}

class ViewModel {
  constructor(id, label, largeIcon, smallIcon, selectedLargeIcon = null, selectedSmallIcon = null, isGroup = false, groupId = null) {
    if (typeof id !== 'number') {
      throw new Error('Supplied \'id\' param to \'MainView.ctor\' is invalid');
    }
    if (typeof label !== 'string') {
      throw new Error('Supplied \'label\' param to \'MainView.ctor\' is invalid');
    }
    if (typeof largeIcon !== 'object') {
      throw new Error('Supplied \'largeIcon\' param to \'MainView.ctor\' is invalid');
    }
    if (typeof smallIcon !== 'object') {
      throw new Error('Supplied \'smallIcon\' param to \'MainView.ctor\' is invalid');
    }
    if (selectedLargeIcon !== null && typeof selectedLargeIcon !== 'object') {
      throw new Error('Supplied \'selectedLargeIcon\' param to \'MainView.ctor\' is invalid');
    }
    if (selectedSmallIcon !== null && typeof selectedSmallIcon !== 'object') {
      throw new Error('Supplied \'selectedSmallIcon\' param to \'MainView.ctor\' is invalid');
    }
    if (typeof isGroup !== 'boolean') {
      throw new Error('Supplied \'isGroup\' param to \'MainView.ctor\' is invalid');
    }
    if (groupId !== null && typeof groupId !== 'number') {
      throw new Error('Supplied \'groupId\' param to \'MainView.ctor\' is invalid');
    }

    this.id = id;
    this.label = label;
    this.largeIcon = largeIcon;
    this.smallIcon = smallIcon;
    this.selectedLargeIcon = selectedLargeIcon === null ? this.largeIcon : selectedLargeIcon;
    this.selectedSmallIcon = selectedSmallIcon === null ? this.smallIcon : selectedSmallIcon;
    this.isGroup = isGroup;
    this.groupId = groupId;
  }
}

export { settingsStates, SettingsStateHelper, ViewModel };