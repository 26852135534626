import React from 'react';
import PropTypes from 'prop-types'

import DetailsComponent from './body/details-component';
import LocationComponent from './body/location-component';
import ResourcesComponent from './body/resources-component';
import OwnersTableComponent from './body/owners-table-component';

import { ProspectDetail } from '../../models/prospect-detail';
import { assignColours } from '../../shared/helpers/array-color-helper';
import MapComponent from '../../components/fiscal/map/map-component';
import OwnersPieChartComponent from '../../shared/components/owners-pie-chart/owners-pie-chart-component';
import { searchResultType, ContextLayerModel, latLng } from '../../shared/helpers/leaflet/leaflet-helper';
import InitialReservesByParticipantChart from '../../shared/components/initial-reserves-by-owner-chart/initial-reserves-by-participant-component';
import DetailViewGridLayoutComponent, { createSection100, createSection, sectionTypes } from '../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import PlannedWellsTableComponent from '../shared/planned-wells-table-component.js';
import ActiveWellsTableComponent from './body/active-wells-table-component';

const ProspectDetailComponent = (props) => {
  const { prospect, appSetState } = props;

  const onWellClick = (wellId) => {
    if (typeof wellId !== 'number') {
      throw new Error('Invalid "wellId" supplied to "DiscoveryDetailsComponent.onWellClick"');
    }

    appSetState.view.details.showWell(wellId);
  }

  const onBlockClick = (blockId) => {
    if (typeof blockId !== 'number') {
      throw new Error('Invalid "blockId" supplied to "ProspectDetailsComponent.onBlockClick"');
    }

    appSetState.view.details.showBlock(blockId);
  };

  const onLicenceClick = (licenceId) => {
    if (typeof licenceId !== 'number') {
      throw new Error('Invalid "licenceId" supplied to "ProspectDetailsComponent.onLicenceClick"');
    }

    appSetState.view.details.showLicence(licenceId);
  };

  const onCorporateClick = (corporateId) => {
    if (typeof corporateId !== 'number') {
      throw new Error('Invalid "corporateId" supplied to "ProspectDetailsComponent.onCorporateClick"');
    }

    appSetState.view.details.showCorporate(corporateId);
  };

  const hasOwners = prospect.owners && prospect.owners.length > 0;
  const hasPlannedWells = prospect.plannedWells && prospect.plannedWells.length > 0;
  const hasActiveWells = prospect.activeWells && prospect.activeWells.length > 0;

  assignColours(prospect.owners, 'color');

  const leftPanel = (
    <React.Fragment>
      {createSection([
        { content: <LocationComponent resource={prospect} onBlockClick={onBlockClick} onLicenceClick={onLicenceClick} />, sectionType: sectionTypes.section50, title: 'Location', style: { marginRight: '5px' } },
        { content: <ResourcesComponent resource={prospect} />, sectionType: sectionTypes.section50, title: 'Unrisked resources', style: { marginLeft: '5px' } }
      ])}
      {createSection100(<DetailsComponent resource={prospect} />, 'Details')}
    </React.Fragment>
  );
  const rightPanel = <div style={{ height: '100%' }}><MapComponent geoserverUrl={props.geoserverUrl} contextLayers={props.contextLayers} center={latLng(prospect.shape.lat, prospect.shape.lng)} zoom={searchResultType.defaultZoomLevel(searchResultType.Prospect)} shapes={[prospect.shape]} /></div>;

  return (
    <DetailViewGridLayoutComponent>
      {createSection([
        { content: leftPanel, sectionType: sectionTypes.section50, noMarginVertical: true },
        { content: rightPanel, sectionType: sectionTypes.section50 }
      ])}
      {hasOwners && createSection([
        { content: <div style={{ height: '250px' }}><OwnersPieChartComponent owners={prospect.owners} /></div>, sectionType: sectionTypes.section50, title: 'Participation' },
        { content: <div style={{ height: '250px' }}><InitialReservesByParticipantChart owners={prospect.owners} /></div>, sectionType: sectionTypes.section50, title: 'Net resources by participant' }
      ])}
      {hasOwners && createSection100(<OwnersTableComponent owners={prospect.owners} onParticipantClick={onCorporateClick} />)}
      {hasPlannedWells && createSection100(<PlannedWellsTableComponent plannedWells={prospect.plannedWells} onWellClick={onWellClick} onParticipantClick={onCorporateClick} onBlockClick={onBlockClick} />, 'Planned E&A wells')}
      {hasActiveWells && createSection100(<ActiveWellsTableComponent activeWells={prospect.activeWells} onWellClick={onWellClick} onParticipantClick={onCorporateClick} />, 'Active E&A wells')}
      
    </DetailViewGridLayoutComponent>
  );
};

ProspectDetailComponent.propTypes = {
  prospect: PropTypes.instanceOf(ProspectDetail).isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
  appSetState: PropTypes.instanceOf(Object).isRequired, 
  geoserverUrl: PropTypes.string.isRequired
};

export default ProspectDetailComponent;

//NB - get backend ready as want to merge fiscal changes in before changing the wellstabel compoent used in disco details component
