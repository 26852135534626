import { objectToEnum } from "../../helpers/common";

const LoadingImage = objectToEnum({
  Loading: 0,
  Logo: 1,
  Save: 2,
}, false);

LoadingImage.getImageSrc = (value) => {
  switch (value) {
    case LoadingImage.Loading: return '/images/loading.gif';
    case LoadingImage.Logo: return '/images/wge-logo.svg';
    case LoadingImage.Save: return '/images/save.gif';
    default: throw new Error('Invalid "value" param supplied to "LoadingImage.getImage"');
  }
};

Object.freeze(LoadingImage);

export { LoadingImage };