import React from 'react';
import PropTypes from 'prop-types';

import DetailsComponent from './details-component';
import LocationComponent from './location-component';
import StandardWarningComponent from '../../standard-warning-component';

import { appViews } from '../../../app/app-views';
import { DiscoveryDetail } from '../../../models/discovery-detail';
import MapComponent from '../../../components/fiscal/map/map-component';
import { ContextLayerModel, latLng, searchResultType } from '../../../shared/helpers/leaflet/leaflet-helper';
import OwnersPieChartComponent from '../../../shared/components/owners-pie-chart/owners-pie-chart-component';
import DetailViewGridLayoutComponent, { createSection100, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const StandardComponent = (props) => {
  const { discovery } = props;
  const hasOwners = discovery.owners && discovery.owners.length > 0;

  const leftPanel = (
    <React.Fragment>
      {createSection100(<LocationComponent resource={discovery} onBlockClick={props.onBlockClick} onLicenceClick={props.onLicenceClick} />, 'Location')}
      {createSection100(<DetailsComponent resource={discovery} onWellClick={props.onWellClick} />, 'Details')}
      {hasOwners && createSection100(<div style={{ height: '200px' }}><OwnersPieChartComponent owners={discovery.owners} /></div>, 'Participation')}
    </React.Fragment>
  );
  const rightPanel = <div style={{ height: '100%' }}><MapComponent geoserverUrl={props.geoserverUrl} contextLayers={props.contextLayers} center={latLng(discovery.shape.lat, discovery.shape.lng)} zoom={searchResultType.defaultZoomLevel(searchResultType.Discovery)} shapes={[discovery.shape]} /></div>;

  return (
    <DetailViewGridLayoutComponent>
      {createSection100(<StandardWarningComponent appView={props.appView} />)}
      {createSection([
        { content: leftPanel, sectionType: sectionTypes.section50, noMarginVertical: true },
        { content: rightPanel, sectionType: sectionTypes.section50 }
      ])}
    </DetailViewGridLayoutComponent>
  );
}

StandardComponent.propTypes = {
  appView: appViews.propType,
  discovery: PropTypes.instanceOf(DiscoveryDetail).isRequired,
  onBlockClick: PropTypes.func,
  onWellClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  geoserverUrl: PropTypes.string.isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired
};

export default StandardComponent;
