import React from 'react';
import PropTypes from 'prop-types';

import './detail-component.scss';
import { DetailModel } from './detail-model';

import MessageBoxComponent, { MessageBoxIcon, MessageBoxButton } from '../shared/components/message-box/message-box-component';

class DetailComponent extends React.Component {
  constructor(props) {
    super(props);

    this.props.detailModel.forceUpdate = this._forceUpdate;

    this.props.appSetState.appValuation.clearDetailValuation();
    this._backStack = [];
    this._forwardStack = [];

    this.state = {
      fullScreen: false,
      current: this.props.detailModel
    };
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.detailModel !== this.props.detailModel) {
      this.props.detailModel.forceUpdate = this._forceUpdate;

      if (prevState.current.matches(this.props.detailModel)) {
        return;
      }

      let current = this.props.detailModel;
      let idx = this._backStack.findIndex(obj => obj.matches(current))
      if (idx !== -1) {
        current = this._backStack[idx];
        this._backStack.splice(idx, 1);
        this._forwardStack.push(prevState.current);
      } else {
        idx = this._forwardStack.findIndex(obj => obj.matches(current))
        if (idx !== -1) {
          current = this._forwardStack[idx];
          this._forwardStack.splice(idx, 1);
          this._backStack.push(prevState.current);
        } else {
          this._backStack.push(prevState.current);
          this._forwardStack.length = 0;
        }
      }

      this.setState({ current: current }, this.load);
    }
  }

  load = () => {
    const { current } = this.state;

    if (!current.loaded) {
      current.load(this.props.appSetState, this.onLoadComplete);
    }
  }

  onLoadComplete = (callback = undefined) => {
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "DetailComponent.forceUpdate"');
    }

    this.forceUpdate(callback);
  }

  setFullScreen = (value) => {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "DetailComponent.setFullScreen"');
    }

    this.setState(prevState => {
      return prevState.fullScreen === value
        ? null
        : { fullScreen: value };
    });
  }

  backClick = () => {
    this.setState(prevState => {
      this._forwardStack.push(prevState.current);
      return { current: this._backStack.pop() };
    });
  }

  forwardClick = () => {
    this.setState(prevState => {
      this._backStack.push(prevState.current);
      return { current: this._forwardStack.pop() };
    });
  }

  _forceUpdate = (callback = undefined) => {
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "DetailComponent._forceUpdate"');
    }

    this.forceUpdate(callback);
  }

  render() {
    const { close } = this.props;
    const { current } = this.state;

    const header = current.getHeaderComponent(
      this.props.appSetState,
      this._backStack.length > 0 ? this.backClick : null,
      this._forwardStack.length > 0 ? this.forwardClick : null,
      this.state.fullScreen,
      this.setFullScreen,
      close
    );

    const body = current.getBodyComponent(
      this.props.appSetState,
      this.props.geoserverUrl
    );

    const message = {
      header: header,
      body: body,
      buttons: [MessageBoxButton.Close],
      callback: close,
      icon: MessageBoxIcon.None,
      size: this.state.fullScreen ? 'full-screen' : 'xl'
    };

    return <MessageBoxComponent className="detail-component" message={message} clear={close} />;
  }
}

DetailComponent.propTypes = {
  appSetState: PropTypes.instanceOf(Object).isRequired,
  detailModel: PropTypes.instanceOf(DetailModel).isRequired,
  close: PropTypes.func.isRequired,
  geoserverUrl: PropTypes.string.isRequired
};

export default DetailComponent;
export { DetailModel };