import { DisplayParticipant } from './gmatrix3-display-participant';

class CombinedProduction {
  constructor(combinedProductionObj) {
    if (typeof combinedProductionObj !== 'object') {
      throw new Error('Invalid combinedProductionObj param supplied to CombinedProduction.ctor');
    }
    if (typeof combinedProductionObj.year !== 'number') { throw new Error('Invalid combinedProductionObj.year param supplied to CombinedProduction.ctor'); }
    if (typeof combinedProductionObj.combinedMboed !== 'number') { throw new Error('Invalid combinedProductionObj.combinedMboed param supplied to CombinedProduction.ctor'); }

    this.year = combinedProductionObj.year;
    this.combinedMboed = combinedProductionObj.combinedMboed;
  }
}

class FieldParticipant extends DisplayParticipant {
  constructor(obj) {
    super(obj);
    
    if (typeof obj.currentYearNetProductionMBOEPD !== 'number') { throw new Error('Invalid ownerObj.currentYearNetProductionMBOEPD param supplied to Owner.ctor'); }
    if (typeof obj.currentYearNetOpexUSDMM !== 'number') { throw new Error('Invalid ownerObj.currentYearNetOpexUSDMM param supplied to Owner.ctor'); }
    if (typeof obj.netRemainingCapexUSDMM !== 'number') { throw new Error('Invalid ownerObj.netRemainingCapexUSDMM param supplied to Owner.ctor'); }
    if (typeof obj.netRemainingLiquidMmb !== 'number') { throw new Error('Invalid ownerObj.netRemainingLiquidMmb param supplied to Owner.ctor'); }
    if (typeof obj.netRemainingGasMmboe !== 'number') { throw new Error('Invalid ownerObj.netRemainingGasMmboe param supplied to Owner.ctor'); }
    if (typeof obj.netRemainingReservesMmboe !== 'number') { throw new Error('Invalid ownerObj.netRemainingReservesMmboe param supplied to Owner.ctor'); }
    if (typeof obj.netRemainingNPVUSDMM !== 'number') { throw new Error('Invalid ownerObj.netRemainingNPVUSDMM param supplied to Owner.ctor'); }
    if (obj.combinedProductionYears !== null && !Array.isArray(obj.combinedProductionYears)) { throw new Error('Invalid ownerObj.combinedProductionYears param supplied to ownerObj.ctor'); }

    this.currentYearNetProductionMBOEPD = obj.currentYearNetProductionMBOEPD;
    this.currentYearNetOpexUSDMM = obj.currentYearNetOpexUSDMM;
    this.netRemainingCapexUSDMM = obj.netRemainingCapexUSDMM;
    this.netRemainingLiquidMmb = obj.netRemainingLiquidMmb;
    this.netRemainingGasMmboe = obj.netRemainingGasMmboe;
    this.netRemainingReservesMmboe = obj.netRemainingReservesMmboe
    this.netRemainingNPVUSDMM = obj.netRemainingNPVUSDMM

    this.combinedProductionYears = obj.combinedProductionYears !== null && obj.combinedProductionYears.map(x => new CombinedProduction(x));
  }
}

export { FieldParticipant, DisplayParticipant, CombinedProduction }