import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const HoverDropdownComponent = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onMouseOver = (e) => {
    setIsOpen(true);
    if (typeof props.onMouseOver === 'function') {
      props.onMouseOver(e)
    }
  };

  const onMouseLeave = (e) => {
    setIsOpen(false);
    if (typeof props.onMouseLeave === 'function') {
      props.onMouseLeave(e)
    }
  };

  return (
    <Dropdown
      {...props}
      isOpen={isOpen}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      toggle={() => setIsOpen(!isOpen)}
    >
      {props.children}
    </Dropdown>
  );
};

export { HoverDropdownComponent, DropdownToggle, DropdownMenu, DropdownItem };