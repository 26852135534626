import React from 'react';
import PropTypes from 'prop-types';

import { BlockFilter } from './block-filter';

import BaseViewSettingsComponent, { FollowMapComponent, MultiSelectComponent, SelectComponent } from '../base/base-view-settings-component';

import { AppSetState } from '../../app/app-set-state';
import { KeyValuePair } from '../../models/key-value-pair';

class BlockViewSettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blocks: [],
      operators: [],
      participants: [],
      countries: [],
      areas: [],
      quadrants: [],
      licences: [],
      licenceRounds: [],
      blockStatuses: []
    };
    this.loadFromFilter(this.state);
  }

  componentDidMount() {
    const url = 'api/block/filters/load';
    const onSuccess = (result) => {
      this.setState({
        blocks: KeyValuePair.FromArray(result.blocks),
        operators: KeyValuePair.FromArray(result.operators),
        participants: KeyValuePair.FromArray(result.participants),
        countries: KeyValuePair.FromArray(result.countries),
        areas: KeyValuePair.FromArray(result.areas),
        quadrants: KeyValuePair.FromArray(result.quadrants),
        licences: KeyValuePair.FromArray(result.licences),
        licenceRounds: KeyValuePair.FromArray(result.licenceRounds),
        blockStatuses: KeyValuePair.FromArray(result.blockStatuses)
      });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter !== prevProps.filter) {
      this.setState(prevState => {
        this.loadFromFilter(prevState);
        return prevState;
      });
    }
  }

  loadFromFilter = (state) => {
    const { filter } = this.props;

    state.followMap = filter.followMap;
    state.blockIds = filter.blockIds;
    state.operatorIds = filter.operatorIds;
    state.participantIds = filter.participantIds;
    state.countryIds = filter.countryIds;
    state.areaIds = filter.areaIds;
    state.quadrantIds = filter.quadrantIds;
    state.licenceIds = filter.licenceIds;
    state.licenceRoundIds = filter.licenceRoundIds;
    state.blockStatus = filter.blockStatus;
  }

  _update = (closeFilters) => {
    const { filter } = this.props;

    filter.followMap = this.state.followMap;
    filter.blockIds = this.state.blockIds;
    filter.operatorIds = this.state.operatorIds;
    filter.participantIds = this.state.participantIds;
    filter.countryIds = this.state.countryIds;
    filter.areaIds = this.state.areaIds;
    filter.quadrantIds = this.state.quadrantIds;
    filter.licenceIds = this.state.licenceIds;
    filter.licenceRoundIds = this.state.licenceRoundIds;
    filter.blockStatus = this.state.blockStatus;
    this.props.onUpdate(closeFilters);
  };

  _clear = () => {
    this.props.filter.clear();
    this.loadFromFilter(this.state);
    this._update();
  }

  onToggleFollowMap = () => this.setState(prevState => { return { followMap: !prevState.followMap }; });
  onBlockIdsChange = (value) => this.setState({ blockIds: value });
  onOperatorIdsChange = (value) => this.setState({ operatorIds: value });
  onParticipantIdsChange = (value) => this.setState({ participantIds: value });
  onquadrantIdsChange = (value) => this.setState({ quadrantIds: value });
  onCountryIdsChange = (value) => this.setState({ countryIds: value });
  onAreaIdsChange = (value) => {
    this.setState({ areaIds: value });
  }
  onLicenceIdsChange = (value) => {
    this.setState({ licenceIds: value });
  }
  onLicenceRoundIdsChange = (value) => this.setState({ licenceRoundIds: value });
  onBlockStatusChange = (value) => this.setState({ blockStatus: (value === null ? 0 : value) });

  render() {
    const statusValue = this.state.blockStatus === 0 ? '' : this.state.blockStatus;

    return (
      <BaseViewSettingsComponent
        appSetState={this.props.appSetState}
        className="field-view-settings-component"
        onUpdate={this._update}
        onClear={this._clear}
      >
        <FollowMapComponent value={this.state.followMap} enabled={this.props.followMapEnabled} onClick={this.onToggleFollowMap} />
        <MultiSelectComponent label="Block Name" value={this.state.blockIds} values={this.state.blocks} onChange={this.onBlockIdsChange} />
        <MultiSelectComponent label="Country" value={this.state.countryIds} values={this.state.countries} onChange={this.onCountryIdsChange} />
        <MultiSelectComponent label="Area" value={this.state.areaIds} values={this.state.areas} onChange={this.onAreaIdsChange} />
        <MultiSelectComponent label="Quadrant" value={this.state.quadrantIds} values={this.state.quadrants} onChange={this.onquadrantIdsChange} />
        <MultiSelectComponent label="Operator" value={this.state.operatorIds} values={this.state.operators} onChange={this.onOperatorIdsChange} />
        <MultiSelectComponent label="Participant" value={this.state.participantIds} values={this.state.participants} onChange={this.onParticipantIdsChange} />
        <MultiSelectComponent label="Licence" value={this.state.licenceIds} values={this.state.licences} onChange={this.onLicenceIdsChange} />
        <MultiSelectComponent label="Licence Round" value={this.state.licenceRoundIds} values={this.state.licenceRounds} onChange={this.onLicenceRoundIdsChange} />
        <SelectComponent label="Block Status" value={statusValue} values={this.state.blockStatuses} onChange={this.onBlockStatusChange} />
      </BaseViewSettingsComponent>
    );
  }
}

BlockViewSettingsComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  filter: PropTypes.instanceOf(BlockFilter).isRequired,
  onUpdate: PropTypes.func.isRequired,
  followMapEnabled: PropTypes.bool.isRequired
};

export default BlockViewSettingsComponent;
