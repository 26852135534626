import React from 'react';
import PropTypes from 'prop-types';

import { ChartType } from '../../../shared/components/generic-chart/chart-type';
import { ChartData } from '../../../shared/components/generic-chart/chart-data';
import { valueTypes } from '../../../shared/helpers/value-handler';
import GenericChart from '../../../shared/components/generic-chart/generic-chart';

const NetProductionByParticipantChart = (props) => {
  const { participants, isStacked } = props;
  const labels = participants.length === 0 ? [] : participants[0].values.map(obj => obj.year);

  const toChartDataItems = () => {
    const toDataset = (participant, index) => {
      const dataSet = {
        yValIndex: index,
        yValLabel: participant.name,
        yVals: participant.values.map(obj => obj.value),
        color: participant.color
      };
      return dataSet;
    };

    const result = new ChartData({
      xLabels: labels,
      yVal: participants.map((obj, idx) => toDataset(obj, idx))
    });
    return result;
  };

  const chartDataItems = toChartDataItems();
  const chartType = props.chartType === 'Line' ? ChartType.Line : ChartType.Bar;

  return (
    <div style={{ height: '100%', paddingTop: '5px' }}>
      <GenericChart
        chartDataItems={chartDataItems}
        chartType={chartType}
        isYStacked={isStacked}
        yAxisLabel={valueTypes.UnitString(valueTypes.mboed)}
        showYAxisLabel
        showYAxisGridLines
      />
    </div>
  );
};

const dataShape = PropTypes.shape({
  year: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
});

NetProductionByParticipantChart.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(dataShape).isRequired
  })).isRequired,
  isStacked: PropTypes.bool.isRequired,
  chartType: PropTypes.oneOf(['Line', 'Bar'])
};

NetProductionByParticipantChart.defaultProps = {
  participants: [],
  isStacked: true,
  chartType: 'Line'
};

export { NetProductionByParticipantChart };
