import { MapShapeBorder } from '../../shared/scss/app-colors';
import { CircleModel } from '../../shared/helpers/leaflet/leaflet-helper';

class ProspectDataRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "ProspectDataRow.ctor"');
    }

    if (typeof obj.id !== 'number') {
      throw new Error('Invalid "obj.id" param supplied to "ProspectDataRow.ctor"');
    }
    this.id = obj.id;

    if (typeof obj.prospectIdentifier !== 'string') {
      throw new Error('Invalid "obj.prospectIdentifier" param supplied to "ProspectDataRow.ctor"');
    }
    this.prospectIdentifier = obj.prospectIdentifier;

    if (typeof obj.name !== 'string') {
      throw new Error('Invalid "obj.name" param supplied to "ProspectDataRow.ctor"');
    }
    this.name = obj.name;

    if (typeof obj.countryId !== 'number') {
      throw new Error('Invalid "obj.countryId" param supplied to "ProspectDataRow.ctor"');
    }
    this.countryId = obj.countryId;

    if (typeof obj.countryName !== 'string') {
      throw new Error('Invalid "obj.countryName" param supplied to "ProspectDataRow.ctor"');
    }
    this.countryName = obj.countryName;

    if (typeof obj.areaId !== 'number') {
      throw new Error('Invalid "obj.areaId" param supplied to "ProspectDataRow.ctor"');
    }
    this.areaId = obj.areaId;

    if (typeof obj.areaName !== 'string') {
      throw new Error('Invalid "obj.areaName" param supplied to "ProspectDataRow.ctor"');
    }
    this.areaName = obj.areaName;

    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') {
      throw new Error('Invalid "obj.operatorId" param supplied to "ProspectDataRow.ctor"');
    }
    this.operatorId = obj.operatorId;

    if (obj.licence !== null && typeof obj.licence !== 'string') {
      throw new Error('Invalid "obj.licence" param supplied to "ProspectDataRow.ctor"');
    }
    this.licence = obj.licence;

    if (obj.licenceId !== null && typeof obj.licenceId !== 'number') {
      throw new Error('Invalid "obj.licenceId" param supplied to "ProspectDataRow.ctor"');
    }
    this.licenceId = obj.licenceId;

    if (typeof obj.operatorName !== 'string') {
      throw new Error('Invalid "obj.operatorName" param supplied to "ProspectDataRow.ctor"');
    }
    this.operatorName = obj.operatorName;

    if (typeof obj.combinedMmboe !== 'number') {
      throw new Error('Invalid "obj.combinedMmboe" param supplied to "ProspectDataRow.ctor"');
    }
    this.combinedMmboe = obj.combinedMmboe;

    if (obj.reservoirAgeId !== null && typeof obj.reservoirAgeId !== 'number') {
      throw new Error('Invalid "obj.reservoirAgeId" param supplied to "ProspectDataRow.ctor"');
    }
    this.reservoirAgeId = obj.reservoirAgeId;

    if (typeof obj.reservoirAgeName !== 'string') {
      throw new Error('Invalid "obj.reservoirAgeName" param supplied to "ProspectDataRow.ctor"');
    }
    this.reservoirAgeName = obj.reservoirAgeName;

    if (typeof obj.shape !== 'object') {
      throw new Error('Invalid "obj.shape" param supplied to "ProspectDataRow.ctor"');
    }
    this.shape = new CircleModel(obj.shape.y, obj.shape.x, { radius: 900, borderColor: MapShapeBorder, fillColor: 'transparent' });

    if (typeof obj.blockId !== 'number') {
      throw new Error('Invalid "obj.blockId" param supplied to "ProspectDataRow.ctor"');
    }
    this.blockId = obj.blockId;

    if (typeof obj.blockName !== 'string') {
      throw new Error('Invalid "obj.blockName" param supplied to "ProspectDataRow.ctor"');
    }
    this.blockName = obj.blockName;

    if (typeof obj.initialLiquidMmb !== 'number') {
      throw new Error('Invalid "obj.initialLiquidMmb" param supplied to "ProspectDataRow.ctor"');
    }
    this.initialLiquidMmb = obj.initialLiquidMmb;

    if (typeof obj.initialGasBcf !== 'number') {
      throw new Error('Invalid "obj.initialGasBcf" param supplied to "ProspectDataRow.ctor"');
    }
    this.initialGasBcf = obj.initialGasBcf;

    if (typeof obj.waterDepthFt !== 'number') {
      throw new Error('Invalid "obj.waterDepthFt" param supplied to "ProspectDataRow.ctor"');
    }
    this.waterDepthFt = obj.waterDepthFt;

    if (typeof obj.reservoirDepthFt !== 'number') {
      throw new Error('Invalid "obj.reservoirDepthFt" param supplied to "ProspectDataRow.ctor"');
    }
    this.reservoirDepthFt = obj.reservoirDepthFt;

    if (typeof obj.reservoirCondition !== 'string') {
      throw new Error('Invalid "obj.reservoirCondition" param supplied to "ProspectDataRow.ctor"');
    }
    this.reservoirCondition = obj.reservoirCondition;

    if (typeof obj.trapType !== 'string') {
      throw new Error('Invalid "obj.trapType" param supplied to "ProspectDataRow.ctor"');
    }
    this.trapType = obj.trapType;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "ProspectDataRow.FromArray"');
    }

    const result = array.map(obj => new ProspectDataRow(obj));
    return result;
  }
}

export { ProspectDataRow };
