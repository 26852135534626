import { FiscalResource, CashFlowAnnual } from './fiscal-resource';
import { FieldDetail, FieldAttributes } from './field-detail';
import { PortfolioCashflow } from './portfolio-cashflow';
import { MapShapeBorder } from '../../../../shared/scss/app-colors';
import { valueTypes } from '../../../../shared/helpers/value-handler';
import { PolygonsModel, PolygonModel } from '../../../../shared/helpers/leaflet/models/polygons-model';
import { ChartData } from '../../../../shared/components/generic-chart/chart-data';

class PortfolioSelection {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to PortfolioSelection.ctor');
    }
    if (typeof obj.selectionType !== 'number') { throw new Error('Invalid obj.selectionType param supplied to PortfolioSelection.ctor'); }
    if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to PortfolioSelection.ctor'); }

    this.selectionType = obj.selectionType;
    this.id = obj.id;
  }
}

class FasterPortfolioFieldAggregate {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to FasterPortfolioFieldAggregate.ctor');
    }
    if (obj.remainingReservesByField !== null && typeof obj.remainingReservesByField !== 'object') { throw new Error('Invalid remainingReservesByField param supplied to FasterPortfolioFieldAggregate.ctor'); }
    if (obj.remainingReservesByStatus !== null && typeof obj.remainingReservesByStatus !== 'object') { throw new Error('Invalid remainingReservesByStatus param supplied to FasterPortfolioFieldAggregate.ctor'); }
    if (obj.remainingNPVByField !== null && typeof obj.remainingNPVByField !== 'object') { throw new Error('Invalid remainingNPVByField param supplied to FasterPortfolioFieldAggregate.ctor'); }
    if (obj.currentYearProduction !== null && typeof obj.currentYearProduction !== 'object') { throw new Error('Invalid currentYearProduction param supplied to FasterPortfolioFieldAggregate.ctor'); }

    this.remainingReservesByField = (obj.remainingReservesByField !== null && obj.remainingReservesByField !== undefined) ? new ChartData(obj.remainingReservesByField) : null;
    this.remainingReservesByStatus = (obj.remainingReservesByStatus !== null && obj.remainingReservesByStatus !== undefined) ? new ChartData(obj.remainingReservesByStatus) : null;
    this.remainingNPVByField = (obj.remainingNPVByField !== null && obj.remainingNPVByField !== undefined) ? new ChartData(obj.remainingNPVByField) : null;
    this.currentYearProduction = (obj.currentYearProduction !== null && obj.currentYearProduction !== undefined) ? new ChartData(obj.currentYearProduction) : null;
  }
}

class FasterResourceSummary {
  constructor(obj) {
    //Field name, Country, Area, Hub, Operator, Development status (aka status_id in resource table), Remaining reserves (mmboe), 2019 production ('000 boepd), Remaining NPV10 (US$ million).
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.resourceId !== 'number') { throw new Error('Invalid obj.resourceId param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.hubId !== 'number') { throw new Error('Invalid obj.hubId param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.resourceName !== 'string') { throw new Error('Invalid obj.resourceName param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.countryId !== 'number') { throw new Error('Invalid obj.countryId param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.countryName !== 'string') { throw new Error('Invalid obj.countryName param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.areaName !== 'string') { throw new Error('Invalid obj.areaName param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.hubName !== 'string') { throw new Error('Invalid obj.hubName param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.hubCountryId !== 'number') { throw new Error('Invalid obj.hubCountryId param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.operatorName !== 'string') { throw new Error('Invalid obj.operatorName param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.operatorId !== 'number') { throw new Error('Invalid obj.operatorId param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.status !== 'string') { throw new Error('Invalid obj.status param supplied to FasterResourceSummary.ctor'); }
    if (obj.remainingReservesMMBOE !== null && typeof obj.remainingReservesMMBOE !== 'number') { throw new Error('Invalid obj.remainingReservesMMBOE param supplied to FasterResourceSummary.ctor'); }
    if (obj.currentYearProductionBOEPD !== null && typeof obj.currentYearProductionBOEPD !== 'number') { throw new Error('Invalid obj.currentYearProductionBOEPD param supplied to FasterResourceSummary.ctor'); }
    if (obj.currentYearCO2EmissionsKTCO2 !== null && typeof obj.currentYearCO2EmissionsKTCO2 !== 'number') { throw new Error('Invalid obj.currentYearCO2EmissionsKTCO2 param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.combinedPercentRemaining !== 'number') { throw new Error('Invalid obj.combinedPercentRemaining param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.remainingRevenue !== 'number') { throw new Error('Invalid obj.remainingRevenue param supplied to FasterResourceSummary.ctor'); }
    if (obj.npv !== null && typeof obj.npv !== 'number') { throw new Error('Invalid obj.npv param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.npvPerBarrel !== 'number') { throw new Error('Invalid obj.npvPerBarrel param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.remainingCAPEX !== 'number') { throw new Error('Invalid obj.remainingCAPEX param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.remainingOPEX !== 'number') { throw new Error('Invalid obj.remainingOPEX param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.remainingOPEXBOE !== 'number') { throw new Error('Invalid obj.remainingOPEXBOE param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.remainingTariff !== 'number') { throw new Error('Invalid obj.remainingTariff param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.remainingABEX !== 'number') { throw new Error('Invalid obj.remainingABEX param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.remainingTax !== 'number') { throw new Error('Invalid obj.remainingTax param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.remainingAbexRelief !== 'number') { throw new Error('Invalid obj.remainingAbexRelief param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.firstProductionYear !== 'number') { throw new Error('Invalid obj.firstProductionYear param supplied to FasterResourceSummary.ctor'); }
    if (obj.lastProductionYear !== null && typeof obj.lastProductionYear !== 'number') { throw new Error('Invalid obj.lastProductionYear param supplied to FasterResourceSummary.ctor'); }
    if (typeof obj.operatorId !== 'number') { throw new Error('Invalid obj.operatorId param supplied to FasterResourceSummary.ctor'); }
    if (obj.remainingLiquidMMB !== null && typeof obj.remainingLiquidMMB !== 'number') { throw new Error('Invalid obj.remainingLiquidMMB param supplied to FasterResourceSummary.ctor'); }
    if (obj.remainingGasBCF !== null && typeof obj.remainingGasBCF !== 'number') { throw new Error('Invalid obj.remainingGasBCF param supplied to FasterResourceSummary.ctor'); }
    if (!Array.isArray(obj.shapes)) { throw new Error('Invalid obj.shapes param supplied to FasterResourceSummary.ctor'); }

    this.resourceId = obj.resourceId;
    this.hubId = obj.hubId;
    this.resourceName = obj.resourceName;
    this.countryId = obj.countryId;
    this.countryName = obj.countryName;
    this.areaName = obj.areaName;
    this.hubName = obj.hubName;
    this.hubCountryId = obj.hubCountryId;
    this.operatorName = obj.operatorName;
    this.status = obj.status;
    this.remainingReservesMMBOE = obj.remainingReservesMMBOE === null ? null : valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingReservesMMBOE);
    this.currentYearProductionBOEPD = obj.currentYearProductionBOEPD === null ? null : valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.currentYearProductionBOEPD);
    this.currentYearCO2EmissionsKTCO2 = obj.currentYearCO2EmissionsKTCO2 === null ? null : valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.currentYearCO2EmissionsKTCO2);
    this.combinedPercentRemaining = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.combinedPercentRemaining);
    this.remainingRevenue = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingRevenue);
    this.npv = obj.npv === null ? null : valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.npv);
    this.npvPerBarrel = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.npvPerBarrel);
    this.remainingCAPEX = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingCAPEX);
    this.remainingOPEX = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingOPEX);
    this.remainingOPEXBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingOPEXBOE);
    this.remainingTariff = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingTariff);
    this.remainingABEX = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingABEX);
    this.remainingTax = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingTax);
    this.remainingAbexRelief = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingAbexRelief);
    this.firstProductionYear = obj.firstProductionYear;
    this.lastProductionYear = obj.lastProductionYear === null ? null : obj.lastProductionYear;
    this.operatorId = obj.operatorId;
    this.remainingLiquidMMB = obj.remainingLiquidMMB === null ? null : valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingLiquidMMB);
    this.remainingGasBCF = obj.remainingGasBCF === null ? null : valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingGasBCF);
    this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "FasterResourceSummary.FromArray"');
    }

    const result = array.map(obj => new FasterResourceSummary(obj));
    return result;
  }
}

class FasterPortfolioCorporateSummary {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.corporateId !== 'number') { throw new Error('Invalid obj.corporateId param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.corporateName !== 'string') { throw new Error('Invalid obj.corporateName param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.countryList !== 'string') { throw new Error('Invalid obj.countryList param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.areaList !== 'string') { throw new Error('Invalid obj.areaList param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.corporateCategory !== 'string') { throw new Error('Invalid obj.corporateCategory param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netRemainingLiquidMMB !== 'number') { throw new Error('Invalid obj.netRemainingLiquidMMB param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netRemainingGasBCF !== 'number') { throw new Error('Invalid obj.netRemainingGasBCF param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netRemainingReservesMMBOE !== 'number') { throw new Error('Invalid obj.netRemainingReservesMMBOE param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netCurrentYearProductionBOEPD !== 'number') { throw new Error('Invalid obj.netCurrentYearProductionBOEPD param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netCurrentYearLiquidProductionBOEPD !== 'number') { throw new Error('Invalid obj.netCurrentYearLiquidProductionBOEPD param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netCurrentYearEquityCO2EmissionsKTCO2 !== 'number') { throw new Error('Invalid obj.netCurrentYearEquityCO2EmissionsKTCO2 param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netCurrentYearOperationalCO2EmissionsKTCO2 !== 'number') { throw new Error('Invalid obj.netCurrentYearOperationalCO2EmissionsKTCO2 param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netCurrentYearGasProductionMMSCFD !== 'number') { throw new Error('Invalid obj.netCurrentYearGasProductionMMSCFD param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netRemainingRevenueUSDMM !== 'number') { throw new Error('Invalid obj.netRemainingRevenueUSDMM param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netRemainingPreTaxCashflowUSDMM !== 'number') { throw new Error('Invalid obj.netRemainingPreTaxCashflowUSDMM param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netRemainingCapexUSDMM !== 'number') { throw new Error('Invalid obj.netRemainingCapexUSDMM param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netCurrentYearOpexUSDMM !== 'number') { throw new Error('Invalid obj.netCurrentYearOpexUSDMM param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netCurrentYearOpexPerBoe !== 'number') { throw new Error('Invalid obj.netCurrentYearOpexPerBoe param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netRemainingOpexUSDMM !== 'number') { throw new Error('Invalid obj.netRemainingOpexUSDMM param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netRemainingOpexPerBoe !== 'number') { throw new Error('Invalid obj.netRemainingOpexPerBoe param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netRemainingAbexUSDMM !== 'number') { throw new Error('Invalid obj.netRemainingAbexUSDMM param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.producingFields !== 'number') { throw new Error('Invalid obj.producingFields param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netRemainingReservesInProducingFieldsMMBOE !== 'number') { throw new Error('Invalid obj.netRemainingReservesInProducingFieldsMMBOE param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.underDevelopmentFields !== 'number') { throw new Error('Invalid obj.underDevelopmentFields param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netRemainingReservesInUnderDevelopmentFieldsMMBOE !== 'number') { throw new Error('Invalid obj.netRemainingReservesInUnderDevelopmentFieldsMMBOE param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.nearTermFields !== 'number') { throw new Error('Invalid obj.nearTermFields param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netRemainingReservesInNearTermFieldsMMBOE !== 'number') { throw new Error('Invalid obj.netRemainingReservesInNearTermFieldsMMBOE param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.otherFields !== 'number') { throw new Error('Invalid obj.otherFields param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.percentOperatedFields !== 'number') { throw new Error('Invalid obj.percentOperatedFields param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.discoveries !== 'number') { throw new Error('Invalid obj.discoveries param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.discoveryResourcesMMBOE !== 'number') { throw new Error('Invalid obj.discoveryResourcesMMBOE param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.prospects !== 'number') { throw new Error('Invalid obj.prospects param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.prospectsResourcesMMBOE !== 'number') { throw new Error('Invalid obj.prospectsResourcesMMBOE param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.explorationWellsLastFiveYears !== 'number') { throw new Error('Invalid obj.explorationWellsLastFiveYears param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (!Array.isArray(obj.shapes)) { throw new Error('Invalid obj.shapes param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.grossAreaSQKM !== 'number') { throw new Error('Invalid obj.grossAreaSQKM param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (typeof obj.netAreaSQKM !== 'number') { throw new Error('Invalid obj.netAreaSQKM param supplied to FasterPortfolioCorporateSummary.ctor'); }
    if (obj.corporateCashflow !== null && typeof obj.corporateCashflow !== 'object') { throw new Error('Invalid corporateCashflow param supplied to FasterPortfolioCorporateSummary.ctor'); }

    this.id = obj.corporateId;
    this.name = obj.corporateName;
    this.country = obj.countryList;
    this.areaList = obj.areaList;
    this.corporateCategory = obj.corporateCategory;
    this.netRemainingLiquidMMB = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netRemainingLiquidMMB);
    this.netRemainingGasBCF = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netRemainingGasBCF);
    this.netRemainingReservesMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netRemainingReservesMMBOE);
    this.netCurrentYearProductionBOEPD = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netCurrentYearProductionBOEPD);
    this.netCurrentYearLiquidProductionBOEPD = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netCurrentYearLiquidProductionBOEPD);
    this.netCurrentYearGasProductionMMSCFD = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netCurrentYearGasProductionMMSCFD);
    this.netCurrentYearEquityCO2EmissionsKTCO2 = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netCurrentYearEquityCO2EmissionsKTCO2);
    this.netCurrentYearOperationalCO2EmissionsKTCO2 = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netCurrentYearOperationalCO2EmissionsKTCO2);
    this.netRemainingRevenueUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netRemainingRevenueUSDMM);
    this.netRemainingPreTaxCashflowUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netRemainingPreTaxCashflowUSDMM);
    this.netRemainingCapexUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netRemainingCapexUSDMM);
    this.netCurrentYearOpexUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netCurrentYearOpexUSDMM);
    this.netCurrentYearOpexPerBoe = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netCurrentYearOpexPerBoe);
    this.netRemainingOpexUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netRemainingOpexUSDMM);
    this.netRemainingOpexPerBoe = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netRemainingOpexPerBoe);
    this.netRemainingAbexUSDMM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netRemainingAbexUSDMM);
    this.producingFields = obj.producingFields;
    this.netRemainingReservesInProducingFieldsMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netRemainingReservesInProducingFieldsMMBOE);
    this.underDevelopmentFields = obj.underDevelopmentFields;
    this.netRemainingReservesInUnderDevelopmentFieldsMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netRemainingReservesInUnderDevelopmentFieldsMMBOE);
    this.nearTermFields = obj.nearTermFields;
    this.netRemainingReservesInNearTermFieldsMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netRemainingReservesInNearTermFieldsMMBOE);
    this.otherFields = obj.otherFields;
    this.percentOperatedFields = valueTypes.ValueOnlyForTable(valueTypes.percent, obj.percentOperatedFields);
    this.discoveries = obj.discoveries;
    this.discoveryResourcesMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.discoveryResourcesMMBOE);
    this.prospects = obj.prospects;
    this.prospectsResourcesMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.prospectsResourcesMMBOE);
    this.explorationWellsLastFiveYears = obj.explorationWellsLastFiveYears;
    this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });
    this.grossAreaSQKM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.grossAreaSQKM);
    this.netAreaSQKM = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.netAreaSQKM);
    this.corporateCashflow = obj.corporateCashflow !== null ? new PortfolioCashflow(obj.corporateCashflow) : null;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "FasterPortfolioCorporateSummary.FromArray"');
    }

    const result = array.map(obj => new FasterPortfolioCorporateSummary(obj));
    return result;
  }
}

class FasterPortfolioCountrySummary {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.name !== 'string') { throw new Error('Invalid obj.name param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.country !== 'string') { throw new Error('Invalid obj.country param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.remainingReservesMMBOE !== 'number') { throw new Error('Invalid obj.remainingReservesMMBOE param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.remainingRevenue !== 'number') { throw new Error('Invalid obj.remainingRevenue param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.npv !== 'number') { throw new Error('Invalid obj.npv param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.npvPerBarrel !== 'number') { throw new Error('Invalid obj.npvPerBarrel param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.remainingCAPEX !== 'number') { throw new Error('Invalid obj.remainingCAPEX param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.remainingOPEX !== 'number') { throw new Error('Invalid obj.remainingOPEX param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.remainingOPEXBOE !== 'number') { throw new Error('Invalid obj.remainingOPEXBOE param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.remainingTariff !== 'number') { throw new Error('Invalid obj.remainingTariff param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.remainingABEX !== 'number') { throw new Error('Invalid obj.remainingABEX param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.remainingTax !== 'number') { throw new Error('Invalid obj.remainingTax param supplied to ResourceCashflow.ctor'); }
    if (typeof obj.remainingAbexRelief !== 'number') { throw new Error('Invalid obj.remainingAbexRelief param supplied to ResourceCashflow.ctor'); }

    this.id = obj.id;
    this.name = obj.name;
    this.country = obj.country;
    this.remainingReservesMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingReservesMMBOE);
    this.remainingRevenue = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingRevenue);
    this.npv = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.npv);
    this.npvPerBarrel = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.npvPerBarrel);
    this.remainingCAPEX = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingCAPEX);
    this.remainingOPEX = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingOPEX);
    this.remainingOPEXBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingOPEXBOE);
    this.remainingTariff = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingTariff);
    this.remainingABEX = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingABEX);
    this.remainingTax = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingTax);
    this.remainingAbexRelief = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingAbexRelief);
  }
}

class FasterPortfolioHubSummary {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.hubId !== 'number') { throw new Error('Invalid obj.hubId param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.hubName !== 'string') { throw new Error('Invalid obj.hubName param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.countryName !== 'string') { throw new Error('Invalid obj.countryName param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.areaName !== 'string') { throw new Error('Invalid obj.areaName param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.operatorName !== 'string') { throw new Error('Invalid obj.operatorName param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.operatorId !== 'number') { throw new Error('Invalid obj.operatorId param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.developmentStatus !== 'string') { throw new Error('Invalid obj.developmentStatus param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.electrificationStatus !== 'string') { throw new Error('Invalid obj.electrificationStatus param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.numberOfFields !== 'number') { throw new Error('Invalid obj.numberOfFields param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.remainingLiquidMMB !== 'number') { throw new Error('Invalid obj.remainingLiquidMMB param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.remainingGasBCF !== 'number') { throw new Error('Invalid obj.gasBCF param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.lastProductionYear !== 'number') { throw new Error('Invalid obj.lastProductionYear param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.remainingReservesMMBOE !== 'number') { throw new Error('Invalid obj.remainingReservesMMBOE param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.currentYearProductionBOEPD !== 'number') { throw new Error('Invalid obj.currentYearProductionBOEPD param supplied to FasterPortfolioHubSummary.ctor'); }
    if (obj.currentYearCO2EmissionsKTCO2 !== null && typeof obj.currentYearCO2EmissionsKTCO2 !== 'number') { throw new Error('Invalid obj.currentYearCO2EmissionsKTCO2 param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.liquidExport !== 'string') { throw new Error('Invalid obj.liquidExport param supplied to FasterPortfolioHubSummary.ctor'); }
    if (typeof obj.gasExport !== 'string') { throw new Error('Invalid obj.gasExport param supplied to FasterPortfolioHubSummary.ctor'); }

    if (obj.shapes !== null && !Array.isArray(obj.shapes)) { throw new Error('Invalid obj.shapes param supplied to FasterPortfolioHubSummary.ctor'); }

    this.hubId = obj.hubId;
    this.hubName = obj.hubName;
    this.countryName = obj.countryName;
    this.areaName = obj.areaName;
    this.operatorName = obj.operatorName;
    this.operatorId = obj.operatorId;
    this.developmentStatus = obj.developmentStatus;
    this.electrificationStatus = obj.electrificationStatus;
    this.lastProductionYear = obj.lastProductionYear;
    this.numberOfFields = obj.numberOfFields;
    this.remainingLiquidMMB = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingLiquidMMB);
    this.remainingGasBCF = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingGasBCF);
    this.remainingReservesMMBOE = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.remainingReservesMMBOE);
    this.currentYearProductionBOEPD = valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.currentYearProductionBOEPD);
    this.currentYearCO2EmissionsKTCO2 = obj.currentYearCO2EmissionsKTCO2 === null ? null : valueTypes.ValueOnlyForTable(valueTypes.generic1, obj.currentYearCO2EmissionsKTCO2);
    this.liquidExport = obj.liquidExport;
    this.gasExport = obj.gasExport;
    this.shapes = obj.shapes !== null && new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "FasterPortfolioHubSummary.FromArray"');
    }

    const result = array.map(obj => new FasterPortfolioHubSummary(obj));
    return result;
  }
}

class FasterPortfolio {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to FasterPortfolio.ctor'); }
    if (typeof obj.selection !== 'object') { throw new Error('Invalid selection param supplied to FasterPortfolio.ctor'); }

    if (typeof obj.currentYear !== 'number') { throw new Error('Invalid obj.currentYear param supplied to FasterPortfolio.ctor'); }
    if (typeof obj.historicDataCutoffYear !== 'number') { throw new Error('Invalid obj.historicDataCutoffYear param supplied to FasterPortfolio.ctor'); }
    if (obj.portfolioCashflows !== null && !Array.isArray(obj.portfolioCashflows)) { throw new Error('Invalid obj.portfolioCashflows param supplied to FasterPortfolio.ctor'); }
    if (obj.fiscalResources !== null && !Array.isArray(obj.fiscalResources)) { throw new Error('Invalid obj.fiscalResources param supplied to FasterPortfolio.ctor'); }
    if (obj.resourceSummary !== null && !Array.isArray(obj.resourceSummary)) { throw new Error('Invalid obj.resourceSummary param supplied to FasterPortfolio.ctor'); }
    if (obj.corporateSummary !== null && !Array.isArray(obj.corporateSummary)) { throw new Error('Invalid obj.corporateSummary param supplied to FasterPortfolio.ctor'); }
    if (obj.hubSummary !== null && !Array.isArray(obj.hubSummary)) { throw new Error('Invalid obj.hubSummary param supplied to FasterPortfolio.ctor'); }
    if (obj.fieldAggregate !== null && typeof obj.fieldAggregate !== 'object') { throw new Error('Invalid fieldAggregate param supplied to FasterPortfolio.ctor'); }
    if (typeof obj.discountStartYear !== 'number') { throw new Error('Invalid obj.discountStartYear param supplied to FasterPortfolio.ctor'); }

    this.selection = new PortfolioSelection(obj.selection);

    this.currentYear = obj.currentYear;
    this.historicDataCutoffYear = obj.historicDataCutoffYear;
    this.portfolioCashflows = obj.portfolioCashflows !== null ? obj.portfolioCashflows.map(obj => new PortfolioCashflow(obj)) : null;
    this.fiscalResources = obj.fiscalResources !== null ? obj.fiscalResources.map(obj => new FiscalResource(obj)) : null;
    this.fieldDetail = obj.fieldDetail.map(obj => new FieldDetail(obj));
    this.resourceSummary = obj.resourceSummary !== null ? obj.resourceSummary.map(obj => new FasterResourceSummary(obj)) : null;
    this.corporateSummary = obj.corporateSummary !== null ? obj.corporateSummary.map(obj => new FasterPortfolioCountrySummary(obj)) : null;
    this.hubSummary = obj.hubSummary !== null ? obj.hubSummary.map(obj => new FasterPortfolioHubSummary(obj)) : null;
    this.fieldAggregate = obj.fieldAggregate !== null ? new FasterPortfolioFieldAggregate(obj.fieldAggregate) : null;
    this.discountStartYear = obj.discountStartYear;
  }
}
export { PortfolioSelection, FasterPortfolio, FiscalResource, FasterPortfolioCountrySummary, FasterResourceSummary, FieldDetail, FieldAttributes, CashFlowAnnual, FasterPortfolioFieldAggregate, FasterPortfolioHubSummary, FasterPortfolioCorporateSummary };
