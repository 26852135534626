import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { appIcons, appIconSizes } from './app-icons';

const SettingsDropdownComponent = (props) => {
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle style={{ padding: '2px 0 1px 0', fontSize: '10px', marginTop: '7px', marginRight: '5px' }} nav caret className="hover-spin-icon" title="Settings">
        {props.showLabels && <span style={{ verticalAlign: 'super', paddingLeft: '5px', paddingRight: '5px' }}>Settings</span>}
        {appIcons.getIcon(appIcons.Settings, appIconSizes.md)}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-right">
        <DropdownItem onClick={props.toggleShowLabels}>
          {appIcons.getIcon(props.showLabels ? appIcons.ToggleOn : appIcons.ToggleOff, appIconSizes.sm)}
          {' ' + (props.showLabels ? 'Hide' : 'Show') + ' labels'}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

SettingsDropdownComponent.propTypes = {
  showLabels: PropTypes.bool.isRequired,
  toggleShowLabels: PropTypes.func.isRequired,
  right: PropTypes.bool.isRequired
};

SettingsDropdownComponent.defaultProps = {
  right: false
};

export default SettingsDropdownComponent;
