import React from 'react';
import PropTypes from 'prop-types';

import { DisplayWell } from '../../shared/components/models/gmatrix3-extension-display-well';
import BaseTableComponent from '../../shared/components/detail-view-grid-layout/base-table-component';
import { valueTypes } from '../../shared/helpers/value-handler';
import { clickHandler, clickIconHandler } from '../../helpers/link-helper';
import { appIcons } from '../../app/app-icons';

const CompletedActiveWellsTableComponent = (props) => {
  const { wells, onWellClick, onParticipantClick, openReport } = props;

  const getRow = (row, rowIndex) => {
    let pdfLink = "";
    //let pdfWidth = "0%";
    if (row.reportId > 0) {   
      //pdfLink = createIconOnly(appIcons.FilePdf, 'Download report', () => openReport(row.reportId));
      //pdfWidth = "4%";
      pdfLink = clickIconHandler('Download report', () => openReport(row.reportId), appIcons.FilePdf);
    }

    return (
      <tr key={'well-row-' + rowIndex.toString()}>
        <td style={{ width: '9%' }}>{clickHandler(row.wellName, row.wellId, onWellClick)}</td>
        <td style={{ width: '4%' }}>{pdfLink}</td>
        <td style={{ width: '5%' }}>{row.discoveryWellStatus}</td>
        <td style={{ width: '5%' }}>{row.class}</td>
        <td style={{ width: '15%' }}>{clickHandler(row.originalOperatorName, row.originalOperatorId, onParticipantClick)}</td>
        <td style={{ width: '15%' }}>{row.status}</td>
        <td style={{ width: '8%' }}>{row.startDate}</td>
        <td style={{ width: '8%' }}>{row.endDate}</td>
        <td style={{ width: '5%' }}>{valueTypes.ValueOnly(valueTypes.feet, row.totalDepthFt)}</td>
        <td style={{ width: '19%' }}>{row.rig.rigName}</td>
        <td style={{ width: '7%' }}>{valueTypes.ValueOnly(valueTypes.generic1, row.estimatedWellCost)}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Well Name</td>
          <td></td>
          <td>Discovery Well</td>
          <td>Class</td>
          <td>Original Operator Name</td>
          <td>Well Status</td>
          <td>Start Date</td>
          <td>End Date</td>
          <td>Total Depth ({valueTypes.UnitString(valueTypes.feet)})</td>
          <td>Rig Name</td>
          <td>Estimated Well Cost ({valueTypes.UnitString(valueTypes.usdMM)})</td>
        </tr>
      </thead>
      <tbody>
        {wells.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
}

CompletedActiveWellsTableComponent.propTypes = {
  wells: PropTypes.arrayOf(PropTypes.instanceOf(DisplayWell)).isRequired,
  onWellClick: PropTypes.func,
  onParticipantClick: PropTypes.func,
  openReport: PropTypes.func
};

export default CompletedActiveWellsTableComponent;
