import { Filedownload } from './filedownload';

const downloadFile = (id, props, download, emailID, page, type, panel, filename = null, title = null, newTab = true) => {
  const onSuccess = (data) => {
    const dfile = new Filedownload(data);
    if (dfile.fileExistsInDB && dfile.fileExistsOnServer && dfile.userSubscribed) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var byteCharacters = atob(dfile.base64EncodedPDF);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type: 'application/pdf'
        });
        window.navigator.msSaveOrOpenBlob(blob, dfile.shortFileName);
      } else { //(not IE)
        var byteCharacters2 = atob(dfile.base64EncodedPDF);
        var byteNumbers2 = new Array(byteCharacters2.length);
        for (var j = 0; j < byteCharacters2.length; j++) {
          byteNumbers2[j] = byteCharacters2.charCodeAt(j);
        }
        var byteArray2 = new Uint8Array(byteNumbers2);
        var file = new Blob([byteArray2], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);

        if (!download && newTab) {
          window.open(fileURL, '_blank'); //works - opens in new window but the url is the blob name
        }
        else if (!download && !newTab) {
          window.open(fileURL, '_self'); //same tab
        }
        else {
          var anchor = document.createElement("a");
          anchor.setAttribute("download", dfile.shortFileName);
          anchor.setAttribute("href", fileURL);
          document.body.appendChild(anchor);
          anchor.remove();
          anchor.click();
          window.URL.revokeObjectURL(anchor.href);
        }
      }
    }
    else {
      props.messageBox.showOKPrompt(dfile.errorMessage);
    }
  }
  const onFail = (err) => {
    console.log(' open report failed ');
  }

  const apiAction = id > 0 ? "openreport" : id === -1 ? "openspecificfile" : "";
  var url = 'api/reports/' + apiAction +
    '?notificationID=' + emailID +
    '&page=' + page +
    '&type=' + type +
    '&panel=' + panel +
    '&reportID=' + id;

  if (id === -1) url = url + '&filename=' + filename + '&title=' + title;

  if (props.api !== undefined)
    props.api.get(url, onSuccess, onFail);
  else if (props._api !== undefined)
    props._api.getCachedAsync(url, onSuccess, onFail);

};

const downloadSpecial = (id, props) => {
  const onSuccess = (data) => {
    const dfile = new Filedownload(data);
    if (dfile.fileExistsInDB && dfile.fileExistsOnServer && dfile.userSubscribed) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var byteCharacters = atob(dfile.zipByteArray);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type: 'application/x-compressed'
        });
        window.navigator.msSaveOrOpenBlob(blob, data[1]);
      } else { //(not IE)
        var blob2 = base64ToBlob(dfile.zipByteArray, "application/x-compressed");
        var url = window.URL.createObjectURL(blob2);

        const link = document.createElement('a');
        link.href = url;
        link.download = dfile.shortFileName;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

      }
    }
    else {
      props.messageBox.showOKPrompt(dfile.errorMessage);
    }
  }
  const onFail = (err) => {
    console.log(' open report failed ');
  }
  props.api.get('api/reports/downloadzip?reportID=' + id, onSuccess, onFail);
};

const base64ToBlob = (base64, mimetype, slicesize) => {
  if (!window.atob || !window.Uint8Array) {
    // The current browser doesn't have the atob function. Cannot continue
    return null;
  }
  mimetype = mimetype || '';
  slicesize = slicesize || 512;
  var bytechars = atob(base64);
  var bytearrays = [];
  for (var offset = 0; offset < bytechars.length; offset += slicesize) {
    var slice = bytechars.slice(offset, offset + slicesize);
    var bytenums = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      bytenums[i] = slice.charCodeAt(i);
    }
    var bytearray = new Uint8Array(bytenums);
    bytearrays[bytearrays.length] = bytearray;
  }
  return new Blob(bytearrays, { type: mimetype });
};

export { downloadFile, downloadSpecial }
