import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';

import './select-component.scss';
import { appIcons, appIconSizes } from '../../../app/app-icons';

const SelectComponent = (props) => {
  const labelRef = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelId = props.label.replace(' ', '-').toLowerCase() + '-label';

  React.useEffect(() => { setLabelWidth(labelRef.current.offsetWidth); }, []);

  const getValue = (item) => {
    if (item === undefined) {
      return undefined;
    }
    const result = item[props.valueName];
    if (result === undefined) {
      console.error('*** SelectComponent.getValue returned undefined for valueName "' + props.valueName + '"');
    }
    return result;
  };
  const getLabel = (item) => {
    if (item === undefined) {
      return undefined;
    }
    const result = item[props.labelName];
    if (result === undefined) {
      console.error('*** SelectComponent.getLabel returned undefined for labelName "' + props.labelName + '"');
    }
    return result;
  };
  const handleOnChange = (e) => props.onChange(e.target.value);
  const handleClearClick = (e) => props.onChange(null);

  const buttons = props.showButtons === true
    ? (
      <div className="buttons-div">
        <span title="Clear" onClick={handleClearClick}>
          {appIcons.getIcon(appIcons.Close, appIconSizes.xs, false)}
        </span>
      </div>
    )
    : null;

  return (
    <FormControl className="select-component" fullWidth variant="outlined" margin="dense" disabled={props.disabled}>
      {buttons}

      <InputLabel className="labelShrunk" ref={labelRef} id={labelId} shrink>
        {props.label}
      </InputLabel>

      <Select
        labelId={labelId}
        labelWidth={labelWidth}
        value={props.value === null ? '' : props.value}
        onChange={handleOnChange}
        MenuProps={{
          PaperProps: { style: { maxHeight: 400, minWidth: 293 } },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null }}
      >
        {props.values.map(obj => <MenuItem key={'menu-item-' + getValue(obj)} value={getValue(obj)}>{getLabel(obj)}</MenuItem>)}
      </Select>
    </FormControl>
  );
};

function allowNull(props, propName, componentName) {
  if (props[propName] === null)
    return null;
  else if (typeof props[propName] !== 'string' && typeof props[propName] !== 'number' && typeof props[propName] !== 'boolean') {
    throw new Error('Invalid prop type supplied for ' + propName + ' to SelectComponent');
  }
}

SelectComponent.propTypes = {
  valueName: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: allowNull,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  showButtons: PropTypes.bool.isRequired
};

SelectComponent.defaultProps = {
  valueName: 'key',
  labelName: 'value',
  disabled: false,
  showButtons: true
};

export default SelectComponent;