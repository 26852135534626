import { appViews } from "../../app/app-views";
import { AppSetState } from "../../app/app-set-state";

import { BaseExcelExportColumn, BaseExcelExport } from "../../shared/helpers/base-excel-export";

class ReportsGridExcelExport {
  _formatDataRow(getLink, dataRow) {
    const result = { ...dataRow };
    if (typeof result.link === 'string') {
      result.link = getLink(result.link);
    }
    return result;
  }

  export(appSetState, dataRows) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "ReportsExcelExport.export"');
    }

    const baseExcelExport = new BaseExcelExport();
    const workbook = baseExcelExport.createWorkbook();

    const appView = appViews.getDisplayName(appViews.Reports);
    dataRows = dataRows.map(obj => this._formatDataRow(appSetState.view.details.getLink, obj));
    const colDefs = [
      new BaseExcelExportColumn('Report Name', 'name'),
      new BaseExcelExportColumn('Country', 'countryName'),
      new BaseExcelExportColumn('Category', 'category'),
      new BaseExcelExportColumn('Publish Date', 'publishDateDisplay'),
      new BaseExcelExportColumn('Description', 'summaryText'),
      new BaseExcelExportColumn('Atlas URL', 'link')
    ];

    baseExcelExport.writeSheet(workbook, appView, dataRows, colDefs);
    baseExcelExport.writeWorkbook(workbook, baseExcelExport.getAtlas5WorkbookPrefix() + appView + baseExcelExport.getAtlas5WorkbookSuffix());
  }
}

export { ReportsGridExcelExport };