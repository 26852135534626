import React from 'react';
import PropTypes from 'prop-types';

import { FieldFilter } from './field-filter';
import BaseViewSettingsComponent, { createSplitDiv, FollowMapComponent, NumericFieldComponent, MultiSelectComponent } from '../base/base-view-settings-component';

import { AppSetState } from '../../app/app-set-state';
import { KeyValuePair } from '../../models/key-value-pair';
import { valueTypes } from '../../shared/helpers/value-handler';

class FieldViewSettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields:[],
      operators: [],
      participants: [],
      countries: [],
      areas: [],
      hubs: [],
      statuses: [],
      reservoirAges: [],
      liquidPipelines: [],
      gasPipelines: [],
      basicOnly: false,
      licenceStatuses: []
    };
    this.loadFromFilter(this.state);
  }

  componentDidMount() {
    const url = 'api/field/filters/load';
    const onSuccess = (result) => {
      this.setState({
        fields: KeyValuePair.FromArray(result.fields),
        operators: KeyValuePair.FromArray(result.operators),
        participants: KeyValuePair.FromArray(result.participants),
        countries: KeyValuePair.FromArray(result.countries),
        hubs: KeyValuePair.FromArray(result.hubs),
        areas: KeyValuePair.FromArray(result.areas),
        statuses: KeyValuePair.FromArray(result.statuses),
        reservoirAges: KeyValuePair.FromArray(result.reservoirAges),
        liquidPipelines: KeyValuePair.FromArray(result.liquidPipelines),
        gasPipelines: KeyValuePair.FromArray(result.gasPipelines),
        basicOnly: result.basicOnly,
        licenceStatuses: KeyValuePair.FromArray(result.licenceStatuses)
      });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter !== prevProps.filter) {
      this.setState(prevState => {
        this.loadFromFilter(prevState);
        return prevState;
      });
    }
  }

  loadFromFilter = (state) => {
    const { filter } = this.props;

    state.followMap = filter.followMap;
    state.fieldIds = filter.fieldIds;
    state.operatorIds = filter.operatorIds;
    state.participantIds = filter.participantIds;
    state.countryIds = filter.countryIds;
    state.areaIds = filter.areaIds;
    state.hubIds = filter.hubIds;
    state.statusIds = filter.statusIds;
    state.reservoirAgeIds = filter.reservoirAgeIds;
    state.minRemainingReserves = filter.minRemainingReserves;
    state.maxRemainingReserves = filter.maxRemainingReserves;
    state.minProduction = filter.minProduction;
    state.maxProduction = filter.maxProduction;
    state.minNpv10 = filter.minNpv10;
    state.maxNpv10 = filter.maxNpv10;
    state.liquidPipelineIds = filter.liquidPipelineIds;
    state.gasPipelineIds = filter.gasPipelineIds;
    state.licenceStatus = filter.licenceStatus;
  }

  _update = (closeFilters) => {
    const { filter } = this.props;

    filter.followMap = this.state.followMap;
    filter.fieldIds = this.state.fieldIds;
    filter.operatorIds = this.state.operatorIds;
    filter.participantIds = this.state.participantIds;
    filter.countryIds = this.state.countryIds;
    filter.areaIds = this.state.areaIds;
    filter.hubIds = this.state.hubIds;
    filter.statusIds = this.state.statusIds;
    filter.reservoirAgeIds = this.state.reservoirAgeIds;
    filter.minRemainingReserves = this.state.minRemainingReserves;
    filter.maxRemainingReserves = this.state.maxRemainingReserves;
    filter.minProduction = this.state.minProduction;
    filter.maxProduction = this.state.maxProduction;
    filter.minNpv10 = this.state.minNpv10;
    filter.maxNpv10 = this.state.maxNpv10;
    filter.liquidPipelineIds = this.state.liquidPipelineIds;
    filter.gasPipelineIds = this.state.gasPipelineIds;
    filter.licenceStatus = this.state.licenceStatus;

    filter.clearInvalidValues();

    this.props.onUpdate(closeFilters);
  };

  _clear = () => {
    this.props.filter.clear();
    this.loadFromFilter(this.state);
    this._update();
  }

  onToggleFollowMap = () => this.setState(prevState => { return { followMap: !prevState.followMap }; });
  onFieldIdsChange = (value) => this.setState({ fieldIds: value });
  onOperatorIdsChange = (value) => this.setState({ operatorIds: value });
  onParticipantIdsChange = (value) => this.setState({ participantIds: value });
  onCountryIdsChange = (value) => this.setState({ countryIds: value });
  onAreaIdsChange = (value) => this.setState({ areaIds: value });
  onHubIdsChange = (value) => this.setState({ hubIds: value });
  onStatusIdsChange = (value) => this.setState({ statusIds: value });
  onReservoirAgeIdsChange = (value) => this.setState({ reservoirAgeIds: value });
  onMinRemainingReservesChange = (value) => this.setState({ minRemainingReserves: value });
  onMaxRemainingReservesChange = (value) => this.setState({ maxRemainingReserves: value });
  onMinProductionChange = (value) => this.setState({ minProduction: value });
  onMaxProductionChange = (value) => this.setState({ maxProduction: value });
  onMinNpv10Change = (value) => this.setState({ minNpv10: value });
  onMaxNpv10Change = (value) => this.setState({ maxNpv10: value });
  onLiquidPipelineIdsChange = (value) => this.setState({ liquidPipelineIds: value });
  onGasPipelineIdsChange = (value) => this.setState({ gasPipelineIds: value });
  onLicenceStatusChange = (value) => this.setState({ licenceStatus: (value === null ? 0 : value) });

  render() {
    const { minRemainingReserves, maxRemainingReserves, minProduction, maxProduction, minNpv10, maxNpv10, basicOnly } = this.state;

    const year = this.props.currentYear === undefined ? new Date().getFullYear() : this.props.currentYear;
    //const licenceValue = this.state.licenceStatus === 0 ? '' : this.state.licenceStatus;

    return (
      <BaseViewSettingsComponent
        appSetState={this.props.appSetState}
        className="field-view-settings-component"
        onUpdate={this._update}
        onClear={this._clear}
      >
        <FollowMapComponent value={this.state.followMap} enabled={this.props.followMapEnabled} onClick={this.onToggleFollowMap} />
        <MultiSelectComponent label="Field Name" value={this.state.fieldIds} values={this.state.fields} onChange={this.onFieldIdsChange} />
        <MultiSelectComponent label="Country" value={this.state.countryIds} values={this.state.countries} onChange={this.onCountryIdsChange} />
        <MultiSelectComponent label="Area" value={this.state.areaIds} values={this.state.areas} onChange={this.onAreaIdsChange} />
        <MultiSelectComponent label="Development Status" value={this.state.statusIds} values={this.state.statuses} onChange={this.onStatusIdsChange} />
        <MultiSelectComponent label="Operator" value={this.state.operatorIds} values={this.state.operators} onChange={this.onOperatorIdsChange} />
        <MultiSelectComponent label="Participant" value={this.state.participantIds} values={this.state.participants} onChange={this.onParticipantIdsChange} />
        <MultiSelectComponent label="Hub" value={this.state.hubIds} values={this.state.hubs} onChange={this.onHubIdsChange} />
        <MultiSelectComponent label="Liquid Export" value={this.state.liquidPipelineIds} values={this.state.liquidPipelines} onChange={this.onLiquidPipelineIdsChange} />
        <MultiSelectComponent label="Gas Export" value={this.state.gasPipelineIds} values={this.state.gasPipelines} onChange={this.onGasPipelineIdsChange} />
        <MultiSelectComponent label="Reservoir Age" value={this.state.reservoirAgeIds} values={this.state.reservoirAges} onChange={this.onReservoirAgeIdsChange} />
        
        {!basicOnly ?
          (createSplitDiv(
            <NumericFieldComponent label="Min" value={minRemainingReserves} max={maxRemainingReserves} onChange={this.onMinRemainingReservesChange} type="number" />,
            <NumericFieldComponent label="Max" value={maxRemainingReserves} min={minRemainingReserves} onChange={this.onMaxRemainingReservesChange} type="number" />,
            'Remaining Reserves (' + valueTypes.UnitString(valueTypes.mmmboe) + ')'
          ))
          : null
        }

        {!basicOnly ?
          (createSplitDiv(
            <NumericFieldComponent label={'Min'} value={minProduction} max={maxProduction} onChange={this.onMinProductionChange} type="number" />,
            <NumericFieldComponent label={'Max'} value={maxProduction} min={minProduction} onChange={this.onMaxProductionChange} type="number" />,
            year + ' Production (' + valueTypes.UnitString(valueTypes.mboed) + ')'
          ))
          : null
        }

        {!basicOnly ?
          (createSplitDiv(
            <NumericFieldComponent label="Min" value={minNpv10} max={maxNpv10} onChange={this.onMinNpv10Change} type="number" />,
            <NumericFieldComponent label="Max" value={maxNpv10} min={minNpv10} onChange={this.onMaxNpv10Change} type="number" />,
            'Remaining Post Tax NPV10 (' + valueTypes.UnitString(valueTypes.usdMM) + ')'
          ))
          : null
        }

      </BaseViewSettingsComponent>
    );
  }
}

FieldViewSettingsComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  filter: PropTypes.instanceOf(FieldFilter).isRequired,
  onUpdate: PropTypes.func.isRequired,
  followMapEnabled: PropTypes.bool.isRequired,
  currentYear: PropTypes.number.isRequired
};

export default FieldViewSettingsComponent;