import React from 'react';
import PropTypes from 'prop-types';

import { appIcons, appIconSizes } from '../../../app/app-icons';
import { LightGrey } from '../../../shared/scss/app-colors';

const FollowMapComponent = (props) => {
  const toggleClick = (e) => {
    e.preventDefault();
    props.onClick();
  };

  const boldStyle = { fontWeight: 'bold', textTransform: 'uppercase' };
  let color = LightGrey;
  let icon = appIcons.ToggleOff;
  let text = 'Disabled';
  let description = <React.Fragment>Results <span style={boldStyle}>will not</span> be filtered based on the current map position because it is hidden.</React.Fragment>;

  if (props.enabled) {
    color = undefined;
    if (props.value) {
      icon = appIcons.ToggleOn;
      text = 'On';
      description = <React.Fragment>Results <span style={boldStyle}>will</span> be filtered based on the current map position.</React.Fragment>;
    } else {
      icon = appIcons.ToggleOff;
      text = 'Off';
      description = <React.Fragment>Results <span style={boldStyle}>will not</span> be filtered based on the current map position.</React.Fragment>;
    }
  }

  let toggle = (
    <React.Fragment>
      <span style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '5px', color: color }}>
        {appIcons.getIcon(icon, appIconSizes.md)}
      </span>
      <span style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '5px', paddingBottom: '2px', color: color }}>
        {appIcons.getIcon(appIcons.MapMarked, appIconSizes.sm)}
      </span>
      <span style={{ display: 'inline-block', verticalAlign: 'text-top' }}>
        Map Filter: <span style={boldStyle}>{text}</span>
      </span>
    </React.Fragment>
  );

  if (props.enabled) {
    toggle = <a href="/" onClick={toggleClick} title="Toggle Map Filter">{toggle}</a>;
  }

  return (
    <div>
      {toggle}
      <br />
      {description}
    </div>
  );
};

FollowMapComponent.propTypes = {
  value: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default FollowMapComponent;