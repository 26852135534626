import { objectToEnum, getEnumDisplayName } from '../../common';

const zoomLevels = objectToEnum({
  _5000km: 0,
  _3000km: 1,
  _2000km: 2,
  _1000km: 3,
  _500km: 4,
  _200km: 5,
  _100km: 6,
  _50km: 7,
  _30km: 8,
  _10km: 9,
  _5km: 10,
  _3km: 11,
  _2km: 12,
  _1km: 13,
  _500m: 14,
  _200m: 15,
  _100m: 16
}, false);

zoomLevels.getDisplayName = (value) => {
  let result = getEnumDisplayName(zoomLevels, value);
  result = result.slice(1, result.length - 1);
  return result;
};

Object.freeze(zoomLevels);

export { zoomLevels };