class AISettingsModel {
  constructor(obj) {
    if (typeof obj.instrumentationKey !== 'string') {
      throw new Error('Invalid "InstrumentationKey" supplied to "AISettingsModel.ctor"');
    }

    this.instrumentationKey = obj.instrumentationKey;
  }
}

export { AISettingsModel };