import L from 'leaflet';
import { faSearchPlus } from '@fortawesome/pro-solid-svg-icons';

import { createButton as hlpCreateButton } from '../helpers/control-helper';

const createButton = hlpCreateButton;

const update = (zoomControl) => {
  const draggingEnabled = zoomControl.options._draggingEnabled;
  zoomControl._zoomBoxButton.style.color = draggingEnabled ? 'white' : '';
  zoomControl._zoomBoxButton.style.backgroundColor = draggingEnabled ? 'darkgrey' : '';
  zoomControl._map._container.style.cursor = draggingEnabled ? 'zoom-in' : '';
};

const toggle = (zoomControl) => {
  if (zoomControl.options._draggingEnabled === true) {
    stop(zoomControl);
  } else {
    start(zoomControl);
  }
};

const start = (zoomControl) => {
  zoomControl.options._startLatLng = null;
  zoomControl.options._draggingEnabled = true;
  update(zoomControl);

  zoomControl._map.dragging.disable();

  L.DomEvent.on(zoomControl._map, 'mousedown', zoomControl._zoomBoxButton.handleMouseDown, this);
  L.DomEvent.on(zoomControl._map, 'mousemove', zoomControl._zoomBoxButton.handleMouseMove, this);
  L.DomEvent.on(zoomControl._map, 'mouseup', zoomControl._zoomBoxButton.handleMouseUp, this);
};

const stop = (zoomControl) => {
  zoomControl.options._startLatLng = null;
  zoomControl.options._draggingEnabled = false;
  update(zoomControl);

  zoomControl._map.dragging.enable();
  L.DomEvent.off(zoomControl._map, 'mousedown', zoomControl._zoomBoxButton.handleMouseDown, this);
  L.DomEvent.off(zoomControl._map, 'mousemove', zoomControl._zoomBoxButton.handleMouseMove, this);
  L.DomEvent.off(zoomControl._map, 'mouseup', zoomControl._zoomBoxButton.handleMouseUp, this);
};

const handleMouseDown = (e, zoomControl) => {
  zoomControl.options._startLatLng = e.latlng;
}

const handleMouseMove = (e, zoomControl) => {
  if (zoomControl.options._startLatLng !== null) {
    const ne = zoomControl.options._startLatLng;
    const nw = new L.LatLng(zoomControl.options._startLatLng.lat, e.latlng.lng);
    const sw = e.latlng;
    const se = new L.LatLng(e.latlng.lat, zoomControl.options._startLatLng.lng);

    if (zoomControl.options._dragRect === null) {
      zoomControl.options._dragRect = new L.Rectangle([ne, nw, sw, se]);
      zoomControl._map.addLayer(zoomControl.options._dragRect);
    } else {
      zoomControl.options._dragRect.setLatLngs([ne, nw, sw, se]);
    }
  }
}

const handleMouseUp = (e, zoomControl) => {
  if (zoomControl.options._startLatLng !== null) {
    const bounds = L.latLngBounds([zoomControl.options._startLatLng, e.latlng]);
    if (zoomControl._map.hasLayer(zoomControl.options._dragRect)) {
      zoomControl._map.removeLayer(zoomControl.options._dragRect);
    }
    zoomControl.options._dragRect = null;
    stop(zoomControl);
    zoomControl._map.flyToBounds(bounds, { animate: true, duration: 1 });
  }
}

const addZoomBoxButton = (zoomControl) => {
  if (!(zoomControl instanceof L.Control.Zoom)) {
    throw new Error('Invalid \'zoomControl\' supplied to \'addZoomBoxButton\'');
  }

  zoomControl._zoomBoxButton = createButton(zoomControl._container, 'leaflet-control-zoom-box', faSearchPlus, () => toggle(zoomControl), { title: 'Box Zoom' });
  zoomControl._zoomBoxButton.handleMouseDown = (e) => handleMouseDown(e, zoomControl);
  zoomControl._zoomBoxButton.handleMouseMove = (e) => handleMouseMove(e, zoomControl);
  zoomControl._zoomBoxButton.handleMouseUp = (e) => handleMouseUp(e, zoomControl);
  update(zoomControl);
};

const addZoomControl = (leafletMap, position) => {
  const options = {
    position: position,
    _draggingEnabled: false,
    _startLatLng: null,
    _dragRect: null
  };
  const result = L.control.zoom(options);
  leafletMap.addControl(result);
  addZoomBoxButton(result);
  return result;
};

export { addZoomControl };