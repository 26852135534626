import React from 'react';
import PropTypes from 'prop-types';

import CorporateCashflowChart from './cashflow/corporate-cashflow-chart';
import CorporateCashflowTableComponent from './cashflow/corporate-cashflow-table-component';
import CorporateCashflowAssumptionsComponent from './cashflow/corporate-cashflow-assumptions-component';

import { CorporateDetail } from '../../../models/corporate-detail';
import { createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedCashflowComponent = (props) => {
  const { corporate } = props;

  return (
    <div ref={props.innerRef}>
      {corporate.hasFiscal && createSection100(<div style={{ height: '400px' }}><CorporateCashflowChart cashflowData={corporate.cashflow.cashflowAnnuals} /></div>, 'Cashflow')}
      {corporate.hasFiscal && createSection100(<CorporateCashflowTableComponent annual={corporate.cashflow.cashflowAnnuals} totalRow={corporate.cashflow.cashflowAnnualsTotalRow} />)}
      {corporate.hasFiscal && createSection100(<CorporateCashflowAssumptionsComponent valuationName={corporate.valuation.variableName} />, 'Economic assumptions')}
    </div>
  );
};

AdvancedCashflowComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  corporate: PropTypes.instanceOf(CorporateDetail).isRequired
};

export default AdvancedCashflowComponent;