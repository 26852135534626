import React from 'react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { appViews } from './app-views';
import { LocalStorage } from './local-storage';
import { loadingImages } from './loading-images';
//import { AtlasPanel } from '../../src/shared/helpers/enums';
import CopyLinkComponent from '../components/copy-link/copy-link-component';
import { MessageBoxIcon } from '../shared/components/message-box/message-box-classes';
import { LoadingSetState, MessageBoxSetState, MiscSetState, ViewSetState, Api, validateFuncParam } from '../shared/components/main/app-set-state-common';

import { HubDetailModel } from '../details/hub/hub-detail-model';
import { WellDetailModel } from '../details/well/well-detail-model';
import { BlockDetailModel } from '../details/block/block-detail-model';
import { FieldDetailModel } from '../details/field/field-detail-model';
import { VideoDetailModel } from '../details/video/video-detail-model';
import { ReportsDetailModel } from '../details/reports/reports-detail-model';
import { LicenceDetailModel } from '../details/licence/licence-detail-model';
import { ProspectDetailModel } from '../details/prospect/prospect-detail-model';
import { CorporateDetailModel } from '../details/corporate/corporate-detail-model';
import { DiscoveryDetailModel } from '../details/discovery/discovery-detail-model';
import { HtmlDetailModel } from '../details/html/html-detail-model';

import { checkParamsMatch } from './app-component-helper.js';
import { AppValuation } from './app-valuation';

import { TrackingType, AtlasPanel } from '../shared/helpers/enums';
import { downloadFile } from '../shared/helpers/download';

class MessageBoxSetStateEx extends MessageBoxSetState {
  constructor(logout, setMessage, showUnsubscribed, handleUnauthorized) {
    super(logout, setMessage);

    validateFuncParam(showUnsubscribed, 'showUnsubscribed', 'MessageBoxSetStateEx');
    validateFuncParam(handleUnauthorized, 'handleUnauthorized', 'MessageBoxSetStateEx');

    this.showUnsubscribed = showUnsubscribed;
    this.handleUnauthorized = handleUnauthorized;
  }

  showApiError(httpStatusCode, errorDetails, appView = null) {
    if (httpStatusCode === 401 && typeof errorDetails.rootUrl === 'string') { // Unauthorized
      this.handleUnauthorized(errorDetails.rootUrl);
    }
    else if (httpStatusCode === 402) { // Not subscribed
      const countryName = typeof errorDetails === 'object' && typeof errorDetails.errorMessage === 'string' && !errorDetails.errorMessage.startsWith("{")
        ? errorDetails.errorMessage
        : null;
      this.showUnsubscribed(appView, countryName);
    }
    else {
      return super.showApiError(httpStatusCode, errorDetails);
    }
  }

  showNotAvailableForTrialUsers() {
    this.showMessage('This functionality is not available for trial users.', null, MessageBoxIcon.Information);
  }
}

class MiscSetStateEx extends MiscSetState {
  constructor(logout, setToolbar, forceUpdate, localStorage) {
    super(logout, setToolbar);

    validateFuncParam(forceUpdate, 'forceUpdate', 'MiscSetStateEx');
    if (!(localStorage instanceof LocalStorage)) {
      throw new Error('Invalid "localStorage" param supplied to "MiscSetStateEx.ctor"');
    }

    this._forceUpdate = forceUpdate;
    this._localStorage = localStorage;
  }

  get showLabels() { return this._localStorage.showLabels; }
  set showLabels(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "MiscSetStateEx.showLabels.set"');
    }

    if (this._localStorage.showLabels !== value) {
      this._localStorage.showLabels = value;
      this._forceUpdate();
    }
  }

  get showSettingsOnHover() { return this._localStorage.showSettingsOnHover; }
  set showSettingsOnHover(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "MiscSetStateEx.showSettingsOnHover.set"');
    }

    if (this._localStorage.showSettingsOnHover !== value) {
      this._localStorage.showSettingsOnHover = value;
      this._forceUpdate();
    }
  }
}

class ViewSetStateEx extends ViewSetState {
  constructor(forceUpdate, setAppView, getViewSettings, setMessage, setDetails, api, navigationHistory, getCurrentView, showVideo) {
    super(forceUpdate, setAppView);

    validateFuncParam(getViewSettings, 'getViewSettings', 'ViewSetStateEx');
    validateFuncParam(setMessage, 'setMessage', 'ViewSetStateEx');
    validateFuncParam(setDetails, 'setDetails', 'ViewSetStateEx');
    if (!(api instanceof Api)) {
      throw new Error('Invalid "api" param supplied to "ViewSetStateEx.ctor"');
    }
    validateFuncParam(getCurrentView, 'getCurrentView', 'ViewSetStateEx');
    validateFuncParam(getCurrentView, 'showVideo', 'ViewSetStateEx');

    this.getSettings = getViewSettings;
    this._setMessage = setMessage;
    this._api = api;
    this._navigationHistory = navigationHistory;
    this._getCurrentView = getCurrentView;
    this.details = {
      getLink: this._getLink,
      show: setDetails,
      showReport: this._showReportDetails,
      showReportByFile: this._showReportDetailsByFile,
      showReportLink: (id, url = null) => this._showLink(this._api, 'api/reports/link?report_id=' + id, 'report', url),
      showField: this._showFieldDetails,
      showHub: this._showHubDetails,
      showCorporate: this._showCorporateDetails,
      showDiscovery: this._showDiscoveryDetails,
      showWell: this._showWellDetails,
      showWellLink: (id, url = null) => this._showLink(this._api, 'api/well/link?well_id=' + id, 'well', url),
      showProspect: this._showProspectDetails,
      showBlock: this._showBlockDetails,
      showBlockLink: (id, url = null) => this._showLink(this._api, 'api/block/link?block_id=' + id, 'block', url),
      showLicence: this._showLicenceDetails,
      showLicenceLink: (id, url = null) => this._showLink(this._api, 'api/licence/link?licence_id=' + id, 'licence', url),
      showFromQueryString: this._showFromQueryString
    };
    this.reports = {
      show: this._showReports,
      showFields: this._showReportsFields,
      showCorporate: this._showReportsCorporate
    };
    this.wells = {
      showWells: this._showReportsWells
    }
    this.video = {
      show: this._showVideo
    };
    this.html = {
      show: this._showHtml
    };
  }

  _showLink(api, apiUrl, linkType, url = null) {
    if (!(api instanceof ApiEx)) {
      throw new Error('Invalid "api" param supplied to "ViewSetStateEx._showLink"');
    }
    if (typeof apiUrl !== 'string') {
      throw new Error('Invalid "apiUrl" param supplied to "ViewSetStateEx._showLink"');
    }
    if (typeof linkType !== 'string') {
      throw new Error('Invalid "linkType" param supplied to "ViewSetStateEx._showLink"');
    }
    if (url !== null && typeof url !== 'string') {
      throw new Error('Invalid "url" param supplied to "ViewSetStateEx._showLink"');
    }

    const showUrl = (value) => api.messageBox.showMessage(<CopyLinkComponent linkType={linkType} url={this._getLink(value)} />, 'Copy Link...');

    if (url !== null) {
      showUrl(url);
    } else {
      api.get(apiUrl, showUrl);
    }
  }

  get current() {
    return this._getCurrentView();
  }

  _getQueryParam(name, urlSearchParams) {
    if (typeof name !== 'string' || name.length < 1) {
      throw new Error('Invalid "name" param supplied to "ViewSetStateEx._getQueryParam"');
    }
    if (!(urlSearchParams instanceof URLSearchParams)) {
      throw new Error('Invalid "urlSearchParams" param supplied to "ViewSetStateEx._getQueryParam"');
    }

    let result = null;
    name = name.toLowerCase();
    urlSearchParams.forEach((value, key) => {
      if (result === null && key.toLowerCase() === name) {
        result = value;
      }
    });
    return result;
  }

  _showVideo = (source, title, panel = AtlasPanel.None) => {
    if (typeof source !== 'string') {
      throw new Error('Invalid "source" param supplied to "ViewSetStateEx._showVideo"');
    }
    if (typeof title !== 'string') {
      throw new Error('Invalid "title" param supplied to "ViewSetStateEx._showVideo"');
    }

    this.details.show(new VideoDetailModel(panel, source, title));
  }

  _showHtml = (source, title, panel = AtlasPanel.None) => {
    if (typeof source !== 'string') {
      throw new Error('Invalid "source" param supplied to "ViewSetStateEx._showHtml"');
    }
    if (typeof title !== 'string') {
      throw new Error('Invalid "title" param supplied to "ViewSetStateEx._showHtml"');
    }

    this.details.show(new HtmlDetailModel(panel, source, title));
  }

  _getLink(url) {
    if (typeof url !== 'string') {
      throw new Error('Invalid "url" param supplied to "ViewSetStateEx._getLink"');
    }

    const result = new URL(window.location.href).origin + url;
    return result;
  };

  _showReportDetails = (id, mainid, panel = AtlasPanel.None) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" param supplied to "ViewSetStateEx._showReportDetails"');
    }
    //TODO: validate mainid
    //this.details.show(new ReportsDetailModel(panel, id, mainid));

    const page = this.current;
    downloadFile(id, this, false, 0, page, TrackingType.ReportDownload, panel, null, null, false);
  }

  _showReportDetailsByFile = (id, mainid, filename, title, panel = AtlasPanel.None) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" param supplied to "ViewSetStateEx._showReportDetails"');
    }
    //TODO: validate mainid
    //this.details.show(new ReportsDetailModel(panel, id, mainid, filename, title));

    const page = this.current;
    downloadFile(id, this, false, 0, page, TrackingType.ReportDownload, panel, filename, title, false);
  }
  _showReportDetailsFromQueryString(urlSearchParams, panel) {
    if (!(urlSearchParams instanceof URLSearchParams)) {
      throw new Error('Invalid "urlSearchParams" param supplied to "ViewSetStateEx._showReportDetailsFromQueryString"');
    }

    let searchParam = this._getQueryParam('wge_id', urlSearchParams);

    const onSuccess = (data) => {
      const id = data;
      //this.details.show(new ReportsDetailModel(panel, id, null));

      const page = this.current;
      downloadFile(id, this, false, 0, page, TrackingType.ReportDownload, panel, null, null, false);
    }

    const url = 'api/reports/get_report_id_by_wge_id?reportID=' + searchParam;
    this._api.get(url, onSuccess, null);
  }

  _showReportDetailsByFileFromQueryString(urlSearchParams, panel) {
    if (!(urlSearchParams instanceof URLSearchParams)) {
      throw new Error('Invalid "urlSearchParams" param supplied to "ViewSetStateEx._showReportDetailsByFileFromQueryString"');
    }

    let filename = this._getQueryParam('filename', urlSearchParams);
    let title = this._getQueryParam('title', urlSearchParams);
    title = title.replace("_", " ");

    //this.details.show(new ReportsDetailModel(panel, -1, null, filename, title));
    const page = this.current;
    downloadFile(-1, this, false, 0, page, TrackingType.ReportDownload, panel, filename, title, false);
  }

  _showHubDetails = (id, panel = AtlasPanel.None, callback = undefined, oldproximity = 25, proximity = 25, proximityKMLower = 1, proximityKMUpper = 500) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" param supplied to "ViewSetStateEx._showHubDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "ViewSetStateEx._showHubDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback === 'function') callback();
    if (panel === null || panel === undefined) { panel = AtlasPanel.None; }

    this.details.show(new HubDetailModel(panel, id, oldproximity, proximity, proximityKMLower, proximityKMUpper));
  }

  _showHubDetailsFromQueryString(urlSearchParams, panel) {
    if (!(urlSearchParams instanceof URLSearchParams)) {
      throw new Error('Invalid "urlSearchParams" param supplied to "ViewSetStateEx._showHubDetailsFromQueryString"');
    }

    // One of our hub id's (tbl_resource.resource_id). Can call showHub directly
    let searchParam = this._getQueryParam('hub_id', urlSearchParams) || this._getQueryParam('wge_id', urlSearchParams);
    if (typeof searchParam === 'string' && !isNaN(searchParam)) {
      const hubId = parseInt(searchParam, 10);
      this._showHubDetails(hubId, panel);
      return;
    }

    //TODO: searchParam = this._getQueryParam('some_external_hub_id', urlSearchParams);
    //TODO: if (typeof searchParam === 'string') {
    //TODO:   const onSuccess = (result) => this._showHubDetails(result, panel);
    //TODO:   this._api.get('api/hub/hub_id_for_external?id=' + searchParam, onSuccess);
    //TODO:   return;
    //TODO: }
  }

  _showFieldDetails = (id, panel = AtlasPanel.None, callback = undefined) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" param supplied to "ViewSetStateEx._showFieldDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "ViewSetStateEx._showFieldDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback === 'function') callback();
    if (panel === null || panel === undefined) { panel = AtlasPanel.None; }

    this.details.show(new FieldDetailModel(panel, id));
  }

  _showFieldDetailsFromQueryString(urlSearchParams, panel) {
    if (!(urlSearchParams instanceof URLSearchParams)) {
      throw new Error('Invalid "urlSearchParams" param supplied to "ViewSetStateEx._showFieldDetailsFromQueryString"');
    }

    // One of our field id's (tbl_resource.resource_id). Can call showField directly
    let searchParam = this._getQueryParam('field_id', urlSearchParams) || this._getQueryParam('wge_id', urlSearchParams);
    if (typeof searchParam === 'string' && !isNaN(searchParam)) {
      const fieldId = parseInt(searchParam, 10);
      this._showFieldDetails(fieldId, 0, panel);
      return;
    }

    //TODO: searchParam = this._getQueryParam('some_external_field_id', urlSearchParams);
    //TODO: if (typeof searchParam === 'string') {
    //TODO:   const onSuccess = (result) => this._showFieldDetails(result, panel);
    //TODO:   this._api.get('api/field/field_id_for_external?id=' + searchParam, onSuccess);
    //TODO:   return;
    //TODO: }
  }

  _showCorporateDetails = (id, panel = AtlasPanel.None, callback = undefined, filter = null) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" param supplied to "ViewSetStateEx._showCorporateDetails"');
    }
    //TODO: validate filter param
    if (callback !== null && callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "ViewSetStateEx._showFieldDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback === 'function') callback();
    if (panel === null || panel === undefined) { panel = AtlasPanel.None; }

    this.details.show(new CorporateDetailModel(panel, id, filter));
  }

  _showDiscoveryDetails = (id, panel = AtlasPanel.None, callback = undefined) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" param supplied to "ViewSetStateEx._showDiscoveryDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "ViewSetStateEx._showFieldDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback === 'function') callback();
    if (panel === null || panel === undefined) { panel = AtlasPanel.None; }

    this.details.show(new DiscoveryDetailModel(panel, id));
  }

  _showDiscoveryDetailsFromQueryString(urlSearchParams, panel) {
    if (!(urlSearchParams instanceof URLSearchParams)) {
      throw new Error('Invalid "urlSearchParams" param supplied to "ViewSetStateEx._showDiscoveryDetailsFromQueryString"');
    }

    // One of our discovery id's (tbl_resource.resource_id). Can call showDiscovery directly
    let searchParam = this._getQueryParam('discovery_id', urlSearchParams) || this._getQueryParam('wge_id', urlSearchParams);
    if (typeof searchParam === 'string' && !isNaN(searchParam)) {
      const discoveryId = parseInt(searchParam, 10);
      this._showDiscoveryDetails(discoveryId, panel);
      return;
    }

    //TODO: searchParam = this._getQueryParam('some_external_discovery_id', urlSearchParams);
    //TODO: if (typeof searchParam === 'string') {
    //TODO:   const onSuccess = (result) => this._showDiscoveryDetails(result, panel);
    //TODO:   this._api.get('api/discovery/discovery_id_for_external?id=' + searchParam, onSuccess);
    //TODO:   return;
    //TODO: }
  }

  _showProspectDetails = (id, panel = AtlasPanel.None, callback = undefined) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" param supplied to "ViewSetStateEx._showProspectDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "ViewSetStateEx._showFieldDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback === 'function') callback();
    if (panel === null || panel === undefined) { panel = AtlasPanel.None; }
    
    this.details.show(new ProspectDetailModel(panel, id));
  }

  _showProspectDetailsFromQueryString(urlSearchParams, panel) {
    if (!(urlSearchParams instanceof URLSearchParams)) {
      throw new Error('Invalid "urlSearchParams" param supplied to "ViewSetStateEx._showProspectDetailsFromQueryString"');
    }

    // One of our prospect id's (tbl_resource.resource_id). Can call showProspect directly
    let searchParam = this._getQueryParam('prospect_id', urlSearchParams) || this._getQueryParam('wge_id', urlSearchParams);
    if (typeof searchParam === 'string' && !isNaN(searchParam)) {
      const prospectId = parseInt(searchParam, 10);
      this._showProspectDetails(prospectId, panel);
      return;
    }

    //TODO: searchParam = this._getQueryParam('some_external_prospect_id', urlSearchParams);
    //TODO: if (typeof searchParam === 'string') {
    //TODO:   const onSuccess = (result) => this._showProspectDetails(result, panel);
    //TODO:   this._api.get('api/prospect/prospect_id_for_external?id=' + searchParam, onSuccess);
    //TODO:   return;
    //TODO: }
  }

  _showWellDetails = (id, panel = AtlasPanel.None, callback = undefined) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" param supplied to "ViewSetStateEx._showWellDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "ViewSetStateEx._showFieldDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback === 'function') callback();
    if (panel === null || panel === undefined) { panel = AtlasPanel.None; }

    this.details.show(new WellDetailModel(panel, id));
  }

  _showWellDetailsFromQueryString(urlSearchParams, panel) {
    if (!(urlSearchParams instanceof URLSearchParams)) {
      throw new Error('Invalid "urlSearchParams" param supplied to "ViewSetStateEx._showWellDetailsFromQueryString"');
    }

    const onSuccess = (result) => result.wgegWellId !== null ? this._showWellDetails(result.wgegWellId, panel) : null;
    this._api.get('api/well/well_from_query_string?' + urlSearchParams.toString(), onSuccess);
    return;
  }

  _showBlockDetails = (id, panel = AtlasPanel.None, callback = undefined) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" param supplied to "ViewSetStateEx._showBlockDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "ViewSetStateEx._showFieldDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback === 'function') callback();
    if (panel === null || panel === undefined) { panel = AtlasPanel.None; }

    this.details.show(new BlockDetailModel(panel, id));
  }

  _showBlockDetailsFromQueryString(urlSearchParams, panel) {
    if (!(urlSearchParams instanceof URLSearchParams)) {
      throw new Error('Invalid "urlSearchParams" param supplied to "ViewSetStateEx._showBlockDetailsFromQueryString"');
    }

    // One of our block id's (tbl_resource.resource_id). Can call showBlock directly
    let searchParam = this._getQueryParam('block_id', urlSearchParams) || this._getQueryParam('wge_id', urlSearchParams);
    if (typeof searchParam === 'string' && !isNaN(searchParam)) {
      const blockId = parseInt(searchParam, 10);
      this._showBlockDetails(blockId, panel);
      return;
    }

    //TODO: searchParam = this._getQueryParam('some_external_block_id', urlSearchParams);
    //TODO: if (typeof searchParam === 'string') {
    //TODO:   const onSuccess = (result) => this._showBlockDetails(result, panel);
    //TODO:   this._api.get('api/block/block_id_for_external?id=' + searchParam, onSuccess);
    //TODO:   return;
    //TODO: }
  }

  _showLicenceDetails = (id, panel = AtlasPanel.None, callback = undefined) => {
    if (typeof id !== 'number') {
      throw new Error('Invalid "id" param supplied to "ViewSetStateEx._showLicenceDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "ViewSetStateEx._showFieldDetails"');
    }
    if (callback !== null && callback !== undefined && typeof callback === 'function') callback();
    if (panel === null || panel === undefined) { panel = AtlasPanel.None; }

    this.details.show(new LicenceDetailModel(panel, id));
  }

  _showLicenceDetailsFromQueryString(urlSearchParams) {
    if (!(urlSearchParams instanceof URLSearchParams)) {
      throw new Error('Invalid "urlSearchParams" param supplied to "ViewSetStateEx._showLicenceDetailsFromQueryString"');
    }

    const onSuccess = (result) => result.wgegLicenceId !== null ? this._showLicenceDetails(result.wgegLicenceId) : null;
    this._api.get('api/licence/licence_from_query_string?' + urlSearchParams.toString(), onSuccess);
    return;
  }

  _showFromQueryString = (pathName, searchParams, panel = AtlasPanel.None) => {
    if (typeof pathName !== 'string' || pathName.length <= 1) {
      throw new Error('Invalid "pathName" param supplied to "ViewSetStateEx._showFromQueryString"');
    }
    if (!(searchParams instanceof URLSearchParams)) {
      throw new Error('Invalid "searchParams" param supplied to "ViewSetStateEx._showFromQueryString"');
    }

    switch (pathName.toLowerCase()) {
      case '/report':
        this._showReportDetailsFromQueryString(searchParams, panel);
        break;
      case '/hub':
        this._showHubDetailsFromQueryString(searchParams, panel);
        break;
      case '/field':
        this._showFieldDetailsFromQueryString(searchParams, panel);
        break;
      case '/discovery':
        this._showDiscoveryDetailsFromQueryString(searchParams, panel);
        break;
      case '/prospect':
        this._showProspectDetailsFromQueryString(searchParams, panel);
        break;
      case '/well':
        this._showWellDetailsFromQueryString(searchParams, panel);
        break;
      case '/block':
        this._showBlockDetailsFromQueryString(searchParams, panel);
        break;
      case '/licence':
        this._showLicenceDetailsFromQueryString(searchParams, panel);
        break;
      case '/general':
        this._showReportDetailsByFileFromQueryString(searchParams, panel);
        break;
      case '/assumptions':
        this._showReportDetailsByFile(-1, 0, "assumptions.pdf", "Economics Assumptions and Methodology");
        break;
      default:
        break;
    }
  }

  _showReports = () => {
    const settings = this.getSettings(appViews.Reports);
    settings.filter.clear();
    this.show(appViews.Reports)
  }

  _showReportsFields = (resourceId, countryId) => {
    const settings = this.getSettings(appViews.Reports);
    settings.filter.clear();
    settings.filter.advancedSearch = true;
    settings.filter.countryId = countryId;
    settings.filter.fieldId = resourceId;
    this.show(appViews.Reports)
  }

  _showReportsCorporate = (companyId) => {
    const settings = this.getSettings(appViews.Reports);
    settings.filter.clear();
    settings.filter.advancedSearch = true;
    settings.filter.companyId = companyId;
    this.show(appViews.Reports)
  }

  _showReportsWells = (wellIds) => {
    const settings = this.getSettings(appViews.Well);
    settings.filter.clear();
    settings.filter.wellIds = wellIds;
    this.show(appViews.Well)
  }

}

class NavigationHistory {
  constructor(localStorage) {
    if (!(localStorage instanceof LocalStorage)) {
      throw new Error('Invalid "localStorage" param supplied to "NavigationHistory.ctor"');
    }

    this.get = () => { return localStorage.navigationHistory };

    this.add = (value) => {
      if (typeof value !== 'object') {
        throw new Error('Invalid "value" param supplied to "NavigationHistory.add"');
      }
      if (appViews.isInvalid(value.detailAppView)) {
        throw new Error('Invalid "value.detailAppView" param supplied to "NavigationHistory.add"');
      }
      if (typeof value.displayName !== 'string') {
        throw new Error('Invalid "value.displayName" param supplied to "NavigationHistory.add"');
      }
      if (typeof value.params !== 'object') {
        throw new Error('Invalid "value.params" param supplied to "NavigationHistory.add"');
      }

      let navigationHistory = [...localStorage.navigationHistory];
      const idx = navigationHistory.findIndex(obj => obj.detailAppView === value.detailAppView && this._paramsMatch(value.params, obj.params));
      if (idx !== -1) {
        navigationHistory.splice(idx, 1);
      }

      value.timeStamp = new Date().toUTCString();
      navigationHistory.unshift(value);

      const MAX_HISTORY_COUNT = 20;
      if (navigationHistory.length > MAX_HISTORY_COUNT) {
        navigationHistory = navigationHistory.slice(0, MAX_HISTORY_COUNT);
      }
      localStorage.navigationHistory = navigationHistory;
    }

    this.clear = () => {
      localStorage.navigationHistory = [];
    }
  }

  _paramsMatch = (params1, params2) => {
    return checkParamsMatch(params1, params2);
  };
}

class Track {
  event = (name, value) => {
    if (window.appInsightsInitialized()) {
      window.appInsights.trackEvent({ name: name, properties: { value } });
    }
  }

  exceptions = (error) => {
    if (window.appInsightsInitialized()) {
      window.appInsights.trackException({ error: new Error(error), severityLevel: SeverityLevel.Error });
    }
  }
}

class MapBookmarks {
  constructor(localStorage) {
    if (!(localStorage instanceof LocalStorage)) {
      throw new Error('Invalid "localStorage" param supplied to "MapBookmarks.ctor"');
    }

    this._localStorage = localStorage;
  }

  get visible() { return this._localStorage.mapBookmarksVisible; }
  set visible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "MapBookmarks.visible.set"');
    }

    if (this._localStorage.mapBookmarksVisible !== value) {
      this._localStorage.mapBookmarksVisible = value;
    }
  }

  get = () => {
    return this._localStorage.mapBookmarks;
  }

  new = (lat, lng, zoom) => {
    const prefix = 'Bookmark ';

    let displayName = prefix + '1';
    const displayNames = this.get().map(obj => obj.displayName).filter(obj => obj.startsWith(prefix));
    if (displayNames.length > 0) {
      const nums = displayNames.map(obj => obj.substring(prefix.length)).filter(obj => !isNaN(obj)).map(obj => Number(obj));
      const num = Math.max.apply(null, nums) + 1;
      displayName = prefix + num.toString();
    }

    const result = { displayName: displayName, lat: lat, lng: lng, zoom: zoom, timeStamp: new Date().toUTCString() };
    const mapBookmarks = [...this._localStorage.mapBookmarks];
    mapBookmarks.push(result);
    this._localStorage.mapBookmarks = mapBookmarks;
    return result;
  }

  clear = () => {
    this._localStorage.mapBookmarks = [];
  }

  delete = (bookmark) => {
    const idx = this._findIndex(bookmark);
    if (idx !== -1) {
      const mapBookmarks = [...this._localStorage.mapBookmarks];
      mapBookmarks.splice(idx, 1);
      this._localStorage.mapBookmarks = mapBookmarks;
    }
  }

  updateLatLng = (bookmark, lat, lng) => {
    const idx = this._findIndex(bookmark);
    if (idx !== -1) {
      const mapBookmarks = [...this._localStorage.mapBookmarks];
      mapBookmarks[idx].lat = lat;
      mapBookmarks[idx].lng = lng;
      this._localStorage.mapBookmarks = mapBookmarks;
    }
  }

  updateDisplayName = (bookmark, displayName) => {
    const idx = this._findIndex(bookmark);
    if (idx !== -1) {
      const mapBookmarks = [...this._localStorage.mapBookmarks];
      mapBookmarks[idx].displayName = displayName;
      this._localStorage.mapBookmarks = mapBookmarks;
    }
  }

  _findIndex = (bookmark) => {
    const result = this._localStorage.mapBookmarks.findIndex(obj => obj.timeStamp === bookmark.timeStamp);
    return result;
  }
}

class ApiEx extends Api {
  getCached(apiUrl, onSuccess, onFail, noDelay = false, showLoading = true) {
    const onGetApiVersionSuccess = (result) => {
      apiUrl = apiUrl.indexOf('?') >= 0 ? apiUrl + '&apiversion=' + result : apiUrl + '?apiversion=' + result;
      this.getWithCache(apiUrl, onSuccess, onFail, noDelay, showLoading);
    }

    const onGetApiVersionFail = () => {
      this.getNoCache(apiUrl, onSuccess, onFail, noDelay, showLoading);
    }

    const url = 'api/app/get-version-no';
    const requestInit = { method: 'Post', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: null };
    this.callApi(url, requestInit, onGetApiVersionSuccess, onGetApiVersionFail, false, showLoading);

  }

  getNoCache(apiUrl, onSuccess, onFail, noDelay, showLoading) {
    const requestInit = { method: 'get', headers: { 'Content-Type': 'application/json', 'cache': 'no-store' } };
    this.callApi(apiUrl, requestInit, onSuccess, onFail, noDelay, showLoading);
  }

  getWithCache(apiUrl, onSuccess, onFail, noDelay, showLoading) {
    const requestInit = { method: 'get', headers: { 'Content-Type': 'application/json', 'cache': 'private' } };
    this.callApi(apiUrl, requestInit, onSuccess, onFail, noDelay, showLoading);
  }

  getCachedAsync = async (apiUrl, onSuccess, onFail, noDelay = false, showLoading = true) => {
    var vno = 0;

    const onGetApiVersionSuccess = async (result) => {
      vno = result;
    }

    const onGetApiVersionFail = async () => {
      await this.getNoCacheAsync(apiUrl, await onSuccess, await onFail, noDelay, showLoading);
    }

    const url = 'api/app/get-version-no';
    const requestInit = { method: 'Post', headers: { 'Content-Type': 'application/json' }, credentials: 'include', body: null };
    await this.callApiAsync(url, requestInit, await onGetApiVersionSuccess, await onGetApiVersionFail, false, showLoading);

    apiUrl = apiUrl.indexOf('?') >= 0 ? apiUrl + '&apiversion=' + vno : apiUrl + '?apiversion=' + vno;
    await this.getWithCacheAsync(apiUrl, await onSuccess, await onFail, noDelay, showLoading);
  }

  getNoCacheAsync = async (apiUrl, onSuccess, onFail, noDelay, showLoading) => {
    const requestInit = { method: 'get', headers: { 'Content-Type': 'application/json', 'cache': 'no-store' } };
    await this.callApiAsync(apiUrl, requestInit, await onSuccess, await onFail, noDelay, showLoading);
  }

  getWithCacheAsync = async (apiUrl, onSuccess, onFail, noDelay, showLoading) => {
    const requestInit = { method: 'get', headers: { 'Content-Type': 'application/json', 'cache': 'private' } };
    await this.callApiAsync(apiUrl, requestInit, await onSuccess, await onFail, noDelay, showLoading);
  }

  handleDetailLoadError(appView, e) {
    if (e instanceof Response) {
      if (!this.responseIsJson(e)) {
        e.text().then(txt => { this.messageBox.showApiError(e.status, { errorRef: null, errorMessage: txt }, appView); });
      } else {
        e.json().then(errorDetails => { this.messageBox.showApiError(e.status, errorDetails, appView); });
      }
    } else {
      this.messageBox.showError(e.message);
    }
  }

  execIfTrialUser(appView, isTrialUserFunc, isNotTrialUserFunc) {
    if (appView !== null && appViews.isInvalid(appView)) {
      throw new Error('Invalid "appView" param supplied to "ApiEx.execIfTrialUser"');
    }
    if (typeof isTrialUserFunc !== 'function') {
      throw new Error('Invalid "isTrialUserFunc" param supplied to "ApiEx.execIfTrialUser"');
    }
    if (typeof isNotTrialUserFunc !== 'function') {
      throw new Error('Invalid "isNotTrialUserFunc" param supplied to "ApiEx.execIfTrialUser"');
    }

    const url = appView === null ? 'api/app/is-trial-user-any-product-line' : 'api/app/is-trial-user?productLine=' + appView;
    const onSuccess = (result) => {
      if (result === true) {
        isTrialUserFunc();
      } else {
        isNotTrialUserFunc();
      }
    };
    this.get(url, onSuccess);
  }

  execOrFailIfTrialUser(appView = null, func) {
    const isTrialUserFunc = () => this.messageBox.showNotAvailableForTrialUsers();
    appView === null ? this.execIfTrialUser(null, isTrialUserFunc, func)  : this.execIfTrialUser(appView, isTrialUserFunc, func);
  }
}

//class CopyLink {
//  constructor(messageBox, api) {
//    if (!(messageBox instanceof MessageBoxSetState)) {
//      throw new Error('Invalid "messageBox" param supplied to "CopyLink.ctor"');
//    }
//    if (!(api instanceof ApiEx)) {
//      throw new Error('Invalid "api" param supplied to "CopyLink.ctor"');
//    }

//    this._messageBox = messageBox;
//    this._api = api;
//  }

//  block(blockId, url = null) {
//  }
//}

class AppSetState {
  constructor(logout, forceUpdate, setAppView, setToolbar, showLoading, hideLoading, setMessage, setDetails, getViewSettings, localStorage, showUnsubscribed, getCurrentView, showVideo, handleUnauthorized) {
    this.messageBox = new MessageBoxSetStateEx(logout, setMessage, showUnsubscribed, handleUnauthorized);
    this.loading = new LoadingSetState(loadingImages, showLoading, hideLoading);
    this.api = new ApiEx(this.messageBox, this.loading);
    this.misc = new MiscSetStateEx(logout, setToolbar, forceUpdate, localStorage);
    this.navigationHistory = new NavigationHistory(localStorage);
    this.mapBookmarks = new MapBookmarks(localStorage);
    this.view = new ViewSetStateEx(forceUpdate, setAppView, getViewSettings, setMessage, setDetails, this.api, this.navigationHistory, getCurrentView, showVideo);
    this.track = new Track();
    this.appValuation = new AppValuation();
  }
}

export { AppSetState, ApiEx as Api, NavigationHistory };
