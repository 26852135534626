import React from 'react';
import { AppSetState } from '../app/app-set-state';
import DetailLoadErrorComponent from './detail-load-error-component';
import { appViews } from '../app/app-views';

import { AtlasPanel } from '../shared/helpers/enums';
import { responseIsJson } from '../shared/helpers/api-helpers';
import ErrorMessageComponent from '../components/error-message/error-message-component';
import NonSubscribedComponent from '../components/subscription/non-subscribed-component';
import LoadingComponent, { LoadingImage } from '../shared/components/loading/loading-component';

class DetailModel {
  constructor(appView, atlasPanel) {
    if (appView !== null && appViews.isInvalid(appView)) {
      throw new Error('Invalid "appView" supplied to "DetailModel.ctor"');
    }
    if (!Object.values(AtlasPanel).includes(atlasPanel)) {
      throw new Error('Invalid "atlasPanel" supplied to "DetailModel.ctor"');
    }

    this._appView = appView;
    this._atlasPanel = atlasPanel;
    this._data = null;
    this._error = null;
    this._objName = this.__proto__.constructor.name;
    this._forceUpdate = undefined;
    this._showSettings = false;
  }

  get appView() { return this._appView; }
  get atlasPanel() { return this._atlasPanel; }
  get loaded() { return this._data !== null; }
  get forceUpdate() { return this._forceUpdate; }
  set forceUpdate(value) {
    value = typeof value === 'function' ? value : undefined;
    if (this._forceUpdate !== value) {
      this._forceUpdate = value;
    }
  }

  get showSettings() { return this._showSettings; }
  set showSettings(value) {
    value = typeof value === 'boolean' ? value : false;
    if (this._showSettings !== value) {
      this._showSettings = value;
    }
  }

  matches() {
    throw new Error('Function "matches" has not been overriden in "' + this._objName + '"');
  }

  load(appSetState, onLoadComplete) {
    throw new Error('Function "load" has not been overriden in "' + this._objName + '"');
  }

  clearError() {
    this._error = null;
  }

  handleLoadError(error, onLoadComplete) {
    const setErrorMessage = (errorMessage) => {
      switch (error.status) {
        case 402: // Not Subscribed
          this._error = {
            title: 'Not Subscribed',
            body: <NonSubscribedComponent appView={this._appView} countryName={errorMessage} />
          };
          break;
        case 404: // Not Found
          this._error = {
            title: 'Not Found',
            body: <ErrorMessageComponent message={appViews.getDisplayName(this._appView, true).toLowerCase() + ' not found.'} />
          };
          break;
        default:  // Unknown Error
          this._error = {
            title: 'Error',
            body: <ErrorMessageComponent message={'An unknown error has occurred loading this ' + appViews.getDisplayName(this._appView, true).toLowerCase() + '.'} />
          };
          break;
      }
      onLoadComplete();
    };

    if (error instanceof Response) {
      if (responseIsJson(error)) {
        error.json().then(err => setErrorMessage(err.errorMessage));
      } else {
        error.text().then(txt => setErrorMessage(txt));
      }
    } else {
      setErrorMessage(error.message);
    }
  }

  getHeaderComponent(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close) {
    throw new Error('Function "getHeaderComponent" has not been overriden in "' + this._objName + '"');
  }

  getBodyComponent(appSetState, geoserverUrl) {
    throw new Error('Function "getBodyComponent" has not been overriden in "' + this._objName + '"');
  }

  getTitle(getTitleFunc) {
    if (typeof getTitleFunc !== 'function') {
      throw new Error('Invalid "getTitleFunc" param supplied to "DetailModel.getTitle"');
    }

    if (this._error !== null) {
      return this._error.title;
    }

    if (!this.loaded) {
      return 'Loading...';
    }

    return getTitleFunc();
  }

  addNavigationHistory(appSetState, params, displayName) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "DetailModel.addNavigationHistory"');
    }
    if (typeof params !== 'number' && typeof params !== 'object') {
      throw new Error('Invalid "params" param supplied to "DetailModel.addNavigationHistory"');
    }
    if (typeof displayName !== 'string') {
      throw new Error('Invalid "displayName" param supplied to "DetailModel.addNavigationHistory"');
    }
    
    params = typeof params === 'number' ? { id: params } : params;
    appSetState.navigationHistory.add({
      detailAppView: this.appView,
      displayName: displayName,
      params: { ...params, panel: this._atlasPanel }
    });
  }

  _validateLoadParams(appSetState, onLoadComplete) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "' + this._objName + '.load"');
    }
    if (typeof onLoadComplete !== 'function') {
      throw new Error('Invalid "onLoadComplete" param supplied to "' + this._objName + '.load"');
    }
    if (this.loaded) {
      throw new Error('"' + this._objName + '.load" not available as data has been loaded');
    }
  }

  _validateGetHeaderComponentParams(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "' + this._objName + '.getHeaderComponent"');
    }
    if (backClick !== null && typeof backClick !== 'function') {
      throw new Error('Invalid "backClick" param supplied to "' + this._objName + '.getHeaderComponent"');
    }
    if (forwardClick !== null && typeof forwardClick !== 'function') {
      throw new Error('Invalid "backClick" param supplied to "' + this._objName + '.getHeaderComponent"');
    }
    if (typeof fullScreen !== 'boolean') {
      throw new Error('Invalid "fullScreen" param supplied to "' + this._objName + '.getHeaderComponent"');
    }
    if (typeof setFullScreen !== 'function') {
      throw new Error('Invalid "setFullScreen" param supplied to "' + this._objName + '.getHeaderComponent"');
    }
    if (typeof close !== 'function') {
      throw new Error('Invalid "close" param supplied to "' + this._objName + '.getHeaderComponent"');
    }
  }

  _validateGetBodyComponentParams(appSetState, geoserverUrl) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "' + this._objName + '.getBodyComponent"');
    }
    if (typeof geoserverUrl !== 'string') {
      throw new Error('Invalid "geoserverUrl" param supplied to "' + this._objName + '.getBodyComponent"');
    }
  }

  _getLoadingComponent() {
    return <LoadingComponent visible image={LoadingImage.Loading} />;
  }

  _getErrorComponent() {
    return <DetailLoadErrorComponent>{this._error.body}</DetailLoadErrorComponent>;
  }
}

export { DetailModel };