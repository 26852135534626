import { BaseFilter } from "../base/base-filter";

class FieldFilter extends BaseFilter {
  constructor() {
    super();

    this._fieldIds = undefined;
    this._operatorIds = undefined;
    this._participantIds = undefined;
    this._countryIds = undefined;
    this._areaIds = undefined;
    this._hubIds = undefined;
    this._statusIds = undefined;
    this._reservoirAgeIds = undefined;
    this._liquidPipelineIds = undefined;
    this._gasPipelineIds = undefined;
    this._minRemainingReserves = undefined;
    this._maxRemainingReserves = undefined;
    this._minProduction = undefined;
    this._maxProduction = undefined;
    this._minNpv10 = undefined;
    this._maxNpv10 = undefined;
    this.clear();
  }

  get isSet() {
    const result = super.isSet === true ||
      this.fieldIdsIsSet === true ||
      this.operatorIdsIsSet === true ||
      this.participantIdsIsSet === true ||
      this.countryIdsIsSet === true ||
      this.areaIdsIsSet === true ||
      this.hubIdsIsSet === true ||
      this.statusIdsIsSet === true ||
      this.reservoirAgeIdsIsSet === true ||
      this.liquidPipelineIdsIsSet === true ||
      this.gasPipelineIdsIsSet === true ||
      this.minRemainingReservesIsSet === true ||
      this.maxRemainingReservesIsSet === true ||
      this.minProductionIsSet === true ||
      this.maxProductionIsSet === true ||
      this.minNpv10IsSet === true ||
      this.maxNpv10IsSet === true ||
      this.licenceStatusIsSet === true;
    return result;
  }

  get fieldIds() { return this._fieldIds; }
  set fieldIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.fieldIds.set"');
    }

    if (this._fieldIds !== value) {
      this._fieldIds = value;
    }
  }
  get fieldIdsIsSet() { return this._fieldIds.length > 0; }

  get operatorIds() { return this._operatorIds; }
  set operatorIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.operatorIds.set"');
    }

    if (this._operatorIds !== value) {
      this._operatorIds = value;
    }
  }
  get operatorIdsIsSet() { return this._operatorIds.length > 0; }

  get participantIds() { return this._participantIds; }
  set participantIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.participantIds.set"');
    }

    if (this._participantIds !== value) {
      this._participantIds = value;
    }
  }
  get participantIdsIsSet() { return this._participantIds.length > 0; }

  get countryIds() { return this._countryIds; }
  set countryIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.countryIds.set"');
    }

    if (this._countryIds !== value) {
      this._countryIds = value;
    }
  }
  get countryIdsIsSet() { return this._countryIds.length > 0; }

  get areaIds() { return this._areaIds; }
  set areaIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.areaIds.set"');
    }

    if (this._areaIds !== value) {
      this._areaIds = value;
    }
  }
  get areaIdsIsSet() { return this._areaIds.length > 0; }

  get hubIds() { return this._hubIds; }
  set hubIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.hubIds.set"');
    }

    if (this._hubIds !== value) {
      this._hubIds = value;
    }
  }
  get hubIdsIsSet() { return this._hubIds.length > 0; }

  get statusIds() { return this._statusIds; }
  set statusIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.statusIds.set"');
    }

    if (this._statusIds !== value) {
      this._statusIds = value;
    }
  }
  get statusIdsIsSet() { return this._statusIds.length > 0; }

  get reservoirAgeIds() { return this._reservoirAgeIds; }
  set reservoirAgeIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.reservoirAgeIds.set"');
    }

    if (this._reservoirAgeIds !== value) {
      this._reservoirAgeIds = value;
    }
  }
  get reservoirAgeIdsIsSet() { return this._reservoirAgeIds.length > 0; }

  get liquidPipelineIds() { return this._liquidPipelineIds; }
  set liquidPipelineIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.liquidPipelineIds.set"');
    }

    if (this._liquidPipelineIds !== value) {
      this._liquidPipelineIds = value;
    }
  }
  get liquidPipelineIdsIsSet() { return this._liquidPipelineIds.length > 0; }

  get gasPipelineIds() { return this._gasPipelineIds; }
  set gasPipelineIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "FieldFilter.gasPipelineIds.set"');
    }

    if (this._gasPipelineIds !== value) {
      this._gasPipelineIds = value;
    }
  }
  get gasPipelineIdsIsSet() { return this._gasPipelineIds.length > 0; }

  get minRemainingReserves() { return this._minRemainingReserves; }
  set minRemainingReserves(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "FieldFilter.minRemainingReserves.set"');
    }

    if (this._minRemainingReserves !== value) {
      this._minRemainingReserves = value;
    }
  }
  get minRemainingReservesIsSet() { return this._minRemainingReserves !== null; }

  get maxRemainingReserves() { return this._maxRemainingReserves; }
  set maxRemainingReserves(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "FieldFilter.maxRemainingReserves.set"');
    }

    if (this._maxRemainingReserves !== value) {
      this._maxRemainingReserves = value;
    }
  }
  get maxRemainingReservesIsSet() { return this._maxRemainingReserves !== null; }

  get minProduction() { return this._minProduction; }
  set minProduction(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "FieldFilter.minProduction.set"');
    }

    if (this._minProduction !== value) {
      this._minProduction = value;
    }
  }
  get minProductionIsSet() { return this._minProduction !== null; }

  get maxProduction() { return this._maxProduction; }
  set maxProduction(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "FieldFilter.maxProduction.set"');
    }

    if (this._maxProduction !== value) {
      this._maxProduction = value;
    }
  }
  get maxProductionIsSet() { return this._maxProduction !== null; }

  get minNpv10() { return this._minNpv10; }
  set minNpv10(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "FieldFilter.minNpv10.set"');
    }

    if (this._minNpv10 !== value) {
      this._minNpv10 = value;
    }
  }
  get minNpv10IsSet() { return this._minNpv10 !== null; }

  get maxNpv10() { return this._maxNpv10; }
  set maxNpv10(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "FieldFilter.maxNpv10.set"');
    }

    if (this._maxNpv10 !== value) {
      this._maxNpv10 = value;
    }
  }
  get maxNpv10IsSet() { return this._maxNpv10 !== null; }

  get licenceStatus() { return this._licenceStatus; }
  set licenceStatus(key) {
    if (key !== null && typeof key !== 'number') {
      throw new Error('Invalid "value" param supplied to "FieldFilter.licenceStatus.set"');
    }

    if (this._licenceStatus !== key) {
      this._licenceStatus = key;
    }
  }
  get licenceStatusIsSet() { return this._licenceStatus !== 0; }

  clear() {
    super.clear();

    this._fieldIds = [];
    this._operatorIds = [];
    this._participantIds = [];
    this._countryIds = [];
    this._areaIds = [];
    this._hubIds = [];
    this._statusIds = [];
    this._reservoirAgeIds = [];
    this._liquidPipelineIds = [];
    this._gasPipelineIds = [];
    this._minRemainingReserves = null;
    this._maxRemainingReserves = null;
    this._minProduction = null;
    this._maxProduction = null;
    this._minNpv10 = null;
    this._maxNpv10 = null;
    this._licenceStatus = 0;
  }

  clone() {
    const result = new FieldFilter();
    super.clone(result);
    result._fieldIds = [...this._fieldIds];
    result._operatorIds = [...this._operatorIds]
    result._participantIds = [...this._participantIds];
    result._countryIds = [...this._countryIds];
    result._areaIds = [...this._areaIds];
    result._hubIds = [...this._hubIds];
    result._statusIds = [...this._statusIds];
    result._reservoirAgeIds = [...this._reservoirAgeIds];
    result._liquidPipelineIds = [...this._liquidPipelineIds];
    result._gasPipelineIds = [...this._gasPipelineIds];
    result._minRemainingReserves = this._minRemainingReserves;
    result._maxRemainingReserves = this._maxRemainingReserves;
    result._minProduction = this._minProduction;
    result._maxProduction = this._maxProduction;
    result._minNpv10 = this._minNpv10;
    result._maxNpv10 = this._maxNpv10;
    result._licenceStatus = this._licenceStatus;
    return result;
  }

  clearInvalidValues() {
    if (this.minRemainingReservesIsSet && this.maxRemainingReservesIsSet && this._minRemainingReserves > this._maxRemainingReserves) {
      this.minRemainingReserves = null;
      this.maxRemainingReserves = null;
    }

    if (this.minProductionIsSet && this.maxProductionIsSet && this._minProduction > this._maxProduction) {
      this.minProduction = null;
      this.maxProduction = null;
    }

    if (this.minNpv10IsSet && this.maxNpv10IsSet && this._minNpv10 > this._maxNpv10) {
      this.minNpv10 = null;
      this.maxNpv10 = null;
    }
  }

  toApiParam() {
    const result = super.toApiParam();
    result.fieldIds = this._fieldIds;
    result.operatorIds = this._operatorIds;
    result.participantIds = this._participantIds;
    result.countryIds = this._countryIds;
    result.areaIds = this._areaIds;
    result.hubIds = this._hubIds;
    result.statusIds = this._statusIds;
    result.reservoirAgeIds = this._reservoirAgeIds;
    result.liquidPipelineIds = this._liquidPipelineIds;
    result.gasPipelineIds = this._gasPipelineIds;
    result.minRemainingReserves = this._minRemainingReserves;
    result.maxRemainingReserves = this._maxRemainingReserves;
    result.minProduction = this._minProduction;
    result.maxProduction = this._maxProduction;
    result.minNpv10 = this._minNpv10;
    result.maxNpv10 = this._maxNpv10;
    result.licenceStatus = this._licenceStatus;
    return result;
  }
}

export { FieldFilter };