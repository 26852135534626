import { ScenarioValue } from '../components/fiscal/variables/scenario-value';
import { Scenario } from '../components/fiscal/variables/scenario';

class AnnualVariables {

  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" supplied to "Annualvariables.ctor"');
    }
    if (typeof obj.gasPriceUsdMCF !== 'number') {
      throw new Error('Invalid "gasPriceUsdMCF" supplied to "Annualvariables.ctor"');
    }
    if (typeof obj.liquidPriceUsdBOE !== 'number') {
      throw new Error('Invalid "liquidPriceUsdBOE" supplied to "Annualvariables.ctor"');
    }
    if (typeof obj.year !== 'number') {
      throw new Error('Invalid "year" supplied to "Annualvariables.ctor"');
    }
    this.year = obj.year;
    this.liquidPriceUsdBOE = obj.liquidPriceUsdBOE;
    this.gasPriceUsdMCF = obj.gasPriceUsdMCF;
  }
}

class ValuationValidateResult {

  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" supplied to "ValuationValidateResult.ctor"');
    }
    if (typeof obj.valuationAttribute !== 'number') {
      throw new Error('Invalid "valuationAttribute" supplied to "ValuationValidateResult.ctor"');
    }
    if (typeof obj.year !== 'number') {
      throw new Error('Invalid "year" supplied to "ValuationValidateResult.ctor"');
    }
    if (typeof obj.message !== 'string') {
      throw new Error('Invalid "message" supplied to "ValuationValidateResult.ctor"');
    }
    this.valuationAttribute = obj.valuationAttribute;
    this.year = obj.year;
    this.message = obj.message;
  }
}

class ValuationValidate {

  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" supplied to "ValuationValidate.ctor"');
    }
    if (typeof obj.isValid !== 'boolean') {
      throw new Error('Invalid "isValid" supplied to "ValuationValidate.ctor"');
    }
    this.isValid = obj.isValid;
    this.results = obj.results !== undefined && obj.results !== null && obj.results.length > 0 ? obj.results.map(item => new ValuationValidateResult(item)) : [];
  }
}

class UserValuations {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" supplied to "UserValuation.ctor"');
    }
    if (typeof obj.variableId !== 'number') {
      throw new Error('Invalid "variableId" supplied to "UserValuation.ctor"');
    }
    if (typeof obj.variableName !== 'string') {
      throw new Error('Invalid "variableName" supplied to "UserValuation.ctor"');
    }
    if (typeof obj.isWGEVariable !== 'boolean') {
      throw new Error('Invalid "isWGEVariable" supplied to "UserValuation.ctor"');
    }
    if (typeof obj.isShared !== 'boolean') {
      throw new Error('Invalid "isShared" supplied to "UserValuation.ctor"');
    }
    if (typeof obj.isDefault !== 'boolean') {
      throw new Error('Invalid "isDefault" supplied to "UserValuation.ctor"');
    }
    if (typeof obj.discountRate !== 'number') {
      throw new Error('Invalid "discountRate" supplied to "UserValuation.ctor"');
    }
    if (typeof obj.discountStartYear !== 'number') {
      throw new Error('Invalid "discountStartYear" supplied to "UserValuation.ctor"');
    }
    if (typeof obj.currentYear !== 'number') {
      throw new Error('Invalid "currentYear" supplied to "UserValuation.ctor"');
    }
    //if (obj.validationState !== undefined || typeof obj.validationState !== 'object') {
    //  throw new Error('Invalid "obj.validationState" supplied to "SelecetdUserValuations.ctor"');
    //}
    if (typeof obj.userId !== 'number') {
      throw new Error('Invalid "userId" supplied to "UserValuation.ctor"');
    }
    if (typeof obj.dateAmended !== 'string') {
      throw new Error('Invalid "dateAmended" supplied to "UserValuation.ctor"');
    }
    if (typeof obj.isWGEDefault !== 'boolean') {
      throw new Error('Invalid "isWGEDefault" supplied to "UserValuation.ctor"');
    }
    if (typeof obj.scenario !== 'object') {
      throw new Error('Invalid "scenario" supplied to "UserValuation.ctor"');
    }
    
    this.variableId = obj.variableId;
    this.variableName = obj.variableName;
    this.isWGEVariable = obj.isWGEVariable;
    this.isShared = obj.isShared;
    this.isDefault = obj.isDefault;
    this.userId = obj.userId;
    this.discountRate = obj.discountRate;
    this.discountStartYear = obj.discountStartYear;
    this.currentYear = obj.currentYear;
    this.validationState = obj.validationState !== undefined ? new ValuationValidate(obj.validationState) : undefined;

    this.valuationAnnuals = obj.valuationAnnuals !== undefined && obj.valuationAnnuals.length > 0 ? obj.valuationAnnuals.map(item => new AnnualVariables(item)) : [];

    this.dateAmended = obj.dateAmended;
    this.isWGEDefault = obj.isWGEDefault;
    this.scenario = new Scenario(obj.scenario);
  }

  static GetNewValuation = (newName, newScenario) => {
    newScenario.scenarioId = -1;
    newScenario.variableId = -1;
    newScenario.scenarioValues.forEach(sv => sv.scenarioId = -1);

    let x = {
      variableId: -1,
      variableName: newName,
      isWGEVariable: false,
      isShared: false,
      isDefault: false,
      userId: 0,
      discountRate: 0,
      discountStartYear: 0,
      currentYear: 0,
      validationState: undefined,
      valuationAnnuals: [],
      dateAmended: '2021-01-01T00:00:00.00',
      isWGEDefault: false,
      scenario: newScenario
    };

    return new UserValuations(x);
  }
}

export { UserValuations, AnnualVariables, Scenario, ScenarioValue };