import React from 'react';
import PropTypes from 'prop-types';

import TextFieldComponent from './text-field-component';

const NumericFieldComponent = (props) => {
  const { value, max, min, disabled } = props;

  let error = '';
  if (typeof value === 'number') {
    if (typeof max === 'number' && value > max) {
      error = 'Value should not be more than ' + max;
    } else if (typeof min === 'number' && value < min) {
      error = 'Value should not be less than ' + min;
    }
  }

  return (
    <TextFieldComponent
      label={props.label}
      value={value}
      onChange={props.onChange}
      type="number"
      error={error.length > 0}
      helperText={error.length > 0 ? error : undefined}
      disabled={disabled}
    />
  );
};

NumericFieldComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
};

NumericFieldComponent.defaultProps = {
  disabled: false
};

export default NumericFieldComponent;