import { AppSetState } from "../../app/app-set-state";
import { valueTypes } from '../../shared/helpers/value-handler';
import { BaseExcelExportColumn, BaseExcelExport } from "../../shared/helpers/base-excel-export";
import { UserValuations } from '../../models/user-valuations';

class HubComparisonGridExcelExport {
  export(appSetState, dataRows, valuationOneId, valuationTwoId ) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "HubComparisonGridExcelExport.export"');
    }
    const url = 'api/valuations/load-valid-valuations';
    const onSuccess = (result) => {
      let valuations = result.variableList.map(obj => new UserValuations(obj));
      let valuationOne = valuations.find(obj => obj.variableId === valuationOneId);
      let valuationTwo = valuations.find(obj => obj.variableId === valuationTwoId);
      let baseValuation = valuations.find(obj => obj.isWGEDefault === true);

      this.doExport(appSetState, dataRows, valuationOne, valuationTwo, baseValuation);
    };
    appSetState.api.getCached(url, onSuccess, null, true);
  }

  doExport(appSetState, dataRows, valuationOne, valuationTwo, baseValuation) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "HubComparisonGridExcelExport.doExport"');
    }

    const baseExcelExport = new BaseExcelExport();
    const workbook = baseExcelExport.createWorkbook();

    const appView = "Hub Valuation Comparison"; //sheetname !== null ? sheetname : appViews.getDisplayName(appViews.Field);

    const colDefs = [
      new BaseExcelExportColumn("Production Hub", "hubName"),
      new BaseExcelExportColumn("Operator", "operatorName"),
      new BaseExcelExportColumn("Development Status", "developmentStatus"),

      new BaseExcelExportColumn("Valuation One COP Year", "valuationOneCOPYear"),
      new BaseExcelExportColumn("Valuation Two COP Year", "valuationTwoCOPYear"),
      new BaseExcelExportColumn("COP Difference", "copYearDifference"),

      new BaseExcelExportColumn('Valuation One Remaining Liquid (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'valuationOneRemainingLiquidMMB', '0.00'),
      new BaseExcelExportColumn('Valuation Two Remaining Liquid (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'valuationTwoRemainingLiquidMMB', '0.00'),
      new BaseExcelExportColumn('Remaining Liquid Difference (' + valueTypes.UnitString(valueTypes.mmb) + ')', 'remainingLiquidDifferenceMMB', '0.00'),

      new BaseExcelExportColumn('Valuation One Remaining Gas (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'valuationOneRemainingGasBCF', '0.00'),
      new BaseExcelExportColumn('Valuation Two Remaining Gas (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'valuationTwoRemainingGasBCF', '0.00'),
      new BaseExcelExportColumn('Remaining Gas Difference (' + valueTypes.UnitString(valueTypes.bcf) + ')', 'remainingGasDifferenceBCF', '0.00'),

      new BaseExcelExportColumn('Valuation One Remaining Reserves (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'valuationOneRemainingReservesMMBOE', '0.00'),
      new BaseExcelExportColumn('Valuation Two Remaining Reserves (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'valuationTwoRemainingReservesMMBOE', '0.00'),
      new BaseExcelExportColumn('Remaining Reserves Difference (' + valueTypes.UnitString(valueTypes.mmmboe) + ')', 'remainingReservesDifferenceMMBOE', '0.00'),

      new BaseExcelExportColumn('Valuation One Remaining Undiscounted Pre-Tax Cashflow (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'valuationOneRemainingUndiscountedPreTaxCashflowUSDMM', '0.00'),
      new BaseExcelExportColumn('Valuation Two Remaining Undiscounted Pre-Tax Cashflow (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'valuationTwoRemainingUndiscountedPreTaxCashflowUSDMM', '0.00'),
      new BaseExcelExportColumn('Remaining Undiscounted Pre-Tax Cashflow Difference (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'remainingUndiscountedPreTaxCashflowDifferenceUSDMM', '0.00'),
    ];

    baseExcelExport.writeSheet(workbook, appView, dataRows, colDefs);
    baseExcelExport.writeComparisonWorkbook(workbook, baseExcelExport.getAtlas5WorkbookPrefix() + appView + baseExcelExport.getAtlas5WorkbookSuffix(), valuationOne, valuationTwo, baseValuation);
  }
}

export { HubComparisonGridExcelExport };
