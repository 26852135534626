import React from 'react';
import PropTypes from 'prop-types';
import { NetProductionByParticipantChart } from './chart/net-production-by-participant-chart'

import { FieldParticipant } from './models/portfolio/field-detail'

const NetProductionByParticipantComponent = (props) => {
  const { owners } = props;

  const participants = owners.map(par => {
    return {
      name: par.operatorDisplayName,
      color: par.color,
      values: par.combinedProductionYears.map(pro => {
        return {
          year: pro.year,
          value: pro.combinedMboed
        }
      })
    }
  });

  return <NetProductionByParticipantChart participants={participants} isStacked chartType="Line" />;
}

NetProductionByParticipantComponent.propTypes = {
  owners: PropTypes.arrayOf(PropTypes.instanceOf(FieldParticipant)).isRequired
};

export default NetProductionByParticipantComponent;
