import React from 'react';
import PropTypes from 'prop-types';
import { WgeSectorsRed, WgeAnalyticsGreen } from '../../../shared/scss/wge-colors'
import { valueTypes } from '../../../shared/helpers/value-handler';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const NetRemainingReservesByParticipantChart = (props) => {
  const { participants, isStacked } = props;

  const chartData = {
    labels: participants.map(obj => obj.name),
    datasets: [
      {
        label: 'Liquid',
        backgroundColor: WgeAnalyticsGreen,
        borderColor: WgeAnalyticsGreen,
        data: participants.map(obj => valueTypes.ValueOnly(valueTypes.generic1, obj.netRemainingLiquidMmb))
      },
      {
        label: 'Gas',
        backgroundColor: WgeSectorsRed,
        borderColor: WgeSectorsRed,
        data: participants.map(obj => valueTypes.ValueOnly(valueTypes.generic1, obj.netRemainingGasMmboe))
      }
    ]
  };

  const chartOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: isStacked,
        title: {
          text: valueTypes.UnitString(valueTypes.mmmboe),
          display: true
        },
      },
      y: {
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: false
        },
        stacked: isStacked
      }
    },
    tooltips: { enabled: true },

    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      }
    }
  };

  return (    
   <Bar data={chartData} options={chartOptions} />
  );
}

NetRemainingReservesByParticipantChart.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    netRemainingLiquidMmb: PropTypes.number.isRequired,
    netRemainingGasMmboe: PropTypes.number.isRequired,
  })).isRequired,
  isStacked: PropTypes.bool.isRequired
};

NetRemainingReservesByParticipantChart.defaultProps = {
  participants: [],
  isStacked: true
};

export default NetRemainingReservesByParticipantChart;
