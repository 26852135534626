import React from 'react';
import PropTypes from 'prop-types';

const ApiErrorComponent = (props) => {
  let errorMessage = props.errorMessage.trim();
  if (errorMessage.length === 0) {
    errorMessage = 'An error has occurred communicating with the server.';
  }

  let errorRef = props.errorRef.trim();
  if (errorRef.length > 0) {
    errorRef = <React.Fragment><br /> <br />Please contact support quoting reference "<strong>{errorRef}</strong>".</React.Fragment>;
  }

  return <span>{errorMessage}{errorRef}</span>;
};

ApiErrorComponent.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  errorRef: PropTypes.string.isRequired
};

ApiErrorComponent.defaultProps = {
  errorMessage: '',
  errorRef: ''
};

export default ApiErrorComponent;
