import React from 'react';
import PropTypes from 'prop-types';

import { appViews } from '../app/app-views';

const StandardWarningComponent = (props) => {
  return (
    <div>
      Your current subscription only includes a standard view for {appViews.getDisplayName(props.appView)}.<br />
      For more information, please contact <a href="mailto:sales@westwoodenergy.com">sales@westwoodenergy.com</a> or call +44 (0)2037945380.<br />
    </div>
  );
};

StandardWarningComponent.propTypes = {
  appView: appViews.propType
};

export default StandardWarningComponent;