import React from 'react';
import PropTypes from 'prop-types';

const LastHubChangeComponent = (props) => {
  const { dateAmended, textValue } = props;
  return (
    <div className="summary-component" style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}>      
      <b>{dateAmended}:</b>&nbsp;{textValue}
    </div>
  );
}

LastHubChangeComponent.propTypes = {
  dateAmended: PropTypes.string.isRequired,
  textValue: PropTypes.string.isRequired
};

export default LastHubChangeComponent;
