import { appViews } from "../../app/app-views";
import { AppSetState } from "../../app/app-set-state";

import { WellFilter } from "./well-filter";
import { ExcelDataRow } from "./excel-data-row";
import { valueTypes } from "../../shared/helpers/value-handler";
import { BaseExcelExportColumn, BaseExcelExport } from "../../shared/helpers/base-excel-export";

class GridExcelExport {
  _formatDataRow(getLink, dataRow) {
    const result = { ...dataRow };
    if (typeof result.link === 'string') {
      result.link = getLink(result.link);
    }
    return result;
  }

  _writeFutureSheet(baseExcelExport, workbook, appView, dataRows) {
    const colDefs = [
      new BaseExcelExportColumn('Well Name', 'name'),
      new BaseExcelExportColumn('Country', 'countryName'),
      new BaseExcelExportColumn('Area', 'areaName'),
      new BaseExcelExportColumn('Associated Resource', 'resource'),
      new BaseExcelExportColumn('Operator', 'operatorName'),
      new BaseExcelExportColumn('Licence', 'licence'),
      new BaseExcelExportColumn('Licence Round', 'licenceRound'),
      new BaseExcelExportColumn('Licence Commitment', 'commitment'),
      new BaseExcelExportColumn('Well Type', 'typeName'),
      new BaseExcelExportColumn('Well Sub-Type', 'subTypeName'),
      new BaseExcelExportColumn('Well Status', 'statusName'),
      new BaseExcelExportColumn('Estimated Timing', 'estimatedTiming'),
      new BaseExcelExportColumn('Approved for Drilling?', 'approvedForDrilling'),
      new BaseExcelExportColumn('Drilling Rig', 'rigName'),
      new BaseExcelExportColumn('Primary Objective', 'primaryObjectiveName'),
      //TODO: Unrisked Liquid (mmbbl)
      //TODO: Unrisked Gas (bcf)
      //TODO: Unrisked Total (mmboe)
      new BaseExcelExportColumn('Water Depth (' + valueTypes.UnitString(valueTypes.feet) + ')', 'waterDepthFt', '0'),
      //TODO: Trap Type
      //TODO: Reservoir Depth (ft)
      //TODO: Reservoir Condition
    ];

    baseExcelExport.writeSheet(workbook, appView + ' - Future', dataRows, colDefs);
  }

  _writeCompletedAndActiveSheet(baseExcelExport, workbook, appView, dataRows) {
    const colDefs = [
      new BaseExcelExportColumn('Well Name', 'name'),
      new BaseExcelExportColumn('Country', 'countryName'),
      new BaseExcelExportColumn('Area', 'areaName'),
      new BaseExcelExportColumn('Associated Resource', 'resource'),
      new BaseExcelExportColumn('Operator', 'operatorName'),
      new BaseExcelExportColumn('Licence', 'licence'),
      new BaseExcelExportColumn('Licence Round', 'licenceRound'),
      new BaseExcelExportColumn('Licence Commitment', 'commitment'),
      new BaseExcelExportColumn('Well Type', 'typeName'),
      new BaseExcelExportColumn('Well Sub-Type', 'subTypeName'),
      new BaseExcelExportColumn('Well Status', 'statusName'),
      new BaseExcelExportColumn('Spud Date', 'startDate'),
      new BaseExcelExportColumn('Completion Date', 'endDate'),
      new BaseExcelExportColumn('Well Duration', 'duration'),
      new BaseExcelExportColumn('Drilling Rig', 'rigName'),
      new BaseExcelExportColumn('Estimated Well Cost (' + valueTypes.UnitString(valueTypes.usdMM) + ')', 'estimatedWellCost', '0.00'),
      new BaseExcelExportColumn('Primary Objective', 'primaryObjectiveName'),
      new BaseExcelExportColumn('Total Depth (' + valueTypes.UnitString(valueTypes.feet) + ')', 'totalDepthFt', '0'),
      new BaseExcelExportColumn('Water Depth (' + valueTypes.UnitString(valueTypes.feet) + ')', 'waterDepthFt', '0'),
      new BaseExcelExportColumn('Well Result', 'resultName'),
      //TODO: Discovery Status
      //TODO: Discovered Liquid (mmbbl)
      //TODO: Discovered Gas (bcf)
      //TODO: Discovered Total (mmboe)
      new BaseExcelExportColumn('Atlas URL', 'link')
    ];

    baseExcelExport.writeSheet(workbook, appView + ' - Completed & Active', dataRows, colDefs);
  }

  export(appSetState, filter) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "GridExcelExport.export"');
    }
    if (!(filter instanceof WellFilter)) {
      throw new Error('Invalid "filter" param supplied to "GridExcelExport.export"');
    }

    const onSuccess = (result) => {
      const excelDataRows = ExcelDataRow.FromArray(result.excelDataRows).map(obj => this._formatDataRow(appSetState.view.details.getLink, obj));

      const appView = appViews.getDisplayName(appViews.Well);
      const baseExcelExport = new BaseExcelExport();
      const workbook = baseExcelExport.createWorkbook();

      let dataRows = excelDataRows.filter(obj => obj.isFuture === true);
      this._writeFutureSheet(baseExcelExport, workbook, appView, dataRows);

      dataRows = excelDataRows.filter(obj => obj.isFuture === false);
      this._writeCompletedAndActiveSheet(baseExcelExport, workbook, appView, dataRows);

      baseExcelExport.writeWorkbook(workbook, baseExcelExport.getAtlas5WorkbookPrefix() + appView + baseExcelExport.getAtlas5WorkbookSuffix());
    };
    appSetState.api.post('api/well/excel/load', filter.toApiParam(), onSuccess);
  }

  mapExport(appSetState, mapWellDataRows, mapBaseExcelExport = null, mapWorkbook = null, sheetname = null) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "GridExcelExport.export"');
    }

    const excelDataRows = ExcelDataRow.FromArray(mapWellDataRows).map(obj => this._formatDataRow(appSetState.view.details.getLink, obj));

    let dataRows = excelDataRows.filter(obj => obj.isFuture === true);
    if (dataRows.length > 0) this._writeFutureSheet(mapBaseExcelExport, mapWorkbook, sheetname, dataRows);

    dataRows = excelDataRows.filter(obj => obj.isFuture === false);
    if (dataRows.length > 0) 
    this._writeCompletedAndActiveSheet(mapBaseExcelExport, mapWorkbook, sheetname, dataRows);
  }

}

export { GridExcelExport };
