import { BaseFilter } from "../base/base-filter";

class CorporateFilter extends BaseFilter {
  constructor() {
    super();

    this._corporateIds = undefined;
    this._countryIds = undefined;
    this._areaIds = undefined;
    this._corporateCategoryIds = undefined;
    this._minRemainingReserves = undefined;
    this._maxRemainingReserves = undefined;
    this._minCurrentYearProduction = undefined;
    this._maxCurrentYearProduction = undefined;
    this._minResourcesInUnDevelopedDiscoveries = undefined;
    this._maxResourcesInUnDevelopedDiscoveries = undefined;
    this.clear();
  }

  get isSet() {
    const result = super.isSet === true ||
      this.corporateIdsIsSet === true ||
      this.countryIdsIsSet === true ||
      this.areaIdsIsSet === true ||
      this.corporateCategoryIds === true ||
      this.minRemainingReservesIsSet === true ||
      this.maxRemainingReservesIsSet === true ||
      this.minCurrentYearProductionIsSet === true ||
      this.maxCurrentYearProductionIsSet === true ||
      this.minResourcesInUnDevelopedDiscoveriesIsSet === true ||
      this.maxResourcesInUnDevelopedDiscoveriesIsSet === true
    return result;
  }

  get corporateIds() { return this._corporateIds; }
  set corporateIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "CorporateFilter.corporateIds.set"');
    }

    if (this._corporateIds !== value) {
      this._corporateIds = value;
    }
  }
  get corporateIdsIsSet() { return this._corporateIds.length > 0; }

  get countryIds() { return this._countryIds; }
  set countryIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "CorporateFilter.countryIds.set"');
    }

    if (this._countryIds !== value) {
      this._countryIds = value;
    }
  }
  get countryIdsIsSet() { return this._countryIds.length > 0; }

  get areaIds() { return this._areaIds; }
  set areaIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "CorporateFilter.areaIds.set"');
    }

    if (this._areaIds !== value) {
      this._areaIds = value;
    }
  }
  get areaIdsIsSet() { return this._areaIds.length > 0; }

  get corporateCategoryIds() { return this._corporateCategoryIds; }
  set corporateCategoryIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "CorporateFilter.corporateCategoryIds.set"');
    }

    if (this._corporateCategoryIds !== value) {
      this._corporateCategoryIds = value;
    }
  }
  get corporateCategoryIdsIsSet() { return this._corporateCategoryIds.length > 0; }

  get minRemainingReserves() { return this._minRemainingReserves; }
  set minRemainingReserves(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "CorporateFilter.minRemainingReserves.set"');
    }

    if (this._minRemainingReserves !== value) {
      this._minRemainingReserves = value;
    }
  }
  get minRemainingReservesIsSet() { return this._minRemainingReserves !== null; }

  get maxRemainingReserves() { return this._maxRemainingReserves; }
  set maxRemainingReserves(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "CorporateFilter.maxRemainingReserves.set"');
    }

    if (this._maxRemainingReserves !== value) {
      this._maxRemainingReserves = value;
    }
  }
  get maxRemainingReservesIsSet() { return this._maxRemainingReserves !== null; }

  get minCurrentYearProduction() { return this._minCurrentYearProduction; }
  set minCurrentYearProduction(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "CorporateFilter.minCurrentYearProduction.set"');
    }

    if (this._minCurrentYearProduction !== value) {
      this._minCurrentYearProduction = value;
    }
  }
  get minCurrentYearProductionIsSet() { return this._minCurrentYearProduction !== null; }

  get maxCurrentYearProduction() { return this._maxCurrentYearProduction; }
  set maxCurrentYearProduction(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "CorporateFilter.maxCurrentYearProduction.set"');
    }

    if (this._maxCurrentYearProduction !== value) {
      this._maxCurrentYearProduction = value;
    }
  }
  get maxCurrentYearProductionIsSet() { return this._maxCurrentYearProduction !== null; }

  get minResourcesInUnDevelopedDiscoveries() { return this._minResourcesInUnDevelopedDiscoveries; }
  set minResourcesInUnDevelopedDiscoveries(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "CorporateFilter.minResourcesInUnDevelopedDiscoveries.set"');
    }

    if (this._minResourcesInUnDevelopedDiscoveries !== value) {
      this._minResourcesInUnDevelopedDiscoveries = value;
    }
  }
  get minResourcesInUnDevelopedDiscoveriesIsSet() { return this._minResourcesInUnDevelopedDiscoveries !== null; }

  get maxResourcesInUnDevelopedDiscoveries() { return this._maxResourcesInUnDevelopedDiscoveries; }
  set maxResourcesInUnDevelopedDiscoveries(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "CorporateFilter.maxResourcesInUnDevelopedDiscoveries.set"');
    }

    if (this._maxResourcesInUnDevelopedDiscoveries !== value) {
      this._maxResourcesInUnDevelopedDiscoveries = value;
    }
  }
  get maxResourcesInUnDevelopedDiscoveriesIsSet() { return this._maxResourcesInUnDevelopedDiscoveries !== null; }

  clone() {
    const result = new CorporateFilter();
    super.clone(result);
    result._corporateIds = [...this._corporateIds];
    result._countryIds = [...this._countryIds];
    result._areaIds = [...this._areaIds];
    result._corporateCategoryIds = [...this._corporateCategoryIds];
    result._minRemainingReserves = this._minRemainingReserves;
    result._maxRemainingReserves = this._maxRemainingReserves;
    result._minCurrentYearProduction = this._minCurrentYearProduction;
    result._maxCurrentYearProduction = this._maxCurrentYearProduction;
    result._minResourcesInUnDevelopedDiscoveries = this._minResourcesInUnDevelopedDiscoveries;
    result._maxResourcesInUnDevelopedDiscoveries = this._maxResourcesInUnDevelopedDiscoveries;

    return result;
  }

  clear() {
    super.clear();

    this._corporateIds = [];
    this._countryIds = [];
    this._areaIds = [];
    this._corporateCategoryIds = [];
    this._minRemainingReserves = null;
    this._maxRemainingReserves = null;
    this._minCurrentYearProduction = null;
    this._maxCurrentYearProduction = null;
    this._minResourcesInUnDevelopedDiscoveries= null;
    this._maxResourcesInUnDevelopedDiscoveries = null;
  }

  toApiParam() {
    const result = super.toApiParam();
    result.corporateIds = this._corporateIds;
    result.countryIds = this._countryIds;
    result.areaIds = this._areaIds;
    result.corporateCategoryIds = this._corporateCategoryIds;
    result.minRemainingReserves = this._minRemainingReserves;
    result.maxRemainingReserves = this._maxRemainingReserves;
    result.minCurrentYearProduction = this._minCurrentYearProduction;
    result.maxCurrentYearProduction = this._maxCurrentYearProduction;
    result.minResourcesInUnDevelopedDiscoveries = this._minResourcesInUnDevelopedDiscoveries;
    result.maxResourcesInUnDevelopedDiscoveries = this._maxResourcesInUnDevelopedDiscoveries;
    return result;
  }
}

export { CorporateFilter };