import React from 'react';
import PropTypes from 'prop-types';

import StandardComponent from './body/standard-component';
import AdvancedFieldComponent from './body/advanced-field-component';

import { appViews } from '../../app/app-views';
import { ContextLayerModel } from '../../shared/helpers/leaflet/leaflet-helper';
import { FasterPortfolio, FieldDetail } from '../../components/fiscal/models/portfolio/faster-portfolio';
import { downloadFile } from '../../shared/helpers/download';
import { TrackingType, AtlasPanel } from '../../shared/helpers/enums';

const FieldDetailComponent = (props) => {
  const { appSetState, portfolio, resource } = props;

  const onHubClick = (hubId) => {
    if (typeof hubId !== 'number') {
      throw new Error('Invalid "hubId" supplied to "FieldDetailsComponent.onHubClick"');
    }

    appSetState.view.details.showHub(hubId);
  }

  const onWellClick = (wellId) => {
    if (typeof wellId !== 'number') {
      throw new Error('Invalid "wellId" supplied to "FieldDetailsComponent.onWellClick"');
    }

    appSetState.view.details.showWell(wellId);
  }

  const onLicenceClick = (licenceId) => {
    if (typeof licenceId !== 'number') {
      throw new Error('Invalid "licenceId" supplied to "FieldDetailsComponent.onLicenceClick"');
    }

    appSetState.view.details.showLicence(licenceId);
  }

  const onBlockClick = (blockId) => {
    if (typeof blockId !== 'number') {
      throw new Error('Invalid "blockId" supplied to "FieldDetailsComponent.onBlockClick"');
    }

    appSetState.view.details.showBlock(blockId);
  }

  const onCorporateClick = (corporateId) => {
    if (typeof corporateId !== 'number') {
      throw new Error('Invalid "corporateId" supplied to "FieldDetailsComponent.onCorporateClick"');
    }

    appSetState.view.details.showCorporate(corporateId);
  };

  const openReport = (id) => {
    const page = appSetState.view.current;
    const panel = appSetState.view.current === appViews.Home ? AtlasPanel.Reports : AtlasPanel.None;
    downloadFile(id, appSetState, true, 0, page, TrackingType.ReportDownload, panel);
  }

  const _props = {
    resource: resource,
    onHubClick: onHubClick,
    onWellClick: onWellClick,
    onLicenceClick: onLicenceClick,
    onBlockClick: onBlockClick,
    onCorporateClick: onCorporateClick,
    geoserverUrl: props.geoserverUrl,
    contextLayers: props.contextLayers,
    showSettings: props.showSettings,
    appSetState: appSetState,
    closeSettings: props.closeSettings,
    update: props.update,
    advancedPlus: props.advancedPlus,
    currentDetailValuationName: props.currentDetailValuationName,
    openSettings: props.openSettings
  };
  return resource.fullView
    ? <AdvancedFieldComponent {..._props} portfolio={portfolio} openReport={openReport} />
    : <StandardComponent {..._props} appView={appViews.Field} />;
}

FieldDetailComponent.propTypes = {
  portfolio: PropTypes.instanceOf(FasterPortfolio).isRequired,
  resource: PropTypes.instanceOf(FieldDetail).isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
  appSetState: PropTypes.instanceOf(Object).isRequired, 
  geoserverUrl: PropTypes.string.isRequired,
  closeSettings: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  advancedPlus: PropTypes.bool.isRequired
};

export default FieldDetailComponent;
