class Dataset {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Dataset.ctor'); }
    if (typeof obj.yValLabel !== 'string') { throw new Error('Invalid obj.yValLabel param supplied to Dataset.ctor'); }
    if (typeof obj.yValIndex !== 'number') { throw new Error('Invalid obj.yValIndex param supplied to Dataset.ctor'); }
    if (obj.yVals !== null && !Array.isArray(obj.yVals)) { throw new Error('Invalid obj.yVals param supplied to Dataset.ctor'); }
    //TODO: PL - validate color param?

    this.yValLabel = obj.yValLabel;
    this.yValIndex = obj.yValIndex;
    this.yVals = obj.yVals.map(obj => obj);
    this.color = obj.color;
  }
}

class ChartData {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to ChartData.ctor'); }
    if (obj.xLabels !== null && !Array.isArray(obj.xLabels)) { throw new Error('Invalid obj.xLabels param supplied to ChartData.ctor'); }
    if (obj.yVal !== null && !Array.isArray(obj.yVal)) { throw new Error('Invalid obj.yVal param supplied to ChartData.ctor'); }

    this.xLabels = obj.xLabels;
    this.yVal = obj.yVal.map(obj => new Dataset(obj));
  }
}

class PieChartData {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to PieChartData.ctor'); }
    if (typeof obj.index !== 'number') { throw new Error('Invalid obj.index param supplied to PieChartData.ctor'); }
    if (typeof obj.label !== 'string') { throw new Error('Invalid obj.label param supplied to PieChartData.ctor'); }
    if (typeof obj.value !== 'number') { throw new Error('Invalid obj.value param supplied to PieChartData.ctor'); }

    this.index = obj.index;
    this.label = obj.label;
    this.value = obj.value;
    this.color = null;
  }
}

export { ChartData, Dataset, PieChartData };