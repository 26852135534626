import React from 'react';
import PropTypes from 'prop-types';

import './overview-component.scss';
import ActivitiesComponent, { ActivityDataRow, ActivityDataRowType } from './activities-component';

import { AppSetState } from '../../app/app-set-state';
import SplitterPanelComponent from '../../shared/components/splitter-panel/splitter-panel-component';
import ReportsGridComponent, { ReportsGridRow } from '../../components/reports-grid/reports-grid-component';

const OverviewComponent = (props) => {
  const handleShowMoreClick = (e) => {
    e.preventDefault();
    props.appSetState.view.reports.show();
  };

  return (
    <div className="overview-component">
      <SplitterPanelComponent primaryIndex={1} percentage={false} secondarySize={370}>
        <div className="top-div">
          <div className="title-div">
            <span className="title">Recently Published Reports</span>
            <span className="show-reports"><a href="/" onClick={handleShowMoreClick}>Show more...</a></span>
          </div>
          <div className="content-div">
            <ReportsGridComponent appSetState={props.appSetState} dataRows={props.reportDataRows} showInstancesCol={false} showReportsFull={false} />
          </div>
        </div>

        <div className="bottom-div">
          <div className="title-div">
            <span className="title">Recent Exploration and Production Activity<small> ({props.activityDataRows.length})</small></span>
          </div>
          <div className="content-div">
            <ActivitiesComponent
              appSetState={props.appSetState}
              showOnMap={props.showOnMap}
              dataRows={props.activityDataRows}
              type={props.activitiesType}
              setType={props.setActivitiesType}
              fromDate={props.activitiesFromDate}
              setFromDate={props.setActivitiesFromDate}
              toDate={props.activitiesToDate}
              setToDate={props.setActivitiesToDate}
            />
          </div>
        </div>
      </SplitterPanelComponent>
    </div>
  );
};

OverviewComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  reportDataRows: PropTypes.arrayOf(PropTypes.instanceOf(ReportsGridRow).isRequired).isRequired,
  activityDataRows: PropTypes.arrayOf(PropTypes.instanceOf(ActivityDataRow).isRequired).isRequired,
  showOnMap: PropTypes.func.isRequired,
  activitiesType: ActivityDataRowType.nullablePropType,
  setActivitiesType: PropTypes.func.isRequired,
  activitiesFromDate: PropTypes.instanceOf(Date).isRequired,
  setActivitiesFromDate: PropTypes.func.isRequired,
  activitiesToDate: PropTypes.instanceOf(Date).isRequired,
  setActivitiesToDate: PropTypes.func.isRequired
};

export default OverviewComponent;
export { ReportsGridRow, ActivityDataRow, ActivityDataRowType };