import React from 'react';
import PropTypes from 'prop-types';

const VideoDetailComponent = (props) => {
  return (
    <video style={{ width: '100%', height: 'calc(100% - 5px)' }} loop autoPlay controls>
      <source src={props.source} type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>
  );
}

export default VideoDetailComponent;

