import React from 'react';
import PropTypes from 'prop-types';

import { ProspectDetail } from '../../../models/prospect-detail';
import { valueTypes } from '../../../shared/helpers/value-handler';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const DetailsComponent = (props) => {
  const { resource } = props;

  const getRows = () => {
    return (
      <React.Fragment>
        <tr>
          <td>Phase</td>
          <td>{resource.type}</td>
        </tr>
        <tr>
          <td>Reservoir age</td>
          <td>{resource.reservoirAge}</td>
        </tr>
        <tr>
          <td>Water Depth ({valueTypes.UnitString(valueTypes.feet)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.feet, resource.waterDepthFt)}</td>
        </tr>
        <tr>
          <td>Reservoir Depth ({valueTypes.UnitString(valueTypes.feet)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.feet, resource.reservoirDepthFt)}</td>
        </tr>
        <tr>
          <td>Fluid Reservoir Condition</td>
          <td>{resource.condition}</td>
        </tr>
        <tr>
          <td>Trap Type</td>
          <td>{resource.trapType}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent className="one-col-key-value">
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

DetailsComponent.propTypes = {
  resource: PropTypes.instanceOf(ProspectDetail).isRequired
};

export default DetailsComponent;
