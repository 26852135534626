import React from 'react';
import PropTypes from 'prop-types';

import CorporateDiscoveryTableComponent from './corporate-discoveries/corporate-discoveries-table-component';

import { CorporateDetail } from '../../../models/corporate-detail';
import { createSection100, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import GenericChart, { ChartType } from '../../../shared/components/generic-chart/generic-chart';
import { valueTypes } from '../../../shared/helpers/value-handler';

const AdvancedCorporateDiscoveryTableComponent = (props) => {
  const { corporate } = props;
  var discoveryTable;

  const hasDiscos = corporate.netDiscoveries !== null && corporate.netDiscoveries.length > 0;
  const chartStyle = { height: '350px' };

  const netDiscoResource = hasDiscos && (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={corporate.chartDataCollection.netDiscoveryResource}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.mmmboe)}
          showYAxisGridLines={true}
        />
      </div>
    </div>);

  const netDiscoResourceByClassificationArea = hasDiscos && (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={corporate.chartDataCollection.netDiscoveryResourceByClassificationArea}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.mmmboe)}
          showYAxisGridLines={true}
        />
      </div>
    </div>);
  
  if (hasDiscos) {
    discoveryTable = <CorporateDiscoveryTableComponent
      currentCorporateId={corporate.corporateId}
      netDiscoveries={corporate.netDiscoveries}
      onDiscoveryClick={props.onDiscoveryClick}
      onLicenceClick={props.onLicenceClick}
      onBlockClick={props.onBlockClick}
      onWellClick={props.onWellClick}
      onOperatorClick={props.onOperatorClick}
    />;
  } else {
    discoveryTable = (<div>No discoveries</div>);
  }

  return (
    <div ref={props.innerRef}>
      {hasDiscos &&
        createSection([
          { content: netDiscoResource, sectionType: sectionTypes.section50, title: 'Net discovered resource' },
          { content: netDiscoResourceByClassificationArea, sectionType: sectionTypes.section50, title: 'Net discovered resource by area and classification'}
      ])}
      {createSection100(discoveryTable, 'Undeveloped discoveries')}
    </div>
  );
};

AdvancedCorporateDiscoveryTableComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  corporate: PropTypes.instanceOf(CorporateDetail).isRequired,
  onDiscoveryClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onWellClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default AdvancedCorporateDiscoveryTableComponent;