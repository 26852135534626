import React from 'react';
import PropTypes from 'prop-types';
import BaseViewSettingsComponent, { SelectComponent } from '../views/base/base-view-settings-component';
import { Button } from 'reactstrap';
import ErrorMessageComponent from '../components/error-message/error-message-component';
import ValuationsManagementComponent from './valuations-management-component';
import ValuationSummaryComponent from './valuation-summary-component';
import { UserValuations } from '../models/user-valuations';
import { appIcons, appIconSizes } from '../app/app-icons';

class PriceSettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { valuations: [] };

    this.loadValuations = this.loadValuations.bind(this);
    this.showManagementPanel = this.showManagementPanel.bind(this);
    this.onValuationsChange = this.onValuationsChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.appSetState.appValuation.modelOpen === true) {
      this.loadValuations();

      this.props.appSetState.appValuation.modelOpen = false;
    }
  }

  componentDidMount() {
    this.props.appSetState.appValuation.modelOpen = false;
    this.loadValuations();
  }

  loadValuations() {
    const url = 'api/valuations/load-valid-valuations';
    const onSuccess = (result) => {
      this.setState({
        valuations: result.variableList.map(obj => new UserValuations(obj)),
      });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true);
  }

  onValuationsChange = (value) => {
    const { valuations } = this.state;
    const valuation = valuations.find(obj => obj.variableId === value);

    if (this.props.isDetail) {
      this.props.appSetState.appValuation.detailValuation = valuation;
    }
    else {
      this.props.appSetState.appValuation.valuation = valuation;
      this.props.appSetState.appValuation.valuationHasChanged = false;
    }
    this.props.onUpdate(false);
  }

  onClose = (recalculate) => {
    this.loadValuations();
    if (recalculate === true && this.props.onUpdate !== undefined && typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(false)
    }
  }

  showManagementPanel = () => {
    const header =
      <React.Fragment>
        {appIcons.getIcon(appIcons.Valuations, appIconSizes.sm)}
        <span style={{ marginLeft: '10px' }}>Valuation Settings</span>
      </React.Fragment>

    this.props.appSetState.messageBox.showPanel(<ValuationsManagementComponent api={this.props.appSetState.api} appSetState={this.props.appSetState} isDetail={this.props.isDetail} onClose={this.onClose} showSelectAndExit={true} />, header);
  }

  getSelectedValuation = () => {
    const { valuations } = this.state;
    const { valuation, detailValuation } = this.props.appSetState.appValuation;
    let selectedValuation = null;
    if (this.props.isDetail) {
      selectedValuation = detailValuation === null ? valuation : detailValuation;
    } else {
      selectedValuation = valuation;
    }

    let listValuation = valuations.find(obj => obj.variableId === selectedValuation.variableId);

    return listValuation !== null && listValuation !== undefined ? listValuation : selectedValuation;
  }

  render() {
    const { valuation, baseValuation } = this.props.appSetState.appValuation;
    const detail = this.props.isDetail ? '-detail' : '';
    const hub = this.props.isHub ? ' hub' : '';
    const corporate = this.props.isCorporate ? this.props.isCorporateLarge ? ' corporate-big' : ' corporate-small' : '';

    const errorMessage = 'Valuations allows you to generate asset and company valuations using different price scenarios, including your own.' + String.fromCharCode(13) + String.fromCharCode(13) + 'You are not currently subscribed to Valuations.';
    const subscribed = this.props.advancedPlus
      ? null
      :
      <div>
        <div style={{ marginLeft: '10px', marginTop: '35px', float: 'left' }}>
          Valuations allows you to generate asset and company valuations using different price scenarios, including your own.<br /><br />
          You are not currently subscribed to Valuations.
          <ErrorMessageComponent message={''} marginLeft={'0px'} />
        </div>

      </div>;

    if (valuation === undefined || valuation === null || this.state.valuations === null || this.state.valuations.length === 0) {
      return null;
    }
    else {
      const selectedValuation = this.getSelectedValuation();
      const summary = <ValuationSummaryComponent selectedValuation={selectedValuation} baseValuation={baseValuation} isAdvancedPlus={this.props.advancedPlus} />

      return (
        <BaseViewSettingsComponent
          appSetState={this.props.appSetState}
          className={"price-settings-component" + detail + hub + corporate}
          showClear={false}
          updateButtonName={'Calculate'}
          advancedPlus={this.props.advancedPlus}
          showUpdate={false}
        >
          <SelectComponent label="Valuation" value={selectedValuation.variableId} values={this.state.valuations} onChange={this.onValuationsChange} showButtons={false} disabled={!this.props.advancedPlus} valueName={"variableId"} labelName={"variableName"} />
          {summary}
          <Button className="manage-btn" disabled={!this.props.advancedPlus} onClick={this.showManagementPanel} style={{ float: 'right', backgroundColor: '#1B365D' }}>Manage Valuations</Button>
          {subscribed}

        </BaseViewSettingsComponent>
      );
    }
  }
}

PriceSettingsComponent.propTypes = {
  isDetail: PropTypes.bool.isRequired,
  appSetState: PropTypes.instanceOf(Object).isRequired,
  isHub: PropTypes.bool,
  isCorporate: PropTypes.bool,
  isCorporateLarge: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  appSetState: PropTypes.instanceOf(Object).isRequired,
  advancedPlus: PropTypes.bool.isRequired
};

PriceSettingsComponent.defaultProps = {
  isDetail: false,
  isHub: false,
  isCorporate: false,
  isCorporateLarge: false,
  advancedPlus: false
}

export default PriceSettingsComponent;