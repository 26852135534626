import React from 'react';
import PropTypes from 'prop-types'

import { LicenceDetail } from './licence-detail';
import KeyFactsComponent from './body/key-facts-component';
import FieldsTableComponent from './body/fields-table-component';
import BlocksTableComponent from './body/blocks-table-component';
import ProspectsTableComponent from './body/prospects-table-component';
import DiscoveriesTableComponent from './body/discoveries-table-component';
import PlannedWellsTableComponent from './body/planned-wells-table-component';
import CompletedAndActiveWellsTableComponent from './body/completed-and-active-wells-table-component';

import { TrackingType } from '../../shared/helpers/enums';
import { downloadFile } from '../../shared/helpers/download';
import MapComponent from '../../components/fiscal/map/map-component';
import { searchResultType, ContextLayerModel, latLngBounds } from '../../shared/helpers/leaflet/leaflet-helper';
import DetailViewGridLayoutComponent, { createSection100, createSection, sectionTypes } from '../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const LicenceDetailComponent = (props) => {
  const { licence, appSetState } = props;

  const onBlockClick = (blockId) => {
    if (typeof blockId !== 'number') {
      throw new Error('Invalid "blockId" param supplied to "LicenceDetailsComponent.onBlockClick"');
    }

    appSetState.view.details.showBlock(blockId);
  }

  const onDiscoveryClick = (resourceId) => {
    if (typeof resourceId !== 'number') {
      throw new Error('Invalid "resourceId" param supplied to "LicenceDetailsComponent.onDiscoveryClick"');
    }

    appSetState.view.details.showDiscovery(resourceId);
  }

  const onProspectClick = (resourceId) => {
    if (typeof resourceId !== 'number') {
      throw new Error('Invalid "resourceId" param supplied to "LicenceDetailsComponent.onProspectClick"');
    }

    appSetState.view.details.showProspect(resourceId);
  }

  const onWellClick = (wellId) => {
    if (typeof wellId !== 'number') {
      throw new Error('Invalid "wellId" param supplied to "LicenceDetailsComponent.onWellClick"');
    }

    appSetState.view.details.showWell(wellId);
  }

  const onReportClick = (reportId) => {
    if (typeof reportId !== 'number') {
      throw new Error('Invalid "reportId" param supplied to "LicenceDetailsComponent.onReportClick"');
    }

    downloadFile(reportId, appSetState, true, 0, appSetState.view.current, TrackingType.ReportDownload, props.atlasPanel);
  }

  const onLicenceClick = (licenceId) => {
    if (typeof licenceId !== 'number') {
      throw new Error('Invalid "licenceId" param supplied to "LicenceDetailsComponent.onLicenceClick"');
    }

    appSetState.view.details.showLicence(licenceId);
  }

  const onCorporateClick = (corporateId) => {
    if (typeof corporateId !== 'number') {
      throw new Error('Invalid "corporateId" param supplied to "LicenceDetailsComponent.onCorporateClick"');
    }

    appSetState.view.details.showCorporate(corporateId);
  }

  const onFieldClick = (resourceId) => {
    if (typeof resourceId !== 'number') {
      throw new Error('Invalid "resourceId" param supplied to "LicenceDetailsComponent.onFieldClick"');
    }

    appSetState.view.details.showField(resourceId);
  }

  const onHubClick = (hubId) => {
    if (typeof hubId !== 'number') {
      throw new Error('Invalid "hubId" param supplied to "LicenceDetailsComponent.onHubClick"');
    }

    appSetState.view.details.showHub(hubId);
  }

  const topRow = [{ content: <KeyFactsComponent licence={licence} onOperatorClick={onCorporateClick} />, sectionType: sectionTypes.section50, title: 'Key facts' }];
  if (licence.shapes.polygons.length > 0) {
    topRow.push({ content: <div style={{ height: '100%', minHeight: '300px' }}><MapComponent geoserverUrl={props.geoserverUrl} contextLayers={props.contextLayers} bounds={latLngBounds(licence.shapes.latLngs)} zoom={searchResultType.defaultZoomLevel(searchResultType.Licence)} shapes={[licence.shapes]} /></div>, sectionType: sectionTypes.section50 });
  }

  return (
    <DetailViewGridLayoutComponent>
      {createSection(topRow)}
      {licence.fields.length > 0 && createSection100(<FieldsTableComponent fields={licence.fields} currentYear={licence.currentYear} onFieldClick={onFieldClick} onHubClick={onHubClick} onOperatorClick={onCorporateClick} />, 'Fields on block')}
      {licence.blocks.length > 0 && createSection100(<BlocksTableComponent blocks={licence.blocks} onBlockClick={onBlockClick} onOperatorClick={onCorporateClick} />, 'Blocks on licence')}
      {licence.discoveries.length > 0 && createSection100(<DiscoveriesTableComponent discoveries={licence.discoveries} onDiscoveryClick={onDiscoveryClick} onOperatorClick={onCorporateClick} />, 'Discoveries on licence')}
      {licence.prospects.length > 0 && createSection100(<ProspectsTableComponent prospects={licence.prospects} onProspectClick={onProspectClick} onOperatorClick={onCorporateClick} />, 'Prospects on licence')}
      {licence.completedAndActiveWells.length > 0 && createSection100(<CompletedAndActiveWellsTableComponent licenceId={licence.id} wells={licence.completedAndActiveWells} onWellClick={onWellClick} onReportClick={onReportClick} onLicenceClick={onLicenceClick} onOperatorClick={onCorporateClick} onProspectClick={onProspectClick} onDiscoveryClick={onDiscoveryClick} onFieldClick={onFieldClick} onProspectClick={onProspectClick} onDiscoveryClick={onDiscoveryClick} onFieldClick={onFieldClick} />, 'Completed and active E&A wells')}
      {licence.plannedWells.length > 0 && createSection100(<PlannedWellsTableComponent wells={licence.plannedWells} onWellClick={onWellClick} onOperatorClick={onCorporateClick} onProspectClick={onProspectClick} onDiscoveryClick={onDiscoveryClick} onFieldClick={onFieldClick} onBlockClick={onBlockClick} />, 'Planned E&A wells on licence')}
    </DetailViewGridLayoutComponent>
  );
};

LicenceDetailComponent.propTypes = {
  licence: PropTypes.instanceOf(LicenceDetail).isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
  appSetState: PropTypes.instanceOf(Object).isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  atlasPanel: PropTypes.number.isRequired
};

export default LicenceDetailComponent;