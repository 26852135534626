import React from 'react';

import { LicenceFilter } from './licence-filter';
import LicenceViewComponent from './licence-view-component';
import LicenceViewSettingsComponent from './licence-view-settings-component';

import { BaseViewSettings } from '../base/base-view-settings';

class LicenceViewSettings extends BaseViewSettings {
  constructor(appView, appSetState) {
    super(appView, appSetState, new LicenceFilter());

    this._settings.showOverview = false;
  }

  _update = (closeFilters) => {
    this._filter = this._filter.clone();
    if (closeFilters) {
      super.showFilters = false;
    } else {
      this.appSetState.view.forceUpdate();
    }
  };

  reset = () => {
    super.reset();
    this._settings.showOverview = false;
    this._update();
  }

  getViewSettingsComponent(settings) {
    super.getViewSettingsComponent(settings);

    return this.showFilters
      ? <LicenceViewSettingsComponent appSetState={this.appSetState} filter={this._filter} onUpdate={this._update} followMapEnabled={this._settings.showMap} />
      : null;
  }

  getViewComponent(settings) {
    super.getViewComponent(settings);

    return (
      <LicenceViewComponent
        appSetState={this.appSetState}
        geoserverUrl={settings.geoserverUrl}
        settings={this._settings}
        filter={this._filter}
        showFilters={this.showFilters}
        setShowFilters={this.setShowFilters}
        followMap={this.followMap}
        setFollowMap={this.setFollowMap}
        reset={this.reset}
      />
    );
  }
}

export { LicenceViewSettings };
