import React from 'react';
import PropTypes from 'prop-types';

import { valueTypes } from '../../../../shared/helpers/value-handler';
import BaseTableComponent from '../../../../shared/components/detail-view-grid-layout/base-table-component';
import { PortfolioCashflow } from '../../../../components/fiscal/models/portfolio/portfolio-cashflow';

const EconomicSummaryComponent = (props) => {
  const { cashflow, currentYear } = props;

  const getAnnualByYear = (theArray, year) => {
    var result = theArray.filter(x => x.year === year);
    return result !== undefined && result !== null && result.length > 0 ? result[0] : null;
  };

  const currentYearAnnual = getAnnualByYear(cashflow.cashflowAnnuals, currentYear);

  const labelWidth = 50; // Percent
  let valueWidth = 100 - labelWidth;
  if (currentYearAnnual) {
    valueWidth = valueWidth / 2;
  }

  const getRows = () => {
    return (
      <React.Fragment>
        <tr style={{ fontWeight: 'bold' }}>
          <td style={{ width: labelWidth + '%' }} />
          <td style={{ width: valueWidth + '%' }}>Remaining</td>
          {currentYearAnnual && <td style={{ width: valueWidth + '%' }}>Current year ({currentYear})</td>}
        </tr>        
        <tr>
          <td>Capex ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.usdMM, cashflow.remainingCAPEX)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.usdMM, currentYearAnnual.costForLossesOrDepreciationUsdMM)}</td>}
        </tr>
        <tr>
          <td>Opex ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.usdMM, cashflow.remainingOPEX)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.usdMM, currentYearAnnual.opexUsdMM)}</td>}
        </tr>
        <tr>
          <td>Tariff ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.usdMM, cashflow.remainingTariff)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.usdMM, currentYearAnnual.totalTariffUsdMM)}</td>}
        </tr>
        <tr>
          <td>Abex ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.usdMM, cashflow.remainingABEX)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.usdMM, currentYearAnnual.abexUsdMM)}</td>}
        </tr>
        <tr>
          <td>Revenue ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.usdMM, cashflow.remainingRevenue)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.usdMM, currentYearAnnual.totalRevenueUsdMM)}</td>}
        </tr>
        <tr>
          <td>Undiscounted pre-tax cashflow ({valueTypes.UnitString(valueTypes.usdMM)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.usdMM, cashflow.remainingPreTaxCashflow)}</td>
          {currentYearAnnual && <td>{valueTypes.ValueOnly(valueTypes.usdMM, currentYearAnnual.preTaxCashflowUsdMM)}</td>}
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent>
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

EconomicSummaryComponent.propTypes = {
  cashflow: PropTypes.instanceOf(PortfolioCashflow).isRequired,
  currentYear: PropTypes.number.isRequired
};

export default EconomicSummaryComponent;
