import React from 'react';
import PropTypes from 'prop-types';

import DetailsComponent from './key-facts/details-component';
import EconomicSummaryComponent from './key-facts/economic-summary-component';

import MapComponent from '../../../components/fiscal/map/map-component';
import { HubDetail } from '../../../models/hub-detail';
import { ContextLayerModel, latLngBounds, searchResultType } from '../../../shared/helpers/leaflet/leaflet-helper';
import { createSection100, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedKeyFactsComponent = (props) => {
  const { hub } = props;
  const { hasFiscal } = hub;

  const shapes = hub.fiscalResources.map(obj => obj.shapes);
  const bounds = latLngBounds([]);
  shapes.forEach(obj => bounds.extend(obj.latLngs));

  const leftPanel = (
    <React.Fragment>
      {createSection100(<DetailsComponent hub={hub} onOperatorClick={props.onOperatorClick} />, 'Key facts')}
      {createSection100(<EconomicSummaryComponent cashflow={hub.cashflow} currentYear={hub.currentYear} />, 'Economic summary')}
    </React.Fragment>
  );
  const rightPanel = hasFiscal
    ? <div style={{ height: '100%' }}><MapComponent geoserverUrl={props.geoserverUrl} contextLayers={props.contextLayers} bounds={bounds} zoom={searchResultType.defaultZoomLevel(searchResultType.Hub)} shapes={shapes} /></div>
    : null;

  return (
    <div ref={props.innerRef}>
      {createSection([
        { content: leftPanel, sectionType: sectionTypes.section50, noMarginVertical: true },
        { content: rightPanel, sectionType: sectionTypes.section50 }
      ])}
    </div>
  );
};

AdvancedKeyFactsComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  hub: PropTypes.instanceOf(HubDetail).isRequired,
  onOperatorClick: PropTypes.func,
  geoserverUrl: PropTypes.string.isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired
};

export default AdvancedKeyFactsComponent;