import React from 'react';
import PropTypes from 'prop-types';

import { valueTypes } from '../../../../shared/helpers/value-handler';
import { CorporateDetail } from '../../../../models/corporate-detail';
import BaseTableComponent from '../../../../shared/components/detail-view-grid-layout/base-table-component';

const ReservesFactsComponent = (props) => {
  const { corporate } = props;

  const getRows = () => {
    return (
      <React.Fragment>
        <tr style={{ fontWeight: 'bold' }}>
          <td style={{ width: '50%' }} />
          <td>Remaining</td>
        </tr>
        <tr>
          <td>Liquid ({valueTypes.UnitString(valueTypes.mmb)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, corporate.remainingLiquidMmb)}</td>
        </tr>
        <tr>
          <td>Gas ({valueTypes.UnitString(valueTypes.bcf)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, corporate.remainingGasBcf)}</td>
        </tr>
        <tr>
          <td>Total ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, corporate.remainingCombinedMmboe)}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent>
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

ReservesFactsComponent.propTypes = {
  corporate: PropTypes.instanceOf(CorporateDetail).isRequired
};

export default ReservesFactsComponent;
