import React from 'react';

import LicenceDetailComponent from './licence-detail-component';

import { DetailModel } from '../detail-model';
import { LicenceDetail } from './licence-detail';
import HeaderComponent from '../header-component';

import { appViews } from '../../app/app-views';
import { ContextLayerModel } from '../../shared/helpers/leaflet/leaflet-helper';

class LicenceDetailModel extends DetailModel {
  constructor(atlasPanel, licenceId) {
    if (typeof licenceId !== 'number') {
      throw new Error('Invalid "licenceId" supplied to "LicenceDetailModel.ctor"');
    }

    super(appViews.Licence, atlasPanel);
    this._licenceId = licenceId;
  }

  matches(detail) {
    const result =
      typeof detail === 'object' &&
      detail instanceof LicenceDetailModel &&
      detail._licenceId === this._licenceId;
    return result;
  }

  load(appSetState, onLoadComplete) {
    this._validateLoadParams(appSetState, onLoadComplete);

    const onSuccess = (result) => {
      this.clearError();
      this._data = {
        licence: new LicenceDetail(result.licence),
        contextLayers: ContextLayerModel.FromArray(result.contextLayers)
      }
      this.addNavigationHistory(appSetState, this._licenceId, this._data.licence.licenceNumber);
      onLoadComplete();
    };
    const onFail = (err) => this.handleLoadError(err, onLoadComplete);
    const url = 'api/licence/details/load/?licenceId=' + this._licenceId + '&page=' + appSetState.view.current + '&panel=' + this.atlasPanel;
    appSetState.api.getCached(url, onSuccess, onFail);
  }

  getHeaderComponent(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close) {
    this._validateGetHeaderComponentParams(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close);

    const copyLinkClick = this.loaded && this._data.licence.link !== null
      ? () => appSetState.view.details.showLicenceLink(this._licenceId, this._data.licence.link)
      : null;

    return (
      <HeaderComponent
        appView={this.appView}
        title={this.getTitle(() => this._data.licence.licenceNumber)}
        backClick={backClick}
        forwardClick={forwardClick}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        close={close}
        copyLinkClick={copyLinkClick}
      />
    );
  }

  getBodyComponent(appSetState, geoserverUrl) {
    this._validateGetBodyComponentParams(appSetState, geoserverUrl);

    if (this._error !== null) {
      return this._getErrorComponent();
    }

    if (this.loaded) {
      return (
        <LicenceDetailComponent
          licence={this._data.licence}
          contextLayers={this._data.contextLayers}
          appSetState={appSetState}
          geoserverUrl={geoserverUrl}
          atlasPanel={this.atlasPanel}
        />
      );
    }

    return this._getLoadingComponent();
  }
}

export { LicenceDetailModel };