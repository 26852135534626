import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { appIcons, appIconSizes } from './app-icons';

const ApiDocumentationComponent = (props) => {
  const _title = "API Definitions";
  const _filename = "api-definitions.pdf";

  const onClick = () => {
    props.appSetState.view.details.showReportByFile(-1, 0, _filename, _title);
  }

  //return (
  //  <Button style={{ padding: '2px 0 1px 0', fontSize: '10px', marginTop: '7px', marginRight: '5px' }} title={_title} onClick={onClick}>
  //    {props.showLabels && <span style={{ verticalAlign: 'super', paddingLeft: '5px', paddingRight: '5px' }}>Api Definitions</span>}
  //    {appIcons.getIcon(appIcons.FileAlt, appIconSizes.md)}
  //  </Button>
  //);
  return (
    null
  );
}

ApiDocumentationComponent.propTypes = {
  showLabels: PropTypes.bool.isRequired,
  right: PropTypes.bool.isRequired
};

ApiDocumentationComponent.defaultProps = {
  right: false
};

export default ApiDocumentationComponent;
