import React from 'react';
import { Button } from 'reactstrap';
import CorporateDetailComponent from './corporate-detail-component';

import { DetailModel } from '../detail-model';
import HeaderComponent, { BUTTON_ICON_SIZE } from '../header-component';
import HeaderButtonComponent from '../header-button-component';
import { AtlasPanel } from '../../shared/helpers/enums';
import { appIcons, appIconSizes } from '../../app/app-icons';
import { appViews } from '../../app/app-views';
import { CorporateDetail } from '../../models/corporate-detail';
import { ContextLayerModel } from '../../shared/helpers/leaflet/leaflet-helper';
import { CorporateDetailExcelExport } from './corporate-detail-excel-export';

class CorporateDetailModel extends DetailModel {
  constructor(atlasPanel, corporateId, filter) {
    if (typeof corporateId !== 'number') {
      throw new Error('Invalid "corporateId" supplied to "CorporateDetailModel.ctor"');
    }
    //TODO: validate filter params

    super(appViews.Corporate, atlasPanel);
    this._corporateId = corporateId;
    this._filter = filter;
    this._valuationId = 0;
    this._appSetState = null;

    this.openSettings = this.openSettings.bind(this);
  }

  matches(detail) {
    const result =
      typeof detail === 'object' &&
      detail instanceof CorporateDetailModel &&
      detail._corporateId === this._corporateId &&
      detail._valuationId === this._valuationId
    return result;
  }

  _getCorporate() {
    if (this._data === null) {
      return null;
    }

    if (this._data.showFiltered === true && this._data.corporateFiltered !== null) {
      return this._data.corporateFiltered;
    }

    return this._data.corporate;
  }

  load(appSetState, onLoadComplete) {
    this._appSetState = appSetState;
    this._validateLoadParams(appSetState, onLoadComplete);

    const onSuccess = (result) => {
      this.clearError();

      this._data = {
        corporate: new CorporateDetail(result.fullPortfolioCorporate),
        corporateFiltered: result.filteredPortfolioCorporate !== null ? new CorporateDetail(result.filteredPortfolioCorporate) : null,
        showFiltered: true,
        contextLayers: ContextLayerModel.FromArray(result.contextLayers),
        advancedPlus: result.advancedPlus,
      }
      appSetState.appValuation.detailValuation = this._data.corporate.valuation;
      this.addNavigationHistory(appSetState, this._corporateId, this._getCorporate().corporateName);
      onLoadComplete();
    };
    const onFail = (err) => this.handleLoadError(err, onLoadComplete);
    const headerBody = {
      corporateId: this._corporateId,
      atlasPage: appSetState.view.current,
      atlasPanel: this.atlasPanel,
      filter: this._filter !== null ? this._filter.toApiParam() : null
    };

    const valuationId = appSetState.appValuation.getDetailValuationId();
    this._valuationId = valuationId;
    const url = 'api/corporate/details/load?valuationId=' + valuationId;
    appSetState.api.post(url, headerBody, onSuccess, onFail, true);
  }

  toggleShowFiltered = (e) => {
    if (e !== undefined) e.preventDefault();
    this._data.showFiltered = !this._data.showFiltered;
    this.forceUpdate();
  }

  openSettings() {
    this.showSettings = !this.showSettings;
    this.forceUpdate();
  }

  reLoader = (appSetState, Id, filter) => {
    appSetState.view.details.showCorporate(Id, AtlasPanel.None, undefined, filter);
  }

  getHeaderComponent(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close) {
    this._validateGetHeaderComponentParams(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close);

    let excelExport = null;
    let isFiltered = false;
    let corporate = this._getCorporate();
    if (this.loaded && corporate !== null) {
      if (this._data.showFiltered === true && this._data.corporateFiltered !== null) {
        isFiltered = true;
      }
      excelExport = () => appSetState.api.execOrFailIfTrialUser(this.appView, () => new CorporateDetailExcelExport(corporate, isFiltered, corporate.valuation, appSetState.appValuation.baseValuation).exportAll());
    }

    const copyLinkClick = null;
    //const copyLinkClick = this.loaded
    //  ? () => appSetState.view.details.showCorporateLink(this._corporateId)
    //  : null;

    const filterDisabled = this._data === null || this._data.corporateFiltered === null;
    const filtered = !filterDisabled && this._data.showFiltered;
    const settingsTitle = this.showSettings ? 'Hide Valuations' : 'Show Valuations';

    return (
      <HeaderComponent
        appView={this.appView}
        title={this.getTitle(() => this._getCorporate().corporateName)}
        backClick={backClick}
        forwardClick={forwardClick}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        close={close}
        copyLinkClick={copyLinkClick}
      >
        <HeaderButtonComponent
          iconDef={appIcons.Valuations}
          iconSize={BUTTON_ICON_SIZE}
          iconSolid={false}
          title={settingsTitle}
          onClick={this.openSettings}
        />

        <HeaderButtonComponent
          iconDef={appIcons.Filter}
          iconSize={BUTTON_ICON_SIZE}
          iconSolid={filtered}
          title={filtered ? 'Show Full Portfolio' : 'Show Filtered Portfolio'}
          disabled={filterDisabled}
          onClick={this.toggleShowFiltered}
        />
        <HeaderButtonComponent
          iconDef={appIcons.FileExcel}
          iconSize={BUTTON_ICON_SIZE}
          title="Export to Excel"
          disabled={excelExport === null}
          onClick={excelExport}
          iconSolid={true}
          colour={'green'}
        />
      </HeaderComponent>
    );
  }

  getBodyComponent(appSetState, geoserverUrl) {
    this._validateGetBodyComponentParams(appSetState, geoserverUrl);

    if (this._error !== null) {
      return this._getErrorComponent();
    }

    if (this.loaded) {
      return (
        <CorporateDetailComponent
          corporate={this._getCorporate()}
          contextLayers={this._data.contextLayers}
          appSetState={appSetState}
          geoserverUrl={geoserverUrl}
          filtered={this._data.showFiltered === true && this._data.corporateFiltered !== null}
          filterAvailable={this._data !== null && this._data.corporateFiltered !== null}
          toggleFiltered={this.toggleShowFiltered}
          showSettings={this.showSettings}
          closeSettings={this.openSettings}
          update={this.reLoader}
          advancedPlus={this._data.advancedPlus}
          currentDetailValuationName={this._data.corporate.valuation.variableName}
          filter={this._filter}
          openSettings={this.openSettings}
        />
      );
    }

    return this._getLoadingComponent();
  }
}

export { CorporateDetailModel };
