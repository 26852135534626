import { CashflowAnnual } from './cashflow-annual';
import { handleDecimal } from '../../../../shared/helpers/decimal-places'

class PortfolioCashflow {
  constructor(portfolioCashflowObj) {
    if (typeof portfolioCashflowObj !== 'object') {
      throw new Error('Invalid cashflowObj param supplied to PortfolioCashflow.ctor');
    }
    if (!Array.isArray(portfolioCashflowObj.cashflowAnnuals)) {
      throw new Error('Invalid cashflowObj.cashflowAnnuals param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.cashflowAnnualsTotalRow !== 'object') { throw new Error('Invalid cashflowAnnualsTotalRow param supplied to PortfolioCashflow.ctor'); }
    
    if (typeof portfolioCashflowObj.grossNetPresetValue !== 'number') {
      throw new Error('Invalid annualCashflowObj.grossNetPresetValue param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.grossInternalRateOfReturn !== 'number' && portfolioCashflowObj.grossInternalRateOfReturn !== null) {
      throw new Error('Invalid annualCashflowObj.grossInternalRateOfReturn param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.grossInternalRateOfReturnPercentage !== 'number') {
      throw new Error('Invalid annualCashflowObj.grossInternalRateOfReturnPercentage param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.grossDiscountedProfitibilityIndex !== 'number' && portfolioCashflowObj.grossDiscountedProfitibilityIndex !== null) {
      throw new Error('Invalid annualCashflowObj.grossDiscountedProfitibilityIndex param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.grossNetPresetValuePerBarrel !== 'number' && portfolioCashflowObj.grossNetPresetValuePerBarrel !== null) {
      throw new Error('Invalid annualCashflowObj.grossNetPresetValuePerBarrel param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.grossGovernmentTake !== 'number') {
      throw new Error('Invalid annualCashflowObj.grossGovernmentTake param supplied to PortfolioCashflow.ctor');
    }  
    if (typeof portfolioCashflowObj.grossCAPEX !== 'number') {
      throw new Error('Invalid annualCashflowObj.grossCAPEX param supplied to PortfolioCashflow.ctor');
    }  
    if (typeof portfolioCashflowObj.grossReservesMMBOE !== 'number') {
      throw new Error('Invalid annualCashflowObj.grossReservesMMBOE param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingInternalRateOfReturnPercentage !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingInternalRateOfReturnPercentage param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingRevenue !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingRevenue param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingNetPresetValue !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingNetPresetValue param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingInternalRateOfReturn !== 'number' && portfolioCashflowObj.remainingInternalRateOfReturn !== null) {
      throw new Error('Invalid annualCashflowObj.remainingInternalRateOfReturn param supplied to PortfolioCashflow.ctor');
    }


    if (typeof portfolioCashflowObj.remainingDiscountedProfitibilityIndex !== 'number' && portfolioCashflowObj.remainingDiscountedProfitibilityIndex !== null) {
      throw new Error('Invalid annualCashflowObj.remainingDiscountedProfitibilityIndex param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingNetPresetValuePerBarrel !== 'number' && portfolioCashflowObj.remainingNetPresetValuePerBarrel !== null) {
      throw new Error('Invalid annualCashflowObj.remainingNetPresetValuePerBarrel param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingGovernmentTake !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingGovernmentTake param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingCAPEX !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingCAPEX param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingOPEX !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingOPEX param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingTariff !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingTariff param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingABEX !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingABEX param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingPreTaxCashflow !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingPreTaxCashflow param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingLiquidMMB !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingLiquidMMB param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingGasBCF !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingGasBCF param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingReservesMMBOE !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingReservesMMBOE param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingCAPEXPerBarrel !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingCAPEXPerBarrel param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingOPEXPerBarrel !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingOPEXPerBarrel param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.remainingTariffPerBarrel !== 'number') {
      throw new Error('Invalid annualCashflowObj.remainingTariffPerBarrel param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.discountRate !== 'number') {
      throw new Error('Invalid annualCashflowObj.discountRate param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.paybackYears !== 'number') {
      throw new Error('Invalid annualCashflowObj.paybackYears param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.lastProductionYear !== 'number') {
      throw new Error('Invalid annualCashflowObj.lastProductionYear param supplied to PortfolioCashflow.ctor');
    }
    if (typeof portfolioCashflowObj.id !== 'number') { throw new Error('Invalid annualCashflowObj.id param supplied to PortfolioCashflow.ctor'); }
    if (typeof portfolioCashflowObj.portfolioCashflowType !== 'number') { throw new Error('Invalid annualCashflowObj.portfolioCashflowType param supplied to PortfolioCashflow.ctor'); }

    this.id = portfolioCashflowObj.id;
    this.portfolioCashflowType = portfolioCashflowObj.portfolioCashflowType;

    this.cashflowAnnuals = portfolioCashflowObj.cashflowAnnuals.map(obj => new CashflowAnnual(obj));
    this.cashflowAnnualsTotalRow = new CashflowAnnual(portfolioCashflowObj.cashflowAnnualsTotalRow);
    
    this.grossNetPresetValue = portfolioCashflowObj.grossNetPresetValue;
    this.grossInternalRateOfReturn = portfolioCashflowObj.grossInternalRateOfReturn;
    this.grossInternalRateOfReturnPercentage = portfolioCashflowObj.grossInternalRateOfReturnPercentage;
    this.grossDiscountedProfitibilityIndex = portfolioCashflowObj.grossDiscountedProfitibilityIndex;
    this.grossNetPresetValuePerBarrel = portfolioCashflowObj.grossNetPresetValuePerBarrel;
    this.grossGovernmentTake = portfolioCashflowObj.grossGovernmentTake;
    this.grossCAPEX = portfolioCashflowObj.grossCAPEX;
    this.grossReservesMMBOE = portfolioCashflowObj.grossReservesMMBOE;   

    this.remainingRevenue = portfolioCashflowObj.remainingRevenue;
    this.remainingNetPresetValue = portfolioCashflowObj.remainingNetPresetValue;
    this.remainingInternalRateOfReturn = portfolioCashflowObj.remainingInternalRateOfReturn;
    this.remainingInternalRateOfReturnPercentage = portfolioCashflowObj.remainingInternalRateOfReturnPercentage;
    this.remainingDiscountedProfitibilityIndex = portfolioCashflowObj.remainingDiscountedProfitibilityIndex;
    this.remainingNetPresetValuePerBarrel = portfolioCashflowObj.remainingNetPresetValuePerBarrel;
    this.remainingGovernmentTake = portfolioCashflowObj.remainingGovernmentTake;
    this.remainingCAPEX = portfolioCashflowObj.remainingCAPEX;
    this.remainingOPEX = portfolioCashflowObj.remainingOPEX;
    this.remainingTariff = portfolioCashflowObj.remainingTariff;
    this.remainingABEX = portfolioCashflowObj.remainingABEX;
    this.remainingPreTaxCashflow = portfolioCashflowObj.remainingPreTaxCashflow;
    this.remainingLiquidMMB = portfolioCashflowObj.remainingLiquidMMB;    
    this.remainingGasBCF = portfolioCashflowObj.remainingGasBCF;    
    this.remainingReservesMMBOE = portfolioCashflowObj.remainingReservesMMBOE;    
    this.remainingCAPEXPerBarrel = portfolioCashflowObj.remainingCAPEXPerBarrel;
    this.remainingOPEXPerBarrel = portfolioCashflowObj.remainingOPEXPerBarrel;
    this.remainingTariffPerBarrel = portfolioCashflowObj.remainingTariffPerBarrel;

    this.discountRate = portfolioCashflowObj.discountRate;
    this.paybackYears = portfolioCashflowObj.paybackYears;
    this.lastProductionYear = portfolioCashflowObj.lastProductionYear;
  }
}

export { CashflowAnnual, PortfolioCashflow, handleDecimal };