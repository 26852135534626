import React from 'react';
import PropTypes from 'prop-types';

import HubDetailGridComponent, { createLink, HEADER_ROW_QUAD, NUMERIC_SORT } from '../hub-detail-grid-component';

import { valueTypes } from '../../../../shared/helpers/value-handler';
import { HubProximityDiscoveries } from '../../../../models/hub-detail';

const HubDiscoveryTableComponent = (props) => {
  const { onDiscoveryClick, onLicenceClick, onBlockClick, onWellClick, onOperatorClick } = props;

  const resourceNameRenderer = (params) => {
    let result = params.data.discoveryName;
    if (typeof onDiscoveryClick === 'function' && typeof params.data.discoveryId === 'number') {
      result = createLink(result, 'Show Details...', () => onDiscoveryClick(params.data.discoveryId));
    }
    return result;
  };

  const licenceRenderer = (params) => {
    let result = params.data.licence;
    if (typeof onLicenceClick === 'function' && typeof params.data.licenceId === 'number') {
      result = createLink(result, 'Show Details...', () => onLicenceClick(params.data.licenceId));
      return result;
    }
    return result;
  };

  const blockRenderer = (params) => {
    let result = params.data.blockName;
    if (typeof onBlockClick === 'function' && typeof params.data.blockId === 'number') {
      result = createLink(result, 'Show Details...', () => onBlockClick(params.data.blockId));
      return result;
    }
    return result;
  };

  const operatorRenderer = (params) => {
    let result = params.data.operatorName;
    if (typeof onOperatorClick === 'function' && typeof params.data.operatorId === 'number') {
      result = createLink(result, 'Show Details...', () => onOperatorClick(params.data.operatorId));
      return result;
    }
    return result;
  }

  const discoveryWellRenderer = (params) => {
    let result = params.data.discoveryWell;
    if (typeof onWellClick === 'function' && typeof params.data.discoveryWellId === 'number') {
      result = createLink(result, 'Show Details...', () => onWellClick(params.data.discoveryWellId));
    }
    return result;
  };

  const LiquidMmbRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.initialLiquidMmb);
  const GasBcfRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.initialGasBcf);
  const CombinedMmboeRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.combinedMmboe);
  const DistanceRenderer = (params) => valueTypes.ValueOnly(valueTypes.generic1, params.data.distance);

  const columnDefs = [
    {
      headerName: 'Discovery',
      field: 'discoveryName',
      minWidth: 135,
      cellRenderer: resourceNameRenderer
    },
    {
      headerName: 'Area',
      minWidth: 100,
      field: 'area'
    },
    {
      headerName: 'Licence',
      field: 'licence',
      minWidth: 85,
      cellRenderer: licenceRenderer
    },
    {
      headerName: 'Block',
      field: 'blockName',
      minWidth: 100,
      cellRenderer: blockRenderer
    },
    {
      headerName: 'Operator',
      field: 'operatorName',
      minWidth: 120,
      cellRenderer: operatorRenderer,
      cellRendererParams: {
        currentCorporateId: props.currentCorporateId
      }
    },
    {
      headerName: 'Discovery\nYear',
      field: 'discoveryYear',
      sortingOrder: NUMERIC_SORT,
      minWidth: 95
    },
    {
      headerName: 'Discovery\nWell',
      field: 'discoveryWell',
      minWidth: 95,
      cellRenderer: discoveryWellRenderer
    },
    {
      headerName: 'Discovery\nClassification',
      field: 'discoveryClassification',
      minWidth: 120
    },
    {
      headerName: 'Liquid\nResources\n(' + valueTypes.UnitString(valueTypes.mmmboe) + ')',
      field: 'initialLiquidMmb',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: LiquidMmbRenderer
    },
    {
      headerName: 'Gas\nResources\n(' + valueTypes.UnitString(valueTypes.bcf) + ')',
      field: 'initialGasBcf',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: GasBcfRenderer
    },
    {
      headerName: 'Resources\n(' + valueTypes.UnitString(valueTypes.mmmboe) + ')',
      field: 'combinedMmboe',
      minWidth: 105,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: CombinedMmboeRenderer
    },
    {
      headerName: 'Reservoir\nAge',
      field: 'reservoirAgeEra',
      minWidth: 150
    },
    {
      headerName: 'Distance (km)',
      field: 'distance',
      minWidth: 100,
      cellRenderer: DistanceRenderer
    }
  ];

  return <HubDetailGridComponent columnDefs={columnDefs} dataRows={props.discoveries} headerRowHeight={HEADER_ROW_QUAD} />;
};

HubDiscoveryTableComponent.propTypes = {
  discoveries: PropTypes.arrayOf(PropTypes.instanceOf(HubProximityDiscoveries)).isRequired,
  onDiscoveryClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onWellClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default HubDiscoveryTableComponent;
