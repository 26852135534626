import React from 'react';
import ApiErrorComponent from './api-error-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faQuestionCircle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

const MessageBoxIcon = {
  None: 0,
  Error: 1,
  Question: 2,
  Warning: 3,
  Information: 4
};
MessageBoxIcon.displayName = (icon) => {
  switch (icon) {
    case MessageBoxIcon.None: return '';
    case MessageBoxIcon.Error: return 'Error';
    case MessageBoxIcon.Question: return 'Question';
    case MessageBoxIcon.Warning: return 'Warning';
    case MessageBoxIcon.Information: return 'Information';
    default: throw new Error('Unsupported icon (' + icon + ') supplied to MessageBoxIcon.displayName()');
  }
};
MessageBoxIcon.getIconDefinition = (icon) => {
  switch (icon) {
    case MessageBoxIcon.None: return null;
    case MessageBoxIcon.Error:
    case MessageBoxIcon.Warning: return faExclamationTriangle;
    case MessageBoxIcon.Question: return faQuestionCircle;
    case MessageBoxIcon.Information: return faInfoCircle;
    default: throw new Error('Unsupported icon (' + icon + ') supplied to MessageBoxIcon.getIconDefinition()');
  }
};
MessageBoxIcon.getIcon = (icon) => {
  const iconDef = MessageBoxIcon.getIconDefinition(icon);
  return iconDef === null ? null : <FontAwesomeIcon icon={iconDef} size="3x" fixedWidth />
};
Object.freeze(MessageBoxIcon);

const MessageBoxButton = {
  Close: 0,
  OK: 1,
  Cancel: 2,
  Abort: 3,
  Retry: 4,
  Ignore: 5,
  Yes: 6,
  No: 7
};
MessageBoxButton.displayName = (button) => {
  switch (button) {
    case MessageBoxButton.Close: return 'Close';
    case MessageBoxButton.OK: return 'OK';
    case MessageBoxButton.Cancel: return 'Cancel';
    case MessageBoxButton.Abort: return 'Abort';
    case MessageBoxButton.Retry: return 'Retry';
    case MessageBoxButton.Ignore: return 'Ignore';
    case MessageBoxButton.Yes: return 'Yes';
    case MessageBoxButton.No: return 'No';
    default: throw new Error('Unsupported button (' + button + ') supplied to MessageBoxButton.displayName()');
  }
};
Object.freeze(MessageBoxButton);

const MessageBoxButtons = {
  OKCancel: [
    MessageBoxButton.OK,
    MessageBoxButton.Cancel
  ],
  AbortRetryIgnore: [
    MessageBoxButton.Abort,
    MessageBoxButton.Retry,
    MessageBoxButton.Ignore
  ],
  YesNoCancel: [
    MessageBoxButton.Yes,
    MessageBoxButton.No,
    MessageBoxButton.Cancel
  ],
  YesNo: [
    MessageBoxButton.Yes,
    MessageBoxButton.No
  ],
  RetryCancel: [
    MessageBoxButton.Retry,
    MessageBoxButton.Cancel
  ]
};
Object.freeze(MessageBoxButtons);

export { ApiErrorComponent, MessageBoxIcon, MessageBoxButton, MessageBoxButtons };