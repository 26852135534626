import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';

import { AppSetState } from './app-set-state';
import HelpButtonComponent from './help-button-component';
import SupportButtonComponent from './support-button-component';
import AccountDropdownComponent from './account-dropdown-component';
import WebinarsDropdownComponent from './webinars-dropdown-component';
import SettingsDropdownComponent from './settings-dropdown-component';
import NavigationDropdownComponent from './navigation-dropdown-component';
import DocumentationDropdownComponent from './documentation-dropdown-component';
import ApiDocumentationComponent from './api-documentation-component';
import WgeAppSwitcherComponent, { appDefs } from '../shared/components/main/wge-app-switcher-component';

const ToolbarItemsComponent = (props) => {
  const dividerStyle = { margin: '5px 10px', width: '2px' };

  return (
    <Nav navbar style={{ width: '100%', padding:'0px' }}>
      <WgeAppSwitcherComponent currentApp={appDefs.Atlas} apps={[appDefs.Analytics, appDefs.Energent, appDefs.GlobalLandRigs, appDefs.Offshore, appDefs.Riglogix, appDefs.Wildcat]} />
      {props.leftToolbarItems.length > 0 && <div className="bg-secondary" style={dividerStyle} />}
      {props.leftToolbarItems}

      <div className="ml-auto mr-auto" />

      {props.rightToolbarItems}
      {props.rightToolbarItems.length > 0 && <div className="bg-secondary" style={dividerStyle} />}
      <WebinarsDropdownComponent appSetState={props.appSetState} showLabels={props.showLabels} right />
      <NavigationDropdownComponent appSetState={props.appSetState} showLabels={props.showLabels} right />
      <div className="bg-secondary" style={dividerStyle} />
      <SupportButtonComponent showLabels={props.showLabels} right />
      <HelpButtonComponent showLabels={props.showLabels} right />
      <DocumentationDropdownComponent appSetState={props.appSetState} showLabels={props.showLabels} right />
      <ApiDocumentationComponent appSetState={props.appSetState} showLabels={props.showLabels} right />
      <SettingsDropdownComponent
        showLabels={props.showLabels}
        toggleShowLabels={props.toggleShowLabels}
        right
      />
      <AccountDropdownComponent
        logout={props.logout}
        changePassword={props.changePassword}
        changeNotifications={props.changeNotifications}
        manageValuations={props.manageValuations}
        showLabels={props.showLabels}
        //right
        advancedPlus={props.appSetState.appValuation.advancedPlus}
      />
    </Nav>
  );
};

ToolbarItemsComponent.propTypes = {
  logout: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  changeNotifications: PropTypes.func.isRequired,
  showLabels: PropTypes.bool.isRequired,
  toggleShowLabels: PropTypes.func.isRequired,
  showSettingsOnHover: PropTypes.bool.isRequired,
  toggleShowSettingsOnHover: PropTypes.func.isRequired,
  leftToolbarItems: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
  rightToolbarItems: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  manageValuations: PropTypes.func.isRequired,
};

export default ToolbarItemsComponent;