const responseIsJson = (response) => {
  const contentType = response.headers.get("content-type");
  const result = contentType && contentType.indexOf("application/json") !== -1;
  return result;
};

const callApiDefaultOnFail = (e, messageBox) => {
  if (e instanceof Response) {
    if (!responseIsJson(e)) {
      e.text().then(txt => { messageBox.showApiError(e.status, { errorRef: null, errorMessage: txt }); });
    } else {
      e.json().then(errorDetails => { messageBox.showApiError(e.status, errorDetails); });
    }
  } else {
    messageBox.showError(e.message);
  }
};

const callApi = (apiUrl, requestInit, showLoading, hideLoading, messageBox, onSuccess, onFail) => {
  if (typeof showLoading === 'function') {
    showLoading();
  }

  fetch(apiUrl,
    requestInit
  ).then(response => {
    if (!response.ok) { throw response; }
    return responseIsJson(response) ? response.json() : response.text();
  }).then(result => {
    if (typeof onSuccess === 'function') {
      onSuccess(result);
    }

    if (typeof hideLoading === 'function') {
      hideLoading();
    }
  }).catch(e => {
    if (typeof onFail === 'function') {
      onFail(e);
    } else {
      callApiDefaultOnFail(e, messageBox);
    }

    if (typeof hideLoading === 'function') {
      hideLoading();
    }
  });
};

const callApiAsync = async (apiUrl, requestInit, showLoading, hideLoading, messageBox, onSuccess, onFail) => {
  if (typeof showLoading === 'function') {
    showLoading();
  }

  await fetch(apiUrl,
    requestInit
  ).then(response => {
    if (!response.ok) { throw response; }
    return responseIsJson(response) ? response.json() : response.text();
  }).then(result => {
    if (typeof onSuccess === 'function') {
      onSuccess(result);
    }

    if (typeof hideLoading === 'function') {
      hideLoading();
    }
  }).catch(e => {
    if (typeof onFail === 'function') {
      onFail(e);
    } else {
      callApiDefaultOnFail(e, messageBox);
    }

    if (typeof hideLoading === 'function') {
      hideLoading();
    }
  });
};

export { responseIsJson, callApiDefaultOnFail, callApi, callApiAsync };