import React from 'react';
import PropTypes from 'prop-types';

import './main-sidebar-component.scss';
import { ViewModel } from './main-classes';
import SidebarButtonComponent from './sidebar-button-component';
import SidebarDropdownComponent from './sidebar-dropdown-component';

const MainSidebarComponent = (props) => {
  const { disabled } = props;

  const filterViewModels = () => {
    if (props.groupViews === false) {
      return props.viewModels.filter(obj => obj.isGroup === false);
    }
    return props.viewModels.filter(obj => obj.isGroup === true || obj.groupId === null);
  };

  const renderViewModel = (viewModel) => {
    if (viewModel.isGroup === true) {
      return (
        <SidebarDropdownComponent
          key={'sb-dropdown-' + viewModel.id}
          selected={props.viewModels.filter(vw => vw.id === props.selectedView).length > 0}
          disabled={disabled}
          onButtonClick={props.onButtonClick}
          viewModel={viewModel}
          viewModels={props.viewModels}
        />
      );
    }

    return (
      <SidebarButtonComponent
        key={'sb-button-' + viewModel.id}
        selected={viewModel.id === props.selectedView}
        disabled={disabled}
        onButtonClick={props.onButtonClick}
        viewModel={viewModel}
        viewModels={props.viewModels}
      />
    );
  };

  return (
    <div className={'main-sidebar-component' + (props.showLabels ? '' : ' hide-labels')}>
      {filterViewModels().map(vw => renderViewModel(vw))}
    </div>
  );
};

MainSidebarComponent.propTypes = {
  disabled: PropTypes.bool.isRequired,
  showLabels: PropTypes.bool.isRequired,
  groupViews: PropTypes.bool.isRequired,
  viewModels: PropTypes.arrayOf(PropTypes.instanceOf(ViewModel).isRequired).isRequired,
  selectedView: PropTypes.number.isRequired,
  onButtonClick: PropTypes.func.isRequired
};

export default MainSidebarComponent;