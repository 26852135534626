import React from 'react';

import DiscoveryDetailComponent from './discovery-detail-component';

import { DetailModel } from '../detail-model';
import { DiscoveryDetail } from '../../models/discovery-detail';
import HeaderComponent from '../header-component';

import { appViews } from '../../app/app-views';
import { ContextLayerModel } from '../../shared/helpers/leaflet/leaflet-helper';

class DiscoveryDetailModel extends DetailModel {
  constructor(atlasPanel, discoveryId) {
    if (typeof discoveryId !== 'number') {
      throw new Error('Invalid "discoveryId" supplied to "DiscoveryDetailModel.ctor"');
    }

    super(appViews.Discovery, atlasPanel);
    this._discoveryId = discoveryId;
  }

  matches(detail) {
    const result =
      typeof detail === 'object' &&
      detail instanceof DiscoveryDetailModel &&
      detail._discoveryId === this._discoveryId;
    return result;
  }

  load(appSetState, onLoadComplete) {
    this._validateLoadParams(appSetState, onLoadComplete);

    const onSuccess = (result) => {
      this.clearError();
      this._data = {
        discovery: new DiscoveryDetail(result.discovery),
        contextLayers: ContextLayerModel.FromArray(result.contextLayers)
      }
      this.addNavigationHistory(appSetState, this._discoveryId, this._data.discovery.name);
      onLoadComplete();
    };
    const onFail = (err) => this.handleLoadError(err, onLoadComplete);
    const url = 'api/discovery/details/load/?resourceId=' + this._discoveryId + '&page=' + appSetState.view.current + '&panel=' + this.atlasPanel;
    appSetState.api.getCached(url, onSuccess, onFail);
  }

  getHeaderComponent(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close) {
    this._validateGetHeaderComponentParams(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close);

    const copyLinkClick = null;
    //const copyLinkClick = this.loaded
    //  ? () => appSetState.view.details.showDiscoveryLink(this._discoveryId)
    //  : null;

    return (
      <HeaderComponent
        appView={this.appView}
        title={this.getTitle(() => this._data.discovery.name)}
        backClick={backClick}
        forwardClick={forwardClick}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        close={close}
        copyLinkClick={copyLinkClick}
      />
    );
  }

  getBodyComponent(appSetState, geoserverUrl) {
    this._validateGetBodyComponentParams(appSetState, geoserverUrl);

    if (this._error !== null) {
      return this._getErrorComponent();
    }

    if (this.loaded) {
      return (
        <DiscoveryDetailComponent
          discovery={this._data.discovery}
          contextLayers={this._data.contextLayers}
          appSetState={appSetState}
          geoserverUrl={geoserverUrl}
        />
      );
    }

    return this._getLoadingComponent();
  }
}

export { DiscoveryDetailModel };
