import React from 'react';

import { FieldFilter } from './field-filter';
import FieldViewComponent from './field-view-component';
import FieldViewSettingsComponent from './field-view-settings-component';

import { BaseViewSettings } from '../base/base-view-settings';
import PriceSettingsComponent from '../../sharedComponents/price-settings-component';

class FieldViewSettings extends BaseViewSettings {
  constructor(appView, appSetState) {
    super(appView, appSetState, new FieldFilter());

    this._settings.showOverview = false;
    this._settings.showComparison = false;
  }

  _update = (closeFilters) => {
    this._filter = this._filter.clone();
    if (closeFilters) {
      super.showFilters = false;
    } else {
      this.appSetState.view.forceUpdate();
    }
  };

  _updateSettings = (closeSettings) => {
    this._filter = this._filter.clone(); //return to this and do something with valuation settings so we know it has been changed (if its been changed)
    if (closeSettings) {
      super.showSettings = false;
    } else {
      this.appSetState.view.forceUpdate();
    }
  };

  _setAdvancedPlus = (value) => {
    this.advancedPlus = value;
  }

  reset = () => {
    super.reset();
    this._settings.showOverview = false;
    this._settings.showComparison = false;
    this._update();
    this._updateSettings();
  }

  getViewSettingsComponent(settings) {
    super.getViewSettingsComponent(settings);

    if (this.showFilters) return <FieldViewSettingsComponent appSetState={this.appSetState} filter={this._filter} onUpdate={this._update} followMapEnabled={this._settings.showMap} currentYear={settings.currentYear} />
    if (this.showSettings) return <PriceSettingsComponent appSetState={this.appSetState} isDetail={false} onUpdate={this._updateSettings} advancedPlus={this.advancedPlus} />

    return null;
  }

  getViewComponent(settings) {
    super.getViewComponent(settings);

    return (
      <FieldViewComponent
        appSetState={this.appSetState}
        geoserverUrl={settings.geoserverUrl}
        settings={this._settings}
        filter={this._filter}
        showFilters={this.showFilters}
        setShowFilters={this.setShowFilters}

        showSettings={this.showSettings}
        setShowSettings={this.setShowSettings}

        setAdvancedPlus={this._setAdvancedPlus}

        followMap={this.followMap}
        setFollowMap={this.setFollowMap}
        reset={this.reset}
      />
    );
  }
}

export { FieldViewSettings };
