import React from 'react';
import PropTypes from 'prop-types';

import { GridExcelExport } from './grid-excel-export';

import { appIcons } from '../../app/app-icons';
import { AppSetState } from '../../app/app-set-state';
import { valueTypes } from '../../shared/helpers/value-handler';
import { FasterPortfolioHubSummary } from '../../components/fiscal/models/portfolio/faster-portfolio';
import { COLUMN_SMALL, COLUMN_EXTRA_LARGE, HEADER_ROW_TREBLE, COLUMN_LARGE_LARGE, COLUMN_LARGE, COLUMN_MEDIUM } from '../../shared/components/data-grid/ag-grid-component';
import BaseGridComponent, { createLink, createLinkAndIcon, NUMERIC_SORT, nullNumberRenderer } from '../../components/base-grid/base-grid-component';

const HubGridComponent = (props) => {
  const { view } = props.appSetState;

  const renderHubName = (params) => {
    const result = createLinkAndIcon(
      params.data.hubName, 'Show Details', () => view.details.showHub(params.data.hubId, null, null, props.proximityKM, props.proximityKM, props.proximityKMLower, props.proximityKMUpper),
      appIcons.MapMarkerAlt, 'Show on map', () => props.showOnMap(params.data));
    return result;
  };

  const renderOperatorName = (params) => {
    const { operatorName, operatorId } = params.data;

    if (typeof operatorName === 'string' && operatorName.length > 0 && typeof operatorId === 'number' && operatorId !== -1) {
      return createLink(operatorName, 'Show details', () => view.details.showCorporate(operatorId));
    }
    return operatorName;
  };

  const renderCOPYear = (params) => {
    return params.value === 0 ? 'Ceased' : params.value;
  };

  const HUB_COLUMN_DEFS = [
    {
      headerName: "Production Hub",
      field: 'hubName',
      sortable: true,
      minWidth: COLUMN_EXTRA_LARGE,
      cellRenderer: renderHubName
    },
    {
      headerName: "Country",
      field: "countryName",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Area",
      field: "areaName",
      sortable: true,
      minWidth: COLUMN_SMALL
    },
    {
      headerName: "Operator",
      field: 'operatorName',
      sortable: true,
      minWidth: COLUMN_LARGE,
      cellRenderer: renderOperatorName
    },
    {
      headerName: "Development Status",
      field: "developmentStatus",
      sortable: true,
      minWidth: COLUMN_LARGE_LARGE
    },
    {
      headerName: "Electrification Status",
      field: "electrificationStatus",
      sortable: true,
      minWidth: COLUMN_LARGE_LARGE
    },
    {
      headerName: "Liquid Export",
      field: "liquidExport",
      sortable: true,
      minWidth: COLUMN_LARGE_LARGE
    },
    {
      headerName: "Gas Export",
      field: "gasExport",
      sortable: true,
      minWidth: COLUMN_LARGE_LARGE
    },
    {
      headerName: "COP Year",
      field: 'lastProductionYear',
      sortable: true,
      minWidth: COLUMN_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: renderCOPYear
    },
    {
      headerName: "Number of Fields",
      field: "numberOfFields",
      sortable: true,
      minWidth: COLUMN_SMALL,
      sortingOrder: NUMERIC_SORT
    },
    {
      headerName: 'Remaining Liquid\nReserves (' + valueTypes.UnitString(valueTypes.mmb) + ')',
      field: 'remainingLiquidMMB',
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: 'Remaining Gas\nReserves (' + valueTypes.UnitString(valueTypes.bcf) + ')',
      field: 'remainingGasBCF',
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: "Remaining Reserves (" + valueTypes.UnitString(valueTypes.mmmboe) + ")",
      field: "remainingReservesMMBOE",
      sortable: true,
      minWidth: COLUMN_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: props.currentYear + '\nProduction\n(' + valueTypes.UnitString(valueTypes.mboed) + ')',
      field: 'currentYearProductionBOEPD',
      sortable: true,
      minWidth: COLUMN_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: props.currentYear + '\nCO2 Emissions\n(' + valueTypes.UnitString(valueTypes.ktCO2) + ')',
      field: 'currentYearCO2EmissionsKTCO2',
      sortable: true,
      minWidth: COLUMN_MEDIUM,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
  ];

  return <BaseGridComponent columnDefs={HUB_COLUMN_DEFS} dataRows={props.dataRows} autoReSizeGrid={true} headerRowHeight={HEADER_ROW_TREBLE} />;
};

HubGridComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.instanceOf(FasterPortfolioHubSummary).isRequired).isRequired,
  currentYear: PropTypes.number.isRequired,
  showOnMap: PropTypes.func.isRequired
};

HubGridComponent.defaultProps = {
  currentYear: new Date().getFullYear()
};

export default HubGridComponent;
export { GridExcelExport };
