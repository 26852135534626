import React from 'react';
import PropTypes from 'prop-types';

import { BlockRow } from '../licence-detail';
import { valueTypes } from '../../../shared/helpers/value-handler';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const BlocksTableComponent = (props) => {
  const { blocks, onBlockClick, onOperatorClick } = props;

  const getRow = (row, rowIndex) => {
    let blockNo = row.blockNo;
    if (typeof onBlockClick === 'function' && typeof row.blockId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onBlockClick(row.blockId);
      };
      blockNo = <a href="/" title="Show Details" onClick={onClick}>{blockNo}</a>;
    }

    let operator = row.operator.operatorName;
    if (typeof onOperatorClick === 'function' && typeof row.operator.operatorId === 'number' && row.operator.operatorId > 0) {
      const onClick = (e) => {
        e.preventDefault();
        onOperatorClick(row.operator.operatorId);
      };
      operator = <a href="/" title="Show Details" onClick={onClick}>{operator}</a>;
    }

    return (
      <tr key={'block-row-' + rowIndex.toString()}>
        <td style={{ width: 'calc(25%)' }}>{blockNo}</td>
        <td style={{ width: 'calc(25%)' }}>{row.area}</td>
        <td style={{ width: 'calc(25%)' }}>{operator}</td>
        <td style={{ width: 'calc(25%)' }}>{row.areaSQKM}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Block Name</td>
          <td>Area</td>
          <td>Operator</td>
          <td>Area ({valueTypes.UnitString(valueTypes.kmsq)})</td>
        </tr>
      </thead>
      <tbody>
        {blocks.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
}

BlocksTableComponent.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.instanceOf(BlockRow)).isRequired,
  onBlockClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default BlocksTableComponent;
