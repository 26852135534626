import React from 'react';
import PropTypes from 'prop-types';

import CorporateProspectTableComponent from './corporate-prospects/corporate-prospects-table-component';

import { CorporateDetail } from '../../../models/corporate-detail';
import { createSection100, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import GenericChart, { ChartType } from '../../../shared/components/generic-chart/generic-chart';
import { valueTypes } from '../../../shared/helpers/value-handler';

const AdvancedCorporateProspectTableComponent = (props) => {
  const { corporate } = props;
  var prospectTable;
  
  const hasProspects = corporate.netProspects !== null && corporate.netProspects.length > 0;
  const chartStyle = { height: '350px' };

  const prospectsByReservoirArea = hasProspects && (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={corporate.chartDataCollection.prospectsByReservoirArea}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={'Number of prospects'}
          showYAxisGridLines={true}
        />
      </div>
    </div>);

  const netProspectResourceByReservoirArea = hasProspects && (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={corporate.chartDataCollection.netProspectResourceByReservoirArea}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.mmmboe)}
          showYAxisGridLines={true}
        />
      </div>
    </div>);

  
  if (hasProspects) {
    prospectTable = <CorporateProspectTableComponent
      currentCorporateId={corporate.corporateId}
      netProspects={corporate.netProspects}
      onProspectClick={props.onProspectClick}
      onLicenceClick={props.onLicenceClick}
      onBlockClick={props.onBlockClick}
      onOperatorClick={props.onOperatorClick}
    />;
  } else {
    prospectTable = (<div>No prospects</div>);
  }

  return (
    <div ref={props.innerRef}>
      {hasProspects &&
        createSection([
          { content: prospectsByReservoirArea, sectionType: sectionTypes.section50, title: 'Number of prospects by reservoir age & area' },
          { content: netProspectResourceByReservoirArea, sectionType: sectionTypes.section50, title: 'Net unrisked resource by reservoir age & area' }
        ])}
      {createSection100(prospectTable, 'Prospects')}
    </div>
  );
};

AdvancedCorporateProspectTableComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  corporate: PropTypes.instanceOf(CorporateDetail).isRequired,
  onProspectClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default AdvancedCorporateProspectTableComponent;