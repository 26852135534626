import { appIcons, appIconSizes } from '../app/app-icons';
import { getIcon } from '../shared/helpers/leaflet/helpers/control-helper';

const clickHandler = (displayText, id, onTextClick) => {
  if (typeof onTextClick === 'function' && typeof id === 'number') {
    const onClick = (e) => {
      e.preventDefault();
      onTextClick(id);
    };

    var retval = <a href="/" title="Show Details" onClick={onClick}>{displayText}</a>
    return retval;

  }
}

const clickIconHandler = (displayText, onIconClick, appIcon) => {

  if (typeof onIconClick === 'function') {
    const onClick = (e) => {
      e.preventDefault();
      onIconClick();
    };

    var reportLink = <a href="/" title={displayText} onClick={onClick}>{appIcons.getIcon(appIcons.FilePdf, appIconSizes.sm, false)}</a>;

    return reportLink;
  }
}

export { clickHandler, clickIconHandler };
