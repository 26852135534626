import { appViews } from "../../app/app-views";
import { AppSetState } from "../../app/app-set-state";
import { valueTypes } from '../../shared/helpers/value-handler';
import { BaseExcelExportColumn, BaseExcelExport } from "../../shared/helpers/base-excel-export";

class GridExcelExport {
  _formatDataRow(getLink, dataRow) {
    const result = { ...dataRow };
    if (typeof result.link === 'string') {
      result.link = getLink(result.link);
    }
    return result;
  }

  export(appSetState, dataRows, mapBaseExcelExport = null, mapWorkbook = null, sheetname = null) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "GridExcelExport.export"');
    }

    const baseExcelExport = mapBaseExcelExport !== null ? mapBaseExcelExport : new BaseExcelExport();
    const workbook = mapWorkbook !== null ? mapWorkbook : baseExcelExport.createWorkbook();

    const appView = sheetname !== null ? sheetname : appViews.getDisplayName(appViews.Licence);
    dataRows = dataRows.map(obj => this._formatDataRow(appSetState.view.details.getLink, obj));
    const colDefs = [
      new BaseExcelExportColumn('Licence Number', 'licenceNumber'),
      new BaseExcelExportColumn('Country', 'countryName'),
      new BaseExcelExportColumn('Area', 'area'),
      new BaseExcelExportColumn('Licence Status', 'status'),
      new BaseExcelExportColumn('Licence Type', 'licenceTypeName'),
      new BaseExcelExportColumn('Licence Round', 'roundName'),
      new BaseExcelExportColumn('Licence Valid From', 'startDate'),
      new BaseExcelExportColumn("Area (" + valueTypes.UnitString(valueTypes.kmsq) + ")", "areaSQKM"),
      new BaseExcelExportColumn('Atlas URL', 'link')
    ];

    baseExcelExport.writeSheet(workbook, appView, dataRows, colDefs);
    if (mapBaseExcelExport === null) baseExcelExport.writeWorkbook(workbook, baseExcelExport.getAtlas5WorkbookPrefix() + appView + baseExcelExport.getAtlas5WorkbookSuffix());
  }
}

export { GridExcelExport };