import React from 'react';
import PropTypes from 'prop-types';

import ResourcePlannedWell from '../../models/resource-planned-well';
import BaseTableComponent from '../../shared/components/detail-view-grid-layout/base-table-component';

const PlannedWellsTableComponent = (props) => {
  const { plannedWells, onWellClick, onParticipantClick, onBlockClick } = props;

  const getRow = (row, rowIndex) => {
    let wellName = row.well;
    if (typeof onWellClick === 'function' && typeof row.wellId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onWellClick(row.wellId);
      };
      wellName = <a href="/" title="Show Details" onClick={onClick}>{wellName}</a>;
    }

    let operatorName = row.operatorName;
    if (typeof onParticipantClick === 'function' && typeof row.operatorId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onParticipantClick(row.operatorId);
      };
      operatorName = <a href="/" title="Show Details" onClick={onClick}>{operatorName}</a>;
    }

    let block = row.block;
    if (typeof onBlockClick === 'function' && typeof row.blockId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onBlockClick(row.blockId);
      };
      block = <a href="/" title="Show Details" onClick={onClick}>{block}</a>;
    }

    return (
      <tr key={'well-row-' + rowIndex.toString()}>
        <td style={{ width: '17%' }}>{wellName}</td>
        <td style={{ width: '16%' }}>{row.typeName}</td>
        <td style={{ width: '16%' }}>{row.statusName}</td>
        <td style={{ width: '18%' }}>{operatorName}</td>
        <td style={{ width: '16%' }}>{row.estimatedTiming}</td>
        <td style={{ width: '17%' }}>{block}</td>
      </tr>
    );
  };

  return (
    <BaseTableComponent className="table-component">
      <thead>
        <tr style={{ textAlign: 'left', fontWeight: 'bold' }}>
          <td>Well Name</td>
          <td>Well Type</td>
          <td>Well Status</td>
          <td>Operator</td>
          <td>Estimated Timing</td>
          <td>Block</td>
        </tr>
      </thead>
      <tbody>
        {plannedWells.map((obj, index) => getRow(obj, index))}
      </tbody>
    </BaseTableComponent>
  );
}

PlannedWellsTableComponent.propTypes = {
  plannedWells: PropTypes.arrayOf(PropTypes.instanceOf(ResourcePlannedWell)).isRequired,
  onWellClick: PropTypes.func,
  onParticipantClick: PropTypes.func,
  onBlockClick: PropTypes.func
};

export default PlannedWellsTableComponent;
