import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink } from 'reactstrap';

class appDef {
  constructor(name, url, image = '/images/wge-logo.png', whiteImage = '/images/wge-logo-white.png') {
    if (typeof name !== 'string') {
      throw new Error('Invalid "name" param supplied to "WgeAppSwitcherComponent.appDef"');
    }
    this.name = name;

    if (typeof url !== 'string') {
      throw new Error('Invalid "url" param supplied to "WgeAppSwitcherComponent.appDef"');
    }
    this.url = url;

    if (typeof image !== 'string') {
      throw new Error('Invalid "image" param supplied to "WgeAppSwitcherComponent.appDef"');
    }
    this.image = image;

    if (typeof whiteImage !== 'string') {
      throw new Error('Invalid "whiteImage" param supplied to "WgeAppSwitcherComponent.appDef"');
    }
    this.whiteImage = whiteImage;
  }
}

const appDefs = {
  None: new appDef('', '/'),
  GME: new appDef('GMatrix Editor', 'https://gmatrix.westwoodenergy.com/'),
  Atlas: new appDef('Atlas', 'https://atlas.westwoodenergy.com/', '/images/atlas-logo.png', '/images/atlas-logo-white.png'),
  Analytics: new appDef('Analytics', 'https://analytics.westwoodenergy.com/', '/images/analytics-logo.png', '/images/analytics-logo-white.png'),
  Sectors: new appDef('Sectors', 'https://sectors.westwoodenergy.com/', '/images/sectors-logo.png', '/images/sectors-logo-white.png'),
  Wildcat: new appDef('Wildcat', 'https://wildcat.westwoodenergy.com/', '/images/wildcat-logo.png', '/images/wildcat-logo-white.png'),
  Riglogix: new appDef('Riglogix', 'https://new-riglogix.westwoodenergy.com/', '/images/riglogix-logo.png', '/images/riglogix-logo-white.png'),
  Offshore: new appDef('Offshore', 'https://new-riglogix.westwoodenergy.com/offshore/', '/images/offshore-logo.png'),
  Energent: new appDef('Energent', 'http://app.energentgroup.com/', '/images/energent-logo.png', '/images/energent-logo-white.png'),
  GlobalLandRigs: new appDef('Global Land Rigs', 'https://riglogix.westwoodenergy.com/onshore/', '/images/global-land-rigs-logo.png', '/images/global-land-rigs-logo-white.png')
};

const WgeAppSwitcherComponent = (props) => {
  const renderImg = (app, height, whiteImage) => {
    return <img style={{ height: height }} src={whiteImage ? app.whiteImage : app.image} alt={app.name} />;
  };

  const renderLink = (app) => {
    return (
      <DropdownItem key={'wge-app-switcher-' + app.name.toLowerCase()} title={'Open ' + app.name}>
        <NavLink target="_blank" href={app.url}>{renderImg(app, '30px', false)}</NavLink>
      </DropdownItem>
    );
  };

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle style={{ padding: 0, margin: 0 }} nav caret title="Switch app">
        {renderImg(props.currentApp, '40px', true)}
      </DropdownToggle>
      <DropdownMenu className="custom-dropdown-menu">
        <DropdownItem header>Switch App</DropdownItem>
        {props.apps.map(app => renderLink(app))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

WgeAppSwitcherComponent.propTypes = {
  currentApp: PropTypes.instanceOf(appDef).isRequired,
  apps: PropTypes.arrayOf(PropTypes.instanceOf(appDef).isRequired).isRequired,
  right: PropTypes.bool.isRequired
};

WgeAppSwitcherComponent.defaultProps = {
  right: false
};

export default WgeAppSwitcherComponent;
export { appDefs };
