import React from 'react';
import PropTypes from 'prop-types';

import { WellDetail } from '../../../models/well-detail';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const KeyFactsComponent = (props) => {
  const { well, onLicenceClick, onOperatorClick } = props;

  const getRows = () => {
    let licence = well.licence;
    if (typeof onLicenceClick === 'function' && typeof well.licenceId === 'number') {
      const onClick = (e) => {
        e.preventDefault();
        onLicenceClick(well.licenceId);
      };
      licence = <a href="/" title="Show Details" onClick={onClick}>{licence}</a>;
    }

    let operatorName = null;
    if (well.operatorOnly === true) {
      operatorName = well.operatorName;

      if (typeof onOperatorClick === 'function' && typeof well.operatorId === 'number') {
        const onClick = (e) => {
          e.preventDefault();
          onOperatorClick(well.operatorId);
        };
        operatorName = <a href="/" title="Show Details" onClick={onClick}>{operatorName}</a>;
      }

      operatorName = (
        <tr>
          <td>Operator</td>
          <td>{operatorName}</td>
        </tr>
      );
    }

    return (
      <React.Fragment>
        <tr>
          <td>Country</td>
          <td>{well.countryName}</td>
        </tr>
        <tr>
          <td>Area</td>
          <td>{well.areaName}</td>
        </tr>
        {operatorName}
        <tr>
          <td>Well type</td>
          <td>{well.class}</td>
        </tr>
        <tr>
          <td>Well sub type</td>
          <td>{well.subClass}</td>
        </tr>
        <tr>
          <td>Licence</td>
          <td>{licence}</td>
        </tr>
        <tr>
          <td>Licence round</td>
          <td>{well.licenceRound}</td>
        </tr>
        <tr>
          <td>Licence commitment</td>
          <td>{well.commitment}</td>
        </tr>
        <tr>
          <td>Farm-in</td>
          <td>{well.farmIn}</td>
        </tr>
        <tr>
          <td>Surface location</td>
          <td>{well.surfaceLocation}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent className="one-col-key-value">
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

KeyFactsComponent.propTypes = {
  well: PropTypes.instanceOf(WellDetail).isRequired,
  onLicenceClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default KeyFactsComponent;
