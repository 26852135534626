import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';

import { BlockFilter } from './block-filter';
import BlockGridComponent, { GridExcelExport } from './block-grid-component';
import OverviewComponent from './overview-component';

import BaseViewComponent, { LeafletHelper, ContextLayerModel } from '../base/base-view-component';

import { Block } from '../../models/Block';
import { appViews } from '../../app/app-views';
import { AppSetState } from '../../app/app-set-state';
import { searchResultType } from '../../shared/helpers/leaflet/leaflet-helper';
import { SearchResultsExcelExport } from '../../map/search-results-excel-export';

class BlockViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.leafletHelper = new LeafletHelper(this.props.geoserverUrl, 'block-view-component-map', new SearchResultsExcelExport(this.props.appSetState, appViews.Block).export);
    this.state = { contextLayers: [], dataRows: [], ids: [], filterEnabled: false };
  }

  componentDidMount() {
    this.update(true);
    this.loadFilter();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.update(false);
    }

    prevProps = null;
  }

  componentWillUnmount() {
    if (this.leafletHelper !== null && this.leafletHelper !== undefined) {
      this.leafletHelper.destroy();
      delete this.leafletHelper;
    }
  }

  loadFilter = () => {
    const url = 'api/block/filters/load';
    const onSuccess = () => {
      this.setState({ filterEnabled: true });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true, false);
  }

  update = (initialLoad) => {
    const url = 'api/block/load-all?excel=false';

    const onSuccess = (result) => {
      var dataRows = Block.FromArray(result.dataRows);
      var contextLayers = ContextLayerModel.FromArray(result.contextLayers);
      var ids;

      var filter = this.props.filter.toApiParam();
      var isFilterSet = this.props.filter.isSet;
      if (isFilterSet) {
        const url = 'api/block/load-ids';

        const onFilteredSuccess = (result) => {
          ids = result;
          dataRows = dataRows.filter(({ id }) => ids.includes(id));
          this.setState({
            contextLayers: contextLayers,
            dataRows: dataRows
          }, this.setMapPosition);
        };

        this.props.appSetState.api.post(url, filter, onFilteredSuccess, undefined, false, true);
      }
      else {
        this.setState({
          contextLayers: contextLayers,
          dataRows: dataRows
        }, initialLoad ? this.initialSetMapPosition : this.setMapPosition);
      }
    };
    
    this.props.appSetState.api.getCached(url, onSuccess, null, false, true);

  }

  initialSetMapPosition = () => {
    const { filter } = this.props;

    if (filter.mapBoundsIsSet) {
      this.leafletHelper.leafletMap.fitBounds(filter.mapBounds);
    } else {
      this.setMapPosition();
    }
  }

  setMapPosition = () => {
    const { dataRows } = this.state;

    if (this.props.filter.followMap === false) {
      if (dataRows.length > 0) {
        const bounds = L.latLngBounds([]);
        dataRows.forEach(obj => bounds.extend(obj.shapes.latLngs));
        this.leafletHelper.flyToBounds(bounds);
      }
    }
  }

  showOnMap = (dataRow) => {
    const bounds = L.latLngBounds(dataRow.shapes.latLngs);
    const zoom = searchResultType.defaultZoomLevel(searchResultType.Block);
    this.leafletHelper.flyTo(bounds.getCenter(), zoom);
  }

  excelExport = () => {
    new GridExcelExport().export(this.props.appSetState, this.state.dataRows);
  }

  render() {
    const { dataRows } = this.state;

    const overview = undefined;
    //const overview = (
    //  <OverviewComponent
    //    appSetState={this.props.appSetState}
    //    dataRows={dataRows}
    //  />
    //);
    const grid = (
      <BlockGridComponent
        appSetState={this.props.appSetState}
        dataRows={dataRows}
        showOnMap={this.showOnMap}
      />
    );
    return (

      <BaseViewComponent
        className="block-view-component"
        leafletHelper={this.leafletHelper}
        contextLayers={this.state.contextLayers}
        shapes={dataRows.map(obj => obj.shapes)}
        appView={appViews.Block}
        appSetState={this.props.appSetState}
        update={this.update}
        settings={this.props.settings}
        filter={this.props.filter}
        showFiltersButton
        filterButtonEnabled={this.state.filterEnabled}
        showFilters={this.props.showFilters}
        setShowFilters={this.props.setShowFilters}
        showFollowMapButton
        followMap={this.props.followMap}
        setFollowMap={this.props.setFollowMap}
        overview={overview}
        grid={grid}
        excelExport={this.excelExport}
        reset={this.props.reset}
      />
    );
  }
}

BlockViewComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    showMap: PropTypes.bool.isRequired,
    showOverview: PropTypes.bool.isRequired
  }).isRequired,
  filter: PropTypes.instanceOf(BlockFilter).isRequired,
  showFilters: PropTypes.bool.isRequired,
  setShowFilters: PropTypes.func.isRequired,
  followMap: PropTypes.bool.isRequired,
  setFollowMap: PropTypes.func.isRequired,
  reset: PropTypes.func
};

export default BlockViewComponent;
