import React from 'react';
import './valuations-management-component.scss';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { UserValuations } from '../models/user-valuations';
import ValuationListComponent from './valuation-list-component';
import ValuationEditComponent from './valuation-edit-component';

class ValuationsManagementComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userValuations: null,
      selectedValuationId: 0,
      selectedValuation: null,
      readOnly: true,
      activeEdit: false,
      hasChanged: false
    }

    this.getUserValuations = this.getUserValuations.bind(this);

    this.closePanel = this.closePanel.bind(this);
    this.selectAndClosePanel = this.selectAndClosePanel.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onSetDefault = this.onSetDefault.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onNew = this.onNew.bind(this);
    this.onReset = this.onReset.bind(this);
    this.setActiveEdit = this.setActiveEdit.bind(this);
    this.getNewName = this.getNewName.bind(this);
    this.isSelectedValid = this.isSelectedValid.bind(this);

    if (!this.props.isDetail) this.props.appSetState.appValuation.clearDetailValuation();
  };

  componentDidMount() {
    this.getUserValuations();
  }

  getUserValuations() {
    const onSuccess = (result) => {
      const { valuation, detailValuation } = this.props.appSetState.appValuation;
      const currentValuation = this.props.isDetail ? detailValuation : valuation;

      const discountYear = result.discountYear;
      const discountRate = result.discountRate;
      const currentYear = result.currentYear;
      this.setState({ userValuations: result.variableList.map(obj => new UserValuations(obj)), selectedValuationId: currentValuation.variableId, discountYear: discountYear, discountRate: discountRate, currentYear: currentYear });
      this.onSelect(currentValuation.variableId);
    }
    const onFail = (err) => {
      console.log(err);
    }

    this.props.api.get('api/valuations/load-all-valuations', onSuccess, onFail);
  }

  closePanel() {
    this.props.appSetState.appValuation.modelOpen = true;
    this.props.appSetState.messageBox.hide();
    if (this.state.hasChanged) this.props.onClose(this.props.isDetail); //this is the line that updates view/detail on save
  }

  selectAndClosePanel = () => {
    if (this.props.isDetail) this.props.appSetState.appValuation.detailValuation = this.state.selectedValuation;
    else {
      this.props.appSetState.appValuation.valuation = this.state.selectedValuation;
    }

    this.props.appSetState.appValuation.modelOpen = true;
    this.props.appSetState.messageBox.hide();
    this.props.onClose(true);
  }

  onSelect = (id, disabled) => {
    if (!disabled) {
      let valuation;
      if (id < 0) {
        valuation = this.state.userValuations.find(obj => obj.isDefault);
      }
      else {
        valuation = this.state.userValuations.find(obj => obj.variableId == id);
      }
      let isReadOnly = valuation !== null && valuation !== undefined ? valuation.isWGEVariable || valuation.isShared ? true : false : true;
      this.setState({ selectedValuationId: valuation.variableId, readOnly: isReadOnly, selectedValuation: valuation, activeEdit: false });
    }
  }

  onCopy = (id) => {
    const onSuccess = (result) => {
      this.setState({ selectedValuationId: result.copiedValutionId, userValuations: result.valuationList.map(obj => new UserValuations(obj)) });
      this.onSelect(result.copiedValutionId);
    }

    const onFail = (err) => {
      console.log(err);
    }

    this.props.api.get('api/valuations/copy-valuation?valuationId=' + id, onSuccess, onFail);
  }

  onDelete = (id) => {
    const { valuation, detailValuation } = this.props.appSetState.appValuation;

    const onSuccess = (result) => {
      const defaultId = result.filter(obj => obj.isDefault == true)[0].variableId;
      this.setState({ userValuations: result.map(obj => new UserValuations(obj)), selectedValuationId: defaultId, defaultId: defaultId, hasChanged: true });
      this.onSelect(defaultId);
    }

    const onFail = (err) => {
      console.log(err);
    }

    if (id === valuation.variableId || (detailValuation !== null && id === detailValuation.variableId)) {
      const userValuationList = this.state.userValuations;
      const defaultValuation = userValuationList.find(obj => obj.isDefault === true);

      if (this.props.isDetail && detailValuation !== null && id === detailValuation.variableId) {
        this.props.appSetState.appValuation.detailValuation = defaultValuation;
      }

      if (id === valuation.variableId) {
        this.props.appSetState.appValuation.valuation = defaultValuation;
        this.props.appSetState.appValuation.valuationHasChanged = true;
      }
    }

    this.props.api.get('api/valuations/delete-valuation?valuationId=' + id, onSuccess, onFail);
  }

  onSetDefault = (id) => {
    const onSuccess = (result) => {
      const defaultValuation = result.find(obj => obj.isDefault == true);
      this.setState({ userValuations: result.map(obj => new UserValuations(obj)), selectedValuationId: defaultValuation.variableId, defaultId: defaultValuation.variableId });
      this.onSelect(defaultValuation.variableId);
    }

    const onFail = (err) => {
      console.log(err);
    }

    this.props.api.get('api/valuations/set-default-valuation?valuationId=' + id, onSuccess, onFail);
  }

  successValidateSave = (saveValuation) => {
    const { valuation, detailValuation } = this.props.appSetState.appValuation;
    saveValuation.validationState = undefined;

    const onSuccess = (result) => {
      this.setState({ selectedValuationId: result.variableId, userValuations: result.valuationList.map(obj => new UserValuations(obj)), activeEdit: true, hasChanged: true });
      if (result.variableId === valuation.variableId || (detailValuation !== null && result.variableId === detailValuation.variableId)) {
        const selectedValuation = this.state.userValuations.find(obj => obj.variableId == result.variableId);
        if (this.props.isDetail && detailValuation !== null && result.variableId === detailValuation.variableId) {
          this.props.appSetState.appValuation.detailValuation = selectedValuation;
        }

        if (result.variableId === valuation.variableId) {
          this.props.appSetState.appValuation.valuation = selectedValuation;
          this.props.appSetState.appValuation.valuationHasChanged = true;
        }
      }
      this.onSelect(result.variableId);
    }

    const onFail = (err) => {
      console.log(err);
    }

    this.props.api.post('api/valuations/save-valuation', saveValuation, onSuccess, onFail);
  }

  onSave = (valuation) => {
    valuation.validationState = undefined;

    const onValidateSuccess = (result) => {
      if (result.validationState.isValid === true) {
        this.successValidateSave(result)
      }
      else
      {
        const errVal = new UserValuations(result);
        this.setState({ selectedValuation: errVal, activeEdit: true });
      }
    }

    const onValidateFail = (err) => {
      console.log(err);
    }

    this.props.api.post('api/valuations/validate-valuation', valuation, onValidateSuccess, onValidateFail);
  }

  onNew = () => {
    let baseValuationScenarioClone = this.props.appSetState.appValuation.baseValuation.scenario.clone();

    var newName = this.getNewName();
    var newValuation = UserValuations.GetNewValuation(newName, baseValuationScenarioClone);
    newValuation.discountRate = this.state.discountRate;
    newValuation.discountStartYear = this.state.discountYear;
    newValuation.currentYear = this.state.currentYear;

    var userValuationList = this.state.userValuations;
    userValuationList.push(newValuation);

    this.setState({ selectedValuationId: newValuation.variableId, selectedValuation: newValuation, readOnly: false, userValuations: userValuationList, activeEdit: true });
  }

  getNewName = () => {
    var name = 'new';
    var newName = name;

    var a = 0;
    const userValuations = this.state.userValuations;

    var nameFound = userValuations.filter(obj => obj.variableName === newName);

    while (nameFound !== null && nameFound.length !== 0){
      a = a + 1;
      newName = name + a;
      nameFound = userValuations.filter(obj => obj.variableName === newName);
    }

    return newName;
  }

  onReset = () => {
    var userValuationList = this.state.userValuations;
    var newValuation = userValuationList.find(x => x.variableId === -1);
    if (newValuation !== null && newValuation !== undefined) {
      userValuationList.pop(newValuation);
      newValuation = null;
    }
    this.setState({ userValuations: userValuationList, activeEdit: false });
    if (newValuation !== null && newValuation !== undefined) {
      this.onSelect(-1);
    } else {
      this.onSelect(this.state.selectedValuationId);
    }
  }

  setActiveEdit = () => {
    this.setState({ activeEdit: true });
  }

  isSelectedValid = () => {
    const { selectedValuation } = this.state;
    if (selectedValuation === null || selectedValuation.validationState === undefined) return false;
    return selectedValuation.validationState.isValid;
  }

  render() {
    if (this.state.userValuations !== null) {
      const noSelectionReadOnly = this.state.selectedValuationId === 0 ? true : false;
      const selectedValid = this.isSelectedValid();
      const selectAndExit = this.props.showSelectAndExit === true ?
        <Button className="select-exit-btn" onClick={this.selectAndClosePanel} disabled={noSelectionReadOnly || this.state.activeEdit || !selectedValid} >Select & Exit</Button>
        : null;
      
      return (
        <div className='valuations-management'>
          <ValuationListComponent className={'valiations-list'} userValuations={this.state.userValuations} selectedValuation={this.state.selectedValuationId} onSelect={this.onSelect} activeEdit={this.state.activeEdit} onNew={this.onNew} />
          <ValuationEditComponent
            className={'valiations-edit'}
            selectedValuation={this.state.selectedValuation}
            readOnly={this.state.readOnly}
            onCopy={this.onCopy}
            onDelete={this.onDelete}
            onSetDefault={this.onSetDefault}
            onSave={this.onSave}
            onReset={this.onReset}
            setActiveEdit={this.setActiveEdit}
            activeEdit={this.state.activeEdit}
            selectedValid={selectedValid}
          />
          {selectAndExit}
          <Button className="exit-btn" onClick={this.closePanel} >Exit</Button>
        </div>
      );
    }
    else
      return null;
  }

}

ValuationsManagementComponent.propTypes = {
  isDetail: PropTypes.bool.isRequired,
  isMainMenu: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showSelectAndExit: PropTypes.bool.isRequired,
};

ValuationsManagementComponent.defaultProps = {
  isDetail: false,
  isMainMenu: false,
  showSelectAndExit: true,
  onClose: () => { }
}

export default ValuationsManagementComponent;
