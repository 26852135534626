import React from 'react';

import './detail-view-grid-layout-component.scss';

import { objectToEnum } from '../../helpers/common';

const createHeader = (header, innerRef = null) => {
  return typeof header === 'string' && header.trim().length > 0
    ? <div className="header" ref={innerRef}>{header}</div>
    : null;
};

const createSectionTitle = (title) => {
  return typeof title === 'string' && title.trim().length > 0
    ? <div className="title">{title}</div>
    : null;
};

const createSectionInner = (className, key, title, content, style) => {
  return (
    <div key={key} className={'section-inner ' + className}>
      <div className="section-content" style={style}>
        {createSectionTitle(title)}
        {content}
      </div>
    </div>
  );
};

const createSection100 = (content, title = '', noMarginVertical = false, noMarginHorizontal = false, style = {}) => {
  const className = 'section-100'
    + (noMarginVertical === true ? ' no-margin-vertical' : '')
    + (noMarginHorizontal === true ? ' no-margin-horizontal' : '');
  return (
    <div className="section-outer">
      {createSectionInner(className, undefined, title, content, style)}
    </div>
  );
};

const sectionTypes = objectToEnum({
  section25: 0,
  section33: 1,
  section50: 2,
  section66: 3,
  section75: 4,
  section60: 5,
  section40: 6,
});

const createSection = (innerSections) => {
  if (!Array.isArray(innerSections)) {
    throw new Error('Invalid "innerSections" param supplied to "DetailViewGridLayoutComponent.createSection"');
  }

  const renderInnerSection = (innerSection, idx) => {
    if (typeof innerSection !== 'object') {
      throw new Error('Invalid "innerSection" param supplied to "DetailViewGridLayoutComponent.createSection.createInnerSection"');
    }
    if (sectionTypes.isInvalid(innerSection.sectionType)) {
      throw new Error('Invalid "innerSection.sectionType" param supplied to "DetailViewGridLayoutComponent.createSection.createInnerSection"');
    }

    const displayName = sectionTypes.getDisplayName(innerSection.sectionType);
    const className = displayName
      + (innerSection.noMarginVertical === true ? ' no-margin-vertical' : '')
      + (innerSection.noMarginHorizontal === true ? ' no-margin-horizontal' : '');
    const style = typeof innerSection.style === 'object' ? innerSection.style : {};
    return createSectionInner(className, displayName + '-' + idx, innerSection.title, innerSection.content, style);
  };

  return (
    <div className="section-outer">
      {innerSections.map((obj, idx) => renderInnerSection(obj, idx))}
    </div>
  );
};

const DetailViewGridLayoutComponent = (props) => {
  return (
    <div id="detailViewGrid" className="detail-view-grid-layout-component">
      {props.children}
    </div>
  );
};

export default DetailViewGridLayoutComponent;
export { createHeader, createSection100, createSection, sectionTypes };
