import React from 'react';
import PropTypes from 'prop-types';

import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';
import { FieldDetail } from '../models/portfolio/field-detail';
import { valueTypes } from '../../../shared/helpers/value-handler';

const ReservesFactsComponent = (props) => {
  const { resource } = props;
  const percentUnit = valueTypes.UnitString(valueTypes.percent);

  const getRows = () => {
    return (
      <React.Fragment>
        <tr style={{ fontWeight: 'bold', border:'0'}}>
          <td style={{ width: '16%' }} />
          <td colSpan='3' style={{ textAlign: 'center', backgroundColor: 'darkgrey', width: '84%' }}>{resource.valuation.variableName} Reserves</td>
        </tr>
        <tr style={{ fontWeight: 'bold' }}>
          <td style={{ width: '16%' }} />
          <td style={{ width: '28%' }}>Total Recoverable</td>
          <td style={{ width: '28%' }}>Remaining</td>
          <td style={{ width: '28%' }}>Remaining ({percentUnit}) </td>
        </tr>
        <tr>
          <td>Liquid ({valueTypes.UnitString(valueTypes.mmb)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.fiscalResource.totalRecoverableLiquidMmb)}</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.fiscalResource.remainingLiquidMmb)}</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.liquidPercentRemaining)}</td>
        </tr>
        <tr>
          <td>Gas ({valueTypes.UnitString(valueTypes.bcf)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.fiscalResource.totalRecoverableGasBcf)}</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.fiscalResource.remainingGasBcf)}</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.gasPercentRemaining)}</td>
        </tr>
        <tr>
          <td>Total ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.totalRecoverableCombinedMmboe)}</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.remainingCombinedMmboe)}</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, resource.combinedPercentRemaining)}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent>
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

ReservesFactsComponent.propTypes = {
  resource: PropTypes.instanceOf(FieldDetail).isRequired
};

export default ReservesFactsComponent;
