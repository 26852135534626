import React from 'react';
import PropTypes from 'prop-types';

import { ChartData } from '../../../shared/components/generic-chart/chart-data';
import EmissionsC02vsCO2IntensityChart from '../../../shared/components/emissions-c02-vs-co2-intensity-chart/emissions-c02-vs-co2-intensity-chart';
import { createSection100, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedEmissionsComponent = (props) => {
  const { emissions, emissionsComment } = props;
  var emissionsContent;

  const hasEmissionsData = emissions !== null && emissions !== undefined;
  const chartStyle = { height: '300px', display: 'inline-block', width: '100%' };

  const emissionsChart = hasEmissionsData && (
    <div>
      <div style={chartStyle}>
        <EmissionsC02vsCO2IntensityChart
          chartDataItems={emissions}
          showXAxisLabel={true}
          showXAxisGridLines={true}
          primaryType='bar'
          secondaryType='line'
        />
      </div>
    </div>
  );

  if (hasEmissionsData && emissionsComment !== '') {
    emissionsContent = createSection([
      { content: emissionsChart, sectionType: sectionTypes.section66, title: 'Emissions' },
      { content: emissionsComment, sectionType: sectionTypes.section33, title: 'Emissions Comment' }
    ]);
  } else if (hasEmissionsData && emissionsComment === '') {
    emissionsContent = createSection100(emissionsChart, 'Emissions');
  } else if (!hasEmissionsData && emissionsComment !== '') {
    emissionsContent = createSection100(emissionsComment, 'Emissions Comment');
  } else {
    emissionsContent = createSection100(<div>No Emissions Information Available</div>, 'Emissions');
  }

  return (
    <div ref={props.innerRef}>
      {emissionsContent}
    </div>
  );
};

AdvancedEmissionsComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  emissions: PropTypes.instanceOf(ChartData),
  emissionsComment: PropTypes.string
};

AdvancedEmissionsComponent.defaultProps = {
  emissions: null,
  emissionsComment: null
};

export default AdvancedEmissionsComponent;
