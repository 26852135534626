import React from 'react';
import PropTypes from 'prop-types';

import { valueTypes } from '../../../shared/helpers/value-handler';
import { HubDetail } from '../../../models/hub-detail';
import GenericChart, { ChartType } from '../../../shared/components/generic-chart/generic-chart';
import { createHeader, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedHubCostsComponent = (props) => {
  const { hub } = props;

  const chartStyle = { height: '350px' };

  const capex = hub.chartDataCollection.capexByField !== null ? (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.capexByField}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.usdMM)}
          showYAxisGridLines={true}
        />
      </div>
    </div>
  ) : <div>No further Capex expected</div>;

  const abex = hub.chartDataCollection.abexByField !== null ? (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.abexByField}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.usdMM)}
          showYAxisGridLines={true}
        />
      </div>
    </div>
  ) : <div>No further Abex expected</div>;

  const opex = hub.chartDataCollection.opexByField !== null ? (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.opexByField}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.usdMM)}
          showYAxisGridLines={true}
        />
      </div>
    </div>
  ) : <div>No further Opex expected</div>;

  const opexBOE = hub.chartDataCollection.hubOpexPerBOE !== null ? (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.hubOpexPerBOE}
          chartType={ChartType.Bar}
          isXStacked={false}
          isYStacked={false}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.usdBOE)}
          showYAxisGridLines={true}
        />
      </div>
    </div>
  ) : <div>No further Opex expected</div>;

  return (
    <div ref={props.innerRef}>
      {createHeader('Costs')}

      {createSection([
        { content: capex, sectionType: sectionTypes.section50, title: 'Capex' },
        { content: abex, sectionType: sectionTypes.section50, title: 'Abex' }
      ])}

      {createSection([
        { content: opex, sectionType: sectionTypes.section50, title: 'Opex' },
        { content: opexBOE, sectionType: sectionTypes.section50, title: 'Opex / boe' }
      ])}
    </div>
  );
};

AdvancedHubCostsComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  hub: PropTypes.instanceOf(HubDetail).isRequired
};

export default AdvancedHubCostsComponent;