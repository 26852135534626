import React from 'react';
import PropTypes from 'prop-types';
import { valueTypes } from '../../shared/helpers/value-handler';
import { FieldComparisonGridExcelExport } from './field-comparison-grid-excel-export';

import BaseGridComponent, { createLink, createLinkAndIcons, NUMERIC_SORT, nullNumberRenderer } from '../../components/base-grid/base-grid-component';

import { appIcons } from '../../app/app-icons';
import { AppSetState } from '../../app/app-set-state';
import { FieldValuationComparison } from '../../models/valuation-comparison';
import { COLUMN_MEDIUM_MEDIUM, COLUMN_LARGE, COLUMN_LARGE_LARGE, HEADER_ROW_DOUBLE, COLUMN_SMALL, HEADER_ROW_SINGLE } from '../../shared/components/data-grid/ag-grid-component';

const FieldComparisonGridComponent = (props) => {
  const { view } = props.appSetState;

  const resourceNameRenderer = (params) => {
    const { resourceName, resourceId, countryId } = params.data;

    const icons = [];
    icons.push({ appIcon: appIcons.Reports, title: 'Show reports', onClick: () => view.reports.showFields(resourceId, countryId) });
    icons.push({ appIcon: appIcons.MapMarkerAlt, title: 'Show on map', onClick: () => props.showOnMap(params.data) });
    const result = createLinkAndIcons(resourceName, 'Show details', () => view.details.showField(resourceId), icons);
    return result;
  };

  const renderHubName = (params) => {
    let result = params.data.hubName;
    if (typeof params.data.hubCountryId === 'number' && params.data.hubCountryId !== 0) {
      result = createLink(result, 'Show details', () => view.details.showHub(params.data.hubId));
    }
    return result;
  };

  const renderOperatorName = (params) => {
    const { operatorName, operatorId } = params.data;

    if (typeof operatorName === 'string' && operatorName.length > 0 && typeof operatorId === 'number' && operatorId !== -1) {
      return createLink(operatorName, 'Show details', () => view.details.showCorporate(operatorId));
    }
    return operatorName;
  };

  const FIELD_COLUMN_DEFS = [];

  const fieldGroup = [];
  fieldGroup.push(
    { headerName: 'Field Name', suppressMenu: true, field: 'resourceName', sortable: true, minWidth: COLUMN_LARGE_LARGE, cellRenderer: resourceNameRenderer, suppressMovable: true },
    { headerName: 'Hub', field: 'hubName', suppressMenu: true, sortable: true, minWidth: COLUMN_LARGE, cellRenderer: renderHubName, suppressMovable: true },
    { headerName: 'Operator', field: 'operatorName', suppressMenu: true, sortable: true, minWidth: COLUMN_LARGE_LARGE, cellRenderer: renderOperatorName, suppressMovable: true },
    { headerName: 'Development Status', field: 'status', suppressMenu: true, sortable: true, minWidth: COLUMN_MEDIUM_MEDIUM, suppressMovable: true },
    // NEED DEV STATUS
  );
  FIELD_COLUMN_DEFS.push({ headerName: 'Field', children: fieldGroup });

  const copYearGroup = [];
  copYearGroup.push(
    { headerName: "Valuation One", field: 'valuationOneCOPYear', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true },
    { headerName: "Valuation Two", field: 'valuationTwoCOPYear', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true },
    { headerName: "Difference", field: 'copYearDifference', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true },
  );
  FIELD_COLUMN_DEFS.push({ headerName: 'COP Year', children: copYearGroup });

  const liquidGroup = [];
  liquidGroup.push(
    { headerName: "Valuation One", field: 'valuationOneRemainingLiquidMMB', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Valuation Two", field: 'valuationTwoRemainingLiquidMMB', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Difference", field: 'remainingLiquidDifferenceMMB', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
  );
  FIELD_COLUMN_DEFS.push({ headerName: 'Remaining Liquid\nReserves (' + valueTypes.UnitString(valueTypes.mmb) + ')', children: liquidGroup });

  const gasGroup = [];
  gasGroup.push(
    { headerName: "Valuation One", field: 'valuationOneRemainingGasBCF', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Valuation Two", field: 'valuationTwoRemainingGasBCF', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Difference", field: 'remainingGasDifferenceBCF', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
  );
  FIELD_COLUMN_DEFS.push({ headerName: 'Remaining Gas\nReserves (' + valueTypes.UnitString(valueTypes.bcf) + ')', children: gasGroup });

  const mmboeGroup = [];
  mmboeGroup.push(
    { headerName: "Valuation One", field: 'valuationOneRemainingReservesMMBOE', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Valuation Two", field: 'valuationTwoRemainingReservesMMBOE', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Difference", field: 'remainingReservesDifferenceMMBOE', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
  );
  FIELD_COLUMN_DEFS.push({ headerName: 'Remaining\nReserves\n(' + valueTypes.UnitString(valueTypes.mmmboe) + ')', children: mmboeGroup });

  const npvGroup = [];
  npvGroup.push(
    { headerName: "Valuation One", field: 'valuationOneRemainingNPVUSDMM', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Valuation Two", field: 'valuationTwoRemainingNPVUSDMM', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
    { headerName: "Difference", field: 'remainingNPVDifferenceUSDMM', suppressMenu: true, sortable: true, minWidth: COLUMN_SMALL, sortingOrder: NUMERIC_SORT, suppressMovable: true, cellRenderer: nullNumberRenderer },
  );
  FIELD_COLUMN_DEFS.push({ headerName: 'Remaining Discounted Post Tax NPV (' + valueTypes.UnitString(valueTypes.usdMM) + ')', children: npvGroup });

  return <BaseGridComponent columnDefs={FIELD_COLUMN_DEFS} dataRows={props.dataRows} autoReSizeGrid={true} headerRowHeight={HEADER_ROW_SINGLE} groupHeaderRowHeight={HEADER_ROW_SINGLE}/>;
};

FieldComparisonGridComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.instanceOf(FieldValuationComparison).isRequired).isRequired,
  showOnMap: PropTypes.func.isRequired,
  currentYear: PropTypes.number.isRequired,
};

FieldComparisonGridComponent.defaultProps = {
  currentYear: new Date().getFullYear(),
};

export default FieldComparisonGridComponent;
export { FieldComparisonGridExcelExport };
