import React from 'react';
import PropTypes from 'prop-types';

import { LicenceFilter } from './licence-filter';

import BaseViewSettingsComponent, { FollowMapComponent, MultiSelectComponent } from '../base/base-view-settings-component';

import { AppSetState } from '../../app/app-set-state';
import { KeyValuePair } from '../../models/key-value-pair';

class LicenceViewSettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      licences: [],
      countries: [],
      areas: [],
      statuses: [],
      types: [],
      rounds: [],
      participants: []
    };
    this.loadFromFilter(this.state);
  }

  componentDidMount() {
    const url = 'api/licence/filters/load';
    const onSuccess = (result) => {
      this.setState({
        licences: KeyValuePair.FromArray(result.licences),
        countries: KeyValuePair.FromArray(result.countries),
        areas: KeyValuePair.FromArray(result.areas),
        statuses: KeyValuePair.FromArray(result.statuses),
        types: KeyValuePair.FromArray(result.types),
        rounds: KeyValuePair.FromArray(result.rounds),
        participants: KeyValuePair.FromArray(result.participants)
      });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter !== prevProps.filter) {
      this.setState(prevState => {
        this.loadFromFilter(prevState);
        return prevState;
      });
    }
  }

  loadFromFilter = (state) => {
    const { filter } = this.props;

    state.followMap = filter.followMap;
    state.licenceIds = filter.licenceIds;
    state.countryIds = filter.countryIds;
    state.areaIds = filter.areaIds;
    state.roundIds = filter.roundIds;
    state.statusIds = filter.statusIds;
    state.typeIds = filter.typeIds;
    state.participantIds = filter.participantIds;
  }

  _update = (closeFilters) => {
    const { filter } = this.props;

    filter.followMap = this.state.followMap;
    filter.licenceIds = this.state.licenceIds;
    filter.countryIds = this.state.countryIds;
    filter.areaIds = this.state.areaIds;
    filter.roundIds = this.state.roundIds;
    filter.statusIds = this.state.statusIds;
    filter.typeIds = this.state.typeIds;
    filter.participantIds = this.state.participantIds;
    this.props.onUpdate(closeFilters);
  };

  _clear = () => {
    this.props.filter.clear();
    this.loadFromFilter(this.state);
    this._update();
  }

  onToggleFollowMap = () => this.setState(prevState => { return { followMap: !prevState.followMap }; });
  onLicenceIdsChange = (value) => this.setState({ licenceIds: value });
  onCountryIdsChange = (value) => this.setState({ countryIds: value });
  onAreaIdsChange = (value) => this.setState({ areaIds: value });
  onRoundIds = (value) => this.setState({ roundIds: value });
  onStatusIds = (value) => this.setState({ statusIds: value });
  onTypeIds = (value) => this.setState({ typeIds: value });
  onParticipantIds = (value) => this.setState({ participantIds: value });

  render() {
    return (
      <BaseViewSettingsComponent
        appSetState={this.props.appSetState}
        className="field-view-settings-component"
        onUpdate={this._update}
        onClear={this._clear}
      >
        <FollowMapComponent value={this.state.followMap} enabled={this.props.followMapEnabled} onClick={this.onToggleFollowMap} />
        <MultiSelectComponent label="Licence Number" value={this.state.licenceIds} values={this.state.licences} onChange={this.onLicenceIdsChange} />
        <MultiSelectComponent label="Country" value={this.state.countryIds} values={this.state.countries} onChange={this.onCountryIdsChange} />
        <MultiSelectComponent label="Area" value={this.state.areaIds} values={this.state.areas} onChange={this.onAreaIdsChange} />
        <MultiSelectComponent label="Licence Round" value={this.state.roundIds} values={this.state.rounds} onChange={this.onRoundIds} />
        <MultiSelectComponent label="Licence Status" value={this.state.statusIds} values={this.state.statuses} onChange={this.onStatusIds} />
        <MultiSelectComponent label="Licence Type" value={this.state.typeIds} values={this.state.types} onChange={this.onTypeIds} />
        <MultiSelectComponent label="Participant" value={this.state.participantIds} values={this.state.participants} onChange={this.onParticipantIds} />
      </BaseViewSettingsComponent>
    );
  }
}

LicenceViewSettingsComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  filter: PropTypes.instanceOf(LicenceFilter).isRequired,
  onUpdate: PropTypes.func.isRequired,
  followMapEnabled: PropTypes.bool.isRequired
};

export default LicenceViewSettingsComponent;