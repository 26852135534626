import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack, faTimes } from '@fortawesome/pro-solid-svg-icons';

import './main-component.scss';
import MainToolbarComponent from './main-toolbar-component';
import MainSidebarComponent from './main-sidebar-component';
import { settingsStates, SettingsStateHelper, ViewModel } from './main-classes';
import { appendClassNames } from '../../helpers/common';

const MainComponent = (props) => {
  const { disabled, showLabels, groupViews, viewSettingsComponent, canCloseSettings, showSettings } = props;

  const [prvState, setPrvState] = React.useState(props.settingsState);

  React.useEffect(() => {
    if (prvState !== props.settingsState) {
      setPrvState(props.settingsState);
    }
  }, [props.settingsState])

  let state = prvState;
  if (showSettings === false) {
    state = settingsStates.Hidden;
  } else if (viewSettingsComponent === null) {
    state = settingsStates.Closed;
  } else if (canCloseSettings === false) {
    state = settingsStates.Pinned;
  }
  const stateHelper = new SettingsStateHelper(() => state, setPrvState, props.showSettingsOnHover, props.onSettingsStateChanged);

  const getClassName = () => {
    let result = appendClassNames('main-component', props.className) + (showLabels ? '' : ' hide-labels') + ' settings-';
    switch (state) {
      case settingsStates.Hidden: result += 'hidden'; break;
      case settingsStates.Closed: result += 'closed'; break;
      case settingsStates.Open:
      case settingsStates.PinnedOverlay: result += 'open'; break;
      case settingsStates.Pinned: result += 'pinned'; break;
      default: break;
    }
    return result;
  };

  return (
    <div className={getClassName()}>
      <div className="ma-toolbar">
        <MainToolbarComponent showLabels={showLabels}>
          {props.toolbarItems}
        </MainToolbarComponent>
      </div>

      <div className="ma-content">
        <div className="ma-sidebar" onMouseOver={stateHelper.show} onMouseOut={stateHelper.hide}>
          <div className="ma-sidebar-content">
            <MainSidebarComponent disabled={disabled} showLabels={showLabels} groupViews={groupViews} viewModels={props.viewModels} selectedView={props.selectedView} onButtonClick={props.setSelectedView} />
          </div>
        </div>

        <div className="ma-settings" onMouseOver={stateHelper.show} onMouseOut={stateHelper.hide}>
          <div className="se-top">
            <div className={'btn-close ' + (canCloseSettings ? 'enabled' : 'disabled')} onClick={canCloseSettings ? stateHelper.forceHide : undefined}>
              <FontAwesomeIcon icon={faTimes} size="xs" fixedWidth />
            </div>
            <div className={'btn-pin ' + (canCloseSettings ? 'enabled' : 'disabled')} onClick={canCloseSettings ? stateHelper.togglePinned : undefined}>
              <FontAwesomeIcon icon={faThumbtack} size="xs" fixedWidth />
            </div>
          </div>
          <div className="se-bottom">
            {viewSettingsComponent}
          </div>
        </div>

        <div className="ma-view-wrapper">
          {props.viewComponent}
        </div>
      </div>
    </div>
  );
};

MainComponent.propTypes = {
  settingsState: settingsStates.propType,
  onSettingsStateChanged: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  showLabels: PropTypes.bool.isRequired,
  showSettings: PropTypes.bool.isRequired,
  showSettingsOnHover: PropTypes.bool.isRequired,
  groupViews: PropTypes.bool.isRequired,
  viewModels: PropTypes.arrayOf(PropTypes.instanceOf(ViewModel).isRequired).isRequired,
  selectedView: PropTypes.number.isRequired,
  setSelectedView: PropTypes.func.isRequired,
  toolbarItems: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.node.isRequired)]),
  canCloseSettings: PropTypes.bool.isRequired,
  viewSettingsComponent: PropTypes.node,
  viewComponent: PropTypes.node.isRequired
};

MainComponent.defaultProps = {
  settingsState: settingsStates.Closed,
  disabled: false,
  showLabels: true,
  showSettings: true,
  showSettingsOnHover: true,
  groupViews: false,
  canCloseSettings: true
};

export default MainComponent;
export { ViewModel, settingsStates };