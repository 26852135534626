import React from 'react';
import PropTypes from 'prop-types';

import { ViewModel } from './main-classes';

const SidebarButtonComponent = (props) => {
  const [hovered, setHovered] = React.useState(false);

  const { selected, disabled, viewModel } = props;
  const className = 'sb-button' + (selected === true ? ' selected' : ' unselected') + (disabled === true ? ' disabled' : ' enabled');
  let label = viewModel.label;
  if (typeof viewModel.groupId === 'number') {
    label = props.viewModels.find(obj => obj.id === viewModel.groupId).label + ': ' + label;
  }
  const onClick = selected === true || disabled === true ? undefined : () => props.onButtonClick(viewModel.id);
  const onMouseOver = () => { if (!disabled) { setHovered(true); } };
  const onMouseLeave = () => { if (!disabled) { setHovered(false); } };

  return (
    <div
      title={viewModel.label}
      className={className}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <div className="sb-icon">{selected || hovered ? viewModel.selectedLargeIcon : viewModel.largeIcon}</div>
      <div className="sb-text">{label}</div>
    </div>
  );
};

SidebarButtonComponent.propTypes = {
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  viewModel: PropTypes.instanceOf(ViewModel).isRequired,
  viewModels: PropTypes.arrayOf(PropTypes.instanceOf(ViewModel).isRequired).isRequired
};

export default SidebarButtonComponent;