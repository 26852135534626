import React from 'react';
import PropTypes from 'prop-types';

import { GridExcelExport } from './grid-excel-export';

import BaseGridComponent, { createLink, createLinkAndIcons, nullNumberRenderer, NUMERIC_SORT } from '../../components/base-grid/base-grid-component';

import { appIcons } from '../../app/app-icons';
import { AppSetState } from '../../app/app-set-state';
import { valueTypes } from '../../shared/helpers/value-handler';
import { FasterResourceSummary, FiscalResource } from '../../components/fiscal/models/portfolio/faster-portfolio';
import { COLUMN_EXTRA_SMALL_SMALL, COLUMN_MEDIUM, COLUMN_MEDIUM_MEDIUM, COLUMN_LARGE, COLUMN_LARGE_LARGE, HEADER_ROW_TREBLE, COLUMN_SMALL } from '../../shared/components/data-grid/ag-grid-component';
import { AtlasPanel } from '../../shared/helpers/enums'

const FieldGridComponent = (props) => {
  const { view } = props.appSetState;

  const resourceNameRenderer = (params) => {
    const { resourceName, resourceId, countryId } = params.data;

    const icons = [];
    icons.push({ appIcon: appIcons.Reports, title: 'Show reports', onClick: () => view.reports.showFields(resourceId, countryId) });
    icons.push({ appIcon: appIcons.MapMarkerAlt, title: 'Show on map', onClick: () => props.showOnMap(params.data) });
    const result = createLinkAndIcons(resourceName, 'Show details', () => view.details.showField(resourceId), icons);
    return result;
  };

  const renderHubName = (params) => {
    let result = params.data.hubName;
    if (typeof params.data.hubCountryId === 'number' && params.data.hubCountryId !== 0) {
      result = createLink(result, 'Show details', () => view.details.showHub(params.data.hubId));
    }
    return result;
  };

  const renderOperatorName = (params) => {
    const { operatorName, operatorId } = params.data;

    if (typeof operatorName === 'string' && operatorName.length > 0 && typeof operatorId === 'number' && operatorId !== -1) {
      return createLink(operatorName, 'Show details', () => view.details.showCorporate(operatorId));
    }
    return operatorName;
  };

  const renderCOPYear = (params) => {
    return params.value === null ? '-' : params.value === 0 ? 'Ceased' : params.value;
  };

  const FIELD_COLUMN_DEFS = [
    {
      headerName: 'Field Name',
      field: 'resourceName',
      sortable: true,
      minWidth: COLUMN_LARGE_LARGE,
      cellRenderer: resourceNameRenderer
    },
    {
      headerName: 'Country',
      field: 'countryName',
      sortable: true,
      minWidth: COLUMN_MEDIUM
    },
    {
      headerName: 'Area',
      field: 'areaName',
      sortable: true,
      minWidth: COLUMN_MEDIUM
    },
    {
      headerName: 'Hub',
      field: 'hubName',
      sortable: true,
      minWidth: COLUMN_LARGE,
      cellRenderer: renderHubName
    },
    {
      headerName: 'Operator',
      field: 'operatorName',
      sortable: true,
      minWidth: COLUMN_LARGE_LARGE,
      cellRenderer: renderOperatorName
    },
    {
      headerName: 'Development Status',
      field: 'status',
      sortable: true,
      minWidth: COLUMN_MEDIUM_MEDIUM
    },
    {
      headerName: "COP Year",
      field: 'lastProductionYear',
      sortable: true,
      minWidth: COLUMN_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: renderCOPYear
    },
    {
      headerName: 'Remaining Liquid\nReserves (' + valueTypes.UnitString(valueTypes.mmb) + ')',
      field: 'remainingLiquidMMB',
      sortable: true,
      minWidth: COLUMN_MEDIUM_MEDIUM,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: 'Remaining Gas\nReserves (' + valueTypes.UnitString(valueTypes.bcf) + ')',
      field: 'remainingGasBCF',
      sortable: true,
      minWidth: COLUMN_MEDIUM_MEDIUM,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: 'Remaining\nReserves\n(' + valueTypes.UnitString(valueTypes.mmmboe) + ')',
      field: 'remainingReservesMMBOE',
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: props.currentYear + '\nProduction\n(' + valueTypes.UnitString(valueTypes.mboed) + ')',
      field: 'currentYearProductionBOEPD',
      sortable: true,
      minWidth: COLUMN_EXTRA_SMALL_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: 'Remaining Discounted \nPost Tax NPV (' + valueTypes.UnitString(valueTypes.usdMM) + ')',
      field: 'npv',
      sortable: true,
      minWidth: COLUMN_LARGE,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
    {
      headerName: props.currentYear + '\nCO2 Emissions\n(' + valueTypes.UnitString(valueTypes.ktCO2) + ')',
      field: 'currentYearCO2EmissionsKTCO2',
      sortable: true,
      minWidth: COLUMN_SMALL,
      sortingOrder: NUMERIC_SORT,
      cellRenderer: nullNumberRenderer
    },
  ];

  return <BaseGridComponent columnDefs={FIELD_COLUMN_DEFS} dataRows={props.dataRows} autoReSizeGrid={true} headerRowHeight={HEADER_ROW_TREBLE} />;
};

FieldGridComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.instanceOf(FasterResourceSummary).isRequired).isRequired,
  showOnMap: PropTypes.func.isRequired,
  currentYear: PropTypes.number.isRequired,
  fiscalResource: PropTypes.arrayOf(PropTypes.instanceOf(FiscalResource).isRequired).isRequired,
};

FieldGridComponent.defaultProps = {
  currentYear: new Date().getFullYear(),
  fiscalResource: [],
};

export default FieldGridComponent;
export { GridExcelExport };
