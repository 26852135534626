class RevenueSummaryChartAnnual {
  constructor(year, totalRevenueUsdMM, developmentCapexUsdMM, opexUsdMM, liquidTariffUsdMM, gasTariffUsdMM, abexUsdMM, abexReliefUsdMM, corporationTaxUsdMM, specialTaxUsdMM, postTaxCashflowUsdMM, totalEmissionsChargeUsdMM) {
    if (typeof year !== 'number') {
      throw new Error('Invalid annualCashflowObj.year param supplied to advancedCashflowChartAnnual.ctor');
    }
    if (typeof totalRevenueUsdMM !== 'number') {
      throw new Error('Invalid annualCashflowObj.totalRevenueUsdMM param supplied to advancedCashflowChartAnnual.ctor');
    }
    if (typeof developmentCapexUsdMM !== 'number') {
      throw new Error('Invalid annualCashflowObj.developmentCapexUsdMM param supplied to advancedCashflowChartAnnual.ctor');
    }    
    if (typeof opexUsdMM !== 'number') {
      throw new Error('Invalid annualCashflowObj.opexUsdMM param supplied to advancedCashflowChartAnnual.ctor');
    }
    if (typeof liquidTariffUsdMM !== 'number') {
      throw new Error('Invalid annualCashflowObj.liquidTariffUsdMM param supplied to advancedCashflowChartAnnual.ctor');
    }
    if (typeof gasTariffUsdMM !== 'number') {
      throw new Error('Invalid annualCashflowObj.gasTariffUsdMM param supplied to advancedCashflowChartAnnual.ctor');
    }
    if (typeof corporationTaxUsdMM !== 'number') {
      throw new Error('Invalid annualCashflowObj.corporationTaxUsdMM param supplied to advancedCashflowChartAnnual.ctor');
    }
    if (typeof specialTaxUsdMM !== 'number') {
      throw new Error('Invalid annualCashflowObj.specialTaxUsdMM param supplied to advancedCashflowChartAnnual.ctor');
    }
    if (typeof abexUsdMM !== 'number') {
      throw new Error('Invalid annualCashflowObj.abexUsdMM param supplied to advancedCashflowChartAnnual.ctor');
    }
    if (typeof abexReliefUsdMM !== 'number') {
      throw new Error('Invalid annualCashflowObj.abexReliefUsdMM param supplied to advancedCashflowChartAnnual.ctor');
    }    
    if (typeof postTaxCashflowUsdMM !== 'number') {
      throw new Error('Invalid annualCashflowObj.postTaxCashflowUsdMM param supplied to advancedCashflowChartAnnual.ctor');
    }
    if (typeof totalEmissionsChargeUsdMM !== 'number') {
      throw new Error('Invalid annualCashflowObj.totalEmissionsChargeUsdMM param supplied to advancedCashflowChartAnnual.ctor');
    }

    this.year = year;    
    this.totalRevenueUsdMM = totalRevenueUsdMM;
    this.developmentCapexUsdMM = developmentCapexUsdMM;
    this.opexUsdMM = opexUsdMM;
    this.liquidTariffUsdMM = liquidTariffUsdMM;
    this.gasTariffUsdMM = gasTariffUsdMM;
    this.abexUsdMM = abexUsdMM; 
    this.abexReliefUsdMM = abexReliefUsdMM; 
    this.corporationTaxUsdMM = corporationTaxUsdMM;
    this.specialTaxUsdMM = specialTaxUsdMM;       
    this.postTaxCashflowUsdMM = postTaxCashflowUsdMM;
    this.totalEmissionsChargeUsdMM = totalEmissionsChargeUsdMM;
  }
}

export { RevenueSummaryChartAnnual };