import React from 'react';

import { HomeFilter } from './home-filter';
import HomeViewComponent from './home-view-component';

import { BaseViewSettings } from '../base/base-view-settings';

class HomeViewSettings extends BaseViewSettings {
  constructor(appView, appSetState) {
    super(appView, appSetState, new HomeFilter());

    this._settings.showOverview = true;
    this._settings.activitiesType = null;
    this._settings.activitiesFromDate = new Date();
    this._settings.activitiesFromDate.setMonth(this._settings.activitiesFromDate.getMonth() - 3);
    this._settings.activitiesToDate = new Date();
  }

  getViewSettingsComponent(settings) {
    super.getViewSettingsComponent(settings);

    return null;
  }

  getViewComponent(settings) {
    super.getViewComponent(settings);

    const reportid = settings.emailReportID;
    settings.emailReportID = 0;
    const mainreportid = settings.mainReportID;
    settings.mainReportID = 0;

    return (
      <HomeViewComponent
        appSetState={this.appSetState}
        geoserverUrl={settings.geoserverUrl}
        settings={this._settings}
        filter={this._filter}
        emailreportid={reportid}
        mainreportid={mainreportid}
      />
    );
  }
}

export { HomeViewSettings };
