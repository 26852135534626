import React from 'react';
import PropTypes from 'prop-types';

import HubFieldsTableComponent from './hub-fields/hub-fields-table-component';

import { valueTypes } from '../../../shared/helpers/value-handler';
import { HubDetail } from '../../../models/hub-detail';
import GenericChart, { ChartType } from '../../../shared/components/generic-chart/generic-chart';
import { createSection, sectionTypes, createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';

const AdvancedHubFieldsComponent = (props) => {
  const { hub } = props;

  const chartStyle = { height: '350px' };

  const fieldTable = <HubFieldsTableComponent resource={hub.fiscalResources} currentYear={hub.currentYear} discountRate={hub.cashflow.discountRate} onFieldClick={props.onFieldClick} onOperatorClick={props.onOperatorClick} />;

  const reserves = (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.remainingReservesByField}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.mmmboe)}
          showYAxisGridLines={true}
        />
      </div>
    </div>
  );

  const production = (
    <div style={chartStyle}>
      <GenericChart
        chartDataItems={hub.chartDataCollection.productionByField}
        chartType={ChartType.Line}
        isXStacked={false}
        isYStacked={true}
        showYAxisLabel={true}
        yAxisLabel={valueTypes.UnitString(valueTypes.mboed)}
        showYAxisGridLines={true}
      />
    </div>

  );

  return (
    <div ref={props.innerRef}>
      {createSection100(fieldTable, 'Fields')}

      {createSection([
        { content: reserves, sectionType: sectionTypes.section33, title: 'Remaining reserves by field' },
        { content: production, sectionType: sectionTypes.section66, title: 'Production by field' },
      ])}
    </div>
  );
};

AdvancedHubFieldsComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  hub: PropTypes.instanceOf(HubDetail).isRequired,
  onOperatorClick: PropTypes.func,
  onFieldClick: PropTypes.func
};

export default AdvancedHubFieldsComponent;