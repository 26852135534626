import moment from 'moment';

import { showError } from '../shared/helpers/common';
import { MapShapeBorder } from '../shared/scss/app-colors';
import { CircleModel } from '../shared/helpers/leaflet/models/circle-model';
import { Area } from '../shared/components/models/gmatrix3-extension-area';
import { Country } from '../shared/components/models/gmatrix3-extension-country';
import { DisplayWell } from '../shared/components/models/gmatrix3-extension-display-well';
import { NonProducingParticipant } from '../shared/components/models/gmatrix3-extension-non-producing-participant';
import ResourcePlannedWell from './resource-planned-well';

class DiscoveryDetail {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('DiscoveryDetail', 'DiscoveryDetail'); }
    if (typeof obj.id !== 'number') { showError('DiscoveryDetail', 'id'); }
    if (typeof obj.name !== 'string') { showError('DiscoveryDetail', 'name'); }
    if (typeof obj.resourceCountry !== 'object') { showError('DiscoveryDetail', 'resourceCountry'); }
    if (typeof obj.resourceArea !== 'object') { showError('DiscoveryDetail', 'resourceArea'); }
    if (obj.licence !== null && typeof obj.licence !== 'string') { showError('DiscoveryDetail', 'licence'); }
    if (obj.licenceId !== null && typeof obj.licenceId !== 'number') { showError('DiscoveryDetail', 'licenceId'); }
    if (obj.blockId !== null && typeof obj.blockId !== 'number') { showError('DiscoveryDetail', 'blockId'); }
    if (obj.blockNo !== null && typeof obj.blockNo !== 'string') { showError('DiscoveryDetail', 'blockNo'); }
    if (obj.operator !== null && typeof obj.operator !== 'string') { showError('DiscoveryDetail', 'operator'); }
    if (obj.type !== null && typeof obj.type !== 'string') { showError('DiscoveryDetail', 'type'); }
    if (obj.classification !== null && typeof obj.classification !== 'string') { showError('DiscoveryDetail', 'classification'); }
    if (obj.classificationRationale !== null && typeof obj.classificationRationale !== 'string') { showError('DiscoveryDetail', 'classificationRationale'); }
    if (typeof obj.initialLiquidMmb !== 'number') { showError('DiscoveryDetail', 'initialLiquidMmb'); }
    if (typeof obj.initialGasBcf !== 'number') { showError('DiscoveryDetail', 'initialGasBcf'); }
    if (typeof obj.initialGasMmboe !== 'number') { showError('DiscoveryDetail', 'initialGasMmboe'); }
    if (typeof obj.initialReservesMmboe !== 'number') { showError('DiscoveryDetail', 'initialReservesMmboe'); }

    if (obj.discoveryWellId !== null && typeof obj.discoveryWellId !== 'number') { showError('DiscoveryDetail', 'discoveryWellId'); }
    if (typeof obj.discoveryWell !== 'string') { showError('DiscoveryDetail', 'discoveryWell'); }
    let discoveryWellSpudDateMoment = null;
    if (obj.discoveryWellSpudDate !== null) {
      if (typeof obj.discoveryWellSpudDate !== 'string') { showError('DiscoveryDetail', 'discoveryWellSpudDate'); }
      discoveryWellSpudDateMoment = moment(obj.discoveryWellSpudDate);
      if (!discoveryWellSpudDateMoment.isValid()) { showError('DiscoveryDetail', 'discoveryWellSpudDateMoment'); }
    }

    let discoveryWellCompletionDateMoment = null;
    if (obj.discoveryWellCompletionDate !== null) {
      if (typeof obj.discoveryWellCompletionDate !== 'string') { showError('DiscoveryDetail', 'discoveryWellCompletionDate'); }
      discoveryWellCompletionDateMoment = moment(obj.discoveryWellCompletionDate);
      if (!discoveryWellCompletionDateMoment.isValid()) { showError('DiscoveryDetail', 'discoveryWellSpudDateMoment'); }
    }
    if (typeof obj.conditionId !== 'number') { showError('FieldDetail', 'conditionId'); }
    if (typeof obj.condition !== 'string') { showError('FieldDetail', 'condition'); }
    if (obj.owners !== null && !Array.isArray(obj.owners)) { showError('DiscoveryDetail', 'owners'); }
    if (obj.wells !== null && !Array.isArray(obj.wells)) { showError('DiscoveryDetail', 'wells'); }
    if (obj.plannedWells !== null && !Array.isArray(obj.plannedWells)) { showError('DiscoveryDetail', 'plannedWells'); }
    if (typeof obj.fullView !== 'boolean') { showError('DiscoveryDetail', 'fullView'); }

    // Technical
    if (typeof obj.waterDepthFt !== 'number') { showError('FieldDetail', 'waterDepthFt'); }
    if (typeof obj.reservoirAgeId !== 'number') { throw new Error('Invalid obj.reservoirAgeId param supplied to Resource.ctor'); }
    if (typeof obj.reservoirAge !== 'string') { throw new Error('Invalid obj.reservoirAge param supplied to Resource.ctor'); }
    if (typeof obj.trapTypeId !== 'number') { throw new Error('Invalid obj.trapTypeId param supplied to Resource.ctor'); }
    if (typeof obj.trapType !== 'string') { throw new Error('Invalid obj.trapType param supplied to Resource.ctor'); }
    if (typeof obj.reservoirDepthFt !== 'number') { throw new Error('Invalid obj.reservoirDepthFt param supplied to Resource.ctor'); }
    if (typeof obj.gasOilContactFtTvdss !== 'number') { throw new Error('Invalid obj.gasOilContactFtTvdss param supplied to Resource.ctor'); }
    if (typeof obj.gasWaterContactFtTvdss !== 'number') { throw new Error('Invalid obj.gasWaterContactFtTvdss param supplied to Resource.ctor'); }
    if (typeof obj.oilWaterContactFtTvdss !== 'number') { throw new Error('Invalid obj.oilWaterContactFtTvdss param supplied to Resource.ctor'); }
    if (typeof obj.contactComment !== 'string') { throw new Error('Invalid obj.contactComment param supplied to Resource.ctor'); }
    if (typeof obj.gasColumnFt !== 'number') { throw new Error('Invalid obj.gasColumnFt param supplied to Resource.ctor'); }
    if (typeof obj.oilColumnFt !== 'number') { throw new Error('Invalid obj.oilColumnFt param supplied to Resource.ctor'); }
    if (typeof obj.grossReservoirIntervalAvgFt !== 'number') { throw new Error('Invalid obj.grossReservoirIntervalAvgFt param supplied to Resource.ctor'); }
    if (typeof obj.netToGrossRatioAvgFt !== 'number') { throw new Error('Invalid obj.netToGrossRatioAvgFt param supplied to Resource.ctor'); }
    if (typeof obj.porosityAveragePercent !== 'number') { throw new Error('Invalid obj.porosityAveragePercent param supplied to Resource.ctor'); }
    if (typeof obj.permeabilityAverageMillidarcies !== 'number') { throw new Error('Invalid obj.permeabilityAverageMillidarcies param supplied to Resource.ctor'); }
    if (typeof obj.hydrocarbonSaturationPercent !== 'number') { throw new Error('Invalid obj.hydrocarbonSaturationPercent param supplied to Resource.ctor'); }
    if (typeof obj.oilDensityDegApi !== 'number') { throw new Error('Invalid obj.oilDensityDegApi param supplied to Resource.ctor'); }
    if (typeof obj.gasGravityGramsPerCc !== 'number') { throw new Error('Invalid obj.gasGravityGramsPerCc param supplied to Resource.ctor'); }
    if (typeof obj.viscosityCentipoise !== 'number') { throw new Error('Invalid obj.viscosityCentipoise param supplied to Resource.ctor'); }
    if (typeof obj.viscosityTemperatureFahrenheit !== 'number') { throw new Error('Invalid obj.viscosityTemperatureFahrenheit param supplied to Resource.ctor'); }
    if (typeof obj.gasToOilRatioScfPerBbl !== 'number') { throw new Error('Invalid obj.gasToOilRatioScfPerBbl param supplied to Resource.ctor'); }
    if (typeof obj.condensateToGasToRatioStbPerMmscf !== 'number') { throw new Error('Invalid obj.condensateToGasToRatioStbPerMmscf param supplied to Resource.ctor'); }
    if (typeof obj.initialReservoirPressurePsi !== 'number') { throw new Error('Invalid obj.initialReservoirPressurePsi param supplied to Resource.ctor'); }
    if (typeof obj.pressureStatus !== 'string') { throw new Error('Invalid obj.pressureStatus param supplied to Resource.ctor'); }
    if (typeof obj.initialReservoirPressureDepthFtTvdss !== 'number') { throw new Error('Invalid obj.initialReservoirPressureDepthFtTvdss param supplied to Resource.ctor'); }
    if (typeof obj.reservoirTemperatureFahrenheit !== 'number') { throw new Error('Invalid obj.reservoirTemperatureFahrenheit param supplied to Resource.ctor'); }
    if (typeof obj.temperatureStatus !== 'string') { throw new Error('Invalid obj.temperatureStatus param supplied to Resource.ctor'); }
    if (typeof obj.initialReservoirTemparatureDepthFtTvdss !== 'number') { throw new Error('Invalid obj.initialReservoirTemparatureDepthFtTvdss param supplied to Resource.ctor'); }
    if (typeof obj.sulphurPercent !== 'number') { throw new Error('Invalid obj.sulphurPercent param supplied to Resource.ctor'); }
    // End Technical
    if (typeof obj.point !== 'object') { throw new Error('Invalid obj.point param supplied to Resource.ctor'); }

    this.id = obj.id;
    this.name = obj.name;
    this.resourceCountry = new Country(obj.resourceCountry);
    this.resourceArea = new Area(obj.resourceArea);
    this.licence = obj.licence;
    this.licenceId = obj.licenceId;
    this.blockId = obj.blockId;
    this.blockNo = obj.blockNo;
    this.operator = obj.operator !== null ? obj.operator : 'N/A';
    this.type = obj.type;
    this.classification = obj.classification !== null ? obj.classification : 'N/A';
    this.classificationRationale = obj.classificationRationale !== null ? obj.classificationRationale : 'N/A';
    this.initialLiquidMmb = obj.initialLiquidMmb;
    this.initialGasBcf = obj.initialGasBcf;
    this.initialGasMmboe = obj.initialGasMmboe;
    this.initialReservesMmboe = obj.initialReservesMmboe;
    this.discoveryWellId = obj.discoveryWellId;
    this.discoveryWell = obj.discoveryWell;
    this.discoveryWellSpudDate = discoveryWellSpudDateMoment === null ? null : discoveryWellSpudDateMoment.format('DD/MM/YYYY');
    this.discoveryWellCompletionDate = discoveryWellCompletionDateMoment === null ? null : discoveryWellCompletionDateMoment.format('DD/MM/YYYY');
    this.conditionId = obj.conditionId;
    this.condition = obj.condition;
    this.owners = obj.owners !== null ? obj.owners.map(obj => new NonProducingParticipant(obj)) : null;
    this.wells = obj.wells !== null ? obj.wells.map(obj => new DisplayWell(obj)) : null;
    this.plannedWells = obj.plannedWells !== null ? obj.plannedWells.map(obj => new ResourcePlannedWell(obj)) : null;

    // Technical
    this.waterDepthFt = obj.waterDepthFt;
    this.reservoirAgeId = obj.reservoirAgeId;
    this.reservoirAge = obj.reservoirAge;
    this.trapTypeId = obj.trapTypeId;
    this.trapType = obj.trapType;
    this.reservoirDepthFt = obj.reservoirDepthFt;
    this.gasOilContactFtTvdss = obj.gasOilContactFtTvdss;
    this.gasWaterContactFtTvdss = obj.gasWaterContactFtTvdss;
    this.oilWaterContactFtTvdss = obj.oilWaterContactFtTvdss;
    this.contactComment = obj.contactComment;
    this.gasColumnFt = obj.gasColumnFt;
    this.oilColumnFt = obj.oilColumnFt;
    this.grossReservoirIntervalAvgFt = obj.grossReservoirIntervalAvgFt;
    this.netToGrossRatioAvgFt = obj.netToGrossRatioAvgFt;
    this.porosityAveragePercent = obj.porosityAveragePercent;
    this.permeabilityAverageMillidarcies = obj.permeabilityAverageMillidarcies;
    this.hydrocarbonSaturationPercent = obj.hydrocarbonSaturationPercent;
    this.oilDensityDegApi = obj.oilDensityDegApi;
    this.gasGravityGramsPerCc = obj.gasGravityGramsPerCc;
    this.viscosityCentipoise = obj.viscosityCentipoise;
    this.viscosityTemperatureFahrenheit = obj.viscosityTemperatureFahrenheit;
    this.gasToOilRatioScfPerBbl = obj.gasToOilRatioScfPerBbl;
    this.condensateToGasToRatioStbPerMmscf = obj.condensateToGasToRatioStbPerMmscf;
    this.initialReservoirPressurePsi = obj.initialReservoirPressurePsi;
    this.pressureStatus = obj.pressureStatus;
    this.initialReservoirPressureDepthFtTvdss = obj.initialReservoirPressureDepthFtTvdss;
    this.reservoirTemperatureFahrenheit = obj.reservoirTemperatureFahrenheit;
    this.temperatureStatus = obj.temperatureStatus;
    this.initialReservoirTemparatureDepthFtTvdss = obj.initialReservoirTemparatureDepthFtTvdss;
    this.sulphurPercent = obj.sulphurPercent;
    this.fullView = obj.fullView;
    this.shape = new CircleModel(obj.point.y, obj.point.x, { borderColor: MapShapeBorder, fillColor: 'transparent', radius: 900 });
  }
}

export { DiscoveryDetail };