import React from 'react';
import PropTypes from 'prop-types';

import HubProspectTableComponent from './hub-prospects/hub-prospects-table-component';

import { HubDetail } from '../../../models/hub-detail';
import { createSection100, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import GenericChart, { ChartType } from '../../../shared/components/generic-chart/generic-chart';
import { valueTypes } from '../../../shared/helpers/value-handler';

const AdvancedHubProspectTableComponent = (props) => {
  const { hub, proximity } = props;
  var prospectTable;
  
  const hasProspects = hub.proximityProspects !== null && hub.proximityProspects.length > 0;
  const chartStyle = { height: '350px' };

  const prospectByReservoirAge = hasProspects && (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.prospectByReservoirAge}
          chartType={ChartType.Bar}
          isXStacked={false}
          isYStacked={false}
          showYAxisLabel={true}
          yAxisLabel={'Number of prospects'}
          showYAxisGridLines={true}
        />
      </div>
    </div>);

  const prospectResourceByReservoirArea = hasProspects && (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.prospectResourceByReservoirArea}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.mmmboe)}
          showYAxisGridLines={true}
        />
      </div>
    </div>);

  if (hasProspects) {
    prospectTable = <HubProspectTableComponent
      prospects={hub.proximityProspects}
      onProspectClick={props.onProspectClick}
      onLicenceClick={props.onLicenceClick}
      onBlockClick={props.onBlockClick}
      onOperatorClick={props.onOperatorClick}
    />;
  } else {
    prospectTable = (<div>No prospects</div>);
  }

  return (
    <div ref={props.innerRef}>
      <h5 className="proximity-title">Prospects within {proximity}km of the hub centre point</h5>
      {createSection100(prospectTable, '')}
      {hasProspects &&
        createSection([
          { content: prospectByReservoirAge, sectionType: sectionTypes.section50, title: 'Number of prospects by reservoir age' },
          { content: prospectResourceByReservoirArea, sectionType: sectionTypes.section50, title: 'Unrisked resource by reservoir age & area' }
        ])}
      
    </div>
  );
};

AdvancedHubProspectTableComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  hub: PropTypes.instanceOf(HubDetail).isRequired,
  proximity: PropTypes.number.isRequired,
  onProspectClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default AdvancedHubProspectTableComponent;