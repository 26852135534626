import React from 'react';
import PropTypes from 'prop-types';
import HubCashflowChart from './cashflow/hub-cashflow-chart';
import HubCashflowTableComponent from './cashflow/hub-cashflow-table-component';
import { HubDetail } from '../../../models/hub-detail';
import { createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import HubCashflowAssumptionsComponent from '../../../components/fiscal/fact-components/hub-cashflow-assumptions-component';

const AdvancedCashflowComponent = (props) => {
  const { hub } = props;
  const { hasFiscal } = hub;

  return (
    <div ref={props.innerRef}>
      {hasFiscal && createSection100(<div style={{ height: '400px' }}><HubCashflowChart cashflowData={hub.cashflow.cashflowAnnuals} /></div>, 'Cashflow')}
      {hasFiscal && createSection100(<HubCashflowTableComponent annual={hub.cashflow.cashflowAnnuals} totalRow={hub.cashflow.cashflowAnnualsTotalRow} />)}
      {hasFiscal && createSection100(<HubCashflowAssumptionsComponent valuationName={hub.valuation.variableName} />, 'Economic assumptions')}
    </div>
  );
};

AdvancedCashflowComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  hub: PropTypes.instanceOf(HubDetail).isRequired
};

export default AdvancedCashflowComponent;