import React from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import { DisplayParticipant } from '../models/gmatrix3-display-participant';
import { valueTypes } from '../../helpers/value-handler';
import './owners-pie-chart-component.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

const OwnersPieChartComponent = (props) => {
  const { owners } = props;

  const chartData = {
    labels: owners.map(obj => obj.corporateName + ' (' + (obj.operator ? 'Op, ' : '') + valueTypes.FullDisplay(valueTypes.equity, obj.equity) + ')'),
    datasets: [{
      data: owners.map(obj => obj.equity),
      backgroundColor: owners.map(obj => obj.color)
    }]
  };

  const tooltipLabel = (tooltipModel) => {
    const owner = owners[tooltipModel.index];
    return owner.corporateName + ' ' + valueTypes.FullDisplay(valueTypes.equity, owner.equity);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (tooltipItems) {
            return '';
          },
          label: function (tooltipItem) {
            return tooltipItem.label;
          }
        }
      },
      legend: {
        position: 'right'
      }
    }
  };

  return (
    <div className="participation-pie"><Pie data={chartData} options={chartOptions} /></div>
  );
}

OwnersPieChartComponent.propTypes = {
  owners: PropTypes.arrayOf(PropTypes.instanceOf(DisplayParticipant)).isRequired
};

export default OwnersPieChartComponent;
