import { HubDetail } from '../../../models/hub-detail';
import { objectToEnum } from '../../../shared/helpers/common';
import { valueTypes } from '../../../shared/helpers/value-handler';
import { BaseExcelExportColumn, BaseExcelExport } from '../../../shared/helpers/base-excel-export';
import { UserValuations } from '../../../models/user-valuations';

const exportType = objectToEnum({
  CashflowAnnuals: 0,
  //TODO: XXX: 1
});

class FiscalHubExcelExport extends BaseExcelExport {
  constructor(hub, valuation, baseValuation) {
    if (!(hub instanceof HubDetail)) {
      throw new Error('Invalid "hub" param supplied to "FiscalHubExcelExport.ctor"');
    }
    if (!(valuation instanceof UserValuations)) {
      throw new Error('Invalid "valuation" param supplied to "FiscalHubExcelExport.ctor"');
    }
    if (!(baseValuation instanceof UserValuations)) {
      throw new Error('Invalid "baseValuation" param supplied to "FiscalHubExcelExport.ctor"');
    }

    super();
    this._hub = hub;
    this._valuation = valuation;
    this._baseValuation = baseValuation;
  }

  exportAll() {
    this._doExport(exportType.getValues());
  }

  exportCashflowAnnuals() {
    this.export([exportType.CashflowAnnuals]);
  }

  //TODO: exportXXX() {
  //TODO:   this.export([exportType.XXX]);
  //TODO: }

  _doExport(exportTypes) {
    if (!Array.isArray(exportTypes) || exportTypes.length === 0 || exportTypes.filter(obj => exportType.isInvalid(obj)).length > 0) {
      throw new Error('Invalid "exportTypes" param supplied to "FiscalHubExcelExport.export"');
    }

    const workbook = this.createWorkbook();

    if (exportTypes.includes(exportType.CashflowAnnuals)) {
      this._doExportCashflowAnnuals(workbook);
    }

    //TODO: if (exportTypes.includes(exportType.XXX)) {
    //TODO:   this._doExportXXX(workbook);
    //TODO: }

    this.writeWorkbook(workbook, 'Hub ' + this._hub.hubName + this.getAtlas5WorkbookSuffix(), null, this._valuation, this._baseValuation);
  }

  _doExportCashflowAnnuals(workbook) {
    const usdMM = valueTypes.UnitString(valueTypes.usdMM);

    const columns = [
      new BaseExcelExportColumn('Year', 'year'),
      //new BaseExcelExportColumn('liquidPriceUsdBOE', 'liquidPriceUsdBOE'),
      //new BaseExcelExportColumn('gasPriceUsdMCF', 'gasPriceUsdMCF'),
      //new BaseExcelExportColumn('liquidPriceDiscountPremiumPercent', 'liquidPriceDiscountPremiumPercent'),
      //new BaseExcelExportColumn('gasPriceDiscountPremiumPercent', 'gasPriceDiscountPremiumPercent'),
      //new BaseExcelExportColumn('discountRate', 'discountRate'),
      new BaseExcelExportColumn('Liquid Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'liquidProductionMbod', '0.00'),
      new BaseExcelExportColumn('Gas Production (' + valueTypes.UnitString(valueTypes.mmscfd) + ')', 'gasProductionMmscfd', '0.00'),
      /*new BaseExcelExportColumn('Total Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'combinedMboed', '0.00'),*/
      new BaseExcelExportColumn('Total Production (' + valueTypes.UnitString(valueTypes.mboed) + ')', 'combinedMboed', '0.00'),
      //new BaseExcelExportColumn('gasProductionMboed', 'gasProductionMboed'),
      //new BaseExcelExportColumn('liquidRevenueUsdMM', 'liquidRevenueUsdMM'),
      //new BaseExcelExportColumn('gasRevenueUsdMM', 'gasRevenueUsdMM'),
      new BaseExcelExportColumn('Revenue (' + usdMM + ')', 'totalRevenueUsdMM', '0.00'),
      new BaseExcelExportColumn('Opex (' + usdMM + ')', 'opexUsdMM', '0.00'),
      new BaseExcelExportColumn('Emissions charge (' + usdMM + ')', 'totalEmissionsChargeUsdMM', '0.00'),
      //new BaseExcelExportColumn('liquidTariffUsdBOE', 'liquidTariffUsdBOE'),
      //new BaseExcelExportColumn('gasTariffUsdBOE', 'gasTariffUsdBOE'),
      //new BaseExcelExportColumn('liquidTariffUsdMM', 'liquidTariffUsdMM'),
      //new BaseExcelExportColumn('gasTariffUsdMM', 'gasTariffUsdMM'),
      new BaseExcelExportColumn('Export tariff (' + usdMM + ')', 'totalTariffUsdMM', '0.00'),
      //new BaseExcelExportColumn('ebitda', 'ebitda'),
      //new BaseExcelExportColumn('cumulativeCostForDDAUsdMM', 'cumulativeCostForDDAUsdMM'),
      //new BaseExcelExportColumn('depreciationUsdMM', 'depreciationUsdMM'),
      //new BaseExcelExportColumn('openingBalanceUsdMM', 'openingBalanceUsdMM'),
      //new BaseExcelExportColumn('lossesOrDepreciationAddedUsdMM', 'lossesOrDepreciationAddedUsdMM'),
      //new BaseExcelExportColumn('lossesUsedOrIncomeShelteredUsdMM', 'lossesUsedOrIncomeShelteredUsdMM'),
      //new BaseExcelExportColumn('rfesUpliftUsdMM', 'rfesUpliftUsdMM'),
      //new BaseExcelExportColumn('closingBalanceUsdMM', 'closingBalanceUsdMM'),
      //new BaseExcelExportColumn('ctTaxBaseUsdMM', 'ctTaxBaseUsdMM'),
      //new BaseExcelExportColumn('investmentsToUpliftUsdMM', 'investmentsToUpliftUsdMM'),
      //new BaseExcelExportColumn('upliftUsdMM', 'upliftUsdMM'),
      //new BaseExcelExportColumn('cumulativeUpliftAfterUsageUsdMM', 'cumulativeUpliftAfterUsageUsdMM'),
      //new BaseExcelExportColumn('investmentAllowanceAvailableUsdMM', 'investmentAllowanceAvailableUsdMM'),
      //new BaseExcelExportColumn('investmentAllowanceUsedUsdMM', 'investmentAllowanceUsedUsdMM'),
      //new BaseExcelExportColumn('sctIncomeShelteredUsdMM', 'sctIncomeShelteredUsdMM'),
      //new BaseExcelExportColumn('sctTaxBaseUsdMM', 'sctTaxBaseUsdMM'),
      //new BaseExcelExportColumn('explorationCapexUsdMM', 'explorationCapexUsdMM'),
      //new BaseExcelExportColumn('developmentCapexUsdMM', 'developmentCapexUsdMM'),
      new BaseExcelExportColumn('Capex (' + usdMM + ')', 'costForLossesOrDepreciationUsdMM', '0.00'),
      new BaseExcelExportColumn('Abex (' + usdMM + ')', 'abexUsdMM', '0.00'),
      new BaseExcelExportColumn('Pre-tax cashflow (' + usdMM + ')', 'preTaxCashflowUsdMM', '0.00'),
      //new BaseExcelExportColumn('cumulativePreTaxCashflowUsdMM', 'cumulativePreTaxCashflowUsdMM'),
      //new BaseExcelExportColumn('explorationRebateUsdMM', 'explorationRebateUsdMM'),
      //new BaseExcelExportColumn('corporationTaxUsdMM', 'corporationTaxUsdMM'),
      //new BaseExcelExportColumn('specialTaxUsdMM', 'specialTaxUsdMM'),
      //new BaseExcelExportColumn('abexReliefUsdMM', 'abexReliefUsdMM'),
      //new BaseExcelExportColumn('ctAndSCT', 'ctAndSCT'),
      //new BaseExcelExportColumn('postTaxCashflowUsdMM', 'postTaxCashflowUsdMM'),
      //new BaseExcelExportColumn('cumulativePostTaxCashflowUsdMM', 'cumulativePostTaxCashflowUsdMM'),
      //new BaseExcelExportColumn('discountedPostTaxCashflowUsdMM', 'discountedPostTaxCashflowUsdMM'),
      //new BaseExcelExportColumn('cumulativeDiscountedPostTaxCashflowUsdMM', 'cumulativeDiscountedPostTaxCashflowUsdMM'),
      //new BaseExcelExportColumn('opexTypeId', 'opexTypeId'),
      //new BaseExcelExportColumn('resourceOpexUsdMM', 'resourceOpexUsdMM'),
      //new BaseExcelExportColumn('opexCostSharePercent', 'opexCostSharePercent'),
      //new BaseExcelExportColumn('portfolioEquityMultiplier', 'portfolioEquityMultiplier')
    ];

    this.writeSheet(workbook, 'Cashflow Annuals', this._hub.cashflow.cashflowAnnuals, columns);
  }

  //TODO: _doExportXXX(workbook) {
  //TODO:   
  //TODO: }
}

export { FiscalHubExcelExport };
