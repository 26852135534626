import React from 'react';
import { Button } from 'reactstrap';

import HubDetailComponent from './hub-detail-component';

import { DetailModel } from '../detail-model';
import HeaderComponent, { BUTTON_ICON_SIZE } from '../header-component';
import HeaderButtonComponent from '../header-button-component';

import { appIcons, appIconSizes } from '../../app/app-icons';
import { appViews } from '../../app/app-views';
import { ContextLayerModel } from '../../shared/helpers/leaflet/leaflet-helper';
import { HubDetail } from '../../models/hub-detail';
import { FiscalHubExcelExport } from '../../components/fiscal/helpers/fiscal-hub-excel-export';

class HubDetailModel extends DetailModel {
  constructor(atlasPanel, hubId, oldproximity = 25, proximity = 25, proximityLower, proximityUpper) {
    if (typeof hubId !== 'number') {
      throw new Error('Invalid "hubId" supplied to "HubDetailModel.ctor"');
    }
    super(appViews.Hub, atlasPanel);
    this._hubId = hubId;
    this._oldproximity = oldproximity;
    this._proximity = proximity;
    this._actualProximity = this._proximity * 1000;
    this._proximityLower = proximityLower;
    this._proximityUpper = proximityUpper;
    this._valuationId = 0;
    this._appSetState = null;
    this.openSettings = this.openSettings.bind(this);
  }

  matches(detail) {
    const result =
      typeof detail === 'object' &&
      detail instanceof HubDetailModel &&
      detail._hubId === this._hubId &&
      detail._valuationId === this._valuationId &&
      this._oldproximity === this._proximity      
    return result;
  }

  load(appSetState, onLoadComplete) {
    this._appSetState = appSetState;

    this._validateLoadParams(appSetState, onLoadComplete);

    const onSuccess = (result) => {
      this.clearError();

      this._data = {
        hub: new HubDetail(result.hub),
        contextLayers: ContextLayerModel.FromArray(result.contextLayers),
        dataProximity: this._proximity,
        advancedPlus: result.advancedPlus,
      }
      appSetState.appValuation.detailValuation = this._data.hub.valuation;
      this.addNavigationHistory(appSetState, this._hubId, this._data.hub.hubName);
      onLoadComplete();
    };
    const onFail = (err) => this.handleLoadError(err, onLoadComplete);
    const valuationId = appSetState.appValuation.getDetailValuationId();
    this._valuationId = valuationId;
    const url = 'api/hub/details/load/?hubId=' + this._hubId + '&page=' + appSetState.view.current + '&panel=' + this.atlasPanel + '&proximity=' + this._actualProximity + '&valuationId=' + valuationId; //todo get proximity form page
    appSetState.api.get(url, onSuccess, onFail);
  }

  reLoader = (appSetState, oldproximity, newproximity, proximityLower, proximityUpper, hubId) => {
    this._proximity = newproximity;
    this._oldproximity = oldproximity;
    this._actualProximity = this._proximity * 1000;

    appSetState.view.details.showHub(hubId, null, undefined, oldproximity, newproximity, proximityLower, proximityUpper);
  }

  openSettings() {
    this.showSettings = !this.showSettings;
    this.forceUpdate();
  }

  getHeaderComponent(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close) {
    this._validateGetHeaderComponentParams(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close);

    const copyLinkClick = null;
    //const copyLinkClick = this.loaded
    //  ? () => appSetState.view.details.showHubLink(this._hubId)
    //  : null;

    const excelExport = () => appSetState.api.execOrFailIfTrialUser(this.appView, () => new FiscalHubExcelExport(this._data.hub, this._data.hub.valuation, appSetState.appValuation.baseValuation).exportAll());
    const settingsTitle = this.showSettings ? 'Hide Valuations' : 'Show Valuations';

    return (
      <HeaderComponent
        appView={this.appView}
        title={this.getTitle(() => this._data.hub.hubName)}
        backClick={backClick}
        forwardClick={forwardClick}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        close={close}
        copyLinkClick={copyLinkClick} 
      >
        <HeaderButtonComponent
          iconDef={appIcons.Valuations}
          iconSize={BUTTON_ICON_SIZE}
          iconSolid={false}
          title={settingsTitle}
          onClick={this.openSettings}
        />

        <HeaderButtonComponent iconDef={appIcons.FileExcel} iconSize={BUTTON_ICON_SIZE} title="Export to Excel" disabled={!this.loaded} onClick={excelExport} iconSolid={true} colour={'green'} /> 
      </HeaderComponent>
    );
  }

  getBodyComponent(appSetState, geoserverUrl) {
    this._validateGetBodyComponentParams(appSetState, geoserverUrl);

    if (this._error !== null) {
      return this._getErrorComponent();
    }
 
    if (this.loaded) {
      return (
        <HubDetailComponent
          hub={this._data.hub}
          proximity={this._data.dataProximity}
          contextLayers={this._data.contextLayers}
          appSetState={appSetState}
          geoserverUrl={geoserverUrl}
          currentProximity={this._data === null ? this._proximity : this._data.dataProximity}
          hubId={this._hubId}
          loaded={this.loaded}
          reLoader={this.reLoader}
          proximityLower={this._proximityLower}
          proximityUpper={this._proximityUpper}
          showSettings={this.showSettings}
          closeSettings={this.openSettings}
          advancedPlus={this._data.advancedPlus}
          openSettings={this.openSettings}
        />
      );
    }

    return this._getLoadingComponent();
  }
}

export { HubDetailModel };
