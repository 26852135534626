import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import './multi-select-component.scss';

import { appIcons, appIconSizes } from '../../../app/app-icons';

const MultiSelectComponent = (props) => {
  const getItemProp = (item, propName) => {
    if (item === undefined) {
      return undefined;
    }
    const result = item[propName];
    if (result === undefined) {
      console.error('*** MultiSelectComponent.getItemProp returned undefined for labelName "' + propName + '"');
    }
    return result;
  };
  const getLabel = (item) => getItemProp(item, props.labelName);
  const getValue = (item) => getItemProp(item, props.valueName);

  const handleChange = (e, items) => {
    items = items.map(obj => getValue(obj));
    props.onChange(items);
  };

  const handleSelectAll = () => {
    const items = props.values.map(obj => getValue(obj));
    props.onChange(items);
  };

  const handleClear = () => {
    const items = [];
    props.onChange(items);
  };

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        label={props.label + ' (' + props.values.length + ')'}
        variant="outlined"
        margin="dense"
        InputLabelProps={{ shrink: true }}
      />
    )
  };

  const buttons = (
    <div className="buttons-div">
      <span title="Select All" onClick={handleSelectAll}>
        {appIcons.getIcon(appIcons.CheckDouble, appIconSizes.xs, false)}
      </span>
      <span title="Clear" onClick={handleClear}>
        {appIcons.getIcon(appIcons.Close, appIconSizes.xs, false)}
      </span>
    </div>
  );

  return (
    <div className="multi-select-component">
      {buttons}
      <Autocomplete
        filterSelectedOptions
        disableClearable
        multiple={props.multiple}
        disabled={props.disabled}
        options={props.values}
        getOptionLabel={getLabel}
        ChipProps={{ size: "small", variant: "outlined" }}
        value={props.values.filter(obj => props.value.includes(getValue(obj)))}
        onChange={handleChange}
        renderInput={renderInput}
      />
    </div>
  );
}

MultiSelectComponent.propTypes = {
  valueName: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired
};

MultiSelectComponent.defaultProps = {
  valueName: 'key',
  labelName: 'value',
  multiple: true,
  disabled: false
};

export default MultiSelectComponent;
