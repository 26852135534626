import React from 'react';
import PropTypes from 'prop-types'
import L from 'leaflet';

import { LeafletHelper, ContextLayerModel, LatLngBounds, LatLng, zoomLevels } from '../../../shared/helpers/leaflet/leaflet-helper';

class MapComponent extends React.Component {
  constructor(props) {
    super(props);

    this._layer = null;
    this._leafletHelper = new LeafletHelper(this.props.geoserverUrl, this.props.id);
  }

  componentDidMount() {
    const { contextLayers } = this.props;

    if (this._leafletHelper instanceof LeafletHelper && contextLayers.length > 0) {
      if (!this._leafletHelper.initialized) {
        this._leafletHelper.initialize(contextLayers, { scrollWheelZoom: false });

        this._leafletHelper.controls.layers.visible = false;
        this._leafletHelper.controls.navbar.visible = false;
        this._leafletHelper.controls.coordinates.visible = false;
        this._leafletHelper.controls.search.visible = false;
        this._leafletHelper.controls.searchButtons.visible = false;
        this._leafletHelper.controls.searchResults.visible = false;

        this._layer = L.layerGroup();
        this._leafletHelper.leafletMap.addLayer(this._layer);
      }

      this._updateLayer();
      this._updatePosition();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.shapes !== prevProps.shapes) {
      this._updateLayer();
    }

    const thisBoundsStr = this.props.bounds instanceof L.LatLngBounds ? this.props.bounds.toBBoxString() : '';
    const prevBoundsStr = prevProps.bounds instanceof L.LatLngBounds ? prevProps.bounds.toBBoxString() : '';
    if (thisBoundsStr !== prevBoundsStr || this.props.center !== prevProps.center) {
      this._updatePosition();
    }
  }

  componentWillUnmount() {
    this._leafletHelper.destroy();
    delete this._leafletHelper;
  }

  _updateLayer() {
    this._layer.clearLayers();
    this.props.shapes.forEach(obj => this._layer.addLayer(this._leafletHelper.createLayer(obj)));
  }

  _updatePosition() {
    const { bounds, center, zoom } = this.props;

    if (bounds instanceof LatLngBounds) {
      this._leafletHelper.fitBounds(bounds, zoom);
    } else {
      this._leafletHelper.setView(center, zoom);
    }
  }

  render() {
    return <div style={{ width: '100%', height: '100%' }} id={this._leafletHelper.id} />;
  }
}

MapComponent.propTypes = {
  geoserverUrl: PropTypes.string.isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
  bounds: PropTypes.instanceOf(LatLngBounds),
  center: PropTypes.instanceOf(LatLng),
  zoom: zoomLevels.nullablePropType,
  shapes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};

MapComponent.defaultProps = {
  zoom: null,
  shapes: []
};

export default MapComponent;