import React from 'react';
import PropTypes from 'prop-types';

import { appViews } from '../../app/app-views';
import ErrorMessageComponent from '../error-message/error-message-component';

const NonSubscribedComponent = (props) => {
  let message = '';
  message = props.isSpecific ? '' : appViews.getDisplayName(props.appView) + ' are not currently included in your subscription.';
  if (typeof props.countryName === 'string' && props.countryName.length > 0 && props.countryName.charAt(0) !== '{') {
    message = props.countryName + ' ' + message;
  }
  return <ErrorMessageComponent message={message} />;
};

NonSubscribedComponent.propTypes = {
  appView: appViews.propType,
  countryName: PropTypes.string,
  isSpecific: PropTypes.bool.isRequired
};

NonSubscribedComponent.defaultProps = {
  isSpecific: false,
  appView: appViews.Home
}

export default NonSubscribedComponent;