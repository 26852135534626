import React from 'react';
import PropTypes from 'prop-types'

import AdvancedComponent from './body/advanced-component';
import StandardComponent from './body/standard-component';

import { appViews } from '../../app/app-views';
import { DiscoveryDetail } from '../../models/discovery-detail';
import { assignColours } from '../../shared/helpers/array-color-helper';
import { ContextLayerModel } from '../../shared/helpers/leaflet/leaflet-helper';

import { downloadFile } from '../../shared/helpers/download';
import { TrackingType, AtlasPanel } from '../../shared/helpers/enums';

const DiscoveryDetailComponent = (props) => {
  const { discovery, appSetState } = props;

  const onWellClick = (wellId) => {
    if (typeof wellId !== 'number') {
      throw new Error('Invalid "wellId" supplied to "DiscoveryDetailsComponent.onWellClick"');   
    }

    appSetState.view.details.showWell(wellId);
  }

  const onBlockClick = (blockId) => {
    if (typeof blockId !== 'number') {
      throw new Error('Invalid "blockId" supplied to "DiscoveryDetailsComponent.onBlockClick"');
    }

    appSetState.view.details.showBlock(blockId);
  }

  const onLicenceClick = (licenceId) => {
    if (typeof licenceId !== 'number') {
      throw new Error('Invalid "licenceId" supplied to "DiscoveryDetailsComponent.onLicenceClick"');
    }

    appSetState.view.details.showLicence(licenceId);
  }

  const onCorporateClick = (corporateId) => {
    if (typeof corporateId !== 'number') {
      throw new Error('Invalid "corporateId" supplied to "DiscoveryDetailsComponent.onCorporateClick"');
    }

    appSetState.view.details.showCorporate(corporateId);
  };

  const openReport = (id) => {
    const page = appSetState.view.current;
    const panel = appSetState.view.current === appViews.Home ? AtlasPanel.Reports : AtlasPanel.None;
    downloadFile(id, appSetState, true, 0, page, TrackingType.ReportDownload, panel);
  }

  assignColours(discovery.owners, 'color');
  const _props = {
    discovery: discovery,
    onBlockClick: onBlockClick,
    onWellClick: onWellClick,
    onLicenceClick: onLicenceClick,
    onCorporateClick: onCorporateClick,
    geoserverUrl: props.geoserverUrl,
    contextLayers: props.contextLayers
   
  };
  return discovery.fullView
    ? <AdvancedComponent {..._props} openReport={openReport} />
    : <StandardComponent {..._props} appView={appViews.Discovery} />;
};

DiscoveryDetailComponent.propTypes = {
  discovery: PropTypes.instanceOf(DiscoveryDetail).isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
  appSetState: PropTypes.instanceOf(Object).isRequired, 
  geoserverUrl: PropTypes.string.isRequired
};

export default DiscoveryDetailComponent;
