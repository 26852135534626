import moment from 'moment';

import { WellResource } from './well-resource';
import { showError } from '../shared/helpers/common';
import { MapShapeBorder } from '../shared/scss/app-colors';
import { dateTypes } from '../shared/helpers/value-handler';
import { BlockParticipant } from '../shared/components/models/gmatrix3-extension-block-participant';
import { PolygonsModel, PolygonModel } from '../shared/helpers/leaflet/models/polygons-model';

class FieldRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "FieldRow.ctor"');
    }
    if (typeof obj.id !== 'number') {
      throw new Error('Invalid "obj.id" param supplied to "FieldRow.ctor"');
    }
    if (typeof obj.name !== 'string') {
      throw new Error('Invalid "obj.name" param supplied to "FieldRow.ctor"');
    }
    if (typeof obj.status !== 'string') {
      throw new Error('Invalid "obj.status" param supplied to "FieldRow.ctor"');
    }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') {
      throw new Error('Invalid "obj.operatorId" param supplied to "FieldRow.ctor"');
    }
    if (typeof obj.operatorName !== 'string') {
      throw new Error('Invalid "obj.operatorName" param supplied to "FieldRow.ctor"');
    }
    if (typeof obj.hubId !== 'number') {
      throw new Error('Invalid "obj.hubId" param supplied to "FieldRow.ctor"');
    }
    if (typeof obj.hubName !== 'string') {
      throw new Error('Invalid "obj.hubName" param supplied to "FieldRow.ctor"');
    }
    if (obj.reserves !== null && typeof obj.reserves !== 'number') {
      throw new Error('Invalid "obj.reserves" param supplied to "FieldRow.ctor"');
    }
    if (obj.production !== null && typeof obj.production !== 'number') {
      throw new Error('Invalid "obj.production" param supplied to "FieldRow.ctor"');
    }

    this.id = obj.id;
    this.name = obj.name;
    this.status = obj.status;
    this.operatorId = obj.operatorId;
    this.operatorName = obj.operatorName;
    this.hubId = obj.hubId;
    this.hubName = obj.hubName;
    this.reserves = obj.reserves;
    this.production = obj.production;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "FieldRow.FromArray"');
    }

    const result = array.map(obj => new FieldRow(obj));
    return result;
  }
}

class DiscoveryRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "DiscoveryRow.ctor"');
    }
    if (typeof obj.id !== 'number') {
      throw new Error('Invalid "obj.id" param supplied to "DiscoveryRow.ctor"');
    }
    if (typeof obj.name !== 'string') {
      throw new Error('Invalid "obj.name" param supplied to "DiscoveryRow.ctor"');
    }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') {
      throw new Error('Invalid "obj.operatorId" param supplied to "DiscoveryRow.ctor"');
    }
    if (typeof obj.operatorName !== 'string') {
      throw new Error('Invalid "obj.operatorName" param supplied to "DiscoveryRow.ctor"');
    }
    if (typeof obj.year !== 'number') {
      throw new Error('Invalid "obj.year" param supplied to "DiscoveryRow.ctor"');
    }
    if (typeof obj.classification !== 'string') {
      throw new Error('Invalid "obj.classification" param supplied to "DiscoveryRow.ctor"');
    }
    if (obj.reserves !== null && typeof obj.reserves !== 'number') {
      throw new Error('Invalid "obj.reserves" param supplied to "DiscoveryRow.ctor"');
    }

    this.id = obj.id;
    this.name = obj.name;
    this.operatorId = obj.operatorId;
    this.operatorName = obj.operatorName;
    this.year = obj.year;
    this.classification = obj.classification;
    this.reserves = obj.reserves;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "DiscoveryRow.FromArray"');
    }

    const result = array.map(obj => new DiscoveryRow(obj));
    return result;
  }
}

class ProspectRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "ProspectRow.ctor"');
    }
    if (typeof obj.id !== 'number') {
      throw new Error('Invalid "obj.id" param supplied to "ProspectRow.ctor"');
    }
    if (typeof obj.identifier !== 'string') {
      throw new Error('Invalid "obj.identifier" param supplied to "ProspectRow.ctor"');
    }
    if (typeof obj.name !== 'string') {
      throw new Error('Invalid "obj.name" param supplied to "ProspectRow.ctor"');
    }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') {
      throw new Error('Invalid "obj.operatorId" param supplied to "ProspectRow.ctor"');
    }
    if (typeof obj.operatorName !== 'string') {
      throw new Error('Invalid "obj.operatorName" param supplied to "ProspectRow.ctor"');
    }
    if (obj.combinedMmboe !== null && typeof obj.combinedMmboe !== 'number') {
      throw new Error('Invalid "obj.combinedMmboe" param supplied to "ProspectRow.ctor"');
    }

    this.id = obj.id;
    this.identifier = obj.identifier;
    this.name = obj.name;
    this.operatorId = obj.operatorId;
    this.operatorName = obj.operatorName;
    this.combinedMmboe = obj.combinedMmboe;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "ProspectRow.FromArray"');
    }

    const result = array.map(obj => new ProspectRow(obj));
    return result;
  }
}

class CompletedAndActiveWellRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.wellId !== 'number') {
      throw new Error('Invalid "obj.wellId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.wellName !== 'string') {
      throw new Error('Invalid "obj.wellName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.typeId !== 'number') {
      throw new Error('Invalid "obj.typeId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.typeName !== 'string') {
      throw new Error('Invalid "obj.typeName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (!Array.isArray(obj.resources)) {
      throw new Error('Invalid "obj.resources" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') {
      throw new Error('Invalid "obj.operatorId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.operatorName !== 'string') {
      throw new Error('Invalid "obj.operatorName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.startDate !== null && typeof obj.startDate !== 'string') {
      throw new Error('Invalid "obj.startDate" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.endDate !== null && typeof obj.endDate !== 'string') {
      throw new Error('Invalid "obj.endDate" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.statusId !== 'number') {
      throw new Error('Invalid "obj.statusId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.statusName !== 'string') {
      throw new Error('Invalid "obj.statusName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.resultName !== 'string') {
      throw new Error('Invalid "obj.resultName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (typeof obj.reportId !== 'number') {
      throw new Error('Invalid "obj.reportId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.originalLicenceId !== null && typeof obj.originalLicenceId !== 'number') {
      throw new Error('Invalid "obj.originalLicenceId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.originalLicenceName !== null && typeof obj.originalLicenceName !== 'string') {
      throw new Error('Invalid "obj.originalLicenceName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.currentLicenceId !== null && typeof obj.currentLicenceId !== 'number') {
      throw new Error('Invalid "obj.currentLicenceId" param supplied to "CompletedAndActiveWellRow.ctor"');
    }
    if (obj.currentLicenceName !== null && typeof obj.currentLicenceName !== 'string') {
      throw new Error('Invalid "obj.currentLicenceName" param supplied to "CompletedAndActiveWellRow.ctor"');
    }

    let startDateMoment = null;
    if (obj.startDate !== null) {
      if (typeof obj.startDate !== 'string') { throw new Error('CompletedAndActiveWellRow', 'startDate'); }
      startDateMoment = moment(obj.startDate);
      if (!startDateMoment.isValid()) { throw new Error('CompletedAndActiveWellRow', 'startDateMoment'); }
    }

    let endDateMoment = null;
    if (obj.endDate !== null) {
      if (typeof obj.endDate !== 'string') { throw new Error('CompletedAndActiveWellRow', 'endDate'); }
      endDateMoment = moment(obj.endDate);
      if (!endDateMoment.isValid()) { throw new Error('CompletedAndActiveWellRow', 'endDateMoment'); }
    }

    this.wellId = obj.wellId;
    this.wellName = obj.wellName;
    this.typeId = obj.typeId;
    this.typeName = obj.typeName;
    this.resources = WellResource.FromArray(obj.resources);
    this.operatorId = obj.operatorId;
    this.operatorName = obj.operatorName;
    this.startDate = startDateMoment === null ? null : startDateMoment.format(dateTypes.GetFormatString(dateTypes.AtlasDisplay));
    this.endDate = endDateMoment === null ? null : endDateMoment.format(dateTypes.GetFormatString(dateTypes.AtlasDisplay));
    this.statusId = obj.statusId;
    this.statusName = obj.statusName;
    this.resultName = obj.resultName;
    this.reportId = obj.reportId;
    this.originalLicenceId = obj.originalLicenceId;
    this.originalLicenceName = obj.originalLicenceName;
    this.currentLicenceId = obj.currentLicenceId;
    this.currentLicenceName = obj.currentLicenceName;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "CompletedAndActiveWellRow.FromArray"');
    }

    const result = array.map(obj => new CompletedAndActiveWellRow(obj));
    return result;
  }
}

class PlannedWellRow {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.wellId !== 'number') {
      throw new Error('Invalid "obj.wellId" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.wellName !== 'string') {
      throw new Error('Invalid "obj.wellName" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.typeId !== 'number') {
      throw new Error('Invalid "obj.typeId" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.typeName !== 'string') {
      throw new Error('Invalid "obj.typeName" param supplied to "PlannedWellRow.ctor"');
    }
    if (!Array.isArray(obj.resources)) {
      throw new Error('Invalid "obj.resources" param supplied to "PlannedWellRow.ctor"');
    }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') {
      throw new Error('Invalid "obj.operatorId" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.operatorName !== 'string') {
      throw new Error('Invalid "obj.operatorName" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.estimatedTiming !== 'string') {
      throw new Error('Invalid "obj.estimatedTiming" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.blockId !== 'number') {
      throw new Error('Invalid "obj.blockId" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.blockName !== 'string') {
      throw new Error('Invalid "obj.blockName" param supplied to "PlannedWellRow.ctor"');
    }
    if (typeof obj.statusName !== 'string') {
      throw new Error('Invalid "obj.statusName" param supplied to "PlannedWellRow.ctor"');
    }

    this.wellId = obj.wellId;
    this.wellName = obj.wellName;
    this.typeId = obj.typeId;
    this.typeName = obj.typeName;
    this.resources = WellResource.FromArray(obj.resources);
    this.operatorId = obj.operatorId;
    this.operatorName = obj.operatorName;
    this.estimatedTiming = obj.estimatedTiming;
    this.blockId = obj.blockId;
    this.blockName = obj.blockName;
    this.statusName = obj.statusName;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "PlannedWellRow.FromArray"');
    }

    const result = array.map(obj => new PlannedWellRow(obj));
    return result;
  }
}

class BlockDetail {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid obj param supplied to BlockDetail.ctor'); }
    if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to BlockDetail.ctor'); }
    if (typeof obj.name !== 'string') { throw new Error('Invalid obj.name param supplied to BlockDetail.ctor'); }
    if (typeof obj.country !== 'string') { throw new Error('Invalid obj.country param supplied to BlockDetail.ctor'); }
    if (obj.area !== null && typeof obj.area !== 'string') { throw new Error('Invalid obj.area param supplied to BlockDetail.ctor'); }
    if (obj.operatorId !== null && typeof obj.operatorId !== 'number') { throw new Error('Invalid obj.operatorId param supplied to BlockDetail.ctor'); }
    if (typeof obj.operatorName !== 'string') { throw new Error('Invalid obj.operatorName param supplied to BlockDetail.ctor'); }
    if (obj.blockStatus !== null && typeof obj.blockStatus !== 'string') { throw new Error('Invalid obj.classification param supplied to BlockDetail.ctor'); }
    if (obj.licence !== null && typeof obj.licence !== 'string') { throw new Error('Invalid obj.licence param supplied to BlockDetail.ctor'); }
    if (obj.licenceId !== null && typeof obj.licenceId !== 'number') { throw new Error('Invalid obj.licenceId param supplied to BlockDetail.ctor'); }
    if (obj.licenceRound !== null && typeof obj.licenceRound !== 'string') { throw new Error('Invalid obj.licenceRound param supplied to BlockDetail.ctor'); }
    if (!Array.isArray(obj.shapes)) { throw new Error('Invalid obj.shapes param supplied to BlockDetail.ctor'); }
    if (obj.areaSQKM !== null && typeof obj.areaSQKM !== 'number') { throw new Error('Invalid obj.areaSQKM param supplied to BlockDetail.ctor'); }
    if (typeof obj.currentYear !== 'number') { throw new Error('Invalid obj.currentYear param supplied to BlockDetail.ctor'); }
    if (!Array.isArray(obj.fields)) { throw new Error('Invalid obj.fields param supplied to BlockDetail.ctor'); }
    if (!Array.isArray(obj.discoveries)) { throw new Error('Invalid obj.discoveries param supplied to BlockDetail.ctor'); }
    if (!Array.isArray(obj.prospects)) { throw new Error('Invalid obj.prospects param supplied to BlockDetail.ctor'); }
    if (!Array.isArray(obj.completedAndActiveWells)) { throw new Error('Invalid obj.completedAndActiveWells param supplied to BlockDetail.ctor'); }
    if (!Array.isArray(obj.plannedWells)) { throw new Error('Invalid obj.plannedWells param supplied to BlockDetail.ctor'); }

    let licenceDateMoment = null;
    if (obj.licenceDate !== null) {
      if (typeof obj.licenceDate !== 'string') { showError('BlockDetail', 'licenceDate'); }
      licenceDateMoment = moment(obj.licenceDate);
      if (!licenceDateMoment.isValid()) { showError('BlockDetail', 'licenceDateMoment'); }
    }
    if (obj.licenceType !== null && typeof obj.licenceType !== 'string') { showError('BlockDetail', 'licenceType'); }
    if (obj.subArea !== null && typeof obj.subArea !== 'string') { showError('BlockDetail', 'subArea'); }
    if (!Array.isArray(obj.owners)) { showError('BlockDetail', 'owners'); }

    this.id = obj.id;
    this.name = obj.name;
    this.country = obj.country;
    this.area = obj.area !== null ? obj.area : 'N/A';
    this.operatorId = obj.operatorId;
    this.operatorName = obj.operatorName.length > 0 ? obj.operator : 'N/A';
    this.blockStatus = obj.blockStatus !== null ? obj.blockStatus : 'N/A';
    this.licence = obj.licence !== null ? obj.licence : 'N/A';
    this.licenceId = obj.licenceId;
    this.licenceRound = obj.licenceRound !== null ? obj.licenceRound : 'N/A';
    this.licenceDate = licenceDateMoment === null ? null : licenceDateMoment.format('DD/MM/YYYY');
    this.licenceType = obj.licenceType !== null ? obj.licenceType : 'N/A';
    this.subArea = obj.subArea !== null ? obj.subArea : 'N/A';
    this.owners = obj.owners.map(obj => new BlockParticipant(obj));
    this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });
    this.areaSQKM = obj.areaSQKM;
    this.currentYear = obj.currentYear;
    this.fields = FieldRow.FromArray(obj.fields);
    this.discoveries = DiscoveryRow.FromArray(obj.discoveries);
    this.prospects = ProspectRow.FromArray(obj.prospects);
    this.completedAndActiveWells = CompletedAndActiveWellRow.FromArray(obj.completedAndActiveWells);
    this.plannedWells = PlannedWellRow.FromArray(obj.plannedWells);
  }
}

export { BlockDetail, FieldRow, DiscoveryRow, ProspectRow, CompletedAndActiveWellRow, PlannedWellRow };
