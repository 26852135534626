class PointModel {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid \'obj\' param supplied to \'PointModel.ctor\''); }
    if (typeof obj.x !== 'number') { throw new Error('Invalid \'obj.x\' param supplied to \'PointModel.ctor\''); }
    if (typeof obj.y !== 'number') { throw new Error('Invalid \'obj.y\' param supplied to \'PointModel.ctor\''); }

    this.x = obj.x;
    this.y = obj.y;
  }

  destroy() {
    delete this.x;
    delete this.y;
    delete this;
  }

  get latLng() { return { lat: this.y, lng: this.x }; }
}

export { PointModel };