import { appViews } from "../../app/app-views";
import { AppSetState } from "../../app/app-set-state";

import { valueTypes } from '../../shared/helpers/value-handler';
import { BaseExcelExportColumn, BaseExcelExport } from "../../shared/helpers/base-excel-export";

class GridExcelExport {
  export(appSetState, dataRows, advancedUser, mapBaseExcelExport = null, mapWorkbook = null, sheetname = null) {
    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "GridExcelExport.export"');
    }
    if (typeof advancedUser !== 'boolean') {
      throw new Error('Invalid "advancedUser" param supplied to "GridExcelExport.export"');
    }

    const baseExcelExport = mapBaseExcelExport !== null ? mapBaseExcelExport : new BaseExcelExport();
    const workbook = mapWorkbook !== null ? mapWorkbook : baseExcelExport.createWorkbook();

    const appView = sheetname !== null ? sheetname : appViews.getDisplayName(appViews.Discovery);
    const colDefs = [];
    colDefs.push(new BaseExcelExportColumn("Discovery Name", 'name'));
    colDefs.push(new BaseExcelExportColumn("Country", "country"));
    colDefs.push(new BaseExcelExportColumn("Area", "area"));
    colDefs.push(new BaseExcelExportColumn("Operator", "operatorName"));
    colDefs.push(new BaseExcelExportColumn("Licence", 'licence'));
    if (advancedUser) {
      colDefs.push(new BaseExcelExportColumn("Block", "blockName"));
      colDefs.push(new BaseExcelExportColumn("Discovery Well", "discoveryWell"));
    }
    colDefs.push(new BaseExcelExportColumn("Discovery Year", "discoveryYear"));
    colDefs.push(new BaseExcelExportColumn("Discovery Classification", "classification"));
    if (advancedUser) {
      colDefs.push(new BaseExcelExportColumn("Discovery Classification Rationale", "classificationRationale"));
      colDefs.push(new BaseExcelExportColumn("Liquid Resources (" + valueTypes.UnitString(valueTypes.mmb) + ")", "initialLiquidMmb", '0.00'));
      colDefs.push(new BaseExcelExportColumn("Gas Resources (" + valueTypes.UnitString(valueTypes.bcf) + ")", "initialGasBcf", '0.00'));
      colDefs.push(new BaseExcelExportColumn("Total Resources (" + valueTypes.UnitString(valueTypes.mmmboe) + ")", 'reserves', '0.00'));
    }
    colDefs.push(new BaseExcelExportColumn("Reservoir Age", "reservoirAgeEra"));
    if (advancedUser) {
      colDefs.push(new BaseExcelExportColumn('Water Depth (ft)', 'waterDepthFt', '0'));
      colDefs.push(new BaseExcelExportColumn('Reservoir Depth (ft)', 'reservoirDepthFt', '0'));
      colDefs.push(new BaseExcelExportColumn('Fluid Reservoir Condition', 'reservoirCondition'));
      colDefs.push(new BaseExcelExportColumn('Trap Type', 'trapType'));
      colDefs.push(new BaseExcelExportColumn('Pressure Status', 'pressureStatus'));
      colDefs.push(new BaseExcelExportColumn('Temperature Status', 'temperatureStatus'));
    }

    baseExcelExport.writeSheet(workbook, appView, dataRows, colDefs);
    if (mapBaseExcelExport === null) baseExcelExport.writeWorkbook(workbook, baseExcelExport.getAtlas5WorkbookPrefix() + appView + baseExcelExport.getAtlas5WorkbookSuffix());
  }
}

export { GridExcelExport };
