import L from 'leaflet';

L.Control.CoordinatesControl = L.Control.extend({
  initialize: function (options) {
    this.options = options;
  },
  onAdd: function (map) {
    this._container = L.DomUtil.create('div', 'leaflet-bar');
    this._container.style.fontSize = '11px';

    this._label = L.DomUtil.create('span', '', this._container);
    this._label.style.color = '#333';
    this._label.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
    this._label.style.padding = '2px 5px 2px 5px';
    this._mouseMove();

    L.DomEvent.on(map, 'mousemove', this._mouseMove, this);

    return this._container;
  },
  onRemove: function (map) {
    L.DomEvent.off(map, 'mousemove', this._mouseMove, this);
  },
  _mouseMove: function (e) {
    let lat = 0;
    let lng = 0;
    if (e !== undefined && e.latlng instanceof L.LatLng) {
      lat = e.latlng.lat;
      lng = e.latlng.lng;
    }
    const dp = 3;
    this._label.innerHTML = 'Latitude: ' + lat.toFixed(dp) + ' Longitude: ' + lng.toFixed(dp);
  }
});

L.control.coordinatesControl = (opts) => {
  return new L.Control.CoordinatesControl(opts);
};

const addCoordinatesControl = (leafletMap, position) => {
  const result = L.control.coordinatesControl({ position: position });
  leafletMap.addControl(result);
  return result;
};

export { addCoordinatesControl };