import { BaseFilter } from "../base/base-filter";

class WellFilter extends BaseFilter {
  constructor() {
    super();

    this._wellIds = undefined;
    this._countryIds = undefined;
    this._areaIds = undefined;
    this._licenceIds = undefined;
    this._quadrantIds = undefined;
    this._blockIds = undefined;
    this._operatorIds = undefined;
    this._participantIds = undefined;
    this._typeIds = undefined;
    this._subTypeIds = undefined;
    this._statusIds = undefined;
    this._resultIds = undefined;
    this._primaryObjectiveIds = undefined;
    this._spudStartYear = undefined;
    this._spudEndYear = undefined;
    this._completionStartYear = undefined;
    this._completionEndYear = undefined;
    this._rigIds = undefined;
    this._resourceWellIds = undefined;
    this.clear();
  }

  get isSet() {
    const result = super.isSet === true ||
      this.wellIdsIsSet === true ||
      this.countryIdsIsSet === true ||
      this.areaIdsIsSet === true ||
      this.licenceIdsIsSet === true ||
      this.quadrantIdsIsSet === true ||
      this.blockIdsIsSet === true ||
      this.operatorIdsIsSet === true ||
      this.participantIdsIsSet === true ||
      this.typeIdsIsSet === true ||
      this.subTypeIdsIsSet === true ||
      this.statusIdsIsSet === true ||
      this.resultIdsIsSet === true ||
      this.primaryObjectiveIdsIsSet === true ||
      this.spudStartYearIsSet === true ||
      this.spudEndYearIsSet === true ||
      this.completionStartYearIsSet === true ||
      this.completionEndYearIsSet === true ||
      this.rigIdsIsSet === true ||
      this.resourceWellIdsIsSet === true;
    return result;
  }

  get wellIds() { return this._wellIds; }
  set wellIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.wellIds.set"');
    }

    if (this._wellIds !== value) {
      this._wellIds = value;
    }
  }
  get wellIdsIsSet() { return this._wellIds.length > 0; }

  get countryIds() { return this._countryIds; }
  set countryIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.countryIds.set"');
    }

    if (this._countryIds !== value) {
      this._countryIds = value;
    }
  }
  get countryIdsIsSet() { return this._countryIds.length > 0; }

  get areaIds() { return this._areaIds; }
  set areaIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.areaIds.set"');
    }

    if (this._areaIds !== value) {
      this._areaIds = value;
    }
  }
  get areaIdsIsSet() { return this._areaIds.length > 0; }

  get licenceIds() { return this._licenceIds; }
  set licenceIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.licenceIds.set"');
    }

    if (this._licenceIds !== value) {
      this._licenceIds = value;
    }
  }
  get licenceIdsIsSet() { return this._licenceIds.length > 0; }

  get quadrantIds() { return this._quadrantIds; }
  set quadrantIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'string').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.quadrantIds.set"');
    }

    if (this._quadrantIds !== value) {
      this._quadrantIds = value;
    }
  }
  get quadrantIdsIsSet() { return this._quadrantIds.length > 0; }

  get blockIds() { return this._blockIds; }
  set blockIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.blockIds.set"');
    }

    if (this._blockIds !== value) {
      this._blockIds = value;
    }
  }
  get blockIdsIsSet() { return this._blockIds.length > 0; }

  get operatorIds() { return this._operatorIds; }
  set operatorIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.operatorIds.set"');
    }

    if (this._operatorIds !== value) {
      this._operatorIds = value;
    }
  }
  get operatorIdsIsSet() { return this._operatorIds.length > 0; }

  get participantIds() { return this._participantIds; }
  set participantIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.participantIds.set"');
    }

    if (this._participantIds !== value) {
      this._participantIds = value;
    }
  }
  get participantIdsIsSet() { return this._participantIds.length > 0; }

  get typeIds() { return this._typeIds; }
  set typeIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.typeIds.set"');
    }

    if (this._typeIds !== value) {
      this._typeIds = value;
    }
  }
  get typeIdsIsSet() { return this._typeIds.length > 0; }

  get subTypeIds() { return this._subTypeIds; }
  set subTypeIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.subTypeIds.set"');
    }

    if (this._subTypeIds !== value) {
      this._subTypeIds = value;
    }
  }
  get subTypeIdsIsSet() { return this._subTypeIds.length > 0; }

  get statusIds() { return this._statusIds; }
  set statusIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.statusIds.set"');
    }

    if (this._statusIds !== value) {
      this._statusIds = value;
    }
  }
  get statusIdsIsSet() { return this._statusIds.length > 0; }

  get resultIds() { return this._resultIds; }
  set resultIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.resultIds.set"');
    }

    if (this._resultIds !== value) {
      this._resultIds = value;
    }
  }
  get resultIdsIsSet() { return this._resultIds.length > 0; }

  get primaryObjectiveIds() { return this._primaryObjectiveIds; }
  set primaryObjectiveIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.primaryObjectiveIds.set"');
    }

    if (this._primaryObjectiveIds !== value) {
      this._primaryObjectiveIds = value;
    }
  }
  get primaryObjectiveIdsIsSet() { return this._primaryObjectiveIds.length > 0; }

  get spudStartYear() { return this._spudStartYear; }
  set spudStartYear(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "WellFilter.spudStartYear.set"');
    }

    if (this._spudStartYear !== value) {
      this._spudStartYear = value;
    }
  }
  get spudStartYearIsSet() { return this._spudStartYear !== null; }

  get spudEndYear() { return this._spudEndYear; }
  set spudEndYear(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "WellFilter.spudEndYear.set"');
    }

    if (this._spudEndYear !== value) {
      this._spudEndYear = value;
    }
  }
  get spudEndYearIsSet() { return this._spudEndYear !== null; }

  get completionStartYear() { return this._completionStartYear; }
  set completionStartYear(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "WellFilter.completionStartYear.set"');
    }

    if (this._completionStartYear !== value) {
      this._completionStartYear = value;
    }
  }
  get completionStartYearIsSet() { return this._completionStartYear !== null; }

  get completionEndYear() { return this._completionEndYear; }
  set completionEndYear(value) {
    if (value !== null && typeof value !== 'number') {
      throw new Error('Invalid "value" param supplied to "WellFilter.completionEndYear.set"');
    }

    if (this._completionEndYear !== value) {
      this._completionEndYear = value;
    }
  }
  get completionEndYearIsSet() { return this._completionEndYear !== null; }

  get rigIds() { return this._rigIds; }
  set rigIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid "value" param supplied to "WellFilter.rigIds.set"');
    }

    if (this._rigIds !== value) {
      this._rigIds = value;
    }
  }
  get rigIdsIsSet() { return this._rigIds.length > 0; }

  get resourceWellIds() { return this._resourceWellIds; }
  set resourceWellIds(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'string').length > 0) {
      throw new Error('Invalid "name" param supplied to "WellFilter.resourceWellIds.set"');
    }

    if (this._resourceWellIds !== value) {
      this._resourceWellIds = value;
    }
  }
  get resourceWellIdsIsSet() { return this._resourceWellIds.length > 0; }

  clear() {
    super.clear();

    this._wellIds = [];
    this._countryIds = [];
    this._areaIds = [];
    this._licenceIds = [];
    this._quadrantIds = [];
    this._blockIds = [];
    this._operatorIds = [];
    this._participantIds = [];
    this._typeIds = [];
    this._subTypeIds = [];
    this._statusIds = [];
    this._resultIds = [];
    this._primaryObjectiveIds = [];
    this._spudStartYear = null;
    this._spudEndYear = null;
    this._completionStartYear = null;
    this._completionEndYear = null;
    this._rigIds = [];
    this._resourceWellIds = [];
  }

  clone() {
    const result = new WellFilter();
    super.clone(result);
    result._wellIds = [...this._wellIds];
    result._countryIds = [...this._countryIds];
    result._areaIds = [...this._areaIds];
    result._licenceIds = [...this._licenceIds];
    result._quadrantIds = [...this._quadrantIds];
    result._blockIds = [...this._blockIds];
    result._operatorIds = [...this._operatorIds];
    result._participantIds = [...this._participantIds];
    result._typeIds = [...this._typeIds];
    result._subTypeIds = [...this._subTypeIds];
    result._statusIds = [...this._statusIds];
    result._resultIds = [...this._resultIds];
    result._primaryObjectiveIds = [...this._primaryObjectiveIds];
    result._spudStartYear = this._spudStartYear;
    result._spudEndYear = this._spudEndYear;
    result._completionStartYear = this._completionStartYear;
    result._completionEndYear = this._completionEndYear;
    result._rigIds = [...this._rigIds];
    result._resourceWellIds = [...this._resourceWellIds];
    return result;
  }

  clearInvalidValues() {
    if (this.spudStartYearIsSet && this.spudEndYearIsSet && this._spudStartYear > this._spudEndYear) {
      this.spudStartYear = null;
      this.spudEndYear = null;
    }

    if (this.completionStartYearIsSet && this.completionEndYearIsSet && this._completionStartYear > this._completionEndYear) {
      this.completionStartYear = null;
      this.completionEndYear = null;
    }
  }

  toApiParam() {
    const result = super.toApiParam();
    result.wellIds = this._wellIds;
    result.countryIds = this._countryIds;
    result.areaIds = this._areaIds;
    result.licenceIds = this._licenceIds;
    result.quadrantIds = this._quadrantIds;
    result.blockIds = this._blockIds;
    result.operatorIds = this._operatorIds;
    result.participantIds = this._participantIds;
    result.typeIds = this._typeIds;
    result.subTypeIds = this._subTypeIds;
    result.statusIds = this._statusIds;
    result.resultIds = this._resultIds;
    result.primaryObjectiveIds = this._primaryObjectiveIds;
    result.spudStartYear = this._spudStartYear;
    result.spudEndYear = this._spudEndYear;
    result.completionStartYear = this._completionStartYear;
    result.completionEndYear = this._completionEndYear;
    result.rigIds = this._rigIds;
    result.resourceWellIds = this._resourceWellIds;
    return result;
  }
}

export { WellFilter };