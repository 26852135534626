import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ag-grid-component.scss';
import { AgGridExcelExport } from './ag-grid-excel-export';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';

//import cloneDeep from 'lodash/cloneDeep';
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey("CompanyName=Westwood Global Energy,LicensedGroup=Westwood_Global_Energy_2020,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-017192,ExpiryDate=3_August_2022_[v2]_MTY1OTQ4MTIwMDAwMA==2afc8148454c4e6a549fe5173e03bb99");

const COLUMN_EXTRA_EXTRA_SMALL = 30;
const COLUMN_EXTRA_SMALL = 45;
const COLUMN_EXTRA_SMALL_SMALL = 92;
const COLUMN_SMALL = 105;
const COLUMN_MEDIUM = 122;
const COLUMN_MEDIUM_MEDIUM = 135;
const COLUMN_LARGE = 165;
const COLUMN_LARGE_LARGE = 200;
const COLUMN_EXTRA_LARGE = 265;
const COLUMN_WIDE = 390;
const COLUMN_EXTRA_EXTRA_WIDE = 450;
const COLUMN_EXTRA_WIDE = 850;
const COLUMN_FULL_WIDTH = '100%';

const HEADER_ROW_SINGLE = 32;
const HEADER_ROW_DOUBLE = 50;
const HEADER_ROW_TREBLE = 65; //THIS IS THE DEFAULT IF NONE SPECIFIED
const HEADER_ROW_QUAD = 75

const NUMERIC_SORT = ['desc', 'asc'];

class agGridComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { preventScrollToTop: true, defaultColumnDefs: { sortingOrder: ['asc', 'desc'], filter: false, suppressMenu: true, resizable: true, flex: 1, minWidth: COLUMN_SMALL } };
    this.grid = null;
    this.gridApi = null;
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  render() {
    let addHeader = this.props.showHeader ? 'add-header' : this.props.showHeaderBasic ? 'add-header-basic' : '';

    const gridOptions = {
      pinnedTopRowData: this.props.pinnedTopRows,
      pinnedBottomRowData: this.props.pinnedBottomRows
    };

    if (this.props.sortCaseSensitive === false) {
      gridOptions.accentedSort = true;
    }

    if (this.gridApi !== null) {
      //this.gridApi.setPinnedTopRowData(this.props.pinnedTopRows);
      //this.gridApi.setPinnedBottomRowData(this.props.pinnedBottomRows);
      //NB THIS CAUSES THE CONSOLE ERROR - BAD SET STATE IN AG GRID - CAN FIND NO INSTANCE THESE 2 VALUES BEING SET IN THE SOLUTION??? MIGHT BE OLD CODE!
    }

    return (
      <div className={'aggrid-component ag-theme-balham ' + addHeader} >
        <AgGridReact
          enableRangeSelection={false}
          enableCellSelect={false}
          columnDefs={this.props.columnDefs}
          defaultColDef={this.state.defaultColumnDefs}
          rowData={this.props.gridData}
          headerHeight={this.props.headerRowHeight}
          getRowHeight={this.props.getRowHeight}
          gridOptions={gridOptions}
          onGridReady={params => this.gridApi = params.api}
          groupHeaderHeight={this.props.groupHeaderRowHeight}
        />
      </div>
    );
  }
};

agGridComponent.propTypes = {
  columnDefs: PropTypes.arrayOf(PropTypes.shape({
    headerName: PropTypes.string.isRequired,
    field: PropTypes.string
  })).isRequired,
  headerRowHeight: PropTypes.number.isRequired,
  showHeader: PropTypes.bool.isRequired,
  showHeaderBasic: PropTypes.bool.isRequired,
  autoReSizeGrid: PropTypes.bool.isRequired,
  sortCaseSensitive: PropTypes.bool.isRequired,
  pinnedTopRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  pinnedBottomRows: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  groupHeaderRowHeight: PropTypes.number.isRequired,
};

agGridComponent.defaultProps = {
  headerRowHeight: HEADER_ROW_QUAD,
  groupHeaderRowHeight: HEADER_ROW_DOUBLE,
  showHeader: false,
  showHeaderBasic: false,
  autoReSizeGrid: false,
  sortCaseSensitive: false,
  pinnedTopRows: [],
  pinnedBottomRows: []
};

export default agGridComponent;
export {
  AgGridExcelExport,
  COLUMN_EXTRA_EXTRA_SMALL,
  COLUMN_EXTRA_SMALL,
  COLUMN_EXTRA_SMALL_SMALL,
  COLUMN_SMALL,
  COLUMN_MEDIUM,
  COLUMN_MEDIUM_MEDIUM,
  COLUMN_LARGE,
  COLUMN_LARGE_LARGE,
  COLUMN_EXTRA_LARGE,
  COLUMN_WIDE,
  COLUMN_EXTRA_EXTRA_WIDE,
  COLUMN_EXTRA_WIDE,
  COLUMN_FULL_WIDTH,
  HEADER_ROW_SINGLE,
  HEADER_ROW_DOUBLE,
  HEADER_ROW_TREBLE,
  HEADER_ROW_QUAD,
  NUMERIC_SORT
};
