import React from 'react';
import PropTypes from 'prop-types';

import { ViewModel } from './main-classes';
import { HoverDropdownComponent, DropdownToggle, DropdownMenu, DropdownItem } from '../hover-dropdown/hover-dropdown-component';

const SidebarDropdownComponent = (props) => {
  const [hovered, setHovered] = React.useState(false);

  const { viewModel, selected, disabled } = props;
  const viewModels = props.viewModels.filter(obj => obj.groupId === viewModel.id);
  const btnClassName = (selected === true ? ' selected' : ' unselected') + (disabled === true ? ' disabled' : ' enabled');
  const onMouseOver = () => disabled === true ? undefined : () => setHovered(true);
  const onMouseLeave = () => disabled === true ? undefined : () => setHovered(false);

  const renderDropdownItem = (viewModel) => {
    const onClick = selected === true || disabled === true ? undefined : () => props.onButtonClick(viewModel.id);
    const className = 'sb-dropdown-item' + (selected === true ? ' selected' : ' unselected') + (disabled === true ? ' disabled' : ' enabled');

    return (
      <DropdownItem key={'sb-dropdown-item-' + viewModel.id} className={className} title={viewModel.label} onClick={onClick}>
        <span className="sb-dropdown-item-text">{viewModel.label}</span>
        <span className="sb-dropdown-item-icon">{selected ? viewModel.selectedSmallIcon : viewModel.smallIcon}</span>
      </DropdownItem>
    );
  };

  return (
    <HoverDropdownComponent direction="right" title={viewModel.label} className="sb-dropdown" onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <DropdownToggle caret className={btnClassName}>
        <div className="sb-icon">{selected || hovered ? viewModel.selectedLargeIcon : viewModel.largeIcon}</div>
        <div className="sb-text">{viewModel.label}</div>
      </DropdownToggle>
      <DropdownMenu>
        {viewModels.map(vw => renderDropdownItem(vw))}
      </DropdownMenu>
    </HoverDropdownComponent>
  );
};

SidebarDropdownComponent.propTypes = {
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  viewModel: PropTypes.instanceOf(ViewModel).isRequired,
  viewModels: PropTypes.arrayOf(PropTypes.instanceOf(ViewModel).isRequired).isRequired
};

export default SidebarDropdownComponent;