import React from 'react';
import PropTypes from 'prop-types'

import AdvancedHubComponent from './body/advanced-hub-component';

import { assignColours } from '../../shared/helpers/array-color-helper';
import { ContextLayerModel } from '../../shared/helpers/leaflet/leaflet-helper';
import { HubDetail } from '../../models/hub-detail';
import { TrackingType } from '../../shared/helpers/enums';
import { downloadFile } from '../../shared/helpers/download';

const HubDetailComponent = (props) => {
  const { hub, appSetState, proximity, proximityLower, proximityUpper } = props;

  const onFieldClick = (resourceId) => {
    if (typeof resourceId !== 'number') {
      throw new Error('Invalid "resourceId" supplied to "HubDetailsComponent.onFieldClick"');
    }

    appSetState.view.details.showField(resourceId);
  }

  const onCorporateClick = (corporateId) => {
    if (typeof corporateId !== 'number') {
      throw new Error('Invalid "corporateId" supplied to "HubDetailsComponent.onCorporateClick"');
    }

    appSetState.view.details.showCorporate(corporateId);
  }

  const onDiscoveryClick = (discoveryId) => {
    if (typeof discoveryId !== 'number') {
      throw new Error('Invalid "discoveryId" supplied to "HubDetailsComponent.onDiscoveryClick"');
    }

    appSetState.view.details.showDiscovery(discoveryId);
  }

  const onProspectClick = (prospectId) => {
    if (typeof prospectId !== 'number') {
      throw new Error('Invalid "prospectId" supplied to "HubDetailsComponent.onProspectClick"');
    }

    appSetState.view.details.showProspect(prospectId);
  }

  const onLicenceClick = (licenceId) => {
    if (typeof licenceId !== 'number') {
      throw new Error('Invalid "licenceId" supplied to "HubDetailsComponent.onLicenceClick"');
    }

    appSetState.view.details.showLicence(licenceId);
  }

  const onBlockClick = (blockId) => {
    if (typeof blockId !== 'number') {
      throw new Error('Invalid "blockId" supplied to "HubDetailsComponent.onBlockClick"');
    }

    appSetState.view.details.showBlock(blockId);
  }

  const onWellClick = (wellId) => {
    if (typeof wellId !== 'number') {
      throw new Error('Invalid "corporateId" supplied to "HubDetailsComponent.onWellClick"');
    }

    appSetState.view.details.showWell(wellId);
  }

  const onReportClick = (reportId) => {
    if (typeof reportId !== 'number') {
      throw new Error('Invalid "reportId" param supplied to "LicenceDetailsComponent.onReportClick"');
    }

    downloadFile(reportId, appSetState, true, 0, appSetState.view.current, TrackingType.ReportDownload, props.atlasPanel);
  }

  assignColours(hub.events, 'color');

  return (
    <AdvancedHubComponent
      hub={hub}
      proximity={proximity}
      onFieldClick={onFieldClick}
      onCorporateClick={onCorporateClick}
      onDiscoveryClick={onDiscoveryClick}
      onProspectClick={onProspectClick}
      onLicenceClick={onLicenceClick}
      onBlockClick={onBlockClick}
      onWellClick={onWellClick}
      onReportClick={onReportClick}
      geoserverUrl={props.geoserverUrl}
      contextLayers={props.contextLayers}
      appSetState={props.appSetState}
      currentProximity={props.currentProximity}
      proximityLower={proximityLower}
      proximityUpper={proximityUpper}
      hubId={props.hubId}
      loaded={props.loaded}
      reLoader={props.reLoader}
      showSettings={props.showSettings}
      closeSettings={props.closeSettings}
      advancedPlus={props.advancedPlus}
      openSettings={props.openSettings}
    />
  );
};

HubDetailComponent.propTypes = {
  hub: PropTypes.instanceOf(HubDetail).isRequired,
  proximity: PropTypes.number.isRequired,
  proximityLower: PropTypes.number.isRequired,
  proximityUpper: PropTypes.number.isRequired,
  contextLayers: PropTypes.arrayOf(PropTypes.instanceOf(ContextLayerModel).isRequired).isRequired,
  appSetState: PropTypes.instanceOf(Object).isRequired, 
  geoserverUrl: PropTypes.string.isRequired,
  closeSettings: PropTypes.func.isRequired
};

export default HubDetailComponent;
