import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'reactstrap';

import './toolbar-component.scss';
import { appendClassNames } from '../../shared/helpers/common';
import { appIcons, appIconSizes } from '../../app/app-icons';
import { appViews } from '../../app/app-views';

const createButton = (appIcon, label, active, onClick, key = undefined, disabled = false, styleModifier = null) => {
  if (appIcons.isInvalid(appIcon)) {
    throw new Error('Invalid "appIcon" param supplied to "ToolbarComponent.createButton"');
  }
  if (typeof label !== 'string') {
    throw new Error('Invalid "label" param supplied to "ToolbarComponent.createButton"');
  }
  if (typeof active !== 'boolean') {
    throw new Error('Invalid "active" param supplied to "ToolbarComponent.createButton"');
  }
  if (typeof onClick !== 'function') {
    throw new Error('Invalid "onClick" param supplied to "ToolbarComponent.createButton"');
  }
  if (key !== undefined && typeof key !== 'string') {
    throw new Error('Invalid "key" param supplied to "ToolbarComponent.createButton"');
  }
  if (typeof disabled !== 'boolean') {
    throw new Error('Invalid "disabled" param supplied to "ToolbarComponent.createButton"');
  }

  const className = 'toolbar-button-icon' + (styleModifier === null ? '' : '-' + styleModifier);

  const result = (
    <Button key={key} disabled={disabled} className={'toolbar-button ' + (active ? 'active' : 'inactive')} onClick={onClick} title={label}>
      <span className={className}>{appIcons.getIcon(appIcon, appIconSizes.sm)}</span>
      <span className="toolbar-button-label">{label}</span>
    </Button>
  );
  return result;
};

const ToolbarComponent = (props) => {
  const middleDivRef = React.useRef(null);
  const [showLabels, setShowLabels] = React.useState(props.showLabels);
  //const [showLabels, setShowLabels] = React.useState(middleDivRef);
  //const [showLabels, setShowLabels] = React.useState(middleDivRef.current.getBoundingClientRect().width);

  const buttons = [];
  const filterImage = props.filterButtonEnabled ? appIcons.Filter : appIcons.Spinner;

  if (props.showFiltersButton) { buttons.push(createButton(filterImage, 'Filters', props.showFilters, props.toggleShowFilters, 'show-filters-btn', !props.filterButtonEnabled)); }
  if (props.showSettingsButton) { buttons.push(createButton(appIcons.Valuations, 'Valuations', props.showSettings, () => props.toggleShowSettings(false), 'show-settings-btn', !props.settingsButtonEnabled)); }
  if (props.showFollowMapButton) { buttons.push(createButton(appIcons.MapMarked, 'Map Filter', props.followMap, () => props.setFollowMap(!props.followMap), 'show-follow-map-btn', !props.showMap)); }
  //buttons.push(createButton(appIcons.Map, props.showMap ? 'Hide Map' : 'Show Map', false, props.toggleShowMap, 'show-map-btn'));
  buttons.push(createButton(appIcons.Map, 'Map', props.showMap, props.toggleShowMap, 'show-map-btn'));
  if (typeof props.excelExport === 'function') { buttons.push(createButton(appIcons.FileExcel, 'Export', false, props.excelExport, 'excel-export-btn', false, 'excel')); }
  if (typeof props.reset === 'function') { buttons.push(createButton(appIcons.Undo, 'Reset', false, props.reset, 'reset-btn')); }
  //if (typeof props.bulkExcelExport === 'function') { buttons.push(createButton(appIcons.FileExcel, 'Bulk Export', false, props.bulkExcelExport, 'bulk-excel-export-btn')); } // BULKEXPORT

  const overviewButton = props.canShowOverview ? createButton(appIcons.Overview, 'Overview', props.showOverview, () => props.setShowOverview(true)) : null;
  const comparisonButton = props.canShowComparison ? createButton(appIcons.Overview, 'Comparison', props.showComparison, () => props.setShowComparison(true)) : null;
  const gridButton = props.canShowGrid ? createButton(appIcons.Table, 'List', props.showGrid, () => props.setShowGrid(true)) : null;
  const tabButtons = (comparisonButton !== null || overviewButton !== null) && gridButton !== null
    ? <ButtonGroup className="tab-buttons">{gridButton}{comparisonButton}{overviewButton}</ButtonGroup>
    : null;

  let valuationTitle = null;
  let valuationText = null;
  let isFieldHubCorp = (props.appView !== undefined && props.appView !== null && (props.appView === appViews.Field || props.appView === appViews.Hub || props.appView === appViews.Corporate));

  //if (props.appView !== undefined && props.appView !== null && (props.appView === appViews.Field || props.appView === appViews.Hub || props.appView === appViews.Corporate )) {
  if (isFieldHubCorp) {
    if (props.appSetState !== undefined && props.appSetState !== null) {
      valuationTitle = <span className="val-settings-text">Valuation Settings: </span>;
      valuationText = <span className="val-text"><a alt="" onClick={() => props.toggleShowSettings(true)} title="Show Valuations">{props.appSetState.appValuation.valuation.variableName}</a></span>
    }
  }
    
  //const addClassName = props.appView === appViews.Field || props.appView === appViews.Hub || props.appView === appViews.Corporate ? 'double' : 'single';
  const addClassName = isFieldHubCorp ? 'double' : 'single';

  React.useLayoutEffect(() => {
    let newValue = props.showLabels;
    if (newValue && middleDivRef.current !== null) {
      let width = middleDivRef.current.getBoundingClientRect().width;

      //on first load we get width as zero so try to cheat and work it out based on what we know e.g. side buttons are 100px and the view is then 60% of the window thats left
      if (width === 0 && document !== null && document !== undefined && document.body !== null && document.body !== undefined) {
        const viewWidth = (document.body.getBoundingClientRect().width - 100) * 0.6;
        width = viewWidth;
      }
      //NB. COULD RETURN TO THIS AND LEARN ABOUT USEEFFECT AND USEREF AND DO IT THE CORRECT WAY


      if (width > 0) {
        let buttonMultiplier = isFieldHubCorp ? 140 : 100;
        const minWidth = (buttons.length * buttonMultiplier) + ((buttons.length - 1) * 5);
        newValue = width > minWidth;
      }
    }
    if (showLabels !== newValue) {
      setShowLabels(newValue);
    }
  }, [props.showLabels, props.percentageWidth])

  const className = appendClassNames('toolbar-component ' + (showLabels ? 'show-labels ' : 'hide-labels ') + addClassName, props.className);

  const filterTitle = 'Showing ' + (props.filterIsSet ? 'Filtered ' : 'All ') + props.title;
  const filterIcon = props.filterIsSet ? <span style={{ fontSize: '0.7em', verticalAlign: 'text-top' }}>{appIcons.getIcon(appIcons.Filter, appIconSizes.xs)}</span> : null;

  return (
    <div className={className}>
      <div className={'left-div ' + addClassName}>
        <div className="title-left-div">{props.title}<small title={filterTitle}> ({props.rowCount}{filterIcon})</small></div>
        <div className="title-right-div">{valuationText}{valuationTitle}</div>
      </div>
      <div ref={middleDivRef} className={'middle-div ' + addClassName}>
        {buttons}
        {tabButtons}
      </div>
      
    </div>
  );
};

ToolbarComponent.propTypes = {
  className: PropTypes.string.isRequired,
  showLabels: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  showMap: PropTypes.bool.isRequired,
  toggleShowMap: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
  showFiltersButton: PropTypes.bool.isRequired,
  filterIsSet: PropTypes.bool.isRequired,
  showFollowMapButton: PropTypes.bool.isRequired,
  followMap: PropTypes.bool.isRequired,
  setFollowMap: PropTypes.func.isRequired,
  showOverview: PropTypes.bool.isRequired,
  setShowOverview: PropTypes.func.isRequired,
  canShowOverview: PropTypes.bool.isRequired,
  canShowGrid: PropTypes.bool.isRequired,
  excelExport: PropTypes.func,
  reset: PropTypes.func,
  percentageWidth: PropTypes.number.isRequired,
  filterButtonEnabled: PropTypes.bool.isRequired
};

ToolbarComponent.defaultProps = {
  showFiltersButton: true,
  filterButtonEnabled: true
};

export default ToolbarComponent;
