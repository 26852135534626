import React from 'react';
import PropTypes from 'prop-types';

import HubDiscoveryTableComponent from './hub-discoveries/hub-discoveries-table-component';

import { HubDetail } from '../../../models/hub-detail';
import { createSection100, createSection, sectionTypes } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import GenericChart, { ChartType } from '../../../shared/components/generic-chart/generic-chart';
import { valueTypes } from '../../../shared/helpers/value-handler';
import './advanced-hub.scss';

const AdvancedHubDiscoveryComponent = (props) => {
  const { hub, proximity } = props;
  
  var discoveryTable;

  const hasDiscos = hub.proximityDiscoveries !== null && hub.proximityDiscoveries.length > 0;
  const chartStyle = { height: '350px' };

  const discoResource = hasDiscos && (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.discoveryResource}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.mmmboe)}
          showYAxisGridLines={true}
        />
      </div>
    </div>);

  const discoResourceByClassificationArea = hasDiscos && (
    <div>
      <div style={chartStyle}>
        <GenericChart
          chartDataItems={hub.chartDataCollection.discoveryResourceByClassificationReservoirAge}
          chartType={ChartType.Bar}
          isXStacked={true}
          isYStacked={true}
          showYAxisLabel={true}
          yAxisLabel={valueTypes.UnitString(valueTypes.mmmboe)}
          showYAxisGridLines={true}
        />
      </div>
    </div>);
  
  if (hasDiscos) {
    discoveryTable = <HubDiscoveryTableComponent
      discoveries={hub.proximityDiscoveries}
      onDiscoveryClick={props.onDiscoveryClick}
      onLicenceClick={props.onLicenceClick}
      onBlockClick={props.onBlockClick}
      onWellClick={props.onWellClick}
      onOperatorClick={props.onOperatorClick}
    />;
  } else {
    discoveryTable = (<div>No discoveries</div>);
  }

  return (
    <div ref={props.innerRef}>
      <h5 className="proximity-title">Discoveries within {proximity}km of the hub centre point</h5>
      {createSection100(discoveryTable, '')}
      {hasDiscos &&
        createSection([
          { content: discoResource, sectionType: sectionTypes.section50, title: 'Discoveries by oil & gas resource' },
          { content: discoResourceByClassificationArea, sectionType: sectionTypes.section50, title: 'Discoveries by reservoir age and classification'}
      ])}
      
    </div>
  );
};

AdvancedHubDiscoveryComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  hub: PropTypes.instanceOf(HubDetail).isRequired,
  proximity: PropTypes.number.isRequired,
  onDiscoveryClick: PropTypes.func,
  onLicenceClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onWellClick: PropTypes.func,
  onOperatorClick: PropTypes.func
};

export default AdvancedHubDiscoveryComponent;