import { showError } from '../../helpers/common';

class Area {
  constructor(obj) {
    if (typeof obj !== 'object') { showError('Area', 'Area'); }
    if (typeof obj.areaId !== 'number') { showError('Area', 'areaId'); }
    if (typeof obj.areaName !== 'string') { showError('Area', 'areaName'); }

    this.areaId = obj.areaId;
    this.areaName = obj.areaName;
  }
}

export { Area }