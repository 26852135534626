import React from 'react';
import PropTypes from 'prop-types';

import './activities-component.scss';
import { ActivityDataRowType, ActivityDataRow } from './activity-data-row';

import { appIcons, appIconSizes } from '../../app/app-icons';
import { TrackingType, AtlasPanel } from '../../shared/helpers/enums';
import { downloadFile } from '../../shared/helpers/download';
import SelectComponent from '../base/components/select-component';
import DatePickerComponent from '../base/components/date-picker-component';
import { appViews } from '../../app/app-views';

const ActivitiesComponent = (props) => {
  const renderSelectItem = (value, text, color) => {
    const item = (
      <span className="activities-component-select-item">
        <span className={'label-color' + (color === null ? ' hidden' : '')} style={{ backgroundColor: color }} />
        <span className="label-text">{text}</span>
      </span>
    );
    const result = { key: value, value: item };
    return result;
  };

  const values = ActivityDataRowType.map(obj => renderSelectItem(obj, ActivityDataRowType.getDisplayName(obj), ActivityDataRowType.getColor(obj)));
  const ALL_KEY = -1;
  values.unshift(renderSelectItem(ALL_KEY, 'All', null));
  const handleSelectChange = (value) => props.setType(value === ALL_KEY ? null : value);
  const handleFromDateChange = (value) => props.setFromDate(value);
  const handleToDateChange = (value) => props.setToDate(value);
  const handleShowDetailsClick = (e, dataRow) => { e.preventDefault(); dataRow.showDetails(dataRow); };
  const handleMapClick = (e, dataRow) => { e.preventDefault(); props.showOnMap(dataRow); };
  const handlePdfClick = (e, dataRow) => { e.preventDefault(); downloadFile(dataRow.documentId, props.appSetState, true, 0, appViews.Home, TrackingType.ReportDownload, AtlasPanel.Activities); };

  const renderDataRow = (dataRow, idx) => {
    let pdfIcon = null;
    if (dataRow.documentId !== null && dataRow.documentId !== 0) {
      pdfIcon = (
        <React.Fragment>
          <span className="separator" />
          <a href="/" className="pdf-icon" title="Show report" onClick={(e) => handlePdfClick(e, dataRow)}>
            {appIcons.getIcon(appIcons.FilePdf, appIconSizes.sm, false)}
          </a>
        </React.Fragment>
      );
    }

    return (
      <div className="data-row" key={'data-row-' + idx}>
        <span className="icon-span" style={{ backgroundColor: dataRow.shape.options.fillColor }} />
        <span className="text-span">
          <a href="/" title={dataRow.header} onClick={(e) => handleShowDetailsClick(e, dataRow)}>
            {dataRow.header.slice(0, 11)}
          </a>
          {dataRow.header.slice(11)}
        </span>
        <span className="buttons-span">
          <a href="/" className="map-icon" title="Show on map" onClick={(e) => handleMapClick(e, dataRow)}>
            {appIcons.getIcon(appIcons.MapMarkerAlt, appIconSizes.sm, false)}
          </a>
          {pdfIcon}
        </span>
      </div>
    );
  };

  return (
    <div className="activities-component">
      <div className="toolbar-div">
        <div className="toolbar-item">
          <SelectComponent label="Type" value={props.type === null ? ALL_KEY : props.type} values={values} onChange={handleSelectChange} showButtons={false} />
        </div>
        <div className="toolbar-item">
          <DatePickerComponent autoOk label="From" value={props.fromDate} maxDate={props.toDate} onChange={handleFromDateChange} clearable={false} showTodayButton />
        </div>
        <div className="toolbar-item">
          <DatePickerComponent autoOk label="To" value={props.toDate} minDate={props.fromDate} onChange={handleToDateChange} clearable={false} showTodayButton disableFuture />
        </div>
      </div>
      <div className="content-div">
        {props.dataRows.map((obj, idx) => renderDataRow(obj, idx))}
      </div>
    </div>
  );
};

ActivitiesComponent.propTypes = {
  dataRows: PropTypes.arrayOf(PropTypes.instanceOf(ActivityDataRow).isRequired).isRequired,
  type: ActivityDataRowType.nullablePropType,
  setType: PropTypes.func.isRequired,
  fromDate: PropTypes.instanceOf(Date),
  setFromDate: PropTypes.func.isRequired,
  toDate: PropTypes.instanceOf(Date),
  setToDate: PropTypes.func.isRequired,
  showOnMap: PropTypes.func.isRequired
};

export default ActivitiesComponent;
export { ActivityDataRowType, ActivityDataRow };