import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'

import { callApi } from '../shared/helpers/api-helpers';
import { AISettingsModel } from '../models/ai-settings-model';

window.appInsights = null;
window.appInsightsInitialized = function () {
  const result = window.appInsights !== null &&
    typeof window.appInsights.isInitialized === 'function' &&
    window.appInsights.isInitialized() === true;
  return result;
}
const _reactPlugin = new ReactPlugin();

function setupAppInsights() {
  const onSuccess = (result) => {
    window.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: new AISettingsModel(result.aisettings).instrumentationKey,
        maxBatchInterval: 0,
        extensions: [_reactPlugin]
      }
    }).loadAppInsights().appInsights;
  };

  const onFail = (error) => console.error('Error loading AppInsight settings', error);

  callApi('api/app/aisettings', { method: 'get', headers: { 'Content-Type': 'application/json' } },
    null, null, null, onSuccess, onFail);
}

const withAppInsights = (Component) => {
  if (window.appInsightsInitialized()) {
    return withAITracking(_reactPlugin, Component);
  }
  return Component;
}
export { setupAppInsights, withAppInsights };

