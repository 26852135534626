import React from 'react';
import PropTypes from 'prop-types';
import './revenue-summary-chart.scss';
import { RevenueSummaryChartAnnual } from './revenue-summary-chart-annual'
import { valueTypes } from '../../../shared/helpers/value-handler';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import {
  WgeCorporateBlue,
  WgeLightGrey,
  WgeWildcatBlue,
  WgeRigLogixYellow,
  WgeAtlasPurple,
  WgeEnergentBlue,
  WgeGlrGreen,
  WgeFarmOutsOrange,
  WgeRigEdgeBlue,
  WgeRigOutlookMaroon
} from '../../../shared/scss/wge-colors'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const RevenueSummaryChart = (props) => {
  const { cashflowData, cumulativeCashflowTitle } = props;

  // NEED TO REFERENCE VARIABLES USED TO CREAT THE CHART, SUCH AS OIL PRICE, DISCOUNT RATE ETC SO THAT WHEN PRINTING CAN BE REUSED INCLUSIVE OF ASSUMPTIONS USED TO CREATE

  cashflowData.sort((a, b) => a.year - b.year);

  var chartData = {
    labels: cashflowData.map(e => e.year),
    datasets: [
      {
        label: cumulativeCashflowTitle,
        fill: false,
        backgroundColor: WgeRigEdgeBlue,
        borderColor: WgeRigEdgeBlue,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.postTaxCashflowUsdMM)),
        pointRadius: 0,
        type: 'line'
      },
      {
        label: 'Revenue',
        backgroundColor: WgeCorporateBlue,
        borderColor: WgeCorporateBlue,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.totalRevenueUsdMM)),
        pointRadius: 0
      },
      {
        label: 'Opex',
        backgroundColor: WgeWildcatBlue,
        borderColor: WgeWildcatBlue,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.opexUsdMM)),
        pointRadius: 0
      },
      {
        label: 'Emissions Charge',
        backgroundColor: WgeRigOutlookMaroon,
        borderColor: WgeRigOutlookMaroon,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.totalEmissionsChargeUsdMM)),
        pointRadius: 0
      },
      {
        label: 'Liquid Tariff',
        backgroundColor: WgeRigLogixYellow,
        borderColor: WgeRigLogixYellow,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.liquidTariffUsdMM)),
        pointRadius: 0
      },
      {
        label: 'Gas Tariff',
        backgroundColor: WgeAtlasPurple,
        borderColor: WgeAtlasPurple,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.gasTariffUsdMM)),
        pointRadius: 0
      },
      {
        label: 'Capex',
        backgroundColor: WgeLightGrey,
        borderColor: WgeLightGrey,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.developmentCapexUsdMM)),
        pointRadius: 0
      },
      {
        label: 'Abex',
        backgroundColor: WgeEnergentBlue,
        borderColor: WgeEnergentBlue,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.abexUsdMM)),
        pointRadius: 0
      }
      //, {
      //  label: 'Abex Relief',
      //  backgroundColor: WgeRigOutlookMaroon,
      //  borderColor: WgeRigOutlookMaroon,
      //  yAxisID: 'A',
      //  data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.abexReliefUsdMM)),
      //  pointRadius: 0
      //}
      , {
        label: 'Corporation Tax',
        backgroundColor: WgeGlrGreen,
        borderColor: WgeGlrGreen,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.corporationTaxUsdMM)),
        pointRadius: 0
      }, {
        label: 'Special Tax',
        backgroundColor: WgeFarmOutsOrange,
        borderColor: WgeFarmOutsOrange,
        data: cashflowData.map(e => valueTypes.ValueOnly(valueTypes.generic1, e.specialTaxUsdMM)),
        pointRadius: 0
      }
    ]
  };

  var chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        stacked: true,
        position: 'left',
        type: 'linear',
        title: {
          text: valueTypes.UnitString(valueTypes.usdMM),
          display: true
        },
        grid: {
          drawOnChartArea: true,
        },
      },

      x: {
        stacked: true,
        ticks: {
          minRotation: 45,
          autoSkip: true
        },
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: false,
          //labelString: 'Year'
        }
      }
    },
    tooltips: { enabled: true },
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      }
    }
  };

  let contentDiv = null;
  contentDiv = (<Bar data={chartData} options={chartOptions} />);

  return (
    <div className="revenue-summary-chart-component">
      {contentDiv}
    </div>
  );

};

RevenueSummaryChart.propTypes = {
  cashflowData: PropTypes.arrayOf(PropTypes.instanceOf(RevenueSummaryChartAnnual)).isRequired,
  cumulativeCashflowTitle: PropTypes.string
};

export { RevenueSummaryChart, RevenueSummaryChartAnnual };