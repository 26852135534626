import React from 'react';
import PropTypes from 'prop-types';

import { DiscoveryDetail } from '../../../models/discovery-detail';
import BaseTableComponent from '../../../shared/components/detail-view-grid-layout/base-table-component';

const DetailsComponent = (props) => {
  const { resource, onWellClick } = props;

  let discoveryWell = resource.discoveryWell;
  if (typeof onWellClick === 'function' && typeof resource.discoveryWellId === 'number') {
    const onClick = (e) => {
      e.preventDefault();
      onWellClick(resource.discoveryWellId);
    };
    discoveryWell = <a href="/" title="Show Details" onClick={onClick}>{discoveryWell}</a>;
  }

  const getRows = () => {
    return (
      <React.Fragment>
        <tr>
          <td>Discovery well</td>
          <td>{discoveryWell}</td>
        </tr>        
        <tr>
          <td>Discovery well spud</td>
          <td>{resource.discoveryWellSpudDate}</td>
        </tr>
        <tr>
          <td>Discovery well completion</td>
          <td>{resource.discoveryWellCompletionDate}</td>
        </tr>
        <tr>
          <td>Phase</td>
          <td>{resource.type}</td>
        </tr>     
        <tr>
          <td>Classification</td>
          <td>{resource.classification}</td>
        </tr>    
        <tr>
          <td>Classification rationale</td>
          <td>{resource.classificationRationale}</td>
        </tr>  
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent className="one-col-key-value">
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

DetailsComponent.propTypes = {
  resource: PropTypes.instanceOf(DiscoveryDetail).isRequired,
  onWellClick: PropTypes.func
};

export default DetailsComponent;
