import React from 'react';
import PropTypes from 'prop-types';

import { WellFilter } from './well-filter';

import BaseViewSettingsComponent, { createSplitDiv, FollowMapComponent, DatePickerComponent, MultiSelectComponent } from '../base/base-view-settings-component';

import { AppSetState } from '../../app/app-set-state';
import { KeyValuePair } from '../../models/key-value-pair';

class WellViewSettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      wells: [],
      countries: [],
      areas: [],
      licences: [],
      quadrants: [],
      blocks: [],
      operators: [],
      participants: [],
      types: [],
      subTypes: [],
      statuses: [],
      results: [],
      primaryObjectives: [],
      rigs: [],
      resourceWells: []
    };
    this.loadFromFilter(this.state);
  }

  componentDidMount() {
    const url = 'api/well/filters/load';
    const onSuccess = (result) => {
      this.setState({
        wells: KeyValuePair.FromArray(result.wells),
        countries: KeyValuePair.FromArray(result.countries),
        areas: KeyValuePair.FromArray(result.areas),
        licences: KeyValuePair.FromArray(result.licences),
        quadrants: KeyValuePair.FromArray(result.quadrants),
        blocks: KeyValuePair.FromArray(result.blocks),
        operators: KeyValuePair.FromArray(result.operators),
        participants: KeyValuePair.FromArray(result.participants),
        types: KeyValuePair.FromArray(result.types),
        subTypes: KeyValuePair.FromArray(result.subTypes),
        statuses: KeyValuePair.FromArray(result.statuses),
        results: KeyValuePair.FromArray(result.results),
        primaryObjectives: KeyValuePair.FromArray(result.primaryObjectives),
        rigs: KeyValuePair.FromArray(result.rigs),
        resourceWells: KeyValuePair.FromArray(result.resourceWells)
      });
    };
    this.props.appSetState.api.getCached(url, onSuccess, null, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter !== prevProps.filter) {
      this.setState(prevState => {
        this.loadFromFilter(prevState);
        return prevState;
      });
    }
  }

  loadFromFilter = (state) => {
    const { filter } = this.props;

    state.followMap = filter.followMap;
    state.wellIds = filter.wellIds;
    state.countryIds = filter.countryIds;
    state.areaIds = filter.areaIds;
    state.licenceIds = filter.licenceIds;
    state.quadrantIds = filter.quadrantIds;
    state.blockIds = filter.blockIds;
    state.operatorIds = filter.operatorIds;
    state.participantIds = filter.participantIds;
    state.typeIds = filter.typeIds;
    state.subTypeIds = filter.subTypeIds;
    state.statusIds = filter.statusIds;
    state.resultIds = filter.resultIds;
    state.primaryObjectiveIds = filter.primaryObjectiveIds;
    state.spudStartYear = filter.spudStartYear;
    state.spudEndYear = filter.spudEndYear;
    state.completionStartYear = filter.completionStartYear;
    state.completionEndYear = filter.completionEndYear;
    state.rigIds = filter.rigIds;
    state.resourceWellIds = filter.resourceWellIds;
  }

  _update = (closeFilters) => {
    const { filter } = this.props;

    filter.followMap = this.state.followMap;
    filter.wellIds = this.state.wellIds;
    filter.countryIds = this.state.countryIds;
    filter.areaIds = this.state.areaIds;
    filter.licenceIds = this.state.licenceIds;
    filter.quadrantIds = this.state.quadrantIds;
    filter.blockIds = this.state.blockIds;
    filter.operatorIds = this.state.operatorIds;
    filter.participantIds = this.state.participantIds;
    filter.typeIds = this.state.typeIds;
    filter.subTypeIds = this.state.subTypeIds;
    filter.statusIds = this.state.statusIds;
    filter.resultIds = this.state.resultIds;
    filter.primaryObjectiveIds = this.state.primaryObjectiveIds;
    filter.spudStartYear = this.state.spudStartYear;
    filter.spudEndYear = this.state.spudEndYear;
    filter.completionStartYear = this.state.completionStartYear;
    filter.completionEndYear = this.state.completionEndYear;
    filter.rigIds = this.state.rigIds;
    filter.resourceWellIds = this.state.resourceWellIds;

    filter.clearInvalidValues();

    this.props.onUpdate(closeFilters);
  }

  _clear = () => {
    this.props.filter.clear();
    this.loadFromFilter(this.state);
    this._update();
  }

  getYearFromDate = (value) => {
    const result = value instanceof Date ? value.getFullYear() : null;
    return result;
  }

  onToggleFollowMap = () => this.setState(prevState => { return { followMap: !prevState.followMap }; });
  onWellIdsChange = (value) => this.setState({ wellIds: value });
  onCountryIdsChange = (value) => this.setState({ countryIds: value });
  onAreaIdsChange = (value) => this.setState({ areaIds: value });
  onLicenceIdsChange = (value) => this.setState({ licenceIds: value });
  onquadrantIdsChange = (value) => this.setState({ quadrantIds: value });
  onBlockIdsChange = (value) => this.setState({ blockIds: value });
  onOperatorIdsChange = (value) => this.setState({ operatorIds: value });
  onParticipantIdsChange = (value) => this.setState({ participantIds: value });
  onTypeIdsChange = (value) => this.setState({ typeIds: value });
  onSubTypeIdsChange = (value) => this.setState({ subTypeIds: value });
  onStatusIdsChange = (value) => this.setState({ statusIds: value });
  onResultIdsChange = (value) => this.setState({ resultIds: value });
  onPrimaryObjectiveIdsChange = (value) => this.setState({ primaryObjectiveIds: value });
  onSpudStartYearChange = (value) => this.setState({ spudStartYear: this.getYearFromDate(value) });
  onSpudEndYearChange = (value) => this.setState({ spudEndYear: this.getYearFromDate(value) });
  onCompletionStartYearChange = (value) => this.setState({ completionStartYear: this.getYearFromDate(value) });
  onCompletionEndYearChange = (value) => this.setState({ completionEndYear: this.getYearFromDate(value) });
  onRigIdsChange = (value) => this.setState({ rigIds: value });
  onResourceWellIdsChange = (value) => this.setState({ resourceWellIds: value });

  render() {
    const spudStartYear = this.state.spudStartYear === null ? null : new Date(this.state.spudStartYear, 1, 1);
    const spudEndYear = this.state.spudEndYear === null ? null : new Date(this.state.spudEndYear, 1, 1)
    const completionStartYear = this.state.completionStartYear === null ? null : new Date(this.state.completionStartYear, 1, 1);
    const completionEndYear = this.state.completionEndYear === null ? null : new Date(this.state.completionEndYear, 1, 1);

    return (
      <BaseViewSettingsComponent
        appSetState={this.props.appSetState}
        className="well-view-settings-component"
        onUpdate={this._update}
        onClear={this._clear}
      >
        <FollowMapComponent value={this.state.followMap} enabled={this.props.followMapEnabled} onClick={this.onToggleFollowMap} />
        <MultiSelectComponent label="Well Name" value={this.state.wellIds} values={this.state.wells} onChange={this.onWellIdsChange} />
        <MultiSelectComponent label="Resource" value={this.state.resourceWellIds} values={this.state.resourceWells} onChange={this.onResourceWellIdsChange} />
        <MultiSelectComponent label="Country" value={this.state.countryIds} values={this.state.countries} onChange={this.onCountryIdsChange} />
        <MultiSelectComponent label="Area" value={this.state.areaIds} values={this.state.areas} onChange={this.onAreaIdsChange} />
        <MultiSelectComponent label="Licence" value={this.state.licenceIds} values={this.state.licences} onChange={this.onLicenceIdsChange} />
        <MultiSelectComponent label="Quadrant" value={this.state.quadrantIds} values={this.state.quadrants} onChange={this.onquadrantIdsChange} />
        <MultiSelectComponent label="Block" value={this.state.blockIds} values={this.state.blocks} onChange={this.onBlockIdsChange} />
        <MultiSelectComponent label="Operator" value={this.state.operatorIds} values={this.state.operators} onChange={this.onOperatorIdsChange} />
        <MultiSelectComponent label="Participant" value={this.state.participantIds} values={this.state.participants} onChange={this.onParticipantIdsChange} />
        <MultiSelectComponent label="Well Type" value={this.state.typeIds} values={this.state.types} onChange={this.onTypeIdsChange} />
        <MultiSelectComponent label="Well Sub-Type" value={this.state.subTypeIds} values={this.state.subTypes} onChange={this.onSubTypeIdsChange} />
        <MultiSelectComponent label="Well Status" value={this.state.statusIds} values={this.state.statuses} onChange={this.onStatusIdsChange} />
        <MultiSelectComponent label="Well Result" value={this.state.resultIds} values={this.state.results} onChange={this.onResultIdsChange} />
        <MultiSelectComponent label="Primary Objective" value={this.state.primaryObjectiveIds} values={this.state.primaryObjectives} onChange={this.onPrimaryObjectiveIdsChange} />
        {createSplitDiv(
          <DatePickerComponent label="Min" views={["year"]} format="YYYY" value={spudStartYear} maxDate={spudEndYear} onChange={this.onSpudStartYearChange} />,
          <DatePickerComponent label="Max" views={["year"]} format="YYYY" value={spudEndYear} minDate={spudStartYear} onChange={this.onSpudEndYearChange} />,
          'Spud Year / Expected Spud Year'
        )}
        {createSplitDiv(
          <DatePickerComponent label="Min" views={["year"]} format="YYYY" value={completionStartYear} maxDate={completionEndYear} onChange={this.onCompletionStartYearChange} />,
          <DatePickerComponent label="Max" views={["year"]} format="YYYY" value={completionEndYear} minDate={completionStartYear} onChange={this.onCompletionEndYearChange} />,
          'Completion Year'
        )}
        <MultiSelectComponent label="Rig" value={this.state.rigIds} values={this.state.rigs} onChange={this.onRigIdsChange} />
      </BaseViewSettingsComponent>
    );
  }
}

WellViewSettingsComponent.propTypes = {
  appSetState: PropTypes.instanceOf(AppSetState).isRequired,
  filter: PropTypes.instanceOf(WellFilter).isRequired,
  onUpdate: PropTypes.func.isRequired,
  followMapEnabled: PropTypes.bool.isRequired
};

export default WellViewSettingsComponent;