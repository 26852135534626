import L from 'leaflet';

import { CircleModel } from '../models/circle-model';
import { removeLayersOfType } from '../helpers/layer-helper';

const createCircleLayer = (circleModel) => {
  if (!(circleModel instanceof CircleModel)) {
    throw new Error('Invalid \'circleModel\' supplied to \'createCircleLayer\'');
  }

  const latLng = L.latLng(circleModel.lat, circleModel.lng);
  const options = {
    radius: circleModel.options.radius,
    stroke: circleModel.options.border,
    color: circleModel.options.borderColor,
    opacity: circleModel.options.borderOpacity,
    weight: circleModel.options.borderWidth,
    fill: circleModel.options.fill,
    fillColor: circleModel.options.fillColor,
    fillOpacity: circleModel.options.fillOpacity,
    interactive: circleModel.options.popup !== undefined
  };
  const result = L.circle(latLng, options);
  result._circleModel = circleModel;
  if (circleModel.options.popup !== undefined) {
    result.bindPopup(circleModel.options.popup);
  }
  return result;
};

const removeCircleLayers = (leafletMap) => removeLayersOfType(leafletMap, L.Circle);

export { createCircleLayer, removeCircleLayers, CircleModel };