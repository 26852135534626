import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import './valuation-summary-component.scss';

const ValuationSummaryComponent = (props) => {
  const { selectedValuation, baseValuation, isAdvancedPlus } = props;
  const percentageLabel = ' (%)';

  let items = [];
  let usingBasePrices = false;
  let summaryClass = isAdvancedPlus ? 'valuations-summary' : 'valuations-summary opaque';
  let scenarioItems = [];
  let scenarioYears = '';
  let scenarioPanel = '';

  if (selectedValuation !== null && selectedValuation !== undefined) {
    let currentYear = selectedValuation.currentYear;
    usingBasePrices = selectedValuation.valuationAnnuals === undefined || selectedValuation.valuationAnnuals === null || selectedValuation.valuationAnnuals.length === 0 ? true : false;

    const valuationAnnuals = usingBasePrices === true ? baseValuation.valuationAnnuals : selectedValuation.valuationAnnuals;
    valuationAnnuals.sort((a, b) => a.year - b.year);
    for (const item of valuationAnnuals) {
      if (item.year >= currentYear) {
        items.push(<tr key={item.year}>
          <td width="20%">{item.year}</td>
          <td width="40%">{item.liquidPriceUsdBOE}</td>
          <td width="40%">{item.gasPriceUsdMCF}</td>
        </tr>)
      }
    }

    for (const scenarioItem of selectedValuation.scenario.scenarioValues) {
      scenarioItems.push(<tr key={scenarioItem.scenarioValueId}><td width="60%">{scenarioItem.attributeName + percentageLabel}</td><td width="40%">{scenarioItem.attributeValue}</td></tr>)
    }

    scenarioYears =
      selectedValuation.scenario.applyToAllYears ?
        <div className="flex-row">
          <div className="col95"><span className="italic-message">Scenario values apply to all years.</span></div>
        </div>
        :
        <div className="flex-row">
          <div className="col95">Scenario values apply from {selectedValuation.scenario.yearFrom} to {selectedValuation.scenario.yearTo}</div>
        </div>;

    if (selectedValuation.isWGEVariable === true) {
      scenarioPanel =
        <div className="flex-row lower-margin">
          <div className="col95"><span className="italic-message">Scenario is not applicable to a Westwood Valuation.</span></div>
        </div>
    } else if (selectedValuation.scenario.isActive === false) {
      scenarioPanel =
        <div className="flex-row lower-margin">
          <div className="col95"><span className="italic-message">Scenario is not active.</span></div>
        </div>
    } else {
      scenarioPanel =
      <div>
        {scenarioYears}
        <div className="table-div-header">
          <Table striped size="sm">
            <thead>
              <tr>
                <th width="60%">Scenario Attribute</th>
                <th width="40%">Attribute Value</th>
              </tr>
            </thead>
          </Table >
        </div>
        <div className="table-div">
          <Table className="main-table" striped size="sm">
            <tbody>
              {scenarioItems}
            </tbody>
          </Table >
        </div>
      </div>
    }
  }
  else {
    return null;
  }

  return (
    <div className={summaryClass}>
      <div className="title-div">General</div>
      <div className="flex-row">
        <div className="col45">Discount Year: {selectedValuation.discountStartYear}</div>
        <div className="col45">Discount Rate: {selectedValuation.discountRate}%</div>
      </div>

      <div className="title-div"><span>Liquid and Gas Price</span></div>
      
      {usingBasePrices === false ? '' :
        <div className="flex-row">
          <div className="col95"><span className="italic-message">The prices below are inherited from the Westwood Base Valuation.</span></div>
        </div>}
      <div className="table-div-header">
        <Table striped size="sm">
          <thead>
            <tr>
              <th width="20%">Year</th>
              <th width="40%">Liquid Price (US$/bbl)</th>
              <th width="40%">Gas Price (US$/mcf)</th>
            </tr>
          </thead>
        </Table >
      </div>
      <div className="table-div">
        <Table className="main-table" striped size="sm">
          <tbody>
            {items}
          </tbody>
        </Table >
      </div>

      <div className="title-div"><span>Scenario</span></div>
      {scenarioPanel}

    </div>
  );
}

ValuationSummaryComponent.propTypes = {
  selectedValuation: PropTypes.object,
  baseValuation: PropTypes.object,
  isAdvancedPlus: PropTypes.bool.isRequired
};

ValuationSummaryComponent.defaultProps = {
  isAdvancedPlus: false
}

export default ValuationSummaryComponent;