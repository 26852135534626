import React from 'react';
import Parser from 'html-react-parser';

class HtmlDetailComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = { apiFile: null }

    this.getfile();
  }

  getfile() {
    const url = 'api/reports/apidefinitions?filename=' + this.props.source;

    const onSuccess = (data) => {
      this.setState({ apiFile: data, });
    }

    const onFail = (err) => {
      console.log(' open api definitions failed ');
    }

    this.props.api.get(url, onSuccess, onFail);
  }

  render() {
    const RenderFile = this.state.apiFile;

    if (RenderFile === null)
      return null;

    return (
      Parser(RenderFile) 
    );
  }

}

export default HtmlDetailComponent;

