class CashflowAnnual {
  constructor(annualCashflowObj) {
    if (typeof annualCashflowObj !== 'object') throw new Error('Invalid annualCashflowObj param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.year !== 'number') throw new Error('Invalid year param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.liquidPriceUsdBOE !== 'number') throw new Error('Invalid liquidPriceUsdBOE param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.gasPriceUsdMCF !== 'number') throw new Error('Invalid gasPriceUsdMCF param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.liquidPriceDiscountPremiumPercent !== 'number') throw new Error('Invalid liquidPriceDiscountPremiumPercent param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.gasPriceDiscountPremiumPercent !== 'number')throw new Error('Invalid gasPriceDiscountPremiumPercent param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.discountRate !== 'number') throw new Error('Invalid discountRate param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.combinedMboed !== 'number') throw new Error('Invalid combinedMboed param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.liquidProductionMbod !== 'number') throw new Error('Invalid liquidProductionMbod param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.gasProductionMmscfd !== 'number') throw new Error('Invalid gasProductionMmscfd param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.gasProductionMboed !== 'number') throw new Error('Invalid gasProductionMboed param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.liquidProductionMMB !== 'number') throw new Error('Invalid liquidProductionMMB param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.gasProductionBCF !== 'number') throw new Error('Invalid gasProductionBCF param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.totalMmboe !== 'number') throw new Error('Invalid totalMmboe param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.liquidRevenueUsdMM !== 'number') throw new Error('Invalid liquidRevenueUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.gasRevenueUsdMM !== 'number') throw new Error('Invalid gasRevenueUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.totalRevenueUsdMM !== 'number') throw new Error('Invalid totalRevenueUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.opexUsdMM !== 'number') throw new Error('Invalid opexUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.liquidTariffUsdBOE !== 'number') throw new Error('Invalid liquidTariffUsdBOE param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.gasTariffUsdBOE !== 'number') throw new Error('Invalid gasTariffUsdBOE param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.liquidTariffUsdMM !== 'number') throw new Error('Invalid liquidTariffUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.gasTariffUsdMM !== 'number') throw new Error('Invalid gasTariffUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.totalTariffUsdMM !== 'number') throw new Error('Invalid totalTariffUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.opexPerBarrel !== 'number') throw new Error('Invalid opexPerBarrel param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.ebitda !== 'number') throw new Error('Invalid ebitda param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.totalEmissionsChargeUsdMM !== 'number') throw new Error('Invalid totalEmissionsChargeUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.cumulativeEBITDA !== 'number') throw new Error('Invalid cumulativeEBITDA param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.ctCumulativeCostForDDAUsdMM !== 'number') throw new Error('Invalid ctCumulativeCostForDDAUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.ctDepreciationUsdMM !== 'number') throw new Error('Invalid ctDepreciationUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.sctCumulativeCostForDDAUsdMM !== 'number') throw new Error('Invalid sctCumulativeCostForDDAUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.sctDepreciationUsdMM !== 'number') throw new Error('Invalid sctDepreciationUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.ctOpeningBalanceUsdMM !== 'number') throw new Error('Invalid ctOpeningBalanceUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.sctOpeningBalanceUsdMM !== 'number') throw new Error('Invalid sctOpeningBalanceUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.ctLossesOrDepreciationAddedUsdMM !== 'number') throw new Error('Invalid ctlossesOrDepreciationAddedUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.ctLossesUsedOrIncomeShelteredUsdMM !== 'number') throw new Error('Invalid ctLossesUsedOrIncomeShelteredUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.sctLossesOrDepreciationAddedUsdMM !== 'number') throw new Error('Invalid sctLossesOrDepreciationAddedUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.sctLossesUsedOrIncomeShelteredUsdMM !== 'number') throw new Error('Invalid sctLossesUsedOrIncomeShelteredUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.rfesUpliftUsdMM !== 'number') throw new Error('Invalid rfesUpliftUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.ctClosingBalanceUsdMM !== 'number') throw new Error('Invalid ctClosingBalanceUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.sctClosingBalanceUsdMM !== 'number') throw new Error('Invalid sctClosingBalanceUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.ctTaxBaseUsdMM !== 'number') throw new Error('Invalid ctTaxBaseUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.investmentsToUpliftUsdMM !== 'number') throw new Error('Invalid investmentsToUpliftUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.upliftUsdMM !== 'number') throw new Error('Invalid upliftUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.cumulativeUpliftAfterUsageUsdMM !== 'number') throw new Error('Invalid cumulativeUpliftAfterUsageUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.investmentAllowanceAvailableUsdMM !== 'number') throw new Error('Invalid investmentAllowanceAvailableUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.investmentAllowanceUsedUsdMM !== 'number') throw new Error('Invalid investmentAllowanceUsedUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.sctIncomeShelteredUsdMM !== 'number') throw new Error('Invalid sctIncomeShelteredUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.sctTaxBaseUsdMM !== 'number') throw new Error('Invalid sctTaxBaseUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.explorationCapexUsdMM !== 'number') throw new Error('Invalid explorationCapexUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.developmentCapexUsdMM !== 'number') throw new Error('Invalid developmentCapexUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.costForLossesOrDepreciationUsdMM !== 'number') throw new Error('Invalid costForLossesOrDepreciationUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.abexUsdMM !== 'number') throw new Error('Invalid abexUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.preTaxCashflowUsdMM !== 'number') throw new Error('Invalid preTaxCashflowUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.cumulativePreTaxCashflowUsdMM !== 'number') throw new Error('Invalid cumulativePreTaxCashflowUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.explorationRebateUsdMM !== 'number') throw new Error('Invalid explorationRebateUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.corporationTaxUsdMM !== 'number') throw new Error('Invalid corporationTaxUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.specialTaxUsdMM !== 'number') throw new Error('Invalid specialTaxUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.abexReliefUsdMM !== 'number') throw new Error('Invalid abexReliefUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.ctAndSCT !== 'number') throw new Error('Invalid ctAndSCT param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.postTaxCashflowUsdMM !== 'number') throw new Error('Invalid postTaxCashflowUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.cumulativePostTaxCashflowUsdMM !== 'number') throw new Error('Invalid cumulativePostTaxCashflowUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.discountedPostTaxCashflowUsdMM !== 'number') throw new Error('Invalid discountedPostTaxCashflowUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.cumulativeDiscountedPostTaxCashflowUsdMM !== 'number') throw new Error('Invalid cumulativeDiscountedPostTaxCashflowUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.opexTypeId !== 'number') throw new Error('Invalid opexTypeId param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.resourceOpexUsdMM !== 'number') throw new Error('Invalid resourceOpexUsdMM param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.opexCostSharePercent !== 'number') throw new Error('Invalid opexCostSharePercent param supplied to CashflowAnnual.ctor');
    if (annualCashflowObj.resourceName != null && typeof annualCashflowObj.resourceName !== 'string') throw new Error('Invalid resourceName param supplied to CashflowAnnual.ctor');
    if (typeof annualCashflowObj.portfolioEquityMultiplier !== 'number') throw new Error('Invalid portfolioEquityMultiplier param supplied to CashflowAnnual.ctor');

    this.year = annualCashflowObj.year;
    this.liquidPriceUsdBOE = annualCashflowObj.liquidPriceUsdBOE;
    this.gasPriceUsdMCF = annualCashflowObj.gasPriceUsdMCF;
    this.liquidPriceDiscountPremiumPercent = annualCashflowObj.liquidPriceDiscountPremiumPercent;
    this.gasPriceDiscountPremiumPercent = annualCashflowObj.gasPriceDiscountPremiumPercent;
    this.discountRate = annualCashflowObj.discountRate;
    this.combinedMboed = annualCashflowObj.combinedMboed;
    this.liquidProductionMbod = annualCashflowObj.liquidProductionMbod;
    this.gasProductionMmscfd = annualCashflowObj.gasProductionMmscfd;
    this.gasProductionMboed = annualCashflowObj.gasProductionMboed;
    this.liquidProductionMMB = annualCashflowObj.liquidProductionMMB;
    this.gasProductionBCF = annualCashflowObj.gasProductionBCF;
    this.totalMmboe = annualCashflowObj.totalMmboe;
    this.liquidRevenueUsdMM = annualCashflowObj.liquidRevenueUsdMM;
    this.gasRevenueUsdMM = annualCashflowObj.gasRevenueUsdMM;
    this.totalRevenueUsdMM = annualCashflowObj.totalRevenueUsdMM;
    this.opexUsdMM = annualCashflowObj.opexUsdMM;
    this.liquidTariffUsdBOE = annualCashflowObj.liquidTariffUsdBOE;
    this.gasTariffUsdBOE = annualCashflowObj.gasTariffUsdBOE;
    this.liquidTariffUsdMM = annualCashflowObj.liquidTariffUsdMM;
    this.gasTariffUsdMM = annualCashflowObj.gasTariffUsdMM;
    this.totalTariffUsdMM = annualCashflowObj.totalTariffUsdMM;    
    this.opexPerBarrel = annualCashflowObj.opexPerBarrel;    
    this.ebitda = annualCashflowObj.ebitda;
    this.totalEmissionsChargeUsdMM = annualCashflowObj.totalEmissionsChargeUsdMM;
    this.cumulativeEBITDA = annualCashflowObj.cumulativeEBITDA;
    this.ctCumulativeCostForDDAUsdMM = annualCashflowObj.ctCumulativeCostForDDAUsdMM;
    this.ctDepreciationUsdMM = annualCashflowObj.ctDepreciationUsdMM;
    this.sctCumulativeCostForDDAUsdMM = annualCashflowObj.sctCumulativeCostForDDAUsdMM;
    this.sctDepreciationUsdMM = annualCashflowObj.sctDepreciationUsdMM;
    this.ctOpeningBalanceUsdMM = annualCashflowObj.ctOpeningBalanceUsdMM;
    this.sctOpeningBalanceUsdMM = annualCashflowObj.sctOpeningBalanceUsdMM;
    this.ctLossesOrDepreciationAddedUsdMM = annualCashflowObj.ctLossesOrDepreciationAddedUsdMM;
    this.ctLossesUsedOrIncomeShelteredUsdMM = annualCashflowObj.ctLossesUsedOrIncomeShelteredUsdMM;
    this.sctLossesOrDepreciationAddedUsdMM = annualCashflowObj.sctLossesOrDepreciationAddedUsdMM;
    this.sctLossesUsedOrIncomeShelteredUsdMM = annualCashflowObj.sctLossesUsedOrIncomeShelteredUsdMM;
    this.rfesUpliftUsdMM = annualCashflowObj.rfesUpliftUsdMM;
    this.ctClosingBalanceUsdMM = annualCashflowObj.ctClosingBalanceUsdMM;
    this.sctClosingBalanceUsdMM = annualCashflowObj.sctClosingBalanceUsdMM;
    this.ctTaxBaseUsdMM = annualCashflowObj.ctTaxBaseUsdMM;
    this.investmentsToUpliftUsdMM = annualCashflowObj.investmentsToUpliftUsdMM;
    this.upliftUsdMM = annualCashflowObj.upliftUsdMM;
    this.cumulativeUpliftAfterUsageUsdMM = annualCashflowObj.cumulativeUpliftAfterUsageUsdMM;
    this.investmentAllowanceAvailableUsdMM = annualCashflowObj.investmentAllowanceAvailableUsdMM;
    this.investmentAllowanceUsedUsdMM = annualCashflowObj.investmentAllowanceUsedUsdMM;
    this.sctIncomeShelteredUsdMM = annualCashflowObj.sctIncomeShelteredUsdMM;
    this.sctTaxBaseUsdMM = annualCashflowObj.sctTaxBaseUsdMM;
    this.explorationCapexUsdMM = annualCashflowObj.explorationCapexUsdMM;
    this.developmentCapexUsdMM = annualCashflowObj.developmentCapexUsdMM;
    this.costForLossesOrDepreciationUsdMM = annualCashflowObj.costForLossesOrDepreciationUsdMM;
    this.abexUsdMM = annualCashflowObj.abexUsdMM;
    this.preTaxCashflowUsdMM = annualCashflowObj.preTaxCashflowUsdMM;
    this.cumulativePreTaxCashflowUsdMM = annualCashflowObj.cumulativePreTaxCashflowUsdMM;
    this.explorationRebateUsdMM = annualCashflowObj.explorationRebateUsdMM;
    this.corporationTaxUsdMM = annualCashflowObj.corporationTaxUsdMM;
    this.specialTaxUsdMM = annualCashflowObj.specialTaxUsdMM;
    this.abexReliefUsdMM = annualCashflowObj.abexReliefUsdMM;
    this.ctAndSCT = annualCashflowObj.ctAndSCT;
    this.postTaxCashflowUsdMM = annualCashflowObj.postTaxCashflowUsdMM;
    this.cumulativePostTaxCashflowUsdMM = annualCashflowObj.cumulativePostTaxCashflowUsdMM;
    this.discountedPostTaxCashflowUsdMM = annualCashflowObj.discountedPostTaxCashflowUsdMM;
    this.cumulativeDiscountedPostTaxCashflowUsdMM = annualCashflowObj.cumulativeDiscountedPostTaxCashflowUsdMM;
    this.opexTypeId = annualCashflowObj.opexTypeId;
    this.resourceOpexUsdMM = annualCashflowObj.resourceOpexUsdMM;
    this.opexCostSharePercent = annualCashflowObj.opexCostSharePercent;
    this.portfolioEquityMultiplier = annualCashflowObj.portfolioEquityMultiplier;
    this.resourceName = annualCashflowObj.resourceName;
  }
}

export { CashflowAnnual };