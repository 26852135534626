import React from 'react';
import PropTypes from 'prop-types';

import { valueTypes } from '../../../../shared/helpers/value-handler';
import { HubDetail } from '../../../../models/hub-detail';
import BaseTableComponent from '../../../../shared/components/detail-view-grid-layout/base-table-component';

const ReservesFactsComponent = (props) => {
  const { hub } = props;
  const percentUnit = valueTypes.UnitString(valueTypes.percent);

  const getRows = () => {
    return (
      <React.Fragment>
        <tr style={{ fontWeight: 'bold' }}>
          <td style={{ width: '25%' }} />
          <td style={{ width: '28%' }}>Total Recoverable</td>
          <td style={{ width: '21%' }}>Remaining</td>
          <td style={{ width: '26%' }}>Remaining ({percentUnit}) </td>
        </tr>
        <tr>
          <td>Liquid ({valueTypes.UnitString(valueTypes.mmb)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, hub.totalRecoverableLiquidMmb)}</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, hub.remainingLiquidMmb)}</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, hub.liquidPercentRemaining)}</td>
        </tr>
        <tr>
          <td>Gas ({valueTypes.UnitString(valueTypes.bcf)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, hub.totalRecoverableGasBcf)}</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, hub.remainingGasBcf)}</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, hub.gasPercentRemaining)}</td>
        </tr>
        <tr>
          <td>Total ({valueTypes.UnitString(valueTypes.mmmboe)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, hub.totalRecoverableCombinedMmboe)}</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, hub.remainingCombinedMmboe)}</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic1, hub.combinedPercentRemaining)}</td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <BaseTableComponent>
      <tbody>
        {getRows()}
      </tbody>
    </BaseTableComponent>
  );
}

ReservesFactsComponent.propTypes = {
  hub: PropTypes.instanceOf(HubDetail).isRequired
};

export default ReservesFactsComponent;
