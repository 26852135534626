import React from 'react';

import { appIcons, appIconSizes } from '../app/app-icons';

const DetailLoadErrorComponent = (props) => {
  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: '50%', left: '50%', marginRight: '-50%', transform: 'translate(-50%, -50%)' }}>
        <div style={{ color: 'red' }}>{appIcons.getIcon(appIcons.ErrorTriangle, appIconSizes.xl)}</div>
        <div style={{ flex: '1 1 auto' }}>{props.children}</div>
      </div>
    </div>
  );
};

export default DetailLoadErrorComponent;