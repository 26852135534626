import React from 'react';
import PropTypes from 'prop-types';

import { valueTypes } from '../../../shared/helpers/value-handler';
import GenericChart, { ChartType } from '../../../shared/components/generic-chart/generic-chart';
import ReservesFactsComponent from '../../../components/fiscal/fact-components/reserves-facts-component';
import { FasterPortfolio, FieldDetail } from '../../../components/fiscal/models/portfolio/faster-portfolio';
import ProductionFactsComponent from '../../../components/fiscal/fact-components/production-facts-component';
import { createSection100 } from '../../../shared/components/detail-view-grid-layout/detail-view-grid-layout-component';
import AdvancedBaseReportedReservesFactsComponent from './advanced-base-reported-reserves-facts-component';

const AdvancedReservesAndProductionComponent = (props) => {
  const { portfolio, resource } = props;

  const reservesChartStyle = { height: '300px', display: 'inline-block', width: '40%' };
  const reservesTableStyle = { width: '58%', display: 'inline-block', float: 'right' };
  const baseReportedTableStyle = { paddingTop: '20px' };
  const productionChartStyle = { height: '300px', display: 'inline-block', width: '70%' };
  const productionTableStyle = { width: '28%', display: 'inline-block', float: 'right' };

  const baseReported = resource.showBaseReserves || (resource.showLiquidReportedReserves && resource.showGasReportedReserves) ? <div style={baseReportedTableStyle}><AdvancedBaseReportedReservesFactsComponent resource={resource} /></div> : null;

  const reserves = (
    <div>
      <div style={reservesChartStyle}>
        <GenericChart
          chartDataItems={resource.chartDataCollection.fieldReserves}
          chartType={ChartType.HorizontalBar}
          isXStacked={true}
          isYStacked={true}
          showXAxisLabel={true}
          xAxisLabel={valueTypes.UnitString(valueTypes.mmmboe)}
          showXAxisGridLines={true}
        />
      </div>
      <div style={reservesTableStyle}>
        <div><ReservesFactsComponent resource={resource} /></div>
        {baseReported}
      </div>
     </div>
  );

  const production = (
    <div>
      <div style={productionChartStyle}>
      <GenericChart
        chartDataItems={resource.chartDataCollection.fieldProduction}
        chartType={ChartType.Line}
        isXStacked={false}
        isYStacked={true}
        showYAxisLabel={true}
        yAxisLabel={valueTypes.UnitString(valueTypes.mboed)}
        showYAxisGridLines={true}
        />
      </div>
      <div style={productionTableStyle}>
        <ProductionFactsComponent
          annuals={resource.fiscalResource.resourceAnnuals}
          currentYear={portfolio.currentYear}
          peakYear={resource.fiscalResource.peakCombinedProductionYear}
        />
      </div>
    </div>
  );

  return (
    <div ref={props.innerRef}>
      {createSection100(reserves, 'Reserves')}
      {createSection100(production, 'Production')}
    </div>
  );
};

AdvancedReservesAndProductionComponent.propTypes = {
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  portfolio: PropTypes.instanceOf(FasterPortfolio).isRequired,
  resource: PropTypes.instanceOf(FieldDetail).isRequired
};

export default AdvancedReservesAndProductionComponent;
