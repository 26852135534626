import React from 'react';

import { appIconSizes } from './app-icons';
import { objectToEnum } from '../shared/helpers/common';
import { ViewModel } from '../shared/components/main/main-classes';

const appViews = objectToEnum({
  Home: 2,
  Reports: 1,
  Corporate: 2002,
  Hub: 2003,
  Field: 2000,
  Discovery: 2001,
  Prospect: 2004,
  Well: 2005,
  Licence: 2007,
  Block: 2006
}, false);

appViews.getDisplayName = (appView, singular = false) => {
  if (singular === true) {
    switch (appView) {
      case appViews.Reports: return 'Report';
      case appViews.Field: return 'Field';
      case appViews.Hub: return 'Production Hub';
      case appViews.Corporate: return 'Company';
      case appViews.Discovery: return 'Discovery';
      case appViews.Block: return 'Block';
      case appViews.Well: return 'E&A Well';
      case appViews.Prospect: return 'Prospect';
      case appViews.Licence: return 'Licence';
      default: break;
    }
  }

  switch (appView) {
    case appViews.Home: return 'Home';
    case appViews.Reports: return 'Reports';
    case appViews.Field: return 'Fields';
    case appViews.Hub: return 'Production Hubs';
    case appViews.Corporate: return 'Companies';
    case appViews.Discovery: return 'Discoveries';
    case appViews.Block: return 'Blocks';
    case appViews.Well: return 'E&A Wells';
    case appViews.Prospect: return 'Prospects';
    case appViews.Licence: return 'Licences';
    default: throw new Error('Invalid "appView" param supplied to "appViews.getDisplayName"');
  }
}

appViews.getIcon = (appView, size, selected = false) => {
  if (appViews.isInvalid(appView)) {
    throw new Error('Invalid "appView" param supplied to "appViews.getIcon"');
  }
  if (appIconSizes.isInvalid(size)) {
    throw new Error('Invalid "size" param supplied to "appViews.getIcon"');
  }
  if (typeof selected !== 'boolean') {
    throw new Error('Invalid "selected" param supplied to "appViews.getIcon"');
  }

  if (size === appIconSizes.xs) {
    size = appIconSizes.sm;
  } else if (size === appIconSizes.xl) {
    size = appIconSizes.lg;
  }

  let fileName = '';
  switch (appView) {
    case appViews.Home:
      fileName = '22052020_home'; break;
    case appViews.Reports:
      fileName = '22052020_reports'; break;
    case appViews.Field:
      fileName = '22052020_field'; break;
    case appViews.Hub:
      fileName = '22052020_hub'; break;
    case appViews.Corporate:
      fileName = '22052020_corporate'; break;
    case appViews.Discovery:
      fileName = '22052020_discovery'; break;
    case appViews.Block:
      fileName = '22052020_block'; break;
    case appViews.Well:
      fileName = '22052020_well'; break;
    case appViews.Prospect:
      fileName = '22052020_prospect'; break;
    case appViews.Licence:
      fileName = '22052020_licence'; break
    default:
      throw new Error('Invalid "appView" param supplied to "appViews.getIcon"');
  }
  fileName = '/images/app-views/' + fileName + '_' + (selected ? 'white' : 'black') + '_' + appIconSizes.getDisplayName(size, true) + '.png';
  const result = <img src={fileName} alt={appViews.getDisplayName(appView)} />;
  return result;
};

appViews.openOverlayed = (appView) => {
  if (appViews.isInvalid(appView)) {
    throw new Error('Invalid "appView" param supplied to "appViews.openOverlayed"');
  }

  switch (appView) {
    case appViews.Reports: return false;
    default: return true;
  }
};

appViews.toViewModel = (appView, showLabels) => {
  if (appViews.isInvalid(appView)) {
    throw new Error('Invalid "appView" param supplied to "appViews.toViewModel"');
  }
  if (typeof showLabels !== 'boolean') {
    throw new Error('Invalid "showLabels" param supplied to "appViews.toViewModel"');
  }

  const result = new ViewModel(
    appView,
    appViews.getDisplayName(appView),
    appViews.getIcon(appView, showLabels ? appIconSizes.lg : appIconSizes.md, false),
    appViews.getIcon(appView, appIconSizes.sm, false),
    appViews.getIcon(appView, showLabels ? appIconSizes.lg : appIconSizes.md, true),
    appViews.getIcon(appView, appIconSizes.sm, true)
  );
  return result;
};

appViews.toViewModels = (showLabels) => {
  const result = appViews.map(obj => appViews.toViewModel(obj, showLabels));
  return result;
};

appViews.getDetailUrl = (appView, id) => {
  if (appViews.isInvalid(appView)) {
    throw new Error('Invalid "appView" param supplied to "appViews.getDetailUrl"');
  }
  if (typeof id !== 'number') {
    throw new Error('Invalid "id" param supplied to "appViews.getDetailUrl"');
  }

  let result = new URL(window.location.href).origin + '/';
  result += appViews.getName(appView, true);
  result += '?wge_id=' + id
  return result;
};

Object.freeze(appViews);

export { appViews };
