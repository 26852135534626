import React from 'react';
import PropTypes from 'prop-types';

import BaseTableComponent from '../detail-view-grid-layout/base-table-component';
import { valueTypes } from '../../helpers/value-handler';

const TechnicalComponent = (props) => {
  const { resource } = props;

  return (
    <BaseTableComponent className="two-col-key-value">
      <tbody>
        <tr>
          <td id="1">Reservoir Age Era</td>
          <td>{resource.reservoirAge}</td>

          <td id="8">Initial Reservoir Pressure ({valueTypes.UnitString(valueTypes.psi)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.psi, resource.initialReservoirPressurePsi)}</td>
        </tr>
        <tr>
          <td id="2">Water Depth ({valueTypes.UnitString(valueTypes.feet)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.feet, resource.waterDepthFt)}</td>          

          <td id="9">Reservoir Temperature ({valueTypes.UnitString(valueTypes.farenheit)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.farenheit, resource.reservoirTemperatureFahrenheit)}</td>
        </tr>
        <tr>
          <td id="3">Reservoir Depth ({valueTypes.UnitString(valueTypes.feet)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.feet, resource.reservoirDepthFt)}</td>          

          <td id="10">Gross Reservoir Avg ({valueTypes.UnitString(valueTypes.feet)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.feet, resource.grossReservoirIntervalAvgFt)}</td>
        </tr>
        <tr>
          <td id="4">Fluid Reservoir Condition</td>
          <td>{resource.condition}</td>
          
          <td id="11">Net to Gross Ratio Average</td>
          <td>{valueTypes.ValueOnly(valueTypes.generic2ZeroDash, resource.netToGrossRatioAvgFt)}</td>
        </tr>
        <tr>
          <td id="5">Trap Type</td>
          <td>{resource.trapType}</td>

          <td id="12">Porosity ({valueTypes.UnitString(valueTypes.percent)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.percentZeroDash, resource.porosityAveragePercent)}</td>          
        </tr>
        <tr>
          <td id="6">Pressure Status</td>
          <td>{resource.pressureStatus}</td>                  

          <td>Permeability Millidarcies ({valueTypes.UnitString(valueTypes.millidarcies)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.millidarcies, resource.permeabilityAverageMillidarcies)}</td>
        </tr>
        <tr>
          <td id="7">Temperature Status</td>
          <td>{resource.temperatureStatus}</td>

          <td>Oil Density API ({valueTypes.UnitString(valueTypes.api)})</td>
          <td>{valueTypes.ValueOnly(valueTypes.api, resource.oilDensityDegApi)}</td>         
          
        </tr>

      </tbody>
    </BaseTableComponent>   
  );
}

TechnicalComponent.propTypes = {
  resource: PropTypes.shape({
    reservoirAge: PropTypes.string.isRequired,
    initialReservoirPressurePsi: PropTypes.number.isRequired,
    waterDepthFt: PropTypes.number.isRequired,
    reservoirTemperatureFahrenheit: PropTypes.number.isRequired,
    reservoirDepthFt: PropTypes.number.isRequired,
    grossReservoirIntervalAvgFt: PropTypes.number.isRequired,
    condition: PropTypes.string.isRequired,
    netToGrossRatioAvgFt: PropTypes.number.isRequired,
    trapType: PropTypes.string.isRequired,
    porosityAveragePercent: PropTypes.number.isRequired,
    pressureStatus: PropTypes.string.isRequired,
    permeabilityAverageMillidarcies: PropTypes.number.isRequired,
    temperatureStatus: PropTypes.string.isRequired,
    oilDensityDegApi: PropTypes.number.isRequired,
  })
};

export default TechnicalComponent;
