import React from 'react';

import BlockDetailComponent from './block-detail-component';

import { DetailModel } from '../detail-model';
import HeaderComponent from '../header-component';

import { appViews } from '../../app/app-views';
import { BlockDetail } from '../../models/block-detail';
import { ContextLayerModel } from '../../shared/helpers/leaflet/leaflet-helper';

class BlockDetailModel extends DetailModel {
  constructor(atlasPanel, blockId) {
    if (typeof blockId !== 'number') {
      throw new Error('Invalid "blockId" supplied to "BlockDetailModel.ctor"');
    }

    super(appViews.Block, atlasPanel);
    this._blockId = blockId;
  }

  matches(detail) {
    const result =
      typeof detail === 'object' &&
      detail instanceof BlockDetailModel &&
      detail._blockId === this._blockId;
    return result;
  }

  load(appSetState, onLoadComplete) {
    this._validateLoadParams(appSetState, onLoadComplete);

    const onSuccess = (result) => {
      this.clearError();
      this._data = {
        block: new BlockDetail(result.block),
        contextLayers: ContextLayerModel.FromArray(result.contextLayers)
      }
      this.addNavigationHistory(appSetState, this._blockId, this._data.block.name);
      onLoadComplete();
    };
    const onFail = (err) => this.handleLoadError(err, onLoadComplete);
    const url = 'api/block/details/load/?blockId=' + this._blockId + '&page=' + appSetState.view.current + '&panel=' + this.atlasPanel;
    appSetState.api.getCached(url, onSuccess, onFail);
  }

  getHeaderComponent(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close) {
    this._validateGetHeaderComponentParams(appSetState, backClick, forwardClick, fullScreen, setFullScreen, close);

    const copyLinkClick = null;
    //const copyLinkClick = this.loaded
    //  ? () => appSetState.view.details.showBlockLink(this._blockId)
    //  : null;

    return (
      <HeaderComponent
        appView={this.appView}
        title={this.getTitle(() => this._data.block.name)}
        backClick={backClick}
        forwardClick={forwardClick}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        close={close}
        copyLinkClick={copyLinkClick}
      />
    );
  }

  getBodyComponent(appSetState, geoserverUrl) {
    this._validateGetBodyComponentParams(appSetState, geoserverUrl);

    if (this._error !== null) {
      return this._getErrorComponent();
    }

    if (this.loaded) {
      return (
        <BlockDetailComponent
          block={this._data.block}
          contextLayers={this._data.contextLayers}
          appSetState={appSetState}
          geoserverUrl={geoserverUrl}
        />
      );
    }

    return this._getLoadingComponent();
  }
}

export { BlockDetailModel };