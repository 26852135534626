import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import icon from './map-bookmark.png';
import { appIcons } from '../../app/app-icons';
import { AppSetState } from '../../app/app-set-state';
import { addEventHandler, getIcon } from '../../shared/helpers/leaflet/helpers/control-helper';
import { MenuControl, MenuItemControl } from "../../shared/helpers/leaflet/controls/menu-control";

const DEFAULT_ICON = L.icon({ iconUrl: icon, shadowUrl: iconShadow, iconSize: [25, 41], iconAnchor: [12, 41], shadowSize: [41, 41] });

class BaseBookmarkItem extends MenuItemControl {
  constructor(appIcon, title, onClick) {
    super(appIcons.getIconDefinition(appIcon), title);

    if (typeof onClick !== 'function') {
      throw new Error('Invalid "onClick" param supplied to "BaseBookmarkItem.ctor"');
    }

    this._onClick = onClick;
  }

  onClick() {
    this._onClick();
  }
}

class AddBookmark extends BaseBookmarkItem {
  constructor(onClick) {
    super(appIcons.PlusCircle, 'Add Bookmark', onClick);
  }
}

class GotoBookmark extends BaseBookmarkItem {
  constructor(displayName, onClick) {
    super(appIcons.MapMarkerAlt, displayName, onClick)
  }
}

class ClearBookmarks extends BaseBookmarkItem {
  constructor(onClick) {
    super(appIcons.Clear, 'Clear Bookmarks', onClick)
  }
}

class ToggleBookmarks extends BaseBookmarkItem {
  constructor(visible, onClick) {
    super(visible ? appIcons.ToggleOn : appIcons.ToggleOff, (visible ? 'Hide' : 'Show') + ' Bookmarks', onClick)
  }
}

class MapBookmarksMenu extends MenuControl {
  constructor(leafletMap, appSetState) {
    super(leafletMap, appIcons.getIconDefinition(appIcons.Bookmark), { title: 'Bookmarks' });

    if (!(appSetState instanceof AppSetState)) {
      throw new Error('Invalid "appSetState" param supplied to "MapBookmarksMenu.ctor"');
    }

    this._appSetState = appSetState;
    this._layer = L.layerGroup();
    leafletMap.addLayer(this._layer);
    if (this.mapBookmarks.visible) {
      this.mapBookmarks.get().forEach(obj => this._add(obj));
    }
  }

  get mapBookmarks() { return this._appSetState.mapBookmarks; }

  get isOpen() { return super.isOpen; }
  set isOpen(value) {
    if (super.isOpen !== value && value) {
      const bookmarks = this.mapBookmarks.get();

      this.clear();
      if (bookmarks.length > 0) {
        bookmarks.forEach(obj => this.addMenuItem(new GotoBookmark(obj.displayName, () => this._gotoClick(obj))));
        this.addSeparator();
      }
      this.addMenuItem(new AddBookmark(this._addClick));
      if (bookmarks.length > 0) {
        this.addMenuItem(new ClearBookmarks(this._clearClick));
        this.addMenuItem(new ToggleBookmarks(this.mapBookmarks.visible, this._toggleClick));
      }
    }
    super.isOpen = value;
  }

  _add(bookmark) {
    const marker = L.marker([bookmark.lat, bookmark.lng], {
      icon: DEFAULT_ICON,
      interactive: true,
      draggable: true
    });
    marker.on('dragend', (e) => { this._dragMarker(bookmark, e.target._latlng) });

    const div = L.DomUtil.create('div');
    div.style.width = '169px';

    const input = L.DomUtil.create('input', 'form-control', div);
    input.style.display = 'inline-block';
    input.style.verticalAlign = 'middle';
    input.style.width = '150px';
    input.setAttribute('type', 'text');
    input.setAttribute('placeholder', 'Bookmark Name...');
    input.value = bookmark.displayName;
    addEventHandler(input, 'keyup', () => this._displayNameChange(bookmark, input.value));
    addEventHandler(input, 'focus', input.select);

    const btn = L.DomUtil.create('span', '', div);
    btn.style.width = '19px';
    btn.style.display = 'inline-block';
    btn.style.verticalAlign = 'middle';
    btn.style.cursor = 'pointer';
    btn.style.padding = '4px';
    btn.innerHTML = getIcon(appIcons.getIconDefinition(appIcons.Clear), { size: '2x' });
    addEventHandler(btn, 'click', () => this._deleteClick(bookmark, marker));

    marker.bindPopup(div);

    if (this.mapBookmarks.visible) {
      this._layer.addLayer(marker);
    }
  }

  _addClick = () => {
    const center = this.leafletMap.getBounds().getCenter();
    const zoom = this.leafletMap.getZoom();
    const bookmark = this.mapBookmarks.new(center.lat, center.lng, zoom);
    this._add(bookmark);
    this.close();
  }

  _gotoClick = (bookmark) => {
    this.leafletMap.flyTo([bookmark.lat, bookmark.lng], bookmark.zoom, { animate: true, duration: 1 });
    this.close();
  }

  _clearClick = () => {
    const doClear = () => {
      this.mapBookmarks.clear();
      this._layer.clearLayers();
    };

    this._appSetState.messageBox.showYesNoPrompt('Are you sure you want to clear your bookmarks?', doClear);
  }

  _deleteClick = (bookmark, marker) => {
    const doDelete = () => {
      this.mapBookmarks.delete(bookmark);
      this._layer.removeLayer(marker);
    };

    this._appSetState.messageBox.showYesNoPrompt('Are you sure you want to delete this bookmark?', doDelete);
  }

  _toggleClick = () => {
    if (this.mapBookmarks.visible) {
      this.mapBookmarks.visible = false;
      this._layer.clearLayers();
    } else {
      this.mapBookmarks.visible = true;
      this.mapBookmarks.get().forEach(obj => this._add(obj));
    }
  }

  _dragMarker = (bookmark, latLng) => {
    this.mapBookmarks.updateLatLng(bookmark, latLng.lat, latLng.lng);
  }

  _displayNameChange = (bookmark, displayName) => {
    this.mapBookmarks.updateDisplayName(bookmark, displayName);
  }
}

export { MapBookmarksMenu };